import React, { useState } from 'react';
import { FormModel, SearchFormProps } from '../../types';
import TitleForm from './typeQuestions/title';
import QuestionContainer from './typeQuestions/questionContainer';
import IconDictionary from '../../../../components/Icons/icons';
import { Button } from '../../../../components';
/* eslint-disable no-unused-vars */

type QuestionProps = {
  id: number;
  title: string;
  type_response: 'text' | 'checkbox' | 'textarea' | 'date' | 'radio';
  is_active: boolean;
  options: { title: string; is_active: boolean }[];
};

const CreateUpdate: React.FC<{
  defaultValue: SearchFormProps;
  closeUpdate: (form: any) => void;
  saveData: (form: FormModel) => void;
  notify: ({ message, type }: any) => void;
}> = ({ defaultValue, closeUpdate, saveData, notify }) => {
  let uniqueIdCounter = 1;

  const getNextUniqueId = () => uniqueIdCounter++;

  interface dataProps extends FormModel {
    didChange: boolean;
  }

  const [data, setData] = useState<dataProps>({
    is_active: defaultValue.is_active,
    questions: defaultValue.search_form_questions.map((item) => ({
      id: item.id,
      is_active: item.is_active,
      options: item.search_form_questions_options,
      title: item.title,
      type_response: item.type_response,
    })),
    title: defaultValue.title,
    didChange: false,
  });

  const [activeCard, setActiveCard] = useState(-2);
  const [leave, setLeave] = useState(false);

  const addData = (payload: QuestionProps, index: number) => {
    const newArray = [...data.questions];
    const newIndex = index + 1;
    newArray.splice(newIndex, 0, { ...payload, id: getNextUniqueId() });

    setData((prevData) => ({
      ...prevData,
      didChange: true,
      questions: [...newArray],
    }));
  };

  const updateData = (index: number, updatedElement: QuestionProps) => {
    setData((prevData) => {
      const newArray = [...prevData.questions];
      newArray[index] = updatedElement;
      return { ...prevData, questions: newArray, didChange: true };
    });
  };

  const deleteData = (index: number) => {
    setData((prevData) => {
      const newArray = [...prevData.questions];
      newArray.splice(index, 1);
      return { ...prevData, didChange: true, questions: newArray };
    });
  };

  window.addEventListener('beforeunload', (event) => {
    if (data.didChange) {
      const mensagem =
        'Você tem alterações não salvas. Tem certeza que deseja sair?';
      event.returnValue = mensagem;
      return mensagem;
    }
    return null;
  });

  return (
    <>
      <IconDictionary
        name="Seta voltar"
        onClick={() => {
          if (data.didChange && !leave) {
            notify({
              message: 'Existem alterações que ainda não foram salvas',
              type: 'Warning',
            });
            setLeave(true);
          } else {
            closeUpdate(data);
          }
        }}
        size={24}
        className="absolute left-3 -mb-5 mt-10 hover:scale-110 cursor-pointer min-h-[24px]"
      />
      <div className="w-full md:w-2/3 2xl:w-1/3 mx-auto flex flex-col relative items-center mt-3 pb-10">
        <TitleForm
          addQuestion={(i) =>
            addData(
              {
                id: getNextUniqueId(),
                is_active: true,
                options: [],
                title: 'Pergunta',
                type_response: 'text',
              },
              -1
            )
          }
          currentCardNumber={activeCard}
          setText={(e) => setData({ ...data, title: e, didChange: true })}
          text={data.title}
          setCurrentCard={setActiveCard}
        />

        {data.questions.map((item, index) => (
          <QuestionContainer
            key={item.id}
            activeNumberCard={activeCard}
            setActiveCard={setActiveCard}
            deleteQuestion={() => deleteData(index)}
            addQuestion={(__, e: any) =>
              e
                ? addData(e, index)
                : addData(
                    {
                      id: getNextUniqueId(),
                      is_active: true,
                      options: [],
                      title: 'Pergunta',
                      type_response: 'text',
                    },
                    index
                  )
            }
            data={item}
            index={index}
            updateData={(__, e) => updateData(index, e)}
          />
        ))}

        <Button
          actionType="button-simple"
          className={
            data.didChange && leave === true
              ? 'animate-bounce w-48 fixed bottom-5'
              : 'w-48 fixed bottom-5'
          }
          onClick={() => {
            saveData(data);
            setData({ ...data, didChange: false });
            setLeave(true);
          }}
        >
          Salvar
        </Button>
      </div>
    </>
  );
};

export default CreateUpdate;
