import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import { UnlockOutline } from '@styled-icons/evaicons-outline/UnlockOutline';
import { Button, Card, SelectComponent, Spinner } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import { useFetchGroup } from '../../admin/group/hooks';
import { ApiMenu } from '../../admin/menu/api';
import { useFetchProfile } from '../../admin/profile/hooks';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { Profile } from '../../admin/profile/types';
import { setItem } from '../../../helpers/storage.helper';
import { reloadMenu } from '../../../hooks/globalAtom';
import { useFetchMenusCompanie, useFetchMenusCompanieProfile } from '../hooks';
import { MenuFullProps } from '../../admin/menu/types';
import { ApiReleaseMenu } from '../api';
import { formatMenuToRoutes } from '../../main/utils';

const ReleaseMenuClient: React.FC = () => {
  const [, setKeyMenu] = useAtom(reloadMenu);
  const { notify } = ToastNotify();
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(-1);

  const FilterProfiles = (profiles: Profile[]) =>
    profiles
      .filter((elem) => !elem.is_admin)
      .filter(
        (item) => item.is_franchise === accountProps.companie?.is_franchise
      );

  const FilterMenus = (menus: MenuFullProps[]) => {
    const menusByCompanyType =
      accountProps.companie?.is_franchise === true
        ? menus.filter(
            (item) =>
              item.is_franchise === accountProps.companie?.is_franchise &&
              !item.route_frontend.includes('-admin')
          )
        : menus.filter(
            (item) =>
              item.is_franchisor === accountProps.companie?.is_franchisor &&
              !item.route_frontend.includes('-admin')
          );
    return menusByCompanyType;
  };

  const { ProfileList, isLoadingProfile } = useFetchProfile('key');
  const optionClient = FilterProfiles(ProfileList).map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const { MenusCompanieProfileList, isLoadingMenuList } =
    useFetchMenusCompanieProfile('', profile, accountProps.companie_id);

  const listOfMenus = MenusCompanieProfileList.map((item) => item.menu_id);
  const [menusID, setMenusID] = useState<number[]>(listOfMenus);

  const { MenusCompanieList, isLoadingMenus } = useFetchMenusCompanie('key');
  const { GroupList, isLoadingGroup } = useFetchGroup('key');

  const AddRemoveIdList = (id: number) => {
    if (menusID.includes(id)) {
      setMenusID(menusID.filter((item) => item !== id));
    } else {
      setMenusID([...menusID, id]);
    }
  };

  const Submit = async () => {
    setIsLoading(true);
    const res = await ApiReleaseMenu.releaseMenus(
      {
        menus: menusID,
        profile_id: profile,
      },
      accountProps.companie_id
    );
    if (res === true) {
      setIsLoading(false);
      notify({ message: 'Menus liberados com sucesso', type: 'Success' });

      const responseMenus: MenuFullProps[] = await ApiMenu.getAllMenusByUser(
        accountProps.id
      );
      setItem('menu', responseMenus);
      const routesUser = formatMenuToRoutes(responseMenus);
      setItem('routes_user', routesUser);
      setKeyMenu(`${Math.random()} change`);
    } else {
      setIsLoading(false);
      notify({ message: 'Desculpe, algo deu errado', type: 'Error' });
    }
  };
  useEffect(() => {
    const menus = MenusCompanieProfileList.map((item) => item.menu_id);
    setMenusID(menus);
  }, [MenusCompanieProfileList]);

  return (
    <div className="w-full min-h-screen h-fit">
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Menus por perfil" size={24} />
        <strong className="text-lg text-black">Permissões por perfil</strong>
      </div>
      <Card className="w-full p-7 mt-6 mb-3 flex-col min-h-screen">
        <strong className="text-xl">Menu</strong>
        <p className="text-sm">Configurações</p>
        <div className="w-full lg:w-72 my-5">
          <SelectComponent
            title="Liberar Menu para o perfil"
            className="w-full"
            closeMenuOnSelect
            options={optionClient}
            onChange={(e: any) => {
              setProfile(e.value);
            }}
          />
        </div>

        {!isLoadingMenuList &&
        !isLoadingProfile &&
        !isLoadingGroup &&
        !isLoadingMenus ? (
          <div className="flex flex-col">
            {FilterMenus(MenusCompanieList).length > 0 && (
              <>
                <p className="font-bold mt-3 mb-2">Sem Módulo</p>
                {FilterMenus(
                  MenusCompanieList.filter((item) => item.group_id === null)
                ).map((item) => (
                  <button
                    type="button"
                    className="flex mb-1 items-center w-fit"
                    onClick={() => AddRemoveIdList(item.id)}
                  >
                    <input
                      value={item.id}
                      type="checkbox"
                      className="accent-primary"
                      checked={menusID.includes(item.id)}
                      onChange={() => AddRemoveIdList(item.id)}
                    />
                    <p className="ml-2 text-sm">{item.title}</p>
                  </button>
                ))}
              </>
            )}
            <div className="flex flex-col my-3">
              {GroupList.sort((a: any, b: any) =>
                a.module.title.localeCompare(b.module.title)
              ).map(
                (item) =>
                  FilterMenus(item.menu ?? []).length !== 0 && (
                    <>
                      <p className="font-bold">
                        {item.module?.title} - {item.title}
                      </p>

                      <div className="grid grid-cols-2 mt-2 mb-4">
                        {FilterMenus(item.menu ?? []).map((menus) => (
                          <button
                            type="button"
                            className="flex mb-1 items-center w-fit"
                            onClick={() => AddRemoveIdList(menus.id)}
                          >
                            <input
                              value={menus.id}
                              checked={menusID.includes(menus.id)}
                              type="checkbox"
                              className="accent-primary"
                              onChange={() => AddRemoveIdList(menus.id)}
                            />
                            <p className="ml-2 text-sm">{menus.title}</p>
                          </button>
                        ))}
                      </div>
                    </>
                  )
              )}
            </div>
            <Button
              variant="primary-strong"
              actionType="button-loading"
              className="mt-2 place-self-center text-white w-44 flex items-center justify-center gap-2.5"
              onClick={Submit}
              isLoading={isLoading}
              disabled={isLoading || profile === -1}
            >
              <UnlockOutline color="#fff" size={18} />
              Liberar
            </Button>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center h-[60vh]">
            <Spinner />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ReleaseMenuClient;
