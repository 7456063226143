import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../Input/input';
import Button from '../Button/button';
import maskHelper from '../../helpers/mask.helper';
import { ToastNotify } from '../Toast/toast';

type options =
  | 'homepage'
  | 'other_homepage'
  | 'facebook'
  | 'instagram'
  | 'obs'
  | 'twitter'
  | 'telegram';

interface SocialMediaValues {
  homepage?: string;
  other_homepage?: string;
  facebook?: string;
  instagram?: string;
  obs?: string;
  twitter?: string;
  telegram?: string;
}

/* eslint-disable no-unused-vars */
interface SocialMediaProps {
  defaultValues?: SocialMediaValues;
  apiFunction: (e: any, id: number) => Promise<any>;
  fields: options[];
  id: number;
  nextPage: () => void;
  setId: (e: number) => void;
  titleButton?: string;
}

const SocialMedia: React.FC<SocialMediaProps> = ({
  defaultValues,
  fields,
  id,
  nextPage,
  setId,
  apiFunction,
  titleButton = 'Salvar e continuar',
}) => {
  const defaultSocialMedia: SocialMediaValues = {
    homepage: '',
    other_homepage: '',
    facebook: '',
    instagram: '',
    obs: '',
    twitter: '',
    telegram: '',
  };
  const [media, setMedia] = useState<SocialMediaValues>(
    defaultValues ?? defaultSocialMedia
  );
  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState } = useForm<SocialMediaValues>({});

  const mediaFields = {
    homepage: (
      <Input
        title="Endereço Web"
        variant="outline-orange"
        value={media.homepage}
        onChange={(e) => {
          setValue('homepage', e.target.value);
          setMedia({ ...media, homepage: e.target.value });
        }}
        errorMensage={formState.errors.homepage?.message}
      />
    ),
    other_homepage: (
      <Input
        title="Outros"
        variant="outline-orange"
        value={media.other_homepage}
        onChange={(e) => {
          setValue('other_homepage', e.target.value);
          setMedia({ ...media, other_homepage: e.target.value });
        }}
        errorMensage={formState.errors.other_homepage?.message}
      />
    ),
    twitter: (
      <Input
        title="Twitter"
        variant="outline-orange"
        value={media.twitter}
        onChange={(e) => {
          setValue('twitter', e.target.value);
          setMedia({ ...media, twitter: e.target.value });
        }}
        errorMensage={formState.errors.twitter?.message}
      />
    ),
    telegram: (
      <Input
        title="Telegram"
        variant="outline-orange"
        value={maskHelper.phone(media.telegram ?? '')}
        onChange={(e) => {
          setValue('telegram', maskHelper.number(e.target.value));
          setMedia({ ...media, telegram: e.target.value });
        }}
        errorMensage={formState.errors.telegram?.message}
      />
    ),
    obs: (
      <div className="col-span-2">
        <p className="text-sm mb-1">Observação</p>
        <textarea
          value={media.obs}
          onChange={(e) => {
            setValue('obs', e.target.value);
            setMedia({ ...media, obs: e.target.value });
          }}
          defaultValue=""
          name="textinput"
          className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
        />
      </div>
    ),
    facebook: (
      <Input
        title="Facebook"
        variant="outline-orange"
        value={media.facebook}
        onChange={(e) => {
          setValue('facebook', e.target.value);
          setMedia({ ...media, facebook: e.target.value });
        }}
        errorMensage={formState.errors.facebook?.message}
      />
    ),
    instagram: (
      <Input
        title="Instagram"
        variant="outline-orange"
        value={media.instagram}
        onChange={(e) => {
          setValue('instagram', e.target.value);
          setMedia({ ...media, instagram: e.target.value });
        }}
        errorMensage={formState.errors.instagram?.message}
      />
    ),
  };

  const Submit = async (values: SocialMediaValues) => {
    setIsLoading(true);
    const response = await apiFunction(values, id);
    if (response.id) {
      setIsLoading(false);
      notify({
        message: 'Redes Sociais salvas com sucesso',
        type: 'Success',
      });
      setId(response.id);
      nextPage();
    } else {
      notify({ message: 'Desculpe, ocorreu um problema', type: 'Error' });
      setIsLoading(false);
    }
  };
  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-4 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      {fields.map((items) => mediaFields[items])}

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {titleButton}
        </Button>
      </div>
    </form>
  );
};

export default SocialMedia;
