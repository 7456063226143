import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal, Switch } from '../../../../components';
import { Whatsapp } from '../types';
import { ApiWhatsapp } from '../api';
import { useFetchWhatsapp } from '../hooks';
import RegisterWhatsapp from '../components/register';
import { dictionaryError } from '../../../../helpers/utils.helper';
import maskHelper from '../../../../helpers/mask.helper';

const WhatsappPlans: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterWhatsapp, setModalRegisterWhatsapp] = useState(false);
  const [input, setInput] = useState('');
  const [whatsappData, setWhatsappData] = useState<Whatsapp | null>(null);
  const [key, setKey] = useState('key');

  const { WhatsappList, isLoadingWhatsapp } = useFetchWhatsapp(key);
  const { notify } = ToastNotify();

  const onSortStatus = (rowA: Whatsapp, rowB: Whatsapp) => {
    const a = rowA.is_active ? 'A' : 'I';
    const b = rowB.is_active ? 'A' : 'I';

    if (a > b) return 1;

    if (b > a) return -1;

    return 0;
  };

  const onUpdateStatus = async (value: Whatsapp) => {
    const res = await ApiWhatsapp.updateWhatsappPlans(
      {
        name: value.name,
        description: value.description,
        qty: value.qty,
        value: value.value,
        is_active: !value.is_active,
        is_trial: value.is_trial,
      },
      value.id
    );
    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Whatsapp) => row.name,
    },
    {
      name: 'Descrição',
      key: 'description',
      selector: (row: Whatsapp) => row.description ?? '--',
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: Whatsapp) => maskHelper.formatMoeda(row.value) ?? '0',
    },
    {
      name: 'Quantidade',
      key: 'qty',
      selector: (row: Whatsapp) => row.qty ?? '0',
    },
    {
      name: 'Status',
      sortFunction: onSortStatus,
      selector: (row: Whatsapp) => (
        <Switch onChange={() => onUpdateStatus(row)} state={row.is_active} />
      ),
    },
    {
      name: '',
      cell: (row: Whatsapp) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color={
            row.is_active || row.companie_whatsapp_plans?.length !== undefined
              ? '#DDD'
              : '#DA3C3C'
          }
          onClick={() => {
            if (
              row.is_active ||
              row.companie_whatsapp_plans?.length !== undefined
            )
              return;

            setWhatsappData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return WhatsappList.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    }
    return WhatsappList.filter(
      (item) => item.name.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingWhatsapp}
        column={columns}
        title="Planos de Whatsapp"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterWhatsapp}
        setRegisterPage={() => setWhatsappData(null)}
        onRowClick={(e: Whatsapp) => {
          setWhatsappData(e);
          setModalRegisterWhatsapp(true);
        }}
      />
      <RegisterWhatsapp
        whatsapp={whatsappData}
        reloadTable={setKey}
        setShow={setModalRegisterWhatsapp}
        show={modalRegisterWhatsapp}
      />
      {whatsappData && (
        <DeleteModal
          apiFunction={ApiWhatsapp.deleteWhatsappPlans}
          notify={notify}
          type="Planos"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={whatsappData.id}
          name={whatsappData.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default WhatsappPlans;
