import { baseApiPrivate } from '../../../api/baseApi';
import {
  accessUser,
  addressData,
  generalData,
  socialMediaData,
  UserProps,
} from '../types';

async function createCompanyGeneralData(information: generalData) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/user',
  });
}

async function updateGeneralData(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/user/${id}`,
  });
}

async function updateAddress(information: addressData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/user/address/${id}`,
  });
}

async function updateSocialMedia(information: socialMediaData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/user/social/${id}`,
  });
}

async function getUsersByCompany(id: number): Promise<UserProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/${id}`,
  });
}

async function deleteUser(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/user/${id}`,
  });
}

async function getAccess(id: number): Promise<accessUser[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/access/${id}`,
  });
}

async function createAccess(information: Omit<accessUser, 'id'>) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/user/access',
  });
}

async function updateAccess(information: Omit<accessUser, 'id'>, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/user/access/${id}`,
  });
}

async function deleteAccess(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/user/access/${id}`,
  });
}

export const ApiUsers = {
  createAccess,
  createCompanyGeneralData,
  deleteUser,
  getAccess,
  getUsersByCompany,
  updateAccess,
  updateAddress,
  updateGeneralData,
  updateSocialMedia,
  deleteAccess,
};
