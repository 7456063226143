import { EncryptStorage } from 'encrypt-storage';

function config() {
  return new EncryptStorage(String(process.env.REACT_APP_STORAGE_KEY), {
    prefix: `@leadsimplus_${process.env.REACT_APP_ENV}`,
    doNotEncryptValues: process.env.REACT_APP_ENV === 'local',
  });
}

export const removeAll = (): void => {
  const encryptStorage = config();
  return encryptStorage.clear();
};

export const setItem = (key: string, value: any): any => {
  const encryptStorage = config();

  try {
    return encryptStorage.setItem(key, value);
  } catch (e: any) {
    document.location.reload();
    removeAll();
    return false;
  }
};

export const removeItem = (key: string): any => {
  const encryptStorage = config();

  try {
    return encryptStorage.removeItem(key);
  } catch (e: any) {
    document.location.reload();
    removeAll();
    return false;
  }
};

export const getItem = (key: string): any => {
  const encryptStorage = config();

  try {
    return encryptStorage.getItem(key);
  } catch (e: any) {
    document.location.reload();
    removeAll();
    return false;
  }
};
