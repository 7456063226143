import { useEffect, useState } from 'react';
import { ApiResponseWinLose } from '../api';
import { ResponseWinLose } from '../types';

export function useFetchResponses(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ResponseWinLose[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const segment = await ApiResponseWinLose.getResponsesByCompany(companyId);
      if (typeof segment !== 'string') {
        setData(segment);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ResponsesList: data, isLoading };
}
