import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../main/components/mainPage';
import { ToastNotify } from '../../../components/Toast/toast';
import { Button, DeleteModal, Modal } from '../../../components';
import { Product } from '../types';
import { ApiProduct } from '../api';
import RegisterProduct from '../components/register';
import { useFetchAllProducts } from '../hooks';
import maskHelper from '../../../helpers/mask.helper';

const ProductsCompanie: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalRegisterProduct, setModalRegisterProduct] = useState(false);
  const [input, setInput] = useState('');
  const [profileData, setProductData] = useState<Product | null>(null);
  const [key, setKey] = useState('key');

  const { ProductsList, isLoading } = useFetchAllProducts(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: Product) => row.id,
    },
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Product) => row.title,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: Product) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Status',
      key: 'title',
      selector: (row: Product) =>
        row.is_active ? (
          <p className="text-xs text-green">Ativo</p>
        ) : (
          <p className="text-xs text-gray-600">Inativo</p>
        ),
    },
    {
      name: 'Tipo',
      key: 'is_product',
      selector: (row: Product) =>
        row.is_product ? (
          <p className="text-xs">Produto</p>
        ) : (
          <p className="text-xs">Serviço</p>
        ),
    },
    {
      name: '',
      cell: (row: Product) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            if (row.opportunity_products.length > 0) {
              setShowModalInfo(true);
            } else {
              setProductData(row);
              setShowModalDelete(true);
            }
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return ProductsList;
    }
    return ProductsList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <Modal
        title="Não é possível excluir o produto"
        setIsOpen={() => setShowModalInfo(false)}
        isOpen={showModalInfo}
        size="medium"
        minHeight="100px"
      >
        <p className="text-sm">
          Este produto está vinculado a uma ou mais oportunidades e não pode ser
          excluído.
        </p>
        <Button className="w-full mt-5" onClick={() => setShowModalInfo(false)}>
          Ok, entendi!
        </Button>
      </Modal>
      <MainPage
        isLoading={isLoading}
        column={columns}
        title="Produtos"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterProduct}
        setRegisterPage={() => {
          setProductData(null);
        }}
        onRowClick={(e: Product) => {
          setProductData(e);
          setModalRegisterProduct(true);
        }}
        sizeIcon={24}
      />
      <RegisterProduct
        product={profileData}
        reloadTable={setKey}
        setShow={setModalRegisterProduct}
        show={modalRegisterProduct}
      />
      {profileData && (
        <DeleteModal
          apiFunction={ApiProduct.deleteProduct}
          notify={notify}
          type="Produto"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={profileData.id}
          name={profileData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default ProductsCompanie;
