/* eslint-disable no-unused-vars */
import { notify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ApiUsers } from '../api';
import { UserProps } from '../types';

export const ChangeStatusUser = async (
  currentUserId: number,
  status: boolean,
  userProps: UserProps,
  setIsLoading: (e: boolean) => void,
  setKey: (e: string) => void
) => {
  setIsLoading(true);
  const res = await ApiUsers.updateGeneralData(
    {
      email: userProps.email,
      name: userProps.name,
      profile_id: userProps.profile_id,
      is_active: status,
    },
    currentUserId
  );
  if (res.id) {
    notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
    setKey(`${Math.random()} status`);
  } else {
    notify({ message: dictionaryError(res), type: 'Error' });
    setIsLoading(false);
  }
};
