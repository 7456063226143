import { baseApiPrivate } from '../../../api/baseApi';
import {
  AnnotationProps,
  contactActivityProps,
  createActivityContact,
  createUpdateAnnotation,
  generalDataContact,
  payloadBackContact,
} from '../types';

async function addTagContact(information: {
  person_id: number;
  tag_id: number;
}) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/personTag',
  });
}

async function removeTagContact(personTagId: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/personTag/${personTagId}`,
  });
}

async function getTagContact(personTagId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/personTag/person/${personTagId}`,
  });
}

async function createContact(information: generalDataContact) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/person',
  });
}

async function updateContact(
  information: Omit<generalDataContact, 'companie_user_id'>,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/person/${id}`,
  });
}

async function searchContacts(
  skip: number,
  take: number,
  key: string
): Promise<payloadBackContact | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/person/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function getContactByCompany(
  id: number,
  skip: number,
  take: number
): Promise<payloadBackContact | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/person/${id}/${skip}/${take}`,
  });
}
async function getContactByCompanyFilter(
  payload: any,
  period: string
): Promise<payloadBackContact | string> {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/person/filterAdvanced/${period}`,
    body: payload,
  });
}

async function deleteContact(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/person/${id}`,
  });
}

async function createAnnotation(information: createUpdateAnnotation) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/person/annotation',
  });
}

async function createImport(file: any) {
  return baseApiPrivate({
    body: file,
    file: true,
    method: 'POST',
    url: 'companie/captured/lead/import/person',
  });
}

async function updateAddress(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/person/address/${id}`,
  });
}

async function updateAnnotation(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/person/annotation/${id}`,
  });
}

async function deleteAnnotation(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/person/annotation/${id}`,
  });
}

async function getAnnotationsByContact(
  id: number
): Promise<AnnotationProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/person/annotation/${id}`,
  });
}

async function getActivitiesByPerson(
  id: number
): Promise<contactActivityProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/activities/person/${id}`,
  });
}

async function createActivities(information: createActivityContact) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/person/activities',
  });
}

async function updateActivity(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/person/activities/${id}`,
  });
}

async function deleteActivity(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/activities/${id}`,
  });
}

export const ApiContact = {
  addTagContact,
  removeTagContact,
  getTagContact,
  createContact,
  updateAddress,
  deleteContact,
  getContactByCompany,
  searchContacts,
  updateContact,
  createActivities,
  createAnnotation,
  updateAnnotation,
  updateActivity,
  deleteActivity,
  getActivitiesByPerson,
  deleteAnnotation,
  getAnnotationsByContact,
  getContactByCompanyFilter,
  createImport,
};
