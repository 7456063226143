import React from 'react';
import IconDictionary from '../Icons/icons';

const PageInvalidLink: React.FC = () => (
  <div className="w-full h-screen bg-gray-200/60 flex flex-col items-center pt-20">
    <div className="flex items-center gap-3">
      <IconDictionary name="Link invalido" size={36} color="#541554" />
      <h2 className="text-xl font-semibold uppercase">Link inválido</h2>
    </div>
    <p className="text-center text-sm mt-1">
      Não foi possível acessar a página. O endereço acessado não é válido <br />
      por favor, tente novamente usando outro link.
    </p>
  </div>
);

export default PageInvalidLink;
