import React, { useRef } from 'react';

import moment from 'moment';
import { io } from 'socket.io-client';

import classNames from 'classnames';
import maskHelper from '../../../../helpers/mask.helper';

import { Spinner } from '../../../../components';
import { ApiOpportunity } from '../../api';
import { useIntersectionObserver } from '../../hooks';
import { getTextColorByOpacity } from '../../../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { useTheme } from '../../../main/hooks';

interface Props {
  messages: Array<any>;
  whatsappPhone: string;
  instanceId: number | null;
  totalMessages: number;
}

const socket = getTokenAccountInformation()
  ? io(`${process.env.REACT_APP_API}`, {
      transports: ['websocket'],
    })
  : null;

const format = (phone: string) =>
  maskHelper.phone(`${phone.substring(2, 4)}9${phone.slice(4, 12)}`);

const fetchMessages = async (whatsapp: string, skip = '0', messages = '0') => {
  const res = await ApiOpportunity.getOpportunityMessage(
    whatsapp,
    skip,
    messages
  );

  return res.data;
};

export const Messages: React.FC<Props> = ({
  messages,
  whatsappPhone,
  instanceId,
  totalMessages,
}) => {
  const accoutProps = getTokenAccountInformation();
  const { theme } = useTheme(accoutProps.companie_id);
  const messageEl = useRef<any>(null);

  const [loadingOpacity, setLoadingOpacity] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingAtInput, setLoadingAtInput] = React.useState(true);
  const [messagesChat, setMessagesChat] = React.useState(messages);
  const [pages, setPages] = React.useState({ page: 1, take: 20 });

  const whatsapp = `${whatsappPhone.substring(0, 4)}${whatsappPhone.substring(
    5,
    13
  )}`;

  // eslint-disable-next-line consistent-return
  const lastMessageRef = useIntersectionObserver<HTMLLIElement>(() => {
    if (loading || loadingAtInput || messagesChat.length >= totalMessages) {
      return null;
    }
    setLoading(true);

    fetchMessages(whatsappPhone, `${pages.page}`, `${pages.take}`)
      .then((data) => setMessagesChat((prev) => [...data, ...prev]))
      .finally(() => {
        setPages({ page: pages.page + 1, take: 20 });

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        messageEl.current.addEventListener('DOMNodeInserted', (event: any) => {
          const { currentTarget: target } = event;
          target.scroll({
            top: 60 * 20 + 105,
          });
        });
      });
  }, []);

  React.useEffect(() => {
    if (!socket) return;
    setLoading(true);

    setTimeout(() => {
      if (messageEl && messageEl.current) {
        messageEl.current.scrollTop = 20000;
      }
    }, 2000);
    messageEl.current.scrollIntoView({ behavior: 'smooth' });

    messageEl.current.addEventListener('DOMNodeInserted', (event: any) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    });

    const room = `${whatsapp}_person`;
    socket.emit('set_room', room);
    socket.on(room, (evt) => setMessagesChat((prev) => [...prev, evt]));

    setTimeout(() => {
      setLoading(false);
      setLoadingAtInput(false);
      setLoadingOpacity(false);
    }, 3000);
  }, []);

  return (
    <div ref={messageEl} className="w-full h-[350px] overflow-auto">
      {(loading || loadingAtInput) && (
        <div
          className={classNames('flex justify-center', {
            'absolute top-0 bottom-0 left-0 right-0': loadingAtInput,
          })}
        >
          <Spinner />
        </div>
      )}
      {messagesChat.length > 0 &&
        messagesChat.map((mes, index, row) => (
          <div
            key={mes.id}
            // @ts-ignore
            ref={
              row.length - (row.length - 1) === index ? lastMessageRef : null
            }
            className={`flex relative flex-col justify-center ${
              whatsapp === mes.remote_j_id ? 'items-end' : 'items-start'
            } ${loadingOpacity ? 'opacity-0' : 'opacity-100'}`}
          >
            <div
              className={`relative flex flex-col justify-center max-w-[500px] min-h-[40px] p-2 mb-2 rounded-lg ${
                whatsapp === mes.remote_j_id
                  ? 'bg-primary mr-2'
                  : 'bg-gray ml-2'
              }`}
              style={{
                color:
                  whatsapp === mes.remote_j_id
                    ? getTextColorByOpacity(theme.color_hex)
                    : '#000',
              }}
            >
              {mes.reaction_emmoticon_sender && (
                <p className="absolute flex justify-center w-[25px] bottom-[-15px] right-[10px] bg-gray rounded-lg border-4 border-black-500">
                  {mes.reaction_emmoticon_sender}
                </p>
              )}
              <p className="flex text-xs font-semibold">
                {mes?.push_name} - {format(mes.sender)}
              </p>
              {mes.message_type === 'imageMessage' ? (
                <a
                  href={`${process.env.REACT_APP_API}${mes.path}`}
                  target="_blank"
                  className="max-w-[200px]"
                  rel="noreferrer"
                >
                  <img
                    className="max-w-[200px]"
                    src={`${process.env.REACT_APP_API}${mes.path}`}
                    alt="message"
                  />
                  <p className="text-xs mt-[2px]">{mes.message_conversation}</p>
                </a>
              ) : mes.message_type === 'documentMessage' ? (
                <a
                  href={`${process.env.REACT_APP_API}${mes.path}`}
                  target="_blank"
                  className="max-w-[200px]"
                  rel="noreferrer"
                >
                  <img
                    className="max-w-[100px]"
                    src="https://www.svgrepo.com/show/144578/pdf.svg"
                    alt="message"
                  />
                  <p className="text-xs mt-[2px]">{mes.message_conversation}</p>
                </a>
              ) : mes.message_type === 'videoMessage' ? (
                <a
                  href={`${process.env.REACT_APP_API}${mes.path}`}
                  target="_blank"
                  className="max-w-[400px]"
                  rel="noreferrer"
                >
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    style={{ maxHeight: 350, borderRadius: 5 }}
                    src={`${process.env.REACT_APP_API}${mes.path}`}
                  />
                  <p className="text-xs mt-[2px]">{mes.message_conversation}</p>
                </a>
              ) : (
                <p className="text-xs">{mes.message_conversation}</p>
              )}
              <p className="flex w-full justify-end text-xs">
                {moment(mes.created_at).format('LT')}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};
