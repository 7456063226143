import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { sendPIX } from '../api';
import {
  Button,
  Card,
  HeaderPages,
  Input,
  SelectComponent,
  TextArea,
  Modal,
} from '../../../components';
import { IPix } from '../types';
import { notify } from '../../../components/Toast/toast';
import IconDictionary from '../../../components/Icons/icons';
import maskHelper from '../../../helpers/mask.helper';
import { dictionaryError } from '../../../helpers/utils.helper';

const PaymentPix: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<IPix | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    type: yup.string().required('Tipo de Chave é obrigatório'),
    value: yup
      .string()
      .required('Valor é obrigatório')
      .test(
        'is-not-zero',
        'O valor não pode ser zero',
        (value) =>
          !!value && !Number.isNaN(parseFloat(value)) && parseFloat(value) > 0
      ),
    key: yup
      .string()
      .required('Chave é obrigatória')
      .when('type', {
        is: 'email',
        then: yup
          .string()
          .matches(
            /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
            'Formato de e-mail inválido'
          ),
        otherwise: yup.string(),
      })
      .when('type', {
        is: 'random',
        then: yup
          .string()
          .min(32, 'Chave aleatória possui um mínimo de 32 caracteres'),
        otherwise: yup.string(),
      }),
    desc: yup.string(),
  });

  const { handleSubmit, control, formState, reset, watch } = useForm<IPix>({
    resolver: yupResolver(schema),
  });

  const selectedType = watch('type');
  const pixOptions = [
    { label: 'CPF', value: 'cpf' },
    { label: 'CNPJ', value: 'cnpj' },
    { label: 'E-mail', value: 'email' },
    { label: 'Telefone', value: 'mobilePhone' },
    { label: 'Chave Aleatória', value: 'random' },
  ];

  const selectTypes = {
    email: 'E-mail',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    mobilePhone: 'Telefone',
    random: 'Chave Aleatória',
  };

  const cleanCNPJCPF = (p: string) => p.replace(/\D/g, '');

  const applyMask = (type: string, value: string) => {
    switch (type) {
      case 'cpf':
        return maskHelper.cpf(value);
      case 'cnpj':
        return maskHelper.cnpj(value);
      case 'mobilePhone':
        return maskHelper.number(value);
      default:
        return value;
    }
  };

  const onSubmit = (data: IPix) => {
    setFormData(data);
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (formData) {
      const valueWithoutDots = formData.value.replace('.', '');
      if (formData.type === 'cpf' || formData.type === 'cnpj') {
        formData.key = cleanCNPJCPF(formData.key);
      }
      const updatedFormData = {
        ...formData,
        value: Number(valueWithoutDots),
      };
      const response = await sendPIX(updatedFormData);
      setIsModalOpen(false);
      notify({
        message: !response.ok
          ? dictionaryError(response.toString())
          : 'PIX enviado com sucesso!',
        type: !response.ok ? 'Error' : 'Success',
      });
      reset();
      reset({
        type: 'cpf',
      });
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string) => void
  ) => {
    let inputValue = e.target.value.replace(/\D/g, '');
    if (inputValue.length > 0) {
      inputValue = parseFloat(inputValue).toString();
    }
    while (inputValue.length < 3) {
      inputValue = `0${inputValue}`;
    }
    const integerPart = inputValue.slice(0, -2);
    const decimalPart = inputValue.slice(-2);
    const formattedValue = `${integerPart}.${decimalPart}`;
    onChange(formattedValue);
  };

  return (
    <>
      <Modal
        title="Confirmar envio de PIX"
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        minHeight="max-w-md"
      >
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-4">
            Você tem certeza que deseja enviar o PIX para os dados abaixo?
          </h3>
          {formData && (
            <div className="mb-4 w-full">
              <p>
                <span className="font-bold">Tipo de Chave:</span>{' '}
                {selectTypes[formData.type as keyof typeof selectTypes]}
              </p>
              <p>
                <span className="font-bold">Chave:</span> {formData.key}
              </p>
              <p>
                <span className="font-bold">Valor:</span>{' '}
                {`${maskHelper.formatMoeda(formData.value)}`}
              </p>
              <p>
                <span className="font-bold">Descrição:</span> {formData.desc}
              </p>
            </div>
          )}
          <p className="mb-6">
            Esta ação não pode ser desfeita. O PIX será efetivado.
          </p>
          <div className="flex justify-around w-full">
            <Button
              disabled={isLoading}
              onClick={handleConfirm}
              className="bg-green-500 text-white text-lg px-4 py-3 rounded flex-1 mr-2"
            >
              Sim
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleCancel}
              className="bg-gray-300 text-gray-700 text-lg px-4 py-3 rounded flex-1 ml-2"
            >
              Não
            </Button>
          </div>
        </div>
      </Modal>

      <div className="mb-8">
        <HeaderPages title="Pagamento via PIX" />
      </div>
      <Card className="flex flex-col items-center px-4 md:px-10 lg:px-64 py-10 bg-white rounded min-h-[53.5rem]">
        <div className="flex flex-col items-center w-full">
          <div className="rounded px-3 py-5 flex items-center justify-center gap-3 w-full h-16 border border-dashed border-red mb-5">
            <IconDictionary
              size={28}
              name="AlertTriangle"
              className="text-red"
            />
            <p className="text-sm">
              Permite realizar um Pix para uma chave Pix de mesma titularidade.
            </p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full"
        >
          <Controller
            name="type"
            control={control}
            defaultValue="CPF"
            render={({ field: { onChange, value, ref, ...field } }) => (
              <SelectComponent
                {...field}
                options={pixOptions}
                title="Tipo de Chave"
                className="min-w-0 md:min-w-full"
                errorMensage={formState.errors.type?.message}
                onChange={(option: { label: string; value: string }) =>
                  onChange(option.value)
                }
                value={pixOptions.find((option) => option.value === value)}
              />
            )}
          />
          <Controller
            name="key"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, ...field } }) => (
              <Input
                {...field}
                title="Chave"
                variant="outline-orange"
                className="min-w-0 md:min-w-full"
                placeholder="Insira a sua chave"
                errorMensage={formState.errors.key?.message}
                value={applyMask(selectedType, value)}
                onChange={(e) => {
                  const maskedValue = applyMask(selectedType, e.target.value);
                  onChange(maskedValue);
                }}
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            defaultValue="0.00"
            render={({ field: { onChange, value, ...field } }) => (
              <Input
                {...field}
                title="Valor"
                variant="outline-orange"
                className="min-w-0 md:min-w-full"
                placeholder="R$ 0.00"
                errorMensage={formState.errors.value?.message}
                value={maskHelper.formatMoeda(value)}
                onChange={(e) => handleValueChange(e, onChange)}
              />
            )}
          />
          <div className="md:col-span-3">
            <Controller
              name="desc"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextArea
                  {...field}
                  title="Descrição"
                  className="min-w-0 md:min-w-full"
                  errorMensage={formState.errors.desc?.message}
                />
              )}
            />
          </div>
          <div className="md:col-span-3 flex justify-center mt-7">
            <Button type="submit" className="px-10">
              Enviar Pix
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default PaymentPix;
