import { useEffect, useState } from 'react';
import { ApiWhatsappCampaign } from '../api';
import { PersonWhatsAppCampaign, whatsappCampaignProps } from '../types';
import { OptionsSelectProp } from '../../../components/Select/select';

export function useFetchWhatsappCampaign(
  reload: string,
  skip: number,
  take: number,
  key: string,
  date: string,
  status: OptionsSelectProp[]
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    data: whatsappCampaignProps[];
    total: number;
  }>({ data: [], total: 0 });
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      if (key.length < 3) {
        const res = await ApiWhatsappCampaign.getWhatsappCampaignFilterAdvanced(
          {
            skip,
            take,
            date,
            status: status.map((item) => item.value),
          }
        );
        if (typeof res !== 'string') {
          setData(res);
        }
      } else {
        const res = await ApiWhatsappCampaign.getWhatsappCampaignAutocomplete(
          key,
          skip,
          take
        );
        if (typeof res !== 'string') {
          setData(res);
        }
      }

      setIsLoading(false);
    })();
  }, [skip, take, reload, status, date, key]);
  return { WhatsappCampaignList: data, isLoadingWhatsapp: isLoading };
}

export function useFetchWhatsappCampaignDropdown(
  skip: number,
  take: number,
  key: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    data: whatsappCampaignProps[];
    total: number;
  }>({ data: [], total: 0 });
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      if (key.length > 3) {
        const res = await ApiWhatsappCampaign.getWhatsappCampaignAutocomplete(
          key,
          skip,
          take
        );
        if (typeof res !== 'string') {
          setData(res);
        }
      } else {
        const res = await ApiWhatsappCampaign.getWhatsappCampaignAutocomplete(
          key,
          0,
          10
        );
        if (typeof res !== 'string') {
          setData(res);
        }
      }

      setIsLoading(false);
    })();
  }, [skip, take, key]);
  return { WhatsappCampaignList: data, isLoadingWhatsappDropdown: isLoading };
}

export function useFetchWhatsappCampaignPerson(
  key: string,
  campaignId: number,
  skip: number,
  take: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PersonWhatsAppCampaign>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const companies = await ApiWhatsappCampaign.getWhatsappCampaignPerson(
        campaignId,
        skip,
        take
      );
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    })();
  }, [campaignId, key, skip, take]);
  return { PersonWhatsappCampaignList: data, isLoadingWhatsapp: isLoading };
}

export function useFetchWhatsappTriggeredCampaigns(
  key: string,
  skip: number,
  take: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    data: whatsappCampaignProps[];
    total: number;
  }>({ data: [], total: 0 });
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const res = await ApiWhatsappCampaign.getWhatsappCampaignStatus(
        'shooting',
        skip,
        take
      );
      if (typeof res !== 'string') {
        setData(res);
      }

      setIsLoading(false);
    })();
  }, [skip, take, key]);
  return {
    WhatsappCampaignList: data,
    isLoadingWhatsappList: isLoading,
    setIsLoading,
  };
}
