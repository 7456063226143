import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { ToastNotify } from '../../../components/Toast/toast';
import {
  Card,
  DeleteModal,
  Input,
  SelectComponent,
  Spinner,
} from '../../../components';
import { OfferProps, filtersOfferProps } from '../types';
import { ApiOffer } from '../api';
import { useFetchOffer } from '../hooks';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import IconDictionary from '../../../components/Icons/icons';
import maskHelper from '../../../helpers/mask.helper';
import RegisterOffer from '../components/register';
import SearchOffers from '../components/filters';
import { useFetchOpportunityDropdown } from '../../opportunity/hooks';
import { useFetchClientDropdown } from '../../clients/hooks';
import { useFetchUsersCompanie } from '../../users/hooks';
import { dictionaryError, getUrlPlatform } from '../../../helpers/utils.helper';
import SendWhatsApp from '../../opportunity/components/update/components/sendWhatsapp';
import SendEmailModal from '../../opportunity/components/update/components/email';
import { useFetchAllProductsActive } from '../../product/hooks';
import RegisterContract from '../../contract/components/registerContract';
import { useFetchPlansAccount } from '../../chartAccounts/hooks';
import { contractProps } from '../../contract/types';
import { useFetchSegment } from '../../segment/hooks';
import { useFetchGalaxyMethods } from '../../contract/hooks';

const Offers: React.FC = () => {
  const accountProps = getTokenAccountInformation();

  const { SegmentList } = useFetchSegment('', accountProps.companie_id);
  const optionsSegment = SegmentList.map((item) => ({
    label: item.title,
    value: item.title,
  }));
  const { notify } = ToastNotify();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterOffer, setModalRegisterOffer] = useState(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState(-1);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [showCreateContract, setShowCreateContract] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalWhatsApp, setShowModalWhatsApp] = useState(false);
  const [key, setKey] = useState('');

  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Cliente',
    page: 0,
  });
  const [currentOffer, setCurrentOffer] = useState<OfferProps | null>(null);
  const statusOptions = [
    { label: 'Aberta', value: 'Aberta' },
    { label: 'Cancelada', value: 'Cancelada' },
    { label: 'Em Negociação', value: 'Em Negociação' },
    { label: 'Fechada', value: 'Fechada' },
    { label: 'Perdida', value: 'Perdida' },
  ];
  const statusColor = {
    Aberta: '#000000',
    Cancelada: '#dadada',
    'Em Negociação': '#FFD700',
    Fechada: '#33A70A',
    Perdida: '#DA3C3C',
  };
  async function downloadPdf(
    contractId: number,
    contractNumber: string,
    clientName: string
  ) {
    setIsLoadingPDF(contractId);
    const download = document.createElement('a');
    const res = await ApiOffer.getPdfOffer(contractId);
    const blob = new Blob([res]);
    const url = window.URL.createObjectURL(blob);
    download.href = url;
    download.download = `${clientName}-${contractNumber}.pdf`;
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
    setIsLoadingPDF(-1);
  }
  const changeStatus = async (
    id: number,
    status: 'Em Negociação' | 'Aberta' | 'Cancelada' | 'Fechada' | 'Perdida'
  ) => {
    setIsLoadingStatus(true);
    const res = await ApiOffer.updateStatusOffer({ status }, id);
    if (res.id) {
      if (showCreateContract === true) return res;
      setKey(String(Math.random()));
      notify({ message: 'Status alterado com sucesso!', type: 'Success' });
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoadingStatus(false);
    return false;
  };
  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalFilter, setModalFilter] = useState(false);

  const { UsersList } = useFetchUsersCompanie('', accountProps.companie_id);
  const userOptions = UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const dateFilterOptions = [
    { value: 'all', label: 'Todas' },
    { value: 'today', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'this_week', label: 'Esta semana' },
    { value: 'last_week', label: 'Semana passada' },
    { value: 'this_month', label: 'Este mês' },
    { value: 'last_30_days', label: 'Últimos 30 dias' },
    { value: 'other_period', label: 'Outro intervalo' },
  ];
  const [inputClient, setInputClient] = useState('');
  const { ClientsList, isLoadingClientsDropDown } = useFetchClientDropdown(
    '',
    0,
    100000000,
    inputClient
  );
  const clientsOptions = ClientsList.data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [filterDate, setFilterDate] = useState('this_month');
  const [offerInput, setOfferInput] = useState('');
  const [opportunityInput, setOpportunityInput] = useState('');
  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });
  const [filterModalOffer, setFilterModalOffer] = useState<filtersOfferProps>({
    personId: [],
    opportunityId: [],
    companieUserId: [],
    order: 'created_at_desc',
    status: 'Todos',
    filter: filterDate,
    date_end: '',
    date_start: '',
    skip: startData,
    take: 10,
  });
  const { OfferList, isLoading } = useFetchOffer(
    key,
    filterModalOffer,
    offerInput
  );
  const [contractData, setContractData] = useState<contractProps | null>(null);

  const { methods } = useFetchGalaxyMethods();

  const { PlanList } = useFetchPlansAccount('');
  const { OpportunityList, isLoadingOpportunityList } =
    useFetchOpportunityDropdown(opportunityInput);
  const opportunityOptions = OpportunityList.data.map((item) => ({
    label: item.title,
    value: item.id,
  }));
  const { ProductsList } = useFetchAllProductsActive('key');
  const productOptions = ProductsList.map((item) => ({
    value: item.id,
    label: item.title,
    price: item.value,
  }));
  const [registerPageContract, setRegisterPageContract] = useState({
    title: 'Dados Cliente',
    page: 0,
    isCreate: true,
  });
  const [modalCreateUpdateContract, setModalCreateUpdateContract] =
    useState(false);

  const columns = [
    {
      name: 'Número',
      key: 'number',
      minWidth: '70px',
      selector: (row: OfferProps) => row.number,
    },
    {
      name: 'Proprietário',
      minWidth: '110px',
      key: 'person_name',
      selector: (row: OfferProps) => row.person_name,
    },
    {
      name: 'Valor',
      minWidth: '70px',
      key: 'person_name',
      selector: (row: OfferProps) => maskHelper.formatMoeda(row.total),
    },
    {
      name: 'Criado em',
      key: 'created_at',

      selector: (row: OfferProps) => maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: 'Alterado em',
      minWidth: '110px',
      key: 'updated_at',
      selector: (row: OfferProps) => maskHelper.formatDateDMY(row.updated_at),
    },
    {
      name: 'Status',
      key: 'updated_at',
      minWidth: '160px',
      cell: (row: OfferProps) => (
        <div className="overflow-visible py-1">
          <SelectComponent
            menuPosition="fixed"
            colorInputText={statusColor[row.status]}
            classNameDiv="mt-1.5"
            className="w-36"
            defaultValue={statusOptions.find(
              (item) => item.value === row.status
            )}
            options={statusOptions}
            onChange={(e: any) => {
              if (e.value === 'Fechada') {
                setCurrentOffer(row);
                setShowCreateContract(true);
              } else {
                changeStatus(row.id, e.value);
              }
            }}
          />
        </div>
      ),
    },
    {
      name: '',
      key: '',
      minWidth: '115px',
      cell: (row: OfferProps) => (
        <div className="flex w-full gap-2 justify-between">
          <IconDictionary
            name="Adicionar"
            title="Contrato"
            size={20}
            className={
              row.contract.length > 0
                ? 'cursor-pointer w-fit'
                : 'cursor-default text-gray-400'
            }
            onClick={() => {
              if (row.contract.length > 0) {
                setRegisterPageContract({
                  page: 0,
                  title: 'Dados Cliente',
                  isCreate: false,
                });
                setContractData(row.contract[0]);
                setModalCreateUpdateContract(true);
              }
            }}
          />
          {isLoadingPDF === row.id ? (
            <Spinner size="w-[13px] h-[20px]" />
          ) : (
            <IconDictionary
              title="Salvar como PDF"
              name="PDF"
              size={20}
              className="cursor-pointer w-fit"
              onClick={() => downloadPdf(row.id, row.number, row.person_name)}
            />
          )}
          <IconDictionary
            title="Enviar por Whatsapp"
            name="Whatsapp"
            size={20}
            className="cursor-pointer w-fit"
            onClick={() => {
              setCurrentOffer(row);
              setShowModalWhatsApp(true);
            }}
          />
          <IconDictionary
            title="Enviar por e-mail"
            name="Email"
            size={20}
            className="cursor-pointer w-fit"
            onClick={() => {
              setCurrentOffer(row);
              setShowModalEmail(true);
            }}
          />
          <IconDictionary
            title="Excluir Proposta"
            name="Excluir"
            size={20}
            className={
              row.contract.length === 0
                ? 'cursor-pointer w-fit text-red'
                : 'cursor-default text-gray-400'
            }
            onClick={() => {
              if (row.contract.length === 0) {
                setShowModalDelete(true);
                setCurrentOffer(row);
              }
            }}
          />
        </div>
      ),
    },
  ];

  const generateCsv = async () => {
    const res = await ApiOffer.getOfferFilters(filterModalOffer);
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Número',
          'Proprietário',
          'Valor',
          'Criado em',
          'Alterado em',
          'Status',
        ],
        ...res.data.map((item: OfferProps) => [
          item.number,
          item.person_name,
          item.total,
          maskHelper.formatDateDMY(item.created_at ?? ''),
          maskHelper.formatDateDMY(item.updated_at ?? ''),
          item.status,
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  const searchOffer = async (value: string) => {
    setStartData(0);
    setCurrentPage(1);
    setOfferInput(value);
  };

  const restartPagination = () => {
    setStartData(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilterModalOffer({ ...filterModalOffer, skip: startData });
  }, [startData]);

  return (
    <>
      <RegisterContract
        galaxyFormPayments={methods}
        optionsSegment={optionsSegment}
        productsOptions={productOptions}
        setContract={setContractData}
        PlanList={PlanList}
        usersOptions={userOptions}
        contract={contractData}
        reloadTable={setKey}
        registerPage={registerPageContract}
        setRegisterPage={setRegisterPageContract}
        setShow={setModalCreateUpdateContract}
        show={modalCreateUpdateContract}
      />
      <SearchOffers
        setInput={setOfferInput}
        isLoadingDropdownOpportunity={isLoadingOpportunityList}
        setInputOpportunity={setOpportunityInput}
        isLoadingClients={isLoadingClientsDropDown}
        setClientInput={setInputClient}
        profileId={accountProps.profile_id}
        restartPagination={() => {
          setStartData(0);
          setCurrentPage(1);
          setOfferInput('');
        }}
        period={filterDate}
        filters={filterModalOffer}
        setFilter={setFilterModalOffer}
        opportunityList={opportunityOptions}
        userOptions={userOptions}
        clientsOptions={clientsOptions}
        show={modalFilter}
        setShow={setModalFilter}
      />
      {currentOffer && (
        <>
          <SendWhatsApp
            messageText={`Olá ${
              currentOffer.person_name
            }, tudo bem? %0A%0A*Aqui está o link da proposta conforme combinado:* ${getUrlPlatform()}offer/pdf/${
              currentOffer.id
            } %0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`}
            number={currentOffer.person_whatsapp}
            setOpen={setShowModalWhatsApp}
            show={showModalWhatsApp}
          />
          <SendEmailModal
            offer={currentOffer}
            close={() => setShowModalEmail(false)}
            id={currentOffer.id}
            open={showModalEmail}
          />
        </>
      )}
      {currentOffer && (
        <RegisterOffer
          personOpportunity={[]}
          isCreate={false}
          opportunityId={currentOffer.opportunity_id}
          productsOptions={productOptions}
          reloadTable={setKey}
          setRegisterPage={setRegisterPage}
          setOffer={setCurrentOffer}
          registerPage={registerPage}
          setShow={setModalRegisterOffer}
          show={modalRegisterOffer}
          offer={currentOffer}
        />
      )}
      <MainPagePagination
        totalRegisters={OfferList.total}
        startDate={setStartData}
        setCurrentPageTable={setCurrentPage}
        currentPageTable={currentPage}
        search={searchOffer}
        isLoading={isLoading || isLoadingStatus}
        column={columns}
        title="Propostas"
        filterTable={() => OfferList.data}
        setModalCreateUpdate={setModalRegisterOffer}
        setRegisterPage={() => {
          setCurrentOffer(null);
        }}
        FilterComponent={
          <Card className="flex-col col-span-2 w-full pt-5 px-4 pb-3.5 gap-4">
            <strong>Pesquisar</strong>
            <div className="flex flex-col xl:flex-row gap-2">
              <div className="flex w-full flex-col sm:flex-row gap-2">
                <Input
                  value={offerInput}
                  onChange={(e) => searchOffer(e.target.value)}
                  icon={
                    <IconDictionary
                      name="Lupa"
                      size={18}
                      color="#8C8C8C"
                      className="mr-4"
                    />
                  }
                  placeholder="Pesquisar por Número, Proprietário e Status"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 w-full mt-2 md:mt-0 lg:w-fit">
                <SelectComponent
                  variant="outline-primary"
                  icon="Calendario"
                  value={dateFilterOptions.find(
                    (item) => item.value === filterDate
                  )}
                  className="w-full sm:w-48 lg:w-48 2xl:w-60"
                  options={dateFilterOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    if (e.value !== 'other_period') {
                      setDatePicker({ open: false });
                      setFilterDate(e.value);
                      restartPagination();
                    } else {
                      setDatePicker({ open: true });
                    }
                  }}
                  maxOptionsHeight="300px"
                />
                {datePicker.open && (
                  <div className="flex mb-1.5">
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="start_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, start: e });
                        if (typeof datePicker.end !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}/${maskHelper.formatDateYMD(
                              datePicker.end?.toLocaleDateString('pt-br') ?? ''
                            )}`
                          );
                          restartPagination();
                        }
                      }}
                      value={datePicker.start}
                    />
                    <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                      Até
                    </p>
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="end_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, end: e });
                        if (typeof datePicker.start !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              datePicker.start?.toLocaleDateString('pt-br') ??
                                ''
                            )}/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}`
                          );
                          restartPagination();
                        }
                      }}
                      value={datePicker.end}
                      minDate={datePicker.start}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="xl:hidden w-12 h-11 rounded-lg md:place-self-start items-center justify-center cursor-pointer text-primary border-primary border-solid border"
                  onClick={() => setModalFilter(true)}
                >
                  <IconDictionary name="Filtro" size={16} />
                </button>
              </div>
              <button
                type="button"
                className="hidden xl:flex min-w-[44px] h-11 rounded-lg justify-center items-center cursor-pointer text-primary border-primary border-solid border"
                onClick={() => setModalFilter(true)}
              >
                <IconDictionary name="Filtro" size={16} />
              </button>
            </div>
          </Card>
        }
        onRowClick={(e: OfferProps) => {
          setCurrentOffer(e);
          setRegisterPage({ page: 0, title: 'Dados Cliente' });
          setModalRegisterOffer(true);
        }}
        filename="propostas"
        objectReport={generateCsv}
      />

      {currentOffer && (
        <DeleteModal
          apiFunction={ApiOffer.deleteOffer}
          notify={notify}
          type="Proposta"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={currentOffer.id}
          name="essa proposta"
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Offers;
