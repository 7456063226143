import { useEffect, useState } from 'react';
import { ApiCategory } from '../api';
import { Category } from '../types';

export function useFetchCategory(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Category[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiCategory.getAllCategory();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { CategoryList: data, isLoading };
}
