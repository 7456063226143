import { Person } from '@styled-icons/fluentui-system-regular/Person';
import { useAtom } from 'jotai';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import IconDictionary from '../../components/Icons/icons';
import ModalPopover from '../../components/Modal/Popover';
import { getItem } from '../../helpers/storage.helper';
import { getTokenAccountInformation } from '../../helpers/token.helper';
import { instancesNumber } from '../../hooks/globalAtom';
import { useDigitalSignaturedCreditContext } from '../DigitalSignatureCredit/dashboard/context';
import ExplanatoryVideos from './components/explanatoryVideos';
import LoginOtherCompanie from './components/loginOtherCompanie';
import { HeaderMobile, Menu } from './components/menu';
import Notification from './components/notification';
import { useFetchCurrentAccess, useTheme } from './hooks';

const DIGITAL_SIGNATURE_CREDIT_ROUTE = 'digital-signature-credit';

const INSTANCE_ROUTE = 'instances';

const Main: React.FC<{ children: ReactNode }> = ({ children }) => {
  const menus = (getItem('routes_user') ?? []) as string[];
  const signatureCreditContext = useDigitalSignaturedCreditContext();

  const [instancesQtd] = useAtom(instancesNumber);
  const hasAccessToDigitalSignatureCredit = menus.includes(
    DIGITAL_SIGNATURE_CREDIT_ROUTE
  );

  const hasAccessToInstances = menus.includes(INSTANCE_ROUTE);

  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(true);
  const [showSessionChange, setShowSessionChange] = useState(false);

  const pathName = window.location.pathname;
  const userInformation = getTokenAccountInformation();
  const { AccessListUser, isLoading } = useFetchCurrentAccess();
  const { theme } = useTheme(userInformation.companie?.id);

  return (
    <div className="relative min-h-screen flex bg-gray-200 max-w-full">
      {showSessionChange && (
        <button
          type="button"
          onClick={() => setShowSessionChange(false)}
          className="absolute z-50 top-0 left-0 w-full h-full cursor-default"
        >
          {' '}
        </button>
      )}

      <div className="max-w-[260px]">
        <Menu
          isMenuOpen={openMenu}
          openMenu={setOpenMenu}
          page={pathName.replace(/[^A-Za-z-]/g, '')}
          theme={theme}
        />
      </div>
      <div className="absolute right-0 w-full lg:w-[calc(100%_-_90px)]">
        <div className="hidden lg:flex items-center gap-3 absolute right-4 top-5 mt-0.5">
          {userInformation.companie?.id && (
            <>
              {hasAccessToInstances && (
                <Link
                  to={`/${INSTANCE_ROUTE}`}
                  className="cursor-pointer relative"
                >
                  <div className="bg-primary rounded-full flex items-center justify-center w-4 h-4 text-xs text-white -right-2 absolute -top-1.5 z-10">
                    {instancesQtd}
                  </div>
                  <IconDictionary
                    size={24}
                    name="Whatsapp"
                    color="text-primary"
                    className="hover:scale-110"
                  />
                </Link>
              )}
              {hasAccessToDigitalSignatureCredit && (
                <Link
                  to={`/${DIGITAL_SIGNATURE_CREDIT_ROUTE}`}
                  title="Ir para assinatura digital de contratos"
                  className="cursor-pointer relative mx-3"
                >
                  <div className="bg-primary rounded-full flex items-center justify-center w-4 h-4 text-xs text-white -right-2 absolute -top-1.5">
                    {signatureCreditContext.signatureCreditBalance}
                  </div>
                  <IconDictionary
                    size={28}
                    name="Assinar contrato"
                    className="hover:scale-110"
                  />
                </Link>
              )}
              <Notification />
            </>
          )}
          <div className="rounded-full w-12 h-12 bg-gray flex justify-center items-center">
            <Person size={24} />
          </div>
          <div className="flex flex-col">
            <div>
              <p className="font-medium text-medium">{userInformation.name}</p>
              {userInformation.companie?.name && (
                <ModalPopover
                  TriggerButton={
                    <p className="text-gray-600 text-xs">
                      {userInformation.companie?.name} - ID:{' '}
                      {userInformation.companie_id}
                    </p>
                  }
                  Content={() => (
                    <LoginOtherCompanie
                      AccessListUser={AccessListUser}
                      isLoading={isLoading}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="px-4 pt-16 lg:pt-8 bg-gray-200 min-h-screen">
          <HeaderMobile
            active={openMenuMobile}
            openMenu={setOpenMenuMobile}
            theme={theme}
          />
          {children}
        </div>
      </div>
      <ExplanatoryVideos />
    </div>
  );
};

export default Main;
