import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { attachment } from '../../../components/Register/attachments';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ApiOpportunity } from '../api';
import {
  AnnotationOpportunity,
  OpportunityProps,
  payloadBackOpportunity,
  serviceOpportunity,
} from '../types';

export function useFetchAllOpportunities(key: string, companyId: number) {
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OpportunityProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const opportunity = await ApiOpportunity.getOpportunityByCompany(
        companyId
      );
      if (typeof opportunity !== 'string') {
        if (accountProps.profile.id === 5) {
          const opportunitySeller = opportunity.filter(
            (oport) => oport.companie_user_id === accountProps.id
          );
          setData(opportunitySeller);
        } else {
          setData(opportunity);
        }
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { OpportunityList: data, isLoading };
}

export function useFetchOpportunity(key: string, opportunityId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OpportunityProps | null>(null);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const opportunity = await ApiOpportunity.getOpportunityById(
        opportunityId
      );
      if (typeof opportunity !== 'string') {
        setData(opportunity);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return {
    OpportunityFullData: data,
    isLoadingFullData: isLoading,
    setOpportunityFullData: setData,
  };
}

export function useFetchOpportunityDropdown(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackOpportunity>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    const FetchCompany = async () => {
      if (key.length >= 3) {
        setIsLoading(true);
        const opportunity = await ApiOpportunity.seachOpportunities(
          key,
          0,
          999999999
        );
        if (typeof opportunity !== 'string') {
          setData(opportunity);
        }
        setIsLoading(false);
      } else {
        setData({ data: [], total: 0 });
      }
    };

    FetchCompany();
  }, [key]);
  return {
    OpportunityList: data,
    isLoadingOpportunityList: isLoading,
    setOpportunityList: setData,
  };
}

export function useFetchAllServicesOpportunity(
  key: string,
  opportunityId: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<serviceOpportunity[]>([]);
  useEffect(() => {
    const FetchProduct = async () => {
      setIsLoading(true);
      const product =
        opportunityId === -1
          ? []
          : await ApiOpportunity.getProductByOpportunity(opportunityId);
      if (typeof product !== 'string') {
        setData(product);
      }
      setIsLoading(false);
    };

    FetchProduct();
  }, [key, opportunityId]);

  return { OpportunityServicesList: data, isLoading, setServicesList: setData };
}

export function useFetchAllAttachmentsOpportunity(
  key: string,
  opportunityId: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchFiles = async () => {
      setIsLoading(true);
      const product = await ApiOpportunity.getOpportunityAttachments(
        opportunityId
      );
      if (typeof product !== 'string') {
        setData(product);
      }
      setIsLoading(false);
    };

    FetchFiles();
  }, [key]);
  return { AttachmentsList: data, isLoading };
}

export function useFetchAllAnnotationsOpportunity(
  key: string,
  opportunityId: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AnnotationOpportunity[]>([]);
  useEffect(() => {
    const FetchAnnotations = async () => {
      setIsLoading(true);
      const annotation = await ApiOpportunity.getAnnotationsByOpportunity(
        opportunityId
      );
      if (typeof annotation !== 'string') {
        setData(annotation);
      }
      setIsLoading(false);
    };

    FetchAnnotations();
  }, [key]);
  return { AnnotationList: data, isLoading };
}

export function useFetchMessageOpportunity(
  key: string,
  whatsappNumber: string | undefined,
  skip: string,
  take: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(
    {} as {
      total: number;
      data: any[];
    }
  );
  useEffect(() => {
    (async () => {
      if (whatsappNumber) {
        setIsLoading(true);
        const annotation = await ApiOpportunity.getOpportunityMessage(
          `55${whatsappNumber}`,
          skip,
          take
        );

        if (typeof annotation !== 'string') {
          setData(annotation);
        }
        setIsLoading(false);
      }
    })();
  }, [key]);
  return { OpportunityMessageList: data, isLoading };
}

export function useIntersectionObserver<T extends HTMLElement>(
  callback: () => void,
  deps: DependencyList
) {
  const observer = useRef<IntersectionObserver | null>(null);
  const ref = useCallback(
    (node: Element) => {
      if (deps.every(Boolean)) {
        observer.current?.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) callback();
        });

        if (node) observer.current.observe(node);
      }
    },
    [deps, callback]
  );

  return ref;
}

interface WhatsappOpportunityProps {
  key: string;
  whatsappNumber: string | undefined;
}

const format = (whatsCustom: string) => {
  if (whatsCustom.length === 11 && whatsCustom.substring(3, 2) === '9') {
    return `${whatsCustom.substring(0, 2)}${whatsCustom.substring(3, 11)}`;
  }

  return whatsCustom;
};

export function useFetchWhatsappOpportunity({
  key,
  whatsappNumber,
}: WhatsappOpportunityProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(
    {} as {
      total: number;
      data: any[];
      instances: {
        data: any[];
        message:
          | 'instanceDisconnected'
          | 'instanceConnected'
          | 'thisContactHasNoInstance'
          | 'planIsNotActive';
      };
    }
  );

  useEffect(() => {
    if (whatsappNumber === undefined || whatsappNumber === null) return;
    (async () => {
      setIsLoading(true);

      const annotation = await ApiOpportunity.getOpportunityMessage(
        `55${format(whatsappNumber)}`,
        '0',
        '20'
      );

      if (typeof annotation !== 'string') {
        if (annotation.data.length > 0) {
          const instances: any = await ApiOpportunity.getInstances(
            annotation.data[annotation.data.length - 1]
              ?.companie_whatsapp_instance_id
          );

          if (instances.message === 'planIsNotActive') {
            setData({ ...annotation, instances });
          } else if (instances.message === 'instanceDisconnected') {
            setData({ ...annotation, instances });
          } else if (instances.message === 'instanceConnected') {
            setData({ ...annotation, instances });
          } else if (
            instances.message === 'thisContactHasNoInstance' ||
            instances.message === 'thereAreNoConnectedInstances'
          ) {
            setData({ ...annotation, instances });
          }
        } else {
          const instances: any = await ApiOpportunity.getInstances(null);

          if (
            instances?.message === 'thisContactHasNoInstance' ||
            instances?.message === 'thereAreNoConnectedInstances'
          ) {
            setData({ ...annotation, instances });
          }
          if (
            instances.message === 'planNotExist' ||
            instances.message === 'planIsNotActive'
          ) {
            setData({ ...annotation, instances });
          }
        }
      }
      setIsLoading(false);
    })();
  }, [key]);

  return { WhatsappOpportunity: data, isLoading };
}
