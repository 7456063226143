import { baseApiPrivate } from '../../../../api/baseApi';
import { cashFlowProps } from '../types';

async function getcashFlow(
  filterPeriod: string,
  contractStatus: string[]
): Promise<cashFlowProps | string> {
  return baseApiPrivate({
    method: 'POST',
    body: { status_contract: contractStatus },
    url: `companie/financial/cashFlow/${filterPeriod}`,
  });
}

export const ApiCashFlow = {
  getcashFlow,
};
