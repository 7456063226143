import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { createUpdateOpportunity } from '../../types';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import maskHelper from '../../../../helpers/mask.helper';
import { ApiOpportunity } from '../../api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import InputDate from '../../../../components/Input/inputDate';
import { useFetchContact } from '../../../contacts/hooks';
import RegisterContact from '../../../contacts/components/register';
import { OptionsSelectProp } from '../../../../components/Select/select';
import { states } from '../../../../helpers/address.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  stepsOptions: { label: string; value: number }[];
  salesChannelOptions: { label: string; value: number }[];
  segmentOptions: { label: string; value: number }[];
}

const RegisterOpportunity: React.FC<registerModalProps> = ({
  show,
  setShow,
  reloadTable,
  salesChannelOptions,
  segmentOptions,
  stepsOptions,
}) => {
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });
  const [citiesOptions, setCitiesOptions] = useState<OptionsSelectProp[]>([]);

  const [defaultContact, setDefaltContact] = useState<any>(null);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accountInformation = getTokenAccountInformation();
  const schema = yup.object().shape({
    title: yup.string().required('Título da oportunidade é obrigatório'),
    value_estimated: yup.string(),
    date_expectation: yup.string(),
    funnel_step_id: yup.number().required('Etapa é obrigatória'),
    person_id: yup.number().required('Contato é obrigatório'),
  });

  const [inputContact, setInputContact] = useState('');
  const { ContactList, isLoadingContacts } = useFetchContact(
    '',
    0,
    100000000,
    inputContact
  );
  const contactOptions = ContactList.data.map((contact) => ({
    value: contact.id,
    label: contact.name,
    name: contact.name,
    id: contact.id,
    email: contact.email,
    whatsapp: contact.whatsapp,
    document: contact.document,
  }));

  const { formState, handleSubmit, setValue, reset, getValues, control } =
    useForm<createUpdateOpportunity>({
      resolver: yupResolver(schema),
      defaultValues: {
        date_expectation: moment().format('YYYY-MM-DD'),
      },
    });

  const { notify } = ToastNotify();

  const Submit = async (e: createUpdateOpportunity) => {
    setIsLoading(true);
    const response = await ApiOpportunity.createOpportunity({
      companie_user_id: accountInformation.id,
      date_expectation: e.date_expectation
        ? maskHelper.formatDateYMD(e.date_expectation)
        : moment().format('YYYY-MM-DD'),
      funnel_step_id: e.funnel_step_id,
      person_id: e.person_id,
      sales_channel_id: e.sales_channel_id,
      segment_id: e.segment_id,
      title: e.title,
      obs: e.obs,
      city: e.city,
      state: e.state,
    });
    if (response.id) {
      notify({ message: 'Oportunidade criada com sucesso!', type: 'Success' });

      reloadTable(`${Math.random()} opportunity`);
      setShow(false);
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoading(false);
  };

  const customFilter = (option: any, searchText: string) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.whatsapp?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.document?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    reset();
  }, [show]);

  useEffect(() => {
    if (show === false) {
      setDefaltContact(null);
    } else if (defaultContact !== null) {
      const contact = contactOptions.find(
        (item) => item.value === defaultContact
      );
      if (contact) {
        setDefaltContact(contact);
        setValue('person_id', contact.value);
      }
    }
  }, [contactOptions]);

  const fetchCities = async (uf: string) => {
    const cities = await ApiOpportunity.getCities(uf);
    if (cities) {
      setCitiesOptions(
        cities.map((item) => ({ label: item.nome, value: item.nome }))
      );
    }
  };

  return (
    <Modal
      title="Cadastrar Oportunidade"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[600px]"
    >
      {openCreateContact && (
        <RegisterContact
          companyId={accountInformation.id}
          contact={defaultContact}
          registerPage={registerPage}
          setRegisterPage={setRegisterPage}
          reloadTable={() => {}}
          setContact={setDefaltContact}
          setShow={() => {
            setOpenCreateContact(false);
            if (defaultContact) setValue('person_id', defaultContact.id);
          }}
          show={openCreateContact}
          outsideCreate
        />
      )}
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Input
          className="col-span-2"
          title="Título da oportunidade"
          variant="outline-orange"
          onChange={(e) => setValue('title', e.target.value)}
          errorMensage={formState.errors.title?.message}
        />
        <div className="relative">
          <button
            onClick={() => {
              setOpenCreateContact(true);
              setRegisterPage({
                title: 'Dados Pessoais',
                page: 0,
              });
            }}
            type="button"
            className="absolute top-0 mt-1 right-0 text-xs text-primary cursor-pointer z-10"
          >
            Adicionar Novo
          </button>
          <SelectComponent
            filterOption={customFilter}
            value={
              defaultContact
                ? {
                    label: `${defaultContact.name} - ${
                      defaultContact.whatsapp ?? defaultContact.email
                    }`,
                    value: defaultContact.id,
                  }
                : null
            }
            title="Contato"
            maxOptionsHeight="250px"
            options={contactOptions}
            onInputChange={setInputContact}
            isLoading={isLoadingContacts}
            closeMenuOnSelect
            onChange={(e: any) => {
              setValue('person_id', e.value);
              setDefaltContact(e);
            }}
            errorMensage={formState.errors.person_id?.message}
          />
        </div>

        <SelectComponent
          title="Etapa"
          maxOptionsHeight="250px"
          options={stepsOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('funnel_step_id', e.value);
          }}
          errorMensage={formState.errors.funnel_step_id?.message}
        />
        <SelectComponent
          title="Canal de venda"
          maxOptionsHeight="180px"
          options={salesChannelOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('sales_channel_id', e.value);
          }}
          errorMensage={formState.errors.sales_channel_id?.message}
        />
        <SelectComponent
          title="Segmento"
          maxOptionsHeight="180px"
          options={segmentOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('segment_id', e.value);
          }}
          errorMensage={formState.errors.segment_id?.message}
        />
        <InputDate
          defaultValue={moment(getValues('date_expectation')).toDate()}
          onChange={(e: any) => {
            if (e !== null) {
              setValue(
                'date_expectation',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
            } else {
              setValue('date_expectation', '');
            }
          }}
          title="Data do Contato"
          errorMensage={formState.errors.date_expectation?.message}
        />
        <SelectComponent
          isDisabled={isLoading}
          title="Estado"
          options={states}
          closeMenuOnSelect
          isClearable
          onChange={(e: any) => {
            setValue('city', '');

            if (e) {
              fetchCities(e.value);
              setValue('state', e.value);
              setValue('city', '');
            } else {
              setValue('state', '');
            }
          }}
          errorMensage={formState.errors.state?.message}
        />
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <SelectComponent
              isClearable
              isDisabled={isLoading || !getValues('state')}
              title="Cidade"
              options={citiesOptions}
              closeMenuOnSelect
              value={citiesOptions.find(
                (element) => element.value === field.value
              )}
              onChange={(e: any) => {
                if (e) {
                  setValue('city', e.value);
                } else {
                  setValue('city', '');
                }
              }}
              errorMensage={formState.errors.state?.message}
            />
          )}
        />

        <div className="col-span-2">
          <p className="text-sm mb-1">Observação</p>
          <textarea
            onChange={(e) => {
              setValue('obs', e.target.value);
            }}
            defaultValue=""
            name="textinput"
            className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
          />
        </div>
        <Button
          disabled={isLoading}
          variant="primary-strong"
          type="submit"
          className="col-span-2 font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default RegisterOpportunity;
