import React from 'react';
import { Button, Modal, SelectComponent } from '../../../components';
import { QuestionAnswersProps } from '../types';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  data: QuestionAnswersProps[];
}

const ModalQuestionsAnswers: React.FC<registerModalProps> = ({
  data,
  setShow,
  show,
}) => {
  const getOptions = (item: any, type: string, response: any) => {
    if (!response) return <p className="text-gray-400">Sem resposta</p>;
    let op: any;
    switch (type) {
      case 'checkbox': {
        const ids = response.map((r: any) => r.search_form_question_option_id);
        return item.map((check: any) => (
          <div className="flex gap-1 mt-1">
            <input type="checkbox" checked={ids.includes(check.id)} />
            <p>{check.title}</p>
          </div>
        ));
      }
      case 'radio':
        return item.map((radio: any) => (
          <div className="flex gap-1 mt-1">
            <input
              type="radio"
              checked={response[0].search_form_question_option_id === radio.id}
            />
            <p>{radio.title}</p>
          </div>
        ));
      case 'select':
        return (
          <SelectComponent
            isDisabled
            className="w-48 mt-1"
            options={[]}
            value={{
              label: response[0].text_response,
              value: response[0].search_form_question_option_id,
            }}
          />
        );
      default:
        return response[0].text_response;
    }
  };
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Perguntas/Respostas"
      size="2xlarge"
      minHeight="min-h-[250px]"
    >
      <div className="max-h-[75vh] flex flex-col overflow-y-auto">
        {data.map((item) => (
          <div className="text-sm mb-2">
            <p className="font-semibold">{item.title}</p>
            <div>
              {getOptions(
                item.search_form_questions_options,
                item.type_response,
                item.response
              )}
            </div>
          </div>
        ))}
      </div>
      <Button
        actionType="button-loading"
        className="mx-auto"
        onClick={() => setShow(false)}
      >
        Voltar
      </Button>
    </Modal>
  );
};

export default ModalQuestionsAnswers;
