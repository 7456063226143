import maskHelper from '../../../helpers/mask.helper';

export const statesNames = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const paymentForm = [
  { name: 'Cartão de Crédito', value: 'creditcard' },
  { name: 'Boleto', value: 'boleto' },
  { name: 'Pix', value: 'pix' },
];

export const validateExpiryDate = (value?: string) => {
  if (!value) return false;

  const [year, month] = value.split('-').map(Number);
  if (month < 1 || month > 12) return false;

  const now = new Date();
  const expiryDate = new Date(year, month - 1, 1);

  return expiryDate > now;
};

export function getCardBrand(cardNumber: string) {
  if (cardNumber.length < 19) {
    return false;
  }

  const cardPatterns = {
    visa: /^4\d{12}(\d{3})?$/,
    mastercard: /^(5[1-5]\d{4}|677189)\d{10}$/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
    amex: /^3[47]\d{13}$/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const brand in cardPatterns) {
    if (
      cardPatterns[brand as keyof typeof cardPatterns].test(
        maskHelper.number(cardNumber)
      )
    ) {
      return brand;
    }
  }
  return '';
}
