import React from 'react';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import { Button } from '../../../components';
import { getUrlPlatform } from '../../../helpers/utils.helper';
import { setItem } from '../../../helpers/storage.helper';
import { cookiesAccepted } from '../../../hooks/globalAtom';

const CookiesBanner: React.FC = () => {
  const [, setAcceptedCookies] = useAtom(cookiesAccepted);
  return (
    <div className="fixed z-50 bottom-0 backdrop-blur-3xl bg-primary/30 w-full py-3 md:h-44 flex items-center">
      <div className="px-4 w-full pt-2 items-center drop-shadow-2xl transition-opacity ease-out duration-700 opacity-100">
        <h3 className="ml-5 mb-2 font-Fredoka text-4xl font-semibold text-white">
          Privacidade & Cookies
        </h3>

        <div className="flex flex-col sm:flex-row w-full sm:items-start justify-between pl-5">
          <p className="text-white text-sm w-full sm:w-1/2 mb-5 sm:mb-0">
            Ao clicar em Aceitar Cookies, você concorda com nossa política de
            privacidade e o uso de cookies para melhorar sua experiência no
            site.
          </p>
          <div className="flex flex-col md:flex-row items-center gap-3 w-full sm:w-1/2 justify-end">
            <Button
              actionType="button-simple"
              className="font-Fredoka w-48 h-12 rounded-2xl text-white text-md bg-primary animate-bounce"
              onClick={() => {
                setItem('cookies', true);
                setAcceptedCookies(true);
              }}
            >
              <span className="text-xs">Aceitar Cookies</span>
            </Button>
            <Link
              target="_blank"
              to={`${getUrlPlatform()}files/politicas_privacidade_cookies.pdf`}
            >
              <Button
                variant="outline-primary"
                className="font-Fredoka w-48 h-12 rounded-2xl text-primary text-base border border-primary border-solid bg-white font-normal"
              >
                <span className="text-xs">Ler mais</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;
