import { baseApiPrivate } from '../../../api/baseApi';
import { Segment } from '../types';

async function getSegmentByCompany(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/segment/${companyId}`,
  });
}

async function createSegment(e: Omit<Segment, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/segment',
    body: e,
  });
}

async function updateSegment(e: Omit<Segment, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/segment/${id}`,
    body: e,
  });
}

async function deleteSegment(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/segment/${id}`,
  });
}

export const ApiSegment = {
  getSegmentByCompany,
  createSegment,
  updateSegment,
  deleteSegment,
};
