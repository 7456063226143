import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
  Switch,
} from '../../../components';
import { Product, createUpdateProducts } from '../types';
import { ApiProduct } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import maskHelper from '../../../helpers/mask.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  product: Product | null;
}

const RegisterProduct: React.FC<registerModalProps> = ({
  product,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<createUpdateProducts, 'id'>>({
    title: product?.title ?? '',
    is_active: product?.is_active ?? true,
    value: product?.value ?? 0,
    is_product: product?.is_product ?? true,
  });
  const productOptions = [
    { value: true, label: 'Produto' },
    { value: false, label: 'Serviço' },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [productType, setProductType] = useState(productOptions[0]);

  const Submit = async () => {
    setIsLoading(true);
    if (product !== null) {
      const res = await ApiProduct.updateProduct(values, product.id);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Produto atualizado com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiProduct.createProduct(values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Produto criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      title: product?.title ?? '',
      is_active: product?.is_active ?? true,
      value: product?.value ?? 0,
      is_product: product?.is_product ?? true,
    });
  }, [product, show]);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={product ? 'Atualizar Produto' : 'Criar Produto'}
      size="medium"
      minHeight="min-h-[250px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <Input
          variant="outline-orange"
          title="Valor"
          defaultValue={maskHelper.formatMoeda(product?.value)}
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setValues({
              ...values,
              value: Number(maskHelper.numberCurrencyDecimal(e.target.value)),
            });
          }}
        />
        <SelectComponent
          title="Tipo"
          options={productOptions}
          value={productType}
          onChange={(e: any) => {
            setProductType(e);
            setValues({ ...values, is_product: e.value });
          }}
        />
        <div className="flex gap-3 items-center mt-3">
          <Switch
            state={values.is_active}
            onChange={() =>
              setValues({ ...values, is_active: !values.is_active })
            }
          />
          <p className="text-sm">Ativo</p>
        </div>
        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterProduct;
