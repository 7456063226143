import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  DeleteModal,
  Input,
  Modal,
  SelectComponent,
  Table,
} from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import InputDate from '../../../../../components/Input/inputDate';
import maskHelper from '../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { ApiActivitie } from '../../../../activitie/api';
import { ActivityOpportunity, createActivity } from '../../../types';
import { ToastNotify } from '../../../../../components/Toast/toast';
import { reloadNotification } from '../../../../../hooks/globalAtom';

/* eslint-disable no-unused-vars */
interface contactList {
  activities: ActivityOpportunity[];
  personId: number | null;
  companieUserId: number;
  companieId: number;
  activityOptions: { label: string; value: number }[];
  reloadOpportunity: (e: string) => void;
  opportunityId: number;
}

interface createProps {
  opportunityId: number;
  activityOptions: { label: string; value: number }[];
  setShowModalCreateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showModalCreateUpdate: boolean;
  currentActivity: null | ActivityOpportunity;
  personId: number | null;
  companieUserId: number;
  // eslint-disable-next-line no-unused-vars
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
  reloadTable: (e: string) => void;
}

export function CreateUpdateActivity({
  activityOptions,
  opportunityId,
  companieUserId,
  currentActivity,
  notify,
  personId,
  setShowModalCreateUpdate,
  showModalCreateUpdate,
  reloadTable,
}: createProps) {
  const [notificationState, reloadNotificationContainer] =
    useAtom(reloadNotification);
  const defaultActivity: createActivity = {
    companie_user_id: companieUserId,
    date:
      currentActivity?.date.slice(0, 10) ??
      maskHelper.formatDateYMD(new Date().toLocaleDateString('pt-br')),
    description: currentActivity?.description ?? '',
    hour: currentActivity?.hour ?? '',
    opportunity_id:
      typeof currentActivity?.opportunity_id === 'undefined'
        ? -1
        : currentActivity?.opportunity_id,
    activitie_id: currentActivity?.activitie_id ?? -1,
  };

  const schema = yup.object().shape({
    activitie_id: yup.number().required('Selecione uma opção'),
    hour: yup.string().required('Horário é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
    date: yup.string().required('Data é obrigatória'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<createActivity>(defaultActivity);

  const { handleSubmit, setValue, formState, reset } = useForm<createActivity>({
    resolver: yupResolver(schema),
    defaultValues: {
      opportunity_id: currentActivity?.opportunity_id,
      activitie_id: currentActivity?.activitie_id,
      date:
        currentActivity?.date ??
        maskHelper.formatDateYMD(new Date().toLocaleDateString('pt-br')),
      description: currentActivity?.description,
      hour: currentActivity?.hour,
    },
  });
  const Submit = async (values: createActivity) => {
    if (currentActivity !== null) {
      setIsLoading(true);
      const res = await ApiActivitie.updateActivitie(
        {
          date: values.date,
          description: values.description,
          hour: values.hour,
          companie_user_id_owner: companieUserId,
          activitie_id: values.activitie_id,
          opportunity_id:
            values.opportunity_id === -1 ? null : values.opportunity_id,
          person_id: personId,
        },
        currentActivity.id
      );
      if (res.id) {
        reloadNotificationContainer(!notificationState);
        setIsLoading(false);
        notify({
          message: 'Atividate atualizada com sucesso!',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShowModalCreateUpdate(false);
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiActivitie.createActivitie({
        ...values,
        companie_user_id_owner: companieUserId,
        opportunity_id: opportunityId,
        companie_user_id: companieUserId,
        person_id: personId,
      });
      if (response.id) {
        reloadNotificationContainer(!notificationState);
        notify({ message: 'Tarefa criada com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShowModalCreateUpdate(false);
        setIsLoading(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setDefaultValues(defaultActivity);
    reset();
  }, [showModalCreateUpdate]);

  return (
    <Modal
      size="2xlarge"
      minHeight="min-h-[450px]"
      title={currentActivity ? 'Atualizar Tarefa' : 'Criar Tarefa'}
      setIsOpen={setShowModalCreateUpdate}
      isOpen={showModalCreateUpdate}
    >
      <form
        className="flex flex-col sm:grid grid-cols-2 gap-3 mt-8"
        onSubmit={handleSubmit(Submit)}
      >
        <SelectComponent
          closeMenuOnSelect
          value={activityOptions.find(
            (activity) => activity.value === defaultValues.activitie_id
          )}
          options={activityOptions}
          title="Tarefa"
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, activitie_id: e.value });
            setValue('activitie_id', e.value);
          }}
          errorMensage={formState.errors.activitie_id?.message}
        />
        <Input
          variant="outline-orange"
          title="Descrição"
          value={defaultValues.description}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, description: e.target.value });
            setValue('description', e.target.value);
          }}
          errorMensage={formState.errors.description?.message}
        />
        <InputDate
          onChange={(e: any) => {
            if (e !== null) {
              setDefaultValues({
                ...defaultValues,
                date: maskHelper.formatDateYMD(e.toLocaleDateString('pt-br')),
              });
              setValue(
                'date',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
            } else {
              setDefaultValues({
                ...defaultValues,
                date: '',
              });
              setValue('date', '');
            }
          }}
          defaultValue={
            defaultValues.date ? new Date(`${defaultValues.date} `) : new Date()
          }
          title="Data"
          errorMensage={formState.errors.date?.message}
        />

        <Input
          title="Horário"
          type="time"
          variant="outline-orange"
          value={defaultValues.hour}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, hour: e.target.value });
            setValue('hour', e.target.value);
          }}
          errorMensage={formState.errors.hour?.message}
        />

        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
}

const ActivityList: React.FC<contactList> = ({
  activities,
  companieUserId,
  personId,
  companieId,
  activityOptions,
  reloadOpportunity,
  opportunityId,
}) => {
  const [notificationState, reloadNotificationContainer] =
    useAtom(reloadNotification);
  const { notify } = ToastNotify();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentActivity, setCurrentActivity] =
    useState<ActivityOpportunity | null>(null);
  const [show, setShow] = useState(false);
  const updateStatus = async (status: any, id: number) => {
    const response = await ApiActivitie.updateActivitieStatus(status, id);
    if (response.id) {
      reloadNotificationContainer(!notificationState);
      notify({ message: 'Status Atualizado com sucesso!', type: 'Success' });
      reloadOpportunity(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
  };
  const columns = [
    {
      name: 'Tarefa',
      key: 'title',
      selector: (row: ActivityOpportunity) => row.activitie_type.title,
    },
    {
      name: 'Proprietário',
      key: 'title',
      selector: (row: ActivityOpportunity) =>
        row.companie_user_activities_companie_user_id_ownerTocompanie_user.name,
    },
    {
      name: 'Date',
      key: 'date',
      selector: (row: ActivityOpportunity) =>
        maskHelper.formatDateDMY(row.date),
      hide: 1515,
    },
    {
      name: 'Criado em',
      key: 'created_at',
      selector: (row: ActivityOpportunity) =>
        maskHelper.formatDateDMY(row.created_at),
      hide: 1366,
    },
    {
      name: 'Realizar em',
      key: 'date',
      selector: (row: ActivityOpportunity) =>
        maskHelper.formatDateDMY(row.date),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: ActivityOpportunity) => (
        <p
          className={
            row.status === 'Realizado'
              ? 'text-[11px] text-green font-medium'
              : 'text-[11px]'
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      name: '',
      maxWidth: '50px',
      cell: (row: ActivityOpportunity) => (
        <div>
          {row.status === 'Em andamento' && (
            <IconDictionary
              name="Circulo Confirmar"
              size={20}
              className="cursor-pointer text-green mr-2 hover:scale-110"
              onClick={() => {
                updateStatus({ status: 'Realizado' }, row.id);
              }}
            />
          )}
          <IconDictionary
            name="Excluir"
            color="#DA3C3C"
            size={20}
            className="cursor-pointer hover:scale-110"
            onClick={() => {
              setCurrentActivity(row);
              setShowModalDelete(true);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="w-full flex flex-col px-4">
      {show && (
        <CreateUpdateActivity
          opportunityId={opportunityId}
          activityOptions={activityOptions}
          reloadTable={reloadOpportunity}
          setShowModalCreateUpdate={setShow}
          showModalCreateUpdate={show}
          notify={notify}
          currentActivity={currentActivity}
          personId={personId}
          companieUserId={companieUserId}
        />
      )}

      <Button
        variant="primary-strong"
        actionType="button-add"
        onClick={() => {
          setShow(true);
          setCurrentActivity(null);
        }}
      >
        Adicionar Tarefa
      </Button>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={activities}
        onRowClick={(e: ActivityOpportunity) => {
          setCurrentActivity(e);
          setShow(true);
        }}
      />
      {currentActivity && (
        <DeleteModal
          apiFunction={ApiActivitie.deleteActivitie}
          notify={notify}
          type="Tarefa"
          article="A"
          updateTable={() => reloadOpportunity(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={currentActivity.id}
          name="essa atividade"
          open={showModalDelete}
        />
      )}
    </div>
  );
};

export default ActivityList;
