import React from 'react';
import { AdminPanelSettings } from '@styled-icons/material-outlined/AdminPanelSettings';

const AdminScreen: React.FC = () => (
  <div className="w-full h-full flex flex-col items-center mt-16 text-gray-600">
    <p className="text-lg mb-2">PÁGINA PRIVADA</p>
    <AdminPanelSettings size={48} />
    <p className="text-sm mt-2">Apenas Administradores</p>
  </div>
);

export default AdminScreen;
