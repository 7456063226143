import React from 'react';
import { Link } from 'react-router-dom';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Home } from '@styled-icons/octicons/Home';
import Logo from '../../../assets/png/logo.png';

/* eslint-disable no-unused-vars */
const Menu: React.FC<{
  setNotActive: (param: boolean) => void;
  outsidePage?: boolean;
  companyLogo?: string;
}> = ({ setNotActive, outsidePage = false, companyLogo = Logo }) => {
  const links = [
    {
      id: 0,
      link: 'Home',
      icon: <Home size={20} style={{ color: 'white' }} />,
      url: '/',
    },
  ];
  const linksOutSidePage = [
    {
      id: 0,
      link: 'Home',
      icon: <Home size={20} style={{ color: 'white' }} />,
      url: 'https://leadsim.com.br/',
    },
  ];
  return (
    <div className="bg-black/25 py-6 px-8 backdrop-blur-lg w-screen h-screen fixed top-0 left-0 z-40">
      <div className="flex flex-row">
        <CloseOutline
          size={30}
          color="#fff"
          onClick={() => setNotActive(false)}
          className="absolute t-0 l-0"
        />
        <Link
          to="/"
          target={outsidePage ? '_blank' : ''}
          className="flex w-full justify-center"
        >
          <img
            alt="logo-company"
            src={companyLogo}
            className="cursor-pointer overflow-y-hidden h-14"
          />
        </Link>
      </div>
      <div className="mt-12">
        {(outsidePage ? linksOutSidePage : links).map((item) => (
          <Link
            to={item.url}
            key={item.id}
            target={outsidePage ? '_blank' : ''}
          >
            <div className="flex flex-col pl-5">
              <div className="flex flex-row w-full items-center mt-8 gap-5">
                {item.icon}
                <p className="text-xl text-white">{item.link}</p>
              </div>
              <hr className="w-full bg-white h-px mt-8" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Menu;
