import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal } from '../../../../components';
import { Category } from '../types';
import { ApiCategory } from '../api';
import { useFetchCategory } from '../hooks';
import RegisterCategory from '../components/register';

const Categories: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterCategory, setModalRegisterCategory] = useState(false);
  const [input, setInput] = useState('');
  const [profileData, setCategoryData] = useState<Category | null>(null);
  const [key, setKey] = useState('key');

  const { CategoryList, isLoading } = useFetchCategory(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Category) => row.title,
    },
    {
      name: '',
      cell: (row: Category) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setCategoryData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return CategoryList;
    }
    return CategoryList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Categorias"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterCategory}
        setRegisterPage={() => {
          setCategoryData(null);
        }}
        onRowClick={(e: Category) => {
          setCategoryData(e);
          setModalRegisterCategory(true);
        }}
      />
      <RegisterCategory
        category={profileData}
        reloadTable={setKey}
        setShow={setModalRegisterCategory}
        show={modalRegisterCategory}
      />
      {profileData && (
        <DeleteModal
          apiFunction={ApiCategory.deleteCategory}
          notify={notify}
          type="Categoria"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={profileData.id}
          name={profileData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Categories;
