import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ToastNotify } from '../Toast/toast';
import maskHelper from '../../helpers/mask.helper';
import Input from '../Input/input';
import SelectComponent from '../Select/select';
import Button from '../Button/button';
import { getAddressByCEP, states } from '../../helpers/address.helper';
import { dictionaryError } from '../../helpers/utils.helper';
import { CEPResponse } from '../../types';
import Spinner from '../Spinner/spinner';

export interface addressData {
  zipcode: string;
  address: string;
  complement?: string;
  province: string;
  city: string;
  state: string;
  number?: string;
}

/* eslint-disable no-unused-vars */
interface AddressProps {
  apiFunction: (values: any, id: number) => Promise<any>;
  nextPage: (e?: any) => void;
  id: number;
  defaultValue: addressData | null;
  hasNumber?: boolean;
  isAllFieldsDisabled?: boolean;
}

const Address: React.FC<AddressProps> = ({
  nextPage,
  apiFunction,
  id,
  defaultValue,
  hasNumber = false,
  isAllFieldsDisabled = false,
}) => {
  const { notify } = ToastNotify();

  const schema = hasNumber
    ? yup.object().shape({
        zipcode: yup
          .string()
          .required('CEP é obrigatório')
          .test({
            message: 'CEP inválido',
            test: (value) => (value ? value.length === 8 : true),
          }),
        address: yup.string().required('Rua/Longradouro é obrigatório'),
        province: yup.string().required('Bairro é obrigatório'),
        city: yup.string().required('Cidade é obrigatório'),
        state: yup
          .string()
          .required('Estado é obrigatório')
          .min(2, 'Selecione um estado'),
        complement: yup.string().nullable(true),
        number: yup
          .string()
          .typeError('Número é obrigatório')
          .required('Número é obrigatório'),
      })
    : yup.object().shape({
        zipcode: yup
          .string()
          .required('CEP é obrigatório')
          .test({
            message: 'CEP inválido',
            test: (value) => (value ? value.length === 8 : true),
          }),
        address: yup.string().required('Rua/Longradouro é obrigatório'),
        province: yup.string().required('Bairro é obrigatório'),
        city: yup.string().required('Cidade é obrigatório'),
        state: yup
          .string()
          .required('Estado é obrigatório')
          .min(2, 'Selecione um estado'),
        complement: yup.string().nullable(true),
      });

  const [cepAddress, setCepAddress] = useState<CEPResponse>({
    bairro: defaultValue?.province ?? '',
    complemento: defaultValue?.complement ?? '',
    uf: defaultValue?.state ?? '',
    logradouro: defaultValue?.address ?? '',
    localidade: defaultValue?.city ?? '',
    number: defaultValue?.number,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCep, setIsLoadingCep] = useState(false);
  const [defaultZipcode, setDefaultZipcode] = useState<string>(
    defaultValue?.zipcode ?? ''
  );

  const { handleSubmit, setValue, formState } = useForm<addressData>({
    defaultValues: {
      address: defaultValue?.address ?? '',
      city: defaultValue?.city ?? '',
      complement: defaultValue?.complement ?? '',
      province: defaultValue?.province ?? '',
      state: defaultValue?.state ?? '',
      zipcode: defaultValue?.zipcode ?? '',
      number: defaultValue?.number,
    },
    resolver: yupResolver(schema),
  });

  const AddressByCEP = async (e: string) => {
    setIsLoadingCep(true);
    const address = await getAddressByCEP(e);
    if (address) {
      setCepAddress(address);
      setValue('address', address.logradouro);
      setValue('city', address.localidade);
      setValue('state', address.uf);
      setValue('province', address.bairro);
    }
    setIsLoadingCep(false);
  };

  const Submit = async (values: addressData) => {
    setIsLoading(true);
    const complement = values.complement ?? '';
    const response = await apiFunction({ ...values, complement }, id);
    if (response.id) {
      setIsLoading(false);
      notify({ message: 'Endereço atualizado com sucesso', type: 'Success' });
      nextPage(response);
    } else {
      setIsLoading(false);
      notify({ message: dictionaryError(response), type: 'Error' });
    }
  };

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-4 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <div className="relative">
        {isLoadingCep && (
          <div className="w-5 absolute top-0 left-10">
            <Spinner size="w-5" />
          </div>
        )}
        <Input
          disabled={isAllFieldsDisabled}
          title="CEP"
          variant="outline-orange"
          value={maskHelper.cep(defaultZipcode)}
          onBlur={(e) => AddressByCEP(maskHelper.number(e.target.value))}
          onChange={(e) => {
            setValue('zipcode', maskHelper.number(e.target.value));
            e.target.value = maskHelper.cep(e.target.value);
            setDefaultZipcode(maskHelper.number(e.target.value));
          }}
          errorMensage={formState.errors.zipcode?.message}
        />
      </div>

      <Input
        disabled={isAllFieldsDisabled || isLoadingCep}
        title="Rua / Longradouro"
        variant="outline-orange"
        onChange={(e) => {
          setCepAddress({ ...cepAddress, logradouro: e.target.value });
          setValue('address', e.target.value);
        }}
        value={cepAddress.logradouro}
        errorMensage={formState.errors.address?.message}
      />
      {hasNumber && (
        <div className="grid grid-cols-2 gap-3">
          {' '}
          <Input
            disabled={isAllFieldsDisabled || isLoadingCep}
            title="Número"
            variant="outline-orange"
            onChange={(e) => {
              setCepAddress({
                ...cepAddress,
                number: e.target.value,
              });
              setValue('number', e.target.value);
            }}
            value={cepAddress.number}
            errorMensage={formState.errors.number?.message}
          />
          <Input
            disabled={isAllFieldsDisabled || isLoadingCep}
            title="Complemento"
            variant="outline-orange"
            onChange={(e) => {
              setValue('complement', e.target.value);
              setCepAddress({ ...cepAddress, complemento: e.target.value });
            }}
            value={cepAddress.complemento}
            errorMensage={formState.errors.complement?.message}
          />
        </div>
      )}

      <Input
        disabled={isAllFieldsDisabled || isLoadingCep}
        title="Bairro"
        variant="outline-orange"
        onChange={(e) => {
          setCepAddress({ ...cepAddress, bairro: e.target.value });
          setValue('province', e.target.value);
        }}
        value={cepAddress.bairro}
        errorMensage={formState.errors.province?.message}
      />
      <Input
        disabled={isAllFieldsDisabled || isLoadingCep}
        title="Cidade"
        variant="outline-orange"
        onChange={(e) => {
          setCepAddress({ ...cepAddress, localidade: e.target.value });
          setValue('city', e.target.value);
        }}
        value={cepAddress.localidade}
        errorMensage={formState.errors.city?.message}
      />

      <SelectComponent
        isDisabled={isAllFieldsDisabled || isLoadingCep}
        title="Estado"
        options={states}
        closeMenuOnSelect
        value={states.find((element) => element.value === cepAddress.uf)}
        onChange={(e: any) => {
          setCepAddress({ ...cepAddress, uf: e.value });
          setValue('state', e.value);
        }}
        errorMensage={formState.errors.state?.message}
      />
      {!hasNumber && (
        <Input
          disabled={isAllFieldsDisabled || isLoadingCep}
          title="Complemento"
          variant="outline-orange"
          onChange={(e) => {
            setValue('complement', e.target.value);
            setCepAddress({ ...cepAddress, complemento: e.target.value });
          }}
          value={cepAddress.complemento}
          errorMensage={formState.errors.complement?.message}
        />
      )}

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          disabled={isLoading || isAllFieldsDisabled}
          isLoading={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default Address;
