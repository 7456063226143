import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { lighten } from 'polished';
import { useNavigate, useParams } from 'react-router-dom';
import { Check2Circle } from '@styled-icons/bootstrap/Check2Circle';
import { KeyOutline } from '@styled-icons/typicons/KeyOutline';
import { Lock } from '@styled-icons/boxicons-regular/Lock';
import Header from '../components/header';
import Menu from '../components/menu';
import { Button, Input, Spinner } from '../../../components';
import { checkCode, resetPassWord, validateIdentity } from '../api';
import { dictionaryError } from '../../../helpers/utils.helper';
import backgroundImage from '../../../assets/jpg/background_home.jpg';
import backgroundImageMobile from '../../../assets/jpg/background_home_mobile.jpg';
import { getItem } from '../../../helpers/storage.helper';

interface model {
  isLoading?: boolean;
  title: string;
  Icon: ReactNode;
  description: string;
  buttonTitle: string;
  InputComponent?: ReactElement;
  onClickFunction: () => void;
  err?: string;
}
interface informationProp {
  element: model;
}

function Information({ element }: informationProp) {
  const {
    InputComponent,
    buttonTitle,
    description,
    Icon,
    title,
    isLoading,
    onClickFunction,
    err,
  } = element;
  return (
    <>
      {Icon}
      <div className="w-full flex flex-col mb-12 mt-8">
        <strong className="text-xl mb-2">{title}</strong>
        <p className="text-xs mb-1">{description}</p>
        {InputComponent}
        <p className="text-red text-xs mt-1">{err}</p>
        <Button
          variant="primary-strong"
          className="mt-3 relative flex items-center justify-center"
          onClick={onClickFunction}
          disabled={isLoading}
        >
          {buttonTitle}
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </>
  );
}
function RecoveryPassCard() {
  const { identity, email } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [userId, setUserId] = useState(-1);
  const [page, setPage] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const pages: model[] = [
    {
      isLoading,
      err,
      InputComponent: (
        <Input
          type="number"
          placeholder="Código"
          onChange={(e) => setPassword(e.target.value)}
          icon={<KeyOutline size={18} color="#ddd" />}
        />
      ),
      buttonTitle: 'Redefinir senha',
      description: 'Insira o código enviado no email para redefinir sua senha',
      Icon: <Lock size={80} className="text-primary" />,
      title: 'Código Enviado',
      onClickFunction: async () => {
        const validIdentity = await validateIdentity(
          email ?? '',
          identity ?? ''
        );
        setIsLoading(true);
        if (typeof validIdentity !== 'string') {
          setUserId(validIdentity.id);
          const codeVerify = await checkCode(
            Number(password),
            validIdentity.id
          );
          if (typeof codeVerify !== 'string') {
            setPage(page + 1);
            setIsLoading(false);
          } else {
            setErr(dictionaryError(codeVerify));
            setIsLoading(false);
          }
        } else {
          setErr(dictionaryError(validIdentity));
          setIsLoading(false);
        }
      },
    },
    {
      isLoading,
      err,
      InputComponent: (
        <>
          <Input
            icon={<KeyOutline size={18} color="#ddd" />}
            type="password"
            className="my-1"
            placeholder="Inserir nova senha"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Input
            icon={<KeyOutline size={18} color="#ddd" />}
            type="password"
            placeholder="Confirmar nova senha"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </>
      ),
      buttonTitle: 'Redefinir Senha',
      description:
        'Sua nova senha deve conter no mínimo 8 caracteres e ao menos uma letra maiúscula',
      Icon: <Lock size={80} className="text-primary" />,
      title: 'Código Enviado',
      onClickFunction: async () => {
        if (password === confirmPassword) {
          setIsLoading(true);
          setErr('');
          const res = await resetPassWord(userId, password, confirmPassword);
          if (res === true) {
            setPage(page + 1);
          } else if (res === false) {
            setIsLoading(false);
            setErr('Não foi possível alterar a senha');
          } else {
            setIsLoading(false);
            setErr(dictionaryError(res));
          }
        } else {
          setErr('Senha de confirmação deve ser igual a senha');
        }
      },
    },
    {
      buttonTitle: 'Login',
      description: 'Click no botão abaixo para ir para a tela de login',
      Icon: <Check2Circle size={80} className="mb-10 text-primary" />,
      title: 'Senha alterada com sucesso',
      onClickFunction: () => navigate('/session/login'),
    },
  ];
  return (
    <div className="mt-10 lg:mt-0 lg:pl-16 w-full lg:w-4/6 flex flex-col lg:flex-row gap-8 relative z-2 items-center justify-evenly">
      <div className="flex flex-col justify-center items-center bg-white w-full md:max-w-[420px] rounded-[30px] px-[38px] py-[53px] lg:py-8 2xl:py-[38px] lg:max-w-sm min-h-[480px] border border-solid border-primary">
        <Information element={pages[page]} />
      </div>
    </div>
  );
}
const CodeVerify: React.FC = () => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    const root = document.documentElement;
    const color = getItem('color-hex') || '#541554';
    root.style.setProperty('--primary', color);
    root.style.setProperty('--secondary', lighten(0.1, color));
  }, []);
  return (
    <div className="overflow-y-auto flex flex-col bg-[#FCF7F2] h-screen lg:overflow-hidden">
      <div className="main w-full min-h-fit h-fit xl:min-h-screen pb-6 bg-[#FCF7F2]">
        {!active ? (
          <Header active={active} setActive={setActive} />
        ) : (
          <Menu setNotActive={setActive} />
        )}

        <img
          src={backgroundImageMobile}
          alt="backgroundImage"
          className="w-full absolute top-0 z-0 lg:hidden brightness-75"
        />
        <img
          src={backgroundImage}
          alt="backgroundImage"
          className="hidden bg-contain lg:flex lg:w-full absolute top-0 z-0 lg:min-h-[60vh] max-w-none brightness-75"
        />
        <div className="flex flex-col lg:flex-row px-2 lg:px-10 lg:px-10 h-full items-center justify-center">
          <RecoveryPassCard />
        </div>
      </div>
    </div>
  );
};

export default CodeVerify;
