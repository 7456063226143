import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { Button, Modal, Spinner } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import maskHelper from '../../../../helpers/mask.helper';
import { ToastNotify } from './Toast/toastnotify';
import { ApiContract } from '../../api';

/* eslint-disable no-unused-vars */
interface Props {
  annotations: {
    id: number;
    companie_user_id: number;
    contract_dependent_id: number;
    description: string;
    created_at: string;
  }[];
  companieUserId: number;
  reloadNotes: (e: string) => void;
}

interface CreateProps {
  setShowModalCreateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showModalCreateUpdate: boolean;
  currentNote: null | any;
  companieUserId: number;
  // eslint-disable-next-line no-unused-vars
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
  reloadNotes: (e: string) => void;
}

function CreateUpdate({
  currentNote,
  setShowModalCreateUpdate,
  showModalCreateUpdate,
  companieUserId,
  notify,
  reloadNotes,
}: CreateProps) {
  const [note, setNote] = useState(currentNote?.description ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    let response;
    if (currentNote === null) {
      response = await ApiContract.createAnnotation({
        description: note,
        contract_dependent_id: companieUserId,
      });
      if (response.id) {
        notify({ message: 'Anotação salva', type: 'Success' });
        reloadNotes(`Nova anotação criada ${Math.random()}`);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
      }
    } else {
      response = await ApiContract.updateAnnotation(
        { description: note },
        currentNote.id
      );
      if (response.id) {
        notify({ message: 'Anotação atualizada', type: 'Success' });
        reloadNotes(`Anotação atualizada ${Math.random()}`);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
      }
    }
    setIsLoading(false);
    setShowModalCreateUpdate(false);
  };

  useEffect(() => {
    setNote(currentNote?.description ?? '');
  }, [currentNote, showModalCreateUpdate]);

  return (
    <Modal
      minHeight="min-h-[300px]"
      setIsOpen={setShowModalCreateUpdate}
      isOpen={showModalCreateUpdate}
      title={
        currentNote === null ? 'Criar Nova Anotação' : 'Atualizar Anotação'
      }
    >
      <p className="text-sm font-normal">Anotação</p>
      <textarea
        key={currentNote?.id || 'new'}
        name="textinput"
        className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-24 rounded-lg p-3 resize-none my-2"
        onChange={(e) => setNote(e.target.value)}
        value={note}
      />
      <Button
        variant="primary-strong"
        disabled={note.length === 0 || isLoading}
        onClick={Submit}
        className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
      >
        Salvar
        {isLoading && (
          <div className="absolute right-0">
            <Spinner />
          </div>
        )}
      </Button>
    </Modal>
  );
}

const Annotations: React.FC<Props> = ({
  annotations,
  companieUserId,
  reloadNotes,
}) => {
  const [currentNote, setCurrentNote] = useState<any | null>(null);
  const [show, setShow] = useState(false);
  const { notify } = ToastNotify();

  return (
    <>
      <CreateUpdate
        currentNote={currentNote}
        notify={notify}
        reloadNotes={(message) => reloadNotes(message)}
        companieUserId={companieUserId}
        showModalCreateUpdate={show}
        setShowModalCreateUpdate={setShow}
      />
      <div className="w-full px-4 mb-2">
        <Button
          actionType="button-add"
          className="w-56"
          variant="primary-strong"
          onClick={() => {
            setCurrentNote(null);
            setShow(true);
          }}
        >
          Adicionar Anotação
        </Button>
        <div className="relative w-full h-fit flex flex-col mt-5 sm:mt-0">
          <hr className="hidden sm:flex h-full top-0 left-1/2 w-px absolute bg-gray-300 z-0" />
          {annotations
            .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
            .map((note, index) =>
              index % 2 === 0 ? (
                <div
                  className="min-w-full h-32 flex justify-end mb-8"
                  key={note.id}
                >
                  <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-400 mr-3 z-10 mt-4">
                    <IconDictionary
                      name="Editar"
                      className="cursor-pointer"
                      onClick={() => {
                        setCurrentNote(note);
                        setShow(true);
                      }}
                    />
                  </div>
                  <div className="overflow-y-auto h-32 w-full sm:w-[46%] bg-primary/10 p-3 rounded border border-solid border-primary/30">
                    <div className="flex gap-2 items-center mb-0.5">
                      <IconDictionary name="Relogio" size={10} />
                      <p className="text-[10px]">
                        {maskHelper.formatDateDMY(note.created_at)}
                      </p>
                    </div>

                    <p className="text-sm mt-1">{note.description}</p>
                  </div>
                </div>
              ) : (
                <div
                  className="min-w-full flex flex-row-reverse sm:flex-row mb-8"
                  key={note.id}
                >
                  <div className="overflow-y-auto h-32 w-full sm:w-[46%] bg-primary/10 p-3 rounded border border-solid border-primary/30">
                    <div className="flex gap-2 items-center mb-0.5">
                      <IconDictionary name="Relogio" size={10} />
                      <p className="text-[10px]">
                        {maskHelper.formatDateDMY(note.created_at)}
                      </p>
                    </div>
                    <p className="text-sm mt-1">{note.description}</p>
                  </div>
                  <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-400 mr-3 sm:ml-3 z-10 mt-4">
                    <IconDictionary
                      name="Editar"
                      className="cursor-pointer"
                      onClick={() => {
                        setCurrentNote(note);
                        setShow(true);
                      }}
                    />
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
};

export default Annotations;
