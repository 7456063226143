/* eslint-disable no-unused-vars */
import React from 'react';
import { ClientProps } from '../../../clients/types';
import { Address, Modal } from '../../../../components';
import { ApiClient } from '../../../clients/api';

interface props {
  client: ClientProps;
  setClient: (e: ClientProps) => void;
  show: boolean;
  setShow: (e: boolean) => void;
  save: () => void;
}

const ModalUpdateClientAdress: React.FC<props> = ({
  client,
  setClient,
  setShow,
  show,
  save,
}) => (
  <Modal
    isOpen={show}
    setIsOpen={setShow}
    size="2xlarge"
    minHeight="min-h-[550px]"
    title="Atualizar endereço cliente"
  >
    <p className="text-xs">
      Por favor atualize o endereço do cliente para gerar o lançamento no
      boleto.
    </p>
    <Address
      hasNumber
      apiFunction={ApiClient.updateAddress}
      defaultValue={{
        address: client.address ?? '',
        city: client.city ?? '',
        province: client.province ?? '',
        state: client.state ?? '',
        zipcode: client.zipcode ?? '',
        complement: client.complement,
        number: client.number,
      }}
      id={client.id}
      nextPage={(e) => {
        setClient(e);
        setShow(false);
        save();
      }}
    />
  </Modal>
);

export default ModalUpdateClientAdress;
