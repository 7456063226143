import React, { ReactNode } from 'react';
import IconDictionary from '../Icons/icons';

interface props {
  message: string | ReactNode;
}
const AlertCard: React.FC<props> = ({ message }) => (
  <div className="px-5 py-8 flex items-center gap-3 max-w-full h-10 border border-dashed border-gray-600">
    <IconDictionary
      size={24}
      name="AlertTriangle"
      className="text-yellow min-w-5"
    />
    <div className="text-xs">{message}</div>
  </div>
);

export default AlertCard;
