import { baseApiPrivate } from '../../../api/baseApi';
import { ActivityProps, activityStatus, filtersProps } from '../types';

async function getActivityFilters(
  period: string,
  filters: filtersProps
): Promise<{ data: ActivityProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/activities/filterAdvanced/${period}`,
    body: filters,
  });
}

async function seachActivity(
  key: string,
  skip: number,
  take: number
): Promise<{ data: ActivityProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/activities/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function createActivitie(e: any) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/activities',
    body: e,
  });
}

async function updateActivitie(e: any, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/activities/${id}`,
    body: e,
  });
}

async function updateActivitieStatus(e: activityStatus, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/activities/status/${id}`,
    body: e,
  });
}

async function deleteActivitie(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/activities/${id}`,
  });
}

export async function getActivitiesUser(userId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/activities/userInProgress/${userId}`,
  });
}

export const ApiActivitie = {
  getActivityFilters,
  seachActivity,
  createActivitie,
  updateActivitie,
  updateActivitieStatus,
  deleteActivitie,
  getActivitiesUser,
};
