import { baseApiPrivate } from '../../../../api/baseApi';
import { Module } from '../types';

async function getAllModule() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/module',
  });
}

async function createModule(e: Omit<Module, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/module',
    body: e,
  });
}

async function updateModule(e: Omit<Module, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/module/${id}`,
    body: e,
  });
}

async function deleteModule(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/module/${id}`,
  });
}

export const ApiModule = {
  getAllModule,
  createModule,
  updateModule,
  deleteModule,
};
