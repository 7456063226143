/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiDashboard } from '../../api';
import { sellersProps } from '../../types';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
}
const TopSellers: React.FC<props> = ({ companyId, period, funnelId }) => {
  const [data, setData] = useState<sellersProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const sellingProducts = await ApiDashboard.getSellers(
        companyId,
        funnelId,
        period
      );
      if (typeof sellingProducts !== 'string') {
        setData(sellingProducts);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [period, funnelId]);

  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Top 5 vendedores</p>
      <div className="flex flex-col mt-4">
        <div className="mb-4 grid grid-cols-4 w-full">
          <p className="text-xs font-medium col-span-2">Vendedor</p>
          <p className="text-xs font-medium mx-auto">Oportunidade</p>
          <p className="text-xs font-medium mx-auto">Valor</p>
        </div>
        {data.slice(0, 5).map((item) => (
          <>
            <div className="grid grid-cols-4 w-full justify-between">
              <p className="text-xs col-span-2">{item.name}</p>
              <p className="text-xs bg-green/20 rounded-lg p-1.5 mx-auto">
                {Number(item._count ?? '').toLocaleString('pt-br')}
              </p>
              <p className="text-xs bg-primary/20 rounded-lg p-1.5 mx-auto">
                {maskHelper.formatMoeda(item._sum ?? '')}
              </p>
            </div>
            <hr className="w-full h-px bg-[#dadada] my-2" />
          </>
        ))}
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center h-72">
      <Spinner />
    </div>
  );
};

export default TopSellers;
