import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { filterFinancials, filterFormProps } from '../types';
import { Button, Modal, SelectComponent, Spinner } from '../../../components';
import { Plans } from '../../chartAccounts/types';
import { useFetchContracts } from '../../contract/hooks';
import { useFetchClientDropdown } from '../../clients/hooks';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  restartPagination: () => void;
  show: boolean;
  planAccounts: Plans[];
  providerOptions: { label: string; value: number }[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: (e: filterFormProps) => void;
  filters: filterFormProps;
}

const SearchFinancial: React.FC<registerModalProps> = ({
  show,
  planAccounts,
  providerOptions,
  filters,
  setShow,
  setFilter,
  restartPagination,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const [type, setType] = useState('Todos');

  const [accountOptions, setAccountOptions] = useState<
    { label: string; value: number; isdisabled: boolean }[]
  >(
    planAccounts
      .filter((item) => item.num_plan.split('.').length > 1)
      .map((elem) => ({
        label: `${elem.num_plan} - ${elem.title}`,
        value: elem.id ?? 0,
        isdisabled: elem.num_plan.split('.').length === 2,
      }))
  );

  const [inputContract, setInputContract] = useState('');
  const [inputClient, setInputClient] = useState('');
  const { ClientsList, isLoadingClientsDropDown } = useFetchClientDropdown(
    '',
    0,
    100000000000,
    inputClient
  );

  const clientsOptions = ClientsList.data.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const { ContractsList, isLoadingList } = useFetchContracts(
    '',
    0,
    100000000000,
    inputContract
  );
  const [contractsOptions, setContractsOptions] = useState<
    { label: string; value: number }[]
  >([]);

  const paymentForm = [
    { value: 'boleto', label: 'Boleto' },
    { value: 'creditcard', label: 'Cartão de crédito' },
    { label: 'PIX', value: 'pix' },
    { label: 'Dinheiro', value: 'dinheiro' },
    { label: 'TED', value: 'ted' },
  ];

  const typeForm = [
    { value: 'Todos', label: 'Todos' },
    { value: 'R', label: 'Receita' },
    { value: 'D', label: 'Despesa' },
  ];

  const received = [
    { value: null, label: 'Todos' },
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ];

  const statusOptions = [
    { value: 'Paga', label: 'Paga' },
    { value: 'Aberta', label: 'Aberta' },
    { value: 'Cancelada', label: 'Cancelada' },
    { value: 'Vencida', label: 'Vencida' },
  ];

  const statusContract = [
    { value: 'Ag.Pagamento', label: 'Aguardando Pagamento' },
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Inadimplente', label: 'Inadimplente' },
    { value: 'Interrompido', label: 'Interrompido' },
    { value: 'Cancelado', label: 'Cancelado' },
  ];

  const orderOptions = [
    { value: 'due_date_asc', label: 'Data vencimento crescente' },
    { value: 'due_date_desc', label: 'Data vencimento decrescentete' },
    { value: 'received_date_asc', label: 'Data recebimento crescente' },
    { value: 'received_date_desc', label: 'Data recebimento decrescentete' },
  ];

  const { formState, handleSubmit, setValue, reset, control, getValues } =
    useForm<filterFormProps>({
      defaultValues: {
        client_id: [],
        contract_id: [],
        type: 'Todos',
        form_of_payment: [],
        status: [],
        received: received[0].value,
        order: 'due_date_asc',
        pla_account_id: [],
        provider_id: [],
      },
    });

  const Submit = async (e: filterFormProps) => {
    setFilter(e);
    setShow(false);
    restartPagination();
  };
  useEffect(() => {
    reset(filters);
    setAccountOptions(
      planAccounts
        .filter((item) => item.num_plan.split('.').length > 1)
        .map((elem) => ({
          label: `${elem.num_plan} - ${elem.title}`,
          value: elem.id ?? 0,
          isdisabled: elem.num_plan.split('.').length === 2,
        }))
    );
  }, [show]);

  useEffect(() => {
    const clientIds = getValues('client_id').map((item) => item.value);
    if (clientIds.length > 0) {
      setContractsOptions(
        ContractsList.data
          .filter((item) => clientIds.includes(item.client_id))
          .map((elem) => ({
            label: `${elem.number} - ${elem.client_name}`,
            value: elem.id ?? 0,
          }))
      );
    } else {
      setContractsOptions(
        ContractsList.data.map((elem) => ({
          label: `${elem.number} - ${elem.client_name}`,
          value: elem.id ?? 0,
        }))
      );
    }
  }, [ContractsList]);

  useEffect(() => {
    if (type === 'Todos') {
      setAccountOptions(
        planAccounts
          .filter((item) => item.num_plan.split('.').length > 1)
          .map((elem) => ({
            label: `${elem.num_plan} - ${elem.title}`,
            value: elem.id ?? 0,
            isdisabled: elem.num_plan.split('.').length === 2,
          }))
      );
    } else {
      setAccountOptions(
        planAccounts
          .filter(
            (item) => item.type === type && item.num_plan.split('.').length > 1
          )
          .map((elem) => ({
            label: `${elem.num_plan} ${elem.title}`,
            value: elem.id ?? 0,
            isdisabled: elem.num_plan.split('.').length === 2,
          }))
      );
    }
  }, [type]);

  const ClearData = async () => {
    setIsLoadingClear(true);
    const defaultFilter: filterFinancials = {
      client_id: [],
      contract_id: [],
      type: 'Todos',
      form_of_payment: [],
      status: [],
      received: received[0].value,
      order: 'due_date_asc',
      pla_account_id: [],
      provider_id: [],
      status_contract: [],
      skip: 0,
      take: 10,
    };
    await Submit({ ...defaultFilter, client_id: [], contract_id: [] });
    reset({ ...defaultFilter, client_id: [], contract_id: [] });
    restartPagination();

    setIsLoadingClear(false);
  };

  return (
    <Modal
      title="Procurar Contas"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[745px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectComponent
              title="Tipo"
              options={typeForm}
              defaultValue={typeForm[0]}
              closeMenuOnSelect
              value={typeForm.filter((item) =>
                field.value.includes(item.value)
              )}
              onChange={(e: any) => {
                setType(e.value);
                setValue('type', e.value);
                if (e.value === 'R') {
                  setValue('client_id', []);
                  setValue('provider_id', []);
                } else if (e.value === 'D') {
                  setValue('provider_id', []);
                  setValue('contract_id', []);
                  setValue('client_id', []);
                }
              }}
              errorMensage={formState.errors.type?.message}
            />
          )}
        />

        <Controller
          name="pla_account_id"
          control={control}
          render={({ field }) => (
            <SelectComponent
              hasDivision
              maxOptionsHeight="280px"
              title="Plano de contas"
              value={accountOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              options={accountOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'pla_account_id',
                  e.map((plan) => plan.value)
                );
              }}
            />
          )}
        />
        <Controller
          name="order"
          control={control}
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              value={orderOptions.filter((item) => field.value === item.value)}
              options={orderOptions}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
            />
          )}
        />

        <Controller
          name="client_id"
          control={control}
          defaultValue={filters.client_id}
          render={({ field }) => (
            <SelectComponent
              title="Clientes"
              value={field.value}
              isDisabled={type === 'D'}
              options={clientsOptions}
              isMulti
              closeMenuOnSelect={false}
              maxOptionsHeight="150px"
              onInputChange={setInputClient}
              autoComplete
              isClearable
              isLoading={isLoadingClientsDropDown}
              onChange={(e: any[]) => {
                setValue('client_id', e);
                if (e.length > 0) {
                  setValue('contract_id', []);
                  setContractsOptions([]);
                } else {
                  setContractsOptions(
                    ContractsList.data.map((elem) => ({
                      label: `${elem.number} ${elem.client_name}`,
                      value: elem.id ?? 0,
                    }))
                  );
                }
              }}
            />
          )}
        />

        <Controller
          name="provider_id"
          control={control}
          render={({ field }) => (
            <SelectComponent
              title="Fornecedor"
              value={providerOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              isDisabled={type === 'R'}
              options={providerOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'provider_id',
                  e.map((res) => res.value)
                );
              }}
            />
          )}
        />

        <Controller
          name="contract_id"
          control={control}
          defaultValue={filters.contract_id}
          render={({ field }) => (
            <SelectComponent
              title="Contratos"
              options={contractsOptions}
              value={field.value}
              onInputChange={setInputContract}
              autoComplete
              isClearable
              isLoading={isLoadingList}
              isDisabled={type === 'D'}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue('contract_id', e);
              }}
            />
          )}
        />
        <div className="col-span-2">
          <Controller
            name="status_contract"
            defaultValue={filters.status_contract}
            control={control}
            render={({ field }) => (
              <SelectComponent
                title="Status do contrato"
                value={statusContract.filter((item) =>
                  field.value.includes(item.value)
                )}
                options={statusContract}
                closeMenuOnSelect
                isMulti
                maxOptionsHeight="300px"
                onChange={(e: any[]) => {
                  setValue(
                    'status_contract',
                    e.map((res) => res.value)
                  );
                }}
                errorMensage={formState.errors.status?.message}
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <SelectComponent
                value={statusOptions.filter((item) =>
                  field.value.includes(item.value)
                )}
                title="Status cobrança"
                options={statusOptions}
                closeMenuOnSelect
                isMulti
                maxOptionsHeight="300px"
                onChange={(e: any[]) => {
                  setValue(
                    'status',
                    e.map((res) => res.value)
                  );
                }}
                errorMensage={formState.errors.status?.message}
              />
            )}
          />
        </div>

        <Controller
          name="received"
          control={control}
          render={({ field }) => (
            <SelectComponent
              value={received.filter((item) => field.value === item.value)}
              title="Recebido"
              options={received}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('received', e.value);
              }}
              errorMensage={formState.errors.received?.message}
            />
          )}
        />

        <Controller
          name="form_of_payment"
          control={control}
          render={({ field }) => (
            <SelectComponent
              value={paymentForm.filter((item) =>
                field.value.includes(item.value)
              )}
              isMulti
              title="Formas de pagamento"
              maxOptionsHeight="150px"
              options={paymentForm}
              closeMenuOnSelect
              onChange={(e: any[]) => {
                setValue(
                  'form_of_payment',
                  e.map((item) => item.value)
                );
              }}
              errorMensage={formState.errors.form_of_payment?.message}
            />
          )}
        />

        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear}
            variant="outline-primary"
            onClick={ClearData}
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Limpar
            {isLoadingClear && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
          </Button>
          <Button
            disabled={isLoading}
            isLoading={!isLoadingClear && isLoading}
            variant="primary-strong"
            type="submit"
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchFinancial;
