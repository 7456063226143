import React, { useState } from 'react';
import { FormResponse, SearchFormProps } from '../../types';
import TitleForm from './typeQuestions/title';
import QuestionContainer from './typeQuestions/questionContainer';
import { Button } from '../../../../components';
import { ApiSearchForm } from '../../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
/* eslint-disable no-unused-vars */

const FormClient: React.FC<{
  defaultValue: SearchFormProps;
  opportunityId: number;
}> = ({ defaultValue, opportunityId }) => {
  const [finished, setFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = ToastNotify();
  const [data, setData] = useState<FormResponse>({
    opportunity_id: opportunityId,
    responses: [],
    search_form_id: defaultValue.id,
  });
  const handleReset = () => {
    setFinished(false);
    setData({
      opportunity_id: opportunityId,
      responses: [],
      search_form_id: defaultValue.id,
    });
  };

  const handleSendForm = async () => {
    setIsLoading(true);
    if (data.responses[0].options.length > 0) {
      const res = await ApiSearchForm.createSearchResponseForm(data);
      if (typeof res !== 'string') {
        notify({
          message: 'Resposta enviada com sucesso! com sucesso!',
          type: 'Success',
        });
        setFinished(true);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      notify({
        message: 'Não foi possível enviar. Formulário está vazio',
        type: 'Error',
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col md:w-2/3 2xl:w-2/4 mx-auto pb-28">
      <TitleForm
        finished={finished}
        handleReset={handleReset}
        title={defaultValue.title}
      />
      {!finished && (
        <>
          {defaultValue.search_form_questions.map((item) => (
            <QuestionContainer
              id={item.id}
              questionId={item.id}
              answers={data}
              questions={{
                id: item.id,
                is_active: item.is_active,
                options: item.search_form_questions_options,
                title: item.title,
                type_response: item.type_response,
              }}
              updateAnswers={setData}
            />
          ))}
          <Button
            actionType="button-loading"
            className="mx-auto mt-8"
            onClick={handleSendForm}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Enviar
          </Button>
        </>
      )}
    </div>
  );
};

export default FormClient;
