import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RegisterCampaign } from '../components';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { useFetchWhatsappCampaignDropdown } from '../hooks';
import { useFetchWhatsappInstances } from '../../whatsapp/hook';
import { ApiWhatsappCampaign } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { CampaignUtils } from '../utils';
import { Spinner } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';

// import { Container } from './styles';

const CreateEditCampaign: React.FC = () => {
  const { planId, campaignId } = useParams();
  const { notify } = ToastNotify();

  const navigate = useNavigate();
  const accountProps = getTokenAccountInformation();
  const [registerPage, setRegisterPage] = useState({
    title: 'Busque os Contatos',
    page: 0,
  });

  const [key, setKey] = useState<string>('');
  const { WhatsappCampaignList, isLoadingWhatsappDropdown } =
    useFetchWhatsappCampaignDropdown(0, 999999999999, key);

  const { InstancesWhatsApp } = useFetchWhatsappInstances(Number(planId), '');

  const [campaign, setCampaign] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchById = async () => {
      setIsLoading(true);
      const res = await ApiWhatsappCampaign.getWhatsappCampaignId(
        Number(campaignId)
      );
      if (res.id) {
        setCampaign(res);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
        navigate('/bulk-shipping');
      }
      setIsLoading(false);
    };

    if (campaignId !== 'create') {
      fetchById();
    }
  }, []);

  return (
    <div className="min-h-full h-fit flex flex-col">
      {campaign !== null ? (
        <div className="flex flex-col">
          <strong className="text-lg text-black">{campaign.title}</strong>
          <div className="flex gap-1 text-xs">
            <p className="text-medium">Status:</p>
            {CampaignUtils.getTextByStatus(campaign.status)}
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <strong className="text-lg text-black mb-8">
            Criação de Campanha
          </strong>
        </div>
      )}
      <IconDictionary
        onClick={() => navigate('/bulk-shipping')}
        size={24}
        className="mt-2 hover:scale-110 cursor-pointer min-h-[24px]"
        name="Seta voltar"
      />
      {!isLoading ? (
        <RegisterCampaign
          isLoadingWhatsappDropdown={isLoadingWhatsappDropdown}
          setInputSelect={setKey}
          setCampaign={setCampaign}
          instances={InstancesWhatsApp.filter(
            (elem) => elem.status === 'Conectada' && elem.is_bulk_shipping
          )}
          campaignList={WhatsappCampaignList.data}
          companieId={accountProps.companie_id}
          campaign={campaign}
          registerPage={registerPage}
          setRegisterPage={setRegisterPage}
          reloadTable={() => {}}
        />
      ) : (
        <div className="mx-auto min-h-full flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CreateEditCampaign;
