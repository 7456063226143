import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Modal, SelectComponent } from '../../../components';
import { filtersOfferProps } from '../types';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  period: string;
  profileId: number;
  restartPagination: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  userOptions: { label: string; value: number }[];
  clientsOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  setFilter: (e: filtersOfferProps) => void;
  setClientInput: (e: string) => void;
  setInput: (e: string) => void;
  isLoadingClients: boolean;
  filters: filtersOfferProps;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdownOpportunity: boolean;
}

const SearchOffers: React.FC<registerModalProps> = ({
  show,
  setShow,
  opportunityList,
  clientsOptions,
  userOptions,
  filters,
  period,
  profileId,
  isLoadingClients,
  isLoadingDropdownOpportunity,
  setInputOpportunity,
  setInput,
  setFilter,
  setClientInput,
  restartPagination,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
    status: yup.string().required('Selecione uma opção'),
  });

  const { formState, handleSubmit, setValue, reset, control } =
    useForm<filtersOfferProps>({
      resolver: yupResolver(schema),
      defaultValues: filters,
    });

  const orderOptions = [
    { value: 'created_at_desc', label: 'Data decrescentete' },
    { value: 'created_at_asc', label: 'Data crescente' },
  ];

  const statusOptions = [
    { label: 'Todos', value: 'Todos' },
    { label: 'Aberta', value: 'Aberta' },
    { label: 'Cancelada', value: 'Cancelada' },
    { label: 'Em Negociação', value: 'Em Negociação' },
    { label: 'Fechada', value: 'Fechada' },
    { label: 'Perdida', value: 'Perdida' },
  ];

  const Submit = async (e: filtersOfferProps) => {
    setIsLoadingClear(true);
    setInput('');
    setFilter(e);
    setShow(false);
    restartPagination();
    setIsLoadingClear(false);
  };

  const ClearData = async () => {
    setIsLoading(true);
    const defaultFilter: filtersOfferProps = {
      personId: [],
      opportunityId: [],
      companieUserId: [],
      order: 'created_at_desc',
      status: 'Todos',
      filter: period,
      date_end: '',
      date_start: '',
      skip: 0,
      take: 10,
    };
    await Submit(defaultFilter);
    reset(defaultFilter);
    restartPagination();
    setIsLoading(false);
  };
  useEffect(() => {
    reset(filters);
  }, [show]);
  return (
    <Modal
      title="Procurar Contrato"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[500px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="order"
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              options={orderOptions}
              value={orderOptions.filter((item) => field.value === item.value)}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              defaultValue={statusOptions[0]}
              value={statusOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              maxOptionsHeight="300px"
              onChange={(e: any) => {
                setValue('status', e.value);
              }}
              errorMensage={formState.errors.status?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="personId"
          defaultValue={filters.personId}
          render={({ field }) => (
            <SelectComponent
              title="Clientes"
              options={clientsOptions}
              value={field.value}
              isMulti
              isLoading={isLoadingClients}
              onInputChange={setClientInput}
              autoComplete
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue('personId', e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="companieUserId"
          render={({ field }) => (
            <SelectComponent
              title="Usuários Promotor"
              options={userOptions}
              value={userOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              isDisabled={profileId === 5}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'companieUserId',
                  e.map((user) => user.value)
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="opportunityId"
          defaultValue={filters.opportunityId}
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              isLoading={isLoadingDropdownOpportunity}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              title="Oportunidades"
              maxOptionsHeight="120px"
              options={opportunityList}
              isMulti
              closeMenuOnSelect
              onChange={(e: any[]) => {
                setValue('opportunityId', e);
              }}
            />
          )}
        />
        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear || isLoading}
            variant="outline-primary"
            onClick={ClearData}
            className="font-bold flex items-center justify-center mt-4 w-64"
          >
            Limpar
          </Button>
          <Button
            disabled={isLoadingClear || isLoading}
            variant="primary-strong"
            actionType="button-loading"
            isLoading={isLoadingClear || isLoading}
            type="submit"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchOffers;
