/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal } from '../../../../../../components';

interface props {
  show: boolean;
  type: 'PJ' | 'PF' | '';
  setShow: (e: boolean) => void;
  setType: (e: 'PJ' | 'PF' | '') => void;
  confirm: () => void;
}

const ModalCompanyType: React.FC<props> = ({
  setShow,
  setType,
  confirm,
  show,
  type,
}) => (
  <Modal
    size="medium"
    isOpen={show}
    setIsOpen={setShow}
    title="Tipo de Conta"
    minHeight="min-h-[100px]"
  >
    <div className="flex flex-col">
      <fieldset className="text-sm mb-5">
        <legend className="text-sm mb-5 ">
          Qual tipo de conta deseja criar?
        </legend>
        <label
          htmlFor="PJ"
          className="flex gap-2 w-fit cursor-pointer"
          onClick={() => setType('PJ')}
        >
          <input
            checked={type === 'PJ'}
            name="PJ"
            type="radio"
            className="cursor-pointer"
            onChange={() => setType('PJ')}
          />
          Pessoa Jurídica
        </label>

        <label
          htmlFor="PF"
          className="flex gap-2 w-fit cursor-pointer"
          onClick={() => setType('PF')}
        >
          <input
            checked={type === 'PF'}
            name="PF"
            type="radio"
            className="cursor-pointer"
            onChange={() => setType('PF')}
          />
          Pessoa Física
        </label>
      </fieldset>

      <Button
        className="disabled:opacity-60"
        actionType="button-full-width"
        variant="primary-strong"
        onClick={confirm}
        disabled={type === ''}
      >
        Confirmar
      </Button>
    </div>
  </Modal>
);

export default ModalCompanyType;
