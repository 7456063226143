import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import FileSaver from 'file-saver';

import * as XLSX from 'xlsx';
import moment from 'moment';
import maskHelper from '../../../../helpers/mask.helper';
import {
  Button,
  Card,
  SelectComponent,
  isLoadingApplication,
} from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import { ApiBalanceReports } from '../api';
import { indicatorsProps } from '../types';

function formatTwoDigits(number: number) {
  if (number < 10) {
    return `0${number}`;
  }
  return String(number);
}

function orderByMonth(meses: string[]) {
  const ordemMeses = [
    `janeiro_${moment().year()}`,
    `fevereiro_${moment().year()}`,
    `março_${moment().year()}`,
    `abril_${moment().year()}`,
    `maio_${moment().year()}`,
    `junho_${moment().year()}`,
    `julho_${moment().year()}`,
    `agosto_${moment().year()}`,
    `setembro_${moment().year()}`,
    `outubro_${moment().year()}`,
    `novembro_${moment().year()}`,
    `dezembro_${moment().year()}`,
  ];

  meses.sort((a, b) => {
    const indiceA = ordemMeses.indexOf(a.toLowerCase());
    const indiceB = ordemMeses.indexOf(b.toLowerCase());
    return indiceA - indiceB;
  });

  return meses;
}

const BalanceSheetReports: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const dateFilterOptions = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const paymentOption = [
    { value: 'received_date', label: 'Pagamento' },
    { value: 'due_date', label: 'Vencimento' },
  ];

  const years: { value: string; label: string }[] = [];
  const currentYear = moment().year();
  for (let year = 2022; year <= currentYear + 5; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }

  const [filterDateMonth, setFilterDateMonth] = useState(
    formatTwoDigits(moment().month() + 1)
  );

  const [filterDateYear, setFilterDateYear] = useState(String(currentYear));

  const [typeDate, setTypeDate] = useState<'due_date' | 'received_date'>(
    'received_date'
  );
  const [isLoading, setIsLoading] = useState(true);

  const [balanceList, setBalanceList] = useState<any[]>([]);
  const [keysBalance, setKeysBalance] = useState<any[]>([]);
  const [indicators, setIndicators] = useState<indicatorsProps>();

  const filterTable = () => balanceList;

  const excelData = [
    ['Código', 'Descrição', 'Tipo', ...keysBalance],
    ...filterTable().map((item) => [
      item.num_plan,
      item.title,
      item.type === 'R' ? 'Receita' : 'Despesa',
      ...keysBalance.map((elem) => Number(item[elem] ?? '')),
    ]),
  ];

  const exportToXLS = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const csvData: any = [];
    const keys = excelData[0];
    const values = excelData.slice(1, excelData.length);
    let item: any = {};

    for (let j = 0; j < values.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        item[keys[i]] = values[j][i];
      }
      csvData.push(item);
      item = {};
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `balancete${fileExtension}`);
  };

  const [contractStatus, setContractStatus] = useState<
    { label: string; value: string }[]
  >([]);

  const FetchReports = async () => {
    setIsLoading(true);
    setState(true);
    const res = await ApiBalanceReports.getBalance(
      `${filterDateMonth}/${filterDateYear}`,
      typeDate,
      contractStatus.map((item) => item.value)
    );
    if (typeof res !== 'string') {
      setBalanceList(res);
      setKeysBalance(
        orderByMonth(
          Object.keys(res[0]).filter(
            (item) => item.includes('_') && item !== 'num_plan'
          )
        )
      );
    } else {
      setBalanceList([]);
    }
    setIsLoading(false);
    setState(false);
  };

  const statusContract = [
    { value: 'Ag.Pagamento', label: 'Aguardando Pagamento' },
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Inadimplente', label: 'Inadimplente' },
    { value: 'Interrompido', label: 'Interrompido' },
    { value: 'Cancelado', label: 'Cancelado' },
  ];

  const FetchIndicators = async () => {
    setIsLoading(true);
    setState(true);
    const res = await ApiBalanceReports.getIndicators(
      `${filterDateMonth}/${filterDateYear}`,
      typeDate,
      contractStatus.map((item) => item.value)
    );
    if (typeof res !== 'string') {
      setIndicators(res);
    }
    setIsLoading(false);
    setState(false);
  };

  useEffect(() => {
    FetchReports();
    FetchIndicators();
  }, []);

  const formatMonthKey = (str: string) => {
    const mon = str.split('_')[0];
    return mon.charAt(0).toUpperCase() + mon.slice(1);
  };

  const getChildrenTotal = (parentNum: string, month: string) =>
    String(
      balanceList
        .filter((children) => children.superior === parentNum)
        .reduce((prev, acc) => prev + Number(acc[month]), 0)
    );

  const getChildrenLevelTwoTotal = (parentNum: string, month: string) => {
    const childrens = balanceList.filter(
      (children) => children.superior === parentNum
    );
    const total = childrens.reduce(
      (prev, cur) => prev + Number(getChildrenTotal(cur.num_plan, month)),
      0
    );
    return String(total);
  };

  const getChildrenLevelThreeTotal = (month: string, parent: string) => {
    const childrenLevelOne = balanceList
      .filter((item) => item.superior === parent)
      .map((ele) => ele.num_plan);
    return String(
      balanceList
        .filter(
          (children) =>
            children.num_plan.split('.').length === 3 &&
            childrenLevelOne.includes(children.superior)
        )
        .reduce((prev, acc) => prev + Number(acc[month]), 0)
    );
  };
  const getTotal = (month: string, type: string) =>
    String(
      balanceList
        .filter(
          (children) =>
            children.num_plan.split('.').length === 3 && children.type === type
        )
        .reduce((prev, acc) => prev + Number(acc[month]), 0)
    );
  return (
    <>
      <div className="flex gap-3 items-center text-primary mb-8">
        <IconDictionary name="Relatório Balancete" size={24} />
        <strong className="text-lg text-black">Relatório Balancete</strong>
      </div>
      <Card className="w-full px-4 pt-5 flex-col pb-3.5 gap-4 mb-3">
        <strong>Filtrar por</strong>
        <div className="flex justify-between flex-col xl:flex-row items-center xl:items-start">
          <div className="grid grid-cols-1 sm:grid-cols-3 xl:flex w-full gap-3">
            <SelectComponent
              variant="outline-primary"
              value={paymentOption.find((item) => item.value === typeDate)}
              className="w-full xl:w-44"
              options={paymentOption}
              closeMenuOnSelect
              onChange={(e: any) => {
                setTypeDate(e.value);
              }}
              maxOptionsHeight="300px"
            />
            <SelectComponent
              variant="outline-primary"
              className="w-full xl:w-44"
              value={contractStatus}
              placeholder="Status contrato"
              isMulti
              onChange={(e: any) => {
                setContractStatus(e);
              }}
              options={statusContract}
              closeMenuOnSelect
              maxOptionsHeight="300px"
            />
            <SelectComponent
              variant="outline-primary"
              icon="Calendario"
              value={dateFilterOptions.find(
                (item) => item.value === filterDateMonth
              )}
              className="w-full xl:w-44"
              options={dateFilterOptions}
              closeMenuOnSelect
              onChange={(e: any) => {
                setFilterDateMonth(e.value);
              }}
              maxOptionsHeight="300px"
            />
            <SelectComponent
              variant="outline-primary"
              value={years.find((item) => item.value === filterDateYear)}
              className="w-full xl:w-44"
              options={years}
              closeMenuOnSelect
              onChange={(e: any) => {
                setFilterDateYear(e.value);
              }}
              maxOptionsHeight="300px"
            />
          </div>

          <div className="flex gap-3 mt-2 xl:mt-0">
            <Button
              variant="primary-strong"
              className="mb-1.5 w-36"
              onClick={() => {
                FetchReports();
                FetchIndicators();
              }}
              isLoading={isLoading}
              actionType="button-simple"
            >
              Carregar
            </Button>
            <Button
              actionType="button-simple"
              variant="outline-primary"
              className="flex justify-center items-center mb-1.5 gap-2 w-36"
              onClick={exportToXLS}
            >
              <IconDictionary name="Download" /> Exportar
            </Button>
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-3">
        <Card className="h-24 justify-between p-4 text-xs w-full">
          <p className="uppercase">Saldo Anterior</p>
          <h2 className="text-xl place-self-end font-medium">
            {maskHelper.formatMoeda(indicators?.saldo_anterior)}
          </h2>
        </Card>
        <Card className="h-24 justify-between p-4 text-xs w-full">
          <p className="uppercase">Total Receita</p>

          <h2 className="text-xl place-self-end font-medium text-green">
            {maskHelper.formatMoeda(indicators?.receita)}
          </h2>
        </Card>
        <Card className="h-24 justify-between p-4 text-xs w-full">
          <p className="uppercase">Total Despesa</p>

          <h2 className="text-xl place-self-end font-medium text-red">
            {maskHelper.formatMoeda(indicators?.despesa)}
          </h2>
        </Card>
        <Card className="h-24 justify-between p-4 text-xs w-full">
          <p className="uppercase">Saldo Final</p>
          <h2 className="text-xl place-self-end font-medium text-primary">
            {maskHelper.formatMoeda(indicators?.saldo)}
          </h2>
        </Card>
      </div>

      <Card className="mt-3 px-4 py-5 flex-col min-h-[80vh]">
        <div className="overflow-x-auto max-w-full">
          {balanceList.length > 0 && (
            <>
              <div className="grid grid-cols-11 gap-x-2 min-w-[900px] font-semibold text-[11px] gap-0 items-center bg-[#E6E6E6] py-2 px-3">
                <p className="w-24">Código</p>
                <p className="col-span-2">Descrição</p>

                {keysBalance.map((elem) => (
                  <>
                    <p>{formatMonthKey(elem)}</p>
                    <p>%</p>
                  </>
                ))}
              </div>

              <div className="grid grid-cols-11 gap-1.5 w-full min-w-[900px] text-sm gap-0 items-center px-3 mt-4 divide-solid divide-gray-600">
                {balanceList.map((item: any) => (
                  <>
                    <p className="font-bold text-xs">{item.num_plan}</p>
                    <p
                      className={classNames([
                        'col-span-2 text-xs',
                        item.num_plan.length === 1 && 'font-bold',
                        item.num_plan?.length > 1 && 'ml-6',
                        item.superior &&
                          item.superior.length > 1 &&
                          'ml-[60px]',
                      ])}
                    >
                      {item.title}
                    </p>
                    {keysBalance.map((month) => (
                      <>
                        <p className="my-auto text-xs">
                          {item.num_plan.split('.').length < 3
                            ? item.num_plan.length === 1
                              ? maskHelper.formatMoeda(
                                  getChildrenLevelThreeTotal(
                                    month,
                                    item.num_plan
                                  )
                                )
                              : maskHelper.formatMoeda(
                                  getChildrenTotal(item.num_plan, month)
                                )
                            : maskHelper.formatMoeda(item[month])}
                        </p>
                        {item.num_plan.split('.').length === 3 && (
                          <p className="my-auto text-xs">
                            {(
                              (Number(item[month]) /
                                Number(
                                  getChildrenTotal(item.superior, month)
                                )) *
                              100
                            ).toFixed(0) !== 'NaN'
                              ? (
                                  (Number(item[month]) /
                                    Number(
                                      getChildrenTotal(item.superior, month)
                                    )) *
                                  100
                                ).toFixed(0)
                              : '0'}
                          </p>
                        )}
                        {item.num_plan.split('.').length === 2 && (
                          <p className="my-auto text-xs">
                            {(
                              (Number(getChildrenTotal(item.num_plan, month)) /
                                Number(
                                  getChildrenLevelTwoTotal(item.superior, month)
                                )) *
                              100
                            ).toFixed(0) !== 'NaN'
                              ? (
                                  (Number(
                                    getChildrenTotal(item.num_plan, month)
                                  ) /
                                    Number(
                                      getChildrenLevelTwoTotal(
                                        item.superior,
                                        month
                                      )
                                    )) *
                                  100
                                ).toFixed(0)
                              : '0'}
                          </p>
                        )}
                        {item.num_plan.length === 1 && (
                          <p className="my-auto text-xs">
                            {(
                              (Number(
                                getChildrenLevelTwoTotal(item.num_plan, month)
                              ) /
                                Number(getTotal(month, item.type))) *
                              100
                            ).toFixed(0) === 'NaN'
                              ? '0'
                              : (
                                  (Number(
                                    getChildrenLevelTwoTotal(
                                      item.num_plan,
                                      month
                                    )
                                  ) /
                                    Number(getTotal(month, item.type))) *
                                  100
                                ).toFixed(0)}
                          </p>
                        )}
                      </>
                    ))}

                    <hr className="col-span-11 my-3 w-full bg-gray-400 h-px border-0" />
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default BalanceSheetReports;
