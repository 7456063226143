import { baseApiPrivate } from '../../../../api/baseApi';

async function getTotalSales(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalSales/${companyId}/${funnelId}/${period}`,
  });
}

async function getTotalPredicted(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalPredicted/${companyId}/${funnelId}/${period}`,
  });
}

async function getReasonsGained(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/reasonsGained/${companyId}/${funnelId}/${period}`,
  });
}

async function getReasonsLoss(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/reasonsLoss/${companyId}/${funnelId}/${period}`,
  });
}

async function getSellers(companyId: number, funnelId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/sellers/${companyId}/${funnelId}/${period}`,
  });
}

async function getOpportuniyByStep(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/opportuniyByStep/${companyId}/${funnelId}/${period}`,
  });
}

async function getSalesChannel(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/salesChannel/${companyId}/${funnelId}/${period}`,
  });
}

async function getSegments(
  companyId: number,
  funnelId: number,
  period: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/segments/${companyId}/${funnelId}/${period}`,
  });
}

export const ApiDashboard = {
  getTotalPredicted,
  getReasonsGained,
  getSalesChannel,
  getSegments,
  getSellers,
  getReasonsLoss,
  getTotalSales,
  getOpportuniyByStep,
};
