import { baseApiPrivate } from '../../../api/baseApi';
import {
  createAddressOfferProps,
  createOfferProps,
  createProductOfferProps,
  payloadBackOffer,
  updateProductOfferProps,
} from '../types';

async function getOfferByCompany() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer`,
  });
}

async function updateObs(obs: { obs: string }, offerId: number) {
  return baseApiPrivate({
    method: 'PUT',
    body: obs,
    url: `companie/offer/obs/${offerId}`,
  });
}

async function getOfferById(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/${id}`,
  });
}

async function getProductsOffer(offerId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/product/${offerId}`,
  });
}

async function getAttachmentsOffer(offerId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/attachment/${offerId}`,
  });
}

async function getOfferFilters(filters: any) {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: `companie/offer/filterAdvanced`,
  });
}

async function getPdfOffer(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/pdf/${id}`,
    responseFile: true,
  });
}

async function sendEmailOffer(companieId: number, id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/sendMail/${companieId}/${id}`,
  });
}

async function createOffer(e: createOfferProps) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/offer',
    body: e,
  });
}
async function createAttachmentOffer(e: any) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/offer/attachment',
    body: e,
    file: true,
  });
}

async function createProductOffer(e: createProductOfferProps) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/offer/product',
    body: e,
  });
}

async function updateProductOffer(e: updateProductOfferProps, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/offer/personal/${id}`,
    body: e,
  });
}

async function updateOffer(
  e: Omit<createOfferProps, 'opportunity_id'>,
  id: number
) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/offer/personal/${id}`,
    body: e,
  });
}

async function updateOfferAddress(
  e: Omit<createAddressOfferProps, 'id'>,
  id: number
) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/offer/address/${id}`,
    body: e,
  });
}

async function updateStatusOffer(
  e: {
    status: 'Em Negociação' | 'Aberta' | 'Cancelada' | 'Fechada' | 'Perdida';
  },
  id: number
) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/offer/status/${id}`,
    body: e,
  });
}

async function seachOffer(
  key: string,
  skip: number,
  take: number
): Promise<payloadBackOffer | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function deleteOffer(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/offer/${id}`,
  });
}

async function deleteProductOffer(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/offer/product/${id}`,
  });
}

async function deleteAttachmentsOffer(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/offer/attachment/${id}`,
  });
}

export const ApiOffer = {
  getOfferByCompany,
  getOfferById,
  getOfferFilters,
  getProductsOffer,
  getPdfOffer,
  getAttachmentsOffer,
  sendEmailOffer,
  createOffer,
  createAttachmentOffer,
  createProductOffer,
  updateStatusOffer,
  updateOfferAddress,
  updateObs,
  updateOffer,
  updateProductOffer,
  seachOffer,
  deleteOffer,
  deleteProductOffer,
  deleteAttachmentsOffer,
};
