import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { CreateStepFunnel, Step } from '../types';
import { ApiFunnel } from '../api';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import { Button, Input, Modal, TextArea, Table } from '../../../components';
import SelectActivity from '../../activitieType/components/selectActivity';
import IconDictionary from '../../../components/Icons/icons';
import { attachment } from '../../admin/company/types';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  step: Step | null;
  funnelId: number;
  handleDelete: () => void;
}

const RegisterFunnelStep: React.FC<registerModalProps> = ({
  step,
  reloadTable,
  setShow,
  show,
  funnelId,
  handleDelete,
}) => {
  const { notify } = ToastNotify();

  const [values, setValues] = useState<CreateStepFunnel>({
    title: step?.title ?? '',
    funnel_id: step?.funnel_id ?? funnelId,
    path: step?.path ?? null,
    obs: step?.obs ?? null,
    activities:
      step?.funnel_step_activitie_type.map((item) => ({
        id: item.activitie_type.id,
        title: item.activitie_type.title,
      })) ?? [],
  });
  const [removedFile, setRemovedFile] = useState(false);

  const addActivity = (id: number, title: string) => {
    const existingActivity = values.activities.find(
      (activity: any) => activity.id === id
    );

    if (!existingActivity) {
      const newActivity = { id, title };
      setValues((prevValues: any) => ({
        ...prevValues,
        activities: [...prevValues.activities, newActivity],
      }));
    }
  };

  const removeActivityById = (id: number) => {
    const updatedActivities = values.activities.filter(
      (activity: any) => activity.id !== id
    );
    setValues((prevValues) => ({
      ...prevValues,
      activities: updatedActivities,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const [droppedFile, setDroppedFile] = useState<File | null>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setDroppedFile(fileCreateObj[0]);
      setValues({ ...values, path: fileCreateObj[0].path });
    },
  });

  const nameFile = values.path?.split('/').pop();
  const fileData =
    values.path !== null ? [{ title: nameFile, path: values.path }] : [];

  const SubmitFile = async (idStep: number, file: any) => {
    setIsLoading(true);
    const formData: any = new FormData();

    try {
      formData.append('funnel_step_id', idStep);
      formData.append('file', file);
    } catch {
      setIsLoading(false);
    }

    const res = await ApiFunnel.createStepFile(formData);
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      setIsLoading(false);
    }
  };

  const Submit = async () => {
    setIsLoading(true);
    if (step !== null) {
      if (droppedFile) {
        await SubmitFile(step.id, droppedFile);
      } else if (!droppedFile && removedFile) {
        await SubmitFile(step.id, droppedFile);
      }
      const res = await ApiFunnel.updateStep(
        {
          title: values.title,
          activities: values.activities.map((item) => item.id),
          obs: values.obs,
        },
        step.id
      );
      if (res.id) {
        notify({
          message: 'Etapa atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiFunnel.createFunnelStep({
        funnel_id: funnelId,
        title: values.title,
        activities: values.activities.map((item: any) => item.id),
      });
      if (res.id) {
        if (droppedFile) {
          await SubmitFile(res.id, droppedFile);
        }
        notify({
          message: 'Etapa criada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
      setIsLoading(false);
    }
    setIsLoading(true);
  };

  useEffect(() => {
    if (show === true) {
      setValues({
        title: step?.title ?? '',
        funnel_id: step?.funnel_id ?? funnelId,
        path: step?.path ?? null,
        obs: step?.obs ?? null,
        activities:
          step?.funnel_step_activitie_type.map((item) => ({
            id: item.activitie_type.id,
            title: item.activitie_type.title,
          })) ?? [],
      });
      setIsLoading(false);
    }
  }, [step, show]);

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: any) => row.id,
    },
    {
      name: 'Tarefa',
      key: 'title',
      selector: (row: any) => row.title,
    },
    {
      key: 'delete',
      selector: (row: any) => (
        <IconDictionary
          name="Excluir"
          className="text-red cursor-pointer"
          onClick={() => removeActivityById(row.id)}
        />
      ),
    },
  ];

  const columnsAttachment = [
    {
      maxWidth: '45px',
      cell: (row: attachment) => (
        <div className="flex items-center justify-center w-full">
          <a
            href={`${process.env.REACT_APP_API}${row.path}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconDictionary
              name="Download"
              size={20}
              className="cursor-pointer"
            />
          </a>
        </div>
      ),
    },
    {
      name: 'Nome',
      key: 'title',
      minWidth: '65%',
      selector: (row: attachment) => row.title,
      cell: (row: attachment) => (
        <a
          href={`${process.env.REACT_APP_API}${row.path}`}
          target="_blank"
          rel="noreferrer"
          className="text-xs"
        >
          {row.title}
        </a>
      ),
    },
    {
      name: '',
      key: 'action',
      cell: (e: attachment) => (
        <IconDictionary
          name="Excluir"
          className="cursor-pointer"
          size={20}
          color="red"
          onClick={async () => {
            if (step) {
              setRemovedFile(true);
            }
            setDroppedFile(null);
            setValues({ ...values, path: null });
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (show === true) setDroppedFile(null);
  }, [show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={step ? 'Atualizar Etapa' : 'Criar Etapa'}
      size="2xlarge"
      minHeight="min-h-[523px]"
    >
      <div className="flex flex-col justify-center gap-2">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <TextArea
          title="Observação da Etapa"
          className="h-40"
          value={values.obs ?? ''}
          onChange={(e) => setValues({ ...values, obs: e.target.value })}
        />
        <div>
          <p className="text-sm mb-1.5">Anexos</p>
          <div
            {...getRootProps()}
            className={classNames(
              'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background relative',
              {
                'border-[#DC7E19]': isDragActive,
              }
            )}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="font-light text-sm text-primary">
                Solte o arquivo aqui...
              </p>
            ) : (
              <p className="font-light text-sm text-center text-[#555]">
                Arraste e solte aqui ou clique para selecionar um arquivo
              </p>
            )}
          </div>
        </div>

        <Table
          selectedRowId={-1}
          columns={columnsAttachment}
          data={fileData}
          isLoading={false}
        />
        <SelectActivity
          isMulti={false}
          setValue={(e: any) => addActivity(e.value, e.label)}
          value={values.activities}
        />
        <Table
          columns={columns}
          isLoading={false}
          data={values.activities}
          selectedRowId={-1}
          scrollTable
          fixedHeaderScrollHeight="200px"
        />
        {step ? (
          <div className="flex mx-auto gap-3">
            <Button
              disabled={values.title.length === 0 || isLoading}
              variant="outline-primary"
              onClick={handleDelete}
              className="w-48 font-bold relative flex items-center justify-center mt-4 w-64"
            >
              Excluir
            </Button>
            <Button
              isLoading={isLoading}
              disabled={values.title.length === 0 || isLoading}
              actionType="button-loading"
              variant="primary-strong"
              onClick={Submit}
              className="w-48 font-bold relative flex items-center justify-center mt-4 w-64"
            >
              Salvar
            </Button>
          </div>
        ) : (
          <Button
            disabled={values.title.length === 0 || isLoading}
            isLoading={isLoading}
            variant="primary-strong"
            actionType="button-loading"
            onClick={Submit}
            className="font-bold relative flex items-center justify-center mt-10 w-64 mx-auto"
          >
            Salvar
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default RegisterFunnelStep;
