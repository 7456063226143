import { useEffect, useState } from 'react';
import { ApiMenu } from '../api';
import { MenuByProfileProps, MenuFullProps } from '../types';

export function useFetchMenus(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MenuFullProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiMenu.getAllMenus();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { MenuList: data, isLoadingMenus: isLoading };
}

export function useFetchMenusByProfile(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MenuByProfileProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = id !== -1 ? await ApiMenu.getByProfileMenus(id) : [];
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { MenuListByProfile: data, isLoadingMenuList: isLoading };
}
