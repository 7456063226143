import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal } from '../../../../components';
import { Module } from '../types';
import { ApiModule } from '../api';
import { useFetchModule } from '../hooks';
import RegisterModule from '../components/register';

const Modules: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterModule, setModalRegisterModule] = useState(false);
  const [input, setInput] = useState('');
  const [groupData, setModuleData] = useState<Module | null>(null);
  const [key, setKey] = useState('key');

  const { ModuleList, isLoadingModule } = useFetchModule(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Module) => row.title,
    },
    {
      name: '',
      cell: (row: Module) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setModuleData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return ModuleList;
    }
    return ModuleList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingModule}
        column={columns}
        title="Módulos"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterModule}
        setRegisterPage={() => {
          setModuleData(null);
        }}
        onRowClick={(e: Module) => {
          setModuleData(e);
          setModalRegisterModule(true);
        }}
      />
      <RegisterModule
        module={groupData}
        reloadTable={setKey}
        setShow={setModalRegisterModule}
        show={modalRegisterModule}
      />
      {groupData && (
        <DeleteModal
          apiFunction={ApiModule.deleteModule}
          notify={notify}
          type="Módulo"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={groupData.id}
          name={groupData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Modules;
