/* eslint-disable no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';

interface props {
  indexCard: number;
  text: string;
  setText: (e: string, i?: number) => void;
  options: {
    title: string;
    is_active: boolean;
  }[];
  setOptions: (
    e: {
      title: string;
      is_active: boolean;
    }[]
  ) => void;
}

const QuestionList: React.FC<props> = ({
  setText,
  text,
  options,
  indexCard,
  setOptions,
}) => {
  const [newElemet, setNewElement] = useState<{ id: number } | null>(null);

  const [style, setStyle] = useState<{
    title: string;
    is_active: boolean;
    question: { id: number; title: string; is_active: boolean }[];
  }>({ title: '', is_active: false, question: [] });
  const addOptionAtIndex = (index: number) => {
    setNewElement({ id: index + 1 });
    const newOptions = [...options];
    newOptions.splice(index + 1, 0, {
      is_active: true,
      title: `Opção ${newOptions.length + 1}`,
    });
    setOptions(newOptions);
  };

  const removeOptionAtIndex = (index: number) => {
    if (index >= 0 && index < options.length) {
      const newOptions = [...options];
      newOptions.splice(index, 1);
      setOptions(newOptions);
    }
  };

  useEffect(() => {
    if (newElemet) {
      document.getElementById(`select_${newElemet.id}`)?.focus();
      setNewElement(null);
    }
  }, [options]);
  useEffect(() => {
    if (options.length === 0) {
      setOptions([{ title: 'Opção 1', is_active: true }]);
    }
  }, []);
  return (
    <div className="w-full sm:w-2/3 text-sm">
      <input
        type="text"
        id={`${indexCard}-title`}
        defaultValue={text}
        onFocus={() => setStyle({ ...style, is_active: true })}
        onBlur={(e) => setText(e.target.value)}
        className={`${style.title} focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
      />
      <br />
      {options.map((item, index) => (
        <div className="flex gap-2 text-xs items-center">
          <p>{index + 1}.</p>
          <input
            id={`select_${index}`}
            type="text"
            onKeyDown={(e) => {
              if (e.key === 'Enter') addOptionAtIndex(index);
            }}
            onBlur={(e) =>
              e.target.value.length === 0
                ? setText(`Opção ${index + 1}`, index)
                : setText(e.target.value, index)
            }
            defaultValue={item.title}
            onFocus={() => setStyle({ ...style, is_active: true })}
            className={`${style.title} focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
          />
          {options.length > 1 && (
            <IconDictionary
              name="Excluir"
              className="place-self-end cursor-pointer"
              onClick={() => removeOptionAtIndex(index)}
            />
          )}
        </div>
      ))}
      <div className="flex gap-2 text-xs items-center mb-8">
        <p>{options.length + 1}</p>
        <button
          type="button"
          id="title"
          onClick={() =>
            setOptions([
              ...options,
              { is_active: true, title: `Opção ${options.length + 1}` },
            ])
          }
          className="text-gray-600 flex justify-start p-0 hover:border-b-primary border-transparent border-b border-solid w-full py-2 text-gray-700 leading-tight hover:outline-none hover:shadow-outline"
        >
          Adicionar opção
        </button>
      </div>
    </div>
  );
};

export default QuestionList;
