/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { ApiDashboard } from '../../api';
import { graphCash } from '../../types';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  companyId: number;
  period: string;
}
const ResultCash: React.FC<props> = ({ companyId, period }) => {
  const [graph, setGraph] = useState<any>();
  const [graphHover, setGraphHover] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const colors = {
    janeiro: 'bg-blue-200',
    fevereiro: 'bg-green-200',
    março: 'bg-primary',
    abril: 'bg-indigo-400',
    maio: 'bg-purple-200',
    junho: 'bg-orange-200',
    julho: 'bg-pink-200',
    agosto: 'bg-green-600',
    setembro: 'bg-brown-200',
    outubro: 'bg-teal-200',
    novembro: 'bg-indigo-200',
    dezembro: 'bg-yellow-200',
  };
  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const salesChannel = await ApiDashboard.getFinancialResultCash(
        companyId,
        period
      );
      if (typeof salesChannel !== 'string') {
        setGraph(salesChannel);
        setGraphHover(
          Object.values<graphCash>(salesChannel).map((item: graphCash) =>
            maskHelper.formatMoeda(item.valor)
          )
        );
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period]);

  return !isLoading ? (
    <>
      <div className="font-medium text-xs uppercase">
        Resultado caixa{' '}
        <p className="text-xs text-gray-600 normal-case font-normal">
          (Contas recebidas menos pagas)
        </p>
      </div>
      {graph !== undefined && Object.values(graph).length === 0 ? (
        <div className="w-full h-64 lg:h-full flex items-center justify-center">
          <p className="text-xs">Nenhum dado encontrado</p>
        </div>
      ) : (
        <>
          <div className="w-full min-h-fit h-80 flex items-center">
            <Plot
              className="w-full"
              data={[
                {
                  x:
                    graph !== undefined
                      ? Object.values<graphCash>(graph).map(
                          (item: graphCash) => item.legenda
                        )
                      : [],
                  // fake for testing values: [20, 20, 30, 10, 20, 10],
                  y:
                    graph !== undefined
                      ? Object.values<graphCash>(graph).map(
                          (item: graphCash) => item.valor
                        )
                      : [],

                  fillcolor: '#f2d5aa',
                  line: { color: '#954893' },
                  fill: 'tonexty',
                  type: 'scatter',
                  hovertext: graphHover,
                  hovertemplate: '%{hovertext}',
                  name: '',
                },
              ]}
              config={{ displayModeBar: false, responsive: true }}
              layout={{
                height: 250,
                autosize: true,
                margin: { l: 60, r: 20, b: 50, t: 28 },
              }}
            />
          </div>
          <div className="w-full flex flex-wrap justify-evenly gap-1">
            {graph !== undefined &&
              Object.values<graphCash>(graph).map((item: graphCash) => (
                <div className="flex flex-col">
                  <div
                    className={`w-20 text-xs font-semibold flex justify-center p-1 rounded ${
                      colors[item.legenda]
                    }`}
                  >
                    {item.legenda}
                  </div>
                  <p className="text-xs mt-2 mx-auto">
                    {maskHelper.formatMoeda(item.valor)}
                  </p>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default ResultCash;
