/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { ApiDashboard } from '../../api';
import { salesChannelProps } from '../../types';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  companyId: number;
  period: string;
}
const TopSalesChannelGraph: React.FC<props> = ({ companyId, period }) => {
  const [graph, setGraph] = useState<salesChannelProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const salesChannel = await ApiDashboard.getContractTopSalesChannel(
        companyId,
        period
      );
      if (typeof salesChannel !== 'string') {
        setGraph(salesChannel);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period]);

  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Top 5 Canais de vendas</p>
      {graph.reduce((acc, curr) => acc + Number(curr._sum), 0) === 0 ? (
        <div className="w-full h-64 lg:h-full flex items-center justify-center">
          <p className="text-xs">Nenhum dado encontrado</p>
        </div>
      ) : (
        <div className="w-full min-h-fit h-80 flex items-center">
          <Plot
            className="w-full"
            data={[
              {
                values: graph.map((item) => Number(item._sum)),
                // fake for testing values: [20, 20, 30, 10, 20, 10]
                labels: graph.map((item) => item.title),
                text: Object.values(graph).map((item: any) =>
                  maskHelper.formatMoeda(item._sum)
                ),
                marker: {
                  colors: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40',
                    '#B3FF5C',
                    '#FFCD56',
                    '#9EE2F4',
                    '#A6A6A6',
                    '#DDDDDD',
                    '#42A5F5',
                    '#FF7043',
                    '#66BB6A',
                    '#F06292',
                    '#BA68C8',
                    '#FFD54F',
                    '#4DB6AC',
                    '#7986CB',
                    '#FF8A65',
                  ],
                },
                type: 'pie',
                hoverinfo: 'label',
              },
            ]}
            config={{ displayModeBar: false, responsive: true }}
            layout={{
              height: 250,
              autosize: true,
              margin: { l: 20, r: 20, b: 50, t: 28 },
            }}
          />
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default TopSalesChannelGraph;
