import React, { useState } from 'react';
import { contractProps, updateformProps } from '../../types';
import { Button, SelectComponent } from '../../../../components';
import { ApiContract } from '../../api';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

interface props extends updateformProps {
  userOptions: { label: string; value: number }[];
  // eslint-disable-next-line no-unused-vars
  setCurrentContract: (e: contractProps) => void;
}
const PromoterSales: React.FC<props> = ({
  defaultValue,
  id,
  nextPage,
  notify,
  setCurrentContract,
  userOptions,
}) => {
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const [idPromoter, setIdPromoter] = useState<number>(
    defaultValue?.companie_user_id_promoter ?? -1
  );
  const Submit = async () => {
    setIsLoading(true);
    const res = await ApiContract.updateContractPromoter(
      defaultValue?.id ?? id,
      {
        companie_user_id_promoter: idPromoter,
      }
    );
    if (res.id) {
      setIsLoading(false);
      notify({
        message: 'Informações atualizadas com sucesso!',
        type: 'Success',
      });
      if (defaultValue)
        setCurrentContract({
          ...defaultValue,
          companie_user_id_promoter: res.companie_user_id_promoter,
        });
      nextPage();
    } else {
      setIsLoading(false);
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    }
  };
  return (
    <div className="flex flex-col mt-7 gap-3">
      <SelectComponent
        onChange={(e: any) => setIdPromoter(e.value)}
        value={userOptions.find((item) => item.value === idPromoter)}
        closeMenuOnSelect
        options={userOptions}
        title="Promotor de Venda"
        isDisabled={accountProps.profile_id === 5}
      />
      <Button
        actionType="button-loading"
        className="mx-auto"
        disabled={idPromoter < 0}
        onClick={Submit}
        isLoading={isLoading}
      >
        Salvar e continuar
      </Button>
    </div>
  );
};

export default PromoterSales;
