import styled from 'styled-components';
import { ArrowDropUp } from 'styled-icons/material';

export const headCells = {
  style: {
    marginTop: 30,
    background: '#E6E6E6',
    color: 'var(--color-primary)',
    height: 30,
    minHeight: 30,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 17,
  },
  activeSortStyle: {
    color: 'var(--color-primary)',
  },
  inactiveSortStyle: {
    color: 'var(--color-secondary)',
  },
};

export const rows = (rowClick: boolean) => {
  const rowStyle = {
    style: {
      cursor: rowClick ? 'pointer' : 'default',
      color: 'var(--color-secondary)',
      fontSize: 11,
    },
  };
  return rowStyle;
};

export const IconSort = styled(ArrowDropUp)``;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 0px 20px;
  gap: 5px;
`;

type TButtons = { active?: boolean; number?: boolean };

export const Buttons = styled.button<TButtons>`
  padding: 5px 8px;
  border: thin solid var(--color-primary);
  cursor: pointer !important;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${(props) =>
    props.active ? 'var(--color-primary)' : 'transparent'};
  color: ${(props) => (props.active ? 'black' : '#8C8C8C')};
`;
