import { baseApiPrivate } from '../../../api/baseApi';
import {
  createUpdateWhatsAppCampaign,
  filterAdvancedCampaign,
  whatsappCampaignProps,
} from '../types';

async function getWhatsappCampaign() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/campaign',
  });
}

async function getWhatsappCampaignFilterAdvanced(
  filter: filterAdvancedCampaign
): Promise<{ data: whatsappCampaignProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filter,
    url: 'companie/whatsapp/campaign/filterAdvanced',
  });
}

async function getWhatsappCampaignAutocomplete(
  key: string,
  skip: number,
  take: number
): Promise<{ data: whatsappCampaignProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/campaign/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function getWhatsappCampaignId(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/campaign/${id}`,
  });
}

async function getWhatsappCampaignStatus(
  status: string,
  skip: number,
  take: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/campaign/${status}/${skip}/${take}`,
  });
}

async function getWhatsappCampaignPerson(
  campaignId: number,
  skip: number,
  take: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/campaign/person/${campaignId}/${skip}/${take}`,
  });
}

async function createWhatsappCampaign(payload: createUpdateWhatsAppCampaign) {
  return baseApiPrivate({
    body: payload,
    method: 'POST',
    url: `companie/whatsapp/campaign`,
  });
}

async function updateWhatsappCampaign(
  payload: createUpdateWhatsAppCampaign,
  id: number
) {
  return baseApiPrivate({
    body: payload,
    method: 'PUT',
    url: `companie/whatsapp/campaign/${id}`,
  });
}

async function updateWhatsappCampaignNameInstances(
  payload: Omit<createUpdateWhatsAppCampaign, 'person_ids'>,
  id: number
) {
  return baseApiPrivate({
    body: payload,
    method: 'PUT',
    url: `companie/whatsapp/campaign/nameInstances/${id}`,
  });
}

async function updateWhatsappCampaignStatus(
  payload: { status: string },
  id: number
) {
  return baseApiPrivate({
    body: payload,
    method: 'PUT',
    url: `companie/whatsapp/campaign/status/${id}`,
  });
}

async function deleteWhatsappCampaign(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/whatsapp/campaign/${id}`,
  });
}

export const ApiWhatsappCampaign = {
  getWhatsappCampaign,
  getWhatsappCampaignStatus,
  getWhatsappCampaignFilterAdvanced,
  getWhatsappCampaignAutocomplete,
  getWhatsappCampaignPerson,
  getWhatsappCampaignId,
  createWhatsappCampaign,
  updateWhatsappCampaign,
  updateWhatsappCampaignStatus,
  updateWhatsappCampaignNameInstances,
  deleteWhatsappCampaign,
};
