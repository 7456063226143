import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  decodeToken,
  getToken,
  getTokenAccountInformation,
} from '../../helpers/token.helper';

import Main from '../main';
import { getItem } from '../../helpers/storage.helper';
import { MenuSideBar } from '../main/types';
import DigitalSignatureCreditContextProvider from '../DigitalSignatureCredit/dashboard/context';

export function isAuthenticated() {
  const token = getToken();
  if (token) {
    const decodedToken = decodeToken(token);
    if (decodedToken) {
      return true;
    }
    return false;
  }
  return false;
}

export const AuthRoute: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const location = useLocation();
  const userisAuthenticated = isAuthenticated();
  const userMenus: any[] = getItem('menu') ?? [];

  const currentPath = window.location.pathname.split('/')[1];

  if (!userisAuthenticated) {
    return <Navigate to="/session/login" state={{ from: location }} replace />;
  }

  const userRoutes: any[] = userMenus.flatMap((item: MenuSideBar) => {
    if (item.route_frontend) {
      return item.route_frontend;
    }
    if (item.group) {
      return item.group.flatMap((groupItem) =>
        groupItem.menu
          ? groupItem.menu.map((menuItem) => menuItem.route_frontend)
          : []
      );
    }
    return [];
  });

  if (!userRoutes.includes(currentPath) && currentPath !== 'admin') {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  return (
    <DigitalSignatureCreditContextProvider>
      <Main>{children}</Main>
    </DigitalSignatureCreditContextProvider>
  );
};

export const AdminRoute: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const location = useLocation();
  const userIsAuthenticated = isAuthenticated();

  if (userIsAuthenticated) {
    const account = getTokenAccountInformation();
    if (account.profile_id === 1) {
      return children;
    }
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  return <Navigate to="/session/login" state={{ from: location }} replace />;
};

const firstMenu = (menus: MenuSideBar[]) => {
  if (menus[0].group) return menus[0].group[0].menu[0].route_frontend;
  if (menus[0].route_frontend) return menus[0].route_frontend;
  return '';
};

export const MainRoute: React.FC = () => {
  const location = useLocation();
  const userIsAuthenticated = isAuthenticated();

  if (userIsAuthenticated) {
    const account = getTokenAccountInformation();
    const menus = getItem('menu');
    if (userIsAuthenticated && account.profile_id === 1) {
      return (
        <Navigate to="/dashboard-admin" state={{ from: location }} replace />
      );
    }
    return (
      <Navigate to={firstMenu(menus)} state={{ from: location }} replace />
    );
  }

  return <Navigate to="/session/login" state={{ from: location }} replace />;
};
