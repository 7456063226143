import React, { createContext, useState, useMemo } from 'react';
import Spinner from './spinner';

/* eslint-disable no-unused-vars */
interface ContextProps {
  state: boolean;
  setState: (e: boolean) => void;
}

const initialContexValue: ContextProps = { state: false, setState: () => {} };

export const isLoadingApplication =
  createContext<ContextProps>(initialContexValue);

const ApiIsLoadingScreen = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const UpdateState = (e: boolean) => {
    setIsLoading(e);
  };
  const values = useMemo(
    () => ({ state: isLoading, setState: UpdateState }),
    []
  );
  return (
    <isLoadingApplication.Provider value={values}>
      <>
        {children}
        {isLoading && (
          <div className="flex items-center justify-center w-full h-screen fixed top-0 left-0 bg-gray-300/40 z-50">
            <Spinner />
          </div>
        )}
      </>
    </isLoadingApplication.Provider>
  );
};

export default ApiIsLoadingScreen;
