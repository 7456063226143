import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  DeleteModal,
  Input,
  Spinner,
  Switch,
  isLoadingApplication,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { Plans } from '../types';
import { ApiPlansAccount } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

interface PlanObject {
  num_plan: string;
  order: string;
}

function findLastContinuousIndex(planObjects: PlanObject[]) {
  for (let i = 1; i < planObjects.length; i++) {
    const currentParts = planObjects[i].num_plan.split('.').pop();
    const prevParts = planObjects[i - 1].num_plan.split('.').pop();

    if (Number(currentParts) - Number(prevParts) !== 1) {
      return planObjects[i - 1];
    }
  }
  return planObjects.pop();
}

const ChartAccount: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const accountProps = getTokenAccountInformation();
  const { notify } = ToastNotify();
  const [idElementScroll, setIdElementScroll] = useState('-1');
  const [plan, setPlan] = useState<null | Plans>(null);
  const [PlanList, setData] = useState<Plans[]>([]);
  const [currentIsProduct, setCurrentIsProduct] = useState(-1);
  const [currentIsService, setCurrentIsService] = useState(-1);
  const [showDelete, setShowDelete] = useState(false);
  const [key, setKey] = useState('');
  const [isLoadingGroup, setIsLoadingGroup] = useState('0');

  const getLevel = (item: string) => item.split('.').length;

  const addChild = async (type: 'D' | 'R', num: string, order: string) => {
    const currentParentIndex = PlanList.findIndex(
      (item) => item.num_plan === num
    );
    const childrens = PlanList.filter((item) => item.superior === num);
    const lastChild = findLastContinuousIndex(childrens);

    if (lastChild) {
      const lastChildChildren = PlanList.filter(
        (item) => item.superior === lastChild.num_plan
      );
      const last = findLastContinuousIndex(lastChildChildren);
      const indexLastChildren =
        last === undefined
          ? PlanList.findIndex((item) => item.num_plan === lastChild?.num_plan)
          : PlanList.findIndex((item) => item.num_plan === last?.num_plan);
      const numPlanNewItem = Number(
        (Number(lastChild?.num_plan) + 0.1).toFixed(2)
      );
      const orderNewItem = String(Number(lastChild.order) + 0.01);

      PlanList.splice(indexLastChildren + 1, 0, {
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: orderNewItem,
        is_product: false,
        is_service: false,
      });
      const newArr = PlanList.slice(0);
      newArr[indexLastChildren + 1].title = '';
      setData(newArr);
      setIdElementScroll(newArr[indexLastChildren + 1].num_plan);
      setIsLoadingGroup('0');
      setPlan({
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: orderNewItem,
        is_product: false,
        is_service: false,
      });
    } else {
      const numPlanNewItem = num.concat('.1');
      PlanList.splice(currentParentIndex + 1, 0, {
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(order) + 0.01),
        is_product: false,
        is_service: false,
      });
      const newArr = PlanList.slice(0);
      newArr[currentParentIndex + 1].title = '';
      setData(newArr);
      setIdElementScroll(newArr[currentParentIndex + 1].num_plan);
      setIsLoadingGroup('0');
      setPlan({
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(order) + 0.01),
        is_product: false,
        is_service: false,
      });
    }
  };

  const addChildLevelTwo = async (
    type: 'D' | 'R',
    num: string,
    order: string
  ) => {
    const currentParentIndex = PlanList.findIndex(
      (item) => item.num_plan === num
    );
    const childrens = PlanList.filter((item) => item.superior === num);
    const lastChild = findLastContinuousIndex(childrens);
    if (lastChild) {
      const indexLastChildren = PlanList.findIndex(
        (item) => item.num_plan === lastChild.num_plan
      );
      const lastDigit = Number(lastChild.num_plan.split('.').pop()) + 1;

      const digitsSplit = lastChild.num_plan.split('.');
      const numPlanNewItem = digitsSplit[0]
        .concat('.')
        .concat(digitsSplit[1])
        .concat('.')
        .concat(String(lastDigit));

      PlanList.splice(indexLastChildren + 1, 0, {
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(lastChild.order) + 0.01),
        is_product: false,
        is_service: false,
      });
      const newArr = PlanList.slice(0);
      newArr[indexLastChildren + 1].title = '';
      setData(newArr);
      setIdElementScroll(newArr[indexLastChildren + 1].num_plan);
      setIsLoadingGroup('0');
      setPlan({
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(lastChild.order) + 0.01),
        is_product: false,
        is_service: false,
      });
    } else {
      const digitsSplit = num.split('.');
      const numPlanNewItem = digitsSplit[0]
        .concat('.')
        .concat(digitsSplit[1])
        .concat('.1');

      PlanList.splice(currentParentIndex + 1, 0, {
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(order) + 0.01),
        is_product: false,
        is_service: false,
      });
      const newArr = PlanList.slice(0);
      newArr[currentParentIndex + 1].title = '';
      setData(newArr);
      setIdElementScroll(newArr[currentParentIndex + 1].num_plan);
      setIsLoadingGroup('0');
      setPlan({
        companie_user_id: accountProps.id,
        superior: num,
        title: '',
        num_plan: String(numPlanNewItem),
        type,
        active: false,
        order: String(Number(order) + 0.01),
        is_product: false,
        is_service: false,
      });
    }
  };

  const createUpdateList = async (Plan: Plans) => {
    if (Plan.title !== '') {
      setState(true);
      if (Plan.id === undefined) {
        const res = await ApiPlansAccount.createPlan({
          companie_user_id: accountProps.id,
          superior: Plan.superior,
          title: Plan.title,
          num_plan: Plan.num_plan,
          type: Plan.type,
          active: Plan.active,
          order: Plan.order,
          is_service: Plan.is_service,
          is_product: Plan.is_product,
        });
        if (res.id) {
          setKey(`${Math.random()}`);
          notify({ message: 'Plano criado com sucesso!', type: 'Success' });
          setPlan(null);
        } else {
          notify({ message: dictionaryError(res), type: 'Error' });
        }
      } else {
        const res = await ApiPlansAccount.updatePlanAccount(
          {
            companie_user_id: accountProps.id,
            superior: Plan.superior,
            title: Plan.title,
            num_plan: Plan.num_plan,
            type: Plan.type,
            active: Plan.active,
            order: Plan.order,
            is_service: Plan.is_service,
            is_product: Plan.is_product,
          },
          Plan.id
        );
        if (res.id) {
          setKey(`${Math.random()}`);
          notify({ message: 'Plano atualizado com sucesso!', type: 'Success' });
          setPlan(null);
        } else {
          notify({ message: dictionaryError(res), type: 'Error' });
        }
      }
      setState(false);
    }
  };

  useEffect(() => {
    setState(true);
    const FetchData = async () => {
      const response: Plans[] = await ApiPlansAccount.getChartsAccounts();
      if (typeof response !== 'string') {
        setData(response);
        setCurrentIsProduct(
          response.find((item: Plans) => item.is_product === true)?.id ?? -1
        );
        setCurrentIsService(
          response.find((item: Plans) => item.is_service === true)?.id ?? -1
        );
      }
      setState(false);
    };

    FetchData();
  }, [key]);

  useEffect(() => {
    if (idElementScroll !== '-1') {
      const elem = document.getElementById(`input ${idElementScroll}`);
      if (elem !== null) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        window.setTimeout(() => elem?.focus(), 500);
      }
      setIdElementScroll('-1');
    }
  }, [idElementScroll, PlanList]);
  return (
    <div className="w-full minh-h-screen flex flex-col">
      <div className="w-full flex justify-between">
        <div className="flex gap-3 items-center text-primary">
          <IconDictionary name="Plano de Contas" size={20} />
          <strong className="text-lg text-black">Plano de Contas</strong>
        </div>
      </div>
      <Card className="mt-8 px-4 py-5 flex-col min-h-[80vh]">
        <div className="overflow-x-auto max-w-full">
          <div className="grid grid-cols-7 min-w-[800px] font-semibold text-sm gap-0 items-center bg-gray-300 py-2 px-3">
            <div className="flex gap-3 col-span-4">
              <p className="w-24">Código</p>
              <p>Descrição</p>
            </div>
            <p className="mx-auto ">Ativo</p>
            <p className="mx-auto text-center">Produto</p>
            <p className="mx-auto text-center">Serviço</p>
          </div>
          <div className="grid grid-cols-7 w-full min-w-[800px] font-semibold text-sm gap-0 items-center px-3 mt-4">
            {PlanList.map((item, index) => (
              <>
                <div className="flex gap-3 col-span-4" key={item.id}>
                  <p className="w-24 my-auto">{item.num_plan}</p>
                  {getLevel(item.num_plan) === 1 && (
                    <div className="flex items-center gap-4" id={item.num_plan}>
                      <p>{item.title}</p>
                      {isLoadingGroup === item.num_plan ? (
                        <Spinner />
                      ) : (
                        <IconDictionary
                          size={24}
                          name="Adicionar nivel"
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            setIsLoadingGroup(item.num_plan);
                            addChild(item.type, item.num_plan, item.order);
                          }}
                        />
                      )}
                    </div>
                  )}
                  {getLevel(item.num_plan) === 2 && (
                    <div
                      className="flex items-center ml-5 gap-4 font-normal"
                      id={item.num_plan}
                    >
                      <Input
                        variant="outline-orange"
                        className="xl:min-w-[370px] 2xl:min-w-[400px]"
                        defaultValue={item.title}
                        id={`input ${item.num_plan}`}
                        onChange={(e) =>
                          setPlan({ ...item, title: e.currentTarget.value })
                        }
                        onBlur={() => createUpdateList(plan ?? item)}
                      />
                      {isLoadingGroup === item.num_plan ? (
                        <Spinner />
                      ) : (
                        <IconDictionary
                          size={24}
                          name="Adicionar nivel"
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            setIsLoadingGroup(item.num_plan);
                            addChildLevelTwo(
                              item.type,
                              item.num_plan,
                              item.order
                            );
                          }}
                        />
                      )}
                      {PlanList[index + 1] ? (
                        PlanList[index + 1].num_plan.split('.').length < 3 && (
                          <IconDictionary
                            size={24}
                            name="Excluir"
                            className={`${
                              item.id
                                ? 'text-red cursor-pointer'
                                : 'text-gray-300 cursor-default'
                            } min-w-[24px]`}
                            onClick={() => {
                              if (item.id !== undefined) {
                                setPlan(item);
                                setShowDelete(true);
                              }
                            }}
                          />
                        )
                      ) : (
                        <IconDictionary
                          size={24}
                          name="Excluir"
                          className={`${
                            item.id ? 'text-red' : 'text-gray-300'
                          } cursor-pointer min-w-[24px]`}
                          onClick={() => {
                            if (item.id !== undefined) {
                              setPlan(item);
                              setShowDelete(true);
                            }
                          }}
                        />
                      )}
                    </div>
                  )}
                  {getLevel(item.num_plan) === 3 && (
                    <div
                      className="flex items-center ml-16 gap-4 font-normal"
                      id={item.num_plan}
                    >
                      <Input
                        variant="outline-orange"
                        defaultValue={item.title}
                        className="xl:min-w-[350px] 2xl:min-w-[400px]"
                        id={`input ${item.num_plan}`}
                        onChange={(e) =>
                          setPlan({ ...item, title: e.currentTarget.value })
                        }
                        onBlur={() => createUpdateList(plan ?? item)}
                      />
                      <IconDictionary
                        size={24}
                        name="Excluir"
                        className={`${
                          item.id
                            ? 'text-red cursor-pointer'
                            : 'text-gray-300 cursor-default'
                        } min-w-[24px]`}
                        onClick={() => {
                          if (item.id !== undefined) {
                            setPlan(item);
                            setShowDelete(true);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                {getLevel(item.num_plan) === 3 ? (
                  <>
                    <div className="mx-auto">
                      <Switch
                        state={item.active}
                        onChange={() =>
                          createUpdateList({ ...item, active: !item.active })
                        }
                      />
                    </div>

                    <input
                      type="radio"
                      checked={currentIsProduct === item.id}
                      onChange={() => {
                        setCurrentIsProduct(item.id ?? -1);
                        createUpdateList({
                          ...item,
                          is_product: !item.is_product,
                        });
                      }}
                      className="mx-auto"
                    />
                    <input
                      type="radio"
                      checked={currentIsService === item.id}
                      onChange={() => {
                        setCurrentIsService(item.id ?? -1);
                        createUpdateList({
                          ...item,
                          is_service: !item.is_service,
                        });
                      }}
                      className="mx-auto"
                    />
                  </>
                ) : (
                  <div className="col-span-4" />
                )}
              </>
            ))}
          </div>
        </div>
      </Card>
      {plan && (
        <DeleteModal
          apiFunction={ApiPlansAccount.deleteActivitie}
          id={plan.id ?? -1}
          name={plan.title}
          close={() => setShowDelete(false)}
          open={showDelete}
          type="Plano"
          updateTable={() => {
            setKey(`${Math.random()}`);
            setPlan(null);
          }}
          notify={notify}
        />
      )}
    </div>
  );
};

export default ChartAccount;
