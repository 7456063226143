import { baseApiPrivate } from '../../../../api/baseApi';
import { Product } from '../types';

async function getAllProduct() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/product',
  });
}

async function createProduct(e: Omit<Product, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/product',
    body: e,
  });
}

async function updateProduct(e: Omit<Product, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/product/${id}`,
    body: e,
  });
}

async function deleteProduct(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/product/${id}`,
  });
}

export const ApiProduct = {
  getAllProduct,
  createProduct,
  updateProduct,
  deleteProduct,
};
