import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
  TextArea,
} from '../../../components';
import { ActivityProps, createUpdateActivitie } from '../types';
import { ApiActivitie } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import InputDate from '../../../components/Input/inputDate';
import maskHelper from '../../../helpers/mask.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  activitie: ActivityProps | null;
  companieUserId: number;
  optionsUsers: { label: string; value: number }[];
  optionsContacts: { label: string; value: number }[];
  activityOptions: { label: string; value: number }[];
  opportunityOptions: { label: string; value: number }[];
  setInputContact: (e: string) => void;
  isLoadingContactList: boolean;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
}

const RegisterActivitie: React.FC<registerModalProps> = ({
  activitie,
  reloadTable,
  setShow,
  setInputContact,
  isLoadingContactList,
  show,
  companieUserId,
  optionsUsers,
  activityOptions,
  opportunityOptions,
  optionsContacts,
  isLoadingDropdown,
  setInputOpportunity,
}) => {
  const allOptionsOpportunity: { label: string; value: number }[] = [
    {
      value: -1,
      label: 'Sem vínculo com oportunidade',
    },
  ].concat(opportunityOptions);

  const accountProps = getTokenAccountInformation();
  const defaultValues = {
    date: activitie?.date.slice(0, 10) ?? new Date(),
    activitie_id: activitie?.activitie_id ?? -1,
    companie_user_id_owner:
      activitie?.companie_user_id_owner ?? accountProps.id,
    description: activitie?.description ?? '',
    hour: activitie?.hour ?? '',
    opportunity_id:
      activitie && activitie.opportunity
        ? {
            value: activitie.opportunity_id,
            label: activitie.opportunity.title,
          }
        : {
            value: -1,
            label: 'Sem vínculo com oportunidade',
          },
    person_id: activitie
      ? {
          value: activitie.person_id,
          label: activitie.person?.name,
        }
      : null,
    companie_user_id: companieUserId,
  };

  const { notify } = ToastNotify();
  const [values, setValues] = useState<createUpdateActivitie>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    opportunity_id: yup
      .object({ label: yup.string(), value: yup.number() })
      .typeError('Selecione uma opção')
      .required('Selecione uma opção'),
    activitie_id: yup
      .number()
      .typeError('Selecione uma opção')
      .required('Selecione uma opção'),
    person_id: yup
      .object({ label: yup.string(), value: yup.number() })
      .typeError('Contato inválido')
      .nullable(),
    companie_user_id_owner: yup
      .number()
      .typeError('Selecione uma opção')
      .required('Selecione uma opção'),
    hour: yup
      .string()
      .typeError('Horário é obrigatório')
      .required('Horário é obrigatório'),
    description: yup
      .string()
      .typeError('Descrição é obrigatória')
      .required('Descrição é obrigatória'),
    date: yup.string().required('Data é obrigatória'),
  });

  const { handleSubmit, setValue, formState, reset, control, getValues } =
    useForm<createUpdateActivitie>({
      resolver: yupResolver(schema),
      defaultValues: {
        opportunity_id:
          activitie && activitie.opportunity
            ? {
                value: activitie.opportunity_id,
                label: activitie.opportunity?.title,
              }
            : {
                value: -1,
                label: 'Sem vínculo com oportunidade',
              },
        activitie_id: activitie?.activitie_id,
        date: maskHelper.formatDateYMD(
          activitie?.date ?? new Date().toLocaleDateString('pt-br')
        ),
        description: activitie?.description,
        hour: activitie?.hour,
        person_id:
          activitie && activitie.person
            ? {
                value: activitie.person_id,
                label: activitie.person?.name,
              }
            : null,
        companie_user_id_owner:
          activitie?.companie_user_id_owner ?? accountProps.id,
      },
    });

  const Submit = async (e: createUpdateActivitie) => {
    setIsLoading(true);
    if (activitie !== null) {
      const res = await ApiActivitie.updateActivitie(
        {
          ...e,
          person_id: e.person_id ? e.person_id.value : null,
          opportunity_id:
            e.opportunity_id && e.opportunity_id.value !== -1
              ? e.opportunity_id.value
              : null,
        },
        activitie.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tarefa atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiActivitie.createActivitie({
        ...e,
        person_id: e.person_id ? e.person_id.value : null,
        opportunity_id:
          e.opportunity_id && e.opportunity_id.value !== -1
            ? e.opportunity_id.value
            : null,
        companie_user_id: companieUserId,
      });
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tarefa criada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };
  useEffect(() => {
    setValues(defaultValues);
    reset();
  }, [show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={activitie ? 'Atualizar Tarefa' : 'Criar Tarefa'}
      size="2xlarge"
      minHeight="min-h-[550px]"
    >
      <form
        onSubmit={handleSubmit(Submit)}
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
      >
        <SelectComponent
          closeMenuOnSelect
          value={optionsUsers.find(
            (users) => users.value === values.companie_user_id_owner
          )}
          options={optionsUsers}
          title="Proprietário"
          onChange={(e: any) => {
            setValues({ ...values, companie_user_id_owner: e.value });
            setValue('companie_user_id_owner', e.value);
          }}
          errorMensage={formState.errors.companie_user_id_owner?.message}
        />
        <Controller
          control={control}
          name="person_id"
          defaultValue={defaultValues.person_id}
          render={({ field }) => (
            <SelectComponent
              closeMenuOnSelect
              value={field.value}
              isLoading={isLoadingContactList}
              onInputChange={setInputContact}
              isClearable
              autoComplete
              options={optionsContacts}
              title="Contato"
              onChange={(e: any) => {
                setValue('person_id', e);
              }}
              errorMensage={formState.errors.person_id?.message}
            />
          )}
        />
        <SelectComponent
          closeMenuOnSelect
          value={activityOptions.find(
            (activity) => activity.value === values.activitie_id
          )}
          options={activityOptions}
          title="Tipo de Tarefa"
          maxOptionsHeight="180px"
          onChange={(e: any) => {
            setValues({ ...values, activitie_id: e.value });
            setValue('activitie_id', e.value);
          }}
          errorMensage={formState.errors.activitie_id?.message}
        />
        <Controller
          control={control}
          name="opportunity_id"
          defaultValue={defaultValues.opportunity_id}
          render={({ field }) => (
            <SelectComponent
              closeMenuOnSelect
              value={field.value}
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              isClearable
              autoComplete
              maxOptionsHeight="180px"
              options={allOptionsOpportunity}
              title="Oportunidade"
              onChange={(e: any) => {
                setValue('opportunity_id', e);
              }}
              errorMensage={formState.errors.opportunity_id?.message}
            />
          )}
        />
        <InputDate
          title="Data"
          defaultValue={values.date ? new Date(`${values.date} `) : new Date()}
          onChange={(e) => {
            if (e !== null)
              setValue(
                'date',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
          }}
          errorMensage={formState.errors.date?.message}
        />
        <Input
          title="Horário"
          type="time"
          variant="outline-orange"
          value={values.hour}
          onChange={(e) => {
            setValues({ ...values, hour: e.target.value });
            setValue('hour', e.target.value);
          }}
          errorMensage={formState.errors.hour?.message}
        />
        <TextArea
          variant="orange"
          title="Descrição"
          value={values.description}
          onChange={(e) => {
            setValues({ ...values, description: e.target.value });
            setValue('description', e.target.value);
          }}
          errorMensage={formState.errors.description?.message}
          classNamediv="col-span-2"
        />
        <Button
          disabled={isLoading}
          variant="primary-strong"
          type="submit"
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto col-span-2"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default RegisterActivitie;
