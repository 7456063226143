import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Input, SelectComponent } from '../../../components';
import { addressData } from '../../../components/Register/address';
import { notify } from '../../../components/Toast/toast';
import { getAddressByCEP, states } from '../../../helpers/address.helper';
import maskHelper from '../../../helpers/mask.helper';

import { CreateAccountProps } from '../types';
import CardRounded from './card';
import ButtonRounded from './button';

interface props {
  isDisabled: boolean;
  done: boolean;
  setIsDone: React.Dispatch<React.SetStateAction<CreateAccountProps | null>>;
  personalInform: CreateAccountProps | null;
  // eslint-disable-next-line no-unused-vars
  setCardNumber: (e: number) => void;
}

const AddressCard: React.FC<props> = ({
  personalInform,
  isDisabled,
  done,
  setIsDone,
  setCardNumber,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object().shape({
    zipcode: yup
      .string()
      .required('CEP é obrigatório')
      .test({
        message: 'CEP inválido',
        test: (value) => (value ? maskHelper.number(value).length === 8 : true),
      }),
    address: yup.string().required('Rua/Longradouro é obrigatório'),
    province: yup.string().required('Bairro é obrigatório'),
    city: yup.string().required('Cidade é obrigatório'),
    state: yup
      .string()
      .required('Estado é obrigatório')
      .min(2, 'Selecione um estado'),
    complement: yup.string().nullable(true),
    number: yup
      .string()
      .typeError('Número é obrigatório')
      .required('Número é obrigatório'),
  });
  const { control, setValue, handleSubmit } = useForm<addressData>({
    resolver: yupResolver(schema),
  });

  const AddressByCEP = async (e: string) => {
    setIsLoading(true);
    const address: any = await getAddressByCEP(e);
    if (!address || address.erro === true) {
      notify({
        message: 'Nenhum endereço encontrado com CEP digitado.',
        type: 'Warning',
      });
      setIsLoading(false);
      return;
    }
    if (address) {
      setValue('address', address.logradouro);
      setValue('city', address.localidade);
      setValue('state', address.uf);
      setValue('province', address.bairro);
    }
    setIsLoading(false);
  };
  const Submit = async (e: addressData) => {
    setIsLoading(true);
    if (personalInform)
      setIsDone({
        ...personalInform,
        zipcode: maskHelper.number(e.zipcode),
        address: e.address,
        city: e.city,
        state: e.state,
        complement: e.complement,
        province: e.province,
      });
    setCardNumber(2);
    // if (res.id) {
    //   setIsDone(true);
    //   if (e.number) setItem('number_person', e.number);
    // } else {
    //   notify({ message: dictionaryError(String(e)), type: 'Error' });
    // }
    setIsLoading(false);
  };
  return (
    <CardRounded disabled={isDisabled} finished={done}>
      <p className="text-xl text-green-400 mb-3 font-extrabold">Endereço</p>
      <p className="text-xs mb-5">
        Preencha os campos abaixo com o seu endereço.
      </p>
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(Submit)}>
        <Controller
          control={control}
          name="zipcode"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              title="CEP"
              value={maskHelper.cep(field.value ?? '')}
              onBlur={(e) =>
                e.target.value.length > 0 &&
                AddressByCEP(maskHelper.number(e.target.value))
              }
              variant="outline-orange"
              placeholder="00000-000"
              errorMensage={fieldState.error?.message}
              disabled={done || isDisabled}
              borderDefault="border-[#03a6c7]"
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              title="Rua"
              variant="outline-orange"
              placeholder="ex: R. Dom Pedro"
              errorMensage={fieldState.error?.message}
              disabled={done || isDisabled}
              borderDefault="border-[#03a6c7]"
            />
          )}
        />
        <div className="grid grid-cols-2 gap-3">
          <Controller
            control={control}
            name="province"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title="Bairro"
                variant="outline-orange"
                placeholder="ex: R. Dom Pedro"
                errorMensage={fieldState.error?.message}
                disabled={done || isDisabled}
                borderDefault="border-[#03a6c7]"
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title="Cidade"
                variant="outline-orange"
                placeholder="ex: R. Dom Pedro"
                errorMensage={fieldState.error?.message}
                disabled={done || isDisabled}
                borderDefault="border-[#03a6c7]"
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="state"
          render={({ field, fieldState }) => (
            <SelectComponent
              variant="outline-primary"
              isDisabled={done || isDisabled}
              title="Estado"
              options={states}
              closeMenuOnSelect
              value={states.find((element) => element.value === field.value)}
              onChange={(e: any) => {
                setValue('state', e.value);
              }}
              errorMensage={fieldState.error?.message}
              primaryColor="#03a6c7"
            />
          )}
        />

        <div className="grid grid-cols-2 gap-3">
          <Controller
            control={control}
            name="number"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title="Número"
                variant="outline-orange"
                placeholder="10"
                errorMensage={fieldState.error?.message}
                disabled={done || isDisabled}
                borderDefault="border-[#03a6c7]"
              />
            )}
          />
          <Controller
            control={control}
            name="complement"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title="Complemento"
                variant="outline-orange"
                placeholder="ex: apto 101"
                errorMensage={fieldState.error?.message}
                disabled={done || isDisabled}
                borderDefault="border-[#03a6c7]"
              />
            )}
          />
        </div>

        <ButtonRounded
          isLoading={isLoading}
          type="submit"
          disabled={done || isDisabled}
        >
          Avançar
        </ButtonRounded>
      </form>
    </CardRounded>
  );
};

export default AddressCard;
