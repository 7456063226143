import React from 'react';

import { Modal, Card, Spinner } from '..';

import { baseApiPrivate } from '../../api/baseApi';

interface Props {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setClose: (isOpen: boolean) => void;
  instanceId: string;
}

async function instancesStatus(planId: number, body: object) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/instances/status/${planId}`,
    body,
  });
}

const ConnectModal: React.FC<Props> = ({ isOpen, instanceId, setClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    base64: '',
  });

  React.useEffect(() => {
    if (isOpen && instanceId) {
      setLoading(true);
      instancesStatus(Number(instanceId), {
        action: 'connect',
      })
        .then(setData)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen]);

  return (
    <Modal
      title="Reconectar Instância"
      isOpen={isOpen}
      setIsOpen={setClose}
      size="medium"
      minHeight="min-h-[250px]"
    >
      <Card className="flex justify-center items-center min-w-[350px]">
        {loading ? (
          <Spinner />
        ) : (
          <img width={300} src={data.base64} alt="qrcode" />
        )}
      </Card>
    </Modal>
  );
};

export default ConnectModal;
