import React, { useState } from 'react';
import classNames from 'classnames';
import { Address, Button, Modal, SocialMedia } from '../../../../components';
import GeneralData from './components/generalData';
import { UserProps } from '../../types';
import { ApiUsers } from '../../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { Profile } from '../../../admin/profile/types';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

interface page {
  title: string;
  page: number;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  ProfileList: Profile[];
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  setCompanyId?: (e: number) => void;
  user: UserProps | null;
  setUser: (e: UserProps) => void;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  companyId: number;
  isFranchise: boolean;
}

const header = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Redes Sociais',
    page: 2,
  },
];

const RegisterUser: React.FC<registerModalProps> = ({
  show,
  setShow,
  registerPage,
  setRegisterPage,
  reloadTable,
  setUser,
  setCompanyId,
  user,
  companyId,
  ProfileList,
  isFranchise,
}) => {
  const accountProps = getTokenAccountInformation();
  const { notify } = ToastNotify();
  const tokenCompanyId = accountProps.companie_id;
  const [userId, setUserId] = useState(-1);

  const nextPage = (createdUserId?: number) => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(String(createdUserId));
      setShow(false);
    }
  };

  const FilterProfiles = (profiles: Profile[]) =>
    profiles
      .filter((elem) => !elem.is_admin)
      .filter((item) =>
        isFranchise ? item.is_franchise === true : item.is_franchisor === true
      );

  return (
    <Modal
      title={user ? 'Atualizar Usuário' : 'Cadastrar Usuário'}
      isOpen={show}
      setIsOpen={(e) => {
        setShow(e);
        reloadTable(`${Math.random()}`);
      }}
      size="4xlarge"
      minHeight="min-h-[690px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 sm:flex gap-2 mt-9 ">
          {header.map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                user !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => user !== null && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />

        {registerPage.page === 0 && (
          <GeneralData
            setUser={setUser}
            ProfileList={FilterProfiles(ProfileList)}
            setId={setUserId}
            notify={notify}
            nextPage={(id) => {
              nextPage(id);
              if (setCompanyId && id) setCompanyId(id);
            }}
            defaultValue={user}
            id={user?.id ?? userId}
            companyId={companyId ?? tokenCompanyId}
          />
        )}
        {registerPage.page === 1 && (
          <Address
            apiFunction={async (values, id) => {
              const res = await ApiUsers.updateAddress(values, id);
              if (res.id) {
                setUser(res);
              }
              return res;
            }}
            defaultValue={
              user && {
                address: user.address ?? '',
                city: user.city ?? '',
                complement: user.complement,
                province: user.province ?? '',
                state: user.state ?? '',
                zipcode: user.zipcode ?? '',
              }
            }
            id={user?.id ?? userId}
            nextPage={nextPage}
          />
        )}
        {registerPage.page === 2 && (
          <SocialMedia
            titleButton="Salvar"
            apiFunction={async (values, id) => {
              const res = await ApiUsers.updateSocialMedia(values, id);
              if (res.id) {
                setUser(res);
              }
              return res;
            }}
            id={user?.id ?? userId}
            nextPage={nextPage}
            setId={setUserId}
            fields={['facebook', 'instagram', 'twitter']}
            defaultValues={{
              facebook: user?.facebook ?? '',
              instagram: user?.instagram ?? '',
              twitter: user?.twitter ?? '',
            }}
          />
        )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterUser;
