import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Modal, SelectComponent } from '../../../components';
import { filtersProps, formProps } from '../types';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ApiActivitie } from '../api';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  period: string;
  profileId: number;
  restartPagination: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setActivityList: (e: any) => void;
  activityOptions: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  personOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  setFilter: (e: formProps) => void;
  filters: formProps;
  personInput: string;
  setInputPerson: (e: string) => void;
  isLoadingPerson: boolean;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
  setInputActivity: (e: string) => void;
}

const SearchActivity: React.FC<registerModalProps> = ({
  show,
  setShow,
  setActivityList,
  setInputOpportunity,
  setInputActivity,
  opportunityList,
  personOptions,
  userOptions,
  activityOptions,
  filters,
  period,
  profileId,
  personInput,
  isLoadingPerson,
  isLoadingDropdown,
  setInputPerson,
  setFilter,
  restartPagination,
}) => {
  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const schema = yup.object().shape({
    status: yup.string().required('Selecione uma opção'),
  });

  const { formState, handleSubmit, setValue, reset, control, getValues } =
    useForm<formProps>({
      resolver: yupResolver(schema),
      defaultValues: filters,
    });

  const statusOptions = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Em andamento', label: 'Em andamento' },
    { value: 'Realizado', label: 'Realizado' },
  ];

  const Submit = async (e: formProps) => {
    setInputActivity('');
    setIsLoadingClear(true);
    const res = await ApiActivitie.getActivityFilters(period, {
      ...e,
      personId: e.personId.map((item) => item.value),
      opportunityId: e.opportunityId.map((item) => item.value),
    });
    if (typeof res !== 'string') {
      setActivityList(res);
      setFilter(e);
      setShow(false);
      restartPagination();
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }

    setIsLoadingClear(false);
  };
  const ClearData = async () => {
    setIsLoading(true);
    const defaultFilter: filtersProps = {
      id: [],
      opportunityId: [],
      activitieId: [],
      companieUserIdOwner: [],
      personId: [],
      status: 'Todos',
      skip: 0,
      take: 10,
    };
    await Submit({ ...defaultFilter, personId: [], opportunityId: [] });
    reset({ ...defaultFilter, personId: [], opportunityId: [] });
    restartPagination();
    setIsLoading(false);
  };
  useEffect(() => {
    reset(filters);
    setInputPerson('');
  }, [show]);
  return (
    <Modal
      title="Procurar Atividade"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[500px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              defaultValue={statusOptions[0]}
              value={statusOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              maxOptionsHeight="300px"
              onChange={(e: any) => {
                setValue('status', e.value);
              }}
              errorMensage={formState.errors.status?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="personId"
          defaultValue={filters.personId}
          render={({ field }) => (
            <SelectComponent
              title="Contato"
              options={personOptions}
              value={field.value}
              inputValue={personInput}
              isLoading={isLoadingPerson}
              isClearable
              onInputChange={setInputPerson}
              autoComplete
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue('personId', e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="companieUserIdOwner"
          render={({ field }) => (
            <SelectComponent
              title="Usuário"
              options={userOptions}
              value={userOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              isDisabled={profileId === 5}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'companieUserIdOwner',
                  e.map((user) => user.value)
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="opportunityId"
          defaultValue={filters.opportunityId}
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              title="Oportunidades"
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              maxOptionsHeight="120px"
              options={opportunityList}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue('opportunityId', e);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="activitieId"
          render={({ field }) => (
            <SelectComponent
              value={activityOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              title="Tipo da atividade"
              maxOptionsHeight="120px"
              options={activityOptions}
              isMulti
              closeMenuOnSelect
              onChange={(e: any[]) => {
                setValue(
                  'activitieId',
                  e.map((opportunity) => opportunity.value)
                );
              }}
            />
          )}
        />
        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear || isLoading}
            variant="outline-primary"
            onClick={ClearData}
            className="font-bold flex items-center justify-center mt-4 w-64"
          >
            Limpar
          </Button>
          <Button
            disabled={isLoadingClear || isLoading}
            variant="primary-strong"
            actionType="button-loading"
            isLoading={isLoadingClear || isLoading}
            type="submit"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchActivity;
