/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { AxiosError } from 'axios';
import maskHelper from '../../../helpers/mask.helper';
import {
  Modal,
  Button,
  Input,
  Select,
  Spinner,
  Table,
} from '../../../components';
import { ApiContract } from '../api';
import { Document, contractProps } from '../types';
import { ToastNotify } from '../../../components/Toast/toast';

interface Props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (show: boolean) => void;
  contract: contractProps | null;
  onSuccessRequest?: () => void;
}

interface SignatoryTypeOption {
  label: string;
  value: string;
}

type SignerData = {
  delivery_method: string;
  phone: string;
  link: string;
  email: string;
  name: string;
  action: string;
  showMore: boolean;
  photoValidation?: boolean;
  manualVerification?: boolean;
  reactKey: number;
  errors: {
    number?: string;
    link?: string;
    email?: string;
    name?: string;
  };
};

const newSigner = {
  delivery_method: 'DELIVERY_METHOD_EMAIL',
  phone: '',
  link: '',
  email: '',
  name: '',
  showMore: false,
  action: 'SIGN',
  errors: {},
};
function getErrorMessage(errorType: any) {
  switch (errorType) {
    case 'error_contract_not_exist':
      return 'Contrato não existe no sistema';
    case 'error_contract_custom_not_exist':
      return 'Contrato customizado não existe no sistema';
    case 'error_document_already_requested_signature_on_autentique':
      return 'Uma solicitação de assinatura já está em andamento';
    case 'error_missing_autentique_token':
      return 'A empresa não tem permissão para solicitar assinaturas';
    case 'missing_digital_signature_credit_exception':
      return 'Créditos insuficientes para solicitar assinaturas';
    default:
      return 'Ops! Algo não aconteceu como esperado';
  }
}

const signatoryTypeOptions: SignatoryTypeOption[] = [
  { label: 'E-mail', value: 'DELIVERY_METHOD_EMAIL' },
  { label: 'Telefone', value: 'DELIVERY_METHOD_SMS' },
];

const SignContractModal: React.FC<Props> = ({
  setOpen,
  show,
  contract,
  onSuccessRequest,
}) => {
  const { notify } = ToastNotify();
  const [loadingAutentiqueContract, setLoadingAutentiqueContract] =
    useState(false);
  const [viewMode, setViewMode] = useState<'view' | 'create'>(() =>
    contract ? 'view' : 'create'
  );
  const [autentiqueContract, setAutentiqueContract] = useState<Document | null>(
    null
  );
  const [loadingSignRequest, setLoadingSignRequest] = useState(false);
  const [signers, setSigners] = useState<SignerData[]>([
    { ...newSigner, reactKey: Math.random() },
  ]);
  useEffect(() => {
    if (show && !contract?.autentique_document_link) {
      setViewMode('create');
      setSigners([
        {
          ...newSigner,
          email: contract?.client_email ?? '',
          name: contract?.client_name ?? '',
          action: 'SIGN',
          delivery_method: 'DELIVERY_METHOD_EMAIL',
          reactKey: Math.random(),
        },
      ]);
      return;
    }
    if (contract?.autentique_document_link) {
      setViewMode('view');
    }
  }, [show, contract]);

  useEffect(() => {
    if (!contract?.autentique_document_link || !show) return;
    setLoadingAutentiqueContract(true);
    ApiContract.getSignedContractDetails(contract?.id)
      .then((result) => {
        result.autentiqueDocument.signatures =
          result.autentiqueDocument.signatures.filter(
            (signer) => signer.action
          );
        setAutentiqueContract(result);
      })
      .finally(() => {
        setLoadingAutentiqueContract(false);
      });
  }, [contract, show]);

  const signatureActionsOptions: SignatoryTypeOption[] = [
    { label: 'Assinar', value: 'SIGN' },
    { label: 'Aprovar', value: 'APPROVE' },
    { label: 'Reconhecer', value: 'RECOGNIZE' },
    { label: 'Testemunhar', value: 'WITNESS' },
    { label: 'Acusar recebimento', value: 'ACKNOWLEDGE_RECEIPT' },
    { label: 'Endossar em preto', value: 'ENDORSE_IN_BLACK' },
    { label: 'Endossar em branco', value: 'ENDORSE_IN_WHITE' },
  ];

  const handleSignatoryTypeChange = (
    index: number,
    selectedOption: SignatoryTypeOption
  ) => {
    setSigners((prev) => {
      const copy = [...prev];
      copy[index].delivery_method = selectedOption.value;
      copy[index].showMore = false;
      return copy;
    });
  };
  const handleeSignatureActionChange = (
    index: number,
    selectedOption: SignatoryTypeOption
  ) => {
    setSigners((prev) => {
      const copy = [...prev];
      copy[index].action = selectedOption.value;
      return copy;
    });
  };

  const renderSignatoryInputs = (index: number) => {
    const form = signers[index];
    switch (signers[index].delivery_method) {
      case 'DELIVERY_METHOD_WHATSAPP':
        return (
          <div className="grid grid-cols-4 gap-3 w-full">
            <Input
              variant="outline-orange"
              title="Número"
              required
              disabled={loadingSignRequest}
              classNameDiv="col-span-3"
              value={maskHelper.phone(form.phone)}
              errorMensage={form.errors.number}
              onChange={(e) =>
                setSigners((prev) => {
                  const copy = [...prev];
                  copy[index].phone = e.target.value;
                  copy[index].errors.number = undefined;
                  return copy;
                })
              }
            />
          </div>
        );
      case 'DELIVERY_METHOD_EMAIL':
        return (
          <Input
            disabled={loadingSignRequest}
            variant="outline-orange"
            title="Endereço de e-mail"
            classNameDiv="flex-1"
            required
            value={form.email}
            errorMensage={form.errors.email}
            onChange={(e) =>
              setSigners((prev) => {
                const copy = [...prev];
                copy[index].email = e.target.value;
                copy[index].errors.email = undefined;
                return copy;
              })
            }
          />
        );
      // Dentro do método renderSignatoryInputs
      case 'DELIVERY_METHOD_LINK':
        return (
          <div className="relative">
            <Input
              disabled={loadingSignRequest}
              required
              variant="outline-orange"
              title="URL"
              value={form.link}
              errorMensage={form.errors.link}
              onChange={(e) =>
                setSigners((prev) => {
                  const copy = [...prev];
                  copy[index].link = e.target.value;
                  copy[index].errors.link = undefined;
                  return copy;
                })
              }
            />
          </div>
        );

      case 'DELIVERY_METHOD_SMS':
        return (
          <Input
            disabled={loadingSignRequest}
            variant="outline-orange"
            title="Número de Telefone"
            classNameDiv="col-span-3 flex-1"
            value={maskHelper.phone(form.phone)}
            errorMensage={form.errors.number}
            onChange={(e) =>
              setSigners((prev) => {
                const copy = [...prev];
                copy[index].phone = e.target.value;
                copy[index].errors.number = undefined;
                return copy;
              })
            }
          />
        );
      default:
        return null;
    }
  };

  const validatedForm = () =>
    signers.map((value) => {
      if (
        ['DELIVERY_METHOD_WHATSAPP', 'DELIVERY_METHOD_SMS'].includes(
          value.delivery_method
        )
      ) {
        if (!value.phone) value.errors.number = 'O Número é obrigatório';
      }
      if (value.delivery_method === 'DELIVERY_METHOD_EMAIL') {
        if (!value.email) value.errors.email = 'O email é obrigatório';
      }
      if (value.delivery_method === 'DELIVERY_METHOD_LINK') {
        if (!value.link) value.errors.link = 'O link é obrigatório';
      }
      return value;
    });
  const onSubmit = async () => {
    const valuesValidated = validatedForm();
    if (!contract) return;
    if (
      // verifica se existe alguma propriedade do objeto `errors` com valor
      valuesValidated.some((value) =>
        Object.entries(value.errors).some(([, v]) => v)
      )
    ) {
      setSigners(valuesValidated);
      return;
    }

    const payload = signers.map(({ errors, reactKey, ...rest }) => {
      let signer = {
        action: rest.action,
        name: rest.name,
        delivery_method: rest.delivery_method,
      } as Record<string, any>;
      switch (rest.delivery_method) {
        case 'DELIVERY_METHOD_WHATSAPP':
        case 'DELIVERY_METHOD_SMS':
          signer = {
            ...signer,
            action: rest.action,
            phone: `+${`55${rest.phone}`.replace(/\D/g, '')}`,
          };
          break;
        case 'DELIVERY_METHOD_EMAIL':
          signer = {
            ...signer,
            email: rest.email,
          };
          break;
        case 'DELIVERY_METHOD_LINK':
          break;
      }

      if (rest.manualVerification) {
        signer.security_verifications = [{ type: 'MANUAL' }];
      }
      return signer;
    });
    try {
      setLoadingSignRequest(true);
      await ApiContract.requestSignatures(contract.id, payload);
      onSuccessRequest?.();
      setSigners([{ ...newSigner, reactKey: Math.random() }]);
    } catch (error) {
      let message = 'Não foi possível solicitar assinaturas';

      if (error instanceof AxiosError) {
        const errorType = error.response?.data.err;

        message = getErrorMessage(errorType);
      }
      notify({
        message,
        type: 'Error',
      });
    } finally {
      setLoadingSignRequest(false);
    }
  };

  const createNewSigner = () =>
    setSigners((prev) => {
      const lastSigner = prev[prev.length - 1];
      return [
        ...prev,
        {
          ...lastSigner,
          email: '',
          name: '',
          phone: '',
          errors: {},
          reactKey: Math.random(),
        },
      ];
    });
  return (
    <Modal
      title={
        contract?.autentique_document_link
          ? 'Dados de assinatura do contrato'
          : 'Cadastrar signatários'
      }
      setIsOpen={() => {
        setOpen(false);
        setTimeout(
          () => setSigners([{ ...newSigner, reactKey: Math.random() }]),
          200
        );
      }}
      size="4xlarge"
      isOpen={show}
      minHeight="500px"
    >
      {loadingAutentiqueContract && (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      )}
      {!loadingAutentiqueContract &&
        (viewMode === 'create' ? (
          <>
            <div className="flex flex-col gap-4">
              {signers.map((signer, index) => (
                <div
                  key={signer.reactKey}
                  className="p-2 rounded-lg flex items-center justify-between gap-4 shadow-[0_0_40px_-15px_rgba(0,0,0,0.2)]"
                >
                  <div className="flex-1">
                    <Input
                      variant="outline-orange"
                      title="Nome *"
                      required
                      value={signers[index].name}
                      errorMensage={signers[index].errors.name}
                      onChange={(e) =>
                        setSigners((prev) => {
                          const copy = [...prev];
                          copy[index].name = e.target.value;
                          copy[index].errors.name = undefined;
                          return copy;
                        })
                      }
                      disabled={loadingSignRequest}
                    />
                    <div className="flex gap-2 w-full items-end whitespace-nowrap">
                      <Select
                        className="w-full"
                        options={signatoryTypeOptions}
                        onChange={(option: any) =>
                          handleSignatoryTypeChange(index, option)
                        }
                        value={signatoryTypeOptions.find(
                          (option) => option.value === signer.delivery_method
                        )}
                        isDisabled={loadingSignRequest}
                        title="Método de envio"
                      />
                      <Select
                        options={signatureActionsOptions}
                        onChange={(option: any) =>
                          handleeSignatureActionChange(index, option)
                        }
                        isDisabled={loadingSignRequest}
                        value={signatureActionsOptions.find(
                          (option) => option.value === signers[index].action
                        )}
                        title="Ações de Assinatura"
                      />
                      {renderSignatoryInputs(index)}
                    </div>
                    {/* <div className="flex gap-2">
                      <input
                        disabled={loadingSignRequest}
                        type="checkbox"
                        id="photo-validation"
                        value={signer.photoValidation as any}
                        onChange={() => {
                          setSigners((prev) => {
                            const copy = [...prev];
                            copy[index].photoValidation =
                              !copy[index].photoValidation;
                            return copy;
                          });
                        }}
                      />
                      <label htmlFor="photo-validation">
                        Exibir documento com foto
                      </label>
                      {signer.photoValidation && (
                        <div>
                          <div className="flex gap-2">
                            <input
                              disabled={loadingSignRequest}
                              type="checkbox"
                              id="manual-check"
                              value={signer.manualVerification as any}
                              onChange={() => {
                                setSigners((prev) => {
                                  const copy = [...prev];
                                  copy[index].manualVerification =
                                    !copy[index].manualVerification;
                                  return copy;
                                });
                              }}
                            />
                            <label htmlFor="manual-check">
                              Exibir aprovação manual
                            </label>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>

                  <Button
                    className="max-w-[50px] w-[50px] flex justify-center items-center disabled:cursor-not-allowed"
                    onClick={() => {
                      setSigners((prev) => prev.filter((_, i) => i !== index));
                    }}
                    disabled={signers.length === 1}
                  >
                    <FaTrash />
                  </Button>
                </div>
              ))}
            </div>

            <Button className="mt-2 w-full" onClick={createNewSigner}>
              Adicionar Signatário
            </Button>

            <Button
              disabled={signers.length === 0 || loadingSignRequest}
              actionType="button-full-width"
              className="mt-2 flex items-center justify-center gap-2"
              onClick={onSubmit}
            >
              {loadingSignRequest && <Spinner />} Enviar
            </Button>
          </>
        ) : (
          <Table
            isLoading={loadingAutentiqueContract}
            data={autentiqueContract?.autentiqueDocument?.signatures ?? []}
            columns={[
              {
                name: 'Nome',
                key: '',
                cell: (row: any) => row.name,
              },
              {
                name: 'Email',
                key: 'email',
                cell: (row: any) => row.email,
              },
              {
                name: 'Status',
                // key: 'email',
                cell: (row: any) => (row?.signed ? 'Assinado' : 'Não assinado'),
              },
            ]}
          />
        ))}
    </Modal>
  );
};

export default SignContractModal;
