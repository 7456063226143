import React from 'react';
import Switch from 'react-switch';

interface SwitchComponentProps {
  state: boolean;
  onChange: () => void;
  width?: number;
  height?: number;
  handleDiameter?: number;
  disabled?: boolean;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
  state,
  onChange,
  width = 52,
  height = 29,
  handleDiameter = 25,
  disabled,
}) => {
  const styles = getComputedStyle(document.documentElement);
  const primary = styles.getPropertyValue('--primary');

  return (
    <Switch
      onChange={onChange}
      checked={state}
      offColor="#CDCDCD"
      onColor={primary}
      uncheckedIcon={false}
      checkedIcon={false}
      height={height}
      width={width}
      handleDiameter={handleDiameter}
      disabled={disabled}
    />
  );
};

export default SwitchComponent;
