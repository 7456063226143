import React from 'react';
import SeloCompraSegura from '../../../../assets/png/compra.png';
import SeloDSS from '../../../../assets/png/dss.png';
import SeloSSL from '../../../../assets/png/ssl.png';

const Stamps: React.FC = () => (
  <div className="w-1/2 flex gap-3 mx-auto items-center justify-center">
    <img src={SeloCompraSegura} alt="SeloCompraSegura" className="h-10" />
    <img src={SeloSSL} alt="SeloSSL" className="h-20" />
    <img src={SeloDSS} alt="SeloDSS" className="h-10" />
  </div>
);

export default Stamps;
