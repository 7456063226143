import React from 'react';
/* eslint-disable no-unused-vars */

interface props {
  title: string;
  // eslint-disable-next-line no-unused-vars
  setText: (e: string) => void;
}

const ShortParagraphAnswer: React.FC<props> = ({ setText, title }) => (
  <div className="flex flex-col gap-2 text-sm w-2/3 py-3">
    <p>{title}</p>
    <input
      type="text"
      id="title"
      placeholder="Sua resposta"
      className="focus:border-b-primary focus:border-b border-solid border-b-gray-400 border-b border-solid w-full py-2 text-gray-700 leading-tight outline-none shadow-outline"
      onBlur={(e) => setText(e.target.value)}
    />
  </div>
);

export default ShortParagraphAnswer;
