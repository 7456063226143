/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { DayIndicators } from '../components';
import Card from '../../../../components/Card/card';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { SelectComponent } from '../../../../components';
import maskHelper from '../../../../helpers/mask.helper';
import GainReasons from '../components/graph/gainReasons';
import LoseReasons from '../components/graph/loseReasons';
import TopSellers from '../components/table/topSellers';
import OpportunityStepsGraph from '../components/graph/opportunityStep';
import SalesChannelGraph from '../components/graph/salesChannel';
import SegmentGraph from '../components/graph/segmentGraph';
import { ApiFunnel } from '../../../funnel/api';
import IconDictionary from '../../../../components/Icons/icons';

const dateFilterOptions = [
  { value: 'today', label: 'Hoje' },
  { value: 'yesterday', label: 'Ontem' },
  { value: 'this_week', label: 'Esta semana' },
  { value: 'last_week', label: 'Semana passada' },
  { value: 'this_month', label: 'Este mês' },
  { value: 'last_30_days', label: 'Últimos 30 dias' },
  { value: 'other_period', label: 'Outro intervalo' },
];

const BiFunnelSales: React.FC = () => {
  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });
  const tokenInfo = getTokenAccountInformation();

  const [optionAllFunnels, setOptionAllFunnels] = useState([
    { value: '', label: '' },
  ]);
  const [funnelId, setFunnelId] = useState<string>('all');

  const [reload, setRealod] = useState('this_month');
  const [period, setPeriod] = useState('this_month');

  useEffect(() => {
    if (period !== 'other_period') {
      setRealod(period);
      if (!period.includes('other_period')) {
        setDatePicker({ open: false });
      }
    } else {
      setDatePicker({ ...datePicker, open: true });
    }
  }, [period]);

  useEffect(() => {
    const fetchFunnel = async () => {
      const allFunnel = [{ label: 'Todos', value: 'all' }];
      const funnel = await ApiFunnel.getFunnelByCompanie(tokenInfo.companie_id);
      if (typeof funnel !== 'string') {
        setOptionAllFunnels(
          allFunnel.concat(
            funnel.map((item: any) => ({
              value: String(item.id),
              label: item.title,
            }))
          )
        );
      }
    };
    fetchFunnel();
  }, []);
  return (
    <>
      <div className="w-full flex justify-between">
        <div className="flex flex-col mb-8">
          <h3 className="font-bold text-lg">Olá, {tokenInfo?.name}</h3>
          <p className="font-medium text-sm">
            Seja bem-vindo a central de operações LeadSim
          </p>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mt-2 mb-7">
        <div className="flex gap-3 items-center text-primary">
          <IconDictionary name="bi-funnel" className="text-primary" size={24} />
          <strong className="text-lg text-black">BI Funil de Vendas</strong>
        </div>

        <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 w-full md:mt-0 lg:w-fit">
          <SelectComponent
            variant="outline-primary"
            icon="Calendario"
            value={dateFilterOptions.find((item) => item.value === period)}
            className="w-full sm:w-60 lg:w-48 2xl:w-60"
            options={dateFilterOptions}
            closeMenuOnSelect
            onChange={(e: any) => {
              setPeriod(e.value);
            }}
            maxOptionsHeight="300px"
          />
          {datePicker.open && (
            <div className="flex mb-1.5">
              <DatePicker
                openCalendarOnFocus
                format="dd/MM/yyyy"
                className="start_date_input"
                onChange={(e: Date) => {
                  setDatePicker({ ...datePicker, start: e });
                  if (typeof datePicker.end !== 'undefined') {
                    setPeriod(
                      `other_period/${maskHelper.formatDateYMD(
                        e.toLocaleDateString('pt-br')
                      )}/${maskHelper.formatDateYMD(
                        datePicker.end?.toLocaleDateString('pt-br') ?? ''
                      )}`
                    );
                  }
                }}
                value={datePicker.start}
              />
              <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                Até
              </p>
              <DatePicker
                openCalendarOnFocus
                format="dd/MM/yyyy"
                className="end_date_input"
                onChange={(e: Date) => {
                  setDatePicker({ ...datePicker, end: e });
                  if (typeof datePicker.start !== 'undefined') {
                    setPeriod(
                      `other_period/${maskHelper.formatDateYMD(
                        datePicker.start?.toLocaleDateString('pt-br') ?? ''
                      )}/${maskHelper.formatDateYMD(
                        e.toLocaleDateString('pt-br')
                      )}`
                    );
                  }
                }}
                value={datePicker.end}
                minDate={datePicker.start}
              />
            </div>
          )}
          <SelectComponent
            variant="outline-primary"
            icon="Funil"
            value={optionAllFunnels.find((item) => item.value === funnelId)}
            className="w-full sm:w-60 lg:w-48 2xl:w-60"
            options={optionAllFunnels}
            closeMenuOnSelect
            onChange={(e: any) => {
              setFunnelId(e.value);
            }}
            maxOptionsHeight="300px"
          />
        </div>
      </div>

      <>
        <DayIndicators
          companyId={tokenInfo.companie_id}
          period={reload}
          funnelId={funnelId}
        />
        <div className="flex flex-col gap-2 mt-2 lg:grid lg:grid-cols-2">
          <Card className="flex-col w-full p-4 h-64 min-h-fit">
            <GainReasons
              companyId={tokenInfo.companie_id}
              period={reload}
              funnelId={funnelId}
            />
          </Card>
          <Card className="flex-col p-4 w-full">
            <LoseReasons
              companyId={tokenInfo.companie_id}
              period={reload}
              funnelId={funnelId}
            />
          </Card>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <Card className="flex-col p-4 w-full mt-2">
            <TopSellers
              companyId={tokenInfo.companie_id}
              period={reload}
              funnelId={funnelId}
            />
          </Card>
        </div>
        {funnelId !== 'all' && (
          <Card className="w-full flex-col mt-2 p-4">
            <OpportunityStepsGraph
              funnelId={funnelId}
              companyId={tokenInfo.companie_id}
              period={reload}
            />
          </Card>
        )}

        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-2 w-full">
          <Card className="p-4 w-full mt-2 flex-col">
            <SalesChannelGraph
              companyId={tokenInfo.companie_id}
              period={reload}
              funnelId={funnelId}
            />
          </Card>
          <Card className="flex-col p-4 w-full mt-2">
            <SegmentGraph
              companyId={tokenInfo.companie_id}
              period={reload}
              funnelId={funnelId}
            />
          </Card>
        </div>
      </>

      <br />
    </>
  );
};

export default BiFunnelSales;
