import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Openaccess } from '@styled-icons/simple-icons/Openaccess';
import {
  Button,
  Card,
  DeleteModal,
  SelectComponent,
  Spinner,
  Table,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import {
  useFetchAccessCompanies,
  useFetchCompanyByGroup,
  useFetchProfileCompany,
  useFetchUsersOtherCompanie,
} from '../hooks';

import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ApiOthersCompanyAccess } from '../api';
import { CompanyAccessCompanies } from '../types';
import maskHelper from '../../../helpers/mask.helper';
import { reloadAccess } from '../../../hooks/globalAtom';

const OtherCompanyAccess: React.FC = () => {
  const { notify } = ToastNotify();
  const [, reloadAccessNotification] = useAtom(reloadAccess);
  const accountProps = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);
  const [currentAccessId, setCurrentAccessId] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [key, setKey] = useState('key');
  const [createAccessData, setCreateAccessData] = useState({
    companyId: -1,
    userId: -1,
    profileId: -1,
  });
  const { CompanyList } = useFetchCompanyByGroup(accountProps.companie_id);
  const companyOption = CompanyList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const { UsersList } = useFetchUsersOtherCompanie(createAccessData.companyId);
  const optionUser = UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const { ProfileList } = useFetchProfileCompany(createAccessData.companyId);
  const optionProfile = ProfileList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const { AccessList, isLoadingAccess } = useFetchAccessCompanies(
    key,
    createAccessData.companyId
  );

  const column = [
    {
      name: 'Empresa',
      key: 'companie.name',
      selector: (row: CompanyAccessCompanies) => row.companie.name,
    },
    {
      name: 'Usuário',
      key: 'companie_user_companie_userTocompanie_user_access_companies_companie_user_id.name',
      selector: (row: CompanyAccessCompanies) =>
        row
          .companie_user_companie_userTocompanie_user_access_companies_companie_user_id
          .name,
    },
    {
      name: 'Perfil',
      key: 'profile.title',
      selector: (row: CompanyAccessCompanies) => row.profile.title,
    },
    {
      name: 'Criado por',
      key: 'companie_user_companie_userTocompanie_user_access_companies_companie_user_id_owner.name',
      selector: (row: CompanyAccessCompanies) =>
        row
          .companie_user_companie_userTocompanie_user_access_companies_companie_user_id_owner
          .name,
    },
    {
      name: 'Criado em',
      key: 'created_at',
      selector: (row: CompanyAccessCompanies) =>
        maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: '',
      key: '',
      cell: (row: CompanyAccessCompanies) => (
        <IconDictionary
          name="Excluir"
          className="text-red cursor-pointer"
          size={20}
          onClick={() => {
            setCurrentAccessId(row.id);
            setShowDelete(true);
          }}
        />
      ),
    },
  ];

  const UnlockAccess = async () => {
    setIsLoading(true);
    const res = await ApiOthersCompanyAccess.createCompanyAccess({
      companie_id: createAccessData.companyId,
      companie_user_id: createAccessData.userId,
      profile_id: createAccessData.profileId,
    });
    if (res.id) {
      notify({ message: 'Acesso criado com sucesso!', type: 'Success' });
      reloadAccessNotification(`${Math.random()}`);
      setCreateAccessData({
        companyId: createAccessData.companyId,
        profileId: -1,
        userId: -1,
      });
      setKey(`${Math.random()}`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (createAccessData.companyId !== -1) {
      setCreateAccessData({
        companyId: createAccessData.companyId,
        profileId: -1,
        userId: -1,
      });
    }
  }, [createAccessData.companyId]);

  return (
    <div className="w-full min-h-screen h-fit">
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Acesso a empresas" size={24} />
        <strong className="text-lg text-black">Acesso a empresas</strong>
      </div>
      <Card className="w-full p-7 mt-6 mb-3 flex-col min-h-screen">
        <strong className="text-xl">Acesso a empresas</strong>
        <p className="text-sm">Configurações</p>
        <div className="flex flex-col xl:flex-row gap-3 w-full mt-5">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 gap-3 w-full">
            <SelectComponent
              value={
                companyOption.find(
                  (item) => item.value === createAccessData.companyId
                ) ?? null
              }
              options={companyOption}
              closeMenuOnSelect
              title="Empresa"
              placeholder="Selecione uma empresa do grupo"
              onChange={(e: any) =>
                setCreateAccessData({ ...createAccessData, companyId: e.value })
              }
            />

            <SelectComponent
              value={
                optionUser.find(
                  (item) => item.value === createAccessData.userId
                ) ?? null
              }
              options={optionUser.filter(
                (user) =>
                  !AccessList.find(
                    (access) => access.companie_user_id === user.value
                  )
              )}
              closeMenuOnSelect
              title="Usuário"
              placeholder="Selecione um usuário da empresa"
              onChange={(e: any) =>
                setCreateAccessData({ ...createAccessData, userId: e.value })
              }
              isDisabled={createAccessData.companyId === -1}
            />

            <SelectComponent
              value={
                optionProfile.find(
                  (item) => item.value === createAccessData.profileId
                ) ?? null
              }
              options={optionProfile}
              closeMenuOnSelect
              title="Perfil"
              placeholder="Selecione um perfil para o acesso"
              onChange={(e: any) =>
                setCreateAccessData({ ...createAccessData, profileId: e.value })
              }
              isDisabled={createAccessData.companyId === -1}
            />
          </div>

          <Button
            variant="primary-strong"
            className="mb-1.5 h-11 place-self-center xl:place-self-end text-white flex items-center justify-center gap-2.5"
            actionType="button-loading"
            onClick={UnlockAccess}
            disabled={
              Object.values(createAccessData).indexOf(-1) !== -1 || isLoading
            }
          >
            <Openaccess color="#fff" size={18} />
            Liberar acesso
          </Button>
        </div>
        <div className="mt-5 w-full">
          {!isLoadingAccess || !isLoading ? (
            <Table
              columns={column}
              data={AccessList}
              isLoading={isLoadingAccess}
            />
          ) : (
            <div className="w-full flex items-center justify-center h-[60vh]">
              <Spinner />
            </div>
          )}
        </div>
      </Card>
      <DeleteModal
        type="acesso"
        updateTable={() => {
          setKey(`${Math.random()}`);
          reloadAccessNotification(`${Math.random()}`);
        }}
        article="O"
        apiFunction={ApiOthersCompanyAccess.deleteAccess}
        close={() => setShowDelete(false)}
        id={currentAccessId}
        name="o acesso"
        notify={notify}
        open={showDelete}
      />
    </div>
  );
};

export default OtherCompanyAccess;
