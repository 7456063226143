import React, { ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Address, Button, Modal } from '../../../../components';
import ContactGeneralData from './components/generalData';
import { ContactProps } from '../../types';
import { ToastNotify } from '../../../../components/Toast/toast';
import Annotations from './components/annotations';
import Activity from './components/activity';
import { ApiContact } from '../../api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import Tags from './components/tags';

interface page {
  title: string;
  page: number;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  contact: ContactProps | null;
  setContact: (e: ContactProps) => void;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  companyId: number;
  outsideCreate?: boolean;
  children?: ReactElement<ReactNode>;
  onCreate?: () => void;
}

const header = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Tarefas',
    page: 2,
  },
  {
    id: 3,
    title: 'Anotações',
    page: 3,
  },
  {
    id: 4,
    title: 'Tags',
    page: 4,
  },
];

const headerOutsideCreate = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Tags',
    page: 1,
  },
];

const RegisterContact: React.FC<registerModalProps> = ({
  show,
  setShow,
  registerPage,
  setRegisterPage,
  reloadTable,
  setContact,
  contact,
  companyId,
  outsideCreate = false,
  children,
  onCreate = () => {},
}) => {
  const { notify } = ToastNotify();
  const tokenCompanyId = getTokenAccountInformation().companie_id;
  const [contactId, setContactId] = useState(-1);

  const nextPage = () => {
    if (
      registerPage.page <
      (outsideCreate ? headerOutsideCreate : header).length - 1
    ) {
      setRegisterPage({
        title: (outsideCreate ? headerOutsideCreate : header)[
          registerPage.page + 1
        ].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(`${Math.random()}create`);
      setShow(false);
    }
  };

  return (
    <Modal
      title={contact ? 'Atualizar Contato' : 'Cadastrar Contato'}
      isOpen={show}
      setIsOpen={() => {
        setShow(false);
        reloadTable(`${Math.random()}save`);
      }}
      size="4xlarge"
      minHeight="min-h-[690px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 sm:flex gap-2 mt-9 ">
          {(outsideCreate ? headerOutsideCreate : header).map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                contact !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => contact !== null && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        {registerPage.page === 0 && children}
        {registerPage.page === 0 && (
          <ContactGeneralData
            setContact={(e) => {
              setContact(e);
              onCreate();
            }}
            setId={setContactId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contact}
            id={contact?.id ?? contactId}
            companyUserId={companyId ?? tokenCompanyId}
            onePageRegister={outsideCreate}
          />
        )}
        {(outsideCreate
          ? registerPage.page === 4
          : registerPage.page === 1) && (
          <Address
            apiFunction={async (values, id) => {
              const res = await ApiContact.updateAddress(values, id);
              if (res.id) {
                setContact(res);
              }
              return res;
            }}
            defaultValue={
              contact && {
                address: contact.address ?? '',
                city: contact.city ?? '',
                complement: contact.complement,
                province: contact.province ?? '',
                state: contact.state ?? '',
                zipcode: contact.zipcode ?? '',
              }
            }
            id={contact?.id ?? contactId}
            nextPage={nextPage}
          />
        )}

        {registerPage.page === 2 && (
          <Activity
            nextPage={nextPage}
            companieId={tokenCompanyId}
            notify={notify}
            personId={contact?.id ?? contactId}
            companieUserId={companyId ?? tokenCompanyId}
          />
        )}

        {registerPage.page === 3 && (
          <Annotations
            setContact={setContact}
            setId={setContactId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contact}
            id={contact?.id ?? contactId}
            companyUserId={companyId ?? tokenCompanyId}
          />
        )}

        {(outsideCreate
          ? registerPage.page === 1
          : registerPage.page === 4) && (
          <Tags
            setContact={setContact}
            setId={setContactId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={contact}
            id={contact?.id ?? contactId}
            companyUserId={companyId ?? tokenCompanyId}
          />
        )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {(outsideCreate ? headerOutsideCreate : header).map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterContact;
