import React, { useState } from 'react';
import { toBase64 } from '../../../../utils';
import { UploadFiles } from '../../../../../../../../components';

interface props {
  // eslint-disable-next-line no-unused-vars
  setGeralData: (e: any) => void;
  generalDataValue: any;
  disabledEdit: boolean;
  typeCompanie?: string;
}
const CompanyUpload: React.FC<props> = ({
  generalDataValue,
  setGeralData,
  disabledEdit,
  typeCompanie,
}) => {
  const [fileNames, setFileNames] = useState({
    lastContract: '',
    electionRecord: '',
    statute: '',
    cnpjCard: '',
  });
  const disabledLastContract =
    disabledEdit ||
    (typeCompanie ? !['ltda', 'eireli', 'slu'].includes(typeCompanie) : false);
  const disabledCnpjCard =
    disabledEdit ||
    (typeCompanie
      ? !['individualEntrepreneur', 'mei'].includes(typeCompanie)
      : false);
  const disabledElectionRecordStatute =
    disabledEdit ||
    (typeCompanie ? !['association', 'sa'].includes(typeCompanie) : false);

  const handleDropFiles = async (
    acceptedFiles: any,
    type: 'lastContract' | 'cnpjCard' | 'electionRecord' | 'statute'
  ) => {
    const fileCreateObj = acceptedFiles.map((f: any) =>
      Object.assign(f, {
        preview: URL.createObjectURL(f),
      })
    );

    const base64: string = await toBase64(fileCreateObj[0]);
    setGeralData({
      ...generalDataValue,
      Documents: {
        ...generalDataValue.Documents,
        Company: {
          ...generalDataValue.Documents.Company,
          [type]: base64,
        },
      },
    });
    setFileNames({ ...fileNames, [type]: fileCreateObj[0].name });
  };

  function hasImageHeader(imageData: string) {
    return imageData.startsWith('data:image');
  }

  const isPDF = (base64String: string) =>
    base64String.startsWith('JVBERi0') || base64String.includes('pdf');

  return (
    <>
      <hr className="w-full h-px -mb-1 rounded-md mb-1 bg-primary col-span-4" />
      <strong className="text-sm col-span-4">Documentos Empresa</strong>

      {!disabledLastContract && (
        <UploadFiles
          title="Contrato social ou última alteração"
          span="Clique para Enviar Contrato social ou última alteração
                  consolidada."
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'application/pdf': [],
          }}
          srcImg={
            isPDF(generalDataValue.Documents.Company.lastContract)
              ? 'https://www.svgrepo.com/show/144578/pdf.svg'
              : hasImageHeader(generalDataValue.Documents.Company.lastContract)
              ? generalDataValue.Documents.Company.lastContract
              : `data:image/png;base64,${generalDataValue.Documents.Company.lastContract}`
          }
          onDropAccepted={(files) => handleDropFiles(files, 'lastContract')}
          fileName={fileNames.lastContract}
          file={generalDataValue.Documents.Company.lastContract}
        />
      )}

      {!disabledCnpjCard && (
        <UploadFiles
          title="Documento CNPJ"
          span="Clique para Enviar Documento CNPJ."
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'application/pdf': [],
          }}
          srcImg={
            isPDF(generalDataValue.Documents.Company.cnpjCard)
              ? 'https://www.svgrepo.com/show/144578/pdf.svg'
              : hasImageHeader(generalDataValue.Documents.Company.cnpjCard)
              ? generalDataValue.Documents.Company.cnpjCard
              : `data:image/png;base64,${generalDataValue.Documents.Company.cnpjCard}`
          }
          onDropAccepted={(files) => handleDropFiles(files, 'cnpjCard')}
          fileName={fileNames.cnpjCard}
          file={generalDataValue.Documents.Company.cnpjCard}
        />
      )}

      {!disabledElectionRecordStatute && (
        <>
          <UploadFiles
            title="Ata de eleição da diretoria"
            span="Clique para Enviar Ata de eleição da diretoria."
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpeg'],
              'image/jpg': ['.jpg'],
              'application/pdf': [],
            }}
            srcImg={
              isPDF(generalDataValue.Documents.Company.electionRecord)
                ? 'https://www.svgrepo.com/show/144578/pdf.svg'
                : hasImageHeader(
                    generalDataValue.Documents.Company.electionRecord
                  )
                ? generalDataValue.Documents.Company.electionRecord
                : `data:image/png;base64,${generalDataValue.Documents.Company.electionRecord}`
            }
            onDropAccepted={(files) => handleDropFiles(files, 'electionRecord')}
            fileName={fileNames.electionRecord}
            file={generalDataValue.Documents.Company.electionRecord}
          />

          <UploadFiles
            title="Estatuto"
            span="Clique para Enviar o Estatuto."
            accept={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpeg'],
              'image/jpg': ['.jpg'],
              'application/pdf': [],
            }}
            srcImg={
              isPDF(generalDataValue.Documents.Company.statute)
                ? 'https://www.svgrepo.com/show/144578/pdf.svg'
                : hasImageHeader(generalDataValue.Documents.Company.statute)
                ? generalDataValue.Documents.Company.statute
                : `data:image/png;base64,${generalDataValue.Documents.Company.statute}`
            }
            onDropAccepted={(files) => handleDropFiles(files, 'statute')}
            fileName={fileNames.statute}
            file={generalDataValue.Documents.Company.statute}
          />
        </>
      )}
    </>
  );
};

export default CompanyUpload;
