/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { SearchFormProps } from '../types';
import IconDictionary from '../../../components/Icons/icons';
import maskHelper from '../../../helpers/mask.helper';
import { Switch } from '../../../components';

interface props {
  form: SearchFormProps;
  openForm: () => void;
  updateStatus: (status: boolean) => void;
  handleDelete: () => void;
}

const CardForm: React.FC<props> = ({
  form,
  openForm,
  handleDelete,
  updateStatus,
}) => {
  const [active, setIsActive] = useState(form.is_active);
  return (
    <button
      type="button"
      onClick={openForm}
      className="h-fit transition ease-in-out delay-75 duration-150 shadow-sm w-72 text-xs flex flex-col border-solid border-gray-400 hover:border-primary cursor-pointer rounded-md border pb-4"
    >
      <div className="py-6 px-3 w-full flex items-center justify-between">
        <strong className="max-w-sm truncate">{form.title}</strong>

        <Switch
          state={active}
          onChange={() => {
            updateStatus(!active);
            setIsActive(!active);
          }}
        />
      </div>
      <hr className="w-full bg-gray-400 mb-4 h-px" />
      <div className="max-w-full text-xs px-3 flex flex-col items-start h-16">
        {form.search_form_questions.slice(0, 3).map((items) => (
          <p className="max-w-full truncate">{items.title}</p>
        ))}
        {form.search_form_questions.length > 3 && '...'}
      </div>
      <hr className="w-full bg-gray-400 mb-4 mt-5 h-px" />

      <div className="flex gap-2 items-center justify-between w-full text-xs px-3">
        <IconDictionary name="Cartao lista" className="text-primary h-4" />
        <p>Data de Criação: {maskHelper.formatDateDMY(form.created_at)}</p>
        <button
          type="button"
          onClick={handleDelete}
          className="hover:scale-110 delay-150 bg-primary w-5 h-5 flex items-center justify-center rounded-full text-white cursor-pointer"
        >
          <IconDictionary name="Excluir" size={16} />
        </button>
      </div>
    </button>
  );
};

export default CardForm;
