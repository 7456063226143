export function formatMenuToRoutes(menuMain: any[]) {
  const routes = menuMain.flatMap((item: any) => {
    if (item.route_frontend) {
      return item.route_frontend;
    }
    if (item.group) {
      return item.group.flatMap((groupItem: any) =>
        groupItem.menu
          ? groupItem.menu.map((menuItem: any) => menuItem.route_frontend)
          : []
      );
    }
    return [];
  });

  return routes;
}
