import React from 'react';
import { Button, Input, SelectComponent } from '../../../../components';
import { OptionsSelectProp } from '../../../../components/Select/select';

/* eslint-disable no-unused-vars */
interface contactList {
  nameMessage: {
    campaignName?: string;
    texId: number | null;
    textMessage?: string;
  };
  setNameMessage: (e: {
    campaignName?: string;
    texId: number | null;
    textMessage?: string;
  }) => void;
  nextPage: () => void;
  isLoadingSave: boolean;
  WhatsappMessagesList: any[];
  prevPage: () => void;
  isEditable: boolean;
}

const NameCampaign: React.FC<contactList> = ({
  nameMessage,
  WhatsappMessagesList,
  isEditable,
  setNameMessage,
  nextPage,
  prevPage,
  isLoadingSave,
}) => {
  const optionMessages = WhatsappMessagesList.map((item) => ({
    label: item.text,
    value: item.id,
  }));

  return (
    <div className="w-full flex flex-col px-4 mt-5 mt-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          value={nameMessage?.campaignName}
          title="Nome da Campanha"
          onChange={(e) =>
            setNameMessage({ ...nameMessage, campaignName: e.target.value })
          }
        />

        <SelectComponent
          isDisabled={!isEditable}
          title="Mensagem para envio"
          options={optionMessages}
          defaultValue={
            nameMessage.textMessage && nameMessage.texId
              ? { label: nameMessage.textMessage, value: nameMessage.texId }
              : undefined
          }
          value={optionMessages.find(
            (item) => item.value === nameMessage?.texId
          )}
          onChange={(e: OptionsSelectProp) =>
            setNameMessage({ ...nameMessage, texId: e.value })
          }
        />
      </div>

      <div className="mx-auto mt-10 flex gap-3 w-full sm:w-fit">
        <Button
          actionType="button-loading"
          variant="outline-primary"
          onClick={prevPage}
        >
          Voltar
        </Button>
        <Button
          disabled={
            nameMessage.campaignName === undefined ||
            nameMessage.campaignName === '' ||
            nameMessage.texId === -1 ||
            nameMessage.texId === null ||
            isLoadingSave
          }
          isLoading={isLoadingSave}
          actionType="button-loading"
          variant="primary-strong"
          onClick={nextPage}
        >
          Salvar e continuar
        </Button>
      </div>
    </div>
  );
};

export default NameCampaign;
