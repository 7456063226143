import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  Input,
  SelectComponent,
  Spinner,
} from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import InputDate from '../../../../../components/Input/inputDate';
import { ToastNotify } from '../../../../../components/Toast/toast';
import maskHelper from '../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { ApiOpportunity } from '../../../api';
import { createUpdateOpportunity, OpportunityProps } from '../../../types';
import { states } from '../../../../../helpers/address.helper';
import { OptionsSelectProp } from '../../../../../components/Select/select';

/* eslint-disable no-unused-vars */
interface props {
  opportunity: OpportunityProps;
  updateOpportunity: (e: OpportunityProps) => void;
  salesChannelOptions: { label: string; value: number }[];
  segmentOptions: { label: string; value: number }[];
  companieUserId: number;
}

const GeneralData: React.FC<props> = ({
  opportunity,
  salesChannelOptions,
  segmentOptions,
  companieUserId,
  updateOpportunity,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { notify } = ToastNotify();

  const [citiesOptions, setCitiesOptions] = useState<OptionsSelectProp[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);

  const [address, setAdress] = useState<{
    city: OptionsSelectProp | null;
    state: OptionsSelectProp | null;
  }>({
    city: opportunity.city
      ? { value: opportunity.city, label: opportunity.city }
      : null,
    state: opportunity.state
      ? { value: opportunity.state, label: opportunity.state }
      : null,
  });

  const [editOpportunity, setEditOpportunity] = useState<any>({
    edit: false,
    hide: false,
    opportunity,
  });

  const { formState, handleSubmit, setValue, control, getValues } =
    useForm<createUpdateOpportunity>({
      defaultValues: {
        title: opportunity.title,
        date_expectation: opportunity.date_expectation
          ? maskHelper.formatDateYMD(opportunity.date_expectation)
          : null,
        sales_channel_id: opportunity.sales_channel_id,
        segment_id: opportunity.segment_id,
        obs: opportunity.obs !== null ? opportunity.obs : '',
        city: opportunity.city,
        state: opportunity.state,
      },
    });

  const Submit = async (e: createUpdateOpportunity) => {
    setIsLoading(true);
    const response = await ApiOpportunity.updateOpportunity(
      {
        companie_user_id: companieUserId,
        date_expectation: e.date_expectation
          ? maskHelper.formatDateYMD(e.date_expectation ?? '')
          : null,
        funnel_step_id: opportunity.funnel_step_id,
        sales_channel_id: e.sales_channel_id,
        segment_id: e.segment_id,
        title: e.title,
        obs: e.obs !== null ? e.obs : '',
        city: e.city,
        state: e.state,
      },
      opportunity.id
    );
    if (response.id) {
      notify({
        message: 'Oportunidade atualizada com sucesso!',
        type: 'Success',
      });
      updateOpportunity({
        ...opportunity,
        date_expectation: response.date_expectation,
        sales_channel_id: response.sales_channel_id,
        segment_id: response.segment_id,
        title: response.title,
        updated_at: response.updated_at,
        created_at: response.created_at,
        obs: response.obs,
      });
      setEditOpportunity({ ...editOpportunity, edit: false });
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoading(false);
  };

  const fetchCities = async (uf: string) => {
    setCitiesLoading(true);
    const cities = await ApiOpportunity.getCities(uf);
    if (cities) {
      setCitiesOptions(
        cities.map((item) => ({ label: item.nome, value: item.nome }))
      );
    }
    setCitiesLoading(false);
  };

  useEffect(() => {
    if (opportunity.state) fetchCities(opportunity.state);
  }, []);

  return (
    <div>
      <Card className="flex flex-col">
        <div className="flex justify-between w-full bg-primary p-4 rounded-t">
          <p className="text-xs uppercase font-semibold text-white">
            Oportunidade
          </p>
          <div className="flex gap-2">
            <IconDictionary
              size={16}
              className="text-white cursor-pointer text-black hover:scale-110"
              name="Editar"
              onClick={() =>
                setEditOpportunity({
                  ...editOpportunity,
                  edit: true,
                })
              }
            />
            {editOpportunity.hide ? (
              <IconDictionary
                size={16}
                color="#fff"
                className="cursor-pointer hover:scale-110"
                name="Adicionar"
                onClick={() =>
                  setEditOpportunity({
                    ...editOpportunity,
                    hide: !editOpportunity.hide,
                  })
                }
              />
            ) : (
              <IconDictionary
                size={16}
                className="cursor-pointer hover:scale-110"
                name="Hide"
                color="#fff"
                onClick={() =>
                  setEditOpportunity({
                    ...editOpportunity,
                    hide: !editOpportunity.hide,
                  })
                }
              />
            )}
          </div>
        </div>
        {!editOpportunity.hide && (
          <form
            onSubmit={handleSubmit(Submit)}
            className="flex flex-col sm:grid grid-cols-2 p-4 gap-3"
          >
            <Input
              value={editOpportunity.opportunity.title}
              disabled={isLoading || !editOpportunity.edit}
              className="col-span-2"
              title="Título da oportunidade"
              variant="outline-orange"
              onChange={(e) => {
                setValue('title', e.target.value);
                setEditOpportunity({
                  ...editOpportunity,
                  opportunity: {
                    ...editOpportunity.opportunity,
                    title: e.target.value,
                  },
                });
              }}
              errorMensage={formState.errors.title?.message}
            />
            <SelectComponent
              isDisabled={isLoading || !editOpportunity.edit}
              title="Canal de venda"
              maxOptionsHeight="180px"
              options={salesChannelOptions}
              closeMenuOnSelect
              value={salesChannelOptions.find(
                (item) =>
                  item.value === editOpportunity.opportunity.sales_channel_id
              )}
              onChange={(e: any) => {
                setValue('sales_channel_id', e.value);
                setEditOpportunity({
                  ...editOpportunity,
                  opportunity: {
                    ...editOpportunity.opportunity,
                    sales_channel_id: e.value,
                  },
                });
              }}
              errorMensage={formState.errors.sales_channel_id?.message}
            />
            <SelectComponent
              isDisabled={isLoading || !editOpportunity.edit}
              title="Segmento"
              maxOptionsHeight="180px"
              options={segmentOptions}
              closeMenuOnSelect
              value={segmentOptions.find(
                (item) => item.value === editOpportunity.opportunity.segment_id
              )}
              onChange={(e: any) => {
                setValue('segment_id', e.value);
                setEditOpportunity({
                  ...editOpportunity,
                  opportunity: {
                    ...editOpportunity.opportunity,
                    segment_id: e.value,
                  },
                });
              }}
              errorMensage={formState.errors.segment_id?.message}
            />

            <InputDate
              disabled={isLoading || !editOpportunity.edit}
              title="Data da Expectativa"
              defaultValue={
                editOpportunity.opportunity.date_expectation
                  ? new Date(editOpportunity.opportunity.date_expectation)
                  : undefined
              }
              onChange={(e) => {
                if (e !== null) {
                  setValue(
                    'date_expectation',
                    maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
                  );
                  setEditOpportunity({
                    ...editOpportunity,
                    opportunity: {
                      ...editOpportunity.opportunity,
                      date_expectation: e.toLocaleDateString('pt-br'),
                    },
                  });
                } else {
                  setValue('date_expectation', '');
                  setEditOpportunity({
                    ...editOpportunity,
                    opportunity: {
                      ...editOpportunity.opportunity,
                      date_expectation: '',
                    },
                  });
                }
              }}
              errorMensage={formState.errors.date_expectation?.message}
            />
            <SelectComponent
              isClearable
              isDisabled={isLoading || !editOpportunity.edit}
              title="Estado"
              options={states}
              closeMenuOnSelect
              value={address.state}
              onChange={(e: any) => {
                if (e) {
                  fetchCities(e.value);
                  setValue('state', e.value);
                  setValue('city', '');
                  setAdress({
                    state: e,
                    city: null,
                  });
                } else {
                  setAdress({
                    state: null,
                    city: null,
                  });
                  setValue('state', '');
                  setValue('city', '');
                }
              }}
              errorMensage={formState.errors.state?.message}
            />

            <SelectComponent
              isClearable
              isDisabled={isLoading || !editOpportunity.edit || !address.state}
              title="Cidade"
              options={citiesOptions}
              isLoading={citiesLoading}
              closeMenuOnSelect
              value={address.city}
              onChange={(e: any) => {
                setAdress({ ...address, city: e });

                if (e) {
                  setValue('city', e.value);
                } else {
                  setValue('city', '');
                }
              }}
              errorMensage={formState.errors.state?.message}
            />

            <div className="col-span-2">
              <p className="text-sm mb-1">Observação</p>
              <textarea
                disabled={isLoading || !editOpportunity.edit}
                onChange={(e) => {
                  setValue('obs', e.target.value);
                  setEditOpportunity({
                    ...editOpportunity,
                    opportunity: {
                      ...editOpportunity.opportunity,
                      obs: e.target.value,
                    },
                  });
                }}
                defaultValue={editOpportunity.opportunity.obs}
                name="textinput"
                className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
              />
            </div>
            {editOpportunity.edit && (
              <div className="col-span-2 flex gap-3 justify-center">
                <Button
                  className="w-36 my-3"
                  variant="outline-primary"
                  onClick={() =>
                    setEditOpportunity({ ...editOpportunity, edit: false })
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="w-36 relative flex items-center justify-center my-3"
                  variant="primary-strong"
                  disabled={isLoading}
                >
                  Salvar{' '}
                  {isLoading && (
                    <div className="absolute right-0">
                      <Spinner />
                    </div>
                  )}
                </Button>
              </div>
            )}
          </form>
        )}
      </Card>
    </div>
  );
};

export default GeneralData;
