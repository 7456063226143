import React from 'react';
import { SelectComponent } from '../../../components';
import { OptionsSelectProp } from '../../../components/Select/select';
import { useFetchActivitieType } from '../hooks';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

interface props {
  value: any[];
  setValue: React.Dispatch<
    React.SetStateAction<OptionsSelectProp | OptionsSelectProp[]>
  >;
  isMulti: boolean;
}

const SelectActivity: React.FC<props> = ({ isMulti, setValue, value }) => {
  const accountProps = getTokenAccountInformation();
  const { ActivitieTypeList, isLoading } = useFetchActivitieType(
    '',
    accountProps.companie_id
  );
  const ids = value.map((item) => item.id);
  const options = ActivitieTypeList.filter(
    (elem) => !ids.includes(elem.id)
  ).map((item) => ({
    label: item.title,
    value: item.id,
  }));
  return (
    <SelectComponent
      options={options}
      title="Tarefas"
      value={null}
      onChange={setValue}
      isMulti={isMulti}
      isLoading={isLoading}
    />
  );
};

export default SelectActivity;
