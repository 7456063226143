import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft';
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline';
import { Lock } from '@styled-icons/boxicons-regular/Lock';
import Header from '../components/header';
import Menu from '../components/menu';
import { Button, Input, Spinner } from '../../../components';
import { sendEmail } from '../api';
import { dictionaryError, verifyEmail } from '../../../helpers/utils.helper';
import backgroundImage from '../../../assets/jpg/background_home.jpg';
import backgroundImageMobile from '../../../assets/jpg/background_home_mobile.jpg';

function RecoveryPassCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const handleSendEmail = async () => {
    setIsLoading(true);

    const valid = await verifyEmail(value);

    if (valid.sendEmail === true) {
      const email = await sendEmail(value);

      if (email === true) {
        setIsLoading(false);
        navigate(`/change-pass/${value}/${valid.identity}`);
      } else {
        setIsLoading(false);
        setErr('Desculpe, algo deu errado.');
      }
    } else {
      setIsLoading(false);
      setErr(dictionaryError(String(valid)));
    }
  };

  return (
    <div className="mt-10 lg:mt-0 lg:pl-16 w-full lg:w-4/6 flex flex-col lg:flex-row gap-8 relative z-2 lg:items-start justify-center items-center md:mx-10">
      <div className="flex flex-col justify-between items-center bg-white w-full md:max-w-[420px] rounded-[30px] px-[38px] py-[53px] lg:py-8 2xl:py-[38px] lg:max-w-sm min-h-[480px] border border-solid border-primary">
        <div className="flex w-full justify-between">
          <div className="flex items-center">
            <KeyboardArrowLeft
              size={24}
              className=" cursor-pointer"
              onClick={() => navigate('/session/login')}
            />
            <strong className="text-sm">Voltar</strong>
          </div>
          <strong className="text-sm">Redefinir Senha</strong>
        </div>
        <Lock size={80} className="text-primary" />
        <div className="w-full flex flex-col mb-12">
          <strong className="text-xl mb-2">Recuperar senha</strong>
          <p className="text-xs mb-2">
            Para recuperar sua senha, insira abaixo o e-mail cadastrado
          </p>
          <Input
            type="text"
            icon={<EmailOutline size={18} color="#ddd" />}
            placeholder="E-mail"
            onChange={(e) => setValue(e.target.value)}
          />
          <p className="text-red text-xs mb-3">{err}</p>
          <Button
            variant="primary-strong"
            onClick={handleSendEmail}
            disabled={isLoading}
            className="w-full flex items-center justify-center relative"
          >
            Continuar
            {isLoading && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
const PasswordRecovery: React.FC = () => {
  const [active, setActive] = useState(false);
  return (
    <div className="overflow-y-auto flex flex-col bg-[#FCF7F2] lg:overflow-hidden">
      <div className="main w-full min-h-screen pb-6">
        {!active ? (
          <Header active={active} setActive={setActive} />
        ) : (
          <Menu setNotActive={setActive} />
        )}
        <img
          src={backgroundImageMobile}
          alt="backgroundImage"
          className="w-full absolute top-0 z-0 lg:hidden brightness-75  h-screen"
        />
        <img
          src={backgroundImage}
          alt="backgroundImage"
          className="hidden bg-contain lg:flex lg:w-full absolute top-0 z-0 h-screen max-w-none brightness-75"
        />
        <div className="flex flex-col min-w-full min-h-full items-center justify-center max-[768px]:px-10 max-[480px]:px-2">
          <RecoveryPassCard />
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
