import React from 'react';
import { io } from 'socket.io-client';

import { Modal, Card, Spinner } from '../../../../components';
import { useWppContext } from '../../context';
import { companieWhatsappInstancesStatus } from '../../api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

const socket = getTokenAccountInformation()
  ? io(`${process.env.REACT_APP_API}`, {
      transports: ['websocket'],
    })
  : null;

export const ConnectModal: React.FC = () => {
  const { modal, setModal, setReload } = useWppContext();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    base64: '',
  });

  React.useEffect(() => {
    if (!socket) return;
    if (modal.show === 'connect') {
      setLoading(true);
      companieWhatsappInstancesStatus(modal.data.id, {
        action: 'connect',
      })
        .then(setData)
        .finally(() => {
          setLoading(false);
        });
    }

    socket.on('received_instance', (text) => {
      if (text === 'Conectada') {
        setModal({ ...modal, show: 'undefined' });
        setReload(`${Math.random()} key`);
      }

      if (text === 'Desconectada') {
        setReload(`${Math.random()} key`);
      }
    });
    socket.emit('set_room_instance', `set_room_${modal.data?.id}`);
  }, [modal.show === 'connect']);

  return (
    <Modal
      title="Use o WhatsApp no Leadsim"
      isOpen={modal.show === 'connect'}
      setIsOpen={() => setModal({ ...modal, show: 'undefined' })}
      size="2xlarge"
      minHeight="min-h-[685px]"
    >
      <Card className="flex justify-center items-center ">
        {loading ? (
          <div className="w-full mt-16 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row sm:justify-between gap-3 w-full">
              <div className="flex flex-col text-sm gap-2">
                <p>1. Abra o WhatsApp em seu celular.</p>
                <p>
                  2. Toque em{' '}
                  <strong className="font-semibold">Mais Opções</strong> ANDROID
                  ou
                  <strong className="font-semibold"> Configurações</strong> no
                  Iphone.
                </p>
                <p>
                  3. Toque em{' '}
                  <strong className="font-semibold">
                    Dispositivos conectados
                  </strong>{' '}
                  e, em seguida, em{' '}
                  <strong className="font-semibold">
                    Conectar um dispositivo.
                  </strong>
                </p>
                <p>
                  4. Aponte seu celular para esta tela para capturar o QR code.
                </p>
              </div>
              <img width={200} src={data.base64} alt="qrcode" />
            </div>
            <div className="flex flex-col gap-3 items-center">
              <p className="text-lg font-light text-gray-600">Tutorial</p>
              <p className="text-xs text-green-600 mb-3">
                Precisa de ajuda para começar?
              </p>
              <iframe
                className="w-full"
                // width="560"
                height="315"
                src="https://www.youtube.com/embed/ZVn_Gqj8Xes?si=oWWWmpKgfhAznK2Z"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </Card>
    </Modal>
  );
};
