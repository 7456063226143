import React from 'react';
import IconDictionary from '../Icons/icons';

interface props {
  title: string;
}

const HeaderPages: React.FC<props> = ({ title }) => (
  <div className="flex gap-3 items-center text-primary">
    <IconDictionary name={title} size={24} />
    <strong className="text-lg text-black">{title}</strong>
  </div>
);

export default HeaderPages;
