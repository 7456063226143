import { ForwardedRef, forwardRef, useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFetchStep } from '../../modules/funnel/hooks';

import { Input, SelectComponent, Switch } from '../../components';

import { SelectProps, ConfigPropsAutomationFullProps } from './types';
import maskHelper from '../../helpers/mask.helper';

export const GroupSelects = forwardRef(
  (
    {
      config,
      optionsFunnel,
      optionUser,
      type,
      optionSaleChannel,
      optionsSegments,
      apiFunction,
    }: SelectProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const [funnelId, setFunnelId] = useState(
      config?.funnel_step?.funnel?.id || 0
    );

    const { StepList, isLoading } = useFetchStep('key', funnelId);

    const optionsStep = StepList.map((step) => ({
      label: step.title,
      value: step.id,
    }));

    const optFunnel = optionsFunnel.find((item) => item.value === funnelId);

    const [createAccessData, setCreateAccessData] =
      useState<ConfigPropsAutomationFullProps>(config);

    const schema = yup.object().shape({
      whatsapp_number: yup
        .string()
        .required('Campo obrigatório')
        .min(10, 'Número inválido')
        .max(11, 'Número inválido'),
      companie_user_id_owner: yup.string().required('Selecione uma opção'),
      funnel_step_id: yup.string().required('Selecione uma opção'),
    });

    const { setValue, handleSubmit, formState } =
      useForm<ConfigPropsAutomationFullProps>({
        defaultValues: {
          is_bulk_shipping: config.is_bulk_shipping ?? false,
          sales_channel_id: config.sales_channel_id,
          segment_id: config.segment_id,
          funnel_step_id: config.funnel_step_id,
          companie_user_id_owner: config.companie_user_id_owner,
          is_create_opportunity: !!config.is_create_opportunity,
          whatsapp_number: config.whatsapp_number,
        },
        resolver: yupResolver(schema),
      });

    return (
      <form onSubmit={handleSubmit(apiFunction)} id={`form ${type}`} ref={ref}>
        <div className="grid grid-cols-2 gap-3">
          <Input
            disabled={!!config.whatsapp_number}
            value={createAccessData.whatsapp_number}
            variant="outline-orange"
            placeholder="9999999999"
            title="Número do WhatsApp"
            maxLength={11}
            onChange={(e: any) => {
              setValue('whatsapp_number', maskHelper.number(e.target.value));
              setCreateAccessData({
                ...createAccessData,
                whatsapp_number: maskHelper.number(e.target.value),
              });
            }}
            errorMensage={formState.errors.whatsapp_number?.message}
          />
          <SelectComponent
            value={
              optionUser.find(
                (item) =>
                  item.value === createAccessData?.companie_user_id_owner
              ) ?? null
            }
            options={optionUser}
            closeMenuOnSelect
            title="Proprietário"
            onChange={(e: any) => {
              setValue('companie_user_id_owner', e.value);
              setCreateAccessData({
                ...createAccessData,
                companie_user_id_owner: e.value,
              });
            }}
            errorMensage={formState.errors.companie_user_id_owner?.message}
          />

          <SelectComponent
            value={optFunnel}
            options={optionsFunnel}
            closeMenuOnSelect
            title="Funil"
            onChange={(e: any) => {
              setFunnelId(e.value);
            }}
          />

          <SelectComponent
            value={
              optionsStep.find(
                (item) => item.value === createAccessData.funnel_step_id
              ) ?? null
            }
            options={optionsStep}
            closeMenuOnSelect
            title="Etapa"
            onChange={(e: any) => {
              setValue('funnel_step_id', e.value);
              setCreateAccessData({
                ...createAccessData,
                funnel_step_id: e.value,
              });
            }}
            isDisabled={funnelId === -1 || isLoading}
            errorMensage={formState.errors.funnel_step_id?.message}
          />

          <SelectComponent
            value={
              optionSaleChannel.find(
                (item) => item.value === createAccessData.sales_channel_id
              ) ?? null
            }
            options={optionSaleChannel}
            closeMenuOnSelect
            title="Canal de venda"
            onChange={(e: any) => {
              setValue('sales_channel_id', e.value);
              setCreateAccessData({
                ...createAccessData,
                sales_channel_id: e.value,
              });
            }}
            errorMensage={formState.errors.sales_channel_id?.message}
          />

          <SelectComponent
            value={
              optionsSegments.find(
                (item) => item.value === createAccessData.segment_id
              ) ?? null
            }
            options={optionsSegments}
            closeMenuOnSelect
            title="Segmentos"
            onChange={(e: any) => {
              setValue('segment_id', e.value);
              setCreateAccessData({
                ...createAccessData,
                segment_id: e.value,
              });
            }}
            errorMensage={formState.errors.segment_id?.message}
          />
        </div>
        <div className="flex items-center mt-3">
          <Switch
            state={createAccessData.is_create_opportunity}
            onChange={() => {
              setCreateAccessData({
                ...createAccessData,
                is_create_opportunity: !createAccessData.is_create_opportunity,
              });

              setValue(
                'is_create_opportunity',
                !createAccessData.is_create_opportunity
              );
            }}
          />
          <p className="text-sm ml-2">
            Criar oportunidade ao receber mensagem.
          </p>
        </div>
        <div className="flex items-center mt-3">
          <Switch
            state={createAccessData.is_bulk_shipping}
            onChange={() => {
              setCreateAccessData({
                ...createAccessData,
                is_bulk_shipping: !createAccessData.is_bulk_shipping,
              });

              setValue('is_bulk_shipping', !createAccessData.is_bulk_shipping);
            }}
          />
          <p className="text-sm ml-2">Permitir envio em massa</p>
        </div>
      </form>
    );
  }
);

export type { ConfigPropsAutomationFullProps };
