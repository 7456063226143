import React, { useState } from 'react';
import MainPage from '../../main/components/mainPage';

import { useFetchWhatsappMessages } from '../hooks';
import { ApiWhatsapp } from '../api';
import { DeleteModal, Switch } from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import IconDictionary from '../../../components/Icons/icons';

import { RegisterWhatsapp } from '../components';

const WhatsappMessages: React.FC = () => {
  const [key, setKey] = useState('key');
  const [isLoading, setIsLoading] = useState(false);
  const { WhatsappMessagesList, isLoadingWhatsapp } =
    useFetchWhatsappMessages(key);

  const [modalRegisterWhatsapp, setModalRegisterWhatsapp] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [whatsappData, setWhatsappData] = useState<any | null>(null);
  const [input, setInput] = useState('');

  const { notify } = ToastNotify();

  const onUpdateStatus = async (value: any) => {
    setIsLoading(true);
    const getFilenameFromPath = (filePath: string) => {
      const parts = filePath.split('/');
      return parts[parts.length - 1];
    };
    const getFileExtension = (filename: string) => {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1) {
        return null; // Se não houver ponto no nome do arquivo, retorna null ou lance um erro
      }
      return filename.substring(lastDotIndex + 1);
    };
    let file = value.path;

    if (value.path !== null) {
      const filename = getFilenameFromPath(value.path);
      const imageType = getFileExtension(filename);
      const blob = await ApiWhatsapp.getBlobFromUrl(value.path);
      file = new File([blob], filename, {
        type: `image/${imageType}`,
      });
    }

    const res = await ApiWhatsapp.updateWhatsappMessage(value.id, {
      ...value,
      is_active: !value.is_active,
      file,
    });

    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoading(false);
  };

  const columns = [
    {
      name: 'Mensagem',
      key: 'text',
      minWidth: '95px',
      selector: (row: any) => <p className="max-w-lg truncate">{row.text}</p>,
    },
    {
      name: 'Arquivo',
      key: 'path',
      minWidth: '95px',
      selector: (row: any) => (
        <p className="max-w-md truncate">{row.path ?? '--'}</p>
      ),
    },
    {
      name: 'Status',
      key: 'is_active',
      width: '100px',
      selector: (row: any) => (
        <Switch onChange={() => onUpdateStatus(row)} state={row.is_active} />
      ),
    },
    {
      name: '',
      width: '100px',
      selector: (row: any) => (
        <button
          type="button"
          disabled={row.status === 'Inativa'}
          onClick={() => {
            setWhatsappData(row);
            setShowModalDelete(true);
          }}
          className="hover:scale-110 w-16 text-white font-semibold flex items-center justify-center py-1 rounded-sm"
        >
          <IconDictionary
            size={18}
            name="Excluir"
            color="#DA3C3C"
            className="mr-0"
          />
        </button>
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return WhatsappMessagesList.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    }
    return WhatsappMessagesList.filter(
      (item) => item.text.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingWhatsapp || isLoading}
        column={columns}
        title="Mensagens de Whatsapp"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterWhatsapp}
        setRegisterPage={() => setWhatsappData(null)}
        onRowClick={(e) => {
          setWhatsappData(e);
          setModalRegisterWhatsapp(true);
        }}
      />
      <RegisterWhatsapp
        whatsapp={whatsappData}
        reloadTable={setKey}
        setShow={setModalRegisterWhatsapp}
        show={modalRegisterWhatsapp}
      />
      {whatsappData && (
        <DeleteModal
          apiFunction={ApiWhatsapp.deleteWhatsappMessage}
          notify={notify}
          type="mensagem"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={whatsappData.id}
          name={whatsappData.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default WhatsappMessages;
