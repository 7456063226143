/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from 'yup';

import { Plans } from '../../../chartAccounts/types';
import {
  Button,
  DeleteModal,
  Input,
  InputDate,
  Modal,
  SelectComponent,
  Table,
} from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import maskHelper from '../../../../helpers/mask.helper';
import { CopyLink, dictionaryError } from '../../../../helpers/utils.helper';
import { ApiContract } from '../../api';
import {
  contractChargeProps,
  contractProps,
  financialProps,
  updateformProps,
} from '../../types';
import SendWhatsApp from '../sendWhatsapp';

interface propsModal {
  show: boolean;
  close: () => void;
  PlanList: Plans[];
  updateList: () => void;
  notify: (e: any) => void;
  contractId: number;
  selectedItemsTableValues: { id: number; value: string; idTable: number }[];
}

function ContractNegociation({
  close,
  show,
  updateList,
  notify,
  contractId,
  selectedItemsTableValues,
  PlanList,
}: propsModal) {
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const planAccountOptions = PlanList.filter(
    (item) => item.type === 'R' && item.superior?.split('.').length === 1
  ).map((elem) => ({ label: elem.title, value: elem.id }));
  const [planAccount, setPlanAccount] = useState<{
    label: string;
    value: number;
  }>();
  const [postingAccount, setPostingAccount] = useState<{
    label: string;
    value: number;
  } | null>();
  const [postingAccountOptions, setPostingAccountOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const total = selectedItemsTableValues.reduce(
    (prev, curr) => Number(prev) + Number(curr.value),
    0
  );

  const [valueInstallment, setValueInstallment] = useState(total);
  const schema = yup.object().shape({
    plan_account_id: yup
      .number()
      .typeError('Selecione uma opção')
      .min(1, 'Selecione uma opção')
      .required('Selecione uma opção'),
    value: yup
      .number()
      .required('Campo obrigatório')
      .test({
        test: (value) => Number(value) > 5,
        message: 'Valor da parcela deve ser maior que R$ 5,00',
      }),
    first_pay_day_date: yup
      .date()
      .typeError('Data inválida')
      .min(yesterdayDate, 'Data deve ser igual ou maior que hoje')
      .required('Campo obrigatório'),
    form_of_payment: yup.string().required('Selecione uma opção'),
    method: yup.string().required('Selecione uma opção'),
  });

  const { setValue, handleSubmit, formState, getValues, reset } =
    useForm<contractChargeProps>({
      resolver: yupResolver(schema),
      defaultValues: {
        installment: 1,
        form_of_payment: 'creditcard',
        method: 'in_sight',
        first_pay_day_date: moment().format('YYYY-MM-DD'),
        obs: 'Negociação - Contrato',
        value: Number(total),
      },
    });
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    formPayment: { label: 'Cartão de Crédito', value: 'creditcard' },
    method: { label: 'À vista', value: 'in_sight' },
    installments: { label: '1x', value: 1 },
  });

  const paymentForm = [
    { label: 'Cartão de Crédito', value: 'creditcard' },
    { label: 'Boleto', value: 'boleto' },
    { label: 'Pix', value: 'pix' },
  ];
  const optionMethod = [
    { label: 'À vista', value: 'in_sight' },
    { label: 'Parcelado', value: 'installments' },
  ];

  const installments = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
    { label: '4x', value: 4 },
    { label: '5x', value: 5 },
    { label: '6x', value: 6 },
    { label: '7x', value: 7 },
    { label: '8x', value: 8 },
    { label: '9x', value: 9 },
    { label: '10x', value: 10 },
  ];

  const Submit = async (e: contractChargeProps) => {
    setIsLoading(true);
    const res = await ApiContract.createChargesContract({
      plan_account_id: e.plan_account_id,
      installment: paymentInfo.installments.value,
      method: paymentInfo.method.value,
      obs: e.obs,
      contract_id: contractId,
      first_pay_day_date: maskHelper.formatDateYMD(
        getValues('first_pay_day_date')
      ),
      value: Number((valueInstallment / e.installment).toFixed(2)),
      form_of_payment: paymentInfo.formPayment.value,
      fin_ids_cancel: selectedItemsTableValues.map((item) => item.idTable),
    });
    if (res === true) {
      setIsLoading(false);
      notify({
        message: 'Cobrança gerada com sucesso!',
        type: 'Success',
      });
      updateList();
      close();
    } else {
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // reset({ plan_account_id: undefined });
    setValue('plan_account_id', 0);
    setPostingAccount(null);
    const currentPlanId = planAccount?.value;
    const currentPlan = PlanList.find((item) => item.id === currentPlanId);
    const account = PlanList.filter(
      (item) => item.superior === currentPlan?.num_plan
    );
    setPostingAccountOptions(
      account.map((elem) => ({ label: elem.title, value: elem.id ?? 0 }))
    );
  }, [planAccount]);

  return (
    <Modal
      size="2xlarge"
      isOpen={show}
      setIsOpen={close}
      minHeight="min-h-[470px]"
      title="Contrato Negociação"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
        onSubmit={handleSubmit(Submit)}
      >
        <SelectComponent
          title="Plano de Contas"
          value={planAccount}
          options={planAccountOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setPlanAccount(e);
          }}
          errorMensage={
            planAccount ? '' : formState.errors.plan_account_id?.message
          }
        />
        <SelectComponent
          title="Conta de lancamento"
          value={postingAccount}
          isDisabled={planAccount === undefined}
          options={postingAccountOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('plan_account_id', e.value);
            setPostingAccount(e);
          }}
          errorMensage={formState.errors.plan_account_id?.message}
        />
        <SelectComponent
          title="Forma de pagamento"
          options={paymentForm}
          value={paymentInfo.formPayment}
          closeMenuOnSelect
          onChange={(e: any) => {
            if (e.value === 'pix') {
              setValue('installment', 1);
              setValue('method', 'in_sight');
              setValue('form_of_payment', e.value);
              setPaymentInfo({
                formPayment: e,
                method: { label: 'À vista', value: 'in_sight' },
                installments: { label: '1x', value: 1 },
              });
            } else {
              setValue('form_of_payment', e.value);
              setPaymentInfo({
                ...paymentInfo,
                formPayment: e,
              });
            }
          }}
          errorMensage={formState.errors.form_of_payment?.message}
        />

        <SelectComponent
          isDisabled={paymentInfo.formPayment.value === 'pix'}
          value={paymentInfo.method}
          title="Método de pagamento"
          options={optionMethod}
          closeMenuOnSelect
          onChange={(e: any) => {
            if (e.value === 'in_sight') {
              setValue('installment', 1);
              setPaymentInfo({
                ...paymentInfo,
                installments: { label: '1x', value: 1 },
                method: e,
              });
              setValue('method', e.value);
            } else {
              setValue('method', e.value);
              setPaymentInfo({
                ...paymentInfo,
                method: e,
              });
            }
          }}
          errorMensage={formState.errors.method?.message}
        />

        <Input
          variant="outline-orange"
          title="Valor Total"
          defaultValue={maskHelper.formatMoeda(valueInstallment)}
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setValueInstallment(
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
        />

        <SelectComponent
          isDisabled={
            paymentInfo.formPayment.value === 'pix' ||
            paymentInfo.method.value === 'in_sight'
          }
          title="Parcelas"
          value={paymentInfo.installments}
          options={installments}
          closeMenuOnSelect
          onChange={(e: any) => {
            setPaymentInfo({ ...paymentInfo, installments: e });
            setValue('installment', e.value);
          }}
          errorMensage={formState.errors.installment?.message}
        />

        <Input
          variant="outline-orange"
          title="Valor Parcela"
          disabled
          value={maskHelper.formatMoeda(
            valueInstallment / paymentInfo.installments.value
          )}
          errorMensage={formState.errors.value?.message}
        />

        <InputDate
          title="Vencimento"
          defaultValue={new Date()}
          onChange={(e: Date) => {
            if (e !== null) {
              setValue(
                'first_pay_day_date',
                maskHelper.formatDateYMD(String(e.toLocaleDateString('pt-BR')))
              );
            }
          }}
          errorMensage={formState.errors.first_pay_day_date?.message}
        />

        <div className="col-span-2">
          <p className="text-sm mb-1">Observação</p>
          <textarea
            defaultValue="Negociação - Contrato"
            onChange={(e) => {
              setValue('obs', e.target.value);
            }}
            name="textinput"
            className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
          />
        </div>

        <Button
          isLoading={isLoading}
          disabled={
            isLoading || valueInstallment / paymentInfo.installments.value < 5
          }
          type="submit"
          actionType="button-loading"
          className="col-span-2 mx-auto"
          variant="primary-strong"
        >
          Gerar cobrança
        </Button>
      </form>
    </Modal>
  );
}

interface props extends updateformProps {
  PlanList: Plans[];
}

const HistoryPayments: React.FC<props> = ({
  id,
  notify,
  defaultValue,
  PlanList,
}) => {
  const [historyList, setHistoryList] = useState(defaultValue?.financial ?? []);
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkPayment, setLinkPayment] = useState('');
  const [payments, setPayments] = useState<
    { id: number; value: string; idTable: number }[]
  >([]);
  const [idPayments, setIdPayments] = useState<number[]>([]);
  const [showCreateUpdate, setShowCreateUpdate] = useState(false);

  const AddRemoveList = (idPayment: number) => {
    if (idPayments.includes(idPayment)) {
      const ids = idPayments.filter((item) => item !== idPayment);
      setIdPayments(ids);
      setPayments(payments.filter((item) => item.id !== idPayment));
    } else {
      const ids = idPayments.concat([idPayment]);
      setIdPayments(ids);
      const paymentsValues = historyList
        .filter((elem) => ids.includes(elem.galax_pay_id))
        .map((item) => ({
          id: item.galax_pay_id,
          value: item.value,
          idTable: item.id,
        }));
      setPayments(paymentsValues);
    }
  };

  const columns = [
    {
      name: '',
      key: '',
      width: '50px',
      cell: (row: financialProps) =>
        (row.status === 'Aberta' || row.status === 'Vencida') && (
          <input
            checked={idPayments.includes(row.galax_pay_id)}
            type="checkbox"
            className="accent-primary w-10 cursor-pointer"
            onChange={() => {
              AddRemoveList(row.galax_pay_id);
            }}
          />
        ),
    },
    {
      name: 'ID Transação',
      key: 'galax_pay_id',
      minWidth: '110px',
      selector: (row: financialProps) => row.galax_pay_id,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: financialProps) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Vencimento',
      key: 'due_date',
      selector: (row: financialProps) =>
        maskHelper.formatDateDMY(row.due_date.slice(0, 10)),
    },
    {
      name: 'Conta de Lançamento',
      key: 'pla_account_id',
      minWidth: '160px',
      selector: (row: financialProps) =>
        PlanList.find((item) => item.id === row.pla_account_id)?.title,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: financialProps) => row.status,
      cell: (row: financialProps) => {
        switch (row.status) {
          case 'Aberta':
            return <p className="text-[11px] text-black">{row.status}</p>;
          case 'Paga':
            return (
              <p className="text-[11px] font-medium text-green">{row.status}</p>
            );
          case 'Vencida':
            return (
              <p className="text-[11px] font-semibold text-red">{row.status}</p>
            );
          case 'Cancelada':
            return (
              <p className="text-[11px] font-semibold text-gray-600">
                {row.status}
              </p>
            );
          default:
            return (
              <p className="text-[11px] font-semibold text-black">
                {row.status}
              </p>
            );
        }
      },
    },
    {
      name: 'Parcela',
      key: 'installment',
      selector: (row: financialProps) => row.installment,
    },
    {
      name: 'Valor Pago',
      key: 'birth',
      selector: (row: financialProps) =>
        maskHelper.formatMoeda(row.received_value),
    },
    {
      name: 'Data Pagamento',
      key: 'email',
      minWidth: '130px',
      selector: (row: financialProps) =>
        maskHelper.formatDateDMY(row.received_date ?? ''),
      hide: 800,
    },
    {
      name: '',
      cell: (row: financialProps) => (
        <>
          <IconDictionary
            name="Link"
            size={20}
            className="cursor-pointer text-gray-600"
            onClick={() => {
              CopyLink(row.payment_link);
            }}
          />
          <IconDictionary
            name="Whatsapp"
            size={20}
            className="cursor-pointer text-green ml-5"
            onClick={() => {
              const message = `Olá, tudo bem? %0AAqui está o link do pagamento: %0A%0A${row.payment_link}%0A%0AQualquer dúvida estamos à disposição. %0AObrigado.%0AAtendimento LeadSim`;
              setLinkPayment(message);
              setShowWhatsApp(true);
            }}
          />
        </>
      ),
    },
  ];

  const UpdateFinancialHistory = async () => {
    setIsLoading(true);
    const res = await ApiContract.getFinacialByContract('all', {
      client_id: [],
      contract_id: [id],
      form_of_payment: [],
      order: 'due_date_desc',
      pla_account_id: [],
      provider_id: [],
      received: null,
      status: [],
      status_contract: [],
      type: 'Todos',
      skip: 0,
      take: 9999999999999,
    });
    if (typeof res !== 'string') {
      setHistoryList(res.data);
    }
    setIdPayments([]);
    setPayments([]);
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col">
      {showCreateUpdate && historyList.length > 0 && (
        <ContractNegociation
          PlanList={PlanList}
          selectedItemsTableValues={payments}
          contractId={id}
          notify={notify}
          show={showCreateUpdate}
          updateList={UpdateFinancialHistory}
          close={() => setShowCreateUpdate(false)}
        />
      )}

      <Table
        isLoading={isLoading}
        columns={columns}
        data={historyList}
        scrollTable
      />
      {showWhatsApp === true && (
        <SendWhatsApp
          clientWhatsapp={defaultValue?.resp_financial_whatsapp ?? ''}
          setOpen={setShowWhatsApp}
          show={showWhatsApp}
          message={linkPayment}
        />
      )}

      <Button
        onClick={() => {
          setShowCreateUpdate(true);
        }}
        className="mx-auto w-64 mt-5"
        disabled={idPayments.length === 0}
      >
        Negociar
      </Button>
    </div>
  );
};

export default HistoryPayments;
