import React, { ReactNode, useState } from 'react';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { Search } from '@styled-icons/material-rounded/Search';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import Card from '../../../../components/Card/card';
import { Button, Input, Table } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

/* eslint-disable no-unused-vars */
interface MainProps {
  isLoading?: boolean;
  title: string;
  titleCard?: string;
  column: any;
  objectReport?: any[][];
  filename?: string;
  FilterComponent?: ReactNode;
  sizeIcon?: number;
  CustomCard?: ReactNode;
  selectedRowId?: number;
  currentPageInPagination?: number;
  changePageTable?: number;
  reportAndCreate?: boolean;
  pagination?: boolean;
  cardsDirection?: 'row' | 'column';
  customHeader?: ReactNode;
  noCards?: boolean;
  filterTable: () => any[];
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setModalCreateUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterPage: React.Dispatch<React.SetStateAction<any>>;
  onRowClick?: (e: any) => void;
}

const MainPage: React.FC<MainProps> = ({
  title,
  column,
  isLoading,
  objectReport,
  filename,
  FilterComponent,
  CustomCard,
  sizeIcon = 28,
  selectedRowId,
  currentPageInPagination,
  changePageTable,
  reportAndCreate = false,
  pagination = true,
  titleCard = title,
  cardsDirection = 'row',
  customHeader,
  noCards = false,
  filterTable,
  setInput,
  setModalCreateUpdate,
  setRegisterPage,
  onRowClick,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(currentPageInPagination ?? 1);
  const exportToXLS = () => {
    if (objectReport) {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const csvData = [];
      const keys = objectReport[0];
      const values = objectReport.slice(1, objectReport.length);
      let item: any = {};

      for (let j = 0; j < values.length; j++) {
        for (let i = 0; i < keys.length; i++) {
          item[keys[i]] = values[j][i];
        }
        csvData.push(item);
        item = {};
      }

      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, filename + fileExtension);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full flex justify-between">
        <div className="flex gap-3 items-center text-primary">
          <IconDictionary name={title} size={sizeIcon} />
          <strong className="text-lg text-black">{title}</strong>
        </div>
      </div>
      <div className="w-full flex flex-col gap-3 mt-8 min-h-full">
        {customHeader ?? (
          <div
            className={`flex flex-col ${
              cardsDirection === 'row'
                ? 'xl:flex-row'
                : 'xl:grid xl:grid-cols-2'
            } gap-3.5`}
          >
            {FilterComponent ?? (
              <Card className="flex-col w-full pt-5 px-4 pb-3.5 gap-4">
                <strong>Pesquisar</strong>
                <div className="flex flex-col sm:flex-row gap-2 items-center">
                  <Input
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                      if (e.target.value.length === 0) setInput('');
                    }}
                    icon={<Search size={18} color="#8C8C8C" className="mr-4" />}
                    placeholder={`Pesquisar ${title}`}
                    className="w-full"
                    onKeyDown={(e) =>
                      e.key === 'Enter' && setInput(searchInput)
                    }
                    clearField={
                      searchInput !== '' && (
                        <CloseOutline
                          color="#8C8C8C"
                          size={16}
                          className="absolute right-3 cursor-pointer"
                          onClick={() => {
                            setInput('');
                            setSearchInput('');
                          }}
                        />
                      )
                    }
                  />
                  <Button
                    className="w-32 h-11"
                    onClick={() => {
                      setInput(searchInput);
                    }}
                  >
                    Pesquisar
                  </Button>
                </div>
              </Card>
            )}

            {noCards ? (
              <> </>
            ) : objectReport ? (
              <>
                {reportAndCreate && (
                  <Card
                    className={`flex-col w-full text-center ${
                      cardsDirection === 'column' ? 'xl:w-full' : 'xl:w-1/3'
                    } justify-center items-center gap-4 pb-3.5 pt-5`}
                  >
                    <strong>Cadastrar {titleCard}</strong>
                    <Button
                      className="w-40 h-11"
                      onClick={() => {
                        if (setModalCreateUpdate) setModalCreateUpdate(true);
                        setRegisterPage({ title: 'Dados Pessoais', page: 0 });
                      }}
                    >
                      Cadastrar
                    </Button>
                  </Card>
                )}
                <Card
                  className={`flex-col w-full ${
                    cardsDirection === 'column' ? 'xl:w-full' : 'xl:w-1/3'
                  }  justify-center items-center gap-4 pb-3.5 pt-5`}
                >
                  <strong className="text-md">Exportar dados da tabela</strong>
                  <Button className="w-40 h-11" onClick={exportToXLS}>
                    Download
                  </Button>
                </Card>
              </>
            ) : (
              <>
                {CustomCard}
                <Card className="flex-col w-full xl:w-2/4 justify-center items-center gap-4 pb-3.5 pt-5">
                  <strong>Cadastrar {title}</strong>
                  <Button
                    className="w-40 h-11"
                    onClick={() => {
                      if (setModalCreateUpdate) setModalCreateUpdate(true);
                      setRegisterPage({ title: 'Dados Pessoais', page: 0 });
                    }}
                  >
                    Cadastrar
                  </Button>
                </Card>
              </>
            )}
          </div>
        )}

        <Card className="min-h-[55vh] lg:min-h-[70vh] px-6 pb-8 flex-col w-full overflow-x-auto">
          <Table
            isLoading={isLoading ?? false}
            columns={column}
            data={filterTable()}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagination={pagination}
            totalPerPage={10}
            onRowClick={onRowClick}
            selectedRowId={selectedRowId}
            changePageTable={changePageTable}
          />
        </Card>
        <br />
      </div>
    </div>
  );
};

export default MainPage;
