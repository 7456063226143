/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { Button, Modal, SelectComponent } from '../../../../components';
import { OptionsSelectProp } from '../../../../components/Select/select';
import { ApiOpportunity } from '../../api';
import { ToastNotify, notify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { useFetchStep } from '../../../funnel/hooks';

interface props {
  setShow: (e: boolean) => void;
  reloadMain: (e: number) => void;
  funnelOptions: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  opportunityId: number;
  show: boolean;
}

interface cloneOpportunityProps {
  companie_user_id: { label: string; value: number };
  funnel_step_id: { label: string; value: number };
}

const ModalClone: React.FC<props> = ({
  setShow,
  reloadMain,
  show,
  opportunityId,
  funnelOptions,
  userOptions,
}) => {
  const [funnel, setFunnel] = useState<OptionsSelectProp | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const accountInformation = getTokenAccountInformation();

  const { StepList, isLoading: isLoadingStep } = useFetchStep(
    '',
    funnel?.value ?? -1
  );
  const stepOptions = StepList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const schema = yup.object({
    companie_user_id: yup.object({
      label: yup.string().required(),
      value: yup.number().required('Usuário é obrigatório'),
    }),
    funnel_step_id: yup.object({
      label: yup.string().required(),
      value: yup.number().required('Etapa é obrigatória'),
    }),
  });

  const { handleSubmit, formState, setValue, reset } =
    useForm<cloneOpportunityProps>({
      defaultValues: {
        companie_user_id: {
          label: accountInformation.name,
          value: accountInformation.id,
        },
      },
      resolver: yupResolver(schema),
    });

  const Submit = async (e: cloneOpportunityProps) => {
    setIsLoading(true);
    const res = await ApiOpportunity.cloneOpportunity(opportunityId, {
      companie_user_id: e.companie_user_id.value,
      funnel_step_id: e.funnel_step_id.value,
    });
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      return;
    }

    notify({ message: 'Oportunidade clonada com sucesso!', type: 'Success' });
    reloadMain(funnel?.value);
    setShow(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (show === true) setFunnel(null);
    reset();
  }, [show]);

  return (
    <Modal
      isOpen={show}
      title="Clonar Oportunidade"
      setIsOpen={setShow}
      size="medium"
      minHeight="min-h-[550px]"
    >
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(Submit)}>
        <SelectComponent
          defaultValue={{
            label: accountInformation.name,
            value: accountInformation.id,
          }}
          options={userOptions}
          title="Usuário Proprietário"
          onChange={(e: any) => setValue('companie_user_id', e)}
          errorMensage={formState.errors.companie_user_id?.value?.message}
        />
        <SelectComponent
          options={funnelOptions}
          title="Funil"
          value={funnel}
          onChange={setFunnel}
        />
        <SelectComponent
          isDisabled={!funnel}
          options={stepOptions}
          title="Etapa"
          isLoading={isLoadingStep}
          onChange={(e: any) => setValue('funnel_step_id', e)}
          errorMensage={formState.errors.funnel_step_id?.value?.message}
        />
        <Button
          disabled={isLoading}
          type="submit"
          actionType="button-loading"
          className="mx-auto mt-5"
          variant="primary-strong"
          isLoading={isLoading}
        >
          Clonar
        </Button>
      </form>
    </Modal>
  );
};

export default ModalClone;
