import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ProfessionalProps, propsModal } from '../../../types';

import {
  Button,
  Input,
  SelectComponent,
} from '../../../../../../../components';
import { OptionsSelectProp } from '../../../../../../../components/Select/select';

const ProfessionalData: React.FC<propsModal> = ({ nextPage, defaultValue }) => {
  const jobs = [
    { label: 'Advogado', value: 'lawyer' },
    { label: 'Doutor', value: 'doctor' },
    { label: 'Contador', value: 'accountant' },
    { label: 'Corretor de Imóveis', value: 'realtor' },
    { label: 'Corretor de Seguros', value: 'broker' },
    { label: 'Educador Físico', value: 'physicalEducator' },
    { label: 'Fisioterapeuta', value: 'physiotherapist' },
    { label: 'Outros', value: 'others' },
  ];
  const DisableEdit = !!(defaultValue && defaultValue.galaxPayId);
  const schema = yup.object().shape({
    internalName: yup.string(),
    inscription: yup.string(),
  });

  const [generalDataValue, setGeralData] = useState<ProfessionalProps>({
    inscription: defaultValue?.Professional?.inscription ?? '',
    internalName: defaultValue?.Professional?.internalName ?? '',
  });

  const { handleSubmit, setValue, formState } = useForm<ProfessionalProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      inscription: defaultValue?.Professional?.inscription ?? '',
      internalName: defaultValue?.Professional?.internalName ?? '',
    },
  });
  const Submit = async (values: ProfessionalProps) => {
    nextPage({ Professional: { ...values } });
  };

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-4 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <SelectComponent
        title="Nome Interno no Cel_Cash"
        isDisabled={DisableEdit}
        value={jobs.find(
          (elem) => elem.value === generalDataValue.internalName
        )}
        options={jobs}
        onChange={(e: OptionsSelectProp) => {
          setGeralData({ ...generalDataValue, internalName: e.value });
          setValue('internalName', e.value);
        }}
        errorMensage={formState.errors.internalName?.message}
      />

      <Input
        disabled={DisableEdit}
        title="Inscrição Professional"
        variant="outline-orange"
        value={generalDataValue.inscription}
        onChange={(e) => {
          setGeralData({ ...generalDataValue, inscription: e.target.value });
          setValue('inscription', e.target.value);
        }}
        errorMensage={formState.errors.inscription?.message}
      />

      <div className="col-span-2 flex justify-center mt-6">
        <Button type="submit" actionType="button-loading">
          Continuar
        </Button>
      </div>
    </form>
  );
};

export default ProfessionalData;
