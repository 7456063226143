/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from '../../../../components';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  isLoading: boolean;
  confirm: (e: boolean) => void;
  type: 'R' | 'D';
}

const ModalDeleteUnpaid: React.FC<props> = ({
  setShow,
  confirm,
  show,
  isLoading,
  type,
}) => {
  const [deleteAll, setDeleteAll] = useState(false);
  useEffect(() => {
    if (show) setDeleteAll(false);
  }, [show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Excluir"
      minHeight="min-h-[100px]"
    >
      <p>Deseja realmente excluir a{type === 'D' ? ' despesa' : ' receita'}?</p>
      <div className="flex gap-1 text-xs font-medium mt-3">
        <input
          type="checkbox"
          checked={deleteAll}
          className="cursor-pointer"
          onChange={() => setDeleteAll(!deleteAll)}
        />
        <p>Excluir todos em aberto deste lançamento</p>
      </div>
      <div className="flex gap-3 mx-auto mt-5">
        <Button
          disabled={isLoading}
          className="w-56"
          variant="outline-primary"
          onClick={() => setShow(false)}
        >
          Sair
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className="w-56"
          actionType="button-loading"
          variant="primary-strong"
          onClick={() => confirm(deleteAll)}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDeleteUnpaid;
