import React, { useEffect, useState } from 'react';
import { Button, DeleteModal, Modal, Spinner } from '../../../../../components';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { ApiContact } from '../../../api';
import { useFetchAnnotations } from '../../../hooks';
import { AnnotationProps, formPropsPage } from '../../../types';
import IconDictionary from '../../../../../components/Icons/icons';
import maskHelper from '../../../../../helpers/mask.helper';

interface createProps {
  setShowModalCreateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showModalCreateUpdate: boolean;
  currentNote: null | AnnotationProps;
  personId: number;
  companieUserId: number;
  // eslint-disable-next-line no-unused-vars
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
  reloadNotes: () => void;
}

function CreateUpdate({
  currentNote,
  setShowModalCreateUpdate,
  showModalCreateUpdate,
  companieUserId,
  personId,
  notify,
  reloadNotes,
}: createProps) {
  const [note, setNote] = useState(currentNote?.description ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    if (currentNote === null) {
      setIsLoading(true);
      const response = await ApiContact.createAnnotation({
        description: note,
        companie_user_id: companieUserId,
        person_id: personId,
      });
      if (response.id) {
        notify({ message: 'Anotação salva com sucesso', type: 'Success' });
        reloadNotes();
        setShowModalCreateUpdate(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
      }
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const response = await ApiContact.updateAnnotation(
        { description: note },
        currentNote.id
      );
      if (response.id) {
        notify({ message: 'Anotação atualizada com sucesso', type: 'Success' });
        reloadNotes();
        setShowModalCreateUpdate(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNote(currentNote?.description ?? '');
  }, [showModalCreateUpdate]);
  return (
    <Modal
      minHeight="min-h-[300px]"
      setIsOpen={setShowModalCreateUpdate}
      isOpen={showModalCreateUpdate}
      title={
        currentNote === null ? 'Criar Nova Anotação' : 'Atualizar Anotação'
      }
    >
      <p className="text-sm font-normal">Anotação</p>
      <textarea
        defaultValue=""
        name="textinput"
        className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-24 rounded-lg p-3 resize-none my-2"
        onChange={(e) => setNote(e.target.value)}
        value={note}
      />
      <Button
        variant="primary-strong"
        disabled={note.length === 0 || isLoading}
        onClick={Submit}
        className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
      >
        Salvar
        {isLoading && (
          <div className="absolute right-0">
            <Spinner />
          </div>
        )}
      </Button>
    </Modal>
  );
}

const Annotations: React.FC<formPropsPage> = ({
  id,
  notify,
  companyUserId,
  nextPage,
}) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalCreateUpdate, setShowModalCreateUpdate] = useState(false);
  const [currentNote, setCurrentNote] = useState<AnnotationProps | null>(null);
  const [key, setKey] = useState('key');
  const { AnnotationList, isLoading } = useFetchAnnotations(key, id);

  return (
    <div className="flex flex-col w-full">
      <CreateUpdate
        reloadNotes={() => setKey(`update table ${Math.random()}`)}
        companieUserId={companyUserId}
        currentNote={currentNote}
        notify={notify}
        personId={id}
        setShowModalCreateUpdate={setShowModalCreateUpdate}
        showModalCreateUpdate={showModalCreateUpdate}
      />
      {currentNote && (
        <DeleteModal
          apiFunction={ApiContact.deleteAnnotation}
          close={() => setShowModalDelete(false)}
          name="essa anotação"
          notify={notify}
          open={showModalDelete}
          updateTable={() => setKey(`${Math.random()}`)}
          type="Anotação"
          article="A"
          id={currentNote.id}
        />
      )}

      <div className="flex flex-col w-full max-h-96 overflow-y-auto gap-4 mt-5">
        {isLoading ? (
          <div className="h-8 mt-3 w-full flex justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="w-full px-0 mb-2">
            <Button
              variant="primary-strong"
              actionType="button-add"
              className="w-56"
              onClick={() => {
                setCurrentNote(null);
                setShowModalCreateUpdate(true);
              }}
            >
              Adicionar Anotação
            </Button>
            <div className="relative w-full h-fit flex flex-col mt-5 sm:mt-0">
              <hr className="hidden sm:flex h-full top-0 left-1/2 w-px absolute bg-gray-300 z-0" />
              {AnnotationList.map((note, index) =>
                index % 2 === 0 ? (
                  <div className="min-w-full h-32 flex sm:justify-end mb-8">
                    <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-400 mr-3 z-10 mt-4">
                      <IconDictionary
                        name="Editar"
                        className="cursor-pointer"
                        onClick={() => {
                          setCurrentNote(note);
                          setShowModalCreateUpdate(true);
                        }}
                      />
                    </div>
                    <div className="overflow-y-auto h-32 w-full sm:w-[46%] bg-primary/10 p-3 rounded border border-solid border-primary/30">
                      <div className="flex gap-2 items-center mb-0.5">
                        <IconDictionary name="Relogio" size={10} />
                        <p className="text-[10px]">
                          {maskHelper.formatDateDMY(note.created_at)}
                        </p>
                        <p className="text-xs">-</p>
                        <p className="text-xs font-medium">
                          {note.person.name}
                        </p>
                      </div>

                      <p className="text-sm mt-1">{note.description}</p>
                    </div>
                  </div>
                ) : (
                  <div className="min-w-full flex flex-row-reverse sm:flex-row mb-8">
                    <div className="overflow-y-auto h-32 w-full sm:w-[46%] bg-primary/10 p-3 rounded-sm border border-solid border-primary/30">
                      <div className="flex gap-2 items-center mb-0.5">
                        <IconDictionary name="Relogio" size={10} />
                        <p className="text-[10px]">
                          {maskHelper.formatDateDMY(note.created_at)}
                        </p>
                        <p className="text-xs">-</p>
                        <p className="text-xs font-medium">
                          {note.person.name}
                        </p>
                      </div>
                      <p className="text-sm mt-1">{note.description}</p>
                    </div>
                    <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-400 mr-3 sm:ml-3 z-10 mt-4">
                      <IconDictionary
                        name="Editar"
                        className="cursor-pointer"
                        onClick={() => {
                          setCurrentNote(note);
                          setShowModalCreateUpdate(true);
                        }}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="button"
          className="w-64 font-bold"
          disabled={isLoading}
          onClick={() => nextPage()}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default Annotations;
