/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { BellOutline } from '@styled-icons/evaicons-outline/BellOutline';
import { ActivityProps } from '../../../activitie/types';
import maskHelper from '../../../../helpers/mask.helper';
import IconDictionary from '../../../../components/Icons/icons';
import { ApiActivitie } from '../../../activitie/api';
import { Spinner } from '../../../../components';
import { reloadNotification } from '../../../../hooks/globalAtom';
import ModalPopover from '../../../../components/Modal/Popover';

interface notificationProps {
  notificationList: ActivityProps[];
  notificationTotal: number;
  reloadNotifications: () => void;
  setLoading: (e: boolean) => void;
  close: () => void;
}

function NotificationContainer({
  notificationList,
  notificationTotal,
  reloadNotifications,
  setLoading,
  close,
}: notificationProps) {
  const ChangeStatus = async (activityId: number) => {
    setLoading(true);
    const res = await ApiActivitie.updateActivitieStatus(
      { status: 'Realizado' },
      activityId
    );
    if (typeof res !== 'string') {
      reloadNotifications();
    }
    setLoading(false);
  };
  return (
    <>
      <div className="px-4 pb-4 flex gap-0.5 items-center flex-wrap">
        <p className="text-sm font-semibold">Você possui</p>
        <p className="flex items-center justify-center text-white font-medium min-w-fit w-7 p-1.5 h-4 rounded-full bg-primary text-xs">
          {notificationTotal}
        </p>
        <p className="text-sm font-semibold">
          {notificationTotal === 1 ? 'tarefa' : 'tarefas'}
        </p>
      </div>

      {notificationList.map((activity) => (
        <div className="flex justify-between hover:bg-gray-300/20 cursor-pointer  border-t border-solid border-gray-300 py-2">
          <Link
            to={`/activities/${activity.id}`}
            className="w-10/12 flex flex-col"
            onClick={close}
          >
            <p className="ml-4 text-sm font-medium">
              {activity.activitie_type.title}
            </p>
            <p className="ml-4 text-sm truncate">{activity.description}</p>
            <div className="flex w-full justify-between">
              <p className="ml-4 text-xs">
                {maskHelper.formatDateDMY(activity.date)}
                {` • `}
                {activity.hour}
              </p>
            </div>
          </Link>
          <IconDictionary
            name="Circulo Confirmar"
            size={24}
            className="mr-2 text-gray-300 hover:text-green cursor-pointer z-50 place-self-end"
            onClick={() => ChangeStatus(activity.id)}
          />
        </div>
      ))}
    </>
  );
}

const Notification: React.FC = () => {
  const sizeCard = (sizeArray: number) => {
    switch (sizeArray) {
      case 0:
        return 'h-8';
      case 1:
        return 'h-28';
      case 2:
        return 'h-48';
      case 3:
        return 'h-64';
      default:
        return 'h-64';
    }
  };
  const [reloadNotificationAtom] = useAtom(reloadNotification);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationList, setNotificationList] = useState<ActivityProps[]>([]);
  const [total, setTotal] = useState<number>(0);

  const reloadList = async () => {
    setIsLoading(true);
    const res = await ApiActivitie.getActivityFilters('all', {
      activitieId: [],
      companieUserIdOwner: [],
      opportunityId: [],
      personId: [],
      skip: 0,
      status: 'Em andamento',
      take: 10,
      id: [],
    });
    if (typeof res !== 'string') {
      setNotificationList(res.data);
      setTotal(res.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadList();
  }, [reloadNotificationAtom]);

  return (
    <ModalPopover
      TriggerButton={
        <>
          <BellOutline color="#000" size={24} className="cursor-pointer mt-1" />
          {notificationList.length > 0 && (
            <div className="absolute top-0 right-0">
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red/80 opacity-75" />
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red" />
              </span>
            </div>
          )}
        </>
      }
      Content={(close) => (
        <div className="h-fit max-h-[280px] w-64 bg-white pt-4 flex flex-col rounded-md border border-solid border-gray-300 overflow-y-scroll">
          {isLoading ? (
            <div
              className={`bg-white ${sizeCard(
                total
              )} w-full flex items-center justify-center mb-2`}
            >
              <Spinner />
            </div>
          ) : (
            <NotificationContainer
              notificationTotal={total}
              close={() => (close ? close() : {})}
              setLoading={setIsLoading}
              reloadNotifications={reloadList}
              notificationList={notificationList}
            />
          )}
          <Link to="/activities" className="text-xs text-primary my-2 mx-auto">
            Ver mais
          </Link>
        </div>
      )}
    />
  );
};

export default Notification;
