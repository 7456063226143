/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Card, SelectComponent } from '../../../../../components';
import ShortParagraphAnswer from './shortParagraph';
import MultipleRadio from './multipleRadio';
import IconDictionary from '../../../../../components/Icons/icons';
import MultipleBox from './multiBox';
import QuestionList from './list';
import DateQuestion from './data';

type QuestionProps = {
  title: string;
  is_active: boolean;
  options: { title: string; is_active: boolean }[];
  type_response: string;
};
interface props {
  data: QuestionProps;
  index: number;
  addQuestion: (index: number, form?: QuestionProps) => void;
  updateData: (index: number, e: any) => void;
  deleteQuestion: () => void;
  setActiveCard: (e: number) => void;
  activeNumberCard: number;
}

const QuestionContainer: React.FC<props> = ({
  data,
  index,
  addQuestion,
  updateData,
  deleteQuestion,
  setActiveCard,
  activeNumberCard,
}) => {
  const isCardActive = index === activeNumberCard;
  const options = [
    {
      value: 'text',
      label: 'Resposta curta',
      icon: 'resposta_curta',
    },
    { value: 'textarea', label: 'Parágrafo', icon: 'paragrafo' },
    {
      value: 'radio',
      label: 'Múltipla escolha',
      icon: 'radio',
    },
    { value: 'checkbox', label: 'Caixas de seleção', icon: 'Checkbox' },
    {
      value: 'select',
      label: 'Lista suspensa',
      icon: 'CheckboxCircle',
    },
    { value: 'date', label: 'Data', icon: 'Calendario' },
  ];

  const [typeQuestion, setTypeQuestion] = useState<null | {
    label: string;
    icon: string;
    value: string;
  }>(options.find((item) => item.value === data.type_response) ?? null);

  const GetQuestion = (type: string, payload: QuestionProps) => {
    switch (type) {
      case 'text':
        return (
          <ShortParagraphAnswer
            type="short"
            onFocus={() => setActiveCard(index)}
            index={String(index)}
            isActive={isCardActive}
            text={payload.title}
            setText={(e) =>
              updateData(index, {
                active: true,
                title: e,
                options: [
                  { title: 'Texto de resposta curta', is_active: true },
                ],
                type_response: type,
              })
            }
          />
        );
      case 'textarea':
        return (
          <ShortParagraphAnswer
            type="textarea"
            onFocus={() => setActiveCard(index)}
            index={String(index)}
            isActive={isCardActive}
            text={payload.title}
            setText={(e) =>
              updateData(index, {
                active: true,
                title: e,
                options: [
                  { title: 'Texto de resposta longa', is_active: true },
                ],
                type_response: type,
              })
            }
          />
        );
      case 'date':
        return (
          <DateQuestion
            onFocus={() => setActiveCard(index)}
            index={String(index)}
            text={payload.title}
            setText={(e) =>
              updateData(index, {
                active: true,
                title: e,
                options: [{ title: 'data', is_active: true }],
                type_response: type,
              })
            }
          />
        );
      case 'radio':
        return (
          <MultipleRadio
            indexCard={index}
            options={payload.options}
            setOptions={(e) => {
              updateData(index, {
                active: true,
                title: payload.title,
                options: e,
                type_response: type,
              });
            }}
            text={payload.title}
            setText={(e, i?: number) => {
              if (i !== undefined) {
                const newArray = [...payload.options];
                newArray[i].title = e;
                updateData(index, {
                  active: true,
                  title: payload.title,
                  options: newArray,
                  type_response: type,
                });
              } else {
                updateData(index, {
                  active: true,
                  title: e,
                  options: payload.options,
                  type_response: type,
                });
              }
            }}
          />
        );
      case 'checkbox':
        return (
          <MultipleBox
            indexCard={index}
            options={payload.options}
            setOptions={(e) => {
              updateData(index, {
                is_active: true,
                title: payload.title,
                options: e,
                type_response: type,
              });
            }}
            text={payload.title}
            setText={(e, i?: number) => {
              if (i !== undefined) {
                const newArray = [...payload.options];
                newArray[i].title = e;
                updateData(index, {
                  is_active: true,
                  title: payload.title,
                  options: newArray,
                  type_response: type,
                });
              } else {
                updateData(index, {
                  is_active: true,
                  title: e,
                  options: payload.options,
                  type_response: type,
                });
              }
            }}
          />
        );
      case 'select':
        return (
          <QuestionList
            indexCard={index}
            options={payload.options}
            setOptions={(e) => {
              updateData(index, {
                is_active: true,
                title: payload.title,
                options: e,
                type_response: type,
              });
            }}
            text={payload.title}
            setText={(e, i?: number) => {
              if (i !== undefined) {
                const newArray = [...payload.options];
                newArray[i].title = e;
                updateData(index, {
                  is_active: true,
                  title: payload.title,
                  options: newArray,
                  type_response: type,
                });
              } else {
                updateData(index, {
                  is_active: true,
                  title: e,
                  options: payload.options,
                  type_response: type,
                });
              }
            }}
          />
        );
      default:
        return <> </>;
    }
  };

  const duplicate = () => {
    addQuestion(index, data);
  };

  return (
    <div className="relative w-full">
      {isCardActive && (
        <button
          type="button"
          onClick={() => addQuestion(index)}
          className="w-6 h-6 bg-primary rounded-full flex items-center justify-center text-white absolute -right-10 top-10"
        >
          <IconDictionary name="plus" />
        </button>
      )}

      <Card
        onClick={() => setActiveCard(index)}
        className={`flex-col mt-2 px-4 py-3 relative transition transition-all duration-150 ${
          isCardActive
            ? 'border-l-4 border-solid border-primary h-fit'
            : 'h-fit'
        }`}
      >
        <div className="flex flex-wrap-reverse sm:flex-nowrap flex sm:justify-between w-full">
          {typeQuestion && GetQuestion(typeQuestion.value, data)}
          {isCardActive && (
            <SelectComponent
              className="sm:w-48"
              classNameDiv="w-full sm:w-fit"
              maxOptionsHeight="300px"
              options={options}
              onChange={(e: any) => {
                setTypeQuestion(e);
                updateData(index, { ...data, type_response: e.value });
              }}
              value={typeQuestion}
            />
          )}
        </div>
        {isCardActive && (
          <>
            <hr className="bg-gray-400 w-full h-px my-2" />

            <div className="flex gap-2 justify-end">
              <IconDictionary
                name="Duplicar"
                className="cursor-pointer hover:scale-110 hover:text-black"
                onClick={duplicate}
              />
              <IconDictionary
                name="Lixeira"
                className="cursor-pointer hover:scale-110 hover:text-red"
                onClick={deleteQuestion}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default QuestionContainer;
