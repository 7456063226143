import React, { useContext, useState } from 'react';
import { isLoadingApplication } from '../../../../../components/Spinner/spinnerScreen';
import maskHelper from '../../../../../helpers/mask.helper';
import { DeleteModal, Input, SelectComponent } from '../../../../../components';
import { ApiOpportunity } from '../../../api';
import { serviceOpportunity } from '../../../types';
import IconDictionary from '../../../../../components/Icons/icons';
import { ToastNotify } from '../../../../../components/Toast/toast';

/* eslint-disable no-unused-vars */
interface props {
  productsOptions: { label: string; value: number; price: number }[];
  companieUserId: number;
  opportunityId: number;
  OpportunityProductList: serviceOpportunity[];
  updateProducts: (
    method: 'create' | 'update',
    products?: serviceOpportunity[]
  ) => void;
}
const OpportunityServices: React.FC<props> = ({
  productsOptions,
  companieUserId,
  opportunityId,
  OpportunityProductList,
  updateProducts,
}) => {
  const { notify } = ToastNotify();
  const [currentProduct, setCurrentProduct] =
    useState<serviceOpportunity | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const { setState } = useContext(isLoadingApplication);

  const CreateProduct = async (e: any) => {
    const response = await ApiOpportunity.createOpportunityProduct({
      companie_user_id: companieUserId,
      opportunity_id: opportunityId,
      product_id: e.value,
      qty: 1,
      total: Number(e.price),
      value: Number(e.price),
    });
    if (response.id) {
      updateProducts('create');
    }
  };

  return (
    <div className="p-4 flex flex-col w-full overflow-x-auto min-h-[280px] sm:overflow-x-visible">
      <SelectComponent
        isClearable
        options={productsOptions.filter(
          (item) =>
            OpportunityProductList.find(
              (prod) => prod.product_id === item.value
            ) === undefined
        )}
        closeMenuOnSelect
        onChange={(e: any) => e !== null && CreateProduct(e)}
      />
      {OpportunityProductList.length > 0 ? (
        <div className="grid grid-cols-4 gap-3 mt-3 min-w-[550px]">
          <p className="text-sm font-medium">Nome</p>
          <p className="text-sm font-medium">Preço</p>
          <p className="text-sm font-medium">Quantidade</p>
          <p className="text-sm font-medium">Total</p>
          <p />
        </div>
      ) : (
        <p className="text-xs mx-auto mt-2">Nenhum produto adicionado</p>
      )}

      {OpportunityProductList.map((product, index) => (
        <>
          <div className="grid grid-cols-4 gap-3 items-center min-w-[550px]">
            <p className="text-xs">{product.product.title}</p>

            <Input
              variant="outline-orange"
              value={maskHelper.formatMoeda(product.value)}
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
                OpportunityProductList[index] = {
                  ...product,
                  value: maskHelper.numberCurrencyDecimal(e.target.value),
                  total: String(
                    Number(product.qty) *
                      Number(maskHelper.numberCurrencyDecimal(e.target.value))
                  ),
                };
                updateProducts('update', OpportunityProductList);
              }}
              onBlur={async (e) => {
                setState(true);
                const res = await ApiOpportunity.updateOpportunityProduct(
                  {
                    qty: product.qty,
                    total: String(
                      product.qty *
                        Number(maskHelper.numberCurrencyDecimal(e.target.value))
                    ),
                    value: maskHelper.numberCurrencyDecimal(e.target.value),
                  },
                  product.id
                );
                if (res.id) {
                  OpportunityProductList.splice(index, 1, {
                    ...product,
                    product: {
                      ...product.product,
                      value: res.value,
                    },
                    qty: res.qty,
                    total: res.total,
                    value: res.value,
                  });
                  updateProducts('update', OpportunityProductList);
                }
                setState(false);
              }}
            />
            <Input
              type="number"
              min="1"
              variant="outline-orange"
              value={product.qty}
              onChange={(e) => {
                OpportunityProductList[index] = {
                  ...product,
                  qty: Number(e.target.value),
                };
                updateProducts('update', OpportunityProductList);
              }}
              onBlur={async (e) => {
                setState(true);
                const res = await ApiOpportunity.updateOpportunityProduct(
                  {
                    qty: Number(e.target.value),
                    total: String(
                      Number(product.value) * Number(e.target.value)
                    ),
                    value: product.value,
                  },
                  product.id
                );
                if (res.id) {
                  OpportunityProductList.splice(index, 1, {
                    ...product,
                    qty: res.qty,
                    total: res.total,
                    value: res.value,
                  });
                  updateProducts('update', OpportunityProductList);
                }
                setState(false);
              }}
            />
            <div className="flex gap-3 w-full items-center">
              {' '}
              <Input
                disabled
                variant="outline-orange"
                value={maskHelper.formatMoeda(product.total)}
              />
              <IconDictionary
                size={24}
                onClick={() => {
                  setCurrentProduct(product);
                  setShowDelete(true);
                }}
                name="Excluir"
                className="cursor-pointer text-red mx-auto"
              />
            </div>
          </div>
          {index !== OpportunityProductList.length - 1 && (
            <hr className="w-full h-[0.5px] bg-gray-300" />
          )}
        </>
      ))}
      {currentProduct && (
        <DeleteModal
          apiFunction={ApiOpportunity.deleteOpportunityProduct}
          close={() => setShowDelete(false)}
          id={currentProduct.id}
          name=""
          open={showDelete}
          updateTable={() => updateProducts('create')}
          type="Produto"
          notify={notify}
        />
      )}
    </div>
  );
};
export default OpportunityServices;
