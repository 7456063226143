import { useEffect, useState } from 'react';
import { ApiCompany } from '../api';
import { attachment, company } from '../types';

export function useFetchCompanies(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<company[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiCompany.getCompanies();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { companiesList: data, isLoading };
}
export function useFetchAccountsGalax(key: string) {
  const [isLoading, setSetIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setSetIsLoading(true);
      const companies = await ApiCompany.getGalaxAccounts();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setSetIsLoading(false);
    };
    FetchCompany();
  }, [key]);
  return { galaxAccountList: data, isLoadingAsaas: isLoading };
}
export function useFetchCompanyAttachments(key: string, id: number) {
  const [isLoading, setSetIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchCompanyAttachments = async () => {
      setSetIsLoading(true);
      const companies =
        id === -1 ? '' : await ApiCompany.getCompanyAttachments(id);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setSetIsLoading(false);
    };
    FetchCompanyAttachments();
  }, [key]);
  return { attachmentsList: data, isLoading };
}
export function useFetchMatriz(key: string) {
  const [data, setData] = useState<company[]>([]);
  useEffect(() => {
    const FetchCompanyAttachments = async () => {
      const companies = await ApiCompany.getAllMatrix();
      if (typeof companies !== 'string') {
        setData(companies);
      }
    };
    FetchCompanyAttachments();
  }, [key]);
  return { MatrizList: data };
}

export function useFetchPartners() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const companies = await ApiCompany.getPartnersActive();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    })();
  }, []);
  return { PartnersList: data, isLoading };
}
