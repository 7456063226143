import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Input,
  InputDate,
  Spinner,
} from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import { ToastNotify } from '../../../../../components/Toast/toast';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import { ApiContact } from '../../../../contacts/api';
import { generalDataContact } from '../../../../contacts/types';
import { ContactOpportunity } from '../../../types';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';

/* eslint-disable no-unused-vars */
interface props {
  contact: ContactOpportunity;
  updateOpportunity: React.Dispatch<React.SetStateAction<string>>;
}

const ContactCard: React.FC<props> = ({ contact, updateOpportunity }) => {
  const defaultValues = {
    email: contact.person.email,
    name: contact.person.name,
    whatsapp: contact.person.whatsapp,
    phone: contact.person.phone,
    organization: contact.person.organization,
    birth: contact.person.birth
      ? new Date(`${contact.person.birth.slice(0, 10)} `)
      : undefined,
  };

  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const [isLoading, setIsLoading] = useState(false);

  const { notify } = ToastNotify();

  const [editContact, setEditContact] = useState({
    edit: false,
    hide: false,
    contact,
  });

  const schemaOnePage = yup.object().shape(
    {
      name: yup.string().required('Nome é obrigatório'),
      birth: yup
        .date()
        .typeError('Data inválida')
        .nullable(true)
        .max(yesterdayDate, 'Data deve ser menor que hoje'),
      document: yup
        .string()
        .nullable()
        .test({
          message: 'CPF inválido',
          test: (value) => (value ? cpfValidation.isValid(value) : true),
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !email || email.length === 0,
        then: yup
          .string()
          .required('É obrigatório preencher E-mail ou WhatsApp')
          .test({
            message: 'Número inválido',
            test: (value) =>
              value !== undefined
                ? phoneValidate.isValid(maskHelper.number(value))
                : true,
          }),
        otherwise: yup.string().test({
          message: 'Número inválido',
          test: (value) =>
            value !== undefined && value !== ''
              ? phoneValidate.isValid(maskHelper.number(value))
              : true,
        }),
      }),
      email: yup
        .string()
        .email('E-mail inválido')
        .when('whatsapp', {
          is: (whatsapp: any) => !whatsapp || whatsapp.length === 0,
          then: yup.string().required(''),
          otherwise: yup.string(),
        }),
    },
    [['email', 'whatsapp']]
  );

  const { handleSubmit, setValue, formState } = useForm<generalDataContact>({
    defaultValues: clearNullValuesObject(defaultValues),
    resolver: yupResolver(schemaOnePage),
  });

  const Submit = async (e: generalDataContact) => {
    setIsLoading(true);
    const response = await ApiContact.updateContact(
      {
        ...e,
      },
      contact.person_id
    );
    updateOpportunity(`${Math.random()}`);
    if (response.id) {
      notify({
        message: 'Contato atualizado com sucesso!',
        type: 'Success',
      });

      setEditContact({ ...editContact, edit: false });
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setEditContact({ ...editContact, contact });
  }, [contact]);

  return (
    <div>
      <Card className="flex flex-col mt-3">
        <div className="flex justify-between w-full bg-primary p-4 rounded-t">
          <p className="text-white text-xs uppercase font-semibold">Contato</p>
          <div className="flex gap-2">
            <IconDictionary
              size={16}
              className="text-white cursor-pointer text-black hover:scale-110"
              name="Editar"
              onClick={() =>
                setEditContact({
                  ...editContact,
                  edit: true,
                })
              }
            />
            {editContact.hide ? (
              <IconDictionary
                size={16}
                className="text-white cursor-pointer hover:scale-110"
                name="Adicionar"
                onClick={() =>
                  setEditContact({
                    ...editContact,
                    hide: !editContact.hide,
                  })
                }
              />
            ) : (
              <IconDictionary
                size={16}
                className="text-white cursor-pointer hover:scale-110"
                name="Hide"
                onClick={() =>
                  setEditContact({
                    ...editContact,
                    hide: !editContact.hide,
                  })
                }
              />
            )}
          </div>
        </div>
        {!editContact.hide && (
          <form
            onSubmit={handleSubmit(Submit)}
            className="flex flex-col sm:grid grid-cols-2 p-4 gap-3"
          >
            <Input
              disabled={!editContact.edit}
              variant="outline-orange"
              title="Nome"
              value={editContact.contact.person.name}
              onChange={(e) => {
                setEditContact({
                  ...editContact,
                  contact: {
                    ...editContact.contact,
                    person: {
                      ...editContact.contact.person,
                      name: e.target.value,
                    },
                  },
                });
                setValue('name', e.target.value);
              }}
              errorMensage={formState.errors.name?.message}
            />
            <InputDate
              disabled={!editContact.edit}
              className="col-span-2 lg:col-span-1"
              title="Data de Nascimento"
              defaultValue={defaultValues.birth}
              onChange={(e: Date) => {
                if (e !== null)
                  setValue(
                    'birth',
                    maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
                  );
              }}
              errorMensage={formState.errors.birth?.message}
            />
            <Input
              disabled={!editContact.edit}
              variant="outline-orange"
              title="Organização"
              value={editContact.contact.person.organization}
              onChange={(e) => {
                setEditContact({
                  ...editContact,
                  contact: {
                    ...editContact.contact,
                    person: {
                      ...editContact.contact.person,
                      organization: e.target.value,
                    },
                  },
                });
                setValue('organization', e.target.value);
              }}
              errorMensage={formState.errors.organization?.message}
            />
            <Input
              disabled={!editContact.edit}
              title="Telefone"
              variant="outline-orange"
              placeholder="(00) 00000-0000"
              value={maskHelper.phone(editContact.contact.person.phone ?? '')}
              onChange={(e) => {
                setEditContact({
                  ...editContact,
                  contact: {
                    ...editContact.contact,
                    person: {
                      ...editContact.contact.person,
                      phone: maskHelper.numberPhone(e.target.value),
                    },
                  },
                });
                setValue('phone', maskHelper.numberPhone(e.target.value));
              }}
              errorMensage={formState.errors.phone?.message}
            />
            <Input
              disabled={!editContact.edit}
              title="WhatsApp"
              variant="outline-orange"
              placeholder="(00) 00000-0000"
              value={maskHelper.phone(
                editContact.contact.person.whatsapp ?? ''
              )}
              onChange={(e) => {
                setEditContact({
                  ...editContact,
                  contact: {
                    ...editContact.contact,
                    person: {
                      ...editContact.contact.person,
                      whatsapp: maskHelper.numberPhone(e.target.value),
                    },
                  },
                });
                setValue('whatsapp', maskHelper.numberPhone(e.target.value));
              }}
              errorMensage={formState.errors.whatsapp?.message}
            />
            <Input
              disabled={!editContact.edit}
              title="E-mail"
              variant="outline-orange"
              value={editContact.contact.person.email}
              onChange={(e) => {
                setEditContact({
                  ...editContact,
                  contact: {
                    ...editContact.contact,
                    person: {
                      ...editContact.contact.person,
                      email: e.target.value,
                    },
                  },
                });
                setValue('email', e.target.value);
              }}
              errorMensage={formState.errors.email?.message}
            />

            {editContact.edit && (
              <div className="col-span-2 flex gap-3 justify-center">
                <Button
                  className="w-36 my-3"
                  variant="outline-primary"
                  onClick={() =>
                    setEditContact({ ...editContact, edit: false })
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="w-36 relative flex items-center justify-center my-3"
                  variant="primary-strong"
                  disabled={isLoading}
                >
                  Salvar{' '}
                  {isLoading && (
                    <div className="absolute right-0">
                      <Spinner />
                    </div>
                  )}
                </Button>
              </div>
            )}
          </form>
        )}
      </Card>
    </div>
  );
};

export default ContactCard;
