import React, { useState } from 'react';
import Button from '../Button/button';
import Modal from '../Modal/Modal';
import Spinner from '../Spinner/spinner';
import { dictionaryError } from '../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface DeleteProps {
  id: number;
  close: () => void;
  open: boolean;
  type: string;
  name: string;
  article?: 'O' | 'A' | 'Os' | 'As';
  apiFunction: (id: number) => Promise<boolean>;
  updateTable: () => void;
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
}

const DeleteModal: React.FC<DeleteProps> = ({
  close,
  id,
  open,
  updateTable,
  notify,
  apiFunction,
  article = 'O',
  name,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = async () => {
    setIsLoading(true);
    const deleted = await apiFunction(id);
    if (deleted === true) {
      setIsLoading(false);
      notify({
        message: `${article} ${type.toLowerCase()} foi removid${article.toLocaleLowerCase()} com sucesso`,
        type: 'Success',
      });
      close();
      updateTable();
    } else if (typeof deleted === 'string') {
      setIsLoading(false);
      notify({
        message: dictionaryError(deleted),
        type: 'Error',
      });
      close();
    } else {
      setIsLoading(false);
      notify({
        message: `Não foi possível remover ${article.toLocaleLowerCase()} ${type.toLowerCase()}`,
        type: 'Error',
      });
      close();
    }
  };
  return (
    <Modal
      setIsOpen={close}
      title={`Remover ${type}`}
      isOpen={open}
      size="2xlarge"
      minHeight="min-h-[300px]"
    >
      <strong className="text-center text-lg">
        Tem certeza que deseja remover {name} ?
      </strong>
      <p className="text-sm mt-4 mb-10">
        Esta ação não pode ser desfeita. {article} {type.toLowerCase()} será
        removid{article.toLocaleLowerCase()}
        {` `}
        dos nossos serviços.
      </p>
      <div className="flex gap-3 w-full">
        <Button variant="primary-strong" onClick={close} className="w-1/2">
          Cancelar
        </Button>
        <Button
          className="w-1/2 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
          variant="outline-primary"
          disabled={isLoading}
          onClick={handleDelete}
        >
          Remover{' '}
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
