import { useEffect, useState } from 'react';
import { ApiFunnel } from '../api';
import { FunnelList, Step } from '../types';

export function useFetchFunnel(key: string, company: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FunnelList[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const funnel = await ApiFunnel.getFunnelByCompanie(company);
      if (typeof funnel !== 'string') {
        setData(funnel);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);

  return { FunnelsList: data, isLoading };
}

export function useFetchFunnelByProfile(key: string, userId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FunnelList[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const funnel = await ApiFunnel.getFunnelByProfileId(userId);
      if (typeof funnel !== 'string') {
        setData(funnel);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);

  return { FunnelsList: data, isLoading };
}

export function useFetchStep(key: string, funnelId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Step[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const steps =
        funnelId !== -1 ? await ApiFunnel.getStepByFunnel(funnelId) : [];
      if (typeof steps !== 'string') {
        setData(steps);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [funnelId, key]);

  return { StepList: data, isLoading };
}
