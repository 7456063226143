import React from 'react';
import { InputDate } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  text: string;
  // eslint-disable-next-line no-unused-vars
  setText: (e: string) => void;
}

const DateQuestion: React.FC<props> = ({ setText, text }) => (
  <div className="flex flex-col gap-2 text-sm w-2/3 my-3">
    <div className="w-64">
      <InputDate
        onChange={(e: Date | null) => {
          if (e !== null) {
            setText(maskHelper.formatDateYMD(e.toLocaleDateString('pt-br')));
          }
        }}
        title={text}
      />
    </div>
  </div>
);

export default DateQuestion;
