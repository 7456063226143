import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../main/components/mainPage';
import { ToastNotify } from '../../../components/Toast/toast';
import { DeleteModal } from '../../../components';
import { ResponseWinLose } from '../types';
import { ApiResponseWinLose } from '../api';
import { useFetchResponses } from '../hooks';
import RegisterResponseWinLose from '../components/register';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

const ResponseWin: React.FC = () => {
  const userProps = getTokenAccountInformation();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterResponse, setModalRegisterResponse] = useState(false);
  const [input, setInput] = useState('');
  const [responses, setResponseData] = useState<ResponseWinLose | null>(null);
  const [key, setKey] = useState('key');

  const { ResponsesList, isLoading } = useFetchResponses(
    key,
    userProps.companie_id
  );

  const positiveResponses = ResponsesList.filter((item) => item.is_win);

  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: ResponseWinLose) => row.title,
      className:
        'min-w-[150px] max-w-[700px] md:max-w-[500px] lg:max-w-[300px]',
      center: true,
    },
    {
      name: 'Status',
      key: 'is_active',
      selector: (row: ResponseWinLose) => (row.is_active ? 'Ativo' : 'Inativo'),
      className:
        'min-w-[100px] max-w-[700px] md:max-w-[500px] lg:max-w-[300px]',
      center: true,
    },
    {
      name: '',
      cell: (row: ResponseWinLose) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setResponseData(row);
            setShowModalDelete(true);
          }}
        />
      ),
      className: 'min-w-[50px] max-w-[100px]',
      center: true,
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return positiveResponses;
    }
    return positiveResponses.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Respostas de ganho"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterResponse}
        setRegisterPage={() => {
          setResponseData(null);
        }}
        onRowClick={(e: ResponseWinLose) => {
          setResponseData(e);
          setModalRegisterResponse(true);
        }}
      />
      <RegisterResponseWinLose
        positiveNegative
        companieUserId={userProps.id}
        responses={responses}
        reloadTable={setKey}
        setShow={setModalRegisterResponse}
        show={modalRegisterResponse}
      />
      {responses && (
        <DeleteModal
          apiFunction={ApiResponseWinLose.deleteResponses}
          notify={notify}
          type="Resposta de ganho"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={responses.id}
          name={responses.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default ResponseWin;
