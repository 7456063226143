import { baseApiPrivate } from '../../../api/baseApi';
import { ResponseWinLose } from '../types';

async function getResponsesByCompany(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/responsesWinLose/${companyId}`,
  });
}

async function createResponses(e: Omit<ResponseWinLose, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/responsesWinLose',
    body: e,
  });
}

async function updateResponses(e: Omit<ResponseWinLose, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/responsesWinLose/${id}`,
    body: e,
  });
}

async function deleteResponses(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/responsesWinLose/${id}`,
  });
}

export const ApiResponseWinLose = {
  getResponsesByCompany,
  createResponses,
  updateResponses,
  deleteResponses,
};
