import React, { useState } from 'react';
import { useFetchWhatsappTag } from '../hooks';
import { SelectComponent } from '../../../components';
import RegisterWhatsappTag from './register';

interface props {
  // eslint-disable-next-line no-unused-vars
  onChange: (e: any) => void;
  isMulti?: boolean;
  itemsSelectedId: number[];
  className?: string;
  title?: string;
  value?: any;
  createTags?: boolean;
  maxOptionsHeight?: string;
}
const SelectWhatsappTag: React.FC<props> = ({
  onChange,
  itemsSelectedId,
  isMulti = false,
  className = '',
  title = 'Tags',
  value = null,
  createTags = false,
  maxOptionsHeight,
}) => {
  const [key, setKey] = useState('');
  const [modalCreate, setModalCreate] = useState(false);
  const { WhatsappTagList } = useFetchWhatsappTag(key);
  const optionsTags = WhatsappTagList.filter(
    (elem) => !itemsSelectedId.includes(elem.id)
  ).map((item) => ({
    value: item.id,
    label: item.title,
    tag_color: item.color,
  }));
  return (
    <>
      <RegisterWhatsappTag
        WhatsappTag={null}
        reloadTable={setKey}
        setShow={setModalCreate}
        show={modalCreate}
        setNewTag={(tag) =>
          onChange({ label: tag.title, value: tag.id, tag_color: tag.color })
        }
      />
      <div className="relative">
        {createTags && (
          <button
            onClick={() => setModalCreate(true)}
            type="button"
            className="absolute top-0 mt-1 right-0 text-xs text-primary cursor-pointer z-10"
          >
            Adicionar Novo
          </button>
        )}
        <SelectComponent
          isMulti={isMulti}
          title={title}
          value={value}
          options={optionsTags}
          onChange={onChange}
          classNameDiv={className}
          maxOptionsHeight={maxOptionsHeight}
        />
      </div>
    </>
  );
};

export default SelectWhatsappTag;
