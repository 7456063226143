import { Schedule } from 'styled-icons/material';
import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Button, Modal } from '../../../components';
import { ApiActivitie } from '../api';
import { activityStatus } from '../types';
import { reloadNotification } from '../../../hooks/globalAtom';

type ActivitieAlertProps = {
  id: number;
  title: string;
  description: string;
  time?: string;
  setNotifications: any;
  personName: string;
  setLostConnection: React.Dispatch<React.SetStateAction<boolean>>;
  opportunityTitle: string;
};

export function ActivitieAlert({
  title,
  description,
  time,
  id,
  opportunityTitle,
  setNotifications,
  personName,
  setLostConnection,
}: ActivitieAlertProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadNotificationAtom, setReloadNotificationAtom] =
    useAtom(reloadNotification);

  const handleUptadeActivitieStatus = async (notificationId: number) => {
    setIsLoading(true);
    const done: activityStatus = {
      status: 'Realizado',
    };
    const res = await ApiActivitie.updateActivitieStatus(done, notificationId);
    if (typeof res !== 'string') {
      setNotifications((prev: any) =>
        prev.filter((notification: any) => notification.id !== notificationId)
      );
      setIsOpen(false);
      setLostConnection(false);
      setReloadNotificationAtom(!reloadNotificationAtom);
    }
    if (res === 'Sem conexão') {
      setLostConnection(true);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      title=""
      setIsOpen={() =>
        setIsOpen(() => {
          setNotifications((prev: any) =>
            prev.filter((notification: any) => notification.id !== id)
          );
          return false;
        })
      }
      isOpen={isOpen}
      minHeight="min-h-[300px]"
    >
      <div className="flex flex-col w-full h-full items-center justify-center">
        <div className="flex gap-1 mb-5">
          <strong>{personName}</strong>
        </div>
        <i className="bg-primary rounded-full mb-2  w-16 h-16 flex items-center justify-center">
          <Schedule width={40} height={40} color="#fff" />
        </i>
        <h1 className="p-2 font-bold text-xl">{title}</h1>
        <h2 className="text-base font-medium">{opportunityTitle}</h2>
        <span className="text-sm font-semibold">Hoje as {time}</span>
        <p className="text-center p-2 text-base">{description}</p>
        <Button
          disabled={isLoading}
          actionType="button-loading"
          isLoading={isLoading}
          className="w-72 mt-6"
          type="button"
          onClick={() => handleUptadeActivitieStatus(id)}
        >
          Realizado
        </Button>
      </div>
    </Modal>
  );
}
