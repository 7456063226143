import { useEffect, useState } from 'react';
import { ApiProfile } from '../api';
import { Profile } from '../types';

export function useFetchProfile(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Profile[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiProfile.getAllProfile();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ProfileList: data, isLoadingProfile: isLoading };
}
