import React, { useEffect, useMemo, useState } from 'react';
import Modal, { ModalProps } from '../../../../components/Modal/Modal';
import { Plan, buyPlan, getAvailablePlans } from '../api';
import { Button, Select, Spinner } from '../../../../components';
import maskHelper from '../../../../helpers/mask.helper';
import { ToastNotify } from '../../../../components/Toast/toast';

interface BuyModalProps extends Omit<ModalProps, 'title' | 'children'> {
  // eslint-disable-next-line no-unused-vars
  onBuyPlanSuccess: (plan: Plan) => void;
}

const BuyModal = (props: BuyModalProps) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const { notify } = ToastNotify();
  useEffect(() => {
    if (!props.isOpen) return;
    setLoadingPlans(true);
    getAvailablePlans()
      .then(setPlans)
      .finally(() => setLoadingPlans(false));
  }, [props.isOpen]);
  const onBuyPlanClick = async () => {
    if (!selectedPlan) return;

    try {
      setLoading(true);
      const result = await buyPlan(selectedPlan);

      window.open(result.subscription.Subscription.paymentLink, '_blank');
      props?.onBuyPlanSuccess(selectedPlan);
    } catch (err) {
      notify({
        message: 'Não foi possível comprar o plano.',
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };
  const plansOptions = useMemo(
    () =>
      plans.map((p) => ({
        label: `${p.name} - ${p.qty} créditos`,
        value: p.id,
      })),
    [plans]
  );

  return (
    <Modal title="Comprar créditos" {...props}>
      {loadingPlans ? (
        <div className="mx-auto w-min">
          <Spinner />
        </div>
      ) : (
        <Select
          options={plansOptions}
          value={
            selectedPlan &&
            plansOptions.find((plan) => plan.value === selectedPlan.id)
          }
          onChange={(planOption: any) => {
            setSelectedPlan(
              plans.find((plan) => plan.id === planOption.value) || null
            );
          }}
        />
      )}
      <div className="">
        <div className="flex items-center justify-between">
          <hr className="h-[1px] w-[50px] bg-black mr-2" />
          <p>
            Valor total pelos <strong>{selectedPlan?.qty ?? 0} Créditos</strong>
          </p>
          <hr className="h-[1px] w-[50px] bg-black mr-2" />
        </div>
        <div className="text-center">
          <p className="text-2xl">
            <strong>{maskHelper.formatMoeda(selectedPlan?.value ?? 0)}</strong>
          </p>
        </div>
        <Button
          onClick={onBuyPlanClick}
          disabled={loading}
          className="w-full flex items-center justify-center gap-2"
        >
          Confirmar compra {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
};

export default BuyModal;
