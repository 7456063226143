import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import maskHelper from '../../../../helpers/mask.helper';
import { Card, Input, SelectComponent } from '../../../../components';
import SearchFinancial from '../../../financial/components/filterFinancials';
import IconDictionary from '../../../../components/Icons/icons';
import { FinancialProps, filterFormProps } from '../../../financial/types';
import { useFetchPlansAccount } from '../../../chartAccounts/hooks';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { useFetchFinancials } from '../../../financial/hooks';
import MainPagePagination from '../../../main/components/mainPage/mainPageWithPagination';
import { ApiExpenses } from '../../../financial/api';
import { useFetchProvider } from '../../../providers/hooks';

const FinancialReports: React.FC = () => {
  const accountProps = getTokenAccountInformation();
  const dateFilterOptions = [
    { value: 'all', label: 'Todas' },
    { value: 'today', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'this_week', label: 'Esta semana' },
    { value: 'last_week', label: 'Semana passada' },
    { value: 'this_month', label: 'Este mês' },
    { value: 'last_30_days', label: 'Últimos 30 dias' },
    { value: 'other_period', label: 'Outro intervalo' },
  ];

  const [modalFilter, setModalFilter] = useState(false);
  const [input, setInput] = useState('');

  const [filterDate, setFilterDate] = useState('this_month');
  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });

  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { PlanList } = useFetchPlansAccount('');
  const { ProviderList } = useFetchProvider('', accountProps.companie_id);
  const providersOptions = ProviderList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const received = [
    { value: null, label: 'Todos' },
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ];

  const statusColor = {
    Aberta: 'text-black',
    Cancelada: 'text-gray-400',
    Vencida: 'text-red',
    Paga: 'text-green',
  };

  const [filterModal, setFilterModal] = useState<filterFormProps>({
    client_id: [],
    contract_id: [],
    name: '',
    type: 'Todos',
    form_of_payment: [],
    status: [],
    received: received[0].value,
    order: 'due_date_asc',
    pla_account_id: [],
    provider_id: [],
    skip: 0,
    take: 10,
    status_contract: [],
  });

  const { FinancialsList, isLoadingList } = useFetchFinancials(
    '',
    { ...filterModal, name: input },
    filterDate
  );

  const columns = [
    {
      name: 'Cliente ou Fornecedor',
      key: 'client_name',
      minWidth: '180px',
      selector: (row: FinancialProps) =>
        row.type === 'R'
          ? row.client
            ? row.client.name
            : row.contract.client_name
          : row.provider.name ?? '',
    },
    {
      name: 'Tipo',
      key: 'type',
      selector: (row: FinancialProps) =>
        row.type === 'R' ? (
          <div className="w-16 bg-green text-white font-semibold flex items-center justify-center py-1 rounded-sm">
            Receita
          </div>
        ) : (
          <div className="w-16 bg-red text-white font-semibold flex items-center justify-center py-1 rounded-sm">
            Despesa
          </div>
        ),
    },
    {
      name: 'Contas',
      key: 'plan_account.title',
      minWidth: '170px',
      selector: (row: FinancialProps) => row.plan_accounts.title,
    },
    {
      name: 'Vencimento',
      selector: (row: FinancialProps) =>
        row.due_date !== undefined
          ? maskHelper.formatDateDMY(row.due_date.slice(0, 10))
          : '',
    },
    {
      name: 'Forma',
      minWidth: '130px',
      key: 'payment_form',
      selector: (row: FinancialProps) =>
        row.form_of_payment === 'creditcard' ? 'Cartão de crédito' : 'Boleto',
    },
    {
      name: 'Método',
      key: 'method',
      selector: (row: FinancialProps) =>
        row.method === 'installments' ? 'Parcelado' : 'À vista',
      hide: 1366,
    },
    {
      name: 'Parcela',
      key: 'installments',
      selector: (row: FinancialProps) => row.installment,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: FinancialProps) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: FinancialProps) => row.status,
      cell: (row: FinancialProps) => (
        <p className={statusColor[row.status]}>{row.status}</p>
      ),
    },
    {
      name: 'Contrato',
      key: 'received_date',
      selector: (row: FinancialProps) =>
        row.contract ? row.contract?.client_name : '',
    },
  ];

  const formatPaymentMethod = {
    creditcard: 'Cartão de crédito',
    boleto: 'Boleto',
    pix: 'Pix',
    ted: 'TED',
    dinheiro: 'Dinheiro',
  };

  const restartPagination = () => {
    setStartData(0);
    setCurrentPage(1);
  };

  const getNameClientProvider = (fin: FinancialProps) => {
    if (fin.client) {
      return fin.client.name;
    }
    if (fin.provider) {
      return fin.provider.name;
    }

    return fin.contract.client_name;
  };

  const generateCsv = async () => {
    const payload = {
      ...filterModal,
      contract_id: filterModal.contract_id.map((item) => item.value),
      client_id: filterModal.client_id.map((item) => item.value),
      take: 100000000,
    };

    if (payload.name === '' || (payload.name && payload.name.length < 3)) {
      delete payload.name;
    }

    const res = await ApiExpenses.getFinancials(payload, filterDate);
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Cliente ou Fornecedor',
          'Tipo',
          'Contas de Lançamento',
          'Vencimento',
          'Forma de pagamento',
          'Método',
          'Parcela',
          'Valor',
          'Status',
          'Data de recebimento',
          'Valor recebido',
          'Descrição Status',
          'Observação',
          'Contrato',
        ],
        ...res.data.map((item: FinancialProps) => [
          getNameClientProvider(item),
          item.type === 'R' ? 'Receita' : 'Despesa',
          item.plan_accounts.title,
          maskHelper.formatDateDMY(item.due_date.slice(0, 10)),
          formatPaymentMethod[item.form_of_payment],
          item.method === 'installments' ? 'Parcelado' : 'À vista',
          item.installment,
          Number(item.value),
          item.status,
          maskHelper.formatDateDMY(item.received_date?.slice(0, 10) ?? ''),
          Number(item.received_value ?? ''),
          item.status_description,
          item.obs,
          item.contract ? item.contract?.client_name : '',
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  useEffect(() => {
    setFilterModal({ ...filterModal, skip: startData });
  }, [startData]);

  const searchFinancials = async (value: string) => {
    setCurrentPage(1);
    setFilterModal({ ...filterModal, skip: 0, take: 10, name: value });
    setInput(value);
  };

  return (
    <>
      <SearchFinancial
        restartPagination={() => {}}
        filters={filterModal}
        setFilter={setFilterModal}
        providerOptions={providersOptions}
        setShow={setModalFilter}
        show={modalFilter}
        planAccounts={PlanList}
      />
      <MainPagePagination
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={FinancialsList.total}
        isLoading={isLoadingList}
        title="Contas a pagar e receber"
        column={columns}
        filterTable={() => FinancialsList.data}
        setRegisterPage={() => {}}
        objectReport={generateCsv}
        filename="contas"
        FilterComponent={
          <Card className="flex-col col-span-2 w-full pt-5 px-4 pb-3.5 gap-4">
            <strong>Pesquisar</strong>
            <div className="flex flex-col xl:flex-row gap-2">
              <div className="flex w-full flex-col sm:flex-row gap-2">
                <Input
                  value={input}
                  onChange={(e) => searchFinancials(e.target.value)}
                  icon={
                    <IconDictionary
                      name="Lupa"
                      size={18}
                      color="#8C8C8C"
                      className="mr-4"
                    />
                  }
                  placeholder="Pesquisar por nome do cliente, contas e status"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 w-full mt-2 md:mt-0 lg:w-fit">
                <SelectComponent
                  variant="outline-primary"
                  icon="Calendario"
                  value={dateFilterOptions.find(
                    (item) => item.value === filterDate
                  )}
                  className="w-full sm:w-48 lg:w-48 2xl:w-60"
                  options={dateFilterOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    if (e.value !== 'other_period') {
                      setDatePicker({ open: false });
                      setFilterDate(e.value);
                      restartPagination();
                    } else {
                      setDatePicker({ open: true });
                    }
                  }}
                  maxOptionsHeight="300px"
                />
                {datePicker.open && (
                  <div className="flex mb-1.5">
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="start_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, start: e });
                        if (typeof datePicker.end !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}/${maskHelper.formatDateYMD(
                              datePicker.end?.toLocaleDateString('pt-br') ?? ''
                            )}`
                          );
                          restartPagination();
                        }
                      }}
                      value={datePicker.start}
                    />
                    <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                      Até
                    </p>
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="end_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, end: e });
                        if (typeof datePicker.start !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              datePicker.start?.toLocaleDateString('pt-br') ??
                                ''
                            )}/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}`
                          );
                          restartPagination();
                        }
                      }}
                      value={datePicker.end}
                      minDate={datePicker.start}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="xl:hidden w-12 h-11 rounded-lg md:place-self-start items-center justify-center cursor-pointer text-primary border-primary border-solid border"
                  onClick={() => setModalFilter(true)}
                >
                  <IconDictionary name="Filtro" size={16} />
                </button>
              </div>
              <button
                type="button"
                className="hidden xl:flex min-w-[44px] h-11 rounded-lg justify-center items-center cursor-pointer text-primary border-primary border-solid border"
                onClick={() => setModalFilter(true)}
              >
                <IconDictionary name="Filtro" size={16} />
              </button>
            </div>
          </Card>
        }
      />
    </>
  );
};

export default FinancialReports;
