import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spinner, Switch } from '../../../../components';
import PartnerApi from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { Partner } from '../types';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  partner: Partner | null;
}

const RegisterGroup: React.FC<registerModalProps> = ({
  partner,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<
    Omit<Partner, 'id' | 'companie' | 'administrator_user'>
  >({
    name: partner?.name ?? '',
    is_active: partner?.is_active ?? false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (partner !== null) {
      const res = await PartnerApi.put(partner.id, values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Parceiro atualizado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await PartnerApi.post(values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Parceiro criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setValues({
      name: partner?.name ?? '',
      is_active: partner?.is_active ?? false,
    });
  }, [partner, show]);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={partner ? 'Atualizar Parceiro' : 'Criar Parceiro'}
      size="medium"
      minHeight="min-h-[370px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
        />
        <p>Status</p>
        <Switch
          onChange={() =>
            setValues({ ...values, is_active: !values.is_active })
          }
          state={values.is_active}
        />
        <Button
          disabled={values.name.length === 0 || !values.is_active || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterGroup;
