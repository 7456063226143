import { baseApiPrivate } from '../../../api/baseApi';
import { SalesChannel } from '../types';

async function getSalesChannelByCompany(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/salesChannel/${companyId}`,
  });
}

async function createSalesChannel(e: Omit<SalesChannel, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/salesChannel',
    body: e,
  });
}

async function updateSalesChannel(e: Omit<SalesChannel, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/salesChannel/${id}`,
    body: e,
  });
}

async function deleteSalesChannel(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/salesChannel/${id}`,
  });
}

export const ApiSalesChannel = {
  getSalesChannelByCompany,
  createSalesChannel,
  updateSalesChannel,
  deleteSalesChannel,
};
