/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components';
import Card from '../../../../../components/Card/card';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiDashboard } from '../../api';
import { totalSalesPredictedProps } from '../../types';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
}

const DayIndicators: React.FC<props> = ({ companyId, period, funnelId }) => {
  const [total, setTotal] = useState<totalSalesPredictedProps>();
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [predicted, setTredicted] = useState<totalSalesPredictedProps>();
  const [isLoadingPredicted, setIsLoadingPredicted] = useState(false);

  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoadingPredicted(true);
      const predictedGraph = await ApiDashboard.getTotalPredicted(
        companyId,
        funnelId,
        period
      );

      if (typeof predictedGraph !== 'string') {
        setTredicted(predictedGraph);
      }
      setIsLoadingPredicted(false);
    };

    const fetchGraphTotal = async () => {
      setIsLoadingTotal(true);
      const totalSales = await ApiDashboard.getTotalSales(
        companyId,
        funnelId,
        period
      );

      if (typeof totalSales !== 'string') {
        setTotal(totalSales);
      }
      setIsLoadingTotal(false);
    };
    fetchGraph();
    fetchGraphTotal();
  }, [period, funnelId]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <Card className="flex-col p-4">
        {isLoadingTotal ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">Total</p>
            </div>

            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-green">
                {maskHelper.formatMoeda(total?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">
                {`${Number(total?._count ?? '').toLocaleString(
                  'pt-br'
                )} Oportunidade(s)`}
              </p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">Previsto</p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-gray-600">
                {maskHelper.formatMoeda(predicted?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">
                {`${Number(predicted?._count ?? '').toLocaleString(
                  'pt-br'
                )} Oportunidade(s)`}
              </p>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default DayIndicators;
