import React, { useState } from 'react';
import { ApiContract } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { Button, Modal, Table } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { financialProps } from '../types';
import maskHelper from '../../../helpers/mask.helper';
import { Plans } from '../../chartAccounts/types';

/* eslint-disable no-unused-vars */
interface DeleteProps {
  id: number;
  close: () => void;
  open: boolean;
  updateTable: () => void;
  installments?: financialProps[];
  PlanList: Plans[];
}

const CancelContract: React.FC<DeleteProps> = ({
  close,
  id,
  open,
  updateTable,
  installments = [],
  PlanList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = ToastNotify();
  const handleDelete = async () => {
    setIsLoading(true);
    const deleted = await ApiContract.cancelContract(id);
    if (deleted.id) {
      setIsLoading(false);
      notify({
        message: `Contrato cancelado com sucesso`,
        type: 'Success',
      });
      updateTable();
      close();
    } else {
      setIsLoading(false);
      notify({ message: dictionaryError(deleted), type: 'Error' });
    }
  };
  const columns = [
    {
      name: 'ID Banco',
      key: 'id',
      selector: (row: financialProps) => row.id,
    },
    {
      name: 'Conta de Lançamento',
      minWidth: '160px',
      key: 'pla_account_id',
      selector: (row: financialProps) =>
        PlanList.find((item) => item.id === row.pla_account_id)?.title,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: financialProps) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Vencimento',
      key: 'due_date',
      selector: (row: financialProps) =>
        maskHelper.formatDateDMY(row.due_date.slice(0, 10)),
    },

    {
      name: 'Status',
      key: 'status',
      selector: (row: financialProps) => row.status,
      cell: (row: financialProps) => {
        switch (row.status) {
          case 'Aberta':
            return <p className="text-[11px] text-black">{row.status}</p>;
          case 'Paga':
            return (
              <p className="text-[11px] font-medium text-green">{row.status}</p>
            );
          case 'Vencida':
            return (
              <p className="text-[11px] font-semibold text-red">{row.status}</p>
            );
          case 'Cancelada':
            return (
              <p className="text-[11px] font-semibold text-gray-600">
                {row.status}
              </p>
            );
          default:
            return (
              <p className="text-[11px] font-semibold text-black">
                {row.status}
              </p>
            );
        }
      },
    },
  ];

  return (
    <Modal
      setIsOpen={close}
      title="Cancelamento do contrato"
      isOpen={open}
      size="2xlarge"
      minHeight="min-h-[250px]"
    >
      {installments.length > 0 ? (
        <>
          <div className="px-3 py-5 flex items-center gap-3 w-full h-10 border border-dashed border-primary">
            <IconDictionary
              size={24}
              name="AlertTriangle"
              className="text-primary"
            />
            <p className="text-sm">
              Ao cancelar o contrato, todas as parcelas abaixo serão canceladas
            </p>
          </div>
          <Table columns={columns} data={installments} isLoading={false} />
        </>
      ) : (
        <p className="text-sm mb-10">
          Tem certeza que deseja cancelar o contrato?
        </p>
      )}

      <div className="flex gap-3 w-full mt-6 justify-center">
        <Button variant="primary-strong" onClick={close} className="w-64">
          Voltar
        </Button>
        <Button
          className="w-64 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
          variant="outline-primary"
          disabled={isLoading}
          onClick={handleDelete}
          actionType="button-loading"
          isLoading={isLoading}
        >
          Confirmar cancelamento
        </Button>
      </div>
    </Modal>
  );
};

export default CancelContract;
