import { baseApiPrivate } from '../../../../api/baseApi';
import { Profile } from '../types';

async function getAllProfile() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/profile',
  });
}

async function createProfile(e: Omit<Profile, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/profile',
    body: e,
  });
}

async function updateProfile(e: Omit<Profile, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/profile/${id}`,
    body: e,
  });
}

async function deleteProfile(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/profile/${id}`,
  });
}

export const ApiProfile = {
  getAllProfile,
  createProfile,
  updateProfile,
  deleteProfile,
};
