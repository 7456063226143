import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import Button from '../Button/button';
import maskHelper from '../../helpers/mask.helper';
import { ToastNotify } from '../Toast/toast';
import DeleteModal from '../Delete/deleteModal';
import IconDictionary from '../Icons/icons';
import { Table } from '..';

export interface attachment {
  id: number;
  title: string;
  path: string;
  created_at: string;
}

/* eslint-disable no-unused-vars */
interface AttachmentsProps {
  apiFunction: (e: FormData) => Promise<any>;
  deleteApiFunction: (id: number) => Promise<boolean>;
  keyFile:
    | 'client_id'
    | 'provider_id'
    | 'companie_id'
    | 'companie_user_id'
    | 'contract_id'
    | 'financial_id';
  secondKeyFile?: { label: string; value: number };
  id: number;
  AttachmentsList: attachment[];
  updateTable: (e: string) => void;
  nextPage?: () => void;
  isLoadingTable: boolean;
}

const Attachments: React.FC<AttachmentsProps> = ({
  apiFunction,
  keyFile,
  id,
  AttachmentsList,
  nextPage,
  deleteApiFunction,
  updateTable,
  isLoadingTable,
  secondKeyFile,
}) => {
  const { notify } = ToastNotify();
  const [modalDelete, setModalDelete] = useState(false);
  const [file, setFile] = useState<attachment>();
  const [isLoading, setIsLoading] = useState(false);

  const createFile = async (fileForm: any) => {
    setIsLoading(true);
    const formData: any = new FormData();

    try {
      if (secondKeyFile) {
        formData.append(secondKeyFile.label, secondKeyFile.value);
      }
      formData.append(keyFile, id);
      formData.append('title', fileForm[0].name);
      formData.append('file', fileForm[0]);
    } catch {
      setIsLoading(false);
    }

    const res = await apiFunction(formData);
    if (res.created_at) {
      notify({ message: 'Arquivo enviado com sucesso', type: 'Success' });
      updateTable(`${Math.random()}newfile`);
      setIsLoading(false);
    } else {
      notify({ message: 'Desculpe, algo deu errado', type: 'Error' });
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      createFile(fileCreateObj);
    },
  });

  const deleteFile = async (e: attachment) => {
    setFile(e);
    setModalDelete(true);
  };

  const columns = [
    {
      maxWidth: '45px',
      cell: (row: attachment) => (
        <div className="flex items-center justify-center w-full">
          <a
            href={`${process.env.REACT_APP_API}${row.path}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconDictionary
              name="Download"
              size={20}
              className="cursor-pointer"
            />
          </a>
        </div>
      ),
    },
    {
      name: 'Nome',
      key: 'name',
      selector: (row: attachment) => row.title,
      cell: (row: attachment) => (
        <a
          href={`${process.env.REACT_APP_API}${row.path}`}
          target="_blank"
          rel="noreferrer"
          className="text-xs"
        >
          {row.title}
        </a>
      ),
    },
    {
      name: 'Data',
      cell: (row: attachment) => (
        <div>{maskHelper.formatDateDMY(row.created_at)}</div>
      ),
    },
    {
      name: '',
      key: 'action',
      cell: (e: attachment) => (
        <CloseOutline
          className="cursor-pointer"
          size={20}
          color="red"
          onClick={() => deleteFile(e)}
        />
      ),
    },
  ];

  return (
    <div className="col-span-3 flex flex-col items-center w-full mt-7">
      <div
        {...getRootProps()}
        className={classNames(
          'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative',
          {
            'border-[#DC7E19]': isDragActive,
          }
        )}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="font-light text-sm text-primary">
            Solte o arquivo aqui...
          </p>
        ) : (
          <p className="font-light text-sm text-center text-[#555]">
            Arraste e solte aqui ou clique para selecionar um arquivo
          </p>
        )}
      </div>
      <div className="max-h-[350px] overflow-y-auto w-full">
        <Table
          columns={columns}
          data={AttachmentsList}
          isLoading={isLoadingTable}
        />
      </div>
      <DeleteModal
        updateTable={() => updateTable(`${Math.random()} delete`)}
        apiFunction={deleteApiFunction}
        name={file?.title ?? ''}
        type="Arquivo"
        close={() => setModalDelete(false)}
        id={file?.id ?? -1}
        notify={notify}
        open={modalDelete}
      />
      {nextPage && (
        <div className="col-span-2 flex justify-center mt-6">
          <Button
            onClick={() => nextPage()}
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Continuar
          </Button>
        </div>
      )}
    </div>
  );
};

export default Attachments;
