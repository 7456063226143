/* eslint-disable no-unused-vars */
import moment from 'moment';
import { notifyProps } from '../../../components/Toast/toast';
import { getUrlPlatform } from '../../../helpers/utils.helper';
import { ApiContract } from '../api';
import { contractProps, financialProps } from '../types';

export function getSignColor(row: contractProps) {
  if (!row?.autentique_document_status) return 'black';

  switch (row.autentique_document_status) {
    case 'PARTIAL_SIGNED':
    case 'NOT_SIGNED':
      return '#d4af37';
    case 'SIGNED':
      return 'green';
    default:
      return 'black';
  }
}

export const getRequestSignButtonTitle = (row: contractProps) =>
  row.autentique_document_status
    ? 'Verificar assinaturas'
    : 'Solicitar assinaturas';

export const dateFilterOptions = [
  { value: 'all', label: 'Todos' },
  { value: 'today', label: 'Hoje' },
  { value: 'yesterday', label: 'Ontem' },
  { value: 'this_week', label: 'Esta semana' },
  { value: 'last_week', label: 'Semana passada' },
  { value: 'this_month', label: 'Este mês' },
  { value: 'last_30_days', label: 'Últimos 30 dias' },
  { value: 'other_period', label: 'Outro intervalo' },
];

export const statusFormat = {
  Aberto: 'Aberto',
  'Ag.Pagamento': 'Aguardando Pagamento',
  Ativo: 'Ativo',
  Inadimplente: 'Inadimplente',
  Cancelado: 'Cancelado',
  Interrompido: 'Interrompido',
};

export async function downloadPdf(
  contractId: number,
  contractNumber: string,
  clientName: string,
  setIsLoadingPDF: (e: number) => void
) {
  setIsLoadingPDF(contractId);
  const download = document.createElement('a');
  const res = await ApiContract.saveAsPDFContract(contractId);
  const blob = new Blob([res]);
  const url = window.URL.createObjectURL(blob);
  download.href = url;
  download.download = `${clientName}-${contractNumber}.pdf`;
  document.body.appendChild(download);
  download.click();
  document.body.removeChild(download);
  setIsLoadingPDF(-1);
}
export const getPaymentLink = (
  financial: financialProps[],
  notify: (e: notifyProps) => void
) => {
  try {
    const links = financial.sort((a, b) => b.id - a.id);
    return links[0].payment_link;
  } catch (e) {
    notify({
      message:
        'Contrato não possui link de pagamento, por favor entre em contato.',
      type: 'Warning',
    });

    return '';
  }
};

export const getStatusMessenger = (
  status: string,
  accept: boolean,
  contractId: number,
  paymentLink: string,
  clientName: string,
  companieId: number
) => {
  switch (status) {
    case 'Aberto':
      return '';

    case 'Ag.Pagamento':
      if (accept === false) {
        return `Olá ${clientName}, tudo bem? %0A%0A*Link para aceite do contrato:* ${getUrlPlatform()}contract/accept/${contractId}/${companieId} %0A%0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`;
      }
      return `Olá ${clientName}, tudo bem? %0A%0A*Aqui está o link de pagamento conforme combinado:* ${paymentLink} %0A%0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`;

    default:
      if (accept) {
        return `Olá ${clientName}, tudo bem? %0A%0A*Aqui está o link de pagamento conforme combinado:* ${paymentLink} %0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`;
      }
      return `Olá ${clientName}, tudo bem? %0A%0A*Aqui está o link de pagamento conforme combinado:* ${paymentLink} %0A%0A*Link para aceite do contrato:* ${getUrlPlatform()}contract/accept/${contractId}/${companieId} %0A%0AQualquer dúvida estamos à disposição. %0A%0AObrigado.%0A*Atendimento LeadSim*`;
  }
};

export function calcMonths(data: string) {
  const dueDate = moment(data);
  const todayDate = moment();
  return dueDate.diff(todayDate, 'months');
}

export function calContractValue(contract: contractProps) {
  return contract.contract_products
    .filter((item) => item.qtd_installments === null)
    .reduce((prev, curr) => prev + Number(curr.value), 0);
}

export function hasOutDateFin(fin: financialProps[]) {
  return Boolean(fin.find((item) => item.status === 'Vencida'));
}

export function getStyledDotMonth(data: string) {
  const months = calcMonths(data);
  if (months > 2) {
    return (
      <div className="w-6 h-6 bg-emerald-500 rounded-full flex items-center justify-center font-medium">
        {months}
      </div>
    );
  }
  if (months >= 1) {
    return (
      <div className="w-6 h-6 bg-yellow-400 rounded-xl flex items-center justify-center font-medium">
        {months}
      </div>
    );
  }
  return (
    <div className="w-6 h-6 bg-red rounded-xl flex items-center justify-center text-white font-medium">
      {months}
    </div>
  );
}

export const initialFormValues = {
  providers: [],
  document: null,
  email: null,
  whatsapp: null,
  birth: null,
  relationship: null,
  name: null,
};
