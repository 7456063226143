/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Address, Button } from '../../../../../../components';
import { CompaniePFHeader, CompaniePJHeader, isBase64 } from '../../utils';

import { notify } from '../../../../../../components/Toast/toast';
import { getTokenAccountInformation } from '../../../../../../helpers/token.helper';
import { tokenModel } from '../../../../../../types';
import { logiParams } from '../../../../../login/api';
import { saveParamLocal } from '../../../../../login/utils';
import { ApiCompanyGalaxPay } from '../../api';
import ModalAlert from '../ModalAlert';
import DocumentsPF from './components/DocumentsPF/DocumentsPF';
import DocumentsPJ from './components/DocumentsPJ/DocumentsPJ';
import GeneralDataPF from './components/generalDataPF';
import GeneralDataPJ from './components/generalDataPJ';
import ProfessionalData from './components/professional';

interface page {
  title: string;
  page: number;
}

interface props {
  modalAlert: boolean;
  setModalAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  companyDefault: any | null;
  setCompanyDefault: (e: any) => void;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  action: 'PJ' | 'PF' | '';
  associateCompanieGalax?: (id: number) => Promise<any>;
}

const Register: React.FC<props> = ({
  modalAlert,
  setModalAlert,
  setShow,
  setIsEdited,
  setCompanyDefault,
  registerPage,
  setRegisterPage,
  reloadTable,
  associateCompanieGalax = async () => {},
  action,
  companyDefault,
}) => {
  const header = action === 'PJ' ? CompaniePJHeader : CompaniePFHeader;
  const cannotEditAddressDocs = companyDefault
    ? companyDefault.Verification?.status === 'approved'
    : false;

  const nextPage = (values: any) => {
    if (registerPage.page < header.length - 1) {
      // Edit and general data
      if (
        registerPage.page === 0 &&
        companyDefault &&
        companyDefault.galaxPayId
      ) {
        if (
          !(
            companyDefault.urlLogo === values.logo &&
            companyDefault.canAccessPlatform === values.canAccessPlatform
          )
        ) {
          setIsEdited(true);
          setCompanyDefault({
            ...companyDefault,
            urlLogo: values.logo, // is allowed to edit only logo and canAccessPlatform
            canAccessPlatform: values.canAccessPlatform,
          });
        }

        setRegisterPage({
          title: header[registerPage.page + 1].title,
          page: registerPage.page + 1,
        });
        return;
      }

      // create and general Data
      if (registerPage.page === 0 && !companyDefault?.galaxPayId) {
        setIsEdited(true);
        setCompanyDefault({ ...values });
        setRegisterPage({
          title: header[registerPage.page + 1].title,
          page: registerPage.page + 1,
        });
        return;
      }

      // create and professional
      if (
        registerPage.title === 'Professional' &&
        !companyDefault?.galaxPayId
      ) {
        setIsEdited(true);
        setCompanyDefault({ ...companyDefault, ...values });
        setRegisterPage({
          title: header[registerPage.page + 1].title,
          page: registerPage.page + 1,
        });
        return;
      }

      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(`${Math.random()} 'create'`);
      setShow(false);
    }
  };

  const handleCreateAccountAddress = async (e: any) => {
    // Edit
    if (companyDefault && companyDefault.galaxPayId) {
      const payload = isBase64(
        companyDefault.urlLogo?.replace('data:', '').replace(/^.+,/, '')
      )
        ? {
            logo: companyDefault.urlLogo
              ?.replace('data:', '')
              ?.replace(/^.+,/, ''),
            canAccessPlatform: companyDefault.canAccessPlatform,

            Address: {
              city: e.city,
              state: e.state,
              complement: e.complement,
              zipCode: e.zipcode,
              neighborhood: e.province,
              street: e.address,
              number: String(e.number),
            },
          }
        : {
            canAccessPlatform: companyDefault.canAccessPlatform,
            Address: {
              city: e.city,
              state: e.state,
              complement: e.complement,
              zipCode: e.zipcode,
              neighborhood: e.province,
              street: e.address,
              number: String(e.number),
            },
          };
      setIsEdited(false);
      const updateRes = await ApiCompanyGalaxPay.updateCompanie(
        payload,
        companyDefault.galaxPayId
      );

      setCompanyDefault({
        ...companyDefault,
        ...payload,
      });

      return updateRes;
    }

    // Create PJ
    if (action === 'PJ') {
      setIsEdited(false);
      const res = await ApiCompanyGalaxPay.createCompaniePJ({
        ...companyDefault,
        logo: companyDefault?.logo?.replace('data:', '').replace(/^.+,/, ''),
        Address: {
          city: e.city,
          state: e.state,
          complement: e.complement,
          zipCode: e.zipcode,
          neighborhood: e.province,
          street: e.address,
          number: String(e.number),
        },
      });
      if (res.id) {
        const companieIdGalax = await associateCompanieGalax(res.id);
        // Atualiza o token quando muda o galax_id da empresa
        if (companieIdGalax?.id) {
          const userEmail: tokenModel = getTokenAccountInformation();
          const login = await logiParams({
            email: userEmail.email,
            password: 'IHthn4KY4jELxt9JPjOL',
          });
          if (login.token) {
            saveParamLocal(login);
          }
        }

        notify({ message: 'Conta criada com sucesso!', type: 'Success' });

        setCompanyDefault({
          ...companyDefault,
          galaxPayId: res.galax_id,
        });

        return { id: res.id };
      }
      return res;
    }

    // Create PF
    if (action === 'PF') {
      setIsEdited(false);
      const res = await ApiCompanyGalaxPay.createCompaniePF({
        ...companyDefault,
        logo: companyDefault?.logo?.replace('data:', '').replace(/^.+,/, ''),

        Address: {
          city: e.city,
          state: e.state,
          complement: e.complement,
          zipCode: e.zipcode,
          neighborhood: e.province,
          street: e.address,
          number: String(e.number),
        },
      });
      if (res.id) {
        const companieIdGalax = await associateCompanieGalax(res.id);
        // Atualiza o token quando muda o galax_id da empresa
        if (companieIdGalax?.id) {
          const userEmail: tokenModel = getTokenAccountInformation();
          const login = await logiParams({
            email: userEmail.email,
            password: 'IHthn4KY4jELxt9JPjOL',
          });
          if (login.token) {
            saveParamLocal(login);
          }
        }
        notify({ message: 'Conta criada com sucesso!', type: 'Success' });
        setCompanyDefault({
          ...companyDefault,
          galaxPayId: res.galax_id,
        });

        return { id: res.id };
      }
      return res;
    }

    return { id: -1 };
  };

  useEffect(
    () => () => {
      setCompanyDefault(null);
    },
    []
  );

  return (
    <>
      <ModalAlert
        close={() => {
          setShow(false);
          setModalAlert(false);
        }}
        edit={companyDefault?.galaxPayId ? 'edição' : 'criação'}
        setShow={() => setModalAlert(false)}
        show={modalAlert}
      />
      <div className="flex flex-col">
        <div className="grid grid-cols-2 md:flex flex-wrap lg:flex-nowrap gap-2 mt-9">
          {header.map((item) => (
            <Button
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              key={item.id}
              className={classNames([
                'font-medium',
                'md:w-32 md:min-w-fit md:px-2',
                companyDefault !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => companyDefault !== null && setRegisterPage(item)}
              disabled={
                !companyDefault?.galaxPayId && item.title === 'Documentos'
              }
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        {registerPage.page === 0 ? (
          action === 'PF' ? (
            <GeneralDataPF
              disabledEdit={cannotEditAddressDocs}
              setId={setCompanyDefault}
              nextPage={nextPage}
              defaultValue={companyDefault}
              id={companyDefault?.galaxPayId ?? -1}
            />
          ) : (
            <GeneralDataPJ
              disabledEdit={cannotEditAddressDocs}
              setId={setCompanyDefault}
              nextPage={(e) => {
                nextPage(e);
              }}
              defaultValue={companyDefault}
              id={companyDefault?.galaxPayId ?? -1}
            />
          )
        ) : (
          <> </>
        )}

        {registerPage.page === 1 ? (
          action === 'PF' ? (
            <ProfessionalData
              disabledEdit={cannotEditAddressDocs}
              setId={setCompanyDefault}
              nextPage={nextPage}
              defaultValue={companyDefault}
              id={companyDefault?.galaxPayId ?? -1}
            />
          ) : (
            <Address
              isAllFieldsDisabled={cannotEditAddressDocs}
              defaultValue={
                companyDefault?.Address
                  ? {
                      ...companyDefault.Address,
                      address: companyDefault.Address.street,
                      zipcode: companyDefault.Address.zipCode,
                      province: companyDefault.Address.neighborhood,
                    }
                  : null
              }
              id={-1}
              nextPage={nextPage}
              hasNumber
              apiFunction={handleCreateAccountAddress}
            />
          )
        ) : (
          <> </>
        )}

        {registerPage.page === 2 ? (
          action === 'PJ' ? (
            <DocumentsPJ
              disabledEdit={cannotEditAddressDocs}
              setId={setCompanyDefault}
              nextPage={nextPage}
              defaultValue={companyDefault}
              id={companyDefault?.galaxPayId ?? -1}
            />
          ) : (
            <Address
              isAllFieldsDisabled={cannotEditAddressDocs}
              defaultValue={
                companyDefault?.Address
                  ? {
                      ...companyDefault.Address,
                      address: companyDefault.Address.street,
                      zipcode: companyDefault.Address.zipCode,
                      province: companyDefault.Address.neighborhood,
                    }
                  : null
              }
              id={-1}
              nextPage={nextPage}
              hasNumber
              apiFunction={handleCreateAccountAddress}
            />
          )
        ) : (
          <> </>
        )}

        {registerPage.page === 3 && (
          <DocumentsPF
            disabledEdit={cannotEditAddressDocs}
            setId={setCompanyDefault}
            nextPage={nextPage}
            defaultValue={companyDefault}
            id={companyDefault?.galaxPayId ?? -1}
          />
        )}
        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
