/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { ApiDashboard } from '../../api';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  companyId: number;
  period: string;
}
const StatusGraph: React.FC<props> = ({ companyId, period }) => {
  const [graph, setGraph] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const res = await ApiDashboard.getTotalFinancialByStatus(
        companyId,
        period
      );
      if (typeof res !== 'string') {
        setGraph(res);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period]);

  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Faturamento por status</p>
      {graph === undefined || graph === null ? (
        <div className="w-full h-64 lg:h-full flex items-center justify-center">
          <p className="text-xs">Nenhum dado encontrado</p>
        </div>
      ) : (
        <div className="w-full min-h-fit h-80 flex items-center">
          <Plot
            className="w-full"
            data={[
              {
                x: Object.keys(graph).map((item) => item),
                y: Object.values(graph).map((item: any) => Number(item._sum)),

                text: Object.values(graph).map((item: any) =>
                  maskHelper.formatMoeda(item._sum)
                ),
                marker: {
                  color: ['#954893', '#2FAC66', '#DA3C3C', '#808080'],
                },
                name: '',
                type: 'bar',
                hoverinfo: 'y+x',
                hovertemplate: '%{text} - %{x}',
              },
            ]}
            config={{ displayModeBar: false, responsive: true }}
            layout={{
              height: 200,
              autosize: true,
              margin: { l: 40, r: 20, b: 50, t: 28 },
              font: {
                size: 12,
              },
              xaxis: {
                showgrid: false,
              },
              yaxis: {
                showgrid: false,
                autorange:
                  Object.values(graph).reduce(
                    (accu: number, curr: any) => accu + Number(curr._count),
                    0
                  ) !== 0,
                tick0: 0,
                tickformat: ' ,d',
              },
            }}
          />
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default StatusGraph;
