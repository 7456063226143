import { useEffect, useState } from 'react';
import { ApiPlansAccount } from '../api';
import { Plans } from '../types';

export function useFetchPlansAccount(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Plans[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchActivity = async () => {
      const activitie = await ApiPlansAccount.getChartsAccounts();
      if (typeof activitie !== 'string') {
        setData(activitie);
      }
      setIsLoading(false);
    };

    FetchActivity();
  }, [key]);
  return { PlanList: data, isLoading, setData };
}
