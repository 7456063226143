/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Input } from '../../../components';
import { notify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { dictionaryError, getUrlPlatform } from '../../../helpers/utils.helper';
import cpfValidation from '../../../helpers/validations/cpf.validation';
import phoneValidate from '../../../helpers/validations/phone.validate';
import { ContactProps } from '../../contacts/types';
import { ApiCheckout } from '../api';

import ButtonRounded from './button';
import CardRounded from './card';
import GeneratePdfTerm from './pdf';
import { CreateAccountProps } from '../types';
import cnpjValidation from '../../../helpers/validations/cnpj.validation';

const RegisterCard: React.FC<{
  companyId: number;
  setCardNumber: (e: number) => void;
  setResponseData: (op: CreateAccountProps) => void;
  documentCompany: string;
  planId: number;
  done: boolean;
}> = ({
  companyId,
  documentCompany,
  planId,
  done,
  setResponseData,
  setCardNumber,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState<'PF' | 'PJ'>('PF');
  const [terms, setTerms] = useState({ term: false, contact: false });

  const schema = yup.object().shape({
    name: yup.string().required('Campo é obrigatório'),
    document: yup
      .string()
      .required('Campo é obrigatório')
      .test({
        message: 'Campo inválido',
        test: (value) =>
          value
            ? type === 'PF'
              ? cpfValidation.isValid(value)
              : cnpjValidation.isValid(value)
            : true,
      }),
    whatsapp: yup
      .string()
      .required('Campo é obrigatório')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value !== undefined
            ? phoneValidate.isValid(maskHelper.number(value))
            : true,
      }),

    email: yup
      .string()
      .email('E-mail inválido')
      .required('Campo é obrigatório'),
    owner: yup.string().required('Campo é obrigatório'),
  });

  const { control, handleSubmit } = useForm<CreateAccountProps>({
    resolver: yupResolver(schema),
  });

  const Submit = async (e: CreateAccountProps) => {
    setIsLoading(true);
    // const payload: CreateAccountProps = {};
    // const res = await ApiCheckout.create(e);
    // if (res.opportunity) {
    //   setIsDone(true);
    //   setResponseData(res.opportunity.id, res.person.id);
    // } else {
    //   notify({ message: dictionaryError(res), type: 'Error' });
    //   setIsDone(false);
    // }
    setResponseData({
      ...e,
      whatsapp: maskHelper.numberPhone(e.whatsapp),
      document: maskHelper.numberCPFCNPJ(e.document),
      // type,
    });
    setIsLoading(false);
    setCardNumber(1);
  };

  const getTerm = async (cnpj: string) => {
    if (cnpj.length > 14) {
      GeneratePdfTerm(
        `${getUrlPlatform()}files/Termos_cnpj.pdf`,
        cnpj,
        57,
        746.2
      );
      return;
    }

    GeneratePdfTerm(`${getUrlPlatform()}files/Termos_cpf.pdf`, cnpj, 57, 746.2);
  };

  return (
    <CardRounded disabled={done} finished={done}>
      <p className="text-xl text-green-400 mb-5 font-extrabold">Cadastro</p>
      <p className="text-xs mb-5">
        Os seus dados serão utilizados apenas para realização de cadastro.
      </p>
      {/* <Preview id="term_html" html={TermHtml(documentCompany)} /> */}

      <form className="flex flex-col gap-3" onSubmit={handleSubmit(Submit)}>
        <p className="text-sm font-semibold">Tipo da Empresa</p>

        <div className="flex gap-5">
          <div className="flex gap-2 text-xs">
            <input
              className="cursor-pointer"
              type="radio"
              checked={type === 'PF'}
              onClick={() => setType('PF')}
              disabled={done}
            />
            <p>Pessoa Física</p>
          </div>
          <div className="flex gap-2 text-xs">
            <input
              className="cursor-pointer"
              type="radio"
              checked={type === 'PJ'}
              onClick={() => setType('PJ')}
              disabled={done}
            />
            <p>Pessoa Jurídica</p>
          </div>
        </div>

        <p className="text-sm font-semibold mt-5">Dados da Empresa</p>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              title={type === 'PF' ? 'Nome Completo' : 'Razão Social'}
              variant="outline-orange"
              placeholder="ex: Maria Lucia Almeida"
              errorMensage={fieldState.error?.message}
              disabled={done}
              borderDefault="border-[#03a6c7]"
            />
          )}
        />
        <div className="grid grid-cols-2 gap-2">
          <Controller
            control={control}
            name="document"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title={type === 'PF' ? 'CPF' : 'CNPJ'}
                variant="outline-orange"
                placeholder="123.456.789-10"
                value={
                  type === 'PF'
                    ? maskHelper.cpf(field.value ?? '')
                    : maskHelper.cnpj(field.value ?? '')
                }
                errorMensage={fieldState.error?.message}
                disabled={done}
                borderDefault="border-[#03a6c7]"
              />
            )}
          />
          <Controller
            control={control}
            name="owner"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                title="Proprietário"
                variant="outline-orange"
                placeholder="ex: Maria Lucia Almeida"
                errorMensage={fieldState.error?.message}
                disabled={done}
                borderDefault="border-[#c70a03]"
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              title="E-mail"
              variant="outline-orange"
              placeholder="email@gmail.com"
              errorMensage={fieldState.error?.message}
              disabled={done}
              borderDefault="border-[#03a6c7]"
            />
          )}
        />
        <Controller
          control={control}
          name="whatsapp"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              title="WhatsApp"
              variant="outline-orange"
              placeholder="(11) 99999-9999"
              value={maskHelper.phone(field.value ?? '')}
              errorMensage={fieldState.error?.message}
              disabled={done}
              borderDefault="border-[#03a6c7]"
            />
          )}
        />

        <div className="flex gap-3 mt-2">
          <input
            type="checkbox"
            className="accent-green-400 cursor-pointer"
            disabled={done}
            checked={terms.term}
            onChange={() => setTerms({ ...terms, term: !terms.term })}
          />
          <p className="text-xs flex gap-1">
            Li e aceito os{' '}
            <button
              type="button"
              onClick={() => getTerm(maskHelper.cnpjCpf(documentCompany))}
              className="font-bold text-green-400 underline cursor-pointer"
            >
              termos e condições
            </button>
          </p>
        </div>
        <div className="flex gap-3">
          <input
            type="checkbox"
            className="accent-green-400 cursor-pointer"
            disabled={done}
            checked={terms.contact}
            onChange={() => setTerms({ ...terms, contact: !terms.contact })}
          />
          <p className="text-xs flex gap-1 flex-wrap">
            Eu aceito notificações e contato via
            <b className="text-gray-600">SMS ou e-mail</b>
          </p>
        </div>

        <ButtonRounded
          isLoading={isLoading}
          type="submit"
          disabled={terms.contact === false || terms.term === false || done}
        >
          Avançar
        </ButtonRounded>
      </form>
    </CardRounded>
  );
};

export default RegisterCard;
