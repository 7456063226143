import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import SelectComponent from './select';
import maskHelper from '../../helpers/mask.helper';

interface props {
  filterDate: string;
  // eslint-disable-next-line no-unused-vars
  setFilterDate: (e: string) => void;
  restartPagination: () => void;
  variant?: 'gray' | 'outline-primary';
  hideAllOption?: boolean;
  disabled?: boolean;
}
const FilterDateSelect: React.FC<props> = ({
  filterDate,
  variant = 'outline-primary',
  hideAllOption = false,
  disabled,
  restartPagination,
  setFilterDate,
}) => {
  const dateFilterOptions = hideAllOption
    ? [
        { value: 'today', label: 'Hoje' },
        { value: 'yesterday', label: 'Ontem' },
        { value: 'this_week', label: 'Esta semana' },
        { value: 'last_week', label: 'Semana passada' },
        { value: 'this_month', label: 'Este mês' },
        { value: 'last_30_days', label: 'Últimos 30 dias' },
        { value: 'other_period', label: 'Outro intervalo' },
      ]
    : [
        { value: 'all', label: 'Todos' },
        { value: 'today', label: 'Hoje' },
        { value: 'yesterday', label: 'Ontem' },
        { value: 'this_week', label: 'Esta semana' },
        { value: 'last_week', label: 'Semana passada' },
        { value: 'this_month', label: 'Este mês' },
        { value: 'last_30_days', label: 'Últimos 30 dias' },
        { value: 'other_period', label: 'Outro intervalo' },
      ];

  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });
  return (
    <>
      <SelectComponent
        isDisabled={disabled}
        isClearable={hideAllOption}
        variant={variant}
        icon="Calendario"
        value={
          datePicker.open
            ? { value: 'other_period', label: 'Outro intervalo' }
            : dateFilterOptions.find((item) => item.value === filterDate)
        }
        className="w-full sm:w-48 lg:w-40 2xl:w-60"
        options={dateFilterOptions}
        closeMenuOnSelect
        onChange={(e: any) => {
          if (e === null) {
            setFilterDate('');
            return;
          }
          if (e.value !== 'other_period') {
            setDatePicker({ open: false });
            setFilterDate(e.value);
            restartPagination();
            return;
          }

          setDatePicker({ open: true });
        }}
        maxOptionsHeight="300px"
      />
      {datePicker.open && (
        <div className="flex mb-1.5">
          <DatePicker
            openCalendarOnFocus
            format="dd/MM/yyyy"
            className="start_date_input"
            onChange={(e: Date) => {
              setDatePicker({ ...datePicker, start: e });
              if (typeof datePicker.end !== 'undefined') {
                setFilterDate(
                  `other_period/${maskHelper.formatDateYMD(
                    e.toLocaleDateString('pt-br')
                  )}/${maskHelper.formatDateYMD(
                    datePicker.end?.toLocaleDateString('pt-br') ?? ''
                  )}`
                );
                restartPagination();
              }
            }}
            value={datePicker.start}
          />
          <p className="bg-primary w-8 h-11 flex items-center justify-center text-xs text-white">
            Até
          </p>
          <DatePicker
            openCalendarOnFocus
            format="dd/MM/yyyy"
            className="end_date_input"
            onChange={(e: Date) => {
              setDatePicker({ ...datePicker, end: e });
              if (typeof datePicker.start !== 'undefined') {
                setFilterDate(
                  `other_period/${maskHelper.formatDateYMD(
                    datePicker.start?.toLocaleDateString('pt-br') ?? ''
                  )}/${maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))}`
                );
                restartPagination();
              }
            }}
            value={datePicker.end}
            minDate={datePicker.start}
          />
        </div>
      )}
    </>
  );
};

export default FilterDateSelect;
