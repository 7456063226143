import { baseApiPrivate } from '../../../api/baseApi';
import { OfferCustom, GetOfferCustom } from '../types';

const getOffersCustom = async (payload: GetOfferCustom) => {
  const urlParams = new URLSearchParams(payload as any).toString();
  return baseApiPrivate({
    method: 'GET',
    url: `companie/offer-custom${urlParams ? `?${urlParams}` : ''}`,
  });
};

const updateOfferCustom = async (offerId: number, payload: OfferCustom) =>
  baseApiPrivate({
    method: 'PUT',
    url: `companie/offer-custom/${offerId}`,
    body: payload,
  });

const createOfferCustom = async (payload: OfferCustom) =>
  baseApiPrivate({
    method: 'POST',
    url: 'companie/offer-custom',
    body: payload,
  });

const generatePDF = async () =>
  baseApiPrivate({
    method: 'GET',
    url: 'companie/offer-custom/generatePDF',
    responseFile: true,
  });

export { getOffersCustom, updateOfferCustom, createOfferCustom, generatePDF };
