import React from 'react';

import {
  CompanieWhatsappInstancesProps,
  ResultPlans,
  ModalProps,
  CreateContextProps,
  ProviderProps,
} from '../types';

import { ApiWhatsInstances, adminWhatsappPlansActive } from '../api';
import { fixNumberInstance, formatInstance } from '../utils';
import { FinancialProps } from '../../financial/types';

const Context = React.createContext<CreateContextProps>(
  {} as CreateContextProps
);

export const Provider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [instances, setInstances] = React.useState<
    CompanieWhatsappInstancesProps[]
  >([]);
  const [whatsappPlans, setWhatsappPlans] = React.useState({} as ResultPlans);
  const [listPlans, setListPlans] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState('');
  const [financialData, setFinancialData] = React.useState<FinancialProps[]>(
    []
  );

  const [modal, setModal] = React.useState<ModalProps>({
    show: 'undefined',
    data: {},
  });

  React.useEffect(() => {
    const fetchPlans = async () => {
      const data = await formatInstance();
      if (!data) {
        setModal({ show: 'purchase' });
        return;
      }
      setFinancialData(data.financial);
      if (data.status === 'Inadimplente') {
        setModal({ data, show: 'negociate' });
        return;
      }
      if (data.disable) {
        setModal({ show: 'purchase' });
        setWhatsappPlans({} as any);
      } else if (data.disablePurchase) {
        setModal({ show: 'warn' });
        setWhatsappPlans(data);
      } else {
        setWhatsappPlans(data);
      }
      setIsLoading(false);
    };
    fetchPlans();
  }, [modal.show === 'warn' || reload]);

  React.useEffect(() => {
    setIsLoading(true);
    if (whatsappPlans.id) {
      ApiWhatsInstances.getCompanieWhatsappInstances(whatsappPlans.id)
        .then((data) => setInstances(fixNumberInstance(data)))
        .catch(console.log)
        .finally(() => {
          setReload('');
          setIsLoading(false);
        });
    }
  }, [reload === 'load' || reload === 'reload' || whatsappPlans]);

  React.useEffect(() => {
    adminWhatsappPlansActive().then(setListPlans);
  }, []);

  // const value = React.useMemo<any>(() => ({ data }), []);

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        instances,
        whatsappPlans,
        modal,
        isLoading,
        listPlans,
        reload,
        setModal,
        setReload,
        setInstances,
        setWhatsappPlans,
        financialData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useWppContext() {
  const context = React.useContext(Context);
  return context;
}
