import { useEffect, useState } from 'react';
import { ApiWhatsapp } from '../api';
import { Whatsapp } from '../types';

export function useFetchWhatsapp(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Whatsapp[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiWhatsapp.getWhatsappPlans(false);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { WhatsappList: data, isLoadingWhatsapp: isLoading };
}
