/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { lighten } from 'polished';
import IconDictionary from '../../../components/Icons/icons';
import { Button, Card } from '../../../components';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ApiTheme } from '../api';
import { ThemeType } from '../types';
import { setItem } from '../../../helpers/storage.helper';
import { ToastNotify } from '../../../components/Toast/toast';

const CompanieCustom: React.FC = () => {
  const { notify } = ToastNotify();
  const [customTheme, setCustomTheme] = useState<ThemeType>({
    name: 'Padrão',
    color_hex: '#712770',
    path_logo: '',
  });
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const tokenInfo = getTokenAccountInformation();
  const themes = ApiTheme.getDefaultThemes();

  const getFilenameFromPath = (filePath: string) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };
  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return null; // Se não houver ponto no nome do arquivo, retorna null ou lance um erro
    }
    return filename.substring(lastDotIndex + 1);
  };

  useEffect(() => {
    const getCompanyTheme = async () => {
      if (!tokenInfo.companie_id) return;
      const currentTheme = await ApiTheme.getCompanyTheme(
        tokenInfo.companie_id
      );
      if (currentTheme.path_logo !== null && currentTheme.path_logo !== '') {
        const filename = getFilenameFromPath(currentTheme.path_logo);
        const imageType = getFileExtension(filename);
        const blob = await ApiTheme.getBlobFromUrl(currentTheme.path_logo);
        const file = new File([blob], filename, {
          type: `image/${imageType}`,
        });
        setDroppedFile(file);
      }
      setCustomTheme(currentTheme);
    };
    getCompanyTheme();
  }, []);

  useEffect(() => {
    if (customTheme.name !== 'Padrão' || customTheme.color_hex !== '#712770') {
      const root = document.documentElement;
      root.style.setProperty('--primary', customTheme.color_hex);
      root.style.setProperty(
        '--secondary',
        lighten(0.1, customTheme.color_hex)
      );
    }
  }, [customTheme.color_hex]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDroppedFile(e.dataTransfer.files[0]);
    }
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setDroppedFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const replaceImage = (path: string) => {
    const urlLogo = `${process.env.REACT_APP_API}${path}`;
    const imageLogo = document.getElementById('logo-black') as HTMLImageElement;
    const imageLogoMobile = document.getElementById(
      'logo-header-mobile'
    ) as HTMLImageElement;
    imageLogo.src = urlLogo;
    imageLogoMobile.src = urlLogo;
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (droppedFile === null) {
      if (customTheme.path_logo === '' || customTheme.path_logo === null) {
        notify({
          message:
            'Não foi possível salvar as alterações. Por favor adicione uma logo',
          type: 'Error',
        });
        setIsLoading(false);
        return;
      }
      const blob = await ApiTheme.getBlobFromUrl(customTheme.path_logo);
      formData.append('file', blob);
    } else {
      formData.append('file', droppedFile as Blob);
    }
    formData.append('companie_id', tokenInfo.companie_id.toString());
    formData.append('color_hex', customTheme.color_hex);

    const data: any = await ApiTheme.updateCompanyTheme(formData);

    if (data.id) {
      if (droppedFile !== null) {
        replaceImage(data.path_logo);
      }
      const root = document.documentElement;
      root.style.setProperty('--primary', customTheme.color_hex);
      root.style.setProperty(
        '--secondary',
        lighten(0.1, customTheme.color_hex)
      );
      setItem('logo-path', data.path_logo);
      setItem('color-hex', data.color_hex);
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full min-h-screen h-fit">
      <div className="flex gap-3 items-center text-primary-color">
        <IconDictionary name="brush" size={24} />
        <strong className="text-lg text-black">Personalização</strong>
      </div>
      <Card className="w-full p-7 mt-6 mb-3 flex-col min-h-screen max-sm:p-2">
        <div className="flex flex-col">
          <strong className="text-xl">Aparência</strong>
          <p className="text-sm">
            Ao configurar a cor primária e a logomarca, o LeadSim assume por
            padrão o estilo personalizado pelo usuário.
          </p>
          <hr className="my-3 border border-gray-400 border-solid" />
        </div>
        <div className="flex flex-col mt-3 md:flex-row">
          <div className="flex w-full justify-center items-center  md:w-1/3 md:mr-8">
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              className="h-64 w-64 max-w-full flex text-center relative justify-center items-center"
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple
                onChange={handleChange}
                className="hidden"
                accept="image/png, image/jpeg , image/jpg"
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={`h-64 w-64 flex items-center justify-center border-2 relative
                rounded-full border-dashed border-primary  cursor-pointer 
                ${droppedFile && 'bg-primary'}`}
              >
                {!droppedFile && (
                  <div className="flex flex-col items-center justify-center p-6">
                    <button
                      className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                      onClick={onButtonClick}
                      type="button"
                    >
                      <IconDictionary
                        name="add-photo"
                        size={38}
                        color="var(--primary)"
                      />
                      <span className="text-base font-bold text-primary">
                        Selecione sua logomarca
                      </span>
                    </button>
                    <p className="text-sm text-gray-400 font-medium">
                      ou arraste e solte aqui
                    </p>
                  </div>
                )}
                {droppedFile && (
                  <div className="flex flex-col items-center relative justify-center  rounded-full">
                    <img
                      src={URL.createObjectURL(droppedFile)}
                      alt="logo"
                      className="h-64 object-center overflow-hidden object-contain  rounded-full"
                      onError={() => setDroppedFile(null)}
                    />
                    <div
                      className="absolute right-4 top-0 -bottom-40 m-auto z-10 bg-gray-600
                    items-center justify-center transition duration-400 ease-in-out w-10 h-10 rounded-full flex hover:bg-gray-400 cursor-pointer"
                    >
                      <IconDictionary
                        name="photo-camera"
                        size={20}
                        color="white"
                      />
                    </div>
                  </div>
                )}
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  className="absolute w-full h-full rounded-lg top-0 right-0 bottom-0 left-0"
                />
              )}
            </form>
          </div>
          <div className="flex flex-col w-full md:4/6 mt-4 md:m-0">
            <div className="flex flex-wrap mt-1 w-full justify-center xl:justify-start">
              <div className="flex w-1/6 mr-3 h-20 rounded-lg mb-5  relative xl:w-1/12 max-sm:w-1/4">
                <div
                  className="flex w-1/2 h-full rounded-l-lg"
                  style={{
                    backgroundColor: lighten(0.1, customTheme.color_hex),
                  }}
                />
                <div
                  className="flex w-1/2 h-full rounded-r-lg"
                  style={{ backgroundColor: customTheme.color_hex }}
                />
                <input
                  type="color"
                  className="absolute cursor-pointer w-full h-full opacity-0 z-20"
                  onChange={(e) =>
                    setCustomTheme({
                      ...customTheme,
                      color_hex: e.target.value,
                    })
                  }
                />
                <IconDictionary
                  name="plus"
                  size={32}
                  className="absolute left-0 right-0 top-0 bottom-0 m-auto z-10"
                  color="white"
                />
              </div>
              {themes.map((theme) => (
                <button
                  className="flex w-1/6 mr-3 h-20 rounded-lg mb-5 cursor-pointer xl:w-1/12 max-sm:w-1/4"
                  key={theme.name}
                  onClick={() =>
                    setCustomTheme({
                      ...customTheme,
                      color_hex: theme.color_hex,
                    })
                  }
                  name="theme"
                  type="button"
                  title={theme.name}
                >
                  <div
                    className="flex w-1/2 h-full rounded-l-lg"
                    style={{
                      backgroundColor: lighten(0.1, theme.color_hex),
                    }}
                  />
                  <div
                    className="flex w-1/2 h-full rounded-r-lg"
                    style={{ backgroundColor: theme.color_hex }}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full h-28 flex justify-center items-center">
          <Button
            className="p-6 flex items-center justify-center hover:bg-secondary transition-colors duration-200 ease-in-out"
            onClick={handleSave}
            isLoading={isLoading}
            actionType="button-loading"
            disabled={isLoading}
          >
            Salvar alterações
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default CompanieCustom;
