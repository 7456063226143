import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spinner } from '../../../components';
import { ActivitieType } from '../types';
import { ApiActivitieType } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  activitieType: ActivitieType | null;
  companieUserId: number;
}

const RegisterActivitieType: React.FC<registerModalProps> = ({
  activitieType,
  reloadTable,
  setShow,
  show,
  companieUserId,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<ActivitieType, 'id'>>({
    title: activitieType?.title ?? '',
    companie_user_id: companieUserId,
  });
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (activitieType !== null) {
      const res = await ApiActivitieType.updateActivitieType(
        { title: values.title },
        activitieType.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tarefa atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiActivitieType.createActivitieType(values);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tarefa criada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      title: activitieType?.title ?? '',
      companie_user_id: companieUserId,
    });
  }, [activitieType, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={activitieType ? 'Atualizar Tarefa' : 'Criar Tarefa'}
      size="medium"
      minHeight="min-h-[250px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterActivitieType;
