import React, { ReactNode } from 'react';
import maskHelper from '../../../../helpers/mask.helper';

type FrontProps = {
  logo: ReactNode;
  number: string;
  name: string;
  dueDate: string;
  show: boolean;
};

type BackProps = {
  cvv: string;
  show: boolean;
};

interface CardProps {
  logo: ReactNode;
  number: string;
  name: string;
  dueDate: string;
  cvv: string;
  typeCard: string;
}

const Front = ({ logo, number, name, dueDate, show }: FrontProps) => (
  <div
    className={`absolute z-10 p-4 pl-7 w-80 h-40 bg-[#1E2347]/30 mt-3 rounded-xl grid grid-cols-4 transition-all duration-500 ${
      show ? '' : 'opacity-0 [transform:rotateY(-180deg)]'
    }`}
  >
    <div className="col-span-2 mt-4 rounded bg-gray-400 w-9 h-6 flex items-center py-px">
      <div className="bg-gray-200 w-2/3 h-2/3 rounded-r" />
    </div>

    <div className="col-span-2 w-8 justify-self-end">{logo}</div>

    <div className="col-span-4 text-lg text-white opacity-90 font-semibold mt-2">
      {maskHelper.creditCard(number)}
    </div>
    <div className="break-words col-span-3 text-white opacity-90 font-medium uppercase my-auto">
      {name}
    </div>
    <div className="text-white flex flex-col items-end">
      <div className="text-[7px] -mb-1">MONTH/YEAR</div>
      <div className="flex">
        <p className="text-[6px] text-center tracking-tight leading-tight mr-1 place-self-center">
          VALID <br />
          THRU
        </p>
        <p className="text-lg">{dueDate}</p>
      </div>
    </div>
  </div>
);

const Back = ({ cvv, show }: BackProps) => (
  <div
    className={`absolute z-20 transition-all duration-500 ${
      show ? '' : 'opacity-0 [transform:rotateY(180deg)]'
    }  w-80 h-40 bg-[#1E2347]/30 mt-3 rounded-xl flex flex-col`}
  >
    <div className="w-full bg-black h-10 mb-3 mt-7" />
    <div className="text-xs text-white opacity-90 font-semibold ml-7">CVV</div>
    <div className="text-sm text-white opacity-90 font-semibold ml-7">
      {cvv}
    </div>
  </div>
);

const CreditCardAnimation: React.FC<CardProps> = ({
  cvv,
  dueDate,
  logo,
  name,
  number,
  typeCard,
}) => (
  <div className="relative h-40">
    <Front
      show={typeCard === 'F'}
      dueDate={dueDate}
      logo={logo}
      name={name}
      number={number}
    />
    <Back cvv={cvv} show={typeCard === 'B'} />
  </div>
);

export default CreditCardAnimation;
