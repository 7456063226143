import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, isLoadingApplication } from '../../../../components';
import { ToastNotify } from '../../../../components/Toast/toast';
import {
  getItem,
  removeItem,
  setItem,
} from '../../../../helpers/storage.helper';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { changeSession } from '../../api';
import {
  getToken,
  saveToken,
  decodeToken,
  getTokenAccountInformation,
} from '../../../../helpers/token.helper';
import { CurrentUserAccessProps } from '../../types';
import { formatMenuToRoutes } from '../../utils';

interface Props {
  AccessListUser: CurrentUserAccessProps[];
  isLoading: boolean;
}

const LoginOtherCompanie: React.FC<Props> = ({ AccessListUser, isLoading }) => {
  const { setState } = useContext(isLoadingApplication);
  const companyId = getTokenAccountInformation().companie_id;
  const tokenMainAccount = getItem('account_main_bearer_token');
  const { notify } = ToastNotify();
  const navigate = useNavigate();

  const loginCompany = async (id: number) => {
    setState(true);
    const res = await changeSession(id);
    if (res.token) {
      const currentToken = getToken();
      const currentMenu = getItem('menu');
      const currentGenerateContractOpp = getItem('generate_contract_opp');

      setItem('account_main_bearer_token', currentToken);
      setItem('account_main_menu', currentMenu);
      setItem('account_main_generate_contract_opp', currentGenerateContractOpp);

      saveToken(res.token);
      setItem('generate_contract_opp', res.companie.is_generate_contract_opp);
      setItem(
        'menu',
        res.menus.filter((item: any) => item !== null)
      );

      setTimeout(() => {
        setState(false);
        navigate('/');
      }, 1000);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
      setState(false);
    }
  };

  const returnMainCompany = () => {
    setState(true);
    const menuMain = getItem('account_main_menu');
    const mainGenerateContractOpp = getItem(
      'account_main_generate_contract_opp'
    );

    setItem('menu', menuMain);
    const routesUser = formatMenuToRoutes(menuMain);
    setItem('routes_user', routesUser);
    saveToken(tokenMainAccount);
    setItem('generate_contract_opp', mainGenerateContractOpp);

    removeItem('account_main_bearer_token');
    removeItem('account_main_menu');
    removeItem('account_main_generate_contract_opp');

    setTimeout(() => {
      setState(false);
      navigate('/');
    }, 1000);
  };

  return (
    <div className="w-[400px] pt-4 px-6 pb-2 h-fit min-h-[150px] max-h-[208px] bg-white rounded-md border border-gray-300 border-solid overflow-y-auto mt-2">
      {isLoading ? (
        <div className="w-full h-full flex mt-10 justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {typeof tokenMainAccount !== 'undefined' ||
          AccessListUser.length > 0 ? (
            <div className="grid grid-cols-3 gap-3 w-full">
              <p className="text-[11px] font-semibold">Empresa</p>
              <p className="text-[11px] font-semibold">Perfil</p>
            </div>
          ) : (
            <div className="flex w-full h-[126px] h-full justify-center items-center">
              <p className="text-[11px]">Nenhum acesso encontrado</p>
            </div>
          )}

          {AccessListUser.filter(
            (access) => access.companie.id !== companyId
          ).map((access) => (
            <>
              <hr className="w-full h-px bg-gray-300 my-2" />
              <div className="grid grid-cols-3 gap-3 w-full">
                <p className="text-[11px] font-light">{access.companie.name}</p>
                <p className="text-[11px] font-light">
                  {access.profile?.title}
                </p>
                <button type="button" onClick={() => loginCompany(access.id)}>
                  <p className="text-[11px] font-semibold text-primary ">
                    Acessar
                  </p>
                </button>
              </div>
            </>
          ))}
          {typeof tokenMainAccount !== 'undefined' && (
            <>
              <hr className="w-full h-px bg-gray-300 my-2" />
              <div className="grid grid-cols-3 gap-3 w-full">
                <p className="text-[11px] font-light">
                  {decodeToken(tokenMainAccount)?.name} (Matriz)
                </p>
                <p className="text-[11px] font-light">
                  {decodeToken(tokenMainAccount)?.profile.title}
                </p>
                <button type="button" onClick={returnMainCompany}>
                  <p className="text-[11px] font-semibold text-primary">
                    Retornar
                  </p>
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LoginOtherCompanie;
