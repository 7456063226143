import React, { useEffect, useState } from 'react';

import { Button, InputDate, Modal } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ApiContract } from '../api';
import { contractProps } from '../types';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  contract: contractProps;
  reloadTable: () => void;
}
const ChangeDueDate: React.FC<props> = ({
  contract,
  setShow,
  show,
  reloadTable,
}) => {
  const [date, setDate] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const { notify } = ToastNotify();
  const changeDate = async () => {
    setIsLoading(true);
    const res = await ApiContract.changeDueDate(contract.id, { payday: date });
    if (res.updateSubscriptionGalax) {
      notify({
        message: 'Data de vencimento alterada com sucesso!',
        type: 'Success',
      });
      reloadTable();
      setShow(false);
    } else {
      notify({ message: dictionaryError(String(res)), type: 'Error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDate('');
    setModalConfirm(false);
  }, [show]);

  return (
    <Modal
      title="Alterar Vencimento"
      isOpen={show}
      setIsOpen={setShow}
      size="medium"
      minHeight="min-h-[430px]"
    >
      <Modal
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        size="medium"
        minHeight="min-h-[180px]"
        title="Deseja realmente alterar o vencimento desta assinatura?"
      >
        <div className="flex w-full gap-3 justify-center mt-8">
          <Button
            variant="outline-primary"
            actionType="button-simple"
            className="w-64"
            disabled={isLoading}
            onClick={() => setModalConfirm(false)}
          >
            Não
          </Button>
          <Button
            variant="primary-strong"
            actionType="button-loading"
            onClick={changeDate}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Sim
          </Button>
        </div>
      </Modal>

      <div className="rounded px-3 py-5 flex items-center gap-3 w-full h-20 md:h-16 border border-dashed border-red mb-5">
        <IconDictionary
          size={28}
          name="AlertTriangle"
          className="text-red min-w-[20px]"
        />
        <p className="text-xs sm:text-sm">
          Ao alterar, todas as próximas cobranças deste contrato terão um novo
          vencimento.
        </p>
      </div>
      <InputDate
        minDate={new Date()}
        onChange={(e: Date) => {
          if (e !== null) {
            setDate(
              maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
            );
            if (e.getDate() > 28) {
              setError('Data deve estar entre o dia 1 e 28 do mês');
            } else {
              setError('');
            }
          }
        }}
        errorMensage={error}
      />
      <div className="flex w-full gap-3 justify-center mt-10">
        <Button
          variant="outline-primary"
          actionType="button-simple"
          className="w-64"
          onClick={() => setShow(false)}
        >
          Não
        </Button>
        <Button
          variant="primary-strong"
          actionType="button-loading"
          onClick={() => setModalConfirm(true)}
          isLoading={isLoading}
          disabled={isLoading || date === '' || error !== ''}
        >
          Alterar
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeDueDate;
