import { baseApiPrivate } from '../../../api/baseApi';
import {
  filterFinancials,
  downloadExpensesProps,
  attachment,
  FinancialProps,
} from '../types';

async function getFinancials(
  filters: filterFinancials,
  period: string
): Promise<any | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: `companie/financial/filterAdvanced/${period}`,
  });
}

async function seachFinancial(
  key: string,
  skip: number,
  take: number
): Promise<{ data: FinancialProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function getFiles(id: number): Promise<attachment[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/financial/attachment/${id}`,
  });
}

async function createFiles(files: any): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'POST',
    body: files,
    file: true,
    url: `companie/financial/attachment`,
  });
}

async function deleteFiles(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/financial/attachment/${id}`,
  });
}

async function createFinancial(information: any) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: `companie/financial`,
  });
}

async function updateFinancial(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/financial`,
  });
}

async function revertPayedFinancial(id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/financial/revertPayed/${id}`,
  });
}

async function downloadFinancial(
  information: downloadExpensesProps,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/financial/payed/${id}`,
  });
}

async function deleteExpense(id: number, deleteAll: boolean) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/financial/${id}/${deleteAll}`,
  });
}

export const ApiExpenses = {
  getFiles,
  createFiles,
  revertPayedFinancial,
  deleteFiles,
  updateFinancial,
  createFinancial,
  seachFinancial,
  downloadFinancial,
  getFinancials,
  deleteExpense,
};
