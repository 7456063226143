/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal } from '../../../components';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  isLoading: boolean;
  submit: (e: boolean) => void;
}
const ModalDuplicateOpportunity: React.FC<props> = ({
  setShow,
  submit,
  show,
  isLoading,
}) => (
  <Modal
    title="Permitir duplicar oportunidade"
    setIsOpen={setShow}
    isOpen={show}
    minHeight="min-h-[250px]"
  >
    <p className="text-sm ">
      Ao ativar a duplicação pode resultar na criação de oportunidades
      duplicadas para o mesmo contato. Deseja realmente ativar?
    </p>
    <div className="flex gap-3 mt-5">
      <Button
        variant="outline-primary"
        onClick={() => setShow(false)}
        disabled={isLoading}
        actionType="button-loading"
      >
        Não
      </Button>
      <Button
        variant="primary"
        onClick={() => submit(true)}
        disabled={isLoading}
        isLoading={isLoading}
        actionType="button-loading"
      >
        Sim
      </Button>
    </div>
  </Modal>
);

export default ModalDuplicateOpportunity;
