import { useEffect, useState } from 'react';
import { OpportunityProps } from '../../opportunity/types';
import { ApiTransferOpportunity } from '../api';
import { filtersInformationProps } from '../types';

export function useFetchAllOpportunities(key: string, company: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OpportunityProps[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const opportunities =
        await ApiTransferOpportunity.getAllOpportunityByCompanie(company);
      if (typeof opportunities !== 'string') {
        setData(opportunities);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return {
    AllOpportunitiesList: data,
    isLoading,
    updateOpportunities: setData,
    setIsLoading,
  };
}

export function useFetchAllOpportunitiesTransfer(
  key: string,
  company: number,
  period: string,
  filters: filtersInformationProps
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ data: OpportunityProps[]; total: number }>(
    { data: [], total: 0 }
  );

  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const opportunities = await ApiTransferOpportunity.filterOpportunities(
        company,
        period,
        {
          companieUserId: filters.companieUserId,
          funnelId: filters.funnelId,
          opportunityId: filters.opportunityId,
          order: filters.order,
          productId: filters.productId,
          responses_win_lose_id: filters.responses_win_lose_id,
          salesChannelId: filters.salesChannelId,
          skip: filters.skip,
          status: filters.status,
          take: filters.take,
          funnelStepId: filters.funnelStepId,
        }
      );
      if (typeof opportunities !== 'string') {
        setData(opportunities);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [company, period, filters, key]);

  return {
    OpportunitiesList: data,
    isLoading,
    updateOpportunities: setData,
    setIsLoading,
  };
}
