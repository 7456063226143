import React from 'react';

import { Button, Modal } from '..';
import AlertCard from '../Card/alertCard';

interface Props {
  local: string;
  open: boolean;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const Plan: React.FC<Props> = ({ open, local, title, onConfirm, onCancel }) => {
  const description =
    local === 'opportunities'
      ? [
          'Você não possui plano de whatsapp ativo. Deseja realizar contratação?',
        ]
      : [
          'Estamos aguardando a confirmacao do pagamento. Assim que for realizado será desbloqueada a criação de novas instâncias',
        ];

  return (
    <Modal
      size="medium"
      minHeight="min-h-[100px]"
      title={<div>{title}</div>}
      isOpen={open}
      setIsOpen={onCancel}
    >
      <div className="flex justify-center items-center flex-col w-full gap-4">
        <AlertCard message={description[0]} />

        <div className="flex w-full gap-1 mt-5 mx-2 justify-center">
          {local === 'opportunities' && (
            <Button
              type="submit"
              variant="outline-primary"
              actionType="button-loading"
              onClick={onCancel}
            >
              Não
            </Button>
          )}

          {local === 'opportunities' ? (
            <Button
              type="submit"
              actionType="button-loading"
              onClick={onConfirm}
            >
              Sim
            </Button>
          ) : (
            <Button
              type="submit"
              actionType="button-loading"
              onClick={onConfirm}
            >
              Ok!
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Plan;
