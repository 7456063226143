function getTextByStatus(status: string) {
  switch (status) {
    case 'Cancelada':
      return <strong className="text-red">{status}</strong>;
    case 'Não iniciada':
      return <strong className="text-black">{status}</strong>;
    case 'Em disparo':
      return <strong className="text-green">{status}</strong>;
    case 'Terminada':
      return <strong className="text-gray-600">{status}</strong>;
    case 'Pausada':
      return <strong className="text-yellow">{status}</strong>;
    default:
      return <p>{status}</p>;
  }
}

function getTextByStatusInstances(status: string) {
  switch (status) {
    case 'Conectada':
      return <strong className="text-green">{status}</strong>;
    case 'Desconectada':
      return <strong className="text-gray-600">{status}</strong>;
    default:
      return <> </>;
  }
}

function getTextByStatusMessage(status: string) {
  switch (status) {
    case 'Enviado':
      return <p className="text-green">{status}</p>;
    case 'Aguardando Envio':
      return <p className="text-yellow-600">{status}</p>;
    case 'Terminada':
      return <p className="text-gray-600">{status}</p>;

    default:
      return <p>status</p>;
  }
}

function verifyStatusInstance(item: any) {
  const statusEmDisparo = item.campaign.some(
    (elemento: any) => elemento.status === 'Em disparo'
  );

  return statusEmDisparo;
}

export const CampaignUtils = {
  getTextByStatus,
  getTextByStatusInstances,
  getTextByStatusMessage,
  verifyStatusInstance,
};
