import React from 'react';
import { Button, Card } from '../../../../../components';
/* eslint-disable no-unused-vars */

interface props {
  title: string;
  handleReset: () => void;
  finished: boolean;
}

const TitleForm: React.FC<props> = ({ title, handleReset, finished }) => (
  <Card className="pb-4 relative px-4 flex-col items-start mt-10 border-t-8 border-solid rounded-md  animate-slideHeight border-primary">
    <p className="focus:border-b-primary focus:border-b border-solid w-full py-2 font-medium text-2xl leading-tight focus:outline-none focus:shadow-outline">
      {title}
    </p>
    {finished && (
      <>
        <p className="text-justify text-sm mt-5">
          Agradecemos por preencher nosso formulário. Caso queira enviar uma
          nova resposta, sinta-se à vontade. Estamos disponíveis para esclarecer
          dúvidas ou oferecer assistência conforme necessário.
        </p>
        <Button
          actionType="button-simple"
          className="mx-auto mt-8 w-48"
          onClick={handleReset}
        >
          Enviar outra resposta
        </Button>
      </>
    )}
  </Card>
);

export default TitleForm;
