import { useEffect, useState } from 'react';
import { ApiOthersCompanyAccess } from '../api';
import {
  CompanyAccessCompanies,
  CompanyByGroup,
  userByCompany,
} from '../types';

export function useFetchUsersOtherCompanie(companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<userByCompany[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        companyId === -1
          ? []
          : await ApiOthersCompanyAccess.getUsers(companyId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [companyId]);
  return { UsersList: data, isLoading };
}

export function useFetchAccessCompanies(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CompanyAccessCompanies[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        companyId === -1
          ? []
          : await ApiOthersCompanyAccess.getAccess(companyId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key, companyId]);
  return { AccessList: data, isLoadingAccess: isLoading };
}

export function useFetchProfileCompany(companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        companyId === -1
          ? []
          : await ApiOthersCompanyAccess.getProfiles(companyId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [companyId]);
  return { ProfileList: data, isLoading };
}

export function useFetchCompanyByGroup(companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CompanyByGroup[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        companyId === -1
          ? []
          : await ApiOthersCompanyAccess.getCompanyGroup(companyId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [companyId]);
  return { CompanyList: data, isLoading };
}
