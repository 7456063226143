import React from 'react';
import whiteLogo from '../../../assets/png/logo-cor-branca.png';

const Footer: React.FC = () => (
  <div className="h-32 py-2 sm:py-0 sm:h-[80px] 2xl:h-[100px] bg-primary relative z-2">
    <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row px-10 items-center justify-between h-full">
      <h3 className="text-normal text-white text-sm font-base">
        © Todos os direitos reservados.
      </h3>
      <img
        src={whiteLogo}
        alt="white-logo"
        className="justify-self-center w-40"
      />
      <h3 className="justify-self-end text-white text-sm font-base">
        © 2023-{new Date().getFullYear()} Laks Tecnologia
      </h3>
    </div>
  </div>
);

export default Footer;
