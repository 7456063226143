import React, { useState } from 'react';

import { Button, Modal } from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ApiExpenses } from '../api';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  financialId: number;
  reload: (e: string) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const RevertPayedModal: React.FC<registerModalProps> = ({
  show,
  financialId,
  setShow,
  reload,
}) => {
  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    const res = await ApiExpenses.revertPayedFinancial(financialId);
    if (!res.id) {
      reload(`${Math.random()} `);
      notify({ message: dictionaryError(res), type: 'Error' });
      setIsLoading(false);
    } else {
      reload(`${Math.random()} `);
      notify({ message: 'Baixa removida com sucesso', type: 'Success' });
      setIsLoading(false);
      setShow(false);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      title="Remover Baixa"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[280px]"
    >
      <strong className="text-center text-lg">
        Tem certeza que deseja remover a baixa da despesa?
      </strong>
      <p className="text-sm mt-4 mb-10">
        Essa ação não pode ser desfeita, ela apagara os dados da baixa e
        revertera o status da despesa para &quot;Aberta&quot;.
      </p>
      <div className="flex gap-3 w-full justify-center">
        <Button
          variant="primary-strong"
          actionType="button-loading"
          onClick={() => setShow(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="outline-primary"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={Submit}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default RevertPayedModal;
