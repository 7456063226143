import React from 'react';
import maskHelper from '../../../helpers/mask.helper';

interface ReceiptPrintProps {
  row: any[];
}

const ReceiptPrint: React.FC<ReceiptPrintProps> = ({ row }) => {
  const dataRow = row ? row[0] : {};
  const dataToken = row ? row[1] : {};

  const currentData = new Date();

  const day = String(currentData.getDate()).padStart(2, '0');
  const month = String(currentData.getMonth() + 1).padStart(2, '0');
  const year = currentData.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const hours = String(currentData.getHours()).padStart(2, '0');
  const minutes = String(currentData.getMinutes()).padStart(2, '0');

  const formattedTime = `${hours}:${minutes}`;

  const result = dataRow.client_id
    ? dataRow.client
    : dataRow.contract_id
    ? dataRow.contract
    : dataRow.provider_id
    ? dataRow.provider
    : null;

  const name = result.name
    ? result.name
    : result.client_name
    ? result.client_name
    : '';
  const document = result.document
    ? result.document
    : result.client_document
    ? result.client_document
    : '';

  const completeAddress = {
    address: result.address
      ? result.address
      : result.client_address
      ? result.client_address
      : '',
    province: result.province
      ? result.province
      : result.client_address_province
      ? result.client_address_province
      : '',
    city: result.city
      ? result.city
      : result.client_address_city
      ? result.client_address_city
      : '',
    state: result.state
      ? result.state
      : result.client_address_state
      ? result.client_address_state
      : '',
  };

  return (
    <div className="bg-white text-black print:break-after-page">
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-row">
          <img
            src={`${process.env.REACT_APP_API}/${dataToken.companie.path_logo}`}
            alt={dataToken.companie.path_logo.split(' ')[1]}
            className="mb-2 mr-4 mt-6 w-20 h-15"
          />
          <div className="flex-column mt-7">
            <p className="font-bold">
              Franquia:{' '}
              {String(
                dataToken.companie.name ? dataToken.companie.name : ''
              ).toUpperCase()}
            </p>
            <p>
              <span className="font-bold">CNPJ:</span>{' '}
              {dataToken.companie.document ? dataToken.companie.document : ''}
            </p>
          </div>
        </div>
        <div className="text-right">
          <p className="font-bold text-xs">
            Impresso em: {formattedDate} {formattedTime}
          </p>
          <p className="mb-1">
            <span className="font-bold">Endereço:</span>{' '}
            {dataToken.companie.address}
            <br />
            B.{dataToken.companie.province ? dataToken.companie.province : ''}-
            {dataToken.companie.city ? dataToken.companie.city : ''}/
            {dataToken.companie.state ? dataToken.companie.state : ''}
          </p>
        </div>
      </div>
      <hr className="border-t border-zinc-400 mb-4" />
      <p className="text-center font-bold text-lg mb-2">Recibo</p>
      <div className="mb-4">
        <p>
          <span className="font-bold"> Recebemos de: </span>
          {name}
        </p>
        <p>
          <span className="font-bold">CPF: </span> {document}
        </p>
        <p>
          <span className="font-bold">Endereço: </span>
          {`${completeAddress.address} `}

          {`${completeAddress.province} `}

          {`${completeAddress.city} `}

          {`${completeAddress.state} `}
        </p>
        <p>
          <span className="font-bold">Contrato:</span>{' '}
          {dataRow.contract ? dataRow.contract.number : ''}
        </p>
        <p>
          <span className="font-bold">A importância de: </span>
          {dataRow.received_value === '0'
            ? ''
            : maskHelper.formatMoeda(dataRow.received_value)}
        </p>
      </div>
      <table className="w-full border-collapse mb-4">
        <thead>
          <tr className="bg-zinc-200">
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              N.Lancamento
            </th>
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              Ref.
            </th>
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              Vencimento
            </th>
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              Vl.Original
            </th>
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              Vl. Pago
            </th>
            <th className="border-t border-b border-zinc-400 text-center text-sm">
              Dt. Pago
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {dataRow.galax_pay_id}
            </td>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {dataRow.created_at
                .split('T')[0]
                .split('-')
                .reverse()
                .slice(1, 3)
                .join('/')}
            </td>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {dataRow.due_date.split('T')[0].split('-').reverse().join('/')}
            </td>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {maskHelper.formatMoeda(dataRow.value)}
            </td>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {dataRow.received_value === '0'
                ? ''
                : maskHelper.formatMoeda(dataRow.received_value)}
            </td>
            <td className="border-t border-zinc-400 p-2 text-center text-sm">
              {dataRow.received_date
                ? dataRow.received_date
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')
                : ''}
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-sm mb-4 font-bold">
        (*) Este recibo não quita parcelas pagas parcialmente ou valores não
        relacionados acima.
      </p>
      <div>
        <hr className="border-t border-zinc-400 mt-10 ml-40 mr-40 mb-2" />
      </div>
      <p className="text-center font-bold mb-4">ASSINATURA DA EMPRESA</p>
      <hr className="border-t border-zinc-400 mb-4" />
      <p className="text-center font-bold text-lg mb-4 underline">Canhoto</p>
      <div className="flex justify-center">
        <p className="text-xs">
          Recebemos a importância de <span className="font-bold">{name}</span>{' '}
          dia{' '}
          <span className="font-bold">
            {dataRow.received_date
              ? dataRow.received_date
                  .split('T')[0]
                  .split('-')
                  .reverse()
                  .join('/')
              : ''}
          </span>{' '}
          no valor de{' '}
          <span className="font-bold">
            {dataRow.received_value === '0'
              ? ''
              : maskHelper.formatMoeda(dataRow.received_value)}
          </span>
          .
        </p>
      </div>
      <hr className="border-t border-zinc-400 mt-10 ml-40 mr-40" />
      <p className="text-center font-bold mt-2">ASSINATURA DO CLIENTE</p>
    </div>
  );
};
export default ReceiptPrint;
