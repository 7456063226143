/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components';
import Card from '../../../../../components/Card/card';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiDashboard } from '../../api';
import { cardsProps } from '../../types';

interface props {
  companyId: number;
  period: string;
}

const DayIndicators: React.FC<props> = ({ companyId, period }) => {
  const [total, setTotal] = useState<cardsProps>();
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [predicted, setPredicted] = useState<cardsProps>();
  const [expensesPredicted, setExpensesPredicted] = useState<cardsProps>();
  const [expensesPayed, setExpensesPayed] = useState<cardsProps>();
  const [isLoadingPredicted, setIsLoadingPredicted] = useState(false);

  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoadingPredicted(true);
      const predictedGraph = await ApiDashboard.getTotalFinancialProvided(
        companyId,
        period
      );

      if (typeof predictedGraph !== 'string') {
        setPredicted(predictedGraph);
      }
      setIsLoadingPredicted(false);
    };

    const fetchGraphTotal = async () => {
      setIsLoadingTotal(true);
      const res = await ApiDashboard.getTotalReceived(companyId, period);

      if (typeof res !== 'string') {
        setTotal(res);
      }
      setIsLoadingTotal(false);
    };

    const fetchGraphExpensesPayed = async () => {
      setIsLoadingTotal(true);
      const res = await ApiDashboard.getTotalExpenseReceived(companyId, period);

      if (typeof res !== 'string') {
        setExpensesPayed(res);
      }
      setIsLoadingTotal(false);
    };

    const fetchGraphExpensesPredicted = async () => {
      setIsLoadingTotal(true);
      const res = await ApiDashboard.getTotalExpenseProvided(companyId, period);

      if (typeof res !== 'string') {
        setExpensesPredicted(res);
      }
      setIsLoadingTotal(false);
    };
    fetchGraphExpensesPayed();
    fetchGraphExpensesPredicted();
    fetchGraph();
    fetchGraphTotal();
  }, [period]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Faturamento Previsto
              </p>
            </div>

            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-gray-600">
                {maskHelper.formatMoeda(predicted?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">Abertas e recebidas</p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingTotal ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Faturamento Recebido
              </p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-green">
                {maskHelper.formatMoeda(total?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">Recebidas</p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Despesas Previstas
              </p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-gray-600">
                {maskHelper.formatMoeda(expensesPredicted?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">Abertas e pagas</p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">Despesas Pagas</p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-red">
                {maskHelper.formatMoeda(expensesPayed?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">Pagas</p>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default DayIndicators;
