import { useEffect, useState } from 'react';
import { ApiProvider } from '../api';
import { attachment, contacts, ProviderProps } from '../types';

export function useFetchProvider(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ProviderProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies =
        companyId !== -1
          ? await ApiProvider.getProviderByCompanie(companyId)
          : [];
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ProviderList: data, isLoading };
}

export function useFetchAttachments(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies =
        id !== -1 ? await ApiProvider.getProviderAttachments(id) : [];
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AttachmentsList: data, isLoadingAttachmentsList: isLoading };
}

export function useFetchContacts(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<contacts[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const historicData =
        id !== -1 ? await ApiProvider.getProviderContacts(id) : [];
      if (typeof historicData !== 'string') {
        setData(historicData);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { contactList: data, isLoadingContactList: isLoading };
}

export function useFetchAllProducts(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiProvider.getAllProducts();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ProductsList: data, isLoading };
}

export function useFetchAllCategories(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiProvider.getAllCategories();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { CategoryList: data, isLoading };
}
