import React from 'react';
import IconDictionary from '../Icons/icons';

const MissingConfiguration: React.FC = () => (
  <div className="w-full h-screen bg-gray-200/60 flex flex-col items-center pt-20">
    <div className="flex items-center gap-3 mb-2">
      <IconDictionary name="Link invalido" size={36} color="#541554" />
      <h2 className="text-xl font-semibold uppercase">
        Não foi possível acessar o página
      </h2>
    </div>
    <div className="text-center text-sm mt-1">
      Favor entrar em contato com o responsavel e informar: <br />
      <p className="font-semibold">Configuração de automação pendente.</p>
    </div>
  </div>
);

export default MissingConfiguration;
