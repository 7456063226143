import React from 'react';
import { Button } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { ClientProps } from '../../../types';

interface FinancialSummaryProps {
  client?: ClientProps | null;
  nextPage: () => void;
}

const FinancialSummary: React.FC<FinancialSummaryProps> = ({
  client,
  nextPage,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-items-center items-start text-left p-16 rounded-lg max-w-6xl mx-auto mt-10">
    <div className="p-6 bg-gray-50 rounded-lg shadow-sm transition-shadow duration-300 ease-in-out w-full">
      <h1 className="block font-semibold text-md text-gray-800">
        Primeira Compra:{'  '}
        <span className="text-md text-gray-600">
          {maskHelper.formatDateDMY(client?.date_first ?? '')}
        </span>
      </h1>
    </div>
    <div className="p-6 bg-gray-50 rounded-lg shadow-sm transition-shadow duration-300 ease-in-out w-full">
      <h1 className="block font-semibold text-md text-gray-800">
        Última Compra:{'  '}
        <span className="text-md text-gray-600">
          {maskHelper.formatDateDMY(client?.date_last ?? '')}
        </span>
      </h1>
    </div>
    <div className="p-6 bg-gray-50 rounded-lg shadow-sm transition-shadow duration-300 ease-in-out w-full">
      <h1 className="block font-semibold text-md text-gray-800">
        Quantidade de Compra:{'  '}
        <span className="text-md text-gray-600">{client?.qtde ?? ''}</span>
      </h1>
    </div>
    <div className="p-6 bg-gray-50 rounded-lg shadow-sm transition-shadow duration-300 ease-in-out w-full">
      <h1 className="block font-semibold text-md text-gray-800">
        Total de Compra:{'  '}
        <span className="text-md text-gray-600">
          {maskHelper.formatMoeda(client?.total ?? '')}
        </span>
      </h1>
    </div>
    <div className="col-span-1 md:col-span-2 flex justify-center mt-6">
      <Button onClick={() => nextPage()} actionType="button-loading">
        Finalizar
      </Button>
    </div>
  </div>
);

export default FinancialSummary;
