import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../../assets/png/logo_LP.png';

import {
  AddressCard,
  FooterCheckout,
  PaymentCard,
  RegisterCard,
} from '../components';
import { CreateAccountProps } from '../types';
import { decryptData } from '../../../helpers/security';

const PlugAndPlay: React.FC = () => {
  const navigate = useNavigate();
  const [planInfo, setPlanInfo] = useState({
    id: -1,
    name: '',
    value: '',
    qty: 0,
    is_trial: false,
  });

  const [done, setIsDone] = useState(false);

  const [currentCard, setCard] = useState(0);

  const [responseData, setResponseData] = useState<CreateAccountProps | null>(
    null
  );

  useEffect(() => {
    const fetchPlans = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const data = queryParams.get('data');
      if (data) {
        const decr = decryptData(decodeURIComponent(data));
        const plan: any = JSON.parse(decr);
        setPlanInfo({
          ...plan,
        });
      } else {
        navigate('/invalid-link');
      }
    };
    fetchPlans();
  }, []);

  return (
    <div className="w-full min-h-screen bg-[#fff] flex flex-col justify-between">
      <div className="py-6 w-full  bg-[#1E2347] flex justify-center">
        <img alt="logo-leadsim" src={logo} className="cursor-pointer h-16" />
      </div>
      <strong className="mx-auto text-4xl my-16 text-[#02a2c4]">
        {planInfo.name}
      </strong>
      <div className="xl:pt-0 flex flex-wrap xl:flex-nowrap gap-6 justify-center max-w-7xl mx-auto px-5 lg:px-3 xl:px-0">
        <RegisterCard
          done={done}
          planId={Number(planInfo.id)}
          documentCompany=""
          setCardNumber={setCard}
          companyId={currentCard}
          setResponseData={setResponseData}
        />
        <AddressCard
          setCardNumber={setCard}
          personalInform={responseData}
          done={done}
          setIsDone={setResponseData}
          isDisabled={currentCard < 1}
        />
        <PaymentCard
          done={done}
          setIsDone={setIsDone}
          planInfo={planInfo}
          personalInform={responseData}
          isDisabled={currentCard < 2}
        />
      </div>

      <FooterCheckout />
    </div>
  );
};

export default PlugAndPlay;
