import React, { useState } from 'react';
import { useFetchAnswerByOpportunity } from '../hooks';
import { OptionsSelectProp } from '../../../components/Select/select';
import SelectSearchForms from './formSelect';
import IconDictionary from '../../../components/Icons/icons';
import { CopyLink, getUrlPlatform } from '../../../helpers/utils.helper';
import {
  QuestionAnswersProps,
  ResponseData,
  SearchFormQuestion,
} from '../types';
import maskHelper from '../../../helpers/mask.helper';
import ModalQuestionsAnswers from './modalQuestionAnswers';
import { Table } from '../../../components';

interface props {
  opportunityId: number;
  companyId: number;
}

function combineQuestionsAndResponses(
  questions: any[],
  responses: any[]
): any[] {
  return questions.map((question) => {
    const response = responses.find(
      (r) => r.search_form_question_id === question.id
    );
    const combinedQuestion = {
      ...question,
      response: response
        ? response.response_search_form_questions_options
        : null,
      options: response ? question.search_form_questions_options : null,
    };

    return combinedQuestion;
  });
}

const FormsAnswersList: React.FC<props> = ({ opportunityId, companyId }) => {
  const [value, setValue] = useState<null | OptionsSelectProp>(null);
  const [currentData, setCurrentData] = useState<null | QuestionAnswersProps[]>(
    null
  );
  const [showModal, setShowModal] = useState(false);

  const [mapOptionsForms, setMapOptionsForms] = useState<
    Record<string, { name: string; questions: SearchFormQuestion[] }>
  >({});
  const { FormAnswersByOpportunity } =
    useFetchAnswerByOpportunity(opportunityId);
  const columns = [
    { key: 'id', name: 'ID', cell: (row: ResponseData) => row.id },
    {
      key: 'created_at',
      name: 'Data - Horário da resposta',
      cell: (row: ResponseData) => (
        <div>
          {maskHelper.formatDateDMY(row.created_at)} -{' '}
          {row.created_at.slice(11, -5)}
        </div>
      ),
    },

    {
      key: 'search_form_id',
      name: 'Formulário',
      cell: (row: ResponseData) => mapOptionsForms[row.search_form_id]?.name,
    },
  ];

  const filterTable = () => {
    if (value) {
      return FormAnswersByOpportunity.filter(
        (item) => item.search_form_id === value.value
      );
    }
    return FormAnswersByOpportunity;
  };
  return (
    <div>
      {currentData && (
        <ModalQuestionsAnswers
          data={currentData}
          setShow={setShowModal}
          show={showModal}
        />
      )}
      <div className="flex flex-col w-full">
        <SelectSearchForms
          setValue={setValue}
          value={value}
          setHashMap={setMapOptionsForms}
        />
        {value && (
          <div className="w-full flex gap-3 items-center">
            <a
              target="_blank"
              href={`${getUrlPlatform()}form/opportunity/${companyId}/${
                value.value
              }/${opportunityId}`}
              className="text-xs hover:text-blue-400 delay-100 transition duration-100"
              rel="noreferrer"
            >
              {getUrlPlatform()}form/opportunity/{companyId}/{value.value}
              {opportunityId}
            </a>
            <IconDictionary
              name="Link"
              className="text-blue-400 cursor-pointer"
              size={28}
              onClick={() =>
                CopyLink(
                  `${getUrlPlatform()}form/opportunity/${companyId}/${
                    value.value
                  }/${opportunityId}`
                )
              }
            />
          </div>
        )}
      </div>

      <Table
        onRowClick={(e: ResponseData) => {
          setCurrentData(
            combineQuestionsAndResponses(
              mapOptionsForms[e.search_form_id].questions,
              e.response_search_form_questions
            )
          );
          setShowModal(true);
        }}
        isLoading={false}
        columns={columns}
        data={filterTable()}
        scrollTable
        fixedHeaderScrollHeight="272px"
      />
    </div>
  );
};

export default FormsAnswersList;
