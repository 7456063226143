import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';

/* eslint-disable no-unused-vars */
interface StyleProps {
  errorMensage?: string;
  title?: string;
  onChange?: (e: any) => void;
  defaultValue?: Date;
  minDate?: Date;
  disabled?: boolean;
  className?: string;
  variant?: 'orange' | 'black';
}

function isAValidDate(date: Date) {
  try {
    if (!Number.isNaN(date.getTime())) return true;
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const InputDate: React.FC<StyleProps> = ({
  errorMensage,
  title,
  onChange,
  defaultValue,
  disabled,
  className,
  variant = 'orange',
  minDate,
}: StyleProps) => {
  const [currentValue, setCurrentValue] = useState<Date | null>(null);
  useEffect(() => {
    setCurrentValue(
      defaultValue ? (isAValidDate(defaultValue) ? defaultValue : null) : null
    );
  }, []);

  useEffect(() => {
    if (defaultValue && isAValidDate(defaultValue)) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <div className={classNames(['relative mb-1.5 w-full', className])}>
      <p className={variant === 'orange' ? 'text-sm' : 'text-sm font-semibold'}>
        {title}
      </p>
      <DatePicker
        openCalendarOnFocus
        format="dd/MM/yyyy"
        minDate={minDate}
        disabled={disabled}
        className={
          disabled
            ? 'input_disabled'
            : errorMensage
            ? 'input_date_error'
            : variant === 'orange'
            ? 'input_date_component'
            : 'input_date_component_black'
        }
        onChange={(e: any) => {
          setCurrentValue(e);
          if (onChange) onChange(e);
        }}
        value={currentValue}
      />
      <p className="text-red text-xs absolute mt-0.5">{errorMensage}</p>
    </div>
  );
};

export default InputDate;
