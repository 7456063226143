import { baseApiPrivate } from '../../../api/baseApi';
import { Funnel, StepCreateUpdate } from '../types';

async function getFunnelByCompanie(company: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/funnel/${company}`,
  });
}

async function getFunnelByProfileId(userId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/funnel/filterProfileUser/${userId}`,
  });
}

async function getStepByFunnel(funnelId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/funnel/step/funnel/${funnelId}`,
  });
}

async function createStepFile(payload: any): Promise<string | { id: number }> {
  return baseApiPrivate({
    body: payload,
    file: true,
    method: 'POST',
    url: 'companie/funnel/step/upload',
  });
}

async function createFunnel(e: Funnel) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/funnel',
    body: e,
  });
}

async function updateFunnel(e: Omit<Funnel, 'companie_user_id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/funnel/${id}`,
    body: e,
  });
}

async function deleteFunnel(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/funnel/${id}`,
  });
}

async function createFunnelStep(e: StepCreateUpdate) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/funnel/step',
    body: e,
  });
}

async function updateStep(
  e: { title: string; activities: number[]; obs: string | null },
  id: number
) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/funnel/step/${id}`,
    body: e,
  });
}

async function updateOrderStep(e: { new_order: number[] }, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/funnel/step/order/${id}`,
    body: e,
  });
}

async function deleteStep(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/funnel/step/${id}`,
  });
}

export const ApiFunnel = {
  getFunnelByCompanie,
  getStepByFunnel,
  getFunnelByProfileId,
  createFunnel,
  createFunnelStep,
  createStepFile,
  updateStep,
  updateFunnel,
  updateOrderStep,
  deleteStep,
  deleteFunnel,
};
