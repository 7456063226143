import React from 'react';

import { Link } from 'react-router-dom';
import { CgMenuLeft } from 'react-icons/cg';
import logo from '../../../assets/png/logo-cor-branca.png';

import '../styles/header.css';

/* eslint-disable no-unused-vars */
interface props {
  active: boolean;
  setActive: (e: boolean) => void;
  outsidePage?: boolean;
  companyLogo?: string;
}

const Header: React.FC<props> = ({
  setActive,
  active,
  outsidePage,
  companyLogo = logo,
}) => (
  <header className="containerHeader overflow-y-hidden h-16">
    <CgMenuLeft
      size={50}
      color="#fff"
      className="absolute l-0 lg:hidden"
      onClick={() => setActive(true)}
    />

    <Link to="/" className="w-full lg:w-fit flex justify-center">
      <img
        alt="logo-leadsim"
        src={companyLogo}
        className="cursor-pointer h-10 sm:h-12"
      />
    </Link>
  </header>
);

export default Header;
