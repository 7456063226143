import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import ButtonRounded from '../button';
import { accessCompanie } from '../../../admin/company/utils';
import { CopyLink } from '../../../../helpers/utils.helper';
import IconDictionary from '../../../../components/Icons/icons';

interface props {
  selected: { name: string; value: string };
  done: boolean;
  link: {
    card: null | { email: string };
    boleto: null | { barcode: string; pdf: string };
    pix: null | {
      code: string;
    };
  };
}

const FinishedPayment: React.FC<props> = ({ link, selected, done }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {selected.name === 'Boleto' && link.boleto !== null && done && (
        <div className="flex flex-col mt-3">
          <p className="text-xs">
            Aponte a camera para o código de barras para efetuar o pagamento ou
            faça o download do pdf.
          </p>
          <div className="w-full flex items-center justify-center">
            <Barcode value={link.boleto.barcode} />
          </div>

          <button
            type="button"
            onClick={() => {
              if (link.boleto) CopyLink(link.boleto.barcode);
            }}
            className="text-xs text-gray-600 mb-2"
          >
            <IconDictionary name="Copiar" /> Copiar Código de Barras
          </button>
          <a
            target="_blank"
            className="text-xs mx-auto text-blue-400 font-medium mb-3 mt-1"
            href={link.boleto.pdf}
            rel="noreferrer"
          >
            Fazer Download do PDF do Boleto
          </a>
        </div>
      )}

      {selected.name === 'Pix' && link.pix !== null && done && (
        <div className="flex flex-col gap-3 mt-3">
          <p className="text-xs mb-2">
            Aponte a camera para o QR Code para efetuar o pagamento ou copie o
            código abaixo.
          </p>
          <div className="mx-auto">
            <QRCodeSVG value={link.pix.code} />
          </div>

          <button
            type="button"
            onClick={() => {
              if (link.pix) CopyLink(link.pix.code);
            }}
            className="break-words text-xs text-gray-600 gap-2"
          >
            <IconDictionary name="Copiar" /> {link.pix.code}
          </button>
        </div>
      )}

      {selected.value === 'creditcard' && link.card !== null && done && (
        <div className="text-sm mt-5 text-center">
          <p>
            Pagamento realizado com sucesso! Clique no botão abaixo para acessar
            a plataforma.
          </p>
          <ButtonRounded
            isLoading={isLoading}
            onClick={() => {
              if (link.card) accessCompanie(link.card.email, setIsLoading);
            }}
          >
            Acessar a plataforma
          </ButtonRounded>
        </div>
      )}
    </>
  );
};

export default FinishedPayment;
