import { ApiWhatsInstances } from '../api';
import { ResultPlans } from '../types';

export const formatInstance = async () => {
  const data = await ApiWhatsInstances.getUserPlans();

  const resultPlans: ResultPlans = data[data.length - 1];

  if (resultPlans) {
    const verifyStatusPlans = {
      ...resultPlans,
      status: resultPlans.status,
      disable: !!(
        resultPlans.status === 'Inadimplente' ||
        resultPlans.status === 'Cancelado' ||
        data.length === 0
      ),
      disablePurchase: resultPlans.status === 'Ag.Pagamento',
      financial: resultPlans.financial,
    };

    return verifyStatusPlans;
  }

  return null;
};

const formatNumber = (value: string) => {
  const result = `${value.substring(2, 4)}9${value.substring(4, value.length)}`;
  return result;
};

export const fixNumberInstance = (data: any[]) =>
  data.length > 0
    ? data
        .filter((item) => item.status !== 'Excluída')
        .map((items: any) => ({
          ...items,
          whatsapp_number: formatNumber(items.whatsapp_number),
        }))
    : [];

export const normalizeColors = (status: string, action: string) => {
  if (status === 'Inativa') {
    return '#ddd';
  }

  if (status === 'Conectada' && action === 'update') {
    return '#FF9C41';
  }

  if (
    (status === 'Conectada' ||
      status === 'Criada' ||
      status === 'Conectando...' ||
      status === 'Desconectada') &&
    action === 'delete'
  ) {
    return '#DA3C3C';
  }

  if (
    (status === 'Criada' ||
      status === 'Conectando...' ||
      status === 'Desconectada') &&
    action === 'update'
  ) {
    return '#303030';
  }

  return '#ddd';
};
