import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Attachments, Button } from '../../../../components';
import Modal from '../../../../components/Modal/Modal';
import { ApiExpenses } from '../../api';
import { CreateUpdateModal } from '../../types';
import { ToastNotify } from '../../../../components/Toast/toast';
import CreateExpenses from './components/generalData';
import { useFetchAttachments } from '../../hooks';
import { useFetchClientDropdown } from '../../../clients/hooks';

const header = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
  {
    id: 1,
    title: 'Anexos',
    page: 1,
  },
];
const headerCreate = [
  {
    id: 0,
    title: 'Dados Pessoais',
    page: 0,
  },
];

const RegisterExpenses: React.FC<CreateUpdateModal> = ({
  show,
  setShow,
  registerPage,
  type,
  setRegisterPage,
  reloadTable,
  setProviderList,
  expense,
  companyUserId,
  accountOptions,
  providerOptions,
  notEditable,
}) => {
  const [expenseId, setExpenseId] = useState(-1);
  const [key, setKey] = useState(`${Math.random()} attachments`);
  const { AttachmentsList, isLoadingAttachmentsList } = useFetchAttachments(
    key,
    expense?.id ?? -1
  );
  const take = 9999999999;
  const skip = 0;
  const [inputClient, setinputClient] = useState('');
  const { ClientsList, isLoadingClientsDropDown } = useFetchClientDropdown(
    '',
    skip,
    take,
    inputClient
  );

  const clientOptions = ClientsList.data.map((item) => ({
    label: item.name,
    value: item.id,
    fullData: item,
  }));

  const { notify } = ToastNotify();
  const nextPage = () => {
    if (
      registerPage.page <
      (expense
        ? notEditable
          ? headerCreate.length
          : header.length
        : headerCreate.length) -
        1
    ) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
      reloadTable(`${Math.random()} save`);
    } else {
      reloadTable(`${Math.random()} save`);
      setShow(false);
    }
  };

  const getTitleModal = () => {
    if (expense) {
      if (expense.type === 'R') {
        return 'Informações Receita';
      }
      if (expense.received_date !== null) {
        return 'Informações Despesa';
      }
      return 'Atualizar Despesa';
    }
    return type === 'R' ? 'Cadastrar Receita' : 'Cadastrar Despesa';
  };

  useEffect(() => {
    setKey(`${Math.random()} files`);
  }, [show]);

  return (
    <Modal
      title={getTitleModal()}
      isOpen={show}
      setIsOpen={() => {
        reloadTable(`${Math.random()} close`);
        setShow(false);
      }}
      size="4xlarge"
      minHeight="min-h-[735px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 md:flex gap-2 mt-9 ">
          {(expense ? header : headerCreate).map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                expense !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => expense !== null && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        <div>
          {registerPage.page === 0 && (
            <CreateExpenses
              isLoadingClient={isLoadingClientsDropDown}
              clientsOptions={clientOptions}
              setInputClients={setinputClient}
              type={type}
              reloadProvidersList={setProviderList}
              onePage={expense === null}
              notEditable={notEditable}
              accountOptions={accountOptions}
              providerOptions={providerOptions}
              setId={setExpenseId}
              notify={notify}
              nextPage={nextPage}
              defaultValue={expense}
              id={companyUserId}
            />
          )}

          {expense && registerPage.page === 1 && (
            <Attachments
              AttachmentsList={AttachmentsList}
              apiFunction={ApiExpenses.createFiles}
              deleteApiFunction={ApiExpenses.deleteFiles}
              id={expense?.id ?? expenseId}
              keyFile="financial_id"
              updateTable={setKey}
              isLoadingTable={isLoadingAttachmentsList}
            />
          )}
        </div>
        <div className="flex w-full gap-2 justify-center mt-9">
          {expense &&
            header.map((item) =>
              item.page === registerPage.page ? (
                <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                  {' '}
                </div>
              ) : (
                <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                  {' '}
                </div>
              )
            )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterExpenses;
