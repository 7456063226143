import React, { useEffect, useState } from 'react';
import { Funnel, FunnelList } from '../types';
import { ApiFunnel } from '../api';
import { dictionaryError } from '../../../helpers/utils.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../components';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  funnel: FunnelList | null;
  companieUserId: number;
  optionsProfile: { label: string; value: number }[];
}

const RegisterFunnel: React.FC<registerModalProps> = ({
  funnel,
  reloadTable,
  setShow,
  show,
  companieUserId,
  optionsProfile,
}) => {
  const fullProfileOptions = [
    {
      label: 'Todos',
      value: 0,
    },
  ].concat(optionsProfile);
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<Funnel, 'id'>>({
    companie_user_id: funnel?.companie_user_id ?? companieUserId,
    profiles: funnel?.funnel_profile.map((item) => item.profile_id) ?? [],
    title: funnel?.title ?? '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (funnel !== null) {
      const res = await ApiFunnel.updateFunnel(
        { profiles: values.profiles, title: values.title },
        funnel.id
      );
      if (res.id) {
        notify({
          message: 'Funil de vendas atualizado com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
      setIsLoading(false);
    } else {
      const res = await ApiFunnel.createFunnel(values);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Funil de vendas criado com sucesso',
          type: 'Success',
        });
        await ApiFunnel.createFunnelStep({
          funnel_id: res.id,
          title: 'NOVO LEAD',
          activities: [],
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValues({
      companie_user_id: funnel?.companie_user_id ?? companieUserId,
      profiles: funnel?.funnel_profile.map((item) => item.profile_id) ?? [],
      title: funnel?.title ?? '',
    });
  }, [funnel, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={funnel ? 'Atualizar Funil de vendas' : 'Criar Funil de vendas'}
      size="2xlarge"
      minHeight="min-h-[400px]"
    >
      <div className="flex flex-col justify-center gap-4">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <SelectComponent
          title="Perfis"
          maxOptionsHeight="150px"
          value={fullProfileOptions.filter(
            (item) => values.profiles.includes(item.value) === true
          )}
          closeMenuOnSelect={false}
          isMulti
          options={
            values.profiles.length === optionsProfile.length
              ? optionsProfile
              : fullProfileOptions
          }
          onChange={(e: any[]) => {
            if (e.find((item) => item.label === 'Todos')) {
              setValues({
                ...values,
                profiles: optionsProfile.map((profile) => profile.value),
              });
            } else {
              setValues({
                ...values,
                profiles: e.map((profile) => profile.value),
              });
            }
          }}
        />
        <Button
          disabled={
            values.title.length === 0 ||
            values.profiles.length === 0 ||
            isLoading
          }
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterFunnel;
