import React, { useState } from 'react';
import { ToastNotify } from '../../../components/Toast/toast';
import MainPage from '../../main/components/mainPage';
import RegisterUser from '../components/register';
import { useFetchUsersCompanie } from '../hooks';
import { UserProps } from '../types';
import maskHelper from '../../../helpers/mask.helper';
import { DeleteModal, Switch } from '../../../components';
import { ApiUsers } from '../api';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { useFetchProfile } from '../../admin/profile/hooks';
import { ChangeStatusUser } from '../utils';

const Users: React.FC = () => {
  const accountProps = getTokenAccountInformation();
  const companyId = accountProps.companie_id;
  const { notify } = ToastNotify();
  const { ProfileList } = useFetchProfile('key');
  const [key, setKey] = useState('');
  const { UsersList, isLoading, setIsLoading } = useFetchUsersCompanie(
    key,
    companyId
  );
  const [user, setUser] = useState<UserProps | null>(null);

  const [showDelete, setShowDelete] = useState(false);
  const [modalCreateUpdateUser, setModalCreateUpdateUser] = useState(false);
  const [input, setInput] = useState('');
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: UserProps) => row.name,
    },
    {
      name: 'CPF/CNPJ',
      key: 'cpfCnpj',
      selector: (row: UserProps) => row.document,
      cell: (row: UserProps) => maskHelper.cnpjCpf(row.document ?? ''),
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: UserProps) => row.whatsapp,
      cell: (row: UserProps) => maskHelper.phone(row.whatsapp ?? ''),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: UserProps) => row.email,
      hide: 800,
    },
    {
      name: 'Status',
      cell: (row: UserProps) => (
        <Switch
          state={row.is_active}
          onChange={() =>
            ChangeStatusUser(row.id, !row.is_active, row, setIsLoading, setKey)
          }
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return UsersList;
    }
    return UsersList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.email.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        (item.document &&
          item.document.toLowerCase().indexOf(input.toLowerCase()) > -1) ||
        (item.whatsapp &&
          item.whatsapp.toLowerCase().indexOf(input.toLowerCase()) > -1)
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Usuários"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalCreateUpdateUser}
        setRegisterPage={() => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setUser(null);
        }}
        onRowClick={(e: UserProps) => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setUser(e);
          setModalCreateUpdateUser(true);
        }}
      />
      {user && (
        <DeleteModal
          apiFunction={ApiUsers.deleteUser}
          type="Usuário"
          article="O"
          notify={notify}
          updateTable={() => setKey('delete')}
          close={() => setShowDelete(false)}
          id={user.id}
          name={user.name}
          open={showDelete}
        />
      )}

      <RegisterUser
        setUser={setUser}
        isFranchise={accountProps.companie?.is_franchise ?? false}
        ProfileList={ProfileList}
        companyId={accountProps.companie_id}
        user={user}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalCreateUpdateUser}
        show={modalCreateUpdateUser}
      />
    </>
  );
};

export default Users;
