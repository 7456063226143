import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
}
const ModalHasNoPlan: React.FC<props> = ({ setShow, show }) => {
  const navigate = useNavigate();
  return (
    <Modal
      setIsOpen={setShow}
      isOpen={show}
      title=""
      size="medium"
      minHeight="min-h-[300px]"
      header={false}
    >
      <div className="flex gap-2 items-center mb-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Plano Inexistente
        </h3>
        <IconDictionary name="AlertCircle" className="text-yellow" size={24} />
      </div>

      <p className="text-sm mb-7">
        Não foi encontrado nenhum plano de WhatsApp para essa empresa. Deseja
        contratar um plano?
      </p>

      <div className="flex gap-3 mx-auto">
        <Button
          className="w-56"
          variant="outline-primary"
          onClick={() => navigate('/')}
        >
          Sair
        </Button>
        <Button
          className="w-56"
          variant="primary-strong"
          onClick={() => navigate('/instances')}
        >
          Contratar Plano
        </Button>
      </div>
    </Modal>
  );
};

export default ModalHasNoPlan;
