import React, { useEffect, useState } from 'react';
import maskHelper from '../../../../helpers/mask.helper';
import {
  ContactProps,
  FilterAdvancedContactsProps,
} from '../../../contacts/types';
import {
  dictionaryError,
  getContrastColorWithOpacity,
} from '../../../../helpers/utils.helper';
import { useFetchTableContactFilters } from '../../../contacts/hooks';
import {
  Button,
  FilterDateSelect,
  Input,
  SelectComponent,
} from '../../../../components';
import TableWithPaginationBack from '../../../../components/Table/tableWithPaginationBack';
import { OptionsSelectProp } from '../../../../components/Select/select';
import SelectWhatsappTag from '../../../whatsappTag/components/select';
import { ApiWhatsappCampaign } from '../../api';
import { ApiContact } from '../../../contacts/api';
import { whatsappCampaignProps } from '../../types';
import { monthsOptions } from '../../../../constants';
import IconDictionary from '../../../../components/Icons/icons';
import { useFetchFunnel, useFetchStep } from '../../../funnel/hooks';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface contactList {
  campaign: null | whatsappCampaignProps;
  companieId: number;
  listContact: number[];
  setListContact: (e: number[]) => void;
  campaignOptions: { label: string; value: number }[];
  nextPage: () => void;
  notify: any;
  setInputSelect: (e: string) => void;
  isLoadingWhatsappDropdown: boolean;
  isEditable: boolean;
}

const InputRange: React.FC<{
  type: string;
  placeholder: string;
  onChange: (e: any) => void;
  onChangeEnd: (e: any) => void;
  defaultValueStart?: string | number;
  defaultValueEnd?: string | number;
  onBlur?: (e: any) => void;
  onBlurEnd?: (e: any) => void;
  errorMen?: string;
}> = ({
  onChange,
  placeholder,
  type,
  defaultValueEnd: valueEnd,
  defaultValueStart: valueStart,
  onChangeEnd,
  onBlur,
  onBlurEnd,
  errorMen,
}) => {
  switch (type) {
    case 'between':
      return (
        <div className="flex">
          <Input
            defaultValue={valueStart}
            placeholder={placeholder}
            variant="outline-orange"
            className="rounded-tr-none rounded-br-none"
            onChange={onChange}
            onBlur={onBlur}
          />
          <p className="mt-1.5 bg-primary min-w-[30px] h-[41.6px] flex items-center justify-center text-xs text-white">
            Até
          </p>
          <Input
            defaultValue={valueEnd}
            placeholder={placeholder}
            variant="outline-orange"
            className="rounded-tl-none rounded-bl-none"
            onChange={onChangeEnd}
            errorMensage={errorMen}
            onBlur={onBlurEnd}
          />
        </div>
      );

    case 'gte':
    case 'lte':
      return (
        <div className="flex">
          <Input
            defaultValue={valueStart}
            placeholder={placeholder}
            variant="outline-orange"
            onChange={onChange}
          />
        </div>
      );
    case 'disabled':
      return (
        <div className="flex">
          <Input placeholder={placeholder} variant="outline-orange" disabled />
        </div>
      );
    default:
      return <> </>;
  }
};

const ContactsList: React.FC<contactList> = ({
  campaign,
  companieId,
  listContact,
  campaignOptions,
  isLoadingWhatsappDropdown,
  notify,
  isEditable,
  setListContact,
  nextPage,
  setInputSelect,
}) => {
  const [originalValues, setOriginalValues] = useState<number[]>([]);
  const [newContacts, setNewContacts] = useState<number[]>([]);
  const [removedCont, setRemovedCont] = useState<number[]>([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState<boolean>(false);
  const [isFirstAccess, setIsFirstAccess] = useState<boolean>(true);
  const [menError, setMenError] = useState({ qtd: '', value: '', ticket: '' });

  const [filterDate, setFilterDate] = useState('all');

  const [currentPage, setCurrentPage] = useState(1);

  const [skip, setSkip] = useState(0);

  const take = 20;

  const [filter, setFilter] = useState<FilterAdvancedContactsProps>({
    tags: [],
    campaignId: campaign ? [{ label: campaign.title, value: campaign.id }] : [],
    companieId,
    is_whatsapp: true,
  });

  const [inputContact, setInputContact] = useState('');
  const [totalSelected, setTotalSelected] = useState(0);
  const [totalSelectedFixed, setTotalSelectedFixed] = useState(0);
  const [key, setKey] = useState('');

  const [isHover, setIsHover] = useState(-1);
  const [funnelId, setFunnelId] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [stepId, setStepId] = useState<number | null>(null);

  const { ContactList, isLoading } = useFetchTableContactFilters(
    key,
    companieId,
    skip,
    take,
    inputContact,
    filter.tags,
    filter.campaignId,
    filter,
    filterDate,
    funnelId?.value,
    stepId
  );

  const accountInformation = getTokenAccountInformation();
  const { FunnelsList } = useFetchFunnel('key', accountInformation.companie_id);
  const optionsFunnel = FunnelsList.map((user) => ({
    label: user.title,
    value: user.id,
  }));

  const { StepList } = useFetchStep('', funnelId?.value ?? -1);
  const optionsSteps = StepList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const AddRemoveIdList = (id: number) => {
    if (listContact.includes(id)) {
      setListContact(listContact.filter((item) => item !== id));
    } else {
      setListContact([...listContact, id]);
    }
  };

  function unifyArraysNoRepetition(array1: any[], array2: any[]) {
    const arrayConcat = array1.concat(array2);

    const conjuntoUnico = new Set(arrayConcat);

    const arraySemRepeticao = Array.from(conjuntoUnico);

    return arraySemRepeticao;
  }

  const toggleContactSelected = (contactID: number) => {
    const index = newContacts.indexOf(contactID);

    if (index === -1) {
      setNewContacts([...newContacts, contactID]);
    } else {
      setNewContacts(newContacts.filter((item) => item !== contactID));
    }
  };

  const toggleContactRemoved = (contactID: number) => {
    const index = removedCont.indexOf(contactID);

    if (index === -1) {
      setRemovedCont([...removedCont, contactID]);
    } else {
      setRemovedCont(removedCont.filter((item) => item !== contactID));
    }
  };

  const handleSearch = () => {
    setSkip(0);
    setKey(String(Math.random()));
  };

  const handleClearSearch = () => {
    setInputContact('');
    setFilter({
      campaignId: [],
      companieId,
      tags: [],
      is_whatsapp: true,
    });
    setStepId(null);
    setFunnelId(null);
    setKey(String(Math.random()));
  };

  const saveContacts = async () => {
    setIsLoadingContacts(true);
    if (campaign && campaign.status === 'Não iniciada') {
      const allContactsIds = await ApiContact.getContactByCompanyFilter(
        {
          skip: 0,
          take: 99999999999999,
          companie_id: companieId,
          tags: [],
          campaign_id: [campaign.id],
        },
        'all'
      );

      if (typeof allContactsIds === 'string') {
        setIsLoadingContacts(false);
        return false;
      }

      const allIds = allContactsIds.data.map((item) => item.id);
      const removeIds = allIds.filter((elem) => !removedCont.includes(elem));
      const finalIds = unifyArraysNoRepetition(removeIds, newContacts);

      const res = await ApiWhatsappCampaign.updateWhatsappCampaign(
        {
          companie_whatsapp_instance_id: campaign.companie_whatsapp_instance_id,
          companie_whatsapp_message_default_id:
            campaign.companie_whatsapp_message_default_id,
          person_ids: finalIds,
          title: campaign.title,
        },
        campaign.id
      );
      if (typeof res === 'string') {
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
        setIsLoadingContacts(false);
        return false;
      }
      notify({
        message: 'Contatos da campanha atualizados com sucesso!',
        type: 'Success',
      });
      nextPage();
      setIsLoadingContacts(false);
      return true;
    }
    if (campaign) {
      nextPage();
      return true;
    }

    const allList = unifyArraysNoRepetition(newContacts, listContact);
    setListContact(allList);
    setNewContacts([]);
    nextPage();

    setIsLoadingContacts(false);
    return false;
  };

  const handleSelectAll = () => {
    const ids: number[] = ContactList.data.map((c) => c.id);
    if (campaign) {
      if (
        listContact.length === ContactList.data.length &&
        ContactList.data.length !== 0
      ) {
        ContactList.data.forEach((item) => {
          const inRemovedElements = removedCont.findIndex(
            (elem) => elem === item.id
          );

          const inCampaignElem = originalValues.findIndex(
            (elem) => elem === item.id
          );

          if (inCampaignElem !== -1 && inRemovedElements === -1) {
            removedCont.push(item.id);
          }
        });
        setListContact([]);

        setRemovedCont(removedCont);
        setNewContacts(newContacts.filter((a) => !ids.includes(a)));
      } else {
        ContactList.data.forEach((item) => {
          const inNewElements = newContacts.findIndex(
            (elem) => elem === item.id
          );

          const inCampaignElem = originalValues.findIndex(
            (elem) => elem === item.id
          );

          if (inCampaignElem === -1 && inNewElements === -1) {
            newContacts.push(item.id);
          }
        });
        setNewContacts(newContacts);
        setRemovedCont(removedCont.filter((elem) => !ids.includes(elem)));

        setListContact(ContactList.data.map((item) => item.id));
      }
      return;
    }
    if (
      newContacts.length === ContactList.data.length &&
      ContactList.data.length !== 0
    ) {
      const arrayRemovedItemsList = listContact.filter(
        (elem) => !ids.includes(elem)
      );
      setNewContacts([]);
      setListContact(arrayRemovedItemsList);

      setTotalSelectedFixed(arrayRemovedItemsList.length);
      return;
    }
    const newItems = unifyArraysNoRepetition(ids, listContact);
    setListContact(newItems);
    setNewContacts(ids);

    setTotalSelectedFixed(newContacts.length);
  };

  const columns = [
    {
      name: (
        <input
          disabled={!isEditable}
          className="cursor-pointer h-10 mr-2"
          type="checkbox"
          checked={
            campaign
              ? listContact.length === ContactList.data.length &&
                ContactList.data.length !== 0
              : newContacts.length === ContactList.data.length &&
                ContactList.data.length !== 0
          }
          onChange={handleSelectAll}
        />
      ),
      width: '70px',
      key: '',
      selector: (row: any) => (
        <div>
          <Input
            disabled={!isEditable}
            classNameDiv="accent-primary"
            className="cursor-pointer"
            type="checkbox"
            checked={
              listContact.includes(row.id) && !removedCont.includes(row.id)
            }
            onChange={() => {
              if (!originalValues.includes(row.id)) {
                toggleContactSelected(row.id);
              } else {
                toggleContactRemoved(row.id);
              }

              AddRemoveIdList(row.id);
            }}
          />
        </div>
      ),
    },
    {
      name: 'Contato',
      key: 'nome',
      selector: (row: ContactProps) => row.name,
    },
    {
      name: 'Mensagens Enviadas',
      key: 'organization',
      minWidth: '170px',
      selector: (row: ContactProps) => row.whatsapp_number_messages,
    },
    {
      name: 'Última atualização',
      key: 'whatsapp_last_contact',
      selector: (row: ContactProps) => row.whatsapp_last_contact,
      cell: (row: ContactProps) =>
        maskHelper.formatDateDMY(row.whatsapp_last_contact ?? ''),
    },

    {
      name: 'Tag',
      key: 'tag',
      width: '250px',
      selector: (row: ContactProps) => (
        <div className="flex flex-wrap gap-2 my-2 w-64">
          {row.person_tag.map(
            (item) =>
              item.tag.color && (
                <div
                  style={{
                    backgroundColor: `${item.tag.color}`,
                    color: getContrastColorWithOpacity(item.tag.color),
                    border:
                      getContrastColorWithOpacity(item.tag.color) !== '#ffffff'
                        ? `1px solid ${getContrastColorWithOpacity(
                            item.tag.color
                          )}`
                        : '0px',
                  }}
                  className="text-[8px] p-1 rounded-sm truncate max-w-[60px]"
                >
                  {item.tag.title}
                </div>
              )
          )}
        </div>
      ),
    },
    {
      name: 'Campanhas',
      key: '',
      selector: (row: any) => (
        <div className="flex flex-wrap">
          {row.campaign_person
            .map((item: any, index: number) => (
              <p className="flex py-1 text-[11px]">
                {index !== 0 && <div className="mx-1 w-fit h-3">•</div>}{' '}
                {item.campaign.title}
              </p>
            ))
            .slice(0, 3)}
          {row.campaign_person.length > 3 && (
            <div className="w-full flex justify-center mb-1"> ...</div>
          )}
        </div>
      ),
      minWidth: '250px',
    },
  ];

  const itemsFilterOptions = [
    { value: 'between', label: 'Entre' },
    { value: 'gte', label: 'Maior ou igual a' },
    { value: 'lte', label: 'Menor ou igual a' },
  ];

  const statusContact = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  useEffect(() => {
    if (campaign) {
      if (
        ContactList.total > 0 &&
        filter.campaignId[0]?.value === campaign.id &&
        isFirstAccess
      ) {
        setTotalSelectedFixed(ContactList.total);

        setIsFirstAccess(false);
      }

      const values = ContactList.data
        .filter((item: any) =>
          item.campaign_person.some((p: any) => p.campaign_id === campaign.id)
        )
        .map((elem: any) => elem.id);
      setListContact(values);
      setOriginalValues(values);
    } else {
      setNewContacts(
        ContactList.data
          .filter((item) => listContact.includes(item.id))
          .map((item) => item.id)
      );
    }
  }, [ContactList]);

  useEffect(() => {
    if (campaign) {
      setTotalSelected(
        totalSelectedFixed - removedCont.length + newContacts.length
      );
    } else {
      setTotalSelected(listContact.length);
    }
  }, [listContact, removedCont, newContacts]);

  useEffect(() => {
    if (campaign) {
      setFilter({
        campaignId: [{ label: campaign.title, value: campaign.id }],
        companieId,
        tags: [],
        is_whatsapp: true,
      });
    }
  }, []);

  return (
    <div className="w-full flex flex-col px-4 mt-2 overflow-y-auto">
      <h3 className="font-semibold text-sm mt-3">
        Filtrar por Receitas Associadas ao Contato
      </h3>
      <div className="w-full flex flex-col md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-x-2 md:items-end mt-3">
        <p className="col-span-1 md:col-span-2 lg:col-span-4 xl:col-span-3 text-sm mb-1">
          Período
        </p>
        <p className="hidden xl:flex lg:col-span-2 xl:col-span-3 text-sm mb-1">
          Última Compra Realizada
        </p>
        <div className="col-span-1 md:col-span-2 lg:col-span-4 xl:col-span-3 flex flex-col sm:flex-row gap-2 mb-3">
          <FilterDateSelect
            variant="gray"
            filterDate={filterDate}
            restartPagination={() => {}}
            disabled={
              filter.last_purchase !== undefined &&
              filter.last_purchase.filter !== 'all'
            }
            setFilterDate={(e) => {
              if (e !== '') {
                setFilterDate(e);
                setFilter({ ...filter, last_purchase: undefined });
              } else {
                setFilterDate('all');
                setFilter({
                  ...filter,
                  last_purchase: {
                    filter: 'all',
                  },
                });
              }
            }}
            hideAllOption
          />
        </div>
        <p className="col-span-1 xl:hidden col-span-2 lg:col-span-4 text-sm mb-1">
          Última Compra Realizada
        </p>
        <div className="col-span-1 md:col-span-2 lg:col-span-4 xl:col-span-3 flex flex-col sm:flex-row gap-2 mb-3">
          <FilterDateSelect
            hideAllOption
            variant="gray"
            filterDate={filter.last_purchase?.filter ?? ''}
            restartPagination={() => {}}
            disabled={filterDate !== '' && filterDate !== 'all'}
            setFilterDate={(e) => {
              if (e === '') {
                setFilterDate('all');
                setFilter({
                  ...filter,
                  last_purchase: undefined,
                });
                return;
              }
              if (e.includes('other_period')) {
                setFilterDate('all');
                const dates = e.split('/');
                setFilter({
                  ...filter,
                  last_purchase: {
                    filter: dates[0],
                    value_start: dates[1],
                    value_end: dates[2],
                  },
                });
              } else {
                setFilterDate('all');
                setFilter({
                  ...filter,
                  last_purchase: {
                    filter: e,
                    value_end: undefined,
                    value_start: undefined,
                  },
                });
              }
            }}
          />
        </div>
        <SelectComponent
          isClearable
          options={itemsFilterOptions}
          title="Quantidade de Receitas"
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                qty_payed: {
                  filter: e.value,
                  value_start: 0,
                },
              });
            } else {
              setFilter({ ...filter, qty_payed: undefined });
            }
          }}
        />
        <InputRange
          defaultValueEnd={filter.qty_payed?.value_end}
          defaultValueStart={filter.qty_payed?.value_start}
          errorMen={menError.qtd}
          placeholder="0"
          onChange={(e) => {
            e.target.value = maskHelper.number(e.target.value);

            if (filter.qty_payed)
              setFilter({
                ...filter,
                qty_payed: {
                  ...filter.qty_payed,
                  value_start: e.target.value,
                },
              });
          }}
          onBlur={(e) => {
            if (Number(e.target.value) > Number(filter.qty_payed?.value_end)) {
              setMenError({
                ...menError,
                qtd: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                qtd: '',
              });
            }
          }}
          onBlurEnd={(e) => {
            if (
              Number(e.target.value) < Number(filter.qty_payed?.value_start)
            ) {
              setMenError({
                ...menError,
                qtd: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                qtd: '',
              });
            }
          }}
          onChangeEnd={(e) => {
            e.target.value = maskHelper.number(e.target.value);

            if (filter.qty_payed)
              setFilter({
                ...filter,
                qty_payed: {
                  ...filter.qty_payed,
                  value_end: e.target.value,
                },
              });
          }}
          type={filter.qty_payed?.filter ?? 'disabled'}
        />
        <SelectComponent
          isClearable
          options={itemsFilterOptions}
          title="Valor Total das Receitas"
          isDisabled={filter.average_ticket !== undefined}
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                average_ticket: undefined,
                value_payed: {
                  filter: e.value,
                  value_start: 0,
                },
              });
            } else {
              setFilter({ ...filter, value_payed: undefined });
            }
          }}
        />
        <InputRange
          defaultValueEnd={filter.value_payed?.value_end}
          defaultValueStart={maskHelper.formatCurrencyInput(
            String(filter.value_payed?.value_start)
          )}
          errorMen={menError.value}
          placeholder="R$ 0,00"
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);

            if (filter.value_payed)
              setFilter({
                ...filter,
                value_payed: {
                  ...filter.value_payed,
                  value_start: Number(maskHelper.number(e.target.value)) / 100,
                },
              });
          }}
          onChangeEnd={(e) => {
            if (e) {
              e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              if (filter.value_payed)
                setFilter({
                  ...filter,
                  value_payed: {
                    ...filter.value_payed,
                    value_end: Number(maskHelper.number(e.target.value)) / 100,
                  },
                });
            } else {
              setFilter({ ...filter, qty_payed: undefined });
            }
          }}
          onBlur={(e) => {
            if (
              Number(maskHelper.number(e.target.value)) / 100 >
              Number(filter.value_payed?.value_end)
            ) {
              setMenError({
                ...menError,
                qtd: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                qtd: '',
              });
            }
          }}
          onBlurEnd={(e) => {
            if (
              Number(maskHelper.number(e.target.value)) / 100 <
              Number(filter.value_payed?.value_start)
            ) {
              setMenError({
                ...menError,
                value: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                value: '',
              });
            }
          }}
          type={filter.value_payed?.filter ?? 'disabled'}
        />
        <SelectComponent
          title="Ticket Médio"
          isDisabled={filter.value_payed !== undefined}
          isClearable
          options={itemsFilterOptions}
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                value_payed: undefined,
                average_ticket: {
                  filter: e.value,
                  value_start: 0,
                },
              });
            } else {
              setFilter({
                ...filter,
                average_ticket: undefined,
              });
            }
          }}
        />
        <InputRange
          defaultValueEnd={filter.average_ticket?.value_end}
          defaultValueStart={maskHelper.formatCurrencyInput(
            String(filter.average_ticket?.value_start)
          )}
          errorMen={menError.ticket}
          placeholder="R$ 0,00"
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            if (filter.average_ticket) {
              setFilter({
                ...filter,
                average_ticket: {
                  ...filter.average_ticket,
                  value_start: Number(maskHelper.number(e.target.value)) / 100,
                },
              });
            }
          }}
          onChangeEnd={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);

            if (filter.average_ticket)
              setFilter({
                ...filter,
                average_ticket: {
                  ...filter.average_ticket,
                  value_end: Number(maskHelper.number(e.target.value)) / 100,
                },
              });
          }}
          onBlur={(e) => {
            if (
              Number(maskHelper.number(e.target.value)) / 100 >
              Number(filter.average_ticket?.value_end)
            ) {
              setMenError({
                ...menError,
                ticket: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                ticket: '',
              });
            }
          }}
          onBlurEnd={(e) => {
            if (
              Number(maskHelper.number(e.target.value)) / 100 <
              Number(filter.average_ticket?.value_start)
            ) {
              setMenError({
                ...menError,
                qtd: 'Valor deve ser maior que o inicial',
              });
            } else {
              setMenError({
                ...menError,
                qtd: '',
              });
            }
          }}
          type={filter.average_ticket?.filter ?? 'disabled'}
        />
      </div>
      <h3 className="font-semibold text-sm mt-5">
        Filtrar por Dados do Contato
      </h3>
      <div className="w-full flex flex-col md:grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-8 gap-2 md:items-end mt-3 mb-14">
        <Input
          variant="outline-orange"
          value={inputContact}
          title="Nome"
          onChange={(e) => setInputContact(e.target.value)}
          classNameDiv="lg:col-span-2 xl:col-span-1"
        />
        <SelectComponent
          options={monthsOptions}
          title="Mês de Aniversário"
          isClearable
          maxOptionsHeight="100px"
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                value_payed: undefined,
                birthday_month: e.value,
              });
            } else {
              setFilter({
                ...filter,
                birthday_month: undefined,
              });
            }
          }}
        />
        <SelectComponent
          options={statusContact}
          title="Devedor"
          isClearable
          onChange={(e: any) => {
            if (e) {
              setFilter({
                ...filter,
                person_debtor: e.value,
              });
            } else {
              setFilter({
                ...filter,
                person_debtor: undefined,
              });
            }
          }}
        />

        <div className="col-span-1 relative mb-3 xl:mb-0">
          <SelectWhatsappTag
            value={null}
            title="Filtrar por TAGs"
            isMulti
            itemsSelectedId={filter.tags.map((item) => item.value)}
            onChange={(e) => {
              setFilter({ ...filter, tags: filter.tags.concat(e) });
            }}
            maxOptionsHeight="100px"
          />
          <div className="absolute grid grid-cols-5 md:grid-cols-11 xl:grid-cols-5 max-h-[80px] gap-1 z-10 overflow-y-auto w-full">
            {filter.tags.map(
              (item, index) =>
                item.tag_color && (
                  <button
                    title={item.label}
                    type="button"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        tags: filter.tags.filter(
                          (elem) => elem.value !== item.value
                        ),
                      })
                    }
                    onMouseEnter={() => {
                      setIsHover((index + 1) * -2);
                    }}
                    onMouseLeave={() => setIsHover(-1)}
                    style={{
                      backgroundColor: `${item.tag_color}`,
                      color: getContrastColorWithOpacity(item.tag_color),
                      border:
                        getContrastColorWithOpacity(item.tag_color) !==
                        '#ffffff'
                          ? `1px solid ${getContrastColorWithOpacity(
                              item.tag_color
                            )}`
                          : '0px',
                    }}
                    className="relative text-[8px] p-1 rounded-sm truncate max-w-[60px] hover:opacity-70"
                  >
                    {isHover === (index + 1) * -2 && (
                      <IconDictionary
                        name="Excluir"
                        className="absolute -top-1 -right-0.5 z-20 w-3"
                      />
                    )}
                    {item.label}
                  </button>
                )
            )}
          </div>
        </div>
        <div className="col-span-1 relative xl:mb-0">
          <SelectComponent
            title="Funil"
            isClearable
            options={optionsFunnel}
            onChange={(selectedOption: any) => {
              setFunnelId(selectedOption);
            }}
          />
        </div>
        <div className="col-span-1 relative xl:mb-0">
          <SelectComponent
            title="Etapa"
            options={optionsSteps}
            isClearable
            isDisabled={funnelId === null}
            onChange={(selectedOption: any) => {
              setStepId(selectedOption ? selectedOption.value : null);
            }}
          />
        </div>
        <div className="col-span-1 relative mb-20 xl:mb-0">
          <SelectComponent
            title="Filtrar por Campanha"
            isLoading={isLoadingWhatsappDropdown}
            options={campaignOptions.filter(
              (item) =>
                !filter.campaignId.some((elem) => elem.value === item.value)
            )}
            onInputChange={setInputSelect}
            value={[]}
            isMulti
            onChange={(e: OptionsSelectProp[]) => {
              setFilter({ ...filter, campaignId: e.concat(filter.campaignId) });
            }}
            classNameDiv="col-span-2"
            maxOptionsHeight="100px"
          />
          <div className="absolute mt-2 grid grid-cols-4 md:grid-cols-11 xl:grid-cols-4 max-h-[80px] gap-1 z-10 overflow-y-auto w-full">
            {filter.campaignId.map((item, index) => (
              <button
                onMouseEnter={() => {
                  setIsHover(index);
                }}
                onMouseLeave={() => setIsHover(-1)}
                title={item.label}
                type="button"
                className="relative hover:opacity-70 text-[8px] p-1 rounded-sm truncate max-w-[80px] border-indigo-400 border-solid border
                 bg-indigo-400/40"
                onClick={() =>
                  setFilter({
                    ...filter,
                    campaignId: filter.campaignId.filter(
                      (elem) => elem.value !== item.value
                    ),
                  })
                }
              >
                {isHover === index && (
                  <IconDictionary
                    name="Excluir"
                    className="absolute -top-1 -right-0.5 z-20 w-3"
                    onClick={() =>
                      setFilter({
                        ...filter,
                        campaignId: filter.campaignId.filter(
                          (elem) => elem.value !== item.value
                        ),
                      })
                    }
                  />
                )}
                {item.label}
              </button>
            ))}
          </div>
        </div>
        <div className="col-span-2 lg:col-span-4 xl:col-span-1 grid grid-cols-2 gap-1.5">
          <Button
            variant="primary-strong"
            className="mb-1.5 w-full lg:w-1/2 xl:w-full place-self-end"
            disabled={Boolean(
              Object.values(menError).find((item) => item !== '')
            )}
            onClick={handleSearch}
          >
            Pesquisar
          </Button>
          <Button
            variant="outline-primary"
            className="mb-1.5 w-full lg:w-1/2 xl:w-full"
            onClick={handleClearSearch}
          >
            Limpar
          </Button>
        </div>
      </div>
      <div className="my-5 relative">
        <strong className="absolute flex gap-1 top-0 z-10 text-xs">
          Contatos: <p className="font-normal">{totalSelected} adicionado(s)</p>
        </strong>
        <TableWithPaginationBack
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          isLoading={isLoading}
          columns={columns}
          data={ContactList.data}
          totalRegisters={ContactList.total}
          setStartDate={setSkip}
          totalPerPage={20}
          selectedRowId={-1}
        />
      </div>

      <div className="mx-auto mt-2 flex gap-3">
        <Button
          isLoading={isLoading || isLoadingContacts}
          disabled={totalSelected === 0}
          actionType="button-loading"
          variant="primary-strong"
          onClick={saveContacts}
        >
          Salvar e continuar
        </Button>
      </div>
    </div>
  );
};

export default ContactsList;
