/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { ApiOffer } from '../../../api';
import { OfferProps } from '../../../types';
import { Button, Input, TextArea } from '../../../../../components';
import { dictionaryError } from '../../../../../helpers/utils.helper';

interface props {
  defaultValues: OfferProps | null;
  setDefaultValue: (e: OfferProps) => void;
  notify: (e: { message: string; type: any }) => void;
  nextPage: () => void;
}

const ObservationOffer: React.FC<props> = ({
  defaultValues,
  setDefaultValue,
  nextPage,
  notify,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [obs, setObs] = useState(defaultValues?.obs ?? '');

  const Submit = async () => {
    if (defaultValues) {
      setIsLoading(true);
      const response = await ApiOffer.updateObs(
        {
          obs,
        },
        defaultValues?.id
      );
      if (!response.id) {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      } else {
        setDefaultValue({
          ...defaultValues,
          obs: response.obs,
        });
        nextPage();
        notify({ message: 'Observação salva com sucesso', type: 'Success' });
      }
    }
  };

  return (
    <div className="flex flex-col gap-5 items-center mt-7">
      <TextArea
        className="h-56"
        title="Observação"
        defaultValue={obs}
        onChange={(e) => {
          setObs(e.target.value);
        }}
      />

      <Button
        actionType="button-loading"
        isLoading={isLoading}
        disabled={isLoading || obs === ''}
        onClick={Submit}
      >
        Salvar
      </Button>
    </div>
  );
};

export default ObservationOffer;
