import { useEffect, useState } from 'react';
import { ApiWhatsapp } from '../api';

export function useFetchWhatsappMessages(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const companies = await ApiWhatsapp.getWhatsappMessages();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    })();
  }, [key]);
  return { WhatsappMessagesList: data, isLoadingWhatsapp: isLoading };
}

export function useFetchWhatsappMessagesActive(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const companies = await ApiWhatsapp.getWhatsappMessagesActive();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    })();
  }, [key]);
  return { WhatsappMessagesList: data, isLoadingWhatsapp: isLoading };
}
