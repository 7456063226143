import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Modal } from '../../../../components';
import AlertCard from '../../../../components/Card/alertCard';

const ModalHasNoConnectedInstances: React.FC<{
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
}> = ({ setShow, show }) => (
  <Modal
    size="medium"
    minHeight="min-h-[100px]"
    isOpen={show}
    setIsOpen={setShow}
    title="Sem Instâncias Conectadas"
  >
    <div className="w-full flex flex-col items-center gap-5">
      <AlertCard
        message="É necessário ter uma instância conectada
            para enviar mensagens"
      />
      <Link to="/instances">
        <Button actionType="button-loading" variant="primary-strong">
          Conectar Instância
        </Button>
      </Link>
    </div>
  </Modal>
);

export default ModalHasNoConnectedInstances;
