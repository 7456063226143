import { baseApiGalax } from '../../../../../api/baseApi';
import {
  CreatePFProps,
  CreatePJProps,
  SubAccountFullProps,
  UpdateCompanieDataProps,
} from '../types';

async function getSubAccounts(): Promise<SubAccountFullProps[]> {
  return baseApiGalax({
    method: 'GET',
    url: `companie/subaccounts?limit=100&startAt=0`,
  });
}

async function getSubAccountsById(id: number): Promise<SubAccountFullProps> {
  return baseApiGalax({
    method: 'GET',
    url: `companie/${id}`,
  });
}

async function createCompaniePF(information: CreatePFProps) {
  return baseApiGalax({
    body: information,
    method: 'POST',
    url: `companie/pf`,
  });
}

async function createCompaniePJ(information: CreatePJProps) {
  return baseApiGalax({
    body: information,
    method: 'POST',
    url: 'companie/pj',
  });
}

async function sendDocumentsPF(information: any) {
  return baseApiGalax({
    body: information,
    method: 'POST',
    url: 'companie/documents/pf',
  });
}

async function sendDocumentsPJ(information: any) {
  return baseApiGalax({
    body: information,
    method: 'POST',
    url: 'companie/documents/pj',
  });
}

async function updateCompanie(
  information: UpdateCompanieDataProps,
  accountID: number
) {
  return baseApiGalax({
    body: information,
    method: 'PUT',
    url: `companie/${accountID}`,
  });
}

async function activeCompanie(accountID: number) {
  return baseApiGalax({
    method: 'PUT',
    url: `companie/active/${accountID}`,
  });
}

async function deleteCompanie(accountID: number) {
  return baseApiGalax({
    method: 'DELETE',
    url: `companie/${accountID}`,
  });
}

export const ApiCompanyGalaxPay = {
  createCompaniePF,
  createCompaniePJ,
  updateCompanie,
  deleteCompanie,
  activeCompanie,
  sendDocumentsPF,
  sendDocumentsPJ,
  getSubAccounts,
  getSubAccountsById,
};
