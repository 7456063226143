import { baseApiPrivate } from '../../../../api/baseApi';
import { Whatsapp } from '../types';

async function getWhatsappPlans(active: boolean) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/whatsapp/plans${active ? '/active' : ''}`,
  });
}

async function createWhatsappPlans(e: Omit<Whatsapp, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/whatsapp/plans',
    body: e,
  });
}

async function updateWhatsappPlans(e: Omit<Whatsapp, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/whatsapp/plans/${id}`,
    body: e,
  });
}

async function deleteWhatsappPlans(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/whatsapp/plans/${id}`,
  });
}

export const ApiWhatsapp = {
  getWhatsappPlans,
  createWhatsappPlans,
  updateWhatsappPlans,
  deleteWhatsappPlans,
};
