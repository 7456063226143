import { baseApiPrivate } from '../../../api/baseApi';
import { createAccessCompanyProps } from '../types';

async function getAccess(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/accessCompanies/companie/${companyId}`,
  });
}

async function getCompanyGroup(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/accounts-group/${companyId}`,
  });
}

async function getUsers(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/accessCompanies/companie/users/${companyId}`,
  });
}

async function getProfiles(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/accessCompanies/companie/profiles/${companyId}`,
  });
}

async function createCompanyAccess(payload: createAccessCompanyProps) {
  return baseApiPrivate({
    method: 'POST',
    body: payload,
    url: `companie/user/accessCompanies`,
  });
}

async function deleteAccess(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/user/accessCompanies/${id}`,
  });
}

export const ApiOthersCompanyAccess = {
  getAccess,
  getProfiles,
  getUsers,
  getCompanyGroup,
  createCompanyAccess,
  deleteAccess,
};
