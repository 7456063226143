import { useEffect, useState } from 'react';
import { ApiExpenses } from '../api';
import { FinancialProps, attachment, filterFormProps } from '../types';

export function useFetchFinancials(
  key: string,
  filters: filterFormProps,
  period: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ data: FinancialProps[]; total: number }>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);

      const filtersToUse = {
        ...filters,
        client_id: filters.client_id.map((item) => item.value),
        contract_id: filters.contract_id.map((item) => item.value),
      };

      if (filters.name && filters.name.length >= 3) {
        filtersToUse.client_id = [];
        filtersToUse.contract_id = [];
      } else {
        delete filtersToUse.name;
      }

      const companies = await ApiExpenses.getFinancials(filtersToUse, period);

      if (typeof companies !== 'string') {
        setData(companies);
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [
    key,
    period,
    filters.name,
    filters.type,
    filters.form_of_payment,
    filters.status,
    filters.received,
    filters.order,
    filters.pla_account_id,
    filters.provider_id,
    filters.status_contract,
    filters.skip,
    filters.take,
  ]);

  return {
    FinancialsList: data,
    isLoadingList: isLoading,
    setFinancialList: setData,
  };
}

export function useFetchAttachments(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (id !== -1) {
        const companies = id === -1 ? [] : await ApiExpenses.getFiles(id);
        if (typeof companies !== 'string') {
          setData(companies);
        }
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AttachmentsList: data, isLoadingAttachmentsList: isLoading };
}
