import React, { useEffect } from 'react';
import { useFetchFormSearch } from '../hooks';
import { SelectComponent } from '../../../components';
import { OptionsSelectProp } from '../../../components/Select/select';

interface props {
  value: OptionsSelectProp | null;
  setValue: React.Dispatch<React.SetStateAction<OptionsSelectProp | null>>;
  // eslint-disable-next-line no-unused-vars
  setHashMap: (e: any) => void;
}

const SelectSearchForms: React.FC<props> = ({
  setValue,
  value,
  setHashMap,
}) => {
  const { SearchFormList, isLoadingData } = useFetchFormSearch('', true);
  const forms = SearchFormList.map((item) => ({
    label: item.title,
    value: item.id,
  }));
  useEffect(() => {
    const answersMap: Record<string, any> = {};
    SearchFormList.forEach((item) => {
      answersMap[item.id] = {
        name: item.title,
        questions: item.search_form_questions,
      };
    });
    setHashMap(answersMap);
  }, [SearchFormList]);

  return (
    <SelectComponent
      options={forms}
      isDisabled={isLoadingData}
      title="Formulário"
      closeMenuOnSelect
      value={value}
      onChange={setValue}
      isClearable
    />
  );
};

export default SelectSearchForms;
