import { useEffect, useState } from 'react';
import { ApiParameters } from '../api';
import { CompanieProps, AccountProps, AdminProps } from '../types';

export function useFetchCompanie() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CompanieProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiParameters.companie();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, []);
  return { CompanieList: data, isLoadingCompanie: isLoading };
}

export function useFetchAdministrator(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AdminProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiParameters.administrator();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { Administrator: data, isLoadingAdministrator: isLoading };
}

export function useFetchAccounts(key: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AccountProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiParameters.accounts(key);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AccountsList: data, isLoadingAccounts: isLoading };
}
