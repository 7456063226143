import React, { useEffect, useState } from 'react';
import maskHelper from '../../../../helpers/mask.helper';
import { Card, FilterDateSelect } from '../../../../components';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { useFetchFunnelByProfile } from '../../../funnel/hooks';
import { useFetchUsersCompanie } from '../../../users/hooks';
import { useFetchResponses } from '../../../responseWinLose/hooks';
import ReportsOpportunity from '../components/filterOpportunity';
import { useFetchSalesChannel } from '../../../salesChannel/hooks';
import { OpportunityProps } from '../../../opportunity/types';
import { ApiOpportunityReports } from '../api';
import IconDictionary from '../../../../components/Icons/icons';
import { reportFilter } from '../types';
import MainPagePagination from '../../../main/components/mainPage/mainPageWithPagination';
import { useFetchOpportunityDropdown } from '../../../opportunity/hooks';
import { useFetchReportsOpportunity } from '../hook';
import { notify } from '../../../../components/Toast/toast';

const OpportunityReports: React.FC = () => {
  const [inputOpportunity, setInputOpportunity] = useState('');

  const { OpportunityList, isLoadingOpportunityList } =
    useFetchOpportunityDropdown(inputOpportunity);
  const allOpportunities = OpportunityList.data.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const [filterDate, setFilterDate] = useState('today');
  const accountProps = getTokenAccountInformation();
  const [modalFilterOpportunity, setModalFilterOpportunity] = useState(false);

  const companyId = accountProps.companie_id;

  const { FunnelsList } = useFetchFunnelByProfile('key', accountProps.id);
  const funnelsOptions = FunnelsList.filter(
    (item) => item.funnel_step.length > 0
  ).map((funnel) => ({
    value: funnel.id,
    label: funnel.title,
  }));

  const { SalesChannelList } = useFetchSalesChannel(
    'key',
    accountProps.companie_id
  );
  const salesChannelOptions = SalesChannelList.map((sale) => ({
    value: sale.id,
    label: sale.title,
  }));

  const { UsersList } = useFetchUsersCompanie('key', accountProps.companie_id);
  const userOptions = UsersList.map((sale) => ({
    value: sale.id,
    label: sale.name,
  }));

  const { ResponsesList } = useFetchResponses('key', accountProps.companie_id);
  const positiveResOptions = ResponsesList.filter((item) => item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );
  const negativeResOptions = ResponsesList.filter((item) => !item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );
  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const division = [
    [{ label: 'Respostas de Perda', value: 0, isdisabled: true }],
    [{ label: 'Respostas de Ganho', value: 0, isdisabled: true }],
  ];

  const responseOptions = division[0]
    .concat(negativeResOptions)
    .concat(division[1])
    .concat(positiveResOptions);

  const [filterModal, setFilterModal] = useState<reportFilter>({
    opportunityId: null,
    productId: [],
    salesChannelId: [],
    responses_win_lose_id: [],
    companieUserId: [],
    order: 'title_asc',
    status: 'Todos',
    funnelId: null,
    skip: startData,
    take: 10,
  });

  const { OpportunityReportsList, isLoading } = useFetchReportsOpportunity(
    companyId,
    filterDate,
    filterModal
  );

  const columns = [
    {
      name: 'Título',
      key: 'title',
      selector: (row: OpportunityProps) => row.title,
    },
    {
      name: 'Nome do Contato',
      key: 'row.opportunity_person[0].person.name',
      selector: (row: OpportunityProps) =>
        row.opportunity_person[0]?.person.name,
      maxWidth: '200px',
    },
    {
      name: 'Funil',
      key: 'funnel_step.funnel.title',
      selector: (row: OpportunityProps) => row.funnel_step.funnel.title,
      hide: 1470,
      maxWidth: '140px',
    },
    {
      name: 'Etapa do funil',
      key: 'funnel_step.title',
      selector: (row: OpportunityProps) => row.funnel_step.title,
      hide: 1216,
      maxWidth: '140px',
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: OpportunityProps) => row.status,
    },
    {
      name: 'Valor',
      key: 'total',
      selector: (row: OpportunityProps) =>
        maskHelper.formatMoeda(row.total ?? ''),
      maxWidth: '230px',
    },
    {
      name: 'Data Expectativa',
      key: 'date_expectation',
      selector: (row: OpportunityProps) =>
        maskHelper.formatDateDMY(row.date_expectation ?? ''),
      hide: 1596,
      maxWidth: '146px',
    },
    {
      name: 'Data do Cadastro',
      key: 'created_at',
      selector: (row: OpportunityProps) =>
        maskHelper.formatDateDMY(row.created_at ?? ''),
      maxWidth: '200px',
    },
    {
      name: 'Responsável do Cadastro',
      key: 'companie_user.name',
      selector: (row: OpportunityProps) => row.companie_user.name,
      hide: 1366,
    },
  ];

  const generateCsv = async () => {
    try {
      const res = await ApiOpportunityReports.getOpportunityReports(
        accountProps.companie_id,
        filterDate,
        {
          ...filterModal,
          opportunityId: filterModal.opportunityId?.value,
          skip: 0,
          take: 999999999999,
        }
      );

      const getWinTitle = (opportunityStatus: string | any[]) => {
        const lastStatus = opportunityStatus[opportunityStatus.length - 1];
        if (
          lastStatus?.responses_win_lose &&
          lastStatus.responses_win_lose.is_win
        ) {
          return lastStatus.responses_win_lose.title;
        }
        return ' ';
      };

      const getLoseTitle = (opportunityStatus: string | any[]) => {
        const lastStatus = opportunityStatus[opportunityStatus.length - 1];
        if (
          lastStatus?.responses_win_lose &&
          !lastStatus.responses_win_lose.is_win
        ) {
          return lastStatus.responses_win_lose.title;
        }
        return ' ';
      };

      if (typeof res !== 'string' && res.data) {
        const csvData = [
          [
            'Titulo',
            'Nome do Contato',
            'WhatsApp do Contato',
            'Email do Contato',
            'CPF',
            'Canal de Vendas',
            'Segmento',
            'Observação',
            'Data de Nascimento Contato',
            'Organização do Contato',
            'Funil',
            'Etapa do funil',
            'Status',
            'Ganho',
            'Perda',
            'Valor',
            'Data Expectativa',
            'Data do Cadastro',
            'Responsável do Cadastro',
            'Projeto',
            'Estado',
            'Cidade',
            'Investimento',
            'UTM Source',
            'UTM Medium',
            'UTM Campaign',
            'UTM Term',
            'UTM Content',
            'Versão',
            'Mensagem',
          ],
          ...res.data.map((item) => [
            item.title,
            item.opportunity_person[0]?.person.name ?? '',
            item.opportunity_person[0]?.person.whatsapp,
            item.opportunity_person[0]?.person.email,
            maskHelper.cnpjCpf(
              item.opportunity_person[0]?.person.document ?? ''
            ),
            item.sales_channel?.title,
            item.segment?.title,
            item.obs !== undefined ? item.obs : '',
            maskHelper.formatDateDMY(
              item.opportunity_person[0]?.person.birth ?? ''
            ),
            item.opportunity_person[0]?.person.organization,
            item.funnel_step.funnel.title,
            item.funnel_step.title,
            item.status,
            getWinTitle(item.opportunity_status),
            getLoseTitle(item.opportunity_status),
            Number(item.total ?? ''),
            maskHelper.formatDateDMY(item.date_expectation ?? ''),
            maskHelper.formatDateDMY(item.created_at ?? ''),
            item.companie_user.name,
            item.campaign_project,
            item.campaign_state,
            item.campaign_city,
            item.campaign_investment,
            item.campaign_utm_source,
            item.campaign_utm_medium,
            item.campaign_utm_campaign,
            item.campaign_utm_term,
            item.campaign_utm_content,
            item.campaign_version,
            item.campaign_message,
          ]),
        ];
        return csvData;
      }
      return [[]];
    } catch (e) {
      console.error(e);
      notify({
        message: 'Desculpe, ocorreu um erro ao exportar a tabela.',
        type: 'Error',
      });
      return [[]];
    }
  };

  const restartPagination = () => {
    setStartData(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilterModal({ ...filterModal, skip: startData });
  }, [startData]);

  return (
    <MainPagePagination
      totalRegisters={OpportunityReportsList.total}
      currentPageTable={currentPage}
      setCurrentPageTable={setCurrentPage}
      startDate={setStartData}
      isLoading={isLoading}
      title="Oportunidades"
      column={columns}
      filterTable={() => OpportunityReportsList.data}
      setRegisterPage={() => {}}
      objectReport={generateCsv}
      filename="oportunidades"
      FilterComponent={
        <Card className="w-full xl:w-2/3 px-4 pt-5 flex-col pb-3.5 gap-4">
          <strong>Filtrar Oportunidades</strong>
          <div className="flex flex-col flex-wrap sm:flex-row gap-1 sm:gap-3 w-full mt-2 md:mt-0 lg:w-fit">
            <ReportsOpportunity
              setCurrentPage={setCurrentPage}
              isLoadingDropdown={isLoadingOpportunityList}
              setInputOpportunity={setInputOpportunity}
              filters={filterModal}
              setFilter={setFilterModal}
              isLoading={isLoading}
              responseOptions={responseOptions}
              salesChannelOptions={salesChannelOptions}
              userOptions={userOptions}
              opportunityList={allOpportunities}
              setShow={setModalFilterOpportunity}
              show={modalFilterOpportunity}
              funnelId={null}
              funnelsOptions={funnelsOptions}
            />

            <FilterDateSelect
              filterDate={filterDate}
              restartPagination={restartPagination}
              setFilterDate={setFilterDate}
            />
            <Card
              className="w-11 h-11 rounded-lg items-center justify-center cursor-pointer text-primary border border-solid border-primary self-end sm:self-auto"
              onClick={() => setModalFilterOpportunity(true)}
            >
              <IconDictionary name="Filtro" size={16} />
            </Card>
          </div>
        </Card>
      }
    />
  );
};

export default OpportunityReports;
