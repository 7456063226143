import { baseApiPrivate } from '../../../api/baseApi';
import { createUpdateProducts } from '../types';

async function getAllProduct() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/product`,
  });
}

async function getAllProductActive() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/product/active`,
  });
}

async function createProduct(e: createUpdateProducts) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/product',
    body: e,
  });
}

async function updateProduct(e: createUpdateProducts, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/product/${id}`,
    body: e,
  });
}

async function deleteProduct(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/product/${id}`,
  });
}

export const ApiProduct = {
  getAllProduct,
  getAllProductActive,
  createProduct,
  updateProduct,
  deleteProduct,
};
