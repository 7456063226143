import React from 'react';
import moment from 'moment';

const FooterCheckout: React.FC = () => (
  <div className="flex flex-col w-full mx-auto items-center px-5 md:px-24 mb-2">
    <hr className="w-full h-px bg-gray-600 mt-10 mb-2" />
    <p className="text-xs text-center mb-5">
      {moment().year()} © Laks Tecnologia
    </p>
  </div>
);

export default FooterCheckout;
