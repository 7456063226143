import { useEffect, useState } from 'react';
import { ApiSignature } from '../api';
import { SignatureProps } from '../types';

export function useFetchSignature(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SignatureProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiSignature.get();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { SignatureList: data, isLoadingSignature: isLoading };
}
