import { baseApiPrivate } from '../../../../api/baseApi';
import { releaseMenu, Menu } from '../types';

async function getAllMenus() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/menu',
  });
}

async function getAllMenusByUser(userId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/menu/user/${userId}`,
  });
}

async function getByProfileMenus(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/menu/profile/${id}`,
  });
}

async function interligateMenu(e: releaseMenu) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/menu/profile',
    body: e,
  });
}

async function createMenu(e: Omit<Menu, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/menu',
    body: e,
  });
}

async function updateMenu(e: Omit<Menu, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/menu/${id}`,
    body: e,
  });
}

async function deleteMenu(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/menu/${id}`,
  });
}

export const ApiMenu = {
  interligateMenu,
  getByProfileMenus,
  getAllMenusByUser,
  getAllMenus,
  createMenu,
  updateMenu,
  deleteMenu,
};
