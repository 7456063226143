import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { MdOutlineBackpack, MdHelpOutline } from 'react-icons/md';
import logo from '../../../assets/png/logo-estrela.png';

import '../styles/header.css';

const Header: React.FC<{ message?: string }> = ({ message }) => (
  <header className="containerHeader px-2 sm:px-16 xl:px-32">
    <img alt="logo-webtur" src={logo} className="cursor-pointer w-[179px]" />

    <div className="actions">
      <div className="action">
        <AiOutlineUser />
        <span>Iniciar Sessão</span>
      </div>

      <div className="action hidden sm:flex">
        <MdOutlineBackpack />
        <span>Minhas Viagens</span>
      </div>
      <div className="action">
        <MdHelpOutline />
        <span>Iniciar Ajuda</span>
      </div>
    </div>

    <p className="titlePage">{message}</p>
  </header>
);

export default Header;
