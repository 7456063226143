import React, { useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';
import { Card } from '../../../../../components';
/* eslint-disable no-unused-vars */

interface props {
  text: string;
  // eslint-disable-next-line no-unused-vars
  addQuestion: (e: number) => void;
  setText: (e: string) => void;
  currentCardNumber: number;
  setCurrentCard: (e: number) => void;
}

const TitleForm: React.FC<props> = ({
  setText,
  setCurrentCard,
  addQuestion,
  text,
  currentCardNumber,
}) => {
  const activeCard = currentCardNumber === -1;
  const [style, setStyle] = useState<{
    title: string;
    active: boolean;
    question: { id: number; title: string; active: boolean }[];
  }>({ title: '', active: true, question: [] });
  return (
    <Card
      onClick={() => setCurrentCard(-1)}
      className="w-full pb-4 relative px-4 flex-col items-start mt-10 border-t-8 border-solid rounded-md  animate-slideHeight border-primary"
    >
      {activeCard && (
        <button
          type="button"
          onClick={() => addQuestion(0)}
          className="w-6 h-6 bg-primary rounded-full flex items-center justify-center text-white absolute -right-10 top-10"
        >
          <IconDictionary name="plus" />
        </button>
      )}
      <input
        type="text"
        id={`${currentCardNumber}-title`}
        defaultValue={text}
        onFocus={() => {
          setStyle({ ...style, active: true });
          setCurrentCard(-1);
        }}
        onBlur={(e) => setText(e.target.value)}
        className="focus:border-b-primary focus:border-b border-solid w-full py-2 font-medium text-2xl leading-tight focus:outline-none focus:shadow-outline"
      />
    </Card>
  );
};

export default TitleForm;
