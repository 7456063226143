import React, { useState } from 'react';
import { ApiContract } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { Button, Modal } from '../../../components';
import { contractProps } from '../types';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface DeleteProps {
  id: number;
  close: () => void;
  open: boolean;
  updateTable: () => void;
  contract: contractProps;
}

const SendEmailModal: React.FC<DeleteProps> = ({
  close,
  id,
  open,
  updateTable,
  contract,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accountProps = getTokenAccountInformation();
  const { notify } = ToastNotify();

  const sendEmail = async (row: contractProps) => {
    setIsLoading(true);
    const res = await ApiContract.sendEmail(accountProps.companie_id, row.id);
    if (typeof res !== 'string') {
      notify({ message: 'E-mail enviado!', type: 'Success' });
      updateTable();
      close();
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      setIsOpen={close}
      title="Enviar e-mail"
      isOpen={open}
      size="medium"
      minHeight="min-h-[230px]"
    >
      <strong className="text-sm">
        Tem certeza que deseja enviar um e-mail para {contract.client_name}?
      </strong>
      <div className="flex gap-3 w-full mt-6 justify-center">
        <Button variant="primary-strong" onClick={close} className="w-64">
          Voltar
        </Button>
        <Button
          className="w-64 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
          variant="outline-primary"
          disabled={isLoading}
          onClick={() => sendEmail(contract)}
          actionType="button-loading"
          isLoading={isLoading}
        >
          Confirmar envio
        </Button>
      </div>
    </Modal>
  );
};

export default SendEmailModal;
