import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
  Switch,
} from '../../../../components';
import { Menu, MenuFullProps } from '../types';
import { ApiMenu } from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { useFetchGroupByModule } from '../../group/hooks';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  menu: MenuFullProps | null;
  moduleOption: { label: string; value: number }[];
}

const RegisterMenu: React.FC<registerModalProps> = ({
  menu,
  reloadTable,
  setShow,
  show,
  moduleOption,
}) => {
  const [moduleId, setModuleId] = useState(-1);

  const { GroupByModuleList, isLoadingGroupByModule } =
    useFetchGroupByModule(moduleId);
  const groupOption = GroupByModuleList.map((item) => ({
    label: item.title,
    value: item.id,
  }));
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<Menu, 'id'>>({
    group_id: menu?.group_id,
    route_frontend: menu?.route_frontend ?? '',
    title: menu?.title ?? '',
    is_admin: false,
    is_franchise: false,
    is_franchisor: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (menu !== null) {
      const res = await ApiMenu.updateMenu(
        {
          route_frontend: values.route_frontend,
          title: values.title,
          group_id: values.group_id,
          is_admin: values.is_admin,
          is_franchise: values.is_franchise,
          is_franchisor: values.is_franchisor,
        },
        menu.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Menu atualizado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiMenu.createMenu({
        route_frontend: values.route_frontend,
        title: values.title,
        group_id: values.group_id,
        is_admin: values.is_admin,
        is_franchise: values.is_franchise,
        is_franchisor: values.is_franchisor,
      });
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Menu criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setModuleId(menu?.group?.module.id ?? -1);
    setValues({
      group_id: menu?.group_id,
      route_frontend: menu?.route_frontend ?? '',
      title: menu?.title ?? '',
      is_admin: menu?.is_admin ?? false,
      is_franchise: menu?.is_franchise ?? false,
      is_franchisor: menu?.is_franchisor ?? false,
    });
  }, [menu, show]);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={menu ? 'Atualizar Menu' : 'Criar Menu'}
      size="medium"
      minHeight="min-h-[300px]"
    >
      <div className="flex flex-col justify-center gap-3">
        <SelectComponent
          title="Módulo"
          value={moduleOption.find((item) => item.value === moduleId)}
          closeMenuOnSelect
          options={moduleOption}
          onChange={(e: any) => {
            setModuleId(e.value);
          }}
        />
        <SelectComponent
          title="Grupo"
          isDisabled={moduleId === -1}
          isLoading={isLoadingGroupByModule}
          defaultValue={
            menu?.group && { label: menu.group.title, value: menu.group_id }
          }
          value={
            groupOption.find((item) => item.value === values.group_id) ?? null
          }
          closeMenuOnSelect
          options={groupOption}
          onChange={(e: any) => setValues({ ...values, group_id: e.value })}
        />
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <Input
          variant="outline-orange"
          title="Rota no front-end"
          value={values.route_frontend}
          onChange={(e) =>
            setValues({ ...values, route_frontend: e.target.value })
          }
        />
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <p className="text-sm">Admin</p>
            <Switch
              state={values.is_admin}
              onChange={() =>
                setValues({ ...values, is_admin: !values.is_admin })
              }
            />
          </div>
          <div className="flex flex-col">
            <p className="text-sm">Franqueador</p>
            <Switch
              state={values.is_franchisor}
              onChange={() =>
                setValues({ ...values, is_franchisor: !values.is_franchisor })
              }
            />
          </div>
          <div className="flex flex-col">
            <p className="text-sm">Franquia</p>
            <Switch
              state={values.is_franchise}
              onChange={() =>
                setValues({ ...values, is_franchise: !values.is_franchise })
              }
            />
          </div>
        </div>

        <Button
          disabled={
            values.title.length === 0 ||
            values.route_frontend.length === 0 ||
            (values.is_admin || values.is_franchise || values.is_franchisor) ===
              false ||
            isLoading
          }
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterMenu;
