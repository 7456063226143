import React, { useState } from 'react';

import { SelectComponent } from '../../../components';

export const SelectDynamicOptions = ({ onChange, isContract }: any) => {
  const [value, setValue] = useState<{ label: string; value: string } | null>(
    null
  );

  const clientOptions = isContract
    ? [
        {
          label: 'CNPJ do Cliente',
          value: '{{document}}',
        },
        { label: 'Nome do Cliente', value: '{{name}}' },
        {
          label: 'Endereço do Cliente',
          value:
            '{{address}}, {{number}}, {{complement}}, {{province}}, {{city}}, {{state}} - {{zipcode}}',
        },
        {
          label: 'Whatsapp do cliente',
          value: '{{whatsapp}}',
        },
        {
          label: 'Telefone fixo do cliente',
          value: '{{phone}}',
        },
        {
          label: 'Email do cliente',
          value: '{{email}}',
        },
        {
          label: 'RG do Cliente',
          value: '{{rg}}',
        },
        {
          label: 'Estado Civil do Cliente',
          value: '{{civil_status}}',
        },
        {
          label: 'Data de nascimento',
          value: '{{birth}}',
        },
        {
          label: 'Produtos do Contrato',
          value: '{{items_contract}}',
        },
        {
          label: 'Início do Contato',
          value: '{{date_start}}',
        },
        {
          label: 'Fim do Contato',
          value: '{{date_end}}',
        },
        {
          label: 'Período do Contrato',
          value: '{{contract_period}}',
        },
        {
          label: 'Valor do Contrato',
          value: '{{contract_value}}',
        },
        {
          label: 'Área do Contrato',
          value: '{{contract_area}}',
        },
        {
          label: 'Índice de Reajuste',
          value: '{{readjustment_index}}',
        },
        {
          label: 'Multa',
          value: '{{fine}}',
        },
        {
          label: 'Diária de Viagem',
          value: '{{travel_alowance}}',
        },
        {
          label: '',
          value: '{{gain}}',
        },
        {
          label: 'Ato de Serviço Não Local',
          value: '{{non_local_service}}',
        },
        {
          label: 'Ato de Serviço Local',
          value: '{{local_service}}',
        },
        {
          label: 'KM rodado comarca',
          value: '{{km_comarca}}',
        },
        {
          label: 'KM rodado fora',
          value: '{{km_out}}',
        },
        {
          label: 'Representante Legal',
          value: '{{client_agent_legal}}',
        },
        {
          label: 'Email Representante Legal',
          value: '{{client_agent_email}}',
        },
        {
          label: 'Cpf Representante Legal',
          value: '{{client_agent_cpf}}',
        },
        {
          label: 'Telefone Representante Legal',
          value: '{{client_agent_whatsapp}}',
        },
        {
          label: 'Observação',
          value: '{{obs}}',
        },
      ]
    : [
        {
          label: 'CNPJ do Contato',
          value: '{{document}}',
        },
        { label: 'Nome do Contato', value: '{{name}}' },
        {
          label: 'Endereço do Contato',
          value:
            '{{address}}, {{complement}}, {{province}}, {{city}}, {{state}} - {{zipcode}}',
        },
        {
          label: 'Whatsapp do Contato',
          value: '{{whatsapp}}',
        },
        {
          label: 'Telefone fixo do Contato',
          value: '{{phone}}',
        },
        {
          label: 'Email do Contato',
          value: '{{email}}',
        },
        {
          label: 'Data de nascimento',
          value: '{{birth}}',
        },
      ];

  return (
    <div className="w-full my-1">
      <SelectComponent
        closeMenuOnSelect
        value={value}
        options={clientOptions}
        className="w-full"
        placeholder="Adicionar Opções"
        onChange={(e: any) => {
          onChange(e.value);
          setValue(null);
        }}
      />
    </div>
  );
};
