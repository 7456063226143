import React from 'react';
import { Button, Modal } from '../../../../components';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  isLoading: boolean;
  restart: () => void;
}
const ModalRestartCampaign: React.FC<props> = ({
  setShow,
  restart,
  show,
  isLoading,
}) => (
  <Modal
    setIsOpen={setShow}
    isOpen={show}
    title="Retomar o disparo"
    size="medium"
    minHeight="min-h-[200px]"
  >
    <p className="text-sm mb-7">
      Você tem certeza de que deseja retomar o disparo? As mensagens seguirão
      sendo enviadas a partir do ponto de interrupção.
    </p>

    <div className="flex gap-3 mx-auto">
      <Button
        disabled={isLoading}
        className="w-56"
        variant="outline-primary"
        onClick={() => setShow(false)}
      >
        Sair
      </Button>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        className="w-56"
        actionType="button-loading"
        variant="primary-strong"
        onClick={restart}
      >
        Retormar
      </Button>
    </div>
  </Modal>
);

export default ModalRestartCampaign;
