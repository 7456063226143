import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { useFetchMenus, useFetchMenusByProfile } from '../hooks/index';
import { useFetchProfile } from '../../profile/hooks';
import { ApiMenu } from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { useFetchGroup } from '../../group/hooks';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
}

const AssociationMenuModal: React.FC<registerModalProps> = ({
  reloadTable,
  setShow,
  show,
}) => {
  const { GroupList } = useFetchGroup('');
  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(-1);
  const [key, setKey] = useState('key');
  const [keyList, setKeyList] = useState('key');
  const { ProfileList } = useFetchProfile('key');
  const { MenuListByProfile, isLoadingMenuList } = useFetchMenusByProfile(
    key,
    profile
  );

  const listOfMenus = MenuListByProfile.map((item) => item.menu_id);
  const [menusID, setMenusID] = useState<number[]>(listOfMenus);
  const { MenuList, isLoadingMenus } = useFetchMenus(keyList);

  const optionsAdmin = ProfileList.filter((item) => item.is_admin).map(
    (item) => ({ label: item.title, value: item.id, isdisabled: false })
  );
  const optionFranchisor = ProfileList.filter((item) => item.is_franchisor).map(
    (item) => ({ label: item.title, value: item.id, isdisabled: false })
  );
  const optionFranchise = ProfileList.filter((item) => item.is_franchise).map(
    (item) => ({ label: item.title, value: item.id, isdisabled: false })
  );

  const division = [
    [{ label: 'Perfis Administradores', value: 0, isdisabled: true }],
    [{ label: 'Perfis Franqueadora', value: 0, isdisabled: true }],
    [{ label: 'Perfis Franquia', value: 0, isdisabled: true }],
  ];

  const optionsProfiles = division[0]
    .concat(optionsAdmin)
    .concat(division[1])
    .concat(optionFranchisor)
    .concat(division[2])
    .concat(optionFranchise);

  const AddRemoveIdList = (id: number) => {
    if (menusID.includes(id)) {
      setMenusID(menusID.filter((item) => item !== id));
    } else {
      setMenusID([...menusID, id]);
    }
  };

  const Submit = async () => {
    setIsLoading(true);
    const res = await ApiMenu.interligateMenu({
      menus: menusID,
      profile_id: profile,
    });
    if (res === true) {
      reloadTable(`${Math.random()} list`);
      setIsLoading(false);
      notify({ message: 'Menus liberados com sucesso', type: 'Success' });
    } else {
      setIsLoading(false);
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  const FilterIsProfileAdmin = (currentProfile: number, menus?: any[]) => {
    if (optionsAdmin.find((item) => item.value === currentProfile)) {
      return menus?.filter((menuItem) =>
        menuItem.route_frontend.includes('-admin')
      );
    }
    return menus?.filter(
      (menuItem) => !menuItem.route_frontend.includes('-admin')
    );
  };

  useEffect(() => {
    const menus = MenuListByProfile.map((item) => item.menu_id);
    setMenusID(menus);
  }, [MenuListByProfile]);

  useEffect(() => {
    setProfile(-1);
    setMenusID([]);
    setKey(`${Math.random()} menus`);
    setKeyList(`${Math.random()} list`);
  }, [show]);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Liberar Menu"
      size="2xlarge"
      minHeight="min-h-[400px]"
    >
      <SelectComponent
        title="Liberar Menu para o perfil"
        closeMenuOnSelect
        options={optionsProfiles}
        onChange={(e: any) => {
          setProfile(e.value);
          setKey(`${Math.random()} menu`);
        }}
        hasDivision
      />
      {!isLoadingMenuList && !isLoadingMenus ? (
        <div className="flex flex-col h-[50vh] overflow-y-auto">
          {MenuList.length > 0 && (
            <>
              <p className="font-bold mt-3 mb-2">Sem Módulo</p>
              {FilterIsProfileAdmin(
                profile,
                MenuList.filter((item) => item.group_id === null)
              )?.map((item) => (
                <button
                  type="button"
                  className="flex mb-1 items-center w-fit"
                  onClick={() => AddRemoveIdList(item.id)}
                >
                  <input
                    value={item.id}
                    type="checkbox"
                    className="accent-primary"
                    checked={menusID.includes(item.id)}
                    onChange={() => AddRemoveIdList(item.id)}
                  />
                  <p className="ml-2 text-sm">{item.title}</p>
                </button>
              ))}
            </>
          )}
          <div className="flex flex-col my-3">
            {GroupList.map(
              (item) =>
                FilterIsProfileAdmin(profile, item.menu)?.length !== 0 && (
                  <>
                    <p className="font-bold">
                      {item.module?.title} - {item.title}
                    </p>

                    <div className="grid grid-cols-2 mt-2 mb-4">
                      {FilterIsProfileAdmin(profile, item.menu)?.map(
                        (menus) => (
                          <button
                            type="button"
                            className="flex mb-1 items-center w-fit"
                            onClick={() => AddRemoveIdList(menus.id)}
                          >
                            <input
                              value={menus.id}
                              checked={menusID.includes(menus.id)}
                              type="checkbox"
                              className="accent-primary"
                              onChange={() => AddRemoveIdList(menus.id)}
                            />
                            <p className="ml-2 text-sm">{menus.title}</p>
                          </button>
                        )
                      )}
                    </div>
                  </>
                )
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-[50vh]">
          <Spinner />
        </div>
      )}

      <Button
        variant="primary-strong"
        className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        onClick={Submit}
        disabled={isLoading}
      >
        Salvar
        {isLoading && (
          <div className="absolute right-0">
            <Spinner />
          </div>
        )}
      </Button>
    </Modal>
  );
};

export default AssociationMenuModal;
