import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal } from '../../../../components';
import { Product } from '../types';
import { ApiProduct } from '../api';
import { useFetchAllProducts } from '../../../clients/hooks';
import RegisterProduct from '../components/register';

const Products: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterProduct, setModalRegisterProduct] = useState(false);
  const [input, setInput] = useState('');
  const [profileData, setProductData] = useState<Product | null>(null);
  const [key, setKey] = useState('key');

  const { ProductsList, isLoading } = useFetchAllProducts(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Product) => row.title,
    },
    {
      name: '',
      cell: (row: Product) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setProductData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return ProductsList;
    }
    return ProductsList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Produtos"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterProduct}
        setRegisterPage={() => {
          setProductData(null);
        }}
        onRowClick={(e: Product) => {
          setProductData(e);
          setModalRegisterProduct(true);
        }}
        sizeIcon={24}
      />
      <RegisterProduct
        product={profileData}
        reloadTable={setKey}
        setShow={setModalRegisterProduct}
        show={modalRegisterProduct}
      />
      {profileData && (
        <DeleteModal
          apiFunction={ApiProduct.deleteProduct}
          notify={notify}
          type="Produto"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={profileData.id}
          name={profileData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Products;
