export function getStatusStyle(status: string) {
  switch (status) {
    case 'Aberto':
      return <p className="text-[11px] text-black">{status}</p>;
    case 'Ag.Pagamento':
      return (
        <p className="text-[11px] font-medium text-yellow-500">
          Aguardando Pagamento
        </p>
      );
    case 'Ativo':
      return <p className="text-[11px] font-medium text-green">{status}</p>;
    case 'Inadimplente':
      return <p className="text-[11px] font-semibold text-red">{status}</p>;
    case 'Interrompido':
      return (
        <p className="text-[11px] font-semibold text-gray-600">{status}</p>
      );
    case 'Cancelado':
      return (
        <p className="text-[11px] font-semibold text-gray-400">{status}</p>
      );
    default:
      return <p className="text-[11px] font-semibold text-black">{status}</p>;
  }
}
