import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../components';
import { ResponseWinLose } from '../types';
import { ApiResponseWinLose } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  responses: ResponseWinLose | null;
  positiveNegative: boolean;
  companieUserId: number;
}

const RegisterResponseWinLose: React.FC<registerModalProps> = ({
  responses,
  reloadTable,
  setShow,
  show,
  companieUserId,
  positiveNegative,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<ResponseWinLose, 'id'>>({
    is_win: responses?.is_win ?? positiveNegative,
    title: responses?.title ?? '',
    companie_user_id: companieUserId,
    is_active: responses?.is_active,
  });
  const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ];
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (responses !== null) {
      const res = await ApiResponseWinLose.updateResponses(
        {
          title: values.title,
          is_win: positiveNegative,
          is_active: values.is_active,
        },
        responses.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Resposta atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiResponseWinLose.createResponses({
        ...values,
        is_win: positiveNegative,
      });
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Resposta criada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      is_win: responses?.is_win ?? false,
      title: responses ? responses?.title : '',
      companie_user_id: companieUserId,
      is_active: responses ? responses?.is_active : false,
    });
  }, [responses, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={responses ? 'Atualizar Resposta' : 'Criar Resposta'}
      size="medium"
      minHeight="min-h-[250px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <SelectComponent
          title="Status"
          options={statusOptions}
          onChange={(selectedOption: any) => {
            setValues({ ...values, is_active: selectedOption.value });
          }}
          defaultValue={statusOptions.find(
            (option) => option.value === values.is_active
          )}
        />

        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterResponseWinLose;
