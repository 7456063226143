import { baseApiPrivate } from '../../../../api/baseApi';

async function getTotalContractsOpen(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalContractsOpenANDActive/${companyId}/all/${period}`,
  });
}

async function getTotalContractsActive(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalContractsActive/${companyId}/all/${period}`,
  });
}

async function getTotalContractsCanceled(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalContractsCanceled/${companyId}/all/${period}`,
  });
}

async function getContractsByStatus(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/contractsByStatus/${companyId}/all/${period}`,
  });
}

async function getContractTopSellers(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/contractTopSellers/${companyId}/all/${period}`,
  });
}

async function getContractTopSalesChannel(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/contractTopSalesChannel/${companyId}/all/${period}`,
  });
}

async function getContractTopSegment(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/contractTopSegment/${companyId}/all/${period}`,
  });
}

async function getContractCanceledBySeller(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/contractCanceledBySeller/${companyId}/all/${period}`,
  });
}

export const ApiDashboard = {
  getTotalContractsOpen,
  getTotalContractsActive,
  getTotalContractsCanceled,
  getContractsByStatus,
  getContractTopSellers,
  getContractCanceledBySeller,
  getContractTopSegment,
  getContractTopSalesChannel,
};
