import React, { useEffect, useState } from 'react';
import { Button, Card, Input, SelectComponent } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { dictionaryError } from '../../../helpers/utils.helper';
import { useFetchAllProducts } from '../../clients/hooks';
import { useFetchFunnel, useFetchStep } from '../../funnel/hooks';
import { OpportunityProps } from '../../opportunity/types';
import { useFetchResponses } from '../../responseWinLose/hooks';
import { useFetchSalesChannel } from '../../salesChannel/hooks';
import { useFetchUsersCompanie } from '../../users/hooks';
import { ApiTransferOpportunity } from '../api';
import FilterModal from '../components/filter';
import {
  useFetchAllOpportunities,
  useFetchAllOpportunitiesTransfer,
} from '../hooks';
import TableWithPaginationBack from '../../../components/Table/tableWithPaginationBack';

const TransferOpportunity: React.FC = () => {
  const { notify } = ToastNotify();
  const accountInformation = getTokenAccountInformation();
  const [funnelId, setFunnelId] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [userId, setUserId] = useState<{
    label: string;
    value: number;
  } | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [opportunitiesCheck, setOpportunitiesCheck] = useState<number[]>([]);

  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const defaultValues = {
    companieUserId: [],
    funnelId: null,
    opportunityId: null,
    responses_win_lose_id: [],
    salesChannelId: [],
    productId: [],
    order: 'title_asc',
    status: 'Todos',
    skip: startData,
    take: 30,
    period: 'all',
    funnelStepId: null,
  };
  const [filters, setFilters] = useState<{
    start?: Date;
    end?: Date;
    opportunityId: number | null;
    productId: number[];
    salesChannelId: number[];
    responses_win_lose_id: number[];
    companieUserId: number[];
    funnelId: null | number;
    funnelStepId: number | null;
    order: string;
    status: string;
    skip: number;
    take: number;
    period: string;
  }>(defaultValues);
  const [key, setKey] = useState('');
  const { OpportunitiesList, updateOpportunities, setIsLoading, isLoading } =
    useFetchAllOpportunitiesTransfer(
      key,
      accountInformation.companie_id,
      filters.period,
      filters
    );

  const [step, setstep] = useState<{
    label: string;
    value: number;
  } | null>(null);

  const { AllOpportunitiesList } = useFetchAllOpportunities(
    '',
    accountInformation.companie_id
  );

  const opportunityOptions = AllOpportunitiesList.map((opportunity) => ({
    label: opportunity.title,
    value: opportunity.id,
  }));

  const { ProductsList } = useFetchAllProducts('key');
  const productOptions = ProductsList.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const { SalesChannelList } = useFetchSalesChannel(
    'key',
    accountInformation.companie_id
  );
  const salesChannelOptions = SalesChannelList.map((sale) => ({
    value: sale.id,
    label: sale.title,
  }));

  const { UsersList } = useFetchUsersCompanie(
    'key',
    accountInformation.companie_id
  );
  const optionsUserList = UsersList.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const { FunnelsList } = useFetchFunnel('key', accountInformation.companie_id);
  const optionsFunnel = FunnelsList.map((user) => ({
    label: user.title,
    value: user.id,
  }));

  const { ResponsesList } = useFetchResponses(
    'key',
    accountInformation.companie_id
  );

  const positiveResOptions = ResponsesList.filter((item) => item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );

  const negativeResOptions = ResponsesList.filter((item) => !item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );

  const division = [
    [{ label: 'Respostas de Perda', value: 0, isdisabled: true }],
    [{ label: 'Respostas de Ganho', value: 0, isdisabled: true }],
  ];

  const responseOptions = division[0]
    .concat(negativeResOptions)
    .concat(division[1])
    .concat(positiveResOptions);

  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);

  const column = [
    {
      name: 'Oportunidade',
      key: 'title',
      selector: (row: OpportunityProps) => row.title,
    },
    {
      name: 'Organização',
      key: 'companie_user.name',
      selector: (row: OpportunityProps) => row.companie_user.name,
    },
    /*     { 
      name: 'Canal de vendas',
      key: 'companie_user.name',
      selector: (row: OpportunityProps) => row.sales_channel.title,
    }, */ /* TODO: NÃO ESTÁ VINDO DO BACK */
    {
      name: 'Funil',
      key: 'funnel_step.funnel.title',
      selector: (row: OpportunityProps) =>
        FunnelsList.find((item) => item.id === row.funnel_step.funnel_id)
          ?.title ?? '',
    },
    {
      name: 'Etapa',
      key: 'step',
      selector: (row: OpportunityProps) => row.funnel_step.title,
    },
    {
      name: 'Data Criação',
      key: 'created_at',
      selector: (row: OpportunityProps) =>
        maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: (
        <Input
          type="checkbox"
          classNameDiv="accent-primary mt-3 cursor-pointer"
          checked={masterCheckboxChecked}
          onClick={(e: any) => {
            const isChecked = e.target.checked;
            if (isChecked) {
              setOpportunitiesCheck(
                OpportunitiesList.data.map((item) => item.id)
              );
            } else {
              setOpportunitiesCheck([]);
            }
            setMasterCheckboxChecked(isChecked);
          }}
        />
      ),
      key: '',
      cell: (row: OpportunityProps) => (
        <Input
          type="checkbox"
          className="accent-primary cursor-pointer"
          checked={opportunitiesCheck.includes(row.id)}
          onChange={() => {
            if (opportunitiesCheck.includes(row.id)) {
              setOpportunitiesCheck(
                opportunitiesCheck.filter((id) => id !== row.id)
              );
            } else {
              setOpportunitiesCheck([...opportunitiesCheck, row.id]);
            }
          }}
        />
      ),
    },
  ];

  const { StepList } = useFetchStep('', funnelId?.value ?? -1);
  const optionsSteps = StepList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const [ErroMessage, setErroMessage] = useState<string>();

  const TransferOpportunitySubmit = async () => {
    if (funnelId && !step) {
      setErroMessage('Campo obrigatório');
      return;
    }
    if (userId) {
      const payload = {
        companie_user_id: userId.value,
        opportunitys: opportunitiesCheck,
        funnel_id: funnelId ? funnelId.value : null,
        funnel_step_id: step?.value ?? null,
      };
      setIsLoading(true);
      const res = await ApiTransferOpportunity.transferOpportunity(payload);
      if (res === true) {
        notify({
          message:
            opportunitiesCheck.length === 1
              ? 'Oportunidade tranferida com sucesso'
              : 'Oportunidades tranferidas com sucesso',
          type: 'Success',
        });
        setOpportunitiesCheck([]);
        setIsLoading(false);
        setKey(String(Math.random()));
        setMasterCheckboxChecked(false);
      } else {
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setFilters({ ...filters, skip: startData });
  }, [startData]);

  return (
    <>
      <FilterModal
        filters={filters}
        setFilters={setFilters}
        FunnelOptions={optionsFunnel}
        show={showFilter}
        setShow={setShowFilter}
        setTableData={updateOpportunities}
        companyId={accountInformation.companie_id}
        opportunityList={opportunityOptions}
        productList={productOptions}
        responseOptions={responseOptions}
        salesChannelOptions={salesChannelOptions}
        userOptions={optionsUserList}
      />
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Transferir oportunidades" size={24} />
        <strong className="text-lg text-black">Transferir Oportunidades</strong>
      </div>
      <Card className="mt-6 p-7 flex-col relative max-w-full mb-5 sm:mb-0">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="mb-3 sm:mb-0">
            <p className="text-sm mb-7 font-medium">
              Transferir oportunidades de um usuário para outro
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-3 mb-4">
          <SelectComponent
            closeMenuOnSelect
            options={optionsUserList}
            title="Transferir para"
            value={userId}
            onChange={(e: any) => setUserId(e)}
          />
          <SelectComponent
            value={funnelId}
            onChange={(e: any) => {
              if (e !== null) {
                setFunnelId(e);
              } else {
                setFunnelId(null);
                setstep(null);
              }
            }}
            closeMenuOnSelect
            options={optionsFunnel}
            title="Funil"
            isClearable
          />
          <SelectComponent
            isDisabled={!funnelId}
            value={step}
            onChange={setstep}
            closeMenuOnSelect
            options={optionsSteps}
            title="Etapa"
            isClearable
            maxOptionsHeight="150px"
            errorMensage={ErroMessage}
          />
          <div className="flex items-end mb-1.5 justify-end gap-3">
            <Card
              className="border border-solid border-primary w-11 h-11 rounded-lg items-center justify-center cursor-pointer text-primary"
              onClick={() => setShowFilter(true)}
            >
              <IconDictionary name="Filtro" size={20} />
            </Card>

            <Button
              title={
                opportunitiesCheck.length === 0 || userId === null
                  ? 'Você deve selecionar um usuário e pelo menos uma oportunidade'
                  : ''
              }
              className="w-32 text-white mt-3 h-11 disabled:cursor-not-allowed"
              variant="primary-strong"
              onClick={TransferOpportunitySubmit}
              disabled={opportunitiesCheck.length === 0 || userId === null}
            >
              Transferir ({opportunitiesCheck.length})
            </Button>
          </div>
        </div>

        <TableWithPaginationBack
          setStartDate={setStartData}
          totalRegisters={OpportunitiesList.total}
          currentPage={currentPage}
          totalPerPage={30}
          isLoading={isLoading}
          columns={column}
          data={OpportunitiesList.data}
          setCurrentPage={setCurrentPage}
        />

        <br />
      </Card>
    </>
  );
};

export default TransferOpportunity;
