/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, ReactElement } from 'react';

interface PopOverProps {
  TriggerButton: ReactElement;
  Content: (closePop?: () => void) => React.ReactNode;
}

const ModalPopover: React.FC<PopOverProps> = ({ TriggerButton, Content }) => (
  <Popover className="relative">
    {({ open, close }) => (
      <div>
        <Popover.Button
          className={`
                  ${open ? '' : 'text-opacity-90'}
                   inline-flex hover:text-opacity-100 focus:outline-none`}
        >
          {TriggerButton}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 w-fit max-w-sm px-4 mt-0 transform -translate-x-1/2 -right-36 sm:px-0 lg:max-w-3xl">
            {Content(close)}
          </Popover.Panel>
        </Transition>
      </div>
    )}
  </Popover>
);

export default ModalPopover;
