import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { Group } from '../types';
import { ApiGroup } from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  group: Group | null;
  modulesOptions: { label: string; value: number }[];
}

const RegisterGroup: React.FC<registerModalProps> = ({
  group,
  reloadTable,
  setShow,
  show,
  modulesOptions,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<Group, 'id'>>({
    title: group?.title ?? '',
    module_id: group?.module_id ?? -1,
  });

  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (group !== null) {
      const res = await ApiGroup.updateGroup(values, group.id);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Grupo atualizado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiGroup.createGroup(values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Grupo criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      title: group?.title ?? '',
      module_id: group?.module_id ?? -1,
    });
  }, [group, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={group ? 'Atualizar Grupo' : 'Criar Grupo'}
      size="medium"
      minHeight="min-h-[370px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <SelectComponent
          title="Módulos"
          options={modulesOptions}
          closeMenuOnSelect
          maxOptionsHeight="140px"
          value={modulesOptions.find(
            (element) => element.value === values.module_id
          )}
          onChange={(e: any) => {
            setValues((prev) => ({ ...prev, module_id: e.value }));
          }}
        />
        <Button
          disabled={
            values.title.length === 0 || values.module_id === -1 || isLoading
          }
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterGroup;
