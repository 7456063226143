import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Modal } from '../../../../components';

const helperVideosEmbed = {
  segment: 'https://www.youtube.com/embed/BgrkeQENOXE?',
  'activities-type': 'https://www.youtube.com/embed/jha1HRUIoos?',
  contacts: 'https://www.youtube.com/embed/UoF_bfepa3A?',
  contracts: 'https://www.youtube.com/embed/1IkjftUBeqs?',
  'contracts-settings': 'https://www.youtube.com/embed/kHdg3JjCcJM',
  'offer-custom': 'https://www.youtube.com/embed/KknWIL6lIg8',
  user: 'https://www.youtube.com/embed/ddM3n5mx71Q',
  'access-companie-group': 'https://www.youtube.com/embed/QrudhBzrFN8',
  'menus-profile': 'https://www.youtube.com/embed/hc1bJYH9nT0',
  'companie-custom': 'https://www.youtube.com/embed/Y39SintVgE4',
  'input-automations': 'https://www.youtube.com/embed/AtwS6I1OPpk',
  'input-automationsform': 'https://www.youtube.com/embed/AtwS6I1OPpk',
  'input-automationsspreadsheet': 'https://www.youtube.com/embed/ogHKp8ZZ26Y',
  'input-automationsapi': 'https://www.youtube.com/embed/tINxqldtCHQ',
  opportunity: 'https://www.youtube.com/embed/TcsR5oCpYH4?',
  opportunityhome: 'https://www.youtube.com/embed/TcsR5oCpYH4?',
  opportunityupdate: 'https://www.youtube.com/embed/vx0xRhhb7Tc?',
  opportunitysearch: 'https://www.youtube.com/embed/AhZ5CXK6YvI',
  'search-form': 'https://www.youtube.com/embed/Br6exo0oWpQ',
  offer: 'https://www.youtube.com/embed/LLsU4sIgV_0?',
  funnel: 'https://www.youtube.com/embed/6EpjzCihj5U',
  'digital-signature-credit': 'https://www.youtube.com/embed/Zinr1ITilNE',
  'sales-channel': 'https://www.youtube.com/embed/mICsAsvHBGQ',
  activities: 'https://www.youtube.com/embed/loD_PF--Mg4?',
  'transfer-opportunity': 'https://www.youtube.com/embed/H8bC34Ys3W0?',
  client: 'https://www.youtube.com/embed/katg_ZlYmtk?',
  provider: 'https://www.youtube.com/embed/NWvuy22nMpg?',
  products: 'https://www.youtube.com/embed/1pU4LsPiS8M',
  'responses-win': 'https://www.youtube.com/embed/GLMkJ1pr5Pg',
  'responses-lose': 'https://www.youtube.com/embed/B1p_0wuQgxU',
  'plan-accounts': 'https://www.youtube.com/embed/S5LwVRhkhXs?',
  'accounts-pay-receive': 'https://www.youtube.com/embed/DwLY9B0F0eo?',
  tags: 'https://www.youtube.com/embed/SI7ufBoJQRw?',
  instances: 'https://www.youtube.com/embed/iCPsOn4n8VE?',
  'message-default': 'https://www.youtube.com/embed/_vmQbE_RMsY?',
  'bulk-shipping': 'https://www.youtube.com/embed/ACZkx8GzAH0?',
  'reports-balancete': 'https://www.youtube.com/embed/-MUB8ebw5CU?',
  'reports-accounts-pay-receive': 'https://www.youtube.com/embed/0vQuFvs98jE?',
  'reports-cash-flow': 'https://www.youtube.com/embed/5_wSBSLLF84?',
  'reports-opportunity': 'https://www.youtube.com/embed/VotyrOzGJzU?',
  'report-campaign': '',
  'bi-funnel': 'https://www.youtube.com/embed/gX-VqmAj4TU?',
  'bi-contracts': 'https://www.youtube.com/embed/XijNSopxmys?',
  'bi-financial': 'https://www.youtube.com/embed/aC9_iZtg7SY?',
};

const ExplanatoryVideos = () => {
  const [showModal, setShowModal] = useState(false);
  const { pathname, hash } = useLocation();
  const key = `${pathname.split('/').pop() ?? ''}${hash}`.replace('#', '');

  const helperVideoURL =
    helperVideosEmbed[key as keyof typeof helperVideosEmbed];

  useEffect(() => {
    if (!helperVideoURL && showModal) setShowModal(false);
  }, [helperVideoURL, showModal]);

  const CurrentHelperVideo = useMemo(
    () => (
      <div className="h-full w-full">
        {Boolean(helperVideoURL) && (
          <iframe
            width="100%"
            height="100%"
            src={helperVideoURL}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        )}
      </div>
    ),
    [helperVideoURL]
  );

  if (!helperVideoURL) return null;

  return (
    <>
      <Button
        type="button"
        className="fixed right-2 bottom-2 rounded px-4 z-50"
        onClick={() => setShowModal(true)}
      >
        Ajuda
      </Button>
      <Modal
        title="Ajuda"
        setIsOpen={setShowModal}
        isOpen={showModal}
        size="4xlarge"
      >
        <div className="h-[500px]">{CurrentHelperVideo}</div>
      </Modal>
    </>
  );
};

export default ExplanatoryVideos;
