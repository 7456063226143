import React from 'react';
import { Button, Modal } from '../../../../../../components';

interface props {
  show: boolean;
  setShow: () => void;
  close: () => void;
  edit: 'edição' | 'criação';
}
const ModalAlert: React.FC<props> = ({ show, close, setShow, edit }) => (
  <Modal
    title={`Fechar ${edit} da Conta`}
    setIsOpen={setShow}
    isOpen={show}
    size="medium"
    minHeight="min-h-[200px]"
  >
    <p className="text-sm">
      Existem dados alterados que ainda não foram salvos. Tem certeza que deseja
      sair?
    </p>
    <div className="flex w-full items-center justify-center gap-3 mt-5">
      <Button className="w-64" variant="outline-primary" onClick={setShow}>
        Retornar
      </Button>
      <Button
        className="w-64"
        variant="primary-strong"
        actionType="button-loading"
        onClick={close}
      >
        Sair
      </Button>
    </div>
  </Modal>
);

export default ModalAlert;
