import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isLoadingApplication } from '../../../components';
import { logiParams } from '../api';
import { saveParamLocal } from '../utils';

const LoginLP: React.FC = () => {
  const { email } = useParams();
  const { setState } = useContext(isLoadingApplication);

  useEffect(() => {
    setState(true);
    const validate = async (emailParam: string) => {
      const login = await logiParams({
        email: emailParam,
        password: 'IHthn4KY4jELxt9JPjOL',
      });
      if (login.token) {
        saveParamLocal(login);
        setState(true);

        window.location.href = '/';
      }
      setState(true);
    };
    if (email) {
      validate(email);
    }
  }, []);
  return <> </>;
};

export default LoginLP;
