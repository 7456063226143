/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal, Switch, Table } from '../../../../../components';
import { UserProps } from '../../../types';
import { ChangeStatusUser } from '../../../utils';

interface props {
  setModalChangeState: (e: boolean) => void;
  ModalChangeState: boolean;
  userList: UserProps[];
  reload: (e: string) => void;
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
  currentPlanLimit: number;
}

const ModalLimitUsers: React.FC<props> = ({
  ModalChangeState,
  setModalChangeState,
  reload,
  userList,
  isLoading,
  setIsLoading,
  currentPlanLimit,
}) => {
  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: UserProps) => row.name,
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: UserProps) => row.email,
      hide: 800,
    },
    {
      name: 'Status',
      cell: (row: UserProps) => (
        <Switch
          state={row.is_active}
          onChange={() =>
            ChangeStatusUser(row.id, !row.is_active, row, setIsLoading, reload)
          }
        />
      ),
    },
  ];
  return (
    <Modal
      setIsOpen={setModalChangeState}
      isOpen={ModalChangeState}
      title="Limite de usuários excedido"
      size="2xlarge"
      minHeight="min-h-[200px]"
    >
      <div className="flex flex-col text-sm">
        <div className="flex gap-1.5">
          <strong>Limite de usuários ativos:</strong>
          <p>{currentPlanLimit}</p>
        </div>
        <div className="flex gap-1.5">
          <strong>Total de usuários ativos:</strong>
          <p>{userList.filter((item) => item.is_active).length}</p>
        </div>
        <p className="mt-3">
          A quantidade de usuários ativos é maior que o limite do plano atual.
          Por favor desative os usuários necessários ou migre para um plano com
          limite superior
        </p>
        <Table isLoading={isLoading} columns={columns} data={userList} />
        <Button
          className="mx-auto mt-5"
          onClick={() => setModalChangeState(false)}
          actionType="button-loading"
        >
          Salvar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalLimitUsers;
