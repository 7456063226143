import React, { useEffect, useState } from 'react';
import {
  Button,
  DeleteModal,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { ApiOpportunity } from '../../api';
import { OpportunityProps } from '../../types';
import { getIsGenerateContractOpp } from '../../../../helpers/token.helper';
import CreateContractModalAlert from '../update/components/createContract';

/* eslint-disable no-unused-vars */
interface props {
  opportunityTitle: string;
  title: string;
  status: string;
  opportunityId: number;
  responseWinLose: { label: string; value: number }[];
  show: boolean;
  fullPropsOpportunity: OpportunityProps;
  setShow: (e: boolean) => void;
  returnMainPage: () => void;
  updateOpportunity: (e: string) => void;
  openCreateOffer: () => void;
  setState: (e: boolean) => void;
}

const UpdateStatus: React.FC<props> = ({
  opportunityTitle,
  opportunityId,
  openCreateOffer,
  responseWinLose,
  status,
  fullPropsOpportunity,
  title,
  setShow,
  returnMainPage,
  updateOpportunity,
  setState,
  show,
}) => {
  const isGenerateContractOpp = getIsGenerateContractOpp();
  const { notify } = ToastNotify();
  const [winLoseRes, setWinLoseRes] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateContract, setShowCreateContract] = useState(false);
  const [showSelect, setShowSelect] = useState(show);

  const ChangeStatus = async (statusOporrtunity: string) => {
    setIsLoading(true);
    if (statusOporrtunity === 'Ganha') {
      const res = await ApiOpportunity.updateOpportunityStatus(opportunityId, {
        status,
        responses_win_lose_id: winLoseRes,
      });
      if (res.id) {
        if (isGenerateContractOpp === true) return res;
        updateOpportunity(`${Math.random()} status`);
        setShow(false);
        notify({ message: 'Status atualizado com sucesso', type: 'Success' });
        setIsLoading(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else if (statusOporrtunity === 'Perdida') {
      const res = await ApiOpportunity.updateOpportunityStatus(opportunityId, {
        status,
        responses_win_lose_id: winLoseRes,
      });
      if (res.id) {
        updateOpportunity(`${Math.random()} status`);
        setShow(false);
        notify({ message: 'Status atualizado com sucesso', type: 'Success' });
      } else {
        notify({ message: dictionaryError(res), type: 'Success' });
      }
    }

    if (isGenerateContractOpp === true) setShowCreateContract(false);
    setIsLoading(false);
    return false;
  };

  useEffect(() => {
    if (show === true) setShowSelect(show);
  }, [show]);
  return status === 'Excluir' ? (
    <DeleteModal
      apiFunction={ApiOpportunity.deleteOpportunity}
      close={() => setShow(false)}
      id={opportunityId}
      name={opportunityTitle}
      notify={notify}
      open={show}
      type="Oportunidade"
      updateTable={returnMainPage}
      article="A"
    />
  ) : status === 'Ganha' || status === 'Perdida' ? (
    <>
      {showCreateContract && (
        <CreateContractModalAlert
          winRes={winLoseRes}
          personOpportunity={fullPropsOpportunity.opportunity_person[0]}
          opportunityId={opportunityId}
          updateOffer={updateOpportunity}
          setState={() => setState(false)}
          closeOffer={async () => {}}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setShow={() => {
            setShowCreateContract(false);
            setShowSelect(false);
            setShow(false);
          }}
          show={showCreateContract}
        />
      )}
      <Modal
        size="medium"
        minHeight="min-h-[270px]"
        setIsOpen={(e) => {
          setShowSelect(e);
          setShow(e);
        }}
        isOpen={showSelect}
        title={`${title} oportunidade`}
      >
        <div className="flex flex-col">
          <SelectComponent
            options={responseWinLose}
            closeMenuOnSelect
            onChange={(e: any) => setWinLoseRes(e.value)}
            maxOptionsHeight="130px"
          />
          <Button
            onClick={() => {
              if (status === 'Ganha' && isGenerateContractOpp === true) {
                setShowCreateContract(true);
                setShowSelect(false);
              } else {
                ChangeStatus(status);
              }
            }}
            className="w-44 mx-auto mt-2 flex items-center justify-center relative"
            disabled={isLoading || winLoseRes === -1}
          >
            {isLoading && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
            Salvar
          </Button>
        </div>
      </Modal>
    </>
  ) : (
    <> </>
  );
};

export default UpdateStatus;
