import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiOffer } from '../api';
import { isLoadingApplication } from '../../../components';

const OfferPdf: React.FC = () => {
  const { offerId } = useParams();
  const isLoading = useContext(isLoadingApplication);

  async function downloadAndOpenPdf(id: number) {
    isLoading.setState(true);

    try {
      const res = await ApiOffer.getPdfOffer(id);
      const blob = new Blob([res], { type: 'application/pdf' });

      const pdfUrl = URL.createObjectURL(blob);

      window.open(pdfUrl, '_self');
    } catch (error) {
      console.error('Erro ao carregar o PDF:', error);
    } finally {
      isLoading.setState(false);
    }
  }

  useEffect(() => {
    downloadAndOpenPdf(Number(offerId));
  }, []);

  return <> </>;
};

export default OfferPdf;
