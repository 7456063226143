/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { generateHexColor } from '../../../../../helpers/utils.helper';
import { ApiDashboard } from '../../api';
import { reasonsGainedProps } from '../../types';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
}
const GainReasons: React.FC<props> = ({ companyId, period, funnelId }) => {
  const [graph, setGraph] = useState<reasonsGainedProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const reasonsGained = await ApiDashboard.getReasonsGained(
        companyId,
        funnelId,
        period
      );
      if (typeof reasonsGained !== 'string') {
        setGraph(reasonsGained);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period, funnelId]);
  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Top 5 motivos de ganho</p>
      <div className="w-full h-52 flex items-center">
        <Plot
          className="w-full"
          data={[
            {
              x: graph.map((item) => item.title),
              y: graph.map((item) => Number(item._count)),
              marker: {
                color: generateHexColor(graph.length),
              },
              type: 'bar',
              text: graph.map((item) => item._count),
              textinfo: 'value',
            },
          ]}
          config={{
            displayModeBar: false,
            responsive: true,
          }}
          layout={{
            height: 200,
            autosize: true,
            margin: { l: 40, r: 20, b: 80, t: 28 },
            font: {
              size: 12,
            },
            xaxis: {
              showgrid: false,
            },
            yaxis: {
              showgrid: false,
              range: [0, 4],
              autorange:
                graph.reduce((accu, curr) => accu + Number(curr._count), 0) !==
                0,
              tickmode:
                graph.reduce(
                  (a, b) => Math.max(a, Number(b._count)),
                  -Infinity
                ) <= 2
                  ? 'linear'
                  : 'auto',
              tick0: 0,
              tickformat: ' ,d',
            },
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center h-full">
      <Spinner />
    </div>
  );
};

export default GainReasons;
