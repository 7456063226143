import jwtDecode from 'jwt-decode';
import { tokenModel } from '../types';
import { getItem, removeItem, setItem } from './storage.helper';

export function saveToken(token: string) {
  setItem('bearer_token', token);
}

export function getToken() {
  return getItem('bearer_token');
}

export function deleteToken() {
  removeItem('bearer_token');
}

export function decodeToken(token: string) {
  try {
    const tokenDecoded: tokenModel = jwtDecode(token);
    return tokenDecoded;
  } catch {
    return null;
  }
}

export function getTokenAccountInformation(): any {
  const token = getToken();
  return decodeToken(token);
}

export function getIsGenerateContractOpp() {
  const res = getItem('generate_contract_opp');
  return Boolean(res);
}
