import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import IconDictionary from '../Icons/icons';

interface props extends DropzoneOptions {
  title?: string;
  span?: string;
  fileName?: string;
  file: string | null;
  srcImg: string;
}

const UploadFiles: React.FC<props> = ({
  title,
  file,
  span,
  srcImg,
  fileName,
  accept = {},
  disabled = false,
  maxFiles = 1,
  multiple = false,
  onDropAccepted = () => {},
  onError = () => {},
}) => {
  const {
    getRootProps: getRootPropsLastContract,
    getInputProps: getInputPropsLastContract,
    isDragActive: isDragActiveLastContract,
  } = useDropzone({
    accept,
    disabled,
    maxFiles,
    multiple,
    onDropAccepted,
    onError,
  });
  return (
    <div className="flex flex-col gap-3">
      <p>{title}</p>
      <div
        id="form-file-upload"
        className="h-40 w-full flex flex-col text-center relative justify-center items-center"
      >
        <input {...getInputPropsLastContract()} />
        <label
          {...getRootPropsLastContract()}
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={`h-40 w-full flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer 
                ${isDragActiveLastContract && 'border-primary'}`}
        >
          {!file &&
            (!isDragActiveLastContract ? (
              <div className="flex flex-col items-center justify-center p-6">
                <button
                  className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                  type="button"
                >
                  <IconDictionary
                    name="add-photo"
                    size={28}
                    className={disabled ? 'text-gray' : 'text-primary'}
                  />
                  <span
                    className={`text-sm font-bold ${
                      disabled ? 'text-gray-400' : 'text-primary'
                    } `}
                  >
                    {span}
                  </span>
                </button>
                <p className="text-xs text-gray-400 font-medium">
                  ou arraste e solte aqui
                </p>
              </div>
            ) : (
              <p className="text-sm font-bold text-primary">
                {' '}
                Solte o arquivo aqui...
              </p>
            ))}
          {file && (
            <div className="flex flex-col items-center relative justify-center max-w-full px-1">
              <img
                src={srcImg}
                alt="logo"
                className="h-28 object-center overflow-hidden object-contain"
              />

              {file.includes('pdf') && (
                <p className="text-xs mt-1 font-medium max-w-full truncate">
                  {fileName}
                </p>
              )}
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

export default UploadFiles;
