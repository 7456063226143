/* eslint-disable no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';

interface props {
  indexCard: number;
  text: string;
  setText: (e: string, i?: number) => void;
  options: {
    title: string;
    is_active: boolean;
  }[];
  setOptions: (
    e: {
      title: string;
      is_active: boolean;
    }[]
  ) => void;
}

const MultipleRadio: React.FC<props> = ({
  setText,
  text,
  options,
  indexCard,
  setOptions,
}) => {
  const [style, setStyle] = useState<{
    title: string;
    is_active: boolean;
  }>({
    title: '',
    is_active: false,
  });
  const [hasOthersOption, setHasOthersOptions] = useState(false);
  const [newElemet, setNewElement] = useState<{ id: number } | null>(null);

  const addOptionAtIndex = (index: number) => {
    setNewElement({ id: index + 1 });
    const newOptions = [...options];
    newOptions.splice(index + 1, 0, {
      is_active: true,
      title: `Opção ${newOptions.length + 1}`,
    });
    setOptions(newOptions);
  };

  const removeOptionAtIndex = (index: number) => {
    if (index >= 0 && index < options.length) {
      const newOptions = [...options];
      newOptions.splice(index, 1);
      setOptions(newOptions);
    }
  };

  useEffect(() => {
    if (newElemet) {
      document.getElementById(`op_${newElemet.id}`)?.focus();
      setNewElement(null);
    }
  }, [options]);

  useEffect(() => {
    if (options.length === 0) {
      setOptions([{ title: 'Opção 1', is_active: true }]);
    }
  }, []);

  return (
    <div className="w-full sm:w-2/3 text-sm">
      <input
        type="text"
        id={`${indexCard}-title`}
        defaultValue={text}
        onFocus={() => setStyle({ ...style, is_active: true })}
        onBlur={(e) => setText(e.target.value)}
        className={`${style.title} focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
      />
      <div className="mt-3">
        {options.map((item, index) => (
          <div className="flex gap-2 text-xs w-full">
            <input type="radio" id={item.title} checked={false} disabled />
            <input
              type="text"
              id={`op_${index}`}
              defaultValue={item.title}
              onKeyDown={(e) => {
                if (e.key === 'Enter') addOptionAtIndex(index);
              }}
              onFocus={() => setStyle({ ...style, is_active: true })}
              onBlur={(e) =>
                e.target.value.length === 0
                  ? setText(`Opção ${index + 1}`, index)
                  : setText(e.target.value, index)
              }
              className=" focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {options.length > 1 && (
              <IconDictionary
                name="Excluir"
                className="place-self-end cursor-pointer"
                onClick={() => removeOptionAtIndex(index)}
              />
            )}
          </div>
        ))}
      </div>

      <div className="flex gap-1 text-xs items-center mb-8 mt-2">
        <input type="radio" id="add" checked={false} disabled />
        <button
          type="button"
          onClick={() => {
            addOptionAtIndex(options.length);
          }}
          className="hover:underline"
        >
          Adicionar opção
        </button>
        {!hasOthersOption && (
          <>
            <p>ou</p>
            <button
              type="button"
              onClick={() => {
                setOptions([
                  ...options,
                  { is_active: false, title: 'Outros...' },
                ]);
                setHasOthersOptions(true);
              }}
              className="text-indigo-600 hover:text-indigo-400"
            >
              adicionar &quot;Outro&quot;
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MultipleRadio;
