import { useEffect, useState } from 'react';
import { ApiGroup } from '../api';
import { Group } from '../types';

export function useFetchGroup(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Group[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiGroup.getAllGroup();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { GroupList: data, isLoadingGroup: isLoading };
}

export function useFetchGroupByModule(moduleId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Group[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res =
        moduleId !== -1 ? await ApiGroup.getGroupByModule(moduleId) : [];
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [moduleId]);
  return { GroupByModuleList: data, isLoadingGroupByModule: isLoading };
}
