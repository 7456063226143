import { baseApiPrivate } from '../../../api/baseApi';
import { createChild } from '../types';

async function getChartsAccounts() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/plan-accounts`,
  });
}

async function getChildPlan(type: 'R' | 'D', id: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/plan-accounts/${type}/${id}`,
  });
}

async function createPlan(e: createChild) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/plan-accounts',
    body: e,
  });
}

async function updatePlanAccount(e: any, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/plan-accounts/${id}`,
    body: e,
  });
}

async function deleteActivitie(id: number) {
  if (id === -1) return '';
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/plan-accounts/${id}`,
  });
}

export const ApiPlansAccount = {
  getChartsAccounts,
  getChildPlan,
  createPlan,
  updatePlanAccount,
  deleteActivitie,
};
