import { useEffect, useState } from 'react';
import { getWhatsappInstancesByPlan } from '../api';

export function useFetchWhatsappInstances(planId: number, key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const opportunity = await getWhatsappInstancesByPlan(planId);
      if (typeof opportunity !== 'string') {
        setData(opportunity);
      }
      setIsLoading(false);
    };
    if (planId !== -1) {
      FetchCompany();
    }
  }, [planId, key]);
  return {
    InstancesWhatsApp: data,
    isLoadingInstancesWhatsApp: isLoading,
  };
}
