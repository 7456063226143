import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContract } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { Button, Modal } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
/* eslint-disable no-unused-vars */
interface DeleteProps {
  id: number;
  galaxy: { galax_pay_id_subs: number; payment_link: string };
  close: () => void;
  open: boolean;
  updateTable: () => void;
}
const ReopenContract: React.FC<DeleteProps> = ({
  close,
  id,
  open,
  galaxy,
  updateTable,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successOpening, setSuccessOpening] = useState(false);
  const { notify } = ToastNotify();
  const handleDelete = async () => {
    setIsLoading(true);
    const res = await ApiContract.reopenContract(id, galaxy.galax_pay_id_subs);
    if (res.type) {
      setIsLoading(false);
      notify({
        message: `Contrato reaberto com sucesso`,
        type: 'Success',
      });
      updateTable();
      close();
    } else {
      setIsLoading(false);
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };
  return (
    <Modal
      setIsOpen={close}
      title={!successOpening ? 'Reativar contrato' : 'Dados contrato'}
      isOpen={open}
      size="medium"
      minHeight="min-h-[200px]"
    >
      {!successOpening ? (
        <>
          <p className="text-sm mb-10">Deseja reativar este contrato?</p>
          <div className="flex gap-3 w-full mt-6 justify-center">
            <Button variant="primary-strong" onClick={close} className="w-64">
              Voltar
            </Button>
            <Button
              className="w-64 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
              variant="outline-primary"
              disabled={isLoading}
              onClick={handleDelete}
              actionType="button-loading"
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          </div>
        </>
      ) : (
        <div>
          <div className="flex items-center gap-1.5 mb-5 border border-dashed border-green p-3 rounded">
            <IconDictionary
              name="Circulo Confirmar"
              size={28}
              className="text-green"
            />{' '}
            <p className="text-sm">Contrato reaberto com sucesso</p>
          </div>
          <Link
            target="_blank"
            className="text-sm font-medium text-blue-400 hover:underline"
            to={galaxy.payment_link}
          >
            Link de pagamento
          </Link>

          <Button
            variant="primary-strong"
            className="w-full mt-5"
            onClick={() => {
              close();
              updateTable();
            }}
          >
            Sair
          </Button>
        </div>
      )}
    </Modal>
  );
};
export default ReopenContract;
