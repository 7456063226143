/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Card,
  isLoadingApplication,
  Spinner,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import cpfValidation from '../../../helpers/validations/cpf.validation';
import Footer from '../../login/components/footer';
import Header from '../../login/components/header';
import Menu from '../../login/components/menu';
import { ApiContract } from '../api';
import {
  dictionaryError,
  getUrlPlatform,
  setLayoutColor,
} from '../../../helpers/utils.helper';

const Accept: React.FC = () => {
  const { contractId, companyId } = useParams();
  const [contract, setContract] = useState('<h1>Carregando...</h1>');
  const { setState } = useContext(isLoadingApplication);
  const [isLoading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cpfError, setCpfError] = useState(false);
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const fetchHtmlContract = async () => {
    const res = await ApiContract.getContractHtml(Number(contractId));
    if (typeof res !== 'string') {
      setContract(res.html);
    } else if (res === '"error_contract_not_exist"') {
      ToastNotify().notify({
        message: 'Link de contrato inválido',
        type: 'Error',
      });
      setTimeout(() => {
        window.location.href = getUrlPlatform();
      }, 6000);
    } else {
      ToastNotify().notify({
        message: dictionaryError(res),
        type: 'Error',
      });
      setTimeout(() => {
        window.location.href = getUrlPlatform();
      }, 6000);
    }
    setState(false);
  };

  const getContract = async () => {
    setState(true);
    const contractData = await ApiContract.getContractById(Number(contractId));
    if (contractData && typeof contractData !== 'string') {
      fetchHtmlContract();
      setCpf(contractData.client_document);
      setEmail(contractData.client_email);
    } else if (
      contractData === '"error_contract_not_exist"' ||
      contractData === null
    ) {
      ToastNotify().notify({
        message: 'Link de contrato inválido',
        type: 'Error',
      });
      setState(false);
      setTimeout(() => {
        window.location.href = getUrlPlatform();
      }, 6000);
    } else {
      ToastNotify().notify({
        message: dictionaryError(String(contractData)),
        type: 'Error',
      });
      setState(false);
      setTimeout(() => {
        window.location.href = getUrlPlatform();
      }, 6000);
    }
  };

  useEffect(() => {
    setLayoutColor(Number(companyId), setState);
    getContract();
  }, []);

  useEffect(() => {
    if (cpf.length === 14) {
      if (!cpfValidation.isValid(cpf)) {
        setCpfError(true);
      }
    } else {
      setCpfError(false);
    }
  }, [cpf]);

  const handleAccept = async () => {
    setLoading(true);
    if (cpfError) {
      ToastNotify().notify({
        message: 'CPF inválido',
        type: 'Warning',
      });
      setLoading(false);
      return;
    }
    if (accept) {
      const ip = await fetch('https://api.ipify.org?format=json').then((res) =>
        res.json()
      );
      const res = await ApiContract.acceptContract(Number(contractId), {
        document: cpf.replace(/\D/g, ''),
        email,
        accept_ip: ip.ip,
      });
      if (res.id) {
        ToastNotify().notify({
          message: 'Contrato aceito com sucesso',
          type: 'Success',
        });
        setTimeout(() => {
          window.location.href = res.payment_link;
        }, 3000);
      } else {
        ToastNotify().notify({
          message: 'Erro ao aceitar contrato',
          type: 'Error',
        });
      }
    } else {
      ToastNotify().notify({
        message: 'Aceite os termos para continuar',
        type: 'Warning',
      });
    }
    setLoading(false);
  };

  const exportPDF = async (id: string | undefined) => {
    setLoadingPdf(true);
    try {
      const download = document.createElement('a');
      const res = await ApiContract.saveAsPDFContract(Number(id));
      const blob = new Blob([res]);
      const url = window.URL.createObjectURL(blob);
      download.href = url;
      download.download = `Contrato-${id}.pdf`;
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    } catch (error) {
      ToastNotify().notify({
        message: 'Erro ao exportar PDF',
        type: 'Error',
      });
    }
    setLoadingPdf(false);
  };

  return (
    <div className="containerScreen flex flex-col bg-[#FCF7F2] min-h-screen overflow-hidden justify-between">
      {!active ? (
        <Header active={active} setActive={setActive} outsidePage />
      ) : (
        <Menu setNotActive={setActive} outsidePage />
      )}
      <div className="pt-28 pb-10 w-full h-full flex justify-center px-2 sm:px-8 overflow-y-auto">
        <Card className="z-10 relative min-h-[550px] h-fit text-sm flex-col py-5 px-4 z-10 rounded-xl border-primary border border-solid z-20 mb-2 w-full max-w-[1168px]">
          <div dangerouslySetInnerHTML={{ __html: contract }} />
          <form
            className="flex flex-col justify-between items-center"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex flex-col items-center w-full">
              <hr className="border-primary border border-solid w-full mb-6 mt-6" />
              <div className="flex flex-col items-center mb-6">
                <strong className="text-lg mx-auto mb-4">
                  Confirmar dados
                </strong>
              </div>
            </div>
            <div className="flex flex-col items-center w-full max-w-[500px]">
              <input
                type="text"
                name="CPF"
                id="CPF"
                className={`border border-solid rounded-lg px-4 py-3 w-full ${
                  cpfError ? 'border-red mb-2' : 'border-primary mb-4'
                }`}
                placeholder="Confirmar CPF"
                value={maskHelper.cpf(cpf)}
                onChange={(e) => setCpf(e.target.value)}
                required
              />
              <p className="text-red text-sm mb-4 font-bold text-left w-full">
                {cpfError ? 'CPF inválido' : ''}
              </p>
              <input
                type="email"
                name="Email"
                id="Email"
                className="border-primary border border-solid rounded-lg px-4 py-3 w-full mb-8"
                placeholder="Confirmar Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label
                className="text-primary text-sm font-bold cursor-pointer flex items-center mb-4"
                htmlFor="accept"
              >
                <input
                  type="checkbox"
                  name="accept"
                  id="accept"
                  className="mr-2 border-primary border border-solid rounded-lg w-4 h-4 cursor-pointer"
                  checked={accept}
                  onChange={(e) => setAccept(e.target.checked)}
                  required
                />
                <p className="text-sm font-bold text-primary">
                  Aceito o contrato com as condicoes acima pré estabelecidadas.
                </p>
              </label>
              <div className="h-11 flex flex-row items-center w-full justify-center mt-4 max-sm:flex-col">
                <Button
                  className="text-white rounded-xl py-3 hover:bg-primary/75 transition-all duration-200 flex items-center justify-center mr-4 w-1/2 max-sm:w-full mb-0 max-sm:mb-6 max-sm:mr-0  mt-7 sm:mt-0"
                  type="button"
                  onClick={() => exportPDF(contractId)}
                >
                  <IconDictionary
                    name="PDF"
                    size={18}
                    color="#FFf"
                    className="mr-2"
                  />
                  Exportar PDF
                  {loadingPdf && <Spinner size="w-4 h-4 ml-2" />}
                </Button>
                <button
                  type="button"
                  className="bg-green-600 text-white rounded-xl py-2.5 hover:bg-green-200 transition-all duration-200 flex items-center justify-center w-1/2 max-sm:w-full"
                  onClick={handleAccept}
                  disabled={isLoading}
                >
                  <IconDictionary
                    name="Circulo Confirmar"
                    size={18}
                    color="#FFf"
                    className="mr-2"
                  />
                  Aceitar
                  {isLoading && <Spinner size="w-4 h-4 ml-2" />}
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Accept;
