import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { isDate } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Input,
  InputDate,
  Modal,
  SelectComponent,
  Table,
} from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import maskHelper from '../../../../helpers/mask.helper';
import { ApiContract } from '../../api';
import { useFetchProvider } from '../../../providers/hooks';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import {
  dictionaryError,
  getContrastColorWithOpacity,
} from '../../../../helpers/utils.helper';
import { notify } from '../../../../components/Toast/toast';
import Annotations from './annotations';
import Tags from './tags';
import { DependentsProps, IFormValues } from '../../types';
import { initialFormValues } from '../../utils';

const Dependents: React.FC<DependentsProps> = ({ contractId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [operation, setOperation] = useState<string>('');
  const [dependents, setDependents] = useState<any[]>([]);
  const [reload, setReload] = useState<any>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<number>(-1);
  const [idUpdate, setIdUpdate] = useState<number>(-1);
  const [selectKey, setSelectKey] = useState(0);
  const [tags, setTags] = useState<any>([]);
  const [formValues, setFormValues] = useState<IFormValues>(initialFormValues);
  const [annotations, setAnnotatinos] = useState<any[]>([]);
  const [keyAnnotation, setKeyAnnotation] = useState('key');
  const [keyTags, setKeyTags] = useState('key');
  const [isLoading, setIsLoading] = useState(false);
  const [blockButtons, setBlockButtons] = useState(false);

  const accountProps = getTokenAccountInformation();

  const { ProviderList } = useFetchProvider('', accountProps.companie_id);

  const providersOptions = ProviderList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const today = new Date();
  const schema = yup.object().shape({
    document: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    email: yup
      .string()
      .email('E-mail inválido')
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    whatsapp: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    birth: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (
          typeof originalValue === 'string' &&
          Number.isNaN(Date.parse(originalValue))
        ) {
          return null;
        }
        return isDate(originalValue) ? originalValue : value;
      })
      .typeError('Data de nascimento inválida')
      .max(new Date(), 'Data de nascimento não pode ser no futuro'),
    relationship: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
    name: yup
      .string()
      .required('Nome é obrigatório')
      .typeError('Nome é obrigatório'),
  });

  const { handleSubmit, control, formState, clearErrors, reset } =
    useForm<IFormValues>({
      resolver: yupResolver(schema),
    });

  const pages = [
    { id: 0, title: 'Cadastro de Dependentes' },
    { id: 1, title: 'Anotações' },
    { id: 2, title: 'Tags' },
  ];

  const nextPage = () => {
    if (page < pages.length - 1) {
      setPage(page + 1);
    } else {
      setIsOpen(false);
    }
  };

  const handleGetAnnotations = async (id: number) => {
    const response = await ApiContract.getAnnotation(id);
    setAnnotatinos(response);
  };

  const submitDependent = async (data: IFormValues) => {
    let response: any = '';
    let formattedDate = null;

    if (data.birth) {
      const date = new Date(data.birth);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      formattedDate = `${year}-${month}-${day}`;
    }

    if (operation === 'Create') {
      response = await ApiContract.createDependent({
        ...formValues,
        birth: formattedDate,
        contract_id: contractId,
        document: formValues.document
          ? maskHelper.number(formValues.document)
          : null,
        whatsapp: formValues.whatsapp
          ? maskHelper.number(formValues.whatsapp)
          : null,
        email: formValues.email || null,
        relationship: formValues.relationship || null,
        providers: formValues.providers
          ? formValues.providers.map((provider) => provider.value)
          : [],
      });

      setAnnotatinos([]);
      setKeyAnnotation(`Atualiza ${Math.random}`);
    } else {
      response = await ApiContract.updateDependent(
        {
          ...formValues,
          birth: formattedDate,
          document: formValues.document
            ? maskHelper.number(formValues.document)
            : null,
          whatsapp: formValues.whatsapp
            ? maskHelper.number(formValues.whatsapp)
            : null,
          email: formValues.email || null,
          relationship: formValues.relationship || null,
          providers: formValues.providers
            ? formValues.providers.map((provider) => provider.value)
            : [],
        },
        idUpdate
      );
    }

    if (!(response instanceof String)) {
      if (response.id) {
        setBlockButtons(false);
        setIdUpdate(response.id);
        setReload(`${Math.random()} create`);
        nextPage();
        handleGetAnnotations(idUpdate);
      }

      notify({
        message: !response.id
          ? dictionaryError(response.toString())
          : 'Dependente salvo',
        type: !response.id ? 'Error' : 'Success',
      });
    }
  };

  useEffect(() => {
    const getAllDependents = async () => {
      const res = await ApiContract.getDependents(contractId);
      setDependents(res);
    };
    const loadInitialData = async () => {
      setIsLoading(true);
      await getAllDependents();
      setIsLoading(false);
    };

    loadInitialData();
  }, [reload, keyTags]);

  const handleDelete = async (id: number) => {
    const response = await ApiContract.deleteDependent(id);
    notify({
      message: response ? 'Dependente removido' : 'Desculpe, houve um erro',
      type: response ? 'Success' : 'Error',
    });
    setReload(`${Math.random()} delete`);
    setDeleteModal(false);
  };

  const handleDeleteConfirm = (id: number) => {
    setDeleteModal(true);
    setIdDelete(id);
  };

  const handleCancel = () => {
    setDeleteModal(false);
    setIdDelete(-1);
  };

  const handleGetTags = async (id: number) => {
    setIsLoading(true);
    const response = await ApiContract.getTags(id);
    setTags(response);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchTags = async () => {
      await handleGetTags(idUpdate);
    };
    const loadInitialData = async () => {
      setIsLoading(true);
      await fetchTags();
      setIsLoading(false);
    };
    loadInitialData();
  }, [keyTags]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      await handleGetAnnotations(idUpdate);
    };
    fetchAnnotations();
  }, [keyAnnotation]);

  const columns = [
    {
      name: 'Nome',
      key: 'name',
      selector: (row: any) => row.name,
    },
    {
      name: 'WhatsApp',
      key: 'wpp',
      selector: (row: any) => maskHelper.phone(row.whatsapp ?? ''),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: any) => row.email,
    },
    {
      name: 'Tags',
      key: 'tags',
      selector: (row: any) => (
        <div className="flex flex-wrap gap-2 my-2 w-64">
          {row.contract_dependents_tags.map(
            (item: any) =>
              item.tag.color && (
                <div
                  style={{
                    backgroundColor: `${item.tag.color}`,
                    color: getContrastColorWithOpacity(item.tag.color),
                    border:
                      getContrastColorWithOpacity(item.tag.color) !== '#ffffff'
                        ? `1px solid ${getContrastColorWithOpacity(
                            item.tag.color
                          )}`
                        : '0px',
                  }}
                  className="text-[8px] p-1 rounded-sm truncate max-w-[60px]"
                >
                  {item.tag.title}
                </div>
              )
          )}
        </div>
      ),
    },
    {
      name: '',
      width: '50px',
      cell: (row: any) => (
        <IconDictionary
          color="#DA3C3C"
          name="Excluir"
          size={20}
          className="cursor-pointer"
          onClick={() => handleDeleteConfirm(row.id)}
        />
      ),
    },
  ];

  const columnsProviders: any = [
    {
      name: 'ID fornecedor',
      key: 'value',
      selector: (row: any) => row.value,
      center: true,
    },
    {
      name: 'Nome',
      key: 'value',
      selector: (row: any) => row.label,
      center: true,
    },
  ];

  const handleRowClick = (row: any) => {
    reset();
    clearErrors();
    setBlockButtons(false);
    const date = new Date(row.birth);

    date.setUTCDate(date.getUTCDate() + 1);

    const formattedDate = date.toISOString();

    setFormValues({
      name: row.name,
      whatsapp: row.whatsapp,
      email: row.email,
      birth: formattedDate,
      relationship: row.relationship,
      document: row.document,
      providers: row.contract_dependents_providers.map((item: any) => ({
        label: item.provider.name,
        value: item.provider_id,
      })),
    });
    setOperation('Update');
    setIdUpdate(row.id);
    setIsOpen(true);
    handleGetAnnotations(row.id);
  };

  const openModal = () => {
    setOperation('Create');
    setFormValues(initialFormValues);
    setIdUpdate(-1);
    setAnnotatinos([]);
    setKeyAnnotation(`create_${Math.random()}`);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) setPage(0);
  }, [isOpen]);

  return (
    <div>
      <Button
        variant="primary-strong"
        className="mt-5 w-60 text-sm"
        actionType="button-add"
        onClick={() => {
          openModal();
          setBlockButtons(true);
          reset();
        }}
      >
        Adicionar Dependente
      </Button>

      <Table
        columns={columns}
        data={dependents}
        isLoading={isLoading}
        onRowClick={handleRowClick}
      />
      <Modal
        title="Remover dependente"
        setIsOpen={setDeleteModal}
        isOpen={deleteModal}
        size="2xlarge"
        minHeight="min-h-[300px]"
      >
        <div>
          <strong className="text-center text-lg">
            Tem certeza que deseja remover o dependente ?
          </strong>
          <p className="text-sm mt-4 mb-10">
            Esta ação não pode ser desfeita. O Dependente será removido
            {` `}
            dos nossos serviços.
          </p>
          <div className="flex gap-3 w-full">
            <Button
              variant="primary-strong"
              onClick={handleCancel}
              className="w-1/2"
            >
              Cancelar
            </Button>
            <Button
              className="w-1/2 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
              variant="outline-primary"
              onClick={() => {
                handleDelete(idDelete);
              }}
            >
              Remover
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          operation === 'Update'
            ? 'Atualizar Dependente'
            : 'Cadastrar Dependente'
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size="4xlarge"
      >
        <div className="flex flex-col">
          <div className="flex gap-2 mt-4">
            {pages.map((item) => {
              if (item.id === 1) {
                return (
                  <Button
                    key={item.id}
                    variant={page === item.id ? 'primary-strong' : 'gray'}
                    actionType="button-full-width"
                    className={classNames(['font-medium', 'cursor-pointer'])}
                    disabled={blockButtons}
                    onClick={() => {
                      setPage(item.id);
                      handleGetAnnotations(idUpdate);
                    }}
                  >
                    {item.title}
                  </Button>
                );
              }
              if (item.id === 2) {
                return (
                  <Button
                    key={item.id}
                    variant={page === item.id ? 'primary-strong' : 'gray'}
                    actionType="button-full-width"
                    className={classNames(['font-medium', 'cursor-pointer'])}
                    disabled={blockButtons}
                    onClick={() => {
                      setPage(item.id);
                      handleGetTags(idUpdate);
                    }}
                  >
                    {item.title}
                  </Button>
                );
              }
              return (
                <Button
                  key={item.id}
                  variant={page === item.id ? 'primary-strong' : 'gray'}
                  actionType="button-full-width"
                  className={classNames(['font-medium', 'cursor-pointer'])}
                  disabled={blockButtons}
                  onClick={() => {
                    setPage(item.id);
                  }}
                >
                  {item.title}
                </Button>
              );
            })}
          </div>
          <hr className="w-full h-[1.5px] bg-primary mt-3" />
          {page === 0 && (
            <div>
              <form
                className="grid grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
                onSubmit={handleSubmit(submitDependent)}
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue={formValues.name}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Input
                      {...field}
                      title="Nome"
                      variant="outline-orange"
                      errorMensage={formState.errors.name?.message}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFormValues({ ...formValues, name: newValue });
                        onChange(newValue);
                      }}
                      value={formValues.name ?? ''}
                    />
                  )}
                />
                <Controller
                  name="document"
                  control={control}
                  defaultValue={formValues.document}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Input
                      {...field}
                      title="CPF"
                      placeholder="000.000.000-00"
                      variant="outline-orange"
                      errorMensage={formState.errors.document?.message}
                      value={maskHelper.cpf(formValues.document ?? '')}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/\D/g, '')
                          .substring(0, 11);
                        setFormValues({ ...formValues, document: newValue });
                        onChange(newValue);
                      }}
                    />
                  )}
                />
                <Controller
                  name="birth"
                  control={control}
                  defaultValue={formValues.birth}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <InputDate
                      {...field}
                      title="Data de Nascimento"
                      errorMensage={formState.errors.birth?.message}
                      defaultValue={
                        formValues.birth
                          ? new Date(formValues.birth)
                          : undefined
                      }
                      onChange={(date) => {
                        const newValue = date;
                        setFormValues({ ...formValues, birth: newValue });
                        onChange(newValue);
                      }}
                    />
                  )}
                />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue={maskHelper.phone(formValues.whatsapp ?? '')}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Input
                      {...field}
                      title="WhatsApp"
                      placeholder="(00) 0000-0000"
                      variant="outline-orange"
                      errorMensage={formState.errors.whatsapp?.message}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/\D/g, '')
                          .substring(0, 11);
                        setFormValues({ ...formValues, whatsapp: newValue });
                        onChange(newValue);
                      }}
                      value={maskHelper.phone(formValues.whatsapp ?? '')}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue={formValues.email}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Input
                      {...field}
                      title="E-mail"
                      variant="outline-orange"
                      errorMensage={formState.errors.email?.message}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFormValues({ ...formValues, email: newValue });
                        onChange(newValue);
                      }}
                      value={formValues.email ?? ''}
                    />
                  )}
                />
                <Controller
                  name="relationship"
                  control={control}
                  defaultValue={formValues.relationship}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <Input
                      {...field}
                      title="Parentesco"
                      variant="outline-orange"
                      errorMensage={formState.errors.relationship?.message}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFormValues({
                          ...formValues,
                          relationship: newValue,
                        });
                        onChange(newValue);
                      }}
                      value={formValues.relationship ?? ''}
                    />
                  )}
                />
                <Controller
                  name="providers"
                  control={control}
                  defaultValue={formValues.providers}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <SelectComponent
                      {...field}
                      title="Fornecedores"
                      key={selectKey}
                      options={providersOptions}
                      errorMensage={formState.errors.providers?.message}
                      onChange={(option: { label: string; value: string }) => {
                        const newValue = option.value;
                        const currentProviders = formValues.providers ?? [];
                        const alreadySelected = currentProviders.some(
                          (item: { value: string }) => item.value === newValue
                        );

                        let updatedProviders;

                        if (alreadySelected) {
                          updatedProviders = currentProviders.filter(
                            (item: { value: string }) => item.value !== newValue
                          );
                        } else {
                          updatedProviders = [...currentProviders, option];
                        }
                        setFormValues({
                          ...formValues,
                          providers: updatedProviders,
                        });

                        onChange(newValue);
                      }}
                    />
                  )}
                />
                <div className="col-span-2 flex justify-center w-full">
                  <div className="w-full">
                    <Table
                      columns={columnsProviders}
                      data={formValues.providers ?? []}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="col-span-2 flex justify-center mt-6">
                  <Button
                    type="submit"
                    className="pl-10 pr-10"
                    actionType="button-loading"
                  >
                    Salvar e continuar
                  </Button>
                </div>
              </form>
            </div>
          )}
          {page === 1 && (
            <div className="mt-5">
              <Annotations
                annotations={annotations}
                companieUserId={idUpdate}
                reloadNotes={setKeyAnnotation}
                key={keyAnnotation}
              />
              <div className="col-span-2 flex justify-center mt-6">
                <Button
                  type="submit"
                  className="pl-10 pr-10"
                  actionType="button-loading"
                  onClick={() => {
                    nextPage();
                    handleGetTags(idUpdate);
                  }}
                >
                  Continuar
                </Button>
              </div>
            </div>
          )}

          {page === 2 && (
            <div className="mt-5">
              <Tags
                tags={tags}
                idDependent={idUpdate}
                reloadTags={setKeyTags}
                isLoading={isLoading}
              />
              <div className="col-span-2 flex justify-center mt-6">
                <Button
                  type="submit"
                  className="pl-10 pr-10"
                  actionType="button-loading"
                  onClick={() => {
                    nextPage();
                    setSelectKey((prevKey) => prevKey + 1);
                    setFormValues(initialFormValues);
                  }}
                >
                  Finalizar
                </Button>
              </div>
            </div>
          )}
          <div className="flex w-full gap-2 justify-center mt-9">
            {pages.map((item) =>
              item.id === page ? (
                <div
                  className="h-3 w-3 rounded-full bg-primary"
                  key={item.id}
                />
              ) : (
                <div
                  className="h-3 w-3 rounded-full bg-gray-400"
                  key={item.id}
                />
              )
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Dependents;
