import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
} from '../../../../../components';

import { createUpdateUser, ModalCreateUpdate } from '../../types';
import { ApiUsers } from '../../api';
import { ToastNotify } from '../../../../../components/Toast/toast';

import { useFetchProfile } from '../../../profile/hooks';
import { useFetchPartners } from '../../hooks';

import { dictionaryError } from '../../../../../helpers/utils.helper';

const RegisterUserAdmin: React.FC<ModalCreateUpdate> = ({
  defaultValue,
  reloadTable,
  setShow,
  show,
}) => {
  const status = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];

  const defaultUserValues: Omit<createUpdateUser, 'administrator_id'> = {
    is_active: defaultValue?.is_active,
    email: defaultValue?.email,
    name: defaultValue?.name,
    profile_id: defaultValue?.profile_id,
    partner_id: defaultValue?.partner_id,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<Omit<createUpdateUser, 'administrator_id'>>(defaultUserValues);

  const { notify } = ToastNotify();
  const { ProfileList } = useFetchProfile('key');
  const { PartnersList } = useFetchPartners();

  const profile = ProfileList.filter((item) => item.is_admin === true).map(
    (item) => ({ value: item.id, label: item.title })
  );

  const partners = PartnersList.filter((item) => item.is_active === true).map(
    (item) => ({ value: item.id, label: item.name })
  );

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    is_active: yup.string().required('Selecione uma opção'),
    partner_id: yup.string().required('Parceiro é obrigatório'),
    profile_id: yup.string().required('Perfil é obrigatório'),
  });

  const { handleSubmit, setValue, clearErrors, formState, reset } =
    useForm<createUpdateUser>({
      resolver: yupResolver(schema),
      defaultValues: defaultValue !== null ? defaultUserValues : {},
    });

  const Submit = async (values: createUpdateUser) => {
    if (defaultValue !== null) {
      setIsLoading(true);
      const res = await ApiUsers.updateUser(values, defaultValue.id);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Usuário atualizado com sucesso!', type: 'Success' });
        reloadTable(`${Math.random()} update`);
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiUsers.createAdminUser({
        ...values,
        administrator_id: 1,
      });
      if (response.id) {
        notify({ message: 'Usuário salvo com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setIsLoading(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setDefaultValues({
      is_active: defaultValue?.is_active,
      email: defaultValue?.email,
      name: defaultValue?.name,
      profile_id: defaultValue?.profile_id,
      partner_id: defaultValue?.partner_id,
    });
    reset(defaultUserValues);
    if (defaultValue !== null) {
      clearErrors();
    }
  }, [defaultValue]);

  return (
    <Modal
      title={defaultValue ? 'Atualizar Usuário' : 'Cadastrar Usuário'}
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[530px]"
    >
      <form
        className="flex flex-col gap-3 mt-8"
        onSubmit={handleSubmit(Submit)}
      >
        <Input
          variant="outline-orange"
          title="Name"
          value={defaultValues.name}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, name: e.target.value });
            setValue('name', e.target.value);
          }}
          errorMensage={formState.errors.name?.message}
        />
        <Input
          title="E-mail"
          variant="outline-orange"
          value={defaultValues.email}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, email: e.target.value });
            setValue('email', e.target.value);
          }}
          errorMensage={formState.errors.email?.message}
        />
        <SelectComponent
          title="Parceiros"
          closeMenuOnSelect
          options={partners}
          value={partners.find(
            (item) => item.value === defaultValues.partner_id
          )}
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, partner_id: e.value });
            setValue('partner_id', e.value);
          }}
          errorMensage={formState.errors.partner_id?.message}
        />
        <SelectComponent
          title="Perfil"
          closeMenuOnSelect
          options={profile}
          value={profile.find(
            (item) => item.value === defaultValues.profile_id
          )}
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, profile_id: e.value });
            setValue('profile_id', e.value);
          }}
          errorMensage={formState.errors.profile_id?.message}
        />
        <SelectComponent
          title="Status"
          closeMenuOnSelect
          options={status}
          value={status.find((item) => item.value === defaultValues.is_active)}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              is_active: e.value,
            });
            setValue('is_active', e.value);
          }}
          errorMensage={formState.errors.is_active?.message}
        />
        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RegisterUserAdmin;
