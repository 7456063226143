import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../main/components/mainPage';
import { ToastNotify } from '../../../components/Toast/toast';
import { DeleteModal } from '../../../components';
import { SalesChannel } from '../types';
import { ApiSalesChannel } from '../api';
import { useFetchSalesChannel } from '../hooks';
import RegisterSalesChannel from '../components/register';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

const SalesChannels: React.FC = () => {
  const userProps = getTokenAccountInformation();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterSalesChannel, setModalRegisterSalesChannel] =
    useState(false);
  const [input, setInput] = useState('');
  const [groupData, setSalesChannelData] = useState<SalesChannel | null>(null);
  const [key, setKey] = useState('key');

  const { SalesChannelList: SegmentList, isLoading } = useFetchSalesChannel(
    key,
    userProps.companie_id
  );
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: SalesChannel) => row.id,
    },
    {
      name: 'Nome',
      key: 'title',
      selector: (row: SalesChannel) => row.title,
    },
    {
      name: '',
      cell: (row: SalesChannel) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setSalesChannelData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return SegmentList;
    }
    return SegmentList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Canal de vendas"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterSalesChannel}
        setRegisterPage={() => {
          setSalesChannelData(null);
        }}
        onRowClick={(e: SalesChannel) => {
          setSalesChannelData(e);
          setModalRegisterSalesChannel(true);
        }}
      />
      <RegisterSalesChannel
        companieUserId={userProps.id}
        salesChannel={groupData}
        reloadTable={setKey}
        setShow={setModalRegisterSalesChannel}
        show={modalRegisterSalesChannel}
      />
      {groupData && (
        <DeleteModal
          apiFunction={ApiSalesChannel.deleteSalesChannel}
          notify={notify}
          type="Canal de vendas"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={groupData.id}
          name={groupData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default SalesChannels;
