import { baseApiPrivate } from '../../../api/baseApi';
import { getItem } from '../../../helpers/storage.helper';
import { MenuSideBar } from '../types';

export function getMenus(): MenuSideBar[] {
  return getItem('menu');
}

export async function getActivitiesUser(userId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/activities/userInProgress/${userId}`,
  });
}

export async function getCurrentUserAccess(companieUserId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/accessCompanies/user/${companieUserId}`,
  });
}

export async function changeSession(companieId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/user/accessCompanies/companie/changeSession/${companieId}`,
  });
}
