export const classes = [
  {
    id: 1,
    name: 'Econômica',
  },
  {
    id: 2,
    name: 'Premium/econômica',
  },
  {
    id: 3,
    name: 'Primeira classe',
  },
  {
    id: 4,
    name: 'Executiva/Business',
  },
];

export const ages = [
  {
    id: 0,
    name: 'Até 1 ano',
  },
  {
    id: 1,
    name: '1 ano',
  },
  {
    id: 2,
    name: '2 anos',
  },
  {
    id: 3,
    name: '3 anos',
  },
  {
    id: 4,
    name: '4 anos',
  },
  {
    id: 5,
    name: '5 anos',
  },
  {
    id: 6,
    name: '6 anos',
  },
  {
    id: 7,
    name: '7 anos',
  },
  {
    id: 8,
    name: '8 anos',
  },
  {
    id: 9,
    name: '9 anos',
  },
  {
    id: 10,
    name: '10 anos',
  },
  {
    id: 11,
    name: '11 anos',
  },
  {
    id: 12,
    name: '12 anos',
  },
  {
    id: 13,
    name: '13 anos',
  },
  {
    id: 14,
    name: '14 anos',
  },
  {
    id: 15,
    name: '15 anos',
  },
  {
    id: 16,
    name: '16 anos',
  },
  {
    id: 17,
    name: '17 anos',
  },
];

export const citys = [
  {
    id: 1,
    name: 'Belo Horizonte',
  },
  {
    id: 2,
    name: 'Rio de Janeiro',
  },
];
