import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight';
import DataTable from 'react-data-table-component';
import Button from '../Button/button';

import { headCells, rows, Buttons } from './styles';
import Spinner from '../Spinner/spinner';
import { conditionalRowStyles } from '../../helpers/table.utils';

/* eslint-disable no-unused-vars */
interface ITableProps {
  columns: any[];
  data: any[];
  headerColorTransparent?: boolean;
  setCurrentPage?: (e: number) => void;
  currentPage?: number;
  totalPerPage?: number;
  onSelectedRowsChange?: (row: any) => void;
  isLoading: boolean;
  onRowClick?: (e: any) => void;
  selectedRowId?: number;
  totalRegisters: number;
  setStartDate: (e: number) => void;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
}

const Table: React.FC<ITableProps> = ({
  columns,
  data,
  headerColorTransparent = false,
  selectedRowId = -1,
  currentPage = 1,
  totalPerPage = 10,
  onRowClick,
  totalRegisters,
  onSelectedRowsChange = () => {},
  setCurrentPage = (e: number) => {},
  setStartDate,
  isLoading,
  fixedHeader = false,
  fixedHeaderScrollHeight,
}: ITableProps) => {
  if (headerColorTransparent) {
    headCells.style.background = 'var(--white)';
  }

  function getPagination() {
    const pages: any = [];
    let totalPages = 0;
    totalPages = Math.ceil(totalRegisters / totalPerPage);
    let minLength =
      totalPages > 3 ? (currentPage === 1 ? 0 : currentPage - 4) : 0;
    minLength = minLength < 0 ? 0 : minLength;

    const maxLengthSum = currentPage + 3;
    const maxLength =
      totalPages > 3 && maxLengthSum < totalPages ? maxLengthSum : totalPages;

    for (let i = minLength; i < maxLength; i++) {
      const numberPage = i + 1;
      pages.push(
        <Buttons
          onClick={() => {
            setCurrentPage(numberPage);
            setStartDate((numberPage - 1) * totalPerPage);
          }}
          key={`page_${numberPage}`}
          active={numberPage === currentPage}
          number={numberPage !== currentPage}
        >
          {numberPage}
        </Buttons>
      );
    }

    return (
      <div className="flex w-full justify-center items-center gap-4 mt-2">
        {currentPage > 1 && (
          <Button className="rounded-lg w-7 h-7">
            <KeyboardArrowLeft
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setStartDate((currentPage - 2) * totalPerPage);
              }}
            />
          </Button>
        )}
        <div>{totalPages > 1 && pages.map((item: ReactNode) => item)}</div>

        {currentPage < totalPages && (
          <Button className="rounded-lg w-7 h-7">
            <KeyboardArrowRight
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setStartDate(currentPage * totalPerPage);
              }}
            />
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="relative">
      {isLoading && (
        <div
          role="status"
          className={classNames([
            data.length === 0 ? 'h-20' : 'bg-gray-400/20',
            'z-10 absolute top-0 items-center w-full flex justify-center h-full mt-4',
          ])}
        >
          <Spinner />
        </div>
      )}
      <DataTable
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        title=""
        columns={columns}
        noDataComponent={
          totalRegisters || isLoading ? (
            ''
          ) : (
            <p className="mt-6 text-sm">Nenhum Registo Encontrado</p>
          )
        }
        data={data}
        noHeader
        pagination
        paginationPerPage={totalPerPage}
        paginationComponent={() => getPagination()}
        onRowClicked={onRowClick}
        onSelectedRowsChange={onSelectedRowsChange}
        conditionalRowStyles={conditionalRowStyles(selectedRowId ?? -1)}
        customStyles={{
          headCells,
          rows: rows(Boolean(onRowClick)),
        }}
      />
    </div>
  );
};

export default memo(Table);
