import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spinner } from '../../../components';
import { SalesChannel } from '../types';
import { ApiSalesChannel } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  salesChannel: SalesChannel | null;
  companieUserId: number;
}

const RegisterSalesChannel: React.FC<registerModalProps> = ({
  salesChannel,
  reloadTable,
  setShow,
  show,
  companieUserId,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<SalesChannel, 'id'>>({
    title: salesChannel ? salesChannel?.title : '',
    companie_user_id: companieUserId,
  });
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (salesChannel !== null) {
      const res = await ApiSalesChannel.updateSalesChannel(
        { title: values.title },
        salesChannel.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Canal de vendas atualizado com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiSalesChannel.createSalesChannel(values);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Canal de vendas criado com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      title: salesChannel ? salesChannel?.title : '',
      companie_user_id: companieUserId,
    });
  }, [salesChannel, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={
        salesChannel ? 'Atualizar Canal de Vendas' : 'Criar Canal de Vendas'
      }
      size="medium"
      minHeight="min-h-[250px]"
    >
      <div className="flex flex-col justify-center">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterSalesChannel;
