import classNames from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';

type DivProps = ComponentPropsWithoutRef<'div'>;
const ArrowCard: React.FC<DivProps> = ({ children, className, ...props }) => (
  <div
    className={classNames(
      ['w-full flex rounded justify-between pr-2'],
      className
    )}
    {...props}
  >
    <div className="bg-white w-full p-4 rounded-l-lg flex flex-col">
      {children}
    </div>
    <div className="border-solid border-l-white border-l-[20px] border-y-transparent border-y-[38px] border-r-0" />
  </div>
);

export default ArrowCard;
