import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { generalData, updateformProps } from '../../../types';
import { ApiProvider } from '../../../api';
import { Button, Input } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../../../../helpers/token.helper';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';

interface props extends updateformProps {
  onePage?: boolean;
  // eslint-disable-next-line no-unused-vars
  setProviderDefaultId?: (id: number) => void;
}

export const CreateProviderGeneralData: React.FC<props> = ({
  nextPage,
  defaultValue,
  onePage = false,
  setId,
  notify,
  setProvider,
  setProviderDefaultId = () => {},
}) => {
  const tokenData = getTokenAccountInformation();
  const [isLoading, setIsLoading] = useState(false);

  const [defaultValues, setDefaultValues] = useState<generalData>({
    owner: defaultValue?.owner ?? '',
    system: defaultValue?.system ?? '',
    system_homepage: defaultValue?.system_homepage ?? '',
    cellular: defaultValue?.cellular ?? '',
    name_fantasy: defaultValue?.name_fantasy ?? '',
    phone: defaultValue?.phone ?? '',
    document: defaultValue?.document ?? '',
    email: defaultValue?.email ?? '',
    name: defaultValue?.name ?? '',
    companie_user_id: tokenData.id,
    whatsapp: defaultValue?.whatsapp ?? '',
  });

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    name_fantasy: yup.string(),
    document: yup
      .string()
      .nullable()
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) => {
          if (value === '' || typeof value === 'undefined' || value === null)
            return true;
          return (
            cpfValidation.isValid(maskHelper.number(value)) ||
            cnpjValidation.isValid(maskHelper.number(value))
          );
        },
      }),
    phone: yup
      .string()
      .nullable()
      .test({
        message: 'Número deve conter 11 dígitos',
        test: (value) => {
          if (value === '' || typeof value === 'undefined') return true;
          if (value?.length !== 11) return false;
          return true;
        },
      })
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
    cellular: yup
      .string()
      .nullable()
      .test({
        message: 'Número deve conter 11 dígitos',
        test: (value) => {
          if (value === '' || typeof value === 'undefined') return true;
          if (value?.length !== 11) return false;
          return true;
        },
      })
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
    whatsapp: yup
      .string()
      .nullable()
      .test({
        message: 'Número inválido',
        test: (value) => {
          if (value === '' || typeof value === 'undefined' || value === null)
            return true;
          return phoneValidate.isValid(maskHelper.number(value));
        },
      }),
    email: yup.string().email('E-mail inválido').nullable(),
    owner: yup.string().nullable(),
  });

  const generalValues: Omit<generalData, 'companie_user_id'> | null = {
    document: defaultValue?.document,
    email: defaultValue?.email,
    name: defaultValue?.name ?? '',
    owner: defaultValue?.owner,
    whatsapp: defaultValue?.whatsapp,
    cellular: defaultValue?.cellular,
    name_fantasy: defaultValue?.name_fantasy,
    phone: defaultValue?.phone,
    system: defaultValue?.system,
    system_homepage: defaultValue?.system_homepage,
  };

  const { handleSubmit, setValue, formState } = useForm<generalData>({
    resolver: yupResolver(schema),
    defaultValues: clearNullValuesObject(generalValues),
  });

  const Submit = async (values: generalData) => {
    if (defaultValue !== null) {
      setIsLoading(true);
      const response = await ApiProvider.updateGeneralData(
        values,
        defaultValue.id
      );
      if (!response.id) {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      } else {
        setProvider(response);
        notify({
          message: 'Dados gerais atualizados com sucesso',
          type: 'Success',
        });
        nextPage();
      }
    } else {
      setIsLoading(true);
      const response = await ApiProvider.createProviderGeneralData({
        ...values,
        companie_user_id: tokenData.id,
      });
      if (response.id) {
        if (!onePage)
          notify({
            message: 'Dados gerais salvos com sucesso',
            type: 'Success',
          });
        setId(response.id);
        setProvider(response);
        setProviderDefaultId(response.id);
        nextPage();
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-x-3 gap-y-6 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <Input
        title="Nome / Razão Social"
        variant="outline-orange"
        value={defaultValues.name}
        onChange={(e) => {
          setValue('name', e.target.value);
          setDefaultValues({ ...defaultValues, name: e.target.value });
        }}
        errorMensage={formState.errors.name?.message}
      />
      <Input
        title="Apelido / Nome Fantasia"
        variant="outline-orange"
        value={defaultValues.name_fantasy}
        onChange={(e) => {
          setValue('name_fantasy', e.target.value);
          setDefaultValues({ ...defaultValues, name_fantasy: e.target.value });
        }}
        errorMensage={formState.errors.name_fantasy?.message}
      />
      <Input
        title="CPF / CNPJ"
        variant="outline-orange"
        type="string"
        placeholder="000.000.000-00"
        value={maskHelper.cnpjCpf(defaultValues.document ?? '')}
        onChange={(e) => {
          setDefaultValues({
            ...defaultValues,
            document: e.target.value,
          });
          setValue('document', maskHelper.numberCPFCNPJ(e.target.value));
        }}
        errorMensage={formState.errors.document?.message}
      />
      <Input
        title="Proprietário"
        variant="outline-orange"
        value={defaultValues.owner}
        onChange={(e) => {
          setValue('owner', e.target.value);
          setDefaultValues({ ...defaultValues, owner: e.target.value });
        }}
        errorMensage={formState.errors.owner?.message}
      />
      <div className="grid sm:grid-cols-3 col-span-2 lg:col-span-1 gap-3">
        <Input
          title="Telefone"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          value={maskHelper.phone(defaultValues.phone ?? '')}
          onChange={(e) => {
            e.target.value = `${maskHelper.phone(e.target.value)}`;
            setDefaultValues({ ...defaultValues, phone: e.target.value });
            setValue('phone', maskHelper.number(e.target.value));
          }}
          errorMensage={formState.errors.phone?.message}
        />
        <Input
          title="Telefone Celular"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          value={maskHelper.phone(defaultValues.cellular ?? '')}
          onChange={(e) => {
            e.target.value = maskHelper.phone(e.target.value);
            setValue('cellular', maskHelper.number(e.target.value));
            setDefaultValues({ ...defaultValues, cellular: e.target.value });
          }}
          errorMensage={formState.errors.cellular?.message}
        />

        <Input
          title="WhatsApp"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          value={maskHelper.phone(defaultValues.whatsapp ?? '')}
          onChange={(e) => {
            e.target.value = `${maskHelper.phone(e.target.value)}`;
            setValue('whatsapp', maskHelper.number(e.target.value));
            setDefaultValues({ ...defaultValues, whatsapp: e.target.value });
          }}
          errorMensage={formState.errors.whatsapp?.message}
        />
      </div>

      <Input
        title="E-mail"
        variant="outline-orange"
        value={defaultValues.email}
        onChange={(e) => {
          setValue('email', e.target.value);
          setDefaultValues({ ...defaultValues, email: e.target.value });
        }}
        errorMensage={formState.errors.email?.message}
      />
      <Input
        title="Site"
        variant="outline-orange"
        value={defaultValues.system_homepage}
        onChange={(e) => {
          setValue('system_homepage', e.target.value);
          setDefaultValues({
            ...defaultValues,
            system_homepage: e.target.value,
          });
        }}
        errorMensage={formState.errors.system_homepage?.message}
      />
      <Input
        title="Sistema"
        variant="outline-orange"
        value={defaultValues.system}
        onChange={(e) => {
          setValue('system', e.target.value);
          setDefaultValues({ ...defaultValues, system: e.target.value });
        }}
        errorMensage={formState.errors.system?.message}
      />
      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {onePage ? 'Salvar' : 'Salvar e continuar'}
        </Button>
      </div>
    </form>
  );
};

export default CreateProviderGeneralData;
