/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Card,
  Input,
  Modal,
  SelectComponent,
  Spinner,
  Switch,
  isLoadingApplication,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { useFetchUsersCompanie } from '../../users/hooks';
import { ConfigPropsAutomationFullProps } from '../types';
import { useFetchFunnelByProfile, useFetchStep } from '../../funnel/hooks';
import { useFetchSalesChannel } from '../../salesChannel/hooks';
import { useFetchSegment } from '../../segment/hooks';
import { ApiInputAutomation } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import {
  CopyLink,
  dictionaryError,
  getUrlPlatform,
} from '../../../helpers/utils.helper';
import defaultImageBg from '../../../assets/jpg/background_home.jpg';
import { ApiTheme } from '../../companieCustom/api';
import { ApiCompany } from '../../admin/company/api';
import ModalRandom from '../components/modalRandom';
import ModalDuplicateOpportunity from '../components/modalDuplicateOpportunity';

interface TabProps {
  setRegisterPage: (e: number) => void;
  registerPage: number;
}

const header = [
  {
    id: 0,
    title: 'Formulário',
    hash: 'form',
  },
  {
    id: 1,
    title: 'Planilha',
    hash: 'spreadsheet',
  },
  {
    id: 2,
    title: 'API',
    hash: 'api',
  },
];
const Tabs = ({ registerPage, setRegisterPage }: TabProps) => (
  <div className="w-full lg:w-96 flex items-center justify-center gap-3 flex-wrap lg:flex-nowrap">
    {header.map((item) => (
      <a
        key={item.id}
        href={`#${item.hash}`}
        className={classNames('md:w-32', {
          'font-medium': true,
          'text-primary-strong': registerPage === item.id,
          'text-gray': registerPage !== item.id,
        })}
        onClick={() => setRegisterPage(item.id)}
      >
        <Button
          variant={registerPage === item.id ? 'primary-strong' : 'gray'}
          actionType="button-full-width"
          className="w-full"
        >
          {item.title}
        </Button>
      </a>
    ))}
  </div>
);

interface SelectProps {
  config: ConfigPropsAutomationFullProps;
  optionsFunnel: { label: string; value: number }[];
  optionUser: { label: string; value: number }[];
  optionsSegments: { label: string; value: number }[];
  optionSaleChannel: { label: string; value: number }[];
  type: 'import' | 'api' | 'form';
  apiFunction: (e: any) => void;
  InputLayout?: React.ReactNode;
}

const GroupSelects = forwardRef(
  (
    {
      config,
      optionsFunnel,
      optionUser,
      type,
      optionSaleChannel,
      optionsSegments,
      InputLayout = <> </>,
      apiFunction,
    }: SelectProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const [funnelId, setFunnelId] = useState(config.funnel_step.funnel_id);
    const { StepList, isLoading } = useFetchStep('key', funnelId);
    const optionsStep = StepList.map((step) => ({
      label: step.title,
      value: step.id,
    }));

    const [createAccessData, setCreateAccessData] =
      useState<ConfigPropsAutomationFullProps>(config);

    const schema = yup.object().shape({
      companie_user_id_owner: yup.string().required('Selecione uma opção'),
      funnel_step_id: yup.string().required('Selecione uma opção'),
    });

    const { setValue, handleSubmit, reset, formState } =
      useForm<ConfigPropsAutomationFullProps>({
        defaultValues: {
          sales_channel_id: config.sales_channel_id,
          segment_id: config.segment_id,
          funnel_step_id:
            config.funnel_step_id !== -1 ? config.funnel_step_id : undefined,
          companie_user_id_owner:
            config.companie_user_id_owner !== -1
              ? config.companie_user_id_owner
              : undefined,
        },
        resolver: yupResolver(schema),
      });

    const Submit = (e: any) => {
      apiFunction(e);
    };

    useEffect(() => {
      setCreateAccessData(config);
      setFunnelId(config.funnel_step.funnel_id);
      reset({
        sales_channel_id: config.sales_channel_id,
        segment_id: config.segment_id,
        funnel_step_id:
          config.funnel_step_id !== -1 ? config.funnel_step_id : undefined,
        companie_user_id_owner:
          config.companie_user_id_owner !== -1
            ? config.companie_user_id_owner
            : undefined,
      });
    }, [config]);

    return (
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
        id={`form ${type}`}
        ref={ref}
      >
        {InputLayout}
        <SelectComponent
          value={
            optionUser.find(
              (item) => item.value === createAccessData?.companie_user_id_owner
            ) ?? null
          }
          options={optionUser}
          closeMenuOnSelect
          title="Proprietário"
          placeholder="Selecione um usuário proprietário"
          onChange={(e: any) => {
            setValue('companie_user_id_owner', e.value);
            setCreateAccessData({
              ...createAccessData,
              companie_user_id_owner: e.value,
            });
          }}
          errorMensage={formState.errors.companie_user_id_owner?.message}
        />

        <SelectComponent
          value={optionsFunnel.find((item) => item.value === funnelId) ?? null}
          options={optionsFunnel}
          closeMenuOnSelect
          title="Funil"
          placeholder="Selecione um funil"
          onChange={(e: any) => {
            setFunnelId(e.value);
          }}
        />

        <SelectComponent
          value={
            optionsStep.find(
              (item) => item.value === createAccessData.funnel_step_id
            ) ?? null
          }
          options={optionsStep}
          closeMenuOnSelect
          title="Etapa"
          placeholder="Selecione uma etapa do funil"
          onChange={(e: any) => {
            setValue('funnel_step_id', e.value);
            setCreateAccessData({
              ...createAccessData,
              funnel_step_id: e.value,
            });
          }}
          isLoading={isLoading}
          isDisabled={funnelId === -1 || isLoading}
          errorMensage={formState.errors.funnel_step_id?.message}
        />

        <SelectComponent
          value={
            optionSaleChannel.find(
              (item) => item.value === createAccessData.sales_channel_id
            ) ?? null
          }
          options={optionSaleChannel}
          closeMenuOnSelect
          title="Canal de venda"
          onChange={(e: any) => {
            setValue('sales_channel_id', e.value);
            setCreateAccessData({
              ...createAccessData,
              sales_channel_id: e.value,
            });
          }}
          errorMensage={formState.errors.sales_channel_id?.message}
        />

        <SelectComponent
          value={
            optionsSegments.find(
              (item) => item.value === createAccessData.segment_id
            ) ?? null
          }
          options={optionsSegments}
          closeMenuOnSelect
          title="Segmentos"
          onChange={(e: any) => {
            setValue('segment_id', e.value);
            setCreateAccessData({
              ...createAccessData,
              segment_id: e.value,
            });
          }}
          errorMensage={formState.errors.segment_id?.message}
        />
      </form>
    );
  }
);

const InputAutomation: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const { notify } = ToastNotify();
  const accountProps = getTokenAccountInformation();

  const [usingDefaultImage, setUsingDefaultImage] = useState({
    bg: false,
    logo: false,
  });

  const FormConfig = useRef<HTMLFormElement>(null);
  const APIConfig = useRef<HTMLFormElement>(null);
  const ImportConfig = useRef<HTMLFormElement>(null);

  const [activeTab, setActiveTab] = useState(0);
  const [openModalActiveRandom, setOpenModalActiveRandom] = useState(false);
  const [isRandomActive, setIsRandomActive] = useState(false);
  const [duplicateOpportunity, setDuplicateOpportunity] = useState(false);
  const [openModalDuplicateOp, setOpenModalDuplicateOp] = useState(false);

  const [layoutText, setLayoutText] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const [ConfigList, setConfigList] = useState<
    ConfigPropsAutomationFullProps[]
  >([]);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [droppedFileBg, setDroppedFileBg] = useState<File | null>(null);
  useState<ConfigPropsAutomationFullProps>();

  const defaultValues: ConfigPropsAutomationFullProps = {
    layout_background_image: '',
    layout_logomarca: '',
    layout_text: '',
    companie_user_id: accountProps.id,
    companie_user_id_owner: -1,
    funnel_step: { funnel_id: -1 },
    funnel_step_id: -1,
  };

  const FunnelListProps = useFetchFunnelByProfile('key', accountProps.id);
  const optionsFunnel = FunnelListProps.FunnelsList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const UserListProps = useFetchUsersCompanie('key', accountProps.companie_id);
  const optionUser = UserListProps.UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const SalesChannelProps = useFetchSalesChannel(
    'key',
    accountProps.companie_id
  );
  const optionSaleChannel = SalesChannelProps.SalesChannelList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const SegmentProps = useFetchSegment('key', accountProps.companie_id);
  const optionsSegments = SegmentProps.SegmentList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const SubmitForms = async (credentials: any) => {
    setIsLoadingSubmit(true);
    switch (activeTab) {
      case 0: {
        const formData = new FormData();
        const data = {
          layout_logomarca: droppedFile ?? '',
          layout_background_image: droppedFileBg ?? '',
          ...credentials,
          layout_text: layoutText,
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(data)) {
          if (typeof value === 'number' || typeof value === 'string') {
            formData.append(key, String(value));
          } else if (value instanceof File) {
            formData.append('file', value);
          }
        }
        const res = await ApiInputAutomation.saveForm(formData);
        if (res.id) {
          notify({
            message: 'Configuração salva com sucesso!',
            type: 'Success',
          });
        } else {
          notify({ message: dictionaryError(res), type: 'Error' });
        }
        break;
      }
      case 1: {
        const res = await ApiInputAutomation.saveImport(credentials);
        if (res.id) {
          notify({
            message: 'Configuração salva com sucesso!',
            type: 'Success',
          });
        } else {
          notify({ message: dictionaryError(res), type: 'Error' });
        }
        break;
      }
      case 2: {
        const res = await ApiInputAutomation.saveAPI(credentials);
        if (res.id) {
          notify({
            message: 'Configuração salva com sucesso!',
            type: 'Success',
          });
        } else {
          notify({ message: dictionaryError(res), type: 'Error' });
        }
      }
    }
    setIsLoadingSubmit(false);
  };

  const SaveForms = () => {
    switch (activeTab) {
      case 0:
        FormConfig.current?.requestSubmit();
        break;
      case 1:
        ImportConfig.current?.requestSubmit();
        break;
      case 2:
        APIConfig.current?.requestSubmit();
        break;
    }
  };

  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    isDragActive: isDragActive1,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setDroppedFile(fileCreateObj[0]);
      setUsingDefaultImage({ ...usingDefaultImage, logo: false });
    },
  });

  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    isDragActive: isDragActive2,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setDroppedFileBg(fileCreateObj[0]);
      setUsingDefaultImage({ ...usingDefaultImage, bg: false });
    },
  });

  const getFilenameFromPath = (filePath: string) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };
  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return null;
    }
    return filename.substring(lastDotIndex + 1);
  };

  const resetImageBg = async () => {
    const response = await fetch(defaultImageBg);
    const blob2 = await response.blob();
    const file2 = new File([blob2], 'background_home.jpg', {
      type: 'image/jpeg',
    });
    setDroppedFileBg(file2);
    setUsingDefaultImage({ ...usingDefaultImage, bg: true });
  };

  const changeRandomSwitch = async (value: boolean) => {
    setIsLoadingSubmit(true);
    const response = await ApiInputAutomation.changeRandom(value);
    if (typeof response !== 'string') {
      setIsRandomActive(value);
      setOpenModalActiveRandom(false);
      notify({
        message: 'Configuração alterada com sucesso!',
        type: 'Success',
      });
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoadingSubmit(false);
  };

  const changeDuplicateSwitch = async (value: boolean) => {
    setIsLoadingSubmit(true);
    const response = await ApiInputAutomation.changeDuplicate(value);
    if (typeof response !== 'string') {
      setDuplicateOpportunity(value);
      setOpenModalDuplicateOp(false);
      notify({
        message: 'Configuração alterada com sucesso!',
        type: 'Success',
      });
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoadingSubmit(false);
  };

  const resetImageLogo = async () => {
    const currentTheme = await ApiTheme.getCompanyTheme(
      accountProps.companie_id
    );
    const filename = getFilenameFromPath(currentTheme.path_logo ?? '');
    const imageType = getFileExtension(filename);
    const blob = await ApiTheme.getBlobFromUrl(currentTheme.path_logo ?? '');
    const file = new File([blob], filename, {
      type: `image/${imageType}`,
    });
    setDroppedFile(file);
    setUsingDefaultImage({ ...usingDefaultImage, logo: true });
  };

  useEffect(() => {
    const FetchImages = async (
      ConfigListProps: ConfigPropsAutomationFullProps
    ) => {
      if (
        ConfigListProps.layout_logomarca !== '' &&
        ConfigListProps.layout_logomarca !== null
      ) {
        const filename = getFilenameFromPath(ConfigListProps.layout_logomarca);
        const imageType = getFileExtension(filename);
        const blob = await ApiTheme.getBlobFromUrl(
          ConfigListProps.layout_logomarca
        );
        const file = new File([blob], filename, {
          type: `image/${imageType}`,
        });
        setDroppedFile(file);
      } else {
        resetImageLogo();
      }
      if (
        ConfigListProps.layout_background_image !== '' &&
        ConfigListProps.layout_background_image !== null
      ) {
        const filename = getFilenameFromPath(
          ConfigListProps.layout_background_image
        );
        const imageType = getFileExtension(filename);
        const blob = await ApiTheme.getBlobFromUrl(
          ConfigListProps.layout_background_image
        );
        const file = new File([blob], filename, {
          type: `image/${imageType}`,
        });
        setDroppedFileBg(file);
      } else {
        resetImageBg();
      }
    };

    const FetchCompanyConfig = async () => {
      setState(true);

      setIsLoadingConfig(true);
      const res = await ApiInputAutomation.getConfig(accountProps.companie_id);
      if (typeof res !== 'string') {
        setConfigList(res);
        const formData = res.find(
          (item: ConfigPropsAutomationFullProps) => item.type === 'form'
        );
        setLogoUrl(process.env.REACT_APP_API + formData.layout_logomarca);
        setLayoutText(formData.layout_text ?? '');
        FetchImages(formData);
      }
      setIsLoadingConfig(false);
    };

    const FetchCompanyData = async () => {
      setIsLoadingConfig(true);
      const res = await ApiCompany.companyById(accountProps.companie_id);
      if (typeof res !== 'string') {
        setIsRandomActive(res.is_lead_random);
        setDuplicateOpportunity(res.is_duplicated_opp_funnel);
      }
      setIsLoadingConfig(false);
      setState(false);
    };
    FetchCompanyConfig();
    FetchCompanyData();
  }, []);

  return (
    <div className="w-full min-h-screen h-fit">
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="input-automations" size={24} />
        <strong className="text-lg text-black">Automação de entradas</strong>
      </div>
      <Card className="relative w-full p-7 mt-6 mb-3 flex-col min-h-[80vh] 2xl:min-h-[85vh]">
        <ModalRandom
          changeRandomSwitch={changeRandomSwitch}
          isLoadingSubmit={isLoadingSubmit}
          openModalActiveRandom={openModalActiveRandom}
          setOpenModalActiveRandom={setOpenModalActiveRandom}
        />

        <ModalDuplicateOpportunity
          submit={changeDuplicateSwitch}
          isLoading={isLoadingSubmit}
          show={openModalDuplicateOp}
          setShow={setOpenModalDuplicateOp}
        />
        <div className="max-w-full px-10 absolute top-56 sm:top-44 xl:top-10 right-0 xl:right-10 flex flex-col items-end gap-3">
          <div className="flex top-44 xl:top-8 md:top-16 right-0 xl:right-10 gap-2 items-center w-fit">
            <p className="text-xs md:text-sm">
              Permitir duplicar oportunidade no mesmo funil
            </p>
            <Switch
              state={duplicateOpportunity}
              onChange={() =>
                duplicateOpportunity === false
                  ? setOpenModalDuplicateOp(true)
                  : changeDuplicateSwitch(false)
              }
            />
          </div>
          <div className="flex top-44 xl:top-8 md:top-32 left-0 xl:right-10 gap-2 items-center w-fit">
            <p className="text-xs md:text-sm">Ativar distribuição automática</p>
            <Switch
              state={isRandomActive}
              onChange={() =>
                isRandomActive === false
                  ? setOpenModalActiveRandom(true)
                  : changeRandomSwitch(false)
              }
            />
          </div>
        </div>

        <strong className="text-xl">Automação de entradas</strong>
        <p className="text-xs sm:text-sm w-full lg:w-[500px] xl:w-2/3">
          Configurar parâmetros para que sua empresa possa entrar com
          oportunidades em massa, como planilha
          <br /> de importação, cadastro via formulário web e API de integração.
        </p>
        {isLoadingConfig ||
        UserListProps.isLoading ||
        FunnelListProps.isLoading ||
        SalesChannelProps.isLoading ||
        SegmentProps.isLoading ? (
          <> </>
        ) : (
          <>
            {' '}
            <p className="w-full lg:w-[500px] xl:w-2/3 text-xs mt-5">
              Para isto, informe os parâmetros abaixo para que a oportunidade
              seja vinculada de forma padronizada.
            </p>
            <div className="flex flex-col w-full mt-44 lg:mt-8 gap-10">
              <Tabs registerPage={activeTab} setRegisterPage={setActiveTab} />
              {activeTab === 0 && (
                <div className="flex flex-col">
                  <strong className="text-sm">Fomulário padronizado</strong>
                  <div className="w-full flex gap-1.5 mb-5 items-center">
                    <p className="text-xs">
                      Copie o link do formulário e envie
                    </p>
                    <IconDictionary
                      name="Link"
                      color="#95BDFF"
                      size={28}
                      className="cursor-pointer"
                      onClick={() =>
                        CopyLink(
                          `${getUrlPlatform()}form-lead/${
                            accountProps.companie_id
                          }`
                        )
                      }
                    />
                  </div>

                  <GroupSelects
                    InputLayout={
                      <Input
                        variant="outline-orange"
                        title="Texto do layout"
                        defaultValue={layoutText}
                        onChange={(e) => setLayoutText(e.target.value)}
                      />
                    }
                    ref={FormConfig}
                    apiFunction={SubmitForms}
                    optionSaleChannel={optionSaleChannel}
                    optionUser={optionUser}
                    optionsFunnel={optionsFunnel}
                    optionsSegments={optionsSegments}
                    type="form"
                    config={
                      ConfigList.find((item) => item.type === 'form') ??
                      defaultValues
                    }
                  />
                  <div className="flex flex-col lg:flex-row">
                    {' '}
                    <div
                      id="form-file-upload"
                      className="h-64 w-full lg:w-1/2 max-w-full flex flex-col text-center relative justify-center items-center"
                    >
                      <p className="text-sm font-normal mb-1 mb-1.5">
                        Logo -{' '}
                        {droppedFile ? (droppedFile.size / 1024).toFixed(2) : 0}
                        kb
                      </p>
                      {usingDefaultImage.logo === false && (
                        <div className="cursor-pointer absolute bottom-7 z-30 right-32 bg-gray-400 rounded-full h-10 w-10 flex items-center justify-center">
                          <IconDictionary
                            title="Voltar a imagem default"
                            name="Reset"
                            className="text-white"
                            onClick={resetImageLogo}
                          />
                        </div>
                      )}
                      <input {...getInputProps1()} />
                      <label
                        {...getRootProps1()}
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={`h-56 w-2/3 flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer 
                ${isDragActive1 && 'border-primary'}`}
                      >
                        {!droppedFile &&
                          (!isDragActive1 ? (
                            <div className="flex flex-col items-center justify-center p-6">
                              <button
                                className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                                type="button"
                              >
                                <IconDictionary
                                  name="add-photo"
                                  size={28}
                                  color="var(--primary)"
                                />
                                <span className="text-sm font-bold text-primary">
                                  Selecione sua logomarca
                                </span>
                              </button>
                              <p className="text-xs text-gray-400 font-medium">
                                ou arraste e solte aqui
                              </p>
                            </div>
                          ) : (
                            <p className="text-sm font-bold text-primary">
                              {' '}
                              Solte o arquivo aqui...
                            </p>
                          ))}
                        {droppedFile && (
                          <div className="flex flex-col items-center relative justify-center">
                            <img
                              src={URL.createObjectURL(droppedFile)}
                              alt="logo"
                              className="h-52 object-center overflow-hidden object-contain"
                              onError={() => setDroppedFile(null)}
                            />
                          </div>
                        )}
                      </label>
                      {droppedFile && (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={logoUrl}
                          className="text-xs flex gap-1 w-72 mt-2"
                        >
                          <strong>url:</strong>
                          <p className="truncate">{logoUrl}</p>
                        </a>
                      )}
                    </div>
                    <div
                      id="form-file-upload"
                      className="h-64 w-full lg:w-1/2 max-w-full flex flex-col text-center relative justify-center items-center"
                    >
                      <p className="text-sm font-normal mb-1 mb-1.5">
                        Background -{' '}
                        {droppedFileBg
                          ? (droppedFileBg.size / 1024).toFixed(2)
                          : 0}
                        kb
                      </p>
                      {usingDefaultImage.bg === false && (
                        <div className="cursor-pointer absolute bottom-3 z-30 right-32 bg-gray-400 rounded-full h-10 w-10 flex items-center justify-center">
                          <IconDictionary
                            title="Voltar a imagem default"
                            name="Reset"
                            className="text-white"
                            onClick={resetImageBg}
                          />
                        </div>
                      )}

                      <input {...getInputProps2()} />
                      <label
                        {...getRootProps2()}
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={`h-56 w-2/3 flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer 
                ${isDragActive2 && 'border-primary'}`}
                      >
                        {!droppedFileBg &&
                          (!isDragActive2 ? (
                            <div className="flex flex-col items-center justify-center p-6">
                              <button
                                className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                                type="button"
                              >
                                <IconDictionary
                                  name="add-photo"
                                  size={38}
                                  color="var(--primary)"
                                />
                                <span className="text-sm font-bold text-primary">
                                  Selecione a imagem de fundo
                                </span>
                              </button>
                              <p className="text-xs text-gray-400 font-medium">
                                ou arraste e solte aqui
                              </p>
                            </div>
                          ) : (
                            <p className="text-sm font-bold text-primary">
                              Solte o arquivo aqui...
                            </p>
                          ))}
                        {droppedFileBg && (
                          <div className="flex flex-col items-center relative justify-center">
                            <img
                              src={URL.createObjectURL(droppedFileBg)}
                              alt="logo"
                              className="w-full h-[220px] object-center overflow-hidden object-contain"
                              onError={() => setDroppedFileBg(null)}
                            />
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div className="flex flex-col">
                  <strong className="text-sm">Importação de planilha</strong>
                  <div className="w-full flex gap-1.5 mb-6 items-center">
                    <p className="text-xs">Faça download da planilha modelo</p>
                    <Link to="/files/planilha_modelo.xlsx" target="_blank">
                      <IconDictionary
                        name="Excel"
                        color="#00A374"
                        size={24}
                        className="cursor-pointer"
                      />
                    </Link>
                  </div>
                  <GroupSelects
                    ref={ImportConfig}
                    apiFunction={SubmitForms}
                    optionSaleChannel={optionSaleChannel}
                    optionUser={optionUser}
                    optionsFunnel={optionsFunnel}
                    optionsSegments={optionsSegments}
                    type="import"
                    config={
                      ConfigList.find((item) => item.type === 'import') ??
                      defaultValues
                    }
                  />
                </div>
              )}
              {activeTab === 2 && (
                <div className="flex flex-col">
                  <strong className="text-sm">Integração via API</strong>
                  <div className="w-full flex gap-1.5 mb-5 items-center">
                    <p className="text-xs">Copie o link da documentação</p>
                    <IconDictionary
                      name="Documentação"
                      color="#8C8C8C"
                      size={29}
                      className="cursor-pointer"
                      onClick={() =>
                        CopyLink(`${process.env.REACT_APP_API}api-docs`)
                      }
                    />
                  </div>
                  <GroupSelects
                    ref={APIConfig}
                    apiFunction={SubmitForms}
                    optionSaleChannel={optionSaleChannel}
                    optionUser={optionUser}
                    optionsFunnel={optionsFunnel}
                    optionsSegments={optionsSegments}
                    type="api"
                    config={
                      ConfigList.find((item) => item.type === 'api') ??
                      defaultValues
                    }
                  />
                </div>
              )}
            </div>
            <Button
              type="button"
              variant="primary-strong"
              className="w-48 sm:w-64 col-span-3 mx-auto mt-5"
              actionType="button-loading"
              onClick={SaveForms}
              disabled={isLoadingSubmit}
              isLoading={isLoadingSubmit}
            >
              Salvar
            </Button>
          </>
        )}
      </Card>
    </div>
  );
};

export default InputAutomation;
