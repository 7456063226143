import { baseApiPrivate } from '../../../../api/baseApi';
import { Category } from '../types';

async function getAllCategory() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/category',
  });
}

async function createCategory(e: Omit<Category, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/category',
    body: e,
  });
}

async function updateCategory(e: Omit<Category, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/category/${id}`,
    body: e,
  });
}

async function deleteCategory(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/category/${id}`,
  });
}

export const ApiCategory = {
  getAllCategory,
  createCategory,
  updateCategory,
  deleteCategory,
};
