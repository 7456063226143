/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Modal, SelectComponent } from '../../../../../components';
import { ApiFunnel } from '../../../../funnel/api';
import { ApiTransferOpportunity } from '../../../../transferOpportunity/api';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { useFetchStep } from '../../../../funnel/hooks';

interface Props {
  userList: { label: string; value: number }[];
  funnelList: { label: string; value: number }[];
  currentFunnel: { label: string; value: number } | undefined;
  setShow: (e: boolean) => void;
  show: boolean;
  opportunityId: number;
  notify: (e: any) => void;
  goToMainPage: () => void;
}
const TransferOpportunity: React.FC<Props> = ({
  funnelList,
  show,
  setShow,
  userList,
  opportunityId,
  notify,
  goToMainPage,
  currentFunnel,
}) => {
  const [funnelId, setFunnelId] = useState<
    | {
        label: string;
        value: number;
      }
    | undefined
  >();

  const [step, setstep] = useState<
    | {
        label: string;
        value: number;
      }
    | undefined
  >();

  const [userId, setUserId] = useState<{ label: string; value: number } | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const [ErroMessage, setErroMessage] = useState<string>();

  const { StepList } = useFetchStep('', funnelId?.value ?? -1);
  const optionsSteps = StepList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const Submit = async () => {
    if (funnelId && !step) {
      setErroMessage('Campo obrigatório');
      return;
    }
    setIsLoading(true);
    if (funnelId && userId) {
      const payload = {
        companie_user_id: userId.value,
        funnel_id: funnelId.value,
        opportunitys: [opportunityId],
        funnel_step_id: step?.value ?? null,
      };
      const res = await ApiTransferOpportunity.transferOpportunity(payload);
      if (typeof res === 'string') {
        notify({ message: dictionaryError(res), type: 'Error' });
      } else {
        notify({
          message: 'Oportunidade transferida com sucesso!',
          type: 'Success',
        });
        setShow(false);
        goToMainPage();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setFunnelId(currentFunnel);
    setUserId(null);
  }, []);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Transferir Oportunidade"
      size="medium"
      minHeight="min-h-[480px]"
    >
      <SelectComponent
        closeMenuOnSelect
        options={userList}
        title="Usuário"
        value={userId}
        onChange={setUserId}
        maxOptionsHeight="150px"
      />
      <SelectComponent
        defaultValue={currentFunnel}
        value={funnelId}
        onChange={setFunnelId}
        closeMenuOnSelect
        options={funnelList}
        title="Funil"
        maxOptionsHeight="150px"
      />
      <SelectComponent
        isDisabled={!funnelId}
        value={step}
        onChange={setstep}
        closeMenuOnSelect
        options={optionsSteps}
        title="Etapa"
        maxOptionsHeight="150px"
        errorMensage={ErroMessage}
      />
      <Button
        actionType="button-loading"
        onClick={Submit}
        disabled={funnelId === null || userId === null || isLoading}
        className="mt-8 mx-auto"
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </Modal>
  );
};

export default TransferOpportunity;
