import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { productsContractProps } from '../../../types';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  products: productsContractProps[];
  showDueDate?: boolean;
}
const SummaryTable: React.FC<props> = ({ products, showDueDate = true }) => {
  const [allInstallments, setAllInstallments] = useState([
    { product_total: 0, service_total: 0 },
  ]);

  const recurrences = products.filter(
    (item: any) => item.method === 'recurrence'
  );

  const recurrencesProducts = recurrences.filter(
    (item) => item.product.is_product
  );
  const recurrencesServices = recurrences.filter(
    (item) => !item.product.is_product
  );

  const calcInstallments = () => {
    const valuesFinancials = [{ product_total: 0, service_total: 0 }];
    let valueRecurrenceProduct = 0;
    let valueRecurrenceService = 0;

    if (recurrences.length > 0) {
      recurrences.forEach((item: any) => {
        if (item.product.is_product) {
          valueRecurrenceProduct += Number(item.total);

          valuesFinancials[0] = {
            product_total:
              valuesFinancials[0].product_total + Number(item.total),
            service_total: valuesFinancials[0].service_total,
          };
        } else {
          valueRecurrenceService += Number(item.total);

          valuesFinancials[0] = {
            product_total: valuesFinancials[0].product_total,
            service_total:
              valuesFinancials[0].service_total + Number(item.total),
          };
        }
      });
    }

    const inSights = products.filter((item: any) => item.method === 'in_sight');

    if (inSights.length > 0) {
      inSights.forEach((item: any) => {
        if (item.product.is_product) {
          valuesFinancials[0] = {
            product_total:
              valuesFinancials[0].product_total + Number(item.total),
            service_total: valuesFinancials[0].service_total,
          };
        } else {
          valuesFinancials[0] = {
            product_total: valuesFinancials[0].product_total,
            service_total:
              valuesFinancials[0].service_total + Number(item.total),
          };
        }
      });
    }

    const installments = products.filter(
      (item: any) => item.method === 'installments'
    );

    if (installments.length > 0) {
      installments.sort((a: any, b: any) => {
        if (a.qtd_installments < b.qtd_installments) return 1;
        if (a.qtd_installments > b.qtd_installments) return -1;
        return 0;
      });

      installments.forEach((item: any) => {
        const value = Number(item.total) / Number(item.qtd_installments);

        Array.from({ length: item.qtd_installments }).forEach(
          (_: any, i: number) => {
            if (valuesFinancials[i] === undefined)
              valuesFinancials.push({
                product_total: valueRecurrenceProduct,
                service_total: valueRecurrenceService,
              });

            if (item.product.is_product) {
              valuesFinancials[i] = {
                product_total:
                  valuesFinancials[i].product_total + Number(value.toFixed(2)),
                service_total: valuesFinancials[i].service_total,
              };
            } else {
              valuesFinancials[i] = {
                product_total: valuesFinancials[i].product_total,
                service_total:
                  valuesFinancials[i].service_total + Number(value.toFixed(2)),
              };
            }
          }
        );
      });
    }

    if (installments.length === 0 && inSights.length === 0) {
      valuesFinancials.pop();
    }

    setAllInstallments(valuesFinancials);
  };

  useEffect(() => {
    calcInstallments();
  }, [products]);

  return (
    <div className="w-full overflow-x-auto gap-3 flex flex-col col-span-2">
      <p className="text-sm font-medium col-span-2 mt-2">Resumo pagamento</p>
      <hr className="w-full h-1 -mt-2 rounded-md mb-1 bg-primary/80 col-span-2" />
      <div className="flex flex-col col-span-2 min-w-[430px] w-full">
        <div
          className={`grid grid-cols-${
            showDueDate ? '4' : '3'
          } w-full pb-3 border-b border-solid border-gray-400`}
        >
          <p className="text-xs font-semibold ml-2">Parcela</p>
          <p className="text-xs font-semibold ml-5">Conta</p>
          <p className="text-xs font-semibold ml-5">Valor</p>
          {showDueDate && (
            <p className="text-xs font-semibold ml-5">Vencimento</p>
          )}
        </div>

        <div className="flex flex-col w-full">
          {allInstallments.map((total, index) => (
            <>
              {total.product_total > 0 && (
                <div
                  className={`grid w-full grid-cols-${
                    showDueDate ? '4' : '3'
                  } divide-x divide-solid divide-gray-400 border-b border-solid border-gray-400`}
                >
                  <p className="text-xs py-3 ml-2">{index + 1}ª parcela</p>
                  <p className="text-xs py-3 pl-5">Produto</p>
                  <p className="text-xs py-3 pl-5">
                    {maskHelper.formatMoeda(total.product_total)}
                  </p>
                  {showDueDate && (
                    <p className="text-xs py-3 pl-5">
                      {moment().add(index, 'M').format('L')}
                    </p>
                  )}
                </div>
              )}
              {total.service_total > 0 && (
                <div
                  className={`grid w-full grid-cols-${
                    showDueDate ? '4' : '3'
                  } divide-x divide-solid divide-gray-400 border-b border-solid border-gray-400`}
                >
                  <p className="text-xs py-3 ml-2">{index + 1}ª parcela</p>
                  <p className="text-xs py-3 pl-5">Serviço</p>
                  <p className="text-xs py-3 pl-5">
                    {maskHelper.formatMoeda(total.service_total)}
                  </p>
                  {showDueDate && (
                    <p className="text-xs py-3 pl-5">
                      {moment().add(index, 'M').format('L')}
                    </p>
                  )}
                </div>
              )}

              {total.service_total > 0 && total.product_total > 0 && (
                <div
                  className={`grid w-full grid-cols-${
                    showDueDate ? '4' : '3'
                  } divide-x divide-solid divide-gray-400 border-b border-solid bg-gray-200 border-gray-400`}
                >
                  <p className="text-xs py-3 ml-2 font-semibold">Subtotal:</p>
                  <p className="text-xs py-3 pl-5" />
                  <p className="text-xs py-3 pl-5">
                    {maskHelper.formatMoeda(
                      total.product_total + total.service_total
                    )}
                  </p>
                  {showDueDate && <p className="text-xs py-3 pl-5" />}
                </div>
              )}
            </>
          ))}

          {recurrences.length > 0 && (
            <>
              {recurrencesProducts.length > 0 && (
                <div
                  className={`grid w-full grid-cols-${
                    showDueDate ? '4' : '3'
                  } divide-x divide-solid divide-gray-400 border-b border-solid border-gray-400`}
                >
                  <p className="text-xs py-3 ml-2">
                    A partir da {allInstallments.length + 1}ª parcela
                  </p>
                  <p className="text-xs py-3 pl-5">Produto</p>
                  <p className="text-xs py-3 pl-5">
                    {maskHelper.formatMoeda(
                      recurrencesProducts.reduce(
                        (acc, cur) => acc + Number(cur.total),
                        0
                      )
                    )}
                  </p>
                  {showDueDate && (
                    <p className="text-xs py-3 pl-5">
                      {moment().add(allInstallments.length, 'M').format('L')}
                    </p>
                  )}
                </div>
              )}

              {recurrencesServices.length > 0 && (
                <div
                  className={`grid w-full grid-cols-${
                    showDueDate ? '4' : '3'
                  } divide-x divide-solid divide-gray-400`}
                >
                  <p className="text-xs py-3 ml-2">
                    A partir da {allInstallments.length + 1}ª parcela
                  </p>
                  <p className="text-xs py-3 pl-5">Serviço</p>
                  <p className="text-xs py-3 pl-5">
                    {maskHelper.formatMoeda(
                      recurrences
                        .filter((item) => !item.product.is_product)
                        .reduce((acc, cur) => acc + Number(cur.total), 0)
                    )}
                  </p>
                  {showDueDate && (
                    <p className="text-xs py-3 pl-5">
                      {moment().add(allInstallments.length, 'M').format('L')}
                    </p>
                  )}
                </div>
              )}
              {recurrencesProducts.length > 0 &&
                recurrencesServices.length > 0 && (
                  <div
                    className={`grid w-full grid-cols-${
                      showDueDate ? '4' : '3'
                    } divide-x divide-solid divide-gray-400 bg-gray-200 border-t border-solid border-gray-400`}
                  >
                    <p className="text-xs py-3 ml-2 font-semibold">Subtotal:</p>
                    <p className="text-xs py-3 pl-5" />
                    <p className="text-xs py-3 pl-5">
                      {maskHelper.formatMoeda(
                        recurrences.reduce(
                          (prev, current) => prev + Number(current.total),
                          0
                        )
                      )}
                    </p>
                    {showDueDate && <p className="text-xs py-3 pl-5" />}
                  </div>
                )}
            </>
          )}
        </div>
        {recurrences.length > 0 && (
          <hr className="w-full h-px border-t border-solid border-[#dadada]" />
        )}
      </div>
    </div>
  );
};

export default SummaryTable;
