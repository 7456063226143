import { useEffect, useState } from 'react';

import PartnerApi from '../api';

import { Partner } from '../types';

export function useFetchGroup(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Partner[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await PartnerApi.get();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { GroupList: data, isLoadingGroup: isLoading };
}
