/* eslint-disable no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';
import { SelectComponent } from '../../../../../components';
import { answerItemProps } from '../../../types';

interface props {
  text: string;
  updateAnswer: (elem: answerItemProps[]) => void;
  options: {
    label: string;
    value: number;
  }[];
}

const QuestionList: React.FC<props> = ({ updateAnswer, text, options }) => (
  <div className="w-full sm:w-2/3 text-sm py-3">
    <SelectComponent
      options={options}
      title={text}
      onChange={(e: any) =>
        updateAnswer([
          {
            search_form_question_option_id: e.value,
            text_response: e.label,
          },
        ])
      }
    />
  </div>
);

export default QuestionList;
