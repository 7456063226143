import React from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/spinner';

const LoadingScreen: React.FC = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate('/session/login');
  }, 2000);
  return (
    <div className="w-full h-screen bg-white flex items-center justify-center gap-2">
      <Spinner />
      <p className="text-lg font-semibold">Carregando...</p>
    </div>
  );
};

export default LoadingScreen;
