import { baseApiPrivate } from '../../../api/baseApi';
import { IPostPix } from '../types';

export async function sendPIX(dataPix: IPostPix) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/companie/payment/pix',
    body: dataPix,
  });
}
