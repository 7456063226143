import { useEffect, useState } from 'react';
import { ApiModule } from '../api';
import { Module } from '../types';

export function useFetchModule(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Module[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res = await ApiModule.getAllModule();
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ModuleList: data, isLoadingModule: isLoading };
}
