import classNames from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';

type DivProps = ComponentPropsWithoutRef<'div'>;
const Card: React.FC<DivProps> = ({ children, className, ...props }) => (
  <div
    className={classNames(['flex', 'bg-white', 'rounded', className])}
    {...props}
  >
    {children}
  </div>
);

export default Card;
