import { baseApiPrivate } from '../../../api/baseApi';
import { MenuFullProps, releaseMenu } from '../../admin/menu/types';

async function getMenus(): Promise<MenuFullProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/menu/typeCompanie`,
  });
}

async function getMenusByProfile(
  profileId: number,
  companyId: number
): Promise<{ menu_id: number; profile_id: number }[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/menu/companie/profile/${profileId}/${companyId}`,
  });
}

async function releaseMenus(
  e: releaseMenu,
  companyId: number
): Promise<boolean | string> {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/companie/menu/profile/${companyId}`,
    body: e,
  });
}

export const ApiReleaseMenu = {
  getMenus,
  getMenusByProfile,
  releaseMenus,
};
