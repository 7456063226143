import React, { useState } from 'react';
import MainPage from '../../../main/components/mainPage';
import RegisterUser from '../components/register';
import { useFetchUsers } from '../hooks';
import { UserProps } from '../types';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { DeleteModal, Switch } from '../../../../components';
import { ApiUsers } from '../api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { ToastNotify } from '../../../../components/Toast/toast';

const UsersAdmin: React.FC = () => {
  const accountProps = getTokenAccountInformation();
  const { notify } = ToastNotify();
  const [key, setKey] = useState('');
  const { UsersList, isLoading } = useFetchUsers(key);
  const [user, setUser] = useState<UserProps | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [modalCreateUpdateUser, setModalCreateUpdateUser] = useState(false);
  const [input, setInput] = useState('');

  const ChangeStatusUser = async (
    id: number,
    status: boolean,
    userProps: UserProps
  ) => {
    const res = await ApiUsers.updateUser(
      {
        email: userProps.email,
        name: userProps.name,
        profile_id: userProps.profile_id,
        partner_id: userProps.partner_id,
        is_active: status,
      },
      id
    );
    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: UserProps) => row.name,
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: UserProps) => row.email,
      hide: 800,
    },
    {
      name: 'Status',
      cell: (row: UserProps) => (
        <Switch
          state={row.is_active}
          onChange={() => ChangeStatusUser(row.id, !row.is_active, row)}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return UsersList;
    }
    return UsersList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.email.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Usuários Administradores"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalCreateUpdateUser}
        setRegisterPage={() => {
          setUser(null);
        }}
        onRowClick={(e: UserProps) => {
          setUser(e);
          setModalCreateUpdateUser(true);
        }}
      />
      {user && (
        <DeleteModal
          apiFunction={ApiUsers.deleteUser}
          type="Usuário"
          article="O"
          notify={notify}
          updateTable={() => setKey('delete')}
          close={() => setShowDelete(false)}
          id={user.id}
          name={user.name}
          open={showDelete}
        />
      )}

      <RegisterUser
        administratorId={accountProps.id}
        defaultValue={user}
        reloadTable={setKey}
        setShow={setModalCreateUpdateUser}
        show={modalCreateUpdateUser}
      />
    </>
  );
};

export default UsersAdmin;
