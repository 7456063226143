import { useCallback, useEffect, useState } from 'react';
import {
  filterPropsContract,
  payloadBackContracts,
  productsContractProps,
} from '../types';
import { ApiContract } from '../api';
import { attachment } from '../../../components/Register/attachments';

export function useFetchContracts(
  key: string,
  skip: number,
  take: number,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackContracts>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value.length >= 3) {
        const res = await ApiContract.searchContract(skip, take, value);
        if (typeof res !== 'string') {
          setData(res);
        }
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, value]);
  return {
    ContractsList: data,
    isLoadingList: isLoading,
    setContractList: setData,
  };
}

export function useFetchContractsList(
  key: string,
  filters: filterPropsContract,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackContracts>({
    data: [],
    total: 0,
  });

  const fetchContractList = useCallback(async () => {
    setIsLoading(true);
    if (value === '') {
      const res = await ApiContract.filterContracts({
        ...filters,
        clientId: filters.clientId.map((item) => item.value),
        offerId: filters.offerId.map((item) => item.value),
        status: filters.status.map((item) => item.value),
      });
      if (typeof res !== 'string') {
        setData(res);
      }
    } else if (value.length >= 3) {
      const res = await ApiContract.searchContract(
        filters.skip,
        filters.take,
        value
      );
      if (typeof res !== 'string') {
        setData(res);
      }
    }

    setIsLoading(false);
  }, [key, filters, value]);
  useEffect(() => {
    fetchContractList();
  }, [fetchContractList]);
  return {
    ContractsList: data,
    isLoadingList: isLoading,
    setContractList: setData,
    fetchContractList,
  };
}

export function useFetchProductsContract(key: string, contractId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<productsContractProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res = await ApiContract.getContractProducts(contractId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return {
    productServiceList: data,
    isLoadingListProductService: isLoading,
  };
}

export function useFetchCompanyAttachments(key: string, id: number) {
  const [isLoading, setSetIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchCompanyAttachments = async () => {
      setSetIsLoading(true);
      const companies =
        id === -1 ? '' : await ApiContract.getContractAttachments(id);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setSetIsLoading(false);
    };
    FetchCompanyAttachments();
  }, [key]);
  return { attachmentsList: data, isLoading };
}

export function useFetchGalaxyMethods() {
  const [data, setData] = useState<{ id: string; displayName: string }[]>([]);
  useEffect(() => {
    const fetchGalaxyMethods = async () => {
      const dictionary: { [key: string]: string } = {
        credit: 'creditcard',
        boleto: 'boleto',
        pix: 'pix',
        debit: 'debit',
      };
      const res = await ApiContract.getPaymentMethodsContract();
      if (typeof res !== 'string') {
        const filter = res.map((item) => ({
          id: dictionary[item.id],
          displayName: item.displayName,
        }));
        setData(filter);
      }
    };
    fetchGalaxyMethods();
  }, []);
  return { methods: data };
}
