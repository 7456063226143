import { baseApiPrivate } from '../../../../api/baseApi';
import { createUpdateUser, UserProps } from '../types';

async function createAdminUser(information: createUpdateUser) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'admin/user',
  });
}

async function updateUser(
  information: Omit<createUpdateUser, 'administrator_id'>,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `admin/user/${id}`,
  });
}

async function getPartnersActive(): Promise<UserProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/partners/active`,
  });
}

async function getUsers(): Promise<UserProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/user`,
  });
}

async function deleteUser(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/user/${id}`,
  });
}

export const ApiUsers = {
  getPartnersActive,
  createAdminUser,
  updateUser,
  deleteUser,
  getUsers,
};
