/* eslint-disable no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';

interface props {
  text: string;
  options: {
    id: number;
    title: string;
    is_active: boolean;
  }[];
  setAnswer: (id: number, text: string) => void;
}

const MultipleRadio: React.FC<props> = ({ text, options, setAnswer }) => {
  const [optionChoosed, setOptionChoosed] = useState(-1);

  return (
    <div className="w-full sm:w-2/3 text-sm py-3">
      <p className="focus:border-b-primary focus:border-b border-solid w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1.5">
        {text}
      </p>
      <div className="">
        {options.map((item, index) => (
          <div className="flex gap-2 text-xs w-full my-2">
            <input
              type="radio"
              id={item.title}
              checked={optionChoosed === item.id}
              onChange={() => {
                setOptionChoosed(item.id);
                setAnswer(item.id, item.title);
              }}
            />
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleRadio;
