import { saveAs } from 'file-saver';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

async function GeneratePdfTerm(
  url: string,
  text: string,
  positionX: number,
  positionY: number
) {
  try {
    const response = await fetch(url);
    const contractBuffer = await response.blob();
    const blobFile = new Blob([contractBuffer]);
    const buffer = new Uint8Array(await blobFile.arrayBuffer());
    const pdfDoc = await PDFDocument.load(buffer);
    const page = pdfDoc.getPages();
    const fonte = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const textSize = 10.5;
    const corTexto = rgb(0, 0, 0);
    const textWidth = fonte.widthOfTextAtSize(text, textSize);
    const textHeight = fonte.heightAtSize(textSize);
    page[0].drawRectangle({
      width: textWidth,
      height: textHeight - 1,
      color: rgb(1, 1, 1),
      x: positionX,
      y: positionY,
    });

    page[0].drawText(text, {
      x: positionX,
      y: positionY,
      font: fonte,
      size: textSize,
      color: corTexto,
    });

    const novoPDFBuffer = await pdfDoc.save();

    const blob = new Blob([novoPDFBuffer], { type: 'application/pdf' });
    saveAs(blob, 'Termos_e_Condicoes.pdf');
  } catch (error) {
    console.error('Erro ao adicionar texto ao PDF:', error);
  }
}

export default GeneratePdfTerm;
