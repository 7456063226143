import React, { useState } from 'react';

import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';

import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal, Switch } from '../../../../components';
import { dictionaryError } from '../../../../helpers/utils.helper';
import maskHelper from '../../../../helpers/mask.helper';

import { SignatureProps } from '../types';
import { ApiSignature } from '../api';
import { useFetchSignature } from '../hooks';
import RegisterSignature from '../components/register';

const Signature: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterWhatsapp, setModalRegisterWhatsapp] = useState(false);
  const [input, setInput] = useState('');
  const [signatureData, setSignatureData] = useState<SignatureProps | null>(
    null
  );
  const [key, setKey] = useState('key');

  const { SignatureList, isLoadingSignature } = useFetchSignature(key);
  const { notify } = ToastNotify();

  const onSortStatus = (rowA: SignatureProps, rowB: SignatureProps) => {
    const a = rowA.is_active ? 'A' : 'I';
    const b = rowB.is_active ? 'A' : 'I';

    if (a > b) return 1;

    if (b > a) return -1;

    return 0;
  };

  const onUpdateStatus = async (value: SignatureProps) => {
    const res = await ApiSignature.put(
      {
        name: value.name,
        description: value.description,
        qty: value.qty,
        value: value.value,
        is_active: !value.is_active,
        is_trial: value.is_trial,
      },
      value.id
    );
    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: SignatureProps) => row.name,
    },
    {
      name: 'Descrição',
      key: 'description',
      selector: (row: SignatureProps) => row.description ?? '--',
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: SignatureProps) =>
        maskHelper.formatMoeda(row.value) ?? '0',
    },
    {
      name: 'Quantidade',
      key: 'qty',
      selector: (row: SignatureProps) => row.qty ?? '0',
    },
    {
      name: 'Status',
      sortFunction: onSortStatus,
      selector: (row: SignatureProps) => (
        <Switch onChange={() => onUpdateStatus(row)} state={row.is_active} />
      ),
    },
    {
      name: '',
      cell: (row: SignatureProps) => {
        const verify =
          row.is_active || row.companie_digital_signature_credit?.length !== 0;
        return (
          <CloseOutline
            size={20}
            className="cursor-pointer"
            color={verify ? '#DDD' : '#DA3C3C'}
            onClick={() => {
              if (row.is_active) return;
              setSignatureData(row);
              setShowModalDelete(true);
            }}
          />
        );
      },
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return SignatureList.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    }
    return SignatureList.filter(
      (item) => item.name.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingSignature}
        column={columns}
        title="Crédito Assinatura Digital"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterWhatsapp}
        setRegisterPage={() => setSignatureData(null)}
        onRowClick={(e: SignatureProps) => {
          setSignatureData(e);
          setModalRegisterWhatsapp(true);
        }}
      />
      <RegisterSignature
        data={signatureData}
        reloadTable={setKey}
        setShow={setModalRegisterWhatsapp}
        show={modalRegisterWhatsapp}
      />
      {showModalDelete && (
        <DeleteModal
          apiFunction={ApiSignature.delete}
          notify={notify}
          type="Assinatura"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={signatureData!.id}
          name={signatureData!.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Signature;
