import { baseApiIBGE, baseApiPrivate } from '../../../api/baseApi';
import { attachment } from '../../../components/Register/attachments';
import {
  GetByStepProps,
  OpportunityProps,
  createUpdateOpportunity,
  createServices,
  updateProduct,
  createUpdateAnnotationOpportunity,
  AnnotationOpportunity,
  filterOpportunityByStepProps,
  payloadBackOpportunity,
  createProduct,
  CampaignProps,
} from '../types';

async function createOpportunity(information: createUpdateOpportunity) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/opportunity',
  });
}

async function createContactOpportunity(information: {
  person_id: number;
  opportunity_id: number;
}) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/opportunity/person',
  });
}

async function createAnnotationsOpportunity(
  information: createUpdateAnnotationOpportunity
) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/opportunity/annotation',
  });
}

async function createOpportunityServices(information: createServices) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/opportunity/service',
  });
}

async function createOpportunityAttachments(file: any) {
  return baseApiPrivate({
    method: 'POST',
    body: file,
    file: true,
    url: 'companie/opportunity/attachment',
  });
}

async function updateOpportunity(
  information: Omit<createUpdateOpportunity, 'person_id'>,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/${id}`,
  });
}

async function updateOpportunityWorkflow(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/workflow/${id}`,
  });
}

async function updateOpportunityStatus(id: number, information: any) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/status/${id}`,
  });
}

async function updateOpportunityServices(
  information: updateProduct,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/service/${id}`,
  });
}

async function updateOpportunityAnnotation(
  information: { description: string },
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/annotation/${id}`,
  });
}

async function seachOpportunities(
  key: string,
  skip: number,
  take: number
): Promise<payloadBackOpportunity | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function getOpportunityByCompanyFunnel(
  companyId: number,
  funnelId: number,
  period: string
): Promise<GetByStepProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/filterFunnelByStep/${companyId}/${funnelId}/${period}`,
  });
}

async function getOpportunityByStep(
  period: string,
  filter: filterOpportunityByStepProps
): Promise<GetByStepProps[] | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filter,
    url: `companie/opportunity/byStepByPagination/${period}`,
  });
}

async function getOpportunityByCompanyFunnelAdvanced(
  companyId: number,
  funnelId: number,
  period: string,
  filters?: any
): Promise<GetByStepProps[] | string> {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/opportunity/filterAdvanced/${companyId}/${funnelId}/${period}`,
    body: filters,
  });
}

async function getOpportunityByCompany(
  id: number
): Promise<OpportunityProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/${id}`,
  });
}

async function getOpportunityById(
  id: number
): Promise<OpportunityProps | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/id/${id}`,
  });
}

async function getOpportunityAttachments(
  id: number
): Promise<attachment[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/attachment/${id}`,
  });
}

async function getAnnotationsByOpportunity(
  id: number
): Promise<AnnotationOpportunity[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/annotation/${id}`,
  });
}

async function deleteOpportunity(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/${id}`,
  });
}

async function deleteOpportunityService(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/service/${id}`,
  });
}

async function deleteOpportunityAttachments(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/attachment/${id}`,
  });
}

async function deleteOpportunityAnnotations(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/annotation/${id}`,
  });
}

async function deleteContactOpportunity(
  opportunityId: number,
  personId: number
) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/person/${personId}/${opportunityId}`,
  });
}

async function getProductByOpportunity(id: number): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/product/${id}`,
  });
}

async function createOpportunityProduct(information: createProduct) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/opportunity/product',
  });
}

async function updateOpportunityProduct(
  information: updateProduct,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/product/${id}`,
  });
}

async function updateOpportunityCampaign(
  information: CampaignProps,
  id: number
) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/opportunity/campaign/${id}`,
  });
}

async function deleteOpportunityProduct(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/opportunity/product/${id}`,
  });
}

async function getOpportunityMessage(
  whatsappNumber: string,
  skip: string,
  take: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/whatsapp/message/${whatsappNumber}/${skip}/${take}`,
  });
}

async function sendOpportunityMessage(body: any) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/whatsapp/message/sendText',
    body,
  });
}

interface OpportunityImagesProps {
  companieWhatsappInstanceId: number;
  whatsapp: string;
  message: string;
  file: Array<File>;
}

const messageType = (key: string) => {
  switch (key) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      return 'imageMessage';

    case 'video/mp4':
      return 'videoMessage';

    case 'application/pdf':
      return 'documentMessage';

    default:
      return 'imageMessage';
  }
};

async function sendOpportunityImages({
  companieWhatsappInstanceId,
  whatsapp,
  message,
  file,
}: OpportunityImagesProps) {
  let formData: any = new FormData();
  formData.append('companie_whatsapp_instance_id', companieWhatsappInstanceId);
  formData.append('remote_j_id', `${whatsapp}`);
  formData.append('file', file[0]);
  formData.append('text', file.length === 1 ? message : '');
  formData.append('message_type', messageType(file[0].type));

  const hasWhatsApp = await baseApiPrivate({
    method: 'POST',
    body: formData,
    file: true,
    url: 'companie/whatsapp/message/sendMedia',
  });

  if (typeof hasWhatsApp === 'string') {
    return hasWhatsApp;
  }

  for (let index = 1; index < file.length; index++) {
    const element = file[index];
    const text = file.length === index + 1 ? message : '';

    formData = new FormData();
    formData.append(
      'companie_whatsapp_instance_id',
      companieWhatsappInstanceId
    );
    formData.append('remote_j_id', `${whatsapp}`);
    formData.append('file', element);
    formData.append('text', text);
    formData.append('message_type', messageType(element.type));

    baseApiPrivate({
      method: 'POST',
      body: formData,
      file: true,
      url: 'companie/whatsapp/message/sendMedia',
    });
  }
  return true;
}

async function getInstances(instanceCompanieId: number | null) {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/plans/active',
  }).then(async (data) => {
    const obj = data?.companie_whatsapp_instances.filter(
      (item: any) => item.status === 'Conectada'
    );

    if (!obj) {
      return {
        data: null,
        message: 'planIsNotActive',
      };
    }

    if (data.status !== 'Ativo') {
      return {
        data: null,
        message: 'planIsNotActive',
      };
    }

    if (obj.length === 0) {
      return {
        data: [],
        message: 'thereAreNoConnectedInstances',
      };
    }

    if (instanceCompanieId) {
      const find = obj.find(
        (fin: any) => fin.id === Number(instanceCompanieId)
      );

      if (!find) {
        return {
          data: obj,
          message: 'thisContactHasNoInstance',
        };
      }

      if (find?.status === 'Conectada') {
        return {
          data: obj,
          message: 'instanceConnected',
        };
      }

      return {
        data: [find],
        message: 'instanceDisconnected',
      };
    }

    return {
      data: obj,
      message: 'thisContactHasNoInstance',
    };
  });
}

async function cloneOpportunity(
  opportunityId: number,
  payload: {
    companie_user_id: number;
    funnel_step_id: number;
  }
) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/opportunity/clone/${opportunityId}`,
    body: payload,
  });
}

async function getCities(uf: string): Promise<{ nome: string; id: number }[]> {
  return baseApiIBGE({
    method: 'GET',
    url: `localidades/estados/${uf}/municipios`,
  });
}

export const ApiOpportunity = {
  cloneOpportunity,
  createOpportunity,
  createContactOpportunity,
  createOpportunityServices,
  createOpportunityProduct,
  updateOpportunityProduct,
  updateOpportunityCampaign,
  deleteOpportunityProduct,
  createAnnotationsOpportunity,
  createOpportunityAttachments,
  updateOpportunityServices,
  updateOpportunityStatus,
  updateOpportunity,
  updateOpportunityWorkflow,
  updateOpportunityAnnotation,
  deleteOpportunity,
  deleteOpportunityService,
  deleteOpportunityAnnotations,
  deleteOpportunityAttachments,
  deleteContactOpportunity,
  getOpportunityByCompany,
  getOpportunityByCompanyFunnel,
  getProductByOpportunity,
  getOpportunityByCompanyFunnelAdvanced,
  getOpportunityAttachments,
  getAnnotationsByOpportunity,
  getOpportunityById,
  getOpportunityByStep,
  getInstances,
  getCities,
  seachOpportunities,
  getOpportunityMessage,
  sendOpportunityMessage,
  sendOpportunityImages,
};
