import { baseApiPrivate } from '../../../../api/baseApi';
import { SignatureProps } from '../types';

export const ApiSignatureLimitUsers = {
  get: async () => baseApiPrivate({ method: 'GET', url: 'admin/limitUsers' }),

  active: async () =>
    baseApiPrivate({
      method: 'GET',
      url: 'admin/limitUsers/active',
    }),

  create: async (body: Omit<SignatureProps, 'id'>) =>
    baseApiPrivate({
      method: 'POST',
      url: 'admin/limitUsers',
      body,
    }),

  put: async (body: Omit<SignatureProps, 'id'>, id: number) =>
    baseApiPrivate({
      method: 'PUT',
      url: `admin/limitUsers/${id}`,
      body,
    }),

  delete: async (id: number) =>
    baseApiPrivate({
      method: 'DELETE',
      url: `admin/limitUsers/${id}`,
    }),
};
