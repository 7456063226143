import React, { useState } from 'react';
import { BiTransfer } from 'react-icons/bi';
import { GiAirplaneDeparture, GiAirplaneArrival } from 'react-icons/gi';
import Calendar from 'react-calendar';

import moment from 'moment';
import Header from '../components/header';

import '../styles/home.css';
import { Switch } from '../../../components';
import Select from '../components/Select';
import {
  dateFirstDayMonth,
  dateLastDayMonth,
} from '../../../helpers/date.helper';

import { classes, ages, citys } from '../mocks';

const pages: React.FC = () => {
  const [definedDate, setDefinedDate] = useState(false);
  const [modalStart, setModalStart] = useState(false);
  const [modalEnd, setModalEnd] = useState(false);
  const [modalPax, setModalPax] = useState(false);
  const [dateStart, setDateStart] = useState(dateFirstDayMonth());
  const [dateEnd, setDateEnd] = useState(dateLastDayMonth());

  function handleCalender(open: boolean, start: boolean) {
    if (start) {
      setModalStart(open);
      setModalEnd(false);
      return;
    }

    if (!start) {
      setModalStart(false);
      setModalEnd(open);
    }
  }

  return (
    <div className="containerScreen">
      <div className="main">
        <Header message="Pra onde vamos?" />

        <div className="cardFilter">
          <div className="containerFilters">
            <div className="containerCheckFiltersColumn gap-2">
              <Switch
                state={definedDate}
                onChange={() => setDefinedDate(!definedDate)}
                handleDiameter={15}
                width={38}
                height={20}
              />
              <span>Ainda não defini as datas</span>
            </div>
            <div className="containerCards">
              <div className="containerCardOrigin">
                <div className="containerCard pr-5">
                  <div className="flex gap-2">
                    <GiAirplaneDeparture size={20} color="#541554" />
                    <p className="labelTitle">Selecione uma origem</p>
                  </div>
                  <Select
                    placeholder="Digite uma cidade..."
                    noOptionsMessage="Não encontrada :("
                    data={citys}
                  />
                </div>

                <div className="circleChangePrimary">
                  <div className="circleChangeSecondary">
                    <BiTransfer size={22} />
                  </div>
                </div>

                <div className="containerCard pl-6">
                  <div className="flex gap-2">
                    <GiAirplaneArrival size={20} color="var(--primary)" />
                    <p className="labelTitle">Selecione um destino</p>
                  </div>
                  <Select
                    placeholder="Digite uma cidade..."
                    noOptionsMessage="Não encontrada :("
                    data={citys}
                  />
                </div>
              </div>

              <div className="w-[2%]" />

              <div className="containerCardDate">
                {!definedDate && (
                  <div className="cardDate w-[55%] flex justify-between pl-0 rounded-md">
                    <div
                      className={`cardDateGroup ${
                        modalStart ? 'bg-gray ' : 'bg-primary'
                      }`}
                    >
                      {modalStart && (
                        <>
                          <div className="modalFilterIndicator left-[25%]" />
                          <div
                            className="modalFilter"
                            onMouseLeave={() => handleCalender(false, true)}
                          >
                            <Calendar
                              onChange={() => {}}
                              value={dateStart}
                              className="react-calendar__navigation"
                              onClickDay={(value) => setDateStart(value)}
                            />
                          </div>
                        </>
                      )}
                      <span
                        className="labelTitle"
                        onClick={() => handleCalender(true, true)}
                        onKeyDown={() => handleCalender(true, true)}
                        role="button"
                        tabIndex={0}
                      >
                        Data de ida
                      </span>
                      <span
                        className="labelBold"
                        onClick={() => handleCalender(true, true)}
                        onKeyDown={() => handleCalender(true, true)}
                        role="button"
                        tabIndex={0}
                      >
                        {moment(dateStart).format('DD [de] MMMM, YYYY')}
                      </span>
                      <span
                        className="labelLight"
                        onClick={() => handleCalender(true, true)}
                        onKeyDown={() => handleCalender(true, true)}
                        role="button"
                        tabIndex={0}
                      >
                        {moment(dateStart).format('dddd')}
                      </span>
                    </div>
                    <div className="separatorBetweenDate" />
                    <div
                      className={`cardDateGroup ${
                        modalEnd ? 'bg-gray ' : 'bg-primary'
                      }`}
                    >
                      {modalEnd && (
                        <>
                          <div className="modalFilterIndicator left-[75%]" />
                          <div
                            className="modalFilter"
                            onMouseLeave={() => handleCalender(false, false)}
                          >
                            <Calendar
                              onChange={() => {}}
                              value={dateEnd}
                              className="react-calendar__navigation"
                              onClickDay={(value) => setDateEnd(value)}
                            />
                          </div>
                        </>
                      )}
                      <span
                        className="labelTitle"
                        onClick={() => handleCalender(true, false)}
                        onKeyDown={() => handleCalender(true, false)}
                        role="button"
                        tabIndex={0}
                      >
                        Data de volta
                      </span>
                      <span
                        className="labelBold"
                        onClick={() => handleCalender(true, false)}
                        onKeyDown={() => handleCalender(true, false)}
                        role="button"
                        tabIndex={0}
                      >
                        {moment(dateEnd).format('DD [de] MMMM, YYYY')}
                      </span>
                      <span
                        className="labelLight"
                        onClick={() => handleCalender(true, false)}
                        onKeyDown={() => handleCalender(true, false)}
                        role="button"
                        tabIndex={0}
                      >
                        {moment(dateEnd).format('dddd')}
                      </span>
                    </div>
                  </div>
                )}

                <div className="w-[2%]" />

                <div
                  className={`cardDate w-[40%] ${
                    modalPax ? 'bg-gray ' : 'bg-primary'
                  }`}
                >
                  {modalPax && (
                    <>
                      <div className="modalFilterIndicator" />
                      <div
                        className="modalFilter p-3"
                        // onMouseLeave={() => setModalPax(false)}
                      >
                        <div className="flex flex-row justify-between items-center w-full mb-4">
                          <div>
                            <p className="text-sm m-0">Adultos</p>
                            <p className="text-[10px] text-stone-400 m-0">
                              A partir de 18 anos
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-2 border-[1px] border-solid border-[#ddd] rounded-sm">
                            <button
                              type="button"
                              className="h-7 w-7 flex justify-center items-center text-2xl"
                            >
                              -
                            </button>
                            <p className="text-sm">1</p>
                            <button
                              type="button"
                              className="h-7 w-7 flex justify-center items-center text-2xl"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between items-center w-full mb-4">
                          <div>
                            <p className="text-sm m-0">Menor</p>
                            <p className="text-[10px] text-stone-400 m-0">
                              Até 17 anos
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-2 border-[1px] border-solid border-[#ddd] rounded-sm">
                            <button
                              type="button"
                              className="h-7 w-7 flex justify-center items-center text-2xl"
                            >
                              -
                            </button>
                            <p className="text-sm">1</p>
                            <button
                              type="button"
                              className="h-7 w-7 flex justify-center items-center text-2xl"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between items-center w-full mb-4">
                          <div>
                            <p className="text-sm m-0">Menor 1</p>
                            <p className="text-[10px] text-stone-400 m-0">
                              Idade do menor
                            </p>
                          </div>
                          <Select
                            placeholder="Selecione a class..."
                            noOptionsMessage="Não encontrada :("
                            defaultValue={ages[0]}
                            data={ages}
                          />
                        </div>

                        <div className="flex-1 flex-row justify-between items-center w-full">
                          <p className="text-sm m-0">Classe</p>
                          <Select
                            placeholder="Selecione a class..."
                            noOptionsMessage="Não encontrada :("
                            defaultValue={classes[0]}
                            data={classes}
                          />
                        </div>

                        <button
                          type="button"
                          onClick={() => setModalPax(false)}
                          className="mt-5 h-9 w-full rounded-md hover:border-solid hover:border-[1px] border-primary hover:shadow-xl bg-primary text-white text-sm active:animate-ping"
                        >
                          aplicar
                        </button>
                      </div>
                    </>
                  )}

                  <div
                    className="flex flex-col"
                    onClick={() => setModalPax(true)}
                    onKeyDown={() => setModalPax(true)}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="labelTitle">Passageiro e classe</span>
                    <span className="labelBold">1 passageiro</span>
                    <span className="labelLight">Classe econômica</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pages;
