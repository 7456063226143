import { baseApiPrivate } from '../../../../api/baseApi';

export type OperationType = 'credit' | 'debit' | 'all';
export type PeriodType =
  | 'all'
  | 'today'
  | 'this_month'
  | 'yesterday'
  | 'this_week'
  | 'other_period';

export type StatusType =
  | 'Debitado'
  | 'Ag.Pagamento'
  | 'Ativo'
  | 'Inadimplente'
  | 'Cancelado';

export interface Plan {
  id: number;
  name: string;
  description: string;
  value: string;
  qty: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export type SignatureTransactionsPayload = {
  filter?: PeriodType;
  type?: OperationType;
  date_start?: Date;
  date_end?: Date;
  status?: StatusType[];
  take?: number;
  skip?: number;
};

export interface Address {
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
}
export interface Customer {
  myId: null;
  galaxPayId: number;
  name: string;
  document: string;
  createdAt: Date;
  updatedAt: Date;
  emails: string[];
  phones: number[];
  Address: Address;
  ExtraFields: any[];
}

export interface Boleto {
  pdf: string;
  bankLine: null;
  bankNumber: null;
  barCode: null;
  bankEmissor: null;
  bankAgency: null;
  bankAccount: null;
}

export interface Antifraud {
  ip: null;
  sessionId: null;
  sent: boolean;
  approved: null;
}
export interface GalaxyTransaction {
  galaxPayId: number;
  value: number;
  payday: Date;
  paydayDate: null;
  installment: number;
  status: string;
  statusDescription: string;
  statusDate: Date;
  createdAt: Date;
  myId: null;
  additionalInfo: null;
  datetimeLastSentToOperator: null;
  subscriptionMyId: null;
  subscriptionGalaxPayId: number;
  payedOutsideGalaxPay: boolean;
  ConciliationOccurrences: any[];
  Boleto: Boleto;
  Antifraud: Antifraud;
}

export interface PaymentMethodCreditCard {
  cardOperatorId: null;
  preAuthorize: boolean;
}

export interface SubscriptionClass {
  myId: null;
  galaxPayId: number;
  value: number;
  paymentLink: string;
  mainPaymentMethodId: string;
  status: string;
  additionalInfo: string;
  createdAt: Date;
  updatedAt: Date;
  quantity: number;
  periodicity: string;
  firstPayDayDate: Date;
  Customer: Customer;
  Transactions: GalaxyTransaction[];
  ExtraFields: any[];
  PaymentMethodCreditCard: PaymentMethodCreditCard;
}
export interface Subscription {
  type: boolean;
  Subscription: SubscriptionClass;
}

export interface SubscriptionAPI {
  id: number;
  client_id: number;
  galax_pay_id: number;
  my_id: null;
  value: string;
  status: string;
  first_pay_day_date: Date;
  main_payment_method_id: string;
  json: Subscription;
  created_at: Date;
  updated_at: null;
}

export interface BuyPlanResponse {
  subscription: Subscription;
  subscriptionApi: SubscriptionAPI;
}

export interface DigitalSignatureCredit {
  name: string;
}
export interface CompanieDigitalSignatureCredit {
  id: number;
  companie_user_id: number;
  digital_signature_credit_id: number;
  value: string;
  qty: number;
  status: StatusType;
  status_date: Date;
  created_at: Date;
  digital_signature_credit: DigitalSignatureCredit;
}
export interface Transaction {
  id: number;
  companie_digital_signature_credit_id: number;
  contract_id?: number;
  qty: number;
  created_at: Date;
  companie_digital_signature_credit: CompanieDigitalSignatureCredit;
  contract: any;
  status: string;
}

export interface SignatureTransactionsResponse {
  total: number;
  data: Transaction[];
}

export async function getSignatureExtract(
  payload: SignatureTransactionsPayload
) {
  return baseApiPrivate({
    method: 'POST',
    url: `companie/digitalSignatureCredit/extract`,
    body: {
      ...payload,
      date_start: payload?.date_start?.toISOString(),
      date_end: payload?.date_end?.toISOString(),
    },
  }) as Promise<SignatureTransactionsResponse>;
}

export async function getSignatureTransactions(skip: number, take: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/digitalSignatureCredit/payments/${skip}/${take}`,
  });
}

export async function getCompanyCreditBalance(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/${companyId}`,
  });
}

export async function getAvailablePlans() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/digitalSignatureCredit/active',
  }) as Promise<Plan[]>;
}

export async function buyPlan(plan: Plan) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/digitalSignatureCredit',
    body: {
      digital_signature_credit_id: plan.id,
      main_payment_method_id: 'creditcard',
    },
  }) as Promise<BuyPlanResponse>;
}

export async function getSignatureCreditBalance() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/user/signatureCreditBalance',
  });
}
