import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ToastNotify } from '../../../components/Toast/toast';
import { downloadExpensesProps } from '../types';
import { ApiExpenses } from '../api';
import { dictionaryError } from '../../../helpers/utils.helper';
import { Button, Input, InputDate, Modal } from '../../../components';
import maskHelper from '../../../helpers/mask.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  isRevenue: boolean;
  show: boolean;
  financialId: number;
  reload: (e: string) => void;
  date: string;
  value: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isIntegrated: boolean;
}

const DownloadFinancialsModal: React.FC<registerModalProps> = ({
  show,
  isRevenue,
  financialId,
  value,
  date,
  isIntegrated,
  setShow,
  reload,
}) => {
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object().shape({
    received_value: yup.string().required('Campo obrigatório'),
    received_date: yup.date().required('Campo obrigatório'),
  });

  const { formState, handleSubmit, setValue, reset, getValues } =
    useForm<downloadExpensesProps>({
      resolver: yupResolver(schema),
    });

  const Submit = async (e: downloadExpensesProps) => {
    setIsLoading(true);
    const res = await ApiExpenses.downloadFinancial(
      {
        ...e,
        received_value:
          isRevenue && isIntegrated
            ? 0
            : Number(
                Number(String(e.received_value).replace(',', '.'))
                  .toFixed(2)
                  .replace('.', '')
              ),
        received_date: maskHelper.formatDateYMD(
          getValues('received_date').slice(0, 10)
        ),
      },
      financialId
    );
    if (res === true) {
      reload(`${Math.random()} `);
      notify({ message: 'Lançamento baixado com sucesso', type: 'Success' });
      setIsLoading(false);
      setShow(false);
    } else {
      reload(`${Math.random()} `);
      notify({ message: dictionaryError(String(res)), type: 'Error' });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    reset();
    setValue('received_value', value);
    setValue('received_date', date);
  }, [show]);
  return (
    <Modal
      title="Baixar Lançamento"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[400px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <InputDate
          defaultValue={new Date(`${date.slice(0, 10)} `)}
          title="Data Pagamento"
          onChange={(e: Date) => {
            if (e !== null) {
              setValue(
                'received_date',
                maskHelper.formatDateYMD(e.toLocaleString('pt-BR').slice(0, 10))
              );
            } else {
              setValue('received_date', '');
            }
          }}
          errorMensage={formState.errors.received_date?.message}
        />
        <Input
          disabled={isRevenue}
          variant="outline-orange"
          title="Valor"
          defaultValue={maskHelper.formatMoeda(value)}
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setValue(
              'received_value',
              maskHelper.numberCurrencyDecimal(e.target.value)
            );
          }}
          errorMensage={formState.errors.received_value?.message}
        />
        <div className="col-span-2">
          <p className="text-sm mb-1">Observação</p>
          <textarea
            onChange={(e) => {
              setValue('obs_received', e.target.value);
            }}
            name="textinput"
            className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
          />
        </div>

        <Button
          disabled={isLoading}
          variant="primary-strong"
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          className="col-span-2 font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Baixar
        </Button>
      </form>
    </Modal>
  );
};

export default DownloadFinancialsModal;
