import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { KeyboardArrowRight } from '@styled-icons/material-outlined/KeyboardArrowRight';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../../components/Droppable/StrictModeDroppable';
import { ApiFunnel } from '../api';
import { useFetchStep } from '../hooks';
import { Step } from '../types';
import { Spinner } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';

const onDragEnd = (
  result: any,
  columns: any[],
  setColumns: any,
  updateBackEnd: any,
  idFunnel: number
) => {
  if (!result.destination) return;

  const { source, destination } = result;
  if (source.index === destination.index) return;

  const currentStep = columns.splice(source.index, 1)[0];
  columns.splice(destination.index, 0, currentStep);
  setColumns(columns);
  const newOrder = columns.map((step) => step.id);
  updateBackEnd({ new_order: newOrder }, idFunnel);
};

/* eslint-disable no-unused-vars */
interface stepProps {
  funnelId: number;
  setStep: (e: any) => void;
  setModalRegisterFunnelStep: (e: boolean) => void;
  reload: string;
}

const Steps: React.FC<stepProps> = ({
  funnelId,
  setStep,
  setModalRegisterFunnelStep,
  reload,
}) => {
  const { StepList, isLoading } = useFetchStep(reload, funnelId);
  const [stepsOrder, setStepsOrder] = useState<Step[]>([]);

  useEffect(() => {
    setStepsOrder(StepList);
  }, [StepList]);

  return (
    <div className="flex items-center w-full mt-10 min-h-[100px] h-fit">
      {isLoading ? (
        <div className="w-full flex justify-center mt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(
                result,
                stepsOrder,
                setStepsOrder,
                ApiFunnel.updateOrderStep,
                funnelId
              )
            }
          >
            <div className="flex w-fit h-24 overflow-x-clip">
              <div className="flex">
                <StrictModeDroppable
                  droppableId="0"
                  key="0"
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-wrap gap-y-20 sm:gap-y-10 items-center"
                    >
                      {stepsOrder.map((steps, i, array) => (
                        <div className="relative flex items-center">
                          <Draggable
                            key={steps.id}
                            draggableId={String(steps.id)}
                            index={i}
                          >
                            {/* eslint-disable-next-line no-shadow */}
                            {(dragBlocks, snapshot) => (
                              <div
                                ref={dragBlocks.innerRef}
                                {...dragBlocks.draggableProps}
                                {...dragBlocks.dragHandleProps}
                                className="relative font-normal cursor-pointer min-w-[151px] w-full flex justify-between h-11 items-center border-y-gray-300 border-solid border-y hover:bg-secondary hover:text-white bg-white"
                              >
                                <div className="absolute -top-2 z-30 right-0 ml-5 cursor-pointer hover:scale-110 delay-75 transition easy-in duration-75 rounded-full bg-gray-400 text-gray-600 w-5 h-5 flex items-center justify-center">
                                  <IconDictionary
                                    name="Editar"
                                    size={14}
                                    onClick={() => {
                                      setStep(steps);
                                      setModalRegisterFunnelStep(true);
                                    }}
                                  />
                                </div>
                                {i !== 0 ? (
                                  <>
                                    <div className="w-10 border-y border-solid border-gray-300 h-11 bg-inherit" />
                                    <div
                                      className={classNames([
                                        'w-full h-full flex items-center justify-center',
                                        i === array.length - 1 &&
                                          'border-r border-solid border-gray-300',
                                      ])}
                                    >
                                      <p
                                        className={classNames([
                                          'text-xs',
                                          'truncate',
                                          'max-w-[148px]',
                                          i !== array.length - 1 && '-mr-1',
                                        ])}
                                      >
                                        {steps.id}: {steps.title}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="w-10 border-y border-l border-solid border-gray-300 h-11 bg-inherit" />
                                    <div className="w-full h-full flex items-center">
                                      <p className="text-xs mx-auto">
                                        {steps.id}: {steps.title}
                                      </p>
                                    </div>
                                  </>
                                )}
                                {i !== array.length - 1 && (
                                  <div className="w-[42px] bg-inherit border border-solid border-gray-300 h-[30px] -mr-4 rotate-45 border-b-0 border-l-0 z-20" />
                                )}
                              </div>
                            )}
                          </Draggable>
                          {i === 0 && (
                            <div className="mr-[-30px] z-20 w-7 h-7 rounded-full flex items-center justify-center bg-green-200">
                              <KeyboardArrowRight size={28} />
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="z-30 ml-10 mr-1 cursor-pointer hover:scale-110 delay-75 transition easy-in duration-75 rounded-full bg-secondary text-white w-8 h-8 flex items-center justify-center">
                        <IconDictionary
                          name="Adicionar"
                          onClick={() => {
                            setStep(null);
                            setModalRegisterFunnelStep(true);
                          }}
                        />
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </div>
            </div>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default Steps;
