import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../main/components/mainPage';
import { ToastNotify } from '../../../components/Toast/toast';
import { DeleteModal } from '../../../components';
import { Segment } from '../types';
import { ApiSegment } from '../api';
import { useFetchSegment } from '../hooks';
import RegisterSegment from '../components/register';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

const Segments: React.FC = () => {
  const userProps = getTokenAccountInformation();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterSegment, setModalRegisterSegment] = useState(false);
  const [input, setInput] = useState('');
  const [groupData, setSegmentData] = useState<Segment | null>(null);
  const [key, setKey] = useState('key');

  const { SegmentList, isLoading } = useFetchSegment(
    key,
    userProps.companie_id
  );
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: Segment) => row.id,
    },
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Segment) => row.title,
    },
    {
      name: '',
      cell: (row: Segment) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setSegmentData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return SegmentList;
    }
    return SegmentList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        title="Segmentos"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterSegment}
        setRegisterPage={() => {
          setSegmentData(null);
        }}
        onRowClick={(e: Segment) => {
          setSegmentData(e);
          setModalRegisterSegment(true);
        }}
      />
      <RegisterSegment
        companieUserId={userProps.id}
        segment={groupData}
        reloadTable={setKey}
        setShow={setModalRegisterSegment}
        show={modalRegisterSegment}
      />
      {groupData && (
        <DeleteModal
          apiFunction={ApiSegment.deleteSegment}
          notify={notify}
          type="Segmento"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={groupData.id}
          name={groupData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Segments;
