import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import maskHelper from '../../../helpers/mask.helper';
import {
  Button,
  Card,
  HeaderPages,
  SelectComponent,
  Table,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { balanceFinder, statementQuery } from '../api';
import { IBalance, IMovements, IBalanceAlternative } from '../types';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

const BankStatement: React.FC = () => {
  const defaultBalance = {
    type: true,
    Balance: {
      blockedBoleto: 0,
      blockedCard: 0,
      enabled: 0,
      requested: 0,
      updatedAt: '2015-01-01 10:00:00',
    },
  };
  const [startDate, setStartDate] = useState<Date | null>(
    new Date('2015-01-01 10:00:00')
  );
  const [showBalance, setShowBalance] =
    useState<IBalanceAlternative>(defaultBalance);
  const [showDateInputs, setShowDateInputs] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [typeOfOperation, setTypeofOperation] = useState<string>('all');
  const [apiDataData, setApiData] = useState<IMovements>({
    type: false,
    Balances: [],
    Totals: { initial: 0, final: 0 },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    if (!startDate || !endDate) {
      console.error('Datas de início e fim devem ser fornecidas.');
      setIsLoading(false);
      return;
    }

    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const startDay = startDate.getDate();
    const finalYear = endDate.getFullYear();
    const finalMonth = endDate.getMonth() + 1;
    const finalDay = endDate.getDate();
    const initialDate = `${startYear}-${startMonth}-${startDay}`;
    const finalDate = `${finalYear}-${finalMonth}-${finalDay}`;

    try {
      const result = await statementQuery({ initialDate, finalDate });
      let filteredData = result.Balances ?? [];
      if (typeOfOperation !== 'all') {
        filteredData = filteredData.filter(
          (transaction: IBalance) => transaction.paymentType === typeOfOperation
        );
      }

      setApiData({
        ...result,
        Balances: filteredData,
        Totals: result.Totals ?? { initial: 0, final: 0 },
      });
    } catch (error) {
      console.error('Erro ao executar a consulta:', error);
    }
    setIsLoading(false);
  };

  const generateCsv = () => {
    const csvData = [
      ['ID da Transação', 'Descrição', 'Data', 'Crédito', 'Débito'],
      ...apiDataData.Balances.map((item: IBalance) => [
        item.transactionGalaxPayId,
        item.friendlyDescription,
        maskHelper.formatDateDMY(item.createdAt),
        item.paymentType === 'credit'
          ? maskHelper.formatMoeda(item.value / 100)
          : '',
        item.paymentType === 'debit'
          ? maskHelper.formatMoeda(item.value / 100)
          : '',
      ]),
    ];

    return csvData;
  };

  const exportToXLS = () => {
    const csvData = generateCsv();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.aoa_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Transações');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `extrato${fileExtension}`);
  };

  const handleTypeOperation = (operation: any) => {
    const selectedOperation = operation.value;
    setTypeofOperation(selectedOperation);
  };

  const handleStartDateChange = (startDateInput: Date | null) => {
    setStartDate(startDateInput);
  };

  const handleEndDateChange = (endDateInput: Date | null) => {
    setEndDate(endDateInput);
  };

  const handlePeriodChange = (selectedOption: any) => {
    if (selectedOption.value === 'otherInterval') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(true);
    } else if (selectedOption.value === 'today') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      setStartDate(today);
      setEndDate(today);
    } else if (selectedOption.value === 'yesterday') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      setStartDate(yesterday);
      setEndDate(yesterday);
    } else if (selectedOption.value === 'this_week') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      const startOfWeek = new Date(today);
      const dayOfWeek = today.getDay();
      startOfWeek.setDate(today.getDate() - dayOfWeek);
      setStartDate(startOfWeek);
      setEndDate(today);
    } else if (selectedOption.value === 'last_week') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      const startOfLastWeek = new Date(today);
      const dayOfWeek = today.getDay();
      startOfLastWeek.setDate(today.getDate() - dayOfWeek - 7);
      const endOfLastWeek = new Date(startOfLastWeek);
      endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
      setStartDate(startOfLastWeek);
      setEndDate(endOfLastWeek);
    } else if (selectedOption.value === 'this_month') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      setStartDate(startOfMonth);
      setEndDate(today);
    } else if (selectedOption.value === 'last_30_days') {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate(null);
      setEndDate(null);
      setShowDateInputs(false);
      const allPeriod = new Date('2015-01-01 10:00:00');
      setStartDate(allPeriod);
      setEndDate(new Date());
    }
  };

  const Periodsoptions = [
    { label: 'Todo o Período', value: 'all' },
    { label: 'Hoje', value: 'today' },
    { label: 'Ontem', value: 'yesterday' },
    { label: 'Esta semana', value: 'this_week' },
    { label: 'Semana passada', value: 'last_week' },
    { label: 'Este mês', value: 'this_month' },
    { label: 'Últimos 30 dias', value: 'last_30_days' },
    { label: 'Outro intervalo', value: 'otherInterval' },
  ];

  const OperationsType = [
    { label: 'Todas Operações', value: 'all' },
    { label: 'Crédito', value: 'credit' },
    { label: 'Débito', value: 'debit' },
  ];

  const columns = [
    {
      name: 'ID da Transação',
      key: 'transactionGalaxPayId',
      selector: (row: IBalance) => row.transactionGalaxPayId,
    },
    {
      name: 'Descrição',
      key: 'friendlyDescription',
      selector: (row: IBalance) => row.friendlyDescription,
    },
    {
      name: 'Data',
      key: 'createdAt',
      selector: (row: IBalance) => maskHelper.formatDateDMY(row.createdAt),
    },
    {
      name: 'Crédito',
      key: 'paymentType',
      selector: (row: IBalance) =>
        row.paymentType === 'credit'
          ? maskHelper.formatMoeda(row.value / 100)
          : '',
    },
    {
      name: 'Débito',
      key: 'paymentType',
      selector: (row: IBalance) =>
        row.paymentType === 'debit'
          ? maskHelper.formatMoeda(row.value / 100)
          : '',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const dateFinal = new Date();
      const year = dateFinal.getFullYear();
      const month = dateFinal.getMonth() + 1;
      const day = dateFinal.getDate();
      const formattedData = `${year}-${month}-${day}`;

      try {
        const tokenId = getTokenAccountInformation();
        const balance = await balanceFinder(tokenId.companie.companie_id_galax);
        setShowBalance({
          ...balance,
          Balance: balance.Balance ?? defaultBalance.Balance,
        });
        const result = await statementQuery({
          initialDate: '2015-01-01',
          finalDate: formattedData,
        });
        setApiData({
          ...result,
          Balances: result.Balances ?? [],
          Totals: result.Totals ?? { initial: 0, final: 0 },
        });
      } catch (error) {
        console.error('Erro ao executar a consulta:', error);
      }
    };
    const loadInitialData = async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    };

    loadInitialData();
  }, []);

  return (
    <div>
      <div className="mb-8">
        <HeaderPages title="Extrato Bancário" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
        <Card className="md:col-span-2 col-span-1 min-h-[7rem] px-2">
          <div className="flex flex-col gap-4 mx-4 my-4 w-full">
            <strong>Pesquisar</strong>
            <div className="flex flex-col xl:flex-row gap-4 justify-center items-center w-full">
              <div className="w-full">
                <SelectComponent
                  options={Periodsoptions}
                  variant="outline-primary"
                  defaultValue={Periodsoptions[0]}
                  onChange={handlePeriodChange}
                />
              </div>
              {showDateInputs && (
                <div className="flex flex-row w-full">
                  <DatePicker
                    className="start_date_input h-[2.79rem] mb-2 w-full"
                    format="dd/MM/yyyy"
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                  <p className="bg-primary w-10 h-9 flex items-center justify-center text-xs text-white min-h-[2.79rem] px-3">
                    Até
                  </p>
                  <DatePicker
                    className="end_date_input h-[2.79rem] mb-2 w-full"
                    format="dd/MM/yyyy"
                    onChange={handleEndDateChange}
                    value={endDate}
                  />
                </div>
              )}
              <div className="w-full">
                <SelectComponent
                  options={OperationsType}
                  variant="outline-primary"
                  defaultValue={OperationsType[0]}
                  onChange={handleTypeOperation}
                />
              </div>
              <div className="">
                <Button
                  variant="primary"
                  className="w-[3.65rem] min-h-[2.65rem] mb-[0.5rem]"
                  onClick={handleSearch}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  <IconDictionary name="Lupa" />
                </Button>
              </div>
            </div>
          </div>
        </Card>
        <Card className="md:col-span-1 min-h-[7rem]">
          <div className="flex flex-row md:flex-col lg:flex-col xl:flex-row gap-4 mx-4 my-4 w-full justify-center items-center text-xs">
            <h1>
              <IconDictionary name="Coin" size={40} />
            </h1>
            <div>
              <h1>Saldo Disponível</h1>
              <p className="font-bold">
                {maskHelper.formatMoeda(
                  (showBalance.Balance.enabled ?? 0) / 100
                )}
              </p>
            </div>
            <div>
              <h1>Boleto Bloqueado</h1>
              <p className="font-bold">
                {maskHelper.formatMoeda(
                  (showBalance.Balance.blockedBoleto ?? 0) / 100
                )}
              </p>
            </div>
            <div>
              <h1>Cartão Bloqueado</h1>
              <p className="font-bold">
                {maskHelper.formatMoeda(
                  (showBalance.Balance.blockedCard ?? 0) / 100
                )}
              </p>
            </div>
          </div>
        </Card>
        <Card className="md:col-span-1 min-h-[7rem] flex flex-col justify-center items-center px-4">
          <h1 className="mb-4">Extrato Crédito e Débito</h1>
          <div className="flex flex-row gap-4 w-full justify-center px-5">
            <Button
              variant="primary"
              className="px-2 min-w-[9.5rem]"
              onClick={exportToXLS}
            >
              Exportar
            </Button>
          </div>
        </Card>
        <Card className="col-span-1 md:col-span-4 min-h-[48rem]">
          <div className="w-full px-6">
            <Table
              columns={columns}
              data={apiDataData.Balances}
              isLoading={isLoading}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BankStatement;
