/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  contractProps,
  paymentResData,
  productsContractProps,
  updateformProps,
} from '../../../types';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import maskHelper from '../../../../../helpers/mask.helper';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import { ApiContract } from '../../../api';
import {
  Button,
  Input,
  SelectComponent,
  TextArea,
} from '../../../../../components';
import { getAddressByCEP, states } from '../../../../../helpers/address.helper';
import PaymentProductsTable from './productsTable';
import SummaryTable from './sumaryTable';

interface props extends updateformProps {
  isCreate: boolean;
  clientDefault: contractProps | null;
  productsOptions: { label: string; value: number; price: number }[];
  galaxyFormPayments: { id: string; displayName: string }[];
  setCurrentContract: (e: any) => void;
}

const PaymentContract: React.FC<props> = ({
  defaultValue,
  id,
  nextPage,
  notify,
  setId,
  setCurrentContract,
  isEditable,
  galaxyFormPayments,
  isCreate,
  clientDefault,
  productsOptions,
}) => {
  const [formOfPayment, setFormOfPayment] = useState('');

  const titleRef = useRef<null | HTMLParagraphElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState('');
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [productServiceList, setProductServiceList] = useState<
    productsContractProps[]
  >(defaultValue?.contract_products ?? []);

  const [defaultValues, setDefaultValues] = useState<paymentResData>({
    is_financial_responsible_client:
      defaultValue?.is_financial_responsible_client ?? true,
    resp_financial_type: defaultValue?.resp_financial_type ?? '',
    resp_financial_name: defaultValue?.resp_financial_name ?? '',
    resp_financial_document: defaultValue?.resp_financial_document ?? '',
    resp_financial_email: defaultValue?.resp_financial_email ?? '',
    resp_financial_phone: defaultValue?.resp_financial_phone ?? '',
    resp_financial_whatsapp: defaultValue?.resp_financial_whatsapp ?? '',
    resp_financial_address: defaultValue?.resp_financial_address ?? '',
    resp_financial_number: defaultValue?.resp_financial_number ?? '',
    resp_financial_complement: defaultValue?.resp_financial_complement ?? '',
    resp_financial_province: defaultValue?.resp_financial_province ?? '',
    resp_financial_city: defaultValue?.resp_financial_city ?? '',
    resp_financial_state: defaultValue?.resp_financial_state ?? '',
    resp_financial_zipcode: defaultValue?.resp_financial_zipcode ?? '',
    discount_type: defaultValue?.discount_type ?? '',
    discount_value: defaultValue?.discount_value ?? 0,
    fine_galaxpay: defaultValue?.fine_galaxpay ?? 0,
    interest: defaultValue?.interest ?? 0,
    instructions: defaultValue?.instructions ?? '',
  });

  const persontype = [
    { label: 'Física', value: 'Física' },
    { label: 'Jurídica', value: 'Jurídica' },
  ];

  const clientRes = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  const discount = [
    { label: 'Não dar desconto', value: '' },
    { label: 'Fixo', value: 'fixed' },
    { label: 'Percentual', value: 'percent' },
  ];

  const schema = yup.object().shape({
    resp_financial_address: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório'),
    resp_financial_number: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório'),
    resp_financial_name: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Nome é obrigatório'),
    resp_financial_state: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Selecione uma opção'),
    resp_financial_province: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório'),
    resp_financial_city: yup
      .string()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório'),
    resp_financial_document: yup
      .string()
      .typeError('Campo obrigatório')
      .required('CPF/CNPJ é obrigatório')
      .min(11, 'Campo deve conter ao menos 11 dígitos')
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) =>
          value
            ? cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            : false,
      }),
    resp_financial_phone: yup
      .string()
      .nullable()
      .matches(/^$|^\d{10}$/, 'Campo deve conter 10 dígitos'),
    resp_financial_whatsapp: yup
      .string()
      .typeError('Campo obrigatório')
      .required('WhatsApp é obrigatório')
      .min(11, 'Campo deve conter 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),

    resp_financial_email: yup
      .string()
      .typeError('Campo obrigatório')
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
  });

  const { setValue, handleSubmit, formState, reset } = useForm<paymentResData>({
    resolver: yupResolver(schema),
    defaultValues: clearNullValuesObject(defaultValues),
  });
  const Submit = async (e: paymentResData) => {
    setIsLoading(true);
    const res = await ApiContract.updatePaymentResContract(
      e,
      defaultValue?.id ?? id
    );
    if (res.id) {
      setIsLoading(false);
      notify({
        message: 'Dados atualizados com sucesso!',
        type: 'Success',
      });
      setId(res.id);
      setCurrentContract({
        ...defaultValue,
        ...res,
        is_financial_responsible_client:
          defaultValues.is_financial_responsible_client,
        id: res.id,
        instructions: res.instructions,
        fine_galaxpay: res.fine_galaxpay,
        discount_type: res.discount_type,
        discount_value: res.discount_value,
        interest: res.interest,
      });

      nextPage();
    } else {
      setIsLoading(false);
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    }
  };

  const AddressByCEP = async (e: string) => {
    const address = await getAddressByCEP(e);
    if (address) {
      setDefaultValues({
        ...defaultValues,
        resp_financial_address: address.logradouro,
        resp_financial_number: address.number ?? '',
        resp_financial_city: address.localidade,
        resp_financial_state: address.uf,
        resp_financial_province: address.bairro,
      });
      setValue('resp_financial_address', address.logradouro);
      setValue('resp_financial_city', address.localidade);
      setValue('resp_financial_state', address.uf);
      setValue('resp_financial_province', address.bairro);
    }
  };

  const handleError = () => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };
  useEffect(() => {
    if (isCreate && defaultValue?.is_financial_responsible_client === true) {
      const values = {
        is_financial_responsible_client: true,
        resp_financial_type: 'Física',
        resp_financial_name: clientDefault?.client_name ?? '',
        resp_financial_document: clientDefault?.client_document ?? '',
        resp_financial_email: clientDefault?.client_email ?? '',
        resp_financial_phone: '',
        resp_financial_whatsapp: clientDefault?.client_whatsapp ?? '',
        resp_financial_address: clientDefault?.client_address ?? '',
        resp_financial_number: clientDefault?.client_address_number ?? '',
        resp_financial_complement:
          clientDefault?.client_address_complement ?? '',
        resp_financial_province: clientDefault?.client_address_province ?? '',
        resp_financial_city: clientDefault?.client_address_city ?? '',
        resp_financial_state: clientDefault?.client_address_state ?? '',
        resp_financial_zipcode: clientDefault?.client_address_zipcode ?? '',
        discount_type: defaultValue?.discount_type ?? '',
        discount_value: defaultValue?.discount_value ?? 0,
        fine_galaxpay: defaultValue?.fine_galaxpay ?? 0,
        interest: defaultValue?.interest ?? 0,
        instructions: defaultValue?.instructions ?? '',
      };
      setDefaultValues(values);
      reset({ ...values });
    }
  }, []);

  useEffect(() => {
    if (defaultValues.is_financial_responsible_client === true) {
      if (defaultValue == null) {
        const values = {
          is_financial_responsible_client: true,
          resp_financial_type: 'Física',
          resp_financial_name: clientDefault?.client_name ?? '',
          resp_financial_document: clientDefault?.client_document ?? '',
          resp_financial_email: clientDefault?.client_email ?? '',
          resp_financial_phone: '',
          resp_financial_whatsapp: clientDefault?.client_whatsapp ?? '',
          resp_financial_address: clientDefault?.client_address ?? '',
          resp_financial_number: clientDefault?.client_address_number ?? '',
          resp_financial_complement:
            clientDefault?.client_address_complement ?? '',
          resp_financial_province: clientDefault?.client_address_province ?? '',
          resp_financial_city: clientDefault?.client_address_city ?? '',
          resp_financial_state: clientDefault?.client_address_state ?? '',
          resp_financial_zipcode: clientDefault?.client_address_zipcode ?? '',
          discount_type: '',
          discount_value: 0,
          fine_type: '',
          fine_galaxpay: 0,
          interest_type: '',
          interest: 0,
          instructions: '',
        };

        setDefaultValues(values);
        reset({ ...values });
      } else {
        const values = {
          is_financial_responsible_client: true,
          resp_financial_type: defaultValue.resp_financial_type,
          resp_financial_name: defaultValue.client_name,
          resp_financial_document: defaultValue.client_document,
          resp_financial_email: defaultValue.client_email,
          resp_financial_phone: '',
          resp_financial_whatsapp: defaultValue.client_whatsapp ?? '',
          resp_financial_address: defaultValue.client_address,
          resp_financial_number: defaultValue.client_address_number,
          resp_financial_complement:
            defaultValue.client_address_complement ?? '',
          resp_financial_province: defaultValue.client_address_province,
          resp_financial_city: defaultValue.client_address_city,
          resp_financial_state: defaultValue.client_address_state,
          resp_financial_zipcode: defaultValue.client_address_zipcode,
          discount_type: defaultValue?.discount_type ?? '',
          discount_value: defaultValue?.discount_value ?? 0,
          fine_galaxpay: defaultValue?.fine_galaxpay ?? 0,
          interest: defaultValue?.interest ?? 0,
          instructions: defaultValue?.instructions ?? '',
        };
        setDefaultValues(values);
        reset({ ...values });
      }
      return;
    }
    if (
      defaultValues.is_financial_responsible_client === false &&
      defaultValue?.resp_financial_document === defaultValue?.client_document
    ) {
      const values = {
        is_financial_responsible_client: false,
        resp_financial_type: 'Física',
        resp_financial_name: '',
        resp_financial_document: '',
        resp_financial_email: '',
        resp_financial_phone: '',
        resp_financial_whatsapp: '',
        resp_financial_address: '',
        resp_financial_number: '',
        resp_financial_complement: '',
        resp_financial_province: '',
        resp_financial_city: '',
        resp_financial_state: '',
        resp_financial_zipcode: '',
        discount_type: defaultValue?.discount_type ?? '',
        discount_value: defaultValue?.discount_value ?? 0,
        fine_galaxpay: defaultValue?.fine_galaxpay ?? 0,
        interest: defaultValue?.interest ?? 0,
        instructions: defaultValue?.instructions ?? '',
      };
      setDefaultValues(values);
      reset({ ...values });
      return;
    }
    if (
      defaultValues.is_financial_responsible_client === false &&
      defaultValue
    ) {
      setDefaultValues({ ...defaultValue });
    }
  }, [defaultValues.is_financial_responsible_client]);

  useEffect(() => {
    const FetchProducts = async () => {
      setIsLoadingTable(true);
      const res =
        defaultValue &&
        (await ApiContract.getContractProducts(defaultValue.id));
      if (typeof res !== 'string') {
        setProductServiceList(res);
      }
      setIsLoadingTable(false);
    };

    FetchProducts();
  }, [key]);

  return (
    <form
      className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4 max-h-[400px] overflow-y-auto"
      onSubmit={handleSubmit(Submit, handleError)}
    >
      <p ref={titleRef} className="text-sm font-medium col-span-2">
        Responsável Financeiro
      </p>
      <hr className="w-full h-1 -mt-2 rounded-md mb-1 bg-primary/80 col-span-2" />
      <SelectComponent
        classNameDiv="col-span-2 sm:col-span-1"
        isDisabled={!isEditable}
        title="Tipo de Pessoa"
        isMulti={false}
        closeMenuOnSelect
        options={persontype}
        value={persontype.find(
          (item) => item.value === defaultValues?.resp_financial_type
        )}
        onChange={(e: any) => {
          setDefaultValues({ ...defaultValues, resp_financial_type: e.value });
          setValue('resp_financial_type', e.value);
        }}
        errorMensage={formState.errors.resp_financial_type?.message}
      />
      <SelectComponent
        classNameDiv="col-span-2 sm:col-span-1"
        isDisabled={!isEditable}
        title="Resp. Financeiro é o Cliente?"
        closeMenuOnSelect
        options={clientRes}
        value={clientRes.find(
          (item) =>
            item.value === defaultValues?.is_financial_responsible_client
        )}
        onChange={(e: any) => {
          setDefaultValues({
            ...defaultValues,
            is_financial_responsible_client: e.value,
          });
          setValue('is_financial_responsible_client', e.value);
        }}
        errorMensage={formState.errors.is_financial_responsible_client?.message}
      />

      <Input
        classNameDiv="col-span-2 sm:col-span-1"
        disabled={!isEditable || defaultValues.is_financial_responsible_client}
        variant="outline-orange"
        title="Nome / Razão Social"
        value={defaultValues.resp_financial_name}
        onChange={(e: any) => {
          setDefaultValues({
            ...defaultValues,
            resp_financial_name: e.target.value,
          });
          setValue('resp_financial_name', e.target.value);
        }}
        errorMensage={formState.errors.resp_financial_name?.message}
      />
      <Input
        classNameDiv="col-span-2 sm:col-span-1"
        disabled={!isEditable || defaultValues.is_financial_responsible_client}
        title="CPF / CNPJ"
        variant="outline-orange"
        type="text"
        value={maskHelper.cnpjCpf(defaultValues.resp_financial_document ?? '')}
        placeholder="000.000.000-00"
        onChange={(e: any) => {
          setDefaultValues({
            ...defaultValues,
            resp_financial_document: maskHelper.numberCPFCNPJ(e.target.value),
          });
          setValue(
            'resp_financial_document',
            maskHelper.numberCPFCNPJ(e.target.value)
          );
        }}
        errorMensage={formState.errors.resp_financial_document?.message}
      />
      <Input
        classNameDiv="col-span-2 sm:col-span-1"
        disabled={!isEditable || defaultValues.is_financial_responsible_client}
        title="E-mail"
        variant="outline-orange"
        type="text"
        value={defaultValues.resp_financial_email}
        onChange={(e: any) => {
          setDefaultValues({
            ...defaultValues,
            resp_financial_email: e.target.value,
          });
          setValue('resp_financial_email', e.target.value);
        }}
        errorMensage={formState.errors.resp_financial_email?.message}
      />

      <div className="flex flex-col sm:grid grid-cols-2 gap-3">
        <Input
          classNameDiv="col-span-2 sm:col-span-1"
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="Telefone"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          max={15}
          type="text"
          value={maskHelper.phoneFix(defaultValues.resp_financial_phone ?? '')}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_phone: maskHelper.numberPhone(e.target.value),
            });
            setValue(
              'resp_financial_phone',
              maskHelper.numberPhone(e.target.value)
            );
          }}
          errorMensage={formState.errors.resp_financial_phone?.message}
        />
        <Input
          classNameDiv="col-span-2 sm:col-span-1"
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="WhatsApp"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          max={15}
          type="text"
          value={maskHelper.phone(defaultValues.resp_financial_whatsapp ?? '')}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_whatsapp: maskHelper.numberPhone(e.target.value),
            });
            setValue(
              'resp_financial_whatsapp',
              maskHelper.numberPhone(e.target.value)
            );
          }}
          errorMensage={formState.errors.resp_financial_whatsapp?.message}
        />
      </div>
      <div className="flex flex-col col-span-2 lg:col-span-1 md:grid grid-cols-2 gap-3">
        <Input
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="CEP"
          variant="outline-orange"
          value={maskHelper.cep(defaultValues.resp_financial_zipcode ?? '')}
          onBlur={(e) => AddressByCEP(maskHelper.number(e.target.value))}
          onChange={(e) => {
            setValue(
              'resp_financial_zipcode',
              maskHelper.number(e.target.value)
            );
            e.target.value = maskHelper.cep(e.target.value);
            setDefaultValues({
              ...defaultValues,
              resp_financial_zipcode: maskHelper.number(e.target.value),
            });
          }}
          errorMensage={formState.errors.resp_financial_zipcode?.message}
        />
        <Input
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="Rua / Longradouro"
          variant="outline-orange"
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_address: e.target.value,
            });
            setValue('resp_financial_address', e.target.value);
          }}
          value={defaultValues.resp_financial_address}
          errorMensage={formState.errors.resp_financial_address?.message}
        />
      </div>
      <div className="col-span-2 lg:col-span-1 grid grid-cols-2 md:grid-cols-4 gap-3">
        <Input
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="Número"
          variant="outline-orange"
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_number: e.target.value,
            });
            setValue('resp_financial_number', e.target.value);
          }}
          value={defaultValues.resp_financial_number}
          errorMensage={formState.errors.resp_financial_number?.message}
        />
        <Input
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="Bairro"
          variant="outline-orange"
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_province: e.target.value,
            });
            setValue('resp_financial_province', e.target.value);
          }}
          value={defaultValues.resp_financial_province}
          errorMensage={formState.errors.resp_financial_province?.message}
        />
        <Input
          disabled={
            !isEditable || defaultValues.is_financial_responsible_client
          }
          title="Cidade"
          variant="outline-orange"
          classNameDiv="col-span-2"
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              resp_financial_city: e.target.value,
            });
            setValue('resp_financial_city', e.target.value);
          }}
          value={defaultValues.resp_financial_city}
          errorMensage={formState.errors.resp_financial_city?.message}
        />
      </div>
      <SelectComponent
        classNameDiv="col-span-2 sm:col-span-1"
        isDisabled={
          !isEditable || defaultValues.is_financial_responsible_client
        }
        title="Estado"
        options={states}
        closeMenuOnSelect
        value={states.find(
          (element) => element.value === defaultValues.resp_financial_state
        )}
        onChange={(e: any) => {
          setDefaultValues({
            ...defaultValues,
            resp_financial_state: e.value,
          });
          setValue('resp_financial_state', e.value);
        }}
        errorMensage={formState.errors.resp_financial_state?.message}
      />
      <Input
        classNameDiv="col-span-2 sm:col-span-1"
        disabled={!isEditable || defaultValues.is_financial_responsible_client}
        title="Complemento"
        variant="outline-orange"
        onChange={(e) => {
          setValue('resp_financial_complement', e.target.value);
          setDefaultValues({
            ...defaultValues,
            resp_financial_complement: e.target.value,
          });
        }}
        value={defaultValues.resp_financial_complement}
        errorMensage={formState.errors.resp_financial_complement?.message}
      />

      {defaultValue && (
        <>
          <PaymentProductsTable
            galaxyFormPayments={galaxyFormPayments}
            setProductServerList={setProductServiceList}
            productServiceList={productServiceList}
            setKey={setKey}
            isLoadingTable={isLoadingTable}
            setIsLoadingTable={setIsLoadingTable}
            defaultValue={defaultValue}
            isEditable={isEditable}
            productsOptions={productsOptions}
            formOfPayment={formOfPayment}
            setFormOfPayment={setFormOfPayment}
          />

          <p className="text-sm font-medium col-span-2">
            Desconto pagamento antecipado
          </p>
          <div className="grid grid-cols-3 gap-3 col-span-2">
            <SelectComponent
              classNameDiv="col-span-1 sm:col-span-1"
              isDisabled={
                !isEditable ||
                !(formOfPayment === 'boleto' || formOfPayment === 'pix')
              }
              title="Tipo de desconto"
              options={discount}
              closeMenuOnSelect
              value={discount.find(
                (item) => item.value === defaultValues?.discount_type
              )}
              onChange={(e: any) => {
                setDefaultValues({
                  ...defaultValues,
                  discount_type: e.value,
                  discount_value: 0,
                });
                setValue('discount_type', e.value);
              }}
              defaultValue={discount[0]}
              errorMensage={formState.errors.discount_type?.message}
            />

            <Input
              classNameDiv="mt-1.5 border-gray-400 col-span-1 sm:col-span-1"
              variant="outline-orange"
              title="Percentual"
              disabled={
                !isEditable ||
                defaultValues.discount_type !== discount[2].value ||
                !(formOfPayment === 'boleto' || formOfPayment === 'pix')
              }
              icon="%"
              value={
                defaultValues.discount_type !== discount[2].value
                  ? '0'
                  : String(defaultValues.discount_value)
              }
              onChange={(e: any) => {
                e.target.value = maskHelper.formatDecimal(e.target.value);

                const number = Number(e.target.value.replace(',', '.'));
                if (
                  number > 99.99 ||
                  Number.isNaN(number) ||
                  e.target.value.length > 5
                ) {
                  const newFormat = e.target.value.replace('.', ',').slice(-5);
                  const newFormatNumber = Number(newFormat.replace(',', '.'));
                  setDefaultValues({
                    ...defaultValues,
                    discount_value: newFormat,
                  });
                  setValue('discount_value', newFormatNumber);
                  return;
                }
                setDefaultValues({
                  ...defaultValues,
                  discount_value: e.target.value,
                });
                setValue('discount_value', number);
              }}
              errorMensage={formState.errors.discount_value?.message}
            />
            <Input
              disabled={
                !isEditable ||
                defaultValues.discount_type !== discount[1].value ||
                !(formOfPayment === 'boleto' || formOfPayment === 'pix')
              }
              variant="outline-orange"
              title="Fixo"
              value={maskHelper.formatMoeda(
                defaultValues.discount_type !== discount[1].value
                  ? '0'
                  : String(defaultValues.discount_value)
              )}
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
                setDefaultValues({
                  ...defaultValues,
                  discount_value: Number(
                    maskHelper.numberCurrencyDecimal(e.target.value)
                  ),
                });
                setValue(
                  'discount_value',
                  Number(maskHelper.numberCurrencyDecimal(e.target.value))
                );
              }}
              errorMensage={formState.errors.discount_value?.message}
            />
          </div>

          <p className="text-sm font-medium col-span-2 mt-7">
            Juros mensais por atraso
          </p>
          <div className="grid grid-cols-2 gap-3 col-span-2">
            <Input
              classNameDiv="mt-1.5 border-gray-400"
              variant="outline-orange"
              title="Multa (%)"
              disabled={
                !isEditable ||
                !(formOfPayment === 'boleto' || formOfPayment === 'pix')
              }
              icon="%"
              value={String(defaultValues.fine_galaxpay)}
              onChange={(e: any) => {
                e.target.value = maskHelper.formatDecimal(e.target.value);

                const number = Number(e.target.value.replace(',', '.'));
                if (
                  number > 99.99 ||
                  Number.isNaN(number) ||
                  e.target.value.length > 5
                ) {
                  const newFormat = e.target.value.replace('.', ',').slice(-5);
                  const newFormatNumber = Number(newFormat.replace(',', '.'));
                  setDefaultValues({
                    ...defaultValues,
                    fine_galaxpay: newFormat,
                  });
                  setValue('fine_galaxpay', newFormatNumber);
                  return;
                }
                setDefaultValues({
                  ...defaultValues,
                  fine_galaxpay: e.target.value,
                });
                setValue('fine_galaxpay', number);
              }}
              errorMensage={formState.errors.fine_galaxpay?.message}
            />
            <Input
              classNameDiv="mt-1.5 border-gray-400"
              variant="outline-orange"
              title="Juros (%)"
              disabled={
                !isEditable ||
                !(formOfPayment === 'boleto' || formOfPayment === 'pix')
              }
              icon="%"
              value={String(defaultValues.interest)}
              onChange={(e: any) => {
                e.target.value = maskHelper.formatDecimal(e.target.value);

                const number = Number(e.target.value.replace(',', '.'));
                if (
                  number > 99.99 ||
                  Number.isNaN(number) ||
                  e.target.value.length > 5
                ) {
                  const newFormat = e.target.value.replace('.', ',').slice(-5);
                  const newFormatNumber = Number(newFormat.replace(',', '.'));
                  setDefaultValues({
                    ...defaultValues,
                    interest: newFormat,
                  });
                  setValue('interest', newFormatNumber);
                  return;
                }
                setDefaultValues({
                  ...defaultValues,
                  interest: e.target.value,
                });
                setValue('interest', number);
              }}
              errorMensage={formState.errors.interest?.message}
            />
          </div>

          <TextArea
            disabled={
              !isEditable ||
              !(formOfPayment === 'boleto' || formOfPayment === 'pix')
            }
            classNamediv="col-span-2 mb-5"
            title="Instruções de pagamento"
            value={defaultValues?.instructions}
            onChange={(e) => {
              setDefaultValues({
                ...defaultValues,
                instructions: e.target.value,
              });
              setValue('instructions', e.target.value);
            }}
            defaultValue=""
          />

          {productServiceList.length > 0 && (
            <SummaryTable products={productServiceList} />
          )}
        </>
      )}

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading || !isEditable}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default PaymentContract;
