import { baseApi } from '../../../api/baseApi';

async function create(payload: any) {
  return baseApi({
    body: payload,
    method: 'POST',
    url: `session/create/account`,
  });
}

export const ApiCheckout = {
  create,
};
