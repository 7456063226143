import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Table } from '../../../../components';
import { useWppContext } from '../../context';
import { FinancialProps } from '../../../financial/types';
import maskHelper from '../../../../helpers/mask.helper';
import IconDictionary from '../../../../components/Icons/icons';
import { statusColorFinancial } from '../../../financial/utils';

// import { Container } from './styles';

const NegociatePlan: React.FC = () => {
  const { modal, financialData } = useWppContext();

  const columns = [
    {
      name: 'Parcela',
      key: 'installment',
      selector: (row: FinancialProps) => row.installment,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: FinancialProps) => (
        <p className={statusColorFinancial[row.status]}>{row.status}</p>
      ),
    },
    {
      name: 'Vencimento',
      key: 'due_date',
      selector: (row: FinancialProps) => maskHelper.formatDateDMY(row.due_date),
    },
    {
      name: 'Valor',
      key: 'status',
      selector: (row: FinancialProps) => maskHelper.formatMoeda(row.value),
    },
  ];
  return (
    <Modal
      isOpen={modal.show === 'negociate'}
      setIsOpen={() => {}}
      header={false}
      title=""
      size="2xlarge"
      minHeight="min-h-[200px]"
    >
      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-5">
        Pagamento Pendente
      </h3>
      <div className="px-3 py-2 flex items-center gap-3 w-full border border-dashed border-red">
        <IconDictionary
          size={20}
          name="AlertTriangle"
          className="text-red min-w-fit"
        />
        <p className="text-sm">
          O pagamento do plano atual está em atraso. Para continuar utilizando
          as instâncias do WhatsApp, por favor, realize o pagamento através do
          link abaixo.
        </p>
      </div>
      <p className="text-xs font-semibold mt-5 relative z-20 -mb-5">
        Parcelas do Plano
      </p>
      <Table data={financialData ?? []} columns={columns} isLoading={false} />
      <div className="flex gap-3 w-full flex justify-center mt-10">
        <Link to="/">
          <Button
            className="w-56"
            variant="outline-primary"
            onClick={() => false}
          >
            Sair
          </Button>
        </Link>

        <Link
          to={financialData.length > 0 ? financialData[0].payment_link : '/'}
          target="_blank"
        >
          <Button
            className="w-56"
            actionType="button-loading"
            variant="primary-strong"
          >
            Realizar pagamento
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

export default NegociatePlan;
