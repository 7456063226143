import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import moment from 'moment';
import {
  adicionalDataContract,
  contractProps,
  updateformProps,
} from '../../types';

import maskHelper from '../../../../helpers/mask.helper';

import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../helpers/utils.helper';
import { ApiContract } from '../../api';
import {
  Button,
  Input,
  InputDate,
  SelectComponent,
  TextArea,
} from '../../../../components';
import phoneValidate from '../../../../helpers/validations/phone.validate';

interface props extends updateformProps {
  // eslint-disable-next-line no-unused-vars
  setCurrentContract: (e: contractProps) => void;
  optionsSegment: { label: string; value: string }[];
}

const typeOptions = [
  {
    label: 'TJMG',
    value: 'TJMG',
  },
  {
    label: 'IGP-M',
    value: 'IGP-M',
  },
  {
    label: 'IPCA',
    value: 'IPCA',
  },
];

const AdicionalData: React.FC<props> = ({
  defaultValue,
  id,
  nextPage,
  notify,
  setCurrentContract,
  isEditable,
  optionsSegment,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<adicionalDataContract>({
    client_agent_celular: defaultValue?.client_agent_celular ?? '',
    client_agent_fixo: defaultValue?.client_agent_fixo ?? '',
    client_agent_legal: defaultValue?.client_agent_legal ?? '',
    client_agent_whatsapp: defaultValue?.client_agent_whatsapp ?? '',
    date_end: maskHelper.formatDateYMD(defaultValue?.date_end ?? ''),
    date_start: maskHelper.formatDateYMD(defaultValue?.date_start ?? ''),
    readjustment_index: defaultValue?.readjustment_index ?? '',
    type: defaultValue?.type ?? '',
    client_agent_cpf: defaultValue?.client_agent_cpf ?? '',
    client_agent_email: defaultValue?.client_agent_email ?? '',
    fine: defaultValue?.fine,
    gain: defaultValue?.gain,
    km_comarca: defaultValue?.km_comarca,
    km_out: defaultValue?.km_out,
    travel_alowance: defaultValue?.travel_alowance,
    local_service: Number(defaultValue?.local_service),
    non_local_service: Number(defaultValue?.non_local_service),
    obs: defaultValue?.obs,
  });

  const schema = yup.object().shape({
    client_agent_legal: yup.string(),
    client_agent_whatsapp: yup.string().test({
      message: 'Número deve conter 11 dígitos',
      test: (value) => {
        if (value === '' || typeof value === 'undefined') {
          return true;
        }
        if (value?.length !== 11) return false;
        return true;
      },
    }),
    client_agent_fixo: yup.string().test({
      message: 'Número deve conter 10 dígitos',
      test: (value) => {
        if (value === '' || typeof value === 'undefined') return true;
        if (value?.length !== 10) return false;
        return true;
      },
    }),
    client_agent_celular: yup
      .string()
      .test({
        message: 'Número deve conter 11 dígitos',
        test: (value) => {
          if (value === '' || typeof value === 'undefined') return true;
          if (value?.length !== 11) return false;
          return true;
        },
      })
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
  });

  const { setValue, handleSubmit, formState, reset } =
    useForm<adicionalDataContract>({
      resolver: yupResolver(schema),
      defaultValues: clearNullValuesObject(defaultValues),
    });

  const Submit = async (e: adicionalDataContract) => {
    setIsLoading(true);
    const res = await ApiContract.updateAdicionalInfoContract(
      {
        ...e,
        date_end: e.date_end === '' ? null : e.date_end,
        date_start: e.date_start === '' ? null : e.date_start,
      },
      defaultValue?.id ?? id
    );
    if (res.id) {
      setIsLoading(false);
      notify({
        message: 'Informações atualizadas com sucesso!',
        type: 'Success',
      });
      if (defaultValue)
        setCurrentContract({
          ...defaultValue,
          type: res.type,
          date_start: res.date_start,
          date_end: res.date_end,
          readjustment_index: res.readjustment_index,
          client_agent_celular: res.client_agent_celular,
          client_agent_fixo: res.client_agent_fixo,
          client_agent_legal: res.client_agent_legal,
          client_agent_whatsapp: res.client_agent_whatsapp,
          client_agent_cpf: res.client_agent_cpf,
          client_agent_email: res.client_agent_email,
          fine: res.fine,
          gain: res.gain,
          km_comarca: res.km_comarca,
          km_out: res.km_out,
          travel_alowance: res.travel_alowance,
          local_service: res.local_service,
          non_local_service: res.non_local_service,
          obs: res.obs,
        });
      nextPage();
    } else {
      setIsLoading(false);
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
      onSubmit={handleSubmit(Submit)}
    >
      <div className="col-span-2 grid grid-cols-4 gap-3">
        <InputDate
          defaultValue={
            defaultValues.date_start
              ? new Date(`${defaultValues.date_start} `)
              : undefined
          }
          onChange={(e: any) => {
            if (e !== null) {
              setValue(
                'date_start',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
              setDefaultValues({
                ...defaultValues,
                date_start: maskHelper.formatDateYMD(
                  e.toLocaleDateString('pt-br')
                ),
              });
            } else {
              setValue('date_start', null);
              setDefaultValues({ ...defaultValues, date_start: null });
            }
          }}
          title="Início do Contato"
          errorMensage={formState.errors.date_start?.message}
        />
        <InputDate
          minDate={moment(defaultValues.date_start, 'YYYY/MM/DD').toDate()}
          defaultValue={
            defaultValues.date_end
              ? new Date(`${defaultValues.date_end} `)
              : undefined
          }
          onChange={(e: any) => {
            if (e !== null) {
              setValue(
                'date_end',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt'))
              );
              setDefaultValues({
                ...defaultValues,
                date_end: maskHelper.formatDateYMD(
                  e.toLocaleDateString('pt-br')
                ),
              });
            } else {
              setValue('date_end', null);
              setDefaultValues({ ...defaultValues, date_end: null });
            }
          }}
          title="Fim do Contato"
          errorMensage={formState.errors.date_end?.message}
        />
        <SelectComponent
          title="Área do Contrato"
          value={optionsSegment.find(
            (item) => item.value === defaultValues.type
          )}
          options={optionsSegment}
          onChange={(e: any) => {
            setValue('type', e.value);
            setDefaultValues({
              ...defaultValues,
              type: e.value,
            });
          }}
        />
        <SelectComponent
          defaultValue={typeOptions.find(
            (item) => item.value === defaultValues.readjustment_index
          )}
          title="Índice de Reajuste"
          options={typeOptions}
          onChange={(e: any) => {
            setValue('readjustment_index', e.value);
            setDefaultValues({
              ...defaultValues,
              readjustment_index: e.value,
            });
          }}
        />
      </div>
      <div className="col-span-2 grid grid-cols-3 gap-3">
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Multa"
          classNameDiv="mt-1.5 border-gray-400"
          icon="%"
          defaultValue={maskHelper.formatDecimal(
            String(defaultValues.fine) ?? ''
          )}
          onChange={(e) => {
            e.target.value = maskHelper.formatDecimal(String(e.target.value));
            setDefaultValues({
              ...defaultValues,
              fine: Number(e.target.value),
            });
            setValue('fine', Number(e.target.value.replace(',', '.')));
          }}
          errorMensage={formState.errors.fine?.message}
        />
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Diária de Viagem"
          defaultValue={maskHelper.formatMoeda(defaultValues.travel_alowance)}
          onChange={(e: any) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setDefaultValues({
              ...defaultValues,
              travel_alowance: e.target.value,
            });
            setValue(
              'travel_alowance',
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
          errorMensage={formState.errors.travel_alowance?.message}
        />
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Êxito"
          classNameDiv="mt-1.5 border-gray-400"
          icon="%"
          defaultValue={maskHelper.formatDecimal(String(defaultValues.gain))}
          onChange={(e) => {
            e.target.value = maskHelper.formatDecimal(e.target.value);
            setDefaultValues({
              ...defaultValues,
              gain: Number(e.target.value),
            });
            setValue('gain', Number(e.target.value.replace(',', '.')));
          }}
          errorMensage={formState.errors.gain?.message}
        />
      </div>
      <div className="col-span-2 grid grid-cols-4 gap-3">
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Ato de Serviço Não Local"
          defaultValue={maskHelper.formatMoeda(
            String(defaultValues.non_local_service) ?? ''
          )}
          onChange={(e) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setDefaultValues({
              ...defaultValues,
              non_local_service: Number(e.target.value),
            });
            setValue(
              'non_local_service',
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
          errorMensage={formState.errors.non_local_service?.message}
        />
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Ato de Serviço Local"
          defaultValue={maskHelper.formatMoeda(
            String(defaultValues.local_service) ?? ''
          )}
          onChange={(e: any) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setDefaultValues({
              ...defaultValues,
              local_service: Number(e.target.value),
            });
            setValue(
              'local_service',
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
          errorMensage={formState.errors.local_service?.message}
        />
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="KM rodado comarca"
          defaultValue={maskHelper.formatMoeda(
            String(defaultValues.km_comarca) ?? ''
          )}
          onChange={(e: any) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setDefaultValues({
              ...defaultValues,
              km_comarca: Number(e.target.value),
            });
            setValue(
              'km_comarca',
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
          errorMensage={formState.errors.km_comarca?.message}
        />
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="KM rodado fora"
          defaultValue={maskHelper.formatMoeda(
            String(defaultValues.km_out) ?? ''
          )}
          onChange={(e: any) => {
            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setDefaultValues({
              ...defaultValues,
              km_out: Number(e.target.value),
            });
            setValue(
              'km_out',
              Number(maskHelper.numberCurrencyDecimal(e.target.value))
            );
          }}
          errorMensage={formState.errors.km_out?.message}
        />
      </div>

      <div className="col-span-2 grid grid-cols-3 gap-3">
        <Input
          disabled={!isEditable}
          variant="outline-orange"
          title="Representante Legal"
          value={defaultValues.client_agent_legal}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_legal: e.target.value,
            });
            setValue('client_agent_legal', e.target.value);
          }}
          errorMensage={formState.errors.client_agent_legal?.message}
        />
        <Input
          disabled={!isEditable}
          title="Email"
          variant="outline-orange"
          value={defaultValues.client_agent_email}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_email: e.target.value,
            });
            setValue('client_agent_email', e.target.value);
          }}
          errorMensage={formState.errors.client_agent_email?.message}
        />
        <Input
          disabled={!isEditable}
          title="CPF"
          variant="outline-orange"
          className="md:col-span-2 lg:col-span-1"
          type="text"
          value={maskHelper.cpf(defaultValues.client_agent_cpf)}
          placeholder="000.000.000-00"
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_cpf: e.target.value,
            });
            setValue(
              'client_agent_cpf',
              maskHelper.numberCPFCNPJ(e.target.value)
            );
          }}
          errorMensage={formState.errors.client_agent_cpf?.message}
        />
        <Input
          disabled={!isEditable}
          title="Telefone Celular"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          max={15}
          type="text"
          value={maskHelper.phone(defaultValues.client_agent_celular ?? '')}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_celular: e.target.value,
            });
            setValue(
              'client_agent_celular',
              maskHelper.numberPhone(e.target.value)
            );
          }}
          errorMensage={formState.errors.client_agent_celular?.message}
        />
        <Input
          disabled={!isEditable}
          title="Telefone Fixo"
          variant="outline-orange"
          placeholder="(00) 0000-0000"
          max={15}
          type="text"
          value={maskHelper.phoneFix(defaultValues.client_agent_fixo ?? '')}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_fixo: e.target.value,
            });
            setValue(
              'client_agent_fixo',
              maskHelper.numberPhone(e.target.value)
            );
          }}
          errorMensage={formState.errors.client_agent_fixo?.message}
        />

        <Input
          disabled={!isEditable}
          title="WhatsApp"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          max={15}
          type="text"
          value={maskHelper.phone(defaultValues.client_agent_whatsapp)}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_agent_whatsapp: e.target.value,
            });
            setValue(
              'client_agent_whatsapp',
              maskHelper.numberPhone(e.target.value)
            );
          }}
          errorMensage={formState.errors.client_agent_whatsapp?.message}
        />
      </div>
      <div className="col-span-2">
        <TextArea
          disabled={!isEditable}
          title="Observação"
          value={defaultValues.obs}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              obs: e.target.value,
            });
            setValue('obs', e.target.value);
          }}
          errorMensage={formState.errors.obs?.message}
        />
      </div>

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading || !isEditable}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default AdicionalData;
