import { useEffect, useState } from 'react';
import { ApiActivitieType } from '../api';
import { ActivitieType } from '../types';

export function useFetchActivitieType(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ActivitieType[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const activitie = await ApiActivitieType.getActivitieTypeByCompany(
        companyId
      );
      if (typeof activitie !== 'string') {
        setData(activitie);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ActivitieTypeList: data, isLoading };
}
