import { ArrowBack } from '@styled-icons/material-rounded/ArrowBack';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import {
  Attachments,
  Button,
  Card,
  SelectComponent,
  Spinner,
} from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import { isLoadingApplication } from '../../../../components/Spinner/spinnerScreen';
import { ToastNotify } from '../../../../components/Toast/toast';
import maskHelper from '../../../../helpers/mask.helper';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { Step } from '../../../funnel/types';
import { ResponseWinLose } from '../../../responseWinLose/types';
import { ApiOpportunity } from '../../api';
import {
  useFetchAllAnnotationsOpportunity,
  useFetchAllAttachmentsOpportunity,
  useFetchOpportunity,
  useFetchWhatsappOpportunity,
} from '../../hooks';

import AlertCard from '../../../../components/Card/alertCard';
import { getItem } from '../../../../helpers/storage.helper';
import { OfferProps } from '../../../offer/types';
import FormsAnswersList from '../../../searchForm/components/answers';
import { useFetchUsersCompanie } from '../../../users/hooks';
import { serviceOpportunity } from '../../types';
import { contactHasWhatsAppNumber } from '../../utils';
import UpdateStatus from '../mainPage/statusOpportunity';
import ActivityList from './components/activity';
import OpportunityAnnotations from './components/annotation';
import CampainData from './components/campaign';
import ContactCard from './components/contact';
import ContactOpportunityList from './components/contactList';
import GeneralData from './components/generalData';
import OpportunityOffers from './components/offers';
import ReopenContractModalAlert from './components/reopenOpportunity';
import SendWhatsApp from './components/sendWhatsapp';
import OpportunityServices from './components/services';
import TransferOpportunity from './components/transferOpportunity';
import Whatsapp from './components/whatsapp';

interface updateProps {
  closeUpdate: () => void;
  opportunityId: number;
  activityOptions: { label: string; value: number }[];
  stepsOptions: Step[];
  salesChannelOptions: { label: string; value: number }[];
  segmentOptions: { label: string; value: number }[];
  responseWinLose: ResponseWinLose[];
  productOptions: { label: string; value: number; price: number }[];
  funnels: { label: string; value: number }[];
  // eslint-disable-next-line no-unused-vars
}

const UpdateOpportunity: React.FC<updateProps> = ({
  salesChannelOptions,
  segmentOptions,
  stepsOptions,
  opportunityId,
  responseWinLose,
  closeUpdate,
  activityOptions,
  productOptions,
  funnels,
}: updateProps) => {
  const statusStyleText = {
    Aberta: 'text-gray-600 text-xs font-medium mt-1',
    Perdida: 'text-red text-xs font-medium mt-1',
    Ganha: 'text-green text-xs font-medium mt-1',
  };
  const accountProps = getTokenAccountInformation();
  const { setState } = useContext(isLoadingApplication);
  const { notify } = ToastNotify();
  const [showTransfer, setShowTransfer] = useState(false);
  const { UsersList } = useFetchUsersCompanie('key', accountProps.companie_id);
  const userListOptions = UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [showCreateUpdateOffer, setShowCreateUpdateOffer] = useState(false);
  const [keyOpportunity, setKeyOpportunity] = useState('key');
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [showReopenContract, setShowReopenContract] = useState(false);
  const { OpportunityFullData, setOpportunityFullData, isLoadingFullData } =
    useFetchOpportunity(keyOpportunity, opportunityId);
  const winResponses = responseWinLose
    .filter((item) => item.is_win)
    .map((res) => ({ label: res.title, value: res.id }));

  const loseResponses = responseWinLose
    .filter((item) => !item.is_win)
    .map((res) => ({ label: res.title, value: res.id }));

  const [updateStatus, setUpdateStatus] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [statusOpportunity, setStatusOpportunity] = useState({
    label: 'Ação',
    value: '',
  });
  const todayDate = new Date();

  const getDuration = (date: string) => {
    if (date === '') return '0 dias';
    const days = (
      (todayDate.getTime() - Date.parse(date)) /
      (1000 * 60 * 60 * 24)
    ).toFixed(0);
    return Number(days) > 1
      ? `${days} dias`
      : Number(days) === 0
      ? `0 dias`
      : `${days} dia`;
  };

  async function reloadProducts(
    method: 'create' | 'update',
    products?: serviceOpportunity[]
  ) {
    setState(true);
    if (method === 'create') {
      const response = await ApiOpportunity.getProductByOpportunity(
        opportunityId
      );

      if (typeof response !== 'string' && OpportunityFullData) {
        setOpportunityFullData({
          ...OpportunityFullData,
          opportunity_products: response,
        });
      }
    } else if (OpportunityFullData && products) {
      setOpportunityFullData({
        ...OpportunityFullData,
        opportunity_products: products,
      });
    }
    setState(false);
  }

  const [messageKey, setMessageKey] = useState('key');
  const { WhatsappOpportunity } = useFetchWhatsappOpportunity({
    key: messageKey,
    whatsappNumber: OpportunityFullData?.opportunity_person[0].person.whatsapp,
  });

  const [keyAnnotation, setKeyAnnotation] = useState('key');
  const { AnnotationList } = useFetchAllAnnotationsOpportunity(
    keyAnnotation,
    opportunityId
  );
  const [currentOffer, setCurrentOffer] = useState<OfferProps | null>(null);

  const [registerPageOffer, setRegisterPageOffer] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const [keyFiles, setKeyFiles] = useState('key');
  const { AttachmentsList, isLoading } = useFetchAllAttachmentsOpportunity(
    keyFiles,
    opportunityId
  );

  const getFullValue = (ListProducts: serviceOpportunity[]) =>
    ListProducts.reduce(
      (interator, current) => interator + Number(current.total ?? 0),
      0
    );

  const [registerPage, setRegisterPage] = useState('Tarefas');

  useEffect(() => {
    if (registerPage === 'Pesquisas') {
      window.location.hash = 'search';
    } else {
      window.location.hash = 'update';
    }
  }, [registerPage]);

  const menus = [
    'Tarefas',
    'Contatos',
    'Produtos/Serviços',
    'Propostas',
    'Anotações',
    'Anexos',
    'Pesquisas',
    'Whatsapp',
    'Dados Campanha',
  ];

  const storedAccountProps: any = getItem('menu') ?? [];

  const result = storedAccountProps
    .flatMap((config: any) => config.group || [])
    .flatMap((group: any) => group.menu || [])
    .filter((menu: any) => menu.route_frontend === 'transfer-opportunity')
    .map((menu: any) => menu.route_frontend)[0];

  const optionsAction = result
    ? {
        Ganha: [
          {
            label: 'Reabrir',
            value: 'Aberta',
            color: '#000',
          },
          {
            label: 'Perder',
            value: 'Perdida',
            color: '#DA3C3C',
          },
          {
            label: 'Transferir oportunidade',
            value: 'transfer',
            color: '#000',
          },
          {
            label: 'Enviar WhatsApp',
            value: 'whatsapp',
            color: '#000',
            isdisabled: false,
          },
          {
            label: 'Excluir oportunidade',
            value: 'Excluir',
            color: '#000',
          },
        ],
        Perdida: [
          {
            label: 'Reabrir',
            value: 'Aberta',
            color: '#000',
          },
          {
            label: 'Transferir oportunidade',
            value: 'transfer',
            color: '#000',
          },
          {
            label: 'Enviar WhatsApp',
            value: 'whatsapp',
            color: '#000',
            isdisabled: false,
          },
          {
            label: 'Excluir oportunidade',
            value: 'Excluir',
            color: '#000',
          },
        ],
        Aberta: [
          { label: 'Ganhar', value: 'Ganha', color: '#33A70A' },
          { label: 'Perder', value: 'Perdida', color: '#DA3C3C' },
          {
            label: 'Transferir oportunidade',
            value: 'transfer',
            color: '#000',
          },
          { label: 'Enviar WhatsApp', value: 'whatsapp', color: '#000' },
          { label: 'Excluir oportunidade', value: 'Excluir', color: '#000' },
        ],
      }
    : {
        Ganha: [
          {
            label: 'Reabrir',
            value: 'Aberta',
            color: '#000',
          },
          {
            label: 'Perder',
            value: 'Perdida',
            color: '#DA3C3C',
          },
          {
            label: 'Enviar WhatsApp',
            value: 'whatsapp',
            color: '#000',
            isdisabled: false,
          },
          {
            label: 'Excluir oportunidade',
            value: 'Excluir',
            color: '#000',
          },
        ],
        Perdida: [
          {
            label: 'Reabrir',
            value: 'Aberta',
            color: '#000',
          },
          {
            label: 'Enviar WhatsApp',
            value: 'whatsapp',
            color: '#000',
            isdisabled: false,
          },
          {
            label: 'Excluir oportunidade',
            value: 'Excluir',
            color: '#000',
          },
        ],
        Aberta: [
          { label: 'Ganhar', value: 'Ganha', color: '#33A70A' },
          { label: 'Perder', value: 'Perdida', color: '#DA3C3C' },
          { label: 'Enviar WhatsApp', value: 'whatsapp', color: '#000' },
          { label: 'Excluir oportunidade', value: 'Excluir', color: '#000' },
        ],
      };

  const reopenOpportunity = async (status: string) => {
    setIsLoadingStatus(true);
    const res = await ApiOpportunity.updateOpportunityStatus(opportunityId, {
      status,
    });
    if (res.id) {
      notify({
        message: 'Oportunidade reaberta com sucesso',
        type: 'Success',
      });
      setKeyOpportunity(`${Math.random()} reopen`);
      setStatusOpportunity({
        label: 'Ação',
        value: '',
      });
      setShowReopenContract(false);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoadingStatus(false);
  };

  const updateStep = async (newFunnel: number) => {
    setState(true);
    const response = await ApiOpportunity.updateOpportunityWorkflow(
      {
        funnel_step_id: newFunnel,
      },
      opportunityId
    );
    if (response.id && OpportunityFullData) {
      setKeyOpportunity(`${Math.random()}`);
    }
    setState(false);
  };

  useEffect(() => {
    setMessageKey(`${Math.random()} whatsapp`);
  }, [OpportunityFullData?.opportunity_person[0].person.whatsapp]);

  return OpportunityFullData ? (
    <>
      <a href="#home" className="no-underline">
        <ArrowBack
          onClick={closeUpdate}
          size={24}
          className="-mb-5 mt-3 hover:scale-110 cursor-pointer min-h-[24px]"
        />
      </a>

      <UpdateStatus
        setState={setState}
        openCreateOffer={() => {
          setRegisterPage('Propostas');
          setRegisterPageOffer({ page: 0, title: 'Dados Cliente' });
          setCurrentOffer(null);
          setShowCreateUpdateOffer(true);
          setUpdateStatus(false);
        }}
        fullPropsOpportunity={OpportunityFullData}
        opportunityId={opportunityId}
        opportunityTitle={OpportunityFullData.title}
        responseWinLose={
          statusOpportunity.value === 'Ganha' ? winResponses : loseResponses
        }
        show={updateStatus}
        setShow={setUpdateStatus}
        returnMainPage={closeUpdate}
        status={statusOpportunity.value}
        title={statusOpportunity.label}
        updateOpportunity={(e) => {
          setKeyOpportunity(e);
          setStatusOpportunity({
            label: 'Ação',
            value: '',
          });
        }}
      />

      <ReopenContractModalAlert
        isLoading={isLoadingStatus}
        reopenOpportunity={() => reopenOpportunity('Aberta')}
        setShow={() => setShowReopenContract(false)}
        show={showReopenContract}
      />
      <SendWhatsApp
        setOpen={setOpenWhatsApp}
        show={openWhatsApp}
        number={OpportunityFullData.opportunity_person[0].person.whatsapp}
      />
      <TransferOpportunity
        currentFunnel={funnels.find(
          (item) => item.value === OpportunityFullData.funnel_step.funnel_id
        )}
        goToMainPage={closeUpdate}
        show={showTransfer}
        funnelList={funnels}
        notify={notify}
        opportunityId={opportunityId}
        setShow={setShowTransfer}
        userList={userListOptions}
      />
      <div className="flex flex-col sm:flex-row justify-between mt-12 mb-3 md:mb-10">
        <div className="flex items-center">
          <div className="text-matterhorn border-2 bg-white border-solid p-4 w-16 h-16 flex items-center justify-center rounded border-gray-300 mr-3">
            <IconDictionary name="Oportunidades" size={36} />
          </div>
          <div className="flex flex-col gap-1 text-xs justify-center">
            <p className="font-medium">{OpportunityFullData.title}</p>
            <p className="font-medium">
              {OpportunityFullData.opportunity_person[0].person.name}
            </p>
            <div className="font-semibold flex gap-1">
              Responsável:
              <p className="font-normal">
                {OpportunityFullData.companie_user.name}
              </p>
            </div>
          </div>
        </div>
        <SelectComponent
          value={{
            label: 'Ação',
            value: '',
          }}
          className="mt-3 sm:mt-0 sm:w-52 my-auto"
          options={optionsAction[OpportunityFullData.status]}
          closeMenuOnSelect
          placeholder="Ação"
          onChange={(e: any) => {
            if (e.value === 'Aberta') {
              reopenOpportunity(e.value);
            } else if (e.value === 'whatsapp') {
              setOpenWhatsApp(true);
            } else if (e.value === 'transfer') {
              setShowTransfer(true);
            } else {
              setStatusOpportunity(e);
              setUpdateStatus(true);
            }
          }}
        />
      </div>
      <div className="flex flex-col-reverse xl:flex-row pb-5">
        <div className="w-full xl:w-1/3 mb-5">
          <GeneralData
            updateOpportunity={setOpportunityFullData}
            companieUserId={accountProps.id}
            opportunity={OpportunityFullData}
            salesChannelOptions={salesChannelOptions}
            segmentOptions={segmentOptions}
          />
          <ContactCard
            updateOpportunity={setKeyOpportunity}
            contact={OpportunityFullData.opportunity_person[0]}
          />
        </div>
        <div className="flex flex-col xl:ml-3 w-full xl:w-2/3">
          <Card className="flex-col w-full">
            <div className="p-4 flex overflow-x-auto">
              {stepsOptions.map((step, index, array) => (
                <div className="w-full" key={step.id}>
                  {step.id === OpportunityFullData.funnel_step_id && (
                    <div className="relative text-[9px] z-10 text-white flex items-center justify-center bg-primary border border-white border-solid w-10 h-5 rounded-full -mb-2 ml-3">
                      {getDuration(
                        OpportunityFullData?.opportunity_worflow
                          .filter((item) => item.funnel_step_id === step.id)
                          .pop()?.created_at ?? ''
                      )}
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => updateStep(step.id)}
                    className={classNames([
                      'font-normal cursor-pointer min-w-[151px] w-full flex justify-between h-11 items-center border-y-gray-300 border-solid border-y hover:bg-secondary hover:text-white',
                      step.id === OpportunityFullData.funnel_step_id
                        ? 'bg-primary text-white'
                        : 'bg-white mt-3',
                    ])}
                  >
                    {index !== 0 ? (
                      <>
                        <div className="w-10 border-y border-solid border-gray-300 h-11 bg-inherit" />
                        <div
                          className={classNames([
                            'w-full h-full flex items-center justify-center',
                            index === array.length - 1 &&
                              'border-r border-solid border-gray-300',
                          ])}
                        >
                          <p
                            className={classNames([
                              'text-xs',
                              index !== array.length - 1 && '-mr-1',
                            ])}
                          >
                            {step.title}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-10 border-y border-l border-solid border-gray-300 h-11 bg-inherit" />
                        <div className="w-full h-full flex items-center">
                          <p className="text-xs mx-auto">{step.title}</p>
                        </div>
                      </>
                    )}

                    {index !== array.length - 1 && (
                      <div className="w-[42px] bg-inherit border border-solid border-gray-300 h-[30px] -mr-4 rotate-45 border-b-0 border-l-0" />
                    )}
                  </button>
                </div>
              ))}
            </div>
            <hr className="w-full h-px bg-gray-400" />
            <div className="grid grid-cols-3 sm:grid-cols-6 gap-2 p-4">
              <div className="flex flex-col text-sm mb-2 sm:mb-0">
                <p>Valor</p>

                <p className="text-xs mt-1">
                  {maskHelper.formatMoeda(
                    String(
                      getFullValue(OpportunityFullData.opportunity_products)
                    )
                  )}
                </p>
              </div>
              <div className="flex flex-col text-sm">
                <p>Expectativa</p>
                <p className="text-xs mt-1">
                  {maskHelper.formatDateDMY(
                    OpportunityFullData.date_expectation ?? ''
                  )}
                </p>
              </div>
              <div className="flex flex-col text-sm">
                <p>Duração</p>
                <p className="text-xs mt-1">
                  {getDuration(OpportunityFullData.created_at)}
                </p>
              </div>
              <div className="flex flex-col text-sm">
                <p>Início</p>
                <p className="text-xs mt-1">
                  {maskHelper.formatDateDMY(OpportunityFullData.created_at)}
                </p>
              </div>
              <div className="flex flex-col text-sm">
                <p>Atualização</p>
                <p className="text-xs mt-1">
                  {maskHelper.formatDateDMY(
                    OpportunityFullData.opportunity_status
                      .pop()
                      ?.created_at.slice(0, 10) ?? ''
                  )}
                </p>
              </div>
              <div className="flex flex-col text-sm">
                <p>Status</p>
                <p className={statusStyleText[OpportunityFullData.status]}>
                  {OpportunityFullData.status}
                </p>
              </div>
            </div>
          </Card>
          <Card className="flex-col mt-3 mb-3 xl:mb-0">
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 2xl:grid-cols-7 gap-2 p-4">
              {menus.map((menu) => (
                <Button
                  disabled={
                    menu === 'Whatsapp' &&
                    contactHasWhatsAppNumber(
                      OpportunityFullData.opportunity_person[0]?.person.whatsapp
                    ) &&
                    !WhatsappOpportunity.instances
                  }
                  variant={registerPage === menu ? 'primary-strong' : 'gray'}
                  key={menu}
                  className={`font-medium relative flex justify-center items-center ${
                    menu === 'Whatsapp' &&
                    !WhatsappOpportunity.data &&
                    contactHasWhatsAppNumber(
                      OpportunityFullData.opportunity_person[0]?.person.whatsapp
                    ) &&
                    'opacity-50'
                  }`}
                  onClick={() => setRegisterPage(menu)}
                >
                  {menu}
                  &nbsp;
                  {menu === 'Whatsapp' &&
                    contactHasWhatsAppNumber(
                      OpportunityFullData.opportunity_person[0]?.person.whatsapp
                    ) &&
                    !WhatsappOpportunity.instances && (
                      <div className="absolute">
                        <Spinner size="w-4 h-4" />
                      </div>
                    )}
                </Button>
              ))}
            </div>

            <div className="mb-3 min-h-[300px] mt-4">
              {registerPage === 'Tarefas' && (
                <ActivityList
                  opportunityId={opportunityId}
                  reloadOpportunity={setKeyOpportunity}
                  activities={OpportunityFullData.activities}
                  activityOptions={activityOptions}
                  companieId={accountProps.companie_id}
                  companieUserId={accountProps.id}
                  personId={null}
                />
              )}
              {registerPage === 'Contatos' && (
                <ContactOpportunityList
                  isLoading={isLoadingFullData}
                  reloadOpportunity={setKeyOpportunity}
                  contacts={OpportunityFullData.opportunity_person}
                  opportunityId={opportunityId}
                  companyUserId={accountProps.id}
                />
              )}
              {registerPage === 'Produtos/Serviços' && (
                <OpportunityServices
                  OpportunityProductList={
                    OpportunityFullData.opportunity_products
                  }
                  updateProducts={(method, products) =>
                    reloadProducts(method, products)
                  }
                  productsOptions={productOptions}
                  companieUserId={accountProps.id}
                  opportunityId={OpportunityFullData.id}
                />
              )}
              {registerPage === 'Propostas' && (
                <OpportunityOffers
                  setState={setState}
                  personOpportunity={OpportunityFullData.opportunity_person}
                  showCreateUpdate={showCreateUpdateOffer}
                  setShowCreateUpdate={setShowCreateUpdateOffer}
                  productsOptions={productOptions}
                  reloadOpportunity={setKeyOpportunity}
                  opportunityId={opportunityId}
                  currentOffer={currentOffer}
                  registerPage={registerPageOffer}
                  setCurrentOffer={setCurrentOffer}
                  setRegisterPage={setRegisterPageOffer}
                />
              )}
              {registerPage === 'Anotações' && (
                <OpportunityAnnotations
                  reloadNotes={setKeyAnnotation}
                  annotations={AnnotationList}
                  companieUserId={accountProps.id}
                  opportunityId={OpportunityFullData.id}
                />
              )}
              {registerPage === 'Anexos' && (
                <div className="p-4 w-full">
                  <Attachments
                    secondKeyFile={{
                      label: 'opportunity_id',
                      value: OpportunityFullData.id,
                    }}
                    AttachmentsList={AttachmentsList}
                    apiFunction={ApiOpportunity.createOpportunityAttachments}
                    deleteApiFunction={
                      ApiOpportunity.deleteOpportunityAttachments
                    }
                    id={accountProps.id}
                    keyFile="companie_user_id"
                    updateTable={setKeyFiles}
                    isLoadingTable={isLoading}
                  />
                </div>
              )}
              {registerPage === 'Pesquisas' && (
                <div className="p-4 w-full">
                  <FormsAnswersList
                    opportunityId={OpportunityFullData.id}
                    companyId={accountProps.companie_id}
                  />
                </div>
              )}
              {registerPage === 'Whatsapp' ? (
                contactHasWhatsAppNumber(
                  OpportunityFullData.opportunity_person[0].person.whatsapp
                ) ? (
                  <div className="p-4 w-full">
                    <Whatsapp
                      totalMessages={WhatsappOpportunity.total}
                      messageData={WhatsappOpportunity.data}
                      instanceData={WhatsappOpportunity.instances}
                      setMessageKey={setMessageKey}
                      onCancel={() => setRegisterPage('Tarefas')}
                      contact={OpportunityFullData.opportunity_person[0]}
                    />
                  </div>
                ) : (
                  <div className="mx-8">
                    <AlertCard
                      message="O contato vinculado a oportunidade não possui um número de
                   WhatsApp cadastrado. Para utilizar esse recurso da
                  plataforma, atualize o WhatsApp do Contato."
                    />
                  </div>
                )
              ) : (
                <> </>
              )}
              {registerPage === 'Dados Campanha' && (
                <div className="p-4 w-full">
                  <CampainData
                    defaultValue={OpportunityFullData}
                    updateOpportunity={setOpportunityFullData}
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </>
  ) : isLoadingFullData ? (
    <div className="w-full flex items-center justify-center my-auto">
      <Spinner />
    </div>
  ) : (
    <>
      <ArrowBack
        onClick={closeUpdate}
        size={24}
        className="-mb-5 mt-3 hover:scale-110 cursor-pointer"
      />
      <div className="w-full flex items-center justify-center my-auto">
        Não foi possível abrir a oportunidade
      </div>
    </>
  );
};

export default UpdateOpportunity;
