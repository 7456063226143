import React, { useEffect, useState } from 'react';
import InputColor from 'react-input-color';
import { Button, Input, Modal, Spinner } from '../../../components';
import { WhatsappTagProps } from '../types';
import { ApiWhatsappTag } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import {
  dictionaryError,
  isValidHexColor,
} from '../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  WhatsappTag: WhatsappTagProps | null;
  setNewTag?: (e: WhatsappTagProps) => void;
}

const RegisterWhatsappTag: React.FC<registerModalProps> = ({
  WhatsappTag,
  reloadTable,
  setShow,
  setNewTag,
  show,
}) => {
  const defaultColor = WhatsappTag?.color ?? '#5e72e4';
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<WhatsappTagProps, 'id'>>({
    title: WhatsappTag?.title ?? '',
    color: defaultColor,
  });
  const [color, setColor] = React.useState<any>({
    hex: defaultColor,
  });
  const [isLoading, setIsLoading] = useState(false);
  const Submit = async () => {
    setIsLoading(true);
    if (WhatsappTag !== null) {
      const res = await ApiWhatsappTag.updateWhatsappTag(
        values,
        WhatsappTag.id
      );
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tag atualizada com sucesso',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiWhatsappTag.createWhatsappTag(values);
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Tag criada com sucesso',
          type: 'Success',
        });
        if (setNewTag) setNewTag(res);
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    if (show === true) {
      setValues({
        title: WhatsappTag?.title ?? '',
        color: WhatsappTag?.color ?? '',
      });
      setColor({ hex: defaultColor });
    }
  }, [WhatsappTag, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={WhatsappTag ? 'Atualizar Tag' : 'Criar Tag'}
      size="medium"
      minHeight="min-h-[550px]"
    >
      <div className="flex flex-col justify-center gap-3">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />

        <Input
          classNameDiv="border-gray-400 max-h-[42px] mt-1.5"
          variant="outline-orange"
          title="Cor"
          value={values.color}
          onChange={(e) => setValues({ ...values, color: e.target.value })}
          onBlur={(e) => {
            if (isValidHexColor(e.target.value)) {
              setColor({ ...color, hex: e.target.value });
              setValues({ ...values, color: e.target.value });
            } else {
              setValues({ ...values, color: color.hex });
            }
          }}
          icon={
            <InputColor
              initialValue={color.hex}
              onChange={(e) => {
                setColor(e);
                setValues({ ...values, color: e.hex });
              }}
              placement="right"
            />
          }
        />

        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterWhatsappTag;
