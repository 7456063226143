import React, { useCallback, useEffect, useState } from 'react';
import { FaDatabase, FaExternalLinkAlt, FaSearch } from 'react-icons/fa';
import DatePicker from 'react-date-picker';
import { Controller, useForm } from 'react-hook-form';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import {
  Button,
  Card,
  HeaderPages,
  SelectComponent,
  Spinner,
} from '../../../../components';
import {
  SignatureTransactionsPayload,
  StatusType,
  Transaction,
  getSignatureExtract,
  getSignatureTransactions,
} from '../api';
import TableWithPaginationBack from '../../../../components/Table/tableWithPaginationBack';
import BuyModal from '../components/BuyModal';
import maskHelper from '../../../../helpers/mask.helper';
import VisualizeModal from '../components/VisualizeModal';
import { useDigitalSignaturedCreditContext } from '../context';

const periodOptions = [
  { label: 'Hoje', value: 'today' },
  { label: 'Este mês', value: 'this_month' },
  { label: 'Ontem', value: 'yesterday' },
  { label: 'Esta semana', value: 'this_week' },
  { label: 'Outro período', value: 'other_period' },
];
const operationTypes = [
  { label: 'Crédito', value: 'credit' },
  { label: 'Débito', value: 'debit' },
  { label: 'Todos', value: 'all' },
];
const statusOptions = [
  { label: 'Debitado', value: 'Debitado' },
  { label: 'Aguardando Pagamento', value: 'Ag.Pagamento' },
  { label: 'Ativo', value: 'Ativo' },
  { label: 'Inadimplente', value: 'Inadimplente' },
  { label: 'Cancelado', value: 'Cancelado' },
];

const DigitalSignatureCreditPage: React.FC = () => {
  const [selectedPeriodType, setSelectedPeriodType] = useState(undefined);
  const [extractData, setExtractData] = useState<{
    data: Transaction[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [transactionsPagination, setTransactions] = useState<{
    data: Transaction[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [loadingTransactionsToExport, setLoadingTransactionsToExport] =
    useState(false);
  const [pageExtract, setPageExtract] = useState(1);
  const [pageTransactions, setPageTransactions] = useState(1);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [isVisualizeModalOpen, setIsVisualizeModalOpen] = useState(false);

  const digitalSignatureBalanceContext = useDigitalSignaturedCreditContext();

  const loadSignatureExtract = useCallback(
    async (payload: SignatureTransactionsPayload) => {
      setLoadingExtract(true);
      try {
        const response = await getSignatureExtract(payload);
        setExtractData(response);
      } finally {
        setLoadingExtract(false);
      }
    },
    []
  );

  const loadSignatureTransactionsToExport = useCallback(async () => {
    setLoadingTransactionsToExport(true);
    try {
      const response = await getSignatureExtract({
        status: [
          'Ag.Pagamento',
          'Ativo',
          'Cancelado',
          'Debitado',
          'Inadimplente',
        ],
        take: 10000,
        filter: 'all',
        skip: 0,
        type: 'all',
      });
      return response;
    } finally {
      setLoadingTransactionsToExport(false);
    }
  }, []);

  useEffect(() => {
    loadSignatureExtract({
      status: [
        'Ag.Pagamento',
        'Ativo',
        'Cancelado',
        'Debitado',
        'Inadimplente',
      ],
      take: 10,
      filter: 'all',
      skip: (pageExtract - 1) * 10,
      type: 'all',
    });
  }, [loadSignatureExtract, pageExtract]);

  const loadSignatureTransactions = useCallback(() => {
    setLoadingTransactions(true);
    getSignatureTransactions((pageTransactions - 1) * 10, 10)
      .then(setTransactions)
      .finally(() => setLoadingTransactions(false));
  }, [pageTransactions]);
  useEffect(() => {
    loadSignatureTransactions();
  }, [loadSignatureTransactions]);

  const onSubmit = (data: SignatureTransactionsPayload) => {
    loadSignatureExtract({
      ...data,
      take: 10,
      skip: (pageExtract - 1) * 10,
      status: data.status?.length
        ? data.status
        : (statusOptions.map((option) => option.value) as StatusType[]),
      type: data.type ?? 'all',
      filter: data.filter ?? 'all',
    });
  };
  const { control, setValue, handleSubmit } =
    useForm<SignatureTransactionsPayload>({ defaultValues: {} });

  const onChangePeriod = (e: any) => {
    setValue('filter', e.value);
    setSelectedPeriodType(e.value);
  };
  const onChangePeriodStart = (d: Date) => {
    setValue('date_start', d);
  };
  const onChangePeriodEnd = (d: Date) => {
    setValue('date_end', d);
  };
  const onChangeOperationType = (e: any) => {
    setValue('type', e.value);
  };

  const onBuyPlanSuccess = useCallback(() => {
    setIsBuyModalOpen(false);
    loadSignatureExtract({
      status: [],
      take: 10,
      filter: 'all',
      skip: 0,
      type: 'all',
    });
    setPageExtract(1);
    loadSignatureTransactions();
  }, [loadSignatureTransactions]);
  const onChangeStatus = (selectedOptions: any) => {
    setValue(
      'status',
      selectedOptions?.map((option: any) => option.value)
    );
  };

  const renderFromDateInput = ({ field }: any) => (
    <DatePicker
      value={field.value}
      openCalendarOnFocus
      format="dd/MM/yyyy"
      className="start_date_input"
      onChange={onChangePeriodStart}
    />
  );
  const renderToDateInput = ({ field }: any) => (
    <DatePicker
      value={field.value}
      openCalendarOnFocus
      format="dd/MM/yyyy"
      className="start_date_input"
      onChange={onChangePeriodEnd}
    />
  );
  const renderFilterTypeSelect = ({ field }: any) => (
    <SelectComponent
      classNameDiv="flex-auto"
      options={periodOptions}
      value={periodOptions.filter((option) => field.value === option.value)}
      onChange={onChangePeriod}
    />
  );
  const renderTypeSelect = ({ field }: any) => (
    <SelectComponent
      classNameDiv="flex-auto"
      options={operationTypes}
      placeholder="Tipos de operação"
      value={operationTypes.filter((option) => field.value === option.value)}
      onChange={onChangeOperationType}
    />
  );
  const renderStatusSelect = ({ field }: any) => (
    <SelectComponent
      isMulti
      classNameDiv="flex-auto"
      options={statusOptions}
      placeholder="Status"
      value={statusOptions.filter((item) => field.value?.includes(item.value))}
      maxOptionsHeight="300px"
      onChange={onChangeStatus}
    />
  );
  const totalBalanceInfo = `${
    digitalSignatureBalanceContext.signatureCreditBalance
  } ${
    digitalSignatureBalanceContext.signatureCreditBalance > 1
      ? 'Créditos'
      : 'Crédito'
  }`;

  const extractColumns = [
    {
      name: 'Descrição',
      key: 'description',
      width: '400px',
      selector: (row: Transaction) =>
        !row.contract_id
          ? `Compra Crédito: ${row.companie_digital_signature_credit.digital_signature_credit.name}`
          : `Contrato ${row.contract.id}: ${row.contract.client_name}`,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: Transaction) =>
        !row.contract_id &&
        maskHelper.formatMoeda(
          row.companie_digital_signature_credit.value ?? 0
        ),
    },
    {
      name: 'Crédit',
      key: 'credit',
      selector: (row: Transaction) => (!row.contract ? row.qty : ''),
    },
    {
      name: 'Débito',
      key: 'debit',
      selector: (row: Transaction) => (row.contract ? 1 : ''),
    },
    {
      name: 'Data',
      key: 'created_at',
      selector: (row: Transaction) =>
        new Date(row.created_at).toLocaleDateString('pt-br'),
    },
  ];

  const transactionColumns = [
    {
      name: 'Id',
      key: 'galax_pay_id_subs',
      selector: (row: any) => row.galax_pay_id_subs,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: any) => row.status,
    },
    {
      name: 'Data',
      key: 'created_at',
      selector: (row: any) =>
        new Date(row.created_at).toLocaleDateString('pt-br'),
    },
    {
      name: 'Qtde.',
      key: 'qty',
      selector: (row: any) => row.qty,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: any) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Link',
      key: 'payment_link',
      selector: (row: any) =>
        row.payment_link && (
          <a
            target="_blank"
            rel="noreferrer"
            href={row.payment_link}
            className="flex items-center gap-2"
          >
            <FaExternalLinkAlt size={16} />
            Pagamento
          </a>
        ),
    },
  ];

  const exportToXLS = async () => {
    const transactionsResponse = await loadSignatureTransactionsToExport();
    const excelData = [
      ['Descrição', 'Valor', 'Crédito', 'Débito', 'Data', 'Status'],
      ...transactionsResponse.data.map((row) => [
        // descrição
        !row.contract_id
          ? `Compra Crédito: ${row.companie_digital_signature_credit.digital_signature_credit.name}`
          : `Contrato: ${row.contract.client_name}`,
        // valor
        !row.contract_id &&
          maskHelper.formatMoeda(
            row.companie_digital_signature_credit.value ?? 0
          ),
        // crédito
        !row.contract ? row.qty : '',
        // débito
        row.contract ? 1 : '',
        // data
        new Date(row.created_at).toLocaleDateString('pt-br'),
        // status
        row.contract_id
          ? 'Debitado'
          : row.companie_digital_signature_credit.status,
      ]),
    ];
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const csvData: any = [];
    const keys = excelData[0] as string[];
    const values = excelData.slice(1, excelData.length);
    let item: any = {};

    for (let j = 0; j < values.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        item[keys[i]] = values[j][i];
      }
      csvData.push(item);
      item = {};
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `balancete${fileExtension}`);
  };
  return (
    <>
      <BuyModal
        isOpen={isBuyModalOpen}
        setIsOpen={setIsBuyModalOpen}
        onBuyPlanSuccess={onBuyPlanSuccess}
      />
      <VisualizeModal
        isOpen={isVisualizeModalOpen}
        setIsOpen={setIsVisualizeModalOpen}
      />
      <HeaderPages title="Crédito assinatura digital" />
      <div className="flex flex-col gap-2 mt-10">
        <div className="flex gap-2 w-full">
          <Card className="p-5 flex-1 flex-col">
            <strong>Pesquisar</strong>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex gap-2 w-full flex-wrap"
            >
              <Controller
                control={control}
                name="filter"
                render={renderFilterTypeSelect}
              />
              {selectedPeriodType === 'other_period' && (
                <div className="flex mb-1.5">
                  <Controller
                    control={control}
                    name="date_start"
                    render={renderFromDateInput}
                  />
                  <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                    Até
                  </p>
                  <Controller
                    control={control}
                    name="date_end"
                    render={renderToDateInput}
                  />
                </div>
              )}
              <Controller
                name="type"
                control={control}
                render={renderTypeSelect}
              />
              <Controller
                name="status"
                control={control}
                render={renderStatusSelect}
              />
              <Button
                type="submit"
                variant="outline-primary"
                className="w-10 h-10 flex justify-center items-center"
              >
                <FaSearch />
              </Button>
            </form>
          </Card>
          <Card className="p-5 flex-initial min-w-[300px]">
            <div className="flex items-center gap-4">
              <FaDatabase size={24} />
              <div>
                <p>Seu saldo</p>
                <p>
                  {digitalSignatureBalanceContext.loadingSignatureCreditBalance ? (
                    <Spinner />
                  ) : (
                    <strong>{totalBalanceInfo}</strong>
                  )}
                </p>
              </div>
              <div>
                <Button
                  onClick={() => setIsBuyModalOpen(true)}
                  className="px-4"
                >
                  Comprar mais
                </Button>
              </div>
            </div>
          </Card>
          <Card className="p-5 flex-initial min-w-[300px] flex-col items-center justify-center">
            <p className="self-center">
              <strong>Extrato Crédito e Débito</strong>
            </p>
            <div className="w-full flex gap-2 self-center">
              <Button
                className="flex-1"
                onClick={() => setIsVisualizeModalOpen(true)}
              >
                Visualizar
              </Button>
              <Button
                className="flex-1 flex items-center gap-2 justify-center"
                onClick={exportToXLS}
              >
                Exportar {loadingTransactionsToExport && <Spinner />}
              </Button>
            </div>
          </Card>
        </div>
        <Card className="pr-4 pl-4 pb-4 flex gap-2">
          <div className="w-[50%]">
            <TableWithPaginationBack
              totalRegisters={transactionsPagination.total ?? 0}
              totalPerPage={10}
              setStartDate={() => {}}
              columns={transactionColumns}
              data={transactionsPagination.data}
              isLoading={loadingTransactions}
              currentPage={pageTransactions}
              setCurrentPage={setPageTransactions}
              selectedRowId={-1}
            />
          </div>
          <div className="w-[50%]">
            <TableWithPaginationBack
              totalRegisters={extractData.total}
              totalPerPage={10}
              setStartDate={() => {}}
              columns={extractColumns}
              data={extractData.data}
              isLoading={loadingExtract}
              currentPage={pageExtract}
              setCurrentPage={setPageExtract}
              selectedRowId={-1}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default DigitalSignatureCreditPage;
