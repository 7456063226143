import { useEffect, useState } from 'react';
import { SubAccountFullProps } from '../types';
import { ApiCompanyGalaxPay } from '../api';

export function useFetchCompanies(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SubAccountFullProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiCompanyGalaxPay.getSubAccounts();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { companiesList: data, isLoading };
}
