import { useEffect, useState } from 'react';
import moment from 'moment';
import { ApiActivitie } from '../api';
import { ActivityProps, formProps } from '../types';
import {
  getToken,
  getTokenAccountInformation,
} from '../../../helpers/token.helper';

export function useFetchActivitie(
  key: string,
  period: string,
  filters: formProps,
  values: string,
  notificationReload: boolean
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ data: ActivityProps[]; total: number }>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    setIsLoading(true);
    const FetchActivity = async () => {
      if (values === '') {
        const activitie = await ApiActivitie.getActivityFilters(period, {
          ...filters,
          personId: filters.personId.map((item) => item.value),
          opportunityId: filters.opportunityId.map((item) => item.value),
        });
        if (typeof activitie !== 'string') {
          setData(activitie);
        }
      } else if (values.length >= 3) {
        const activitie = await ApiActivitie.seachActivity(
          values,
          filters.skip,
          filters.take
        );
        if (typeof activitie !== 'string') {
          setData(activitie);
        }
      }

      setIsLoading(false);
    };

    FetchActivity();
  }, [key, period, filters, values, notificationReload]);
  return { ActivitieList: data, isLoading, setActivityList: setData };
}

export function useNotificationSchedule(userId?: number) {
  const isAdmin = getTokenAccountInformation();
  const [notifications, setNotifications] = useState<ActivityProps[]>([]);
  const [userIdState, setUserId] = useState<number | undefined>(userId);
  const [notificationQueue, setNotificationQueue] = useState<ActivityProps[]>(
    []
  );

  const ONE_MINUTE = 10000;
  let oldActivities: ActivityProps[] = [];

  useEffect(() => {
    if (userIdState === undefined || isAdmin.administrator_id) {
      return;
    }
    const fetchActivities = async () => {
      const newActivities = await ApiActivitie.getActivitiesUser(
        userIdState as number
      );
      const today = moment();
      const tomorrow = moment();
      tomorrow.add(1, 'day');

      if (
        typeof newActivities !== 'string' &&
        newActivities !== oldActivities
      ) {
        oldActivities = newActivities;
        newActivities.forEach((activity: any) => {
          const dateTime = moment(activity.date).add(1, 'day');

          if (dateTime.isBefore(today) || dateTime.isAfter(tomorrow)) {
            return;
          }
          dateTime.hour(activity.hour.split(':')[0]);

          dateTime.minute(activity.hour.split(':')[1]);
          dateTime.second(0);

          const dateTimeMinusThirtyMinutes = dateTime;
          dateTimeMinusThirtyMinutes.minute(activity.hour.split(':')[1] - 30);
          if (
            notificationQueue.find(
              (notification) => notification.id === activity.id
            )
          ) {
            return;
          }
          const now = moment();
          if (dateTimeMinusThirtyMinutes.isSameOrAfter(now)) {
            const millisecondsToNotification =
              dateTimeMinusThirtyMinutes.diff(now);

            const oldNotification = notificationQueue;
            oldNotification.push(activity);
            setNotificationQueue([]);
            setNotificationQueue(oldNotification);
            setTimeout(() => {
              setNotifications((prevNotifications) => [
                ...prevNotifications,
                activity,
              ]);
              setNotificationQueue((prevNotifications) =>
                prevNotifications.filter(
                  (notification) => notification.id !== activity.id
                )
              );
            }, millisecondsToNotification);
          }
        });
      }
    };

    setInterval(() => {
      fetchActivities();
    }, ONE_MINUTE);
  }, [userIdState]);

  useEffect(() => {
    const timer = setInterval(() => {
      const result = getToken();
      if (result !== undefined) {
        clearInterval(timer);
        const user = getTokenAccountInformation();
        if (user !== undefined) {
          setUserId(user.id);
        }
      }
    }, ONE_MINUTE / 2);
  }, []);

  return {
    notifications,
    setNotifications,
  };
}
