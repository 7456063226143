import React from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import Button from '../Button/button';
import IconDictionary from '../Icons/icons';
import Modal from '../Modal/Modal';
import { alertGalaxAccount } from '../../hooks/globalAtom';

const AlertCreateGalax: React.FC = () => {
  const [show, setShow] = useAtom(alertGalaxAccount);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Conta do banco não encontrada"
      minHeight="min-h-[200px]"
      size="medium"
    >
      <div className="mb-5 px-5 py-8 flex items-center gap-3 max-w-full h-10 border border-dashed border-primary">
        <IconDictionary
          size={24}
          name="AlertTriangle"
          className="text-primary min-w-5"
        />
        <p className="text-xs">
          Para utilizar essa funcionalidade é necessário ter uma conta no banco
          ativa e aprovada
        </p>
      </div>

      <Link to="/bank-account" onClick={() => setShow(false)}>
        <Button actionType="button-full-width">Criar Conta</Button>
      </Link>
    </Modal>
  );
};

export default AlertCreateGalax;
