import { baseApiPrivate } from '../../../../api/baseApi';

async function getTotalFinancialProvided(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalFinancialProvided/${companyId}/${period}`,
  });
}

async function getTotalFinancialByPlaAccount(
  companyId: number,
  period: string,
  type: 'R' | 'D'
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/financialByPlaAccount/${companyId}/${type}/${period}`,
  });
}

async function getTotalFinancialByStatus(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/financialByStatus/${companyId}/${period}`,
  });
}

async function getTotalReceived(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalFinancialReceived/${companyId}/${period}`,
  });
}

async function getTotalExpenseProvided(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalFinancialExpenseProvided/${companyId}/${period}`,
  });
}

async function getTotalExpenseReceived(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalFinancialExpenseReceived/${companyId}/${period}`,
  });
}

async function getFinancialBillingExpense(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/financialBillingExpense/${companyId}/${period}`,
  });
}

async function getFinancialResultCash(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/financialResultCash/${companyId}/${period}`,
  });
}

async function getTopClients(companyId: number, period: string) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/financialTopClients/${companyId}/${period}`,
  });
}

export const ApiDashboard = {
  getFinancialBillingExpense,
  getFinancialResultCash,
  getTopClients,
  getTotalFinancialByPlaAccount,
  getTotalExpenseProvided,
  getTotalExpenseReceived,
  getTotalFinancialProvided,
  getTotalFinancialByStatus,
  getTotalReceived,
};
