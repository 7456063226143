import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Card, Modal } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

import { useWppContext } from '../../context';

export const Warn: React.FC = () => {
  const navigate = useNavigate();
  const { whatsappPlans, modal } = useWppContext();

  const onPayment = () => {
    window.open(whatsappPlans.financial[0].payment_link, '_blank');
  };

  return (
    <Modal
      title=""
      header={false}
      isOpen={modal.show === 'warn'}
      setIsOpen={() => {}}
      size="medium"
      minHeight="min-h-[50px]"
    >
      <Card className="flex justify-center items-center flex-col w-full pt-5 pb-3.5 gap-4">
        <IconDictionary
          color="#E63B2E"
          name="ExclamationTriangleFill"
          size={50}
        />
        <strong>Aguardando Pagamento</strong>
        <p className="text-sm">
          Estamos aguardando a confirmacao do pagamento. <br />
          Assim que for realizado será desbloqueada a criação de novas
          instâncias.
        </p>
        <div className="flex gap-2 mt-2">
          <Button
            actionType="button-loading"
            variant="outline-primary"
            className="max-w-[200px]"
            onClick={() => navigate('/')}
          >
            Sair
          </Button>
          <Button
            actionType="button-loading"
            className="max-w-[200px] bg-primary text-white"
            onClick={onPayment}
          >
            Gerar link de pagamento
          </Button>
        </div>
      </Card>
    </Modal>
  );
};
