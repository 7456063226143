import { useEffect, useState } from 'react';
import { ApiContact } from '../api';
import {
  AnnotationProps,
  contactActivityProps,
  payloadBackContact,
} from '../types';

export function useFetchContact(
  key: string,
  skip: number,
  take: number,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackContact>({ data: [], total: 0 });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value.length >= 3) {
        const contact = await ApiContact.searchContacts(skip, take, value);
        if (typeof contact !== 'string') {
          setData(contact);
        }
      } else {
        setData({ data: [], total: 0 });
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, skip, take, value]);
  return { ContactList: data, isLoadingContacts: isLoading };
}

export function useFetchTableContact(
  key: string,
  companyId: number,
  skip: number,
  take: number,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackContact>({ data: [], total: 0 });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value === '') {
        const contact = await ApiContact.getContactByCompany(
          companyId,
          skip,
          take
        );
        if (typeof contact !== 'string') {
          setData(contact);
        }
      } else if (value.length >= 3) {
        const contact = await ApiContact.searchContacts(skip, take, value);
        if (typeof contact !== 'string') {
          setData(contact);
        }
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, skip, take, value]);
  return { ContactList: data, isLoading };
}

export function useFetchTableContactFilters(
  key: string,
  companyId: number,
  skip: number,
  take: number,
  value: string,
  tags: { label: string; value: number; tag_color: string }[],
  campaignId: { label: string; value: number }[],
  restFilters: any,
  filterDate: string,
  funnelId?: number | null,
  funnelStepId?: number | null
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackContact>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value.length > 3) {
        const contact = await ApiContact.getContactByCompanyFilter(
          {
            skip,
            take,
            companie_id: companyId,
            tags: tags.map((item: any) => item.value),
            campaign_id: campaignId.map((item: any) => item.value),
            key: value,
            is_whatsapp: true,
            birthday_month: restFilters.birthday_month,
            person_debtor: restFilters.person_debtor,
            qty_payed: restFilters.qty_payed,
            value_payed: restFilters.value_payed,
            average_ticket: restFilters.average_ticket,
            last_purchase: restFilters.last_purchase,
            funnel_step_id: funnelStepId,
            funnel_id: funnelId,
          },
          filterDate
        );
        if (typeof contact !== 'string') {
          setData(contact);
        }
      } else {
        const contact = await ApiContact.getContactByCompanyFilter(
          {
            skip,
            take,
            companie_id: companyId,
            tags: tags.map((item: any) => item.value),
            campaign_id: campaignId.map((item: any) => item.value),
            is_whatsapp: true,
            birthday_month: restFilters.birthday_month,
            person_debtor: restFilters.person_debtor,
            qty_payed: restFilters.qty_payed,
            value_payed: restFilters.value_payed,
            average_ticket: restFilters.average_ticket,
            last_purchase: restFilters.last_purchase,
            funnel_step_id: funnelStepId,
            funnel_id: funnelId,
          },
          filterDate
        );
        if (typeof contact !== 'string') {
          setData(contact);
        }
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, skip, take]);
  return { ContactList: data, isLoading };
}

export function useFetchAnnotations(key: string, contact: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AnnotationProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const annotation = await ApiContact.getAnnotationsByContact(contact);
      if (typeof annotation !== 'string') {
        setData(annotation);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AnnotationList: data, isLoading };
}

export function useFetchContactActivities(key: string, person: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<contactActivityProps[]>([]);
  useEffect(() => {
    const FethActivity = async () => {
      setIsLoading(true);
      const activities = await ApiContact.getActivitiesByPerson(person);
      if (typeof activities !== 'string') {
        setData(activities);
      }
      setIsLoading(false);
    };

    FethActivity();
  }, [key]);
  return { ActivitiesList: data, isLoading };
}

export function useFetchContactTags(
  key: string,
  person: number,
  initialValue?: any[]
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>(initialValue ?? []);
  useEffect(() => {
    const FethActivity = async () => {
      setIsLoading(true);
      const Tags = await ApiContact.getTagContact(person);
      if (typeof Tags !== 'string') {
        setData(Tags);
      }
      setIsLoading(false);
    };
    if (key !== '') FethActivity();
  }, [key]);
  return { TagsList: data, isLoadingList: isLoading };
}
