class Phone {
  isValid(cellphone: string): boolean {
    const cellphoneParse = cellphone.replace(/\D/g, '');

    if (cellphoneParse === '') {
      return false;
    }

    if (cellphoneParse.length < 11) return false;

    switch (cellphone.replace(/\D/g, '')) {
      case '11111111111':
      case '22222222222':
      case '33333333333':
      case '44444444444':
      case '55555555555':
      case '66666666666':
      case '77777777777':
      case '88888888888':
      case '99999999999':
        return false;
      default:
        return true;
    }
  }
}

export default new Phone();
