import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { getSignatureCreditBalance } from './api';

const DigitalSignatureCreditContext = createContext({
  signatureCreditBalance: 0,
  loadingSignatureCreditBalance: false,
  loadSignatureCreditBalance: () => {},
});

export const useDigitalSignaturedCreditContext = () =>
  useContext(DigitalSignatureCreditContext);

const DigitalSignatureCreditContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const [signatureCreditBalance, setSignatureCreditBalance] = useState(0);
  const [loadingSignatureCreditBalance, setLoadingSignatureCreditBalance] =
    useState(false);

  const loadSignatureCreditBalance = useCallback(() => {
    setLoadingSignatureCreditBalance(true);
    getSignatureCreditBalance()
      .then(
        ({
          digital_signature_credit_balance:
            digitalSignatureCreditBalanceResponse,
        }) => {
          setSignatureCreditBalance(digitalSignatureCreditBalanceResponse);
        }
      )
      .finally(() => setLoadingSignatureCreditBalance(false));
  }, []);
  useEffect(() => {
    loadSignatureCreditBalance();
  }, [loadSignatureCreditBalance]);

  const contextProviderValue = useMemo(
    () => ({
      signatureCreditBalance,
      loadingSignatureCreditBalance,
      loadSignatureCreditBalance,
    }),
    [
      signatureCreditBalance,
      loadingSignatureCreditBalance,
      loadSignatureCreditBalance,
    ]
  );

  return (
    <DigitalSignatureCreditContext.Provider value={contextProviderValue}>
      {children}
    </DigitalSignatureCreditContext.Provider>
  );
};
export default DigitalSignatureCreditContextProvider;
