import { baseApiPrivate } from '../../../api/baseApi';

async function updateCompanyTheme(body: FormData) {
  return baseApiPrivate({
    body,
    method: 'POST',
    url: `admin/companie/custom`,
    file: true,
  });
}

async function getCompanyTheme(companieId: number | undefined) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/custom/${companieId}`,
  });
}

async function getBlobFromUrl(url: string) {
  return baseApiPrivate({
    method: 'GET',
    url,
    file: true,
    responseFile: true,
  });
}

function getDefaultThemes() {
  const themes = [
    {
      name: 'Padrão',
      color_hex: '#712770',
    },
    {
      name: 'Roxo vivo',
      color_hex: '#8338ec',
    },
    {
      name: 'Vermelho',
      color_hex: '#E63B2E',
    },
    {
      name: 'Preto',
      color_hex: '#000000',
    },
    {
      name: 'Cinza claro',
      color_hex: '#33415c',
    },
    {
      name: 'Laranja claro',
      color_hex: '#ff6700',
    },

    {
      name: 'Azul escuro',
      color_hex: '#18206f',
    },
    {
      name: 'Verde claro',
      color_hex: '#00916e',
    },
    {
      name: 'Vermelho sangue',
      color_hex: '#370617',
    },
    {
      name: 'Rosa claro',
      color_hex: '#f72585',
    },
    {
      name: 'Verde escuro',
      color_hex: '#132a13',
    },
    {
      name: 'Marrom escuro',
      color_hex: '#583101',
    },

    {
      name: 'Preto médio',
      color_hex: '#121113',
    },

    {
      name: 'Roxo escuro',
      color_hex: '#240046',
    },
  ];
  return themes;
}

export const ApiTheme = {
  updateCompanyTheme,
  getCompanyTheme,
  getBlobFromUrl,
  getDefaultThemes,
};
