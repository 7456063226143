import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

// import { Container } from './styles';
interface toastProps {
  message: string;
  type: 'Info' | 'Warning' | 'Success' | 'Error';
}
export const NotificationToast: React.FC = () => (
  <ToastContainer
    position="top-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  />
);

export const notify = ({ message, type }: toastProps) => {
  switch (type) {
    case 'Info':
      toast.info(message, {
        className: 'text-sm',
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      break;
    case 'Success':
      toast.success(message, {
        className: 'text-sm',
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      break;
    case 'Warning':
      toast.warning(message, {
        className: 'text-sm',
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      break;
    case 'Error':
      toast.error(message, {
        className: 'text-sm',
        position: 'top-center',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      break;
  }
};

export const ToastNotify = () => ({ notify, NotificationToast });

export type notifyProps = toastProps;
