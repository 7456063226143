import { useEffect, useState } from 'react';
import { ApiWhatsappTag } from '../api';
import { WhatsappTagProps } from '../types';

export function useFetchWhatsappTag(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<WhatsappTagProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res = await ApiWhatsappTag.getWhatsappTagByCompany();
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { WhatsappTagList: data, isLoading };
}
