import React, { useState } from 'react';
import maskHelper from '../../../helpers/mask.helper';
import { Modal, Button, Input } from '../../../components';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (e: boolean) => void;
  message: string;
  clientWhatsapp: string;
}

const SendWhatsApp: React.FC<props> = ({
  setOpen,
  show,
  message,
  clientWhatsapp,
}) => {
  const [values, setValues] = useState({
    DDI: '55',
    number: clientWhatsapp,
    message,
  });

  return (
    <Modal
      title="Enviar WhatsApp"
      setIsOpen={setOpen}
      size="medium"
      isOpen={show}
      minHeight="300px"
    >
      <p className="text-xs mb-3">
        Você será direcionado para o WhatApp Web/Desktop, certifique-se que o
        número de telefone contenha DDI e DDD.
      </p>
      <div className="grid grid-cols-4 gap-3 w-full">
        <Input
          variant="outline-orange"
          title="DDI"
          value={maskHelper.ddi(values.DDI)}
          onChange={(e) =>
            setValues((prev) => ({
              ...prev,
              DDI: e.target.value,
            }))
          }
        />
        <Input
          variant="outline-orange"
          title="Número"
          classNameDiv="col-span-3"
          value={maskHelper.phone(values.number)}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, number: e.target.value }))
          }
        />
      </div>
      <p className="text-sm font-normal">Mensagem</p>
      <textarea
        className="text-xs border-[#ddd] focus:border-primary border border-solid w-full h-24 rounded-lg p-3 resize-none mt-2"
        title="Mensagem"
        value={values.message}
        onChange={(e) =>
          setValues((prev) => ({ ...prev, message: e.target.value }))
        }
      />
      <p className="text-xs text-gray-600 mb-3">
        Para quebra de linha na mensagem do WhatsApp insira este código: %0A
      </p>
      <a
        aria-disabled={values.number.length === 0}
        rel="noreferrer"
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${
          values.DDI
        }${maskHelper.numberPhone(values.number)}&text=${values.message}`}
      >
        <Button
          disabled={values.number.length === 0}
          actionType="button-full-width"
        >
          Enviar
        </Button>
      </a>
    </Modal>
  );
};

export default SendWhatsApp;
