import React from 'react';
import { Button, Modal } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  openCharge: () => void;
}

const AlertOutDateFinContract: React.FC<props> = ({
  openCharge,
  setShow,
  show,
}) => (
  <Modal
    minHeight="min-h-[150px]"
    isOpen={show}
    setIsOpen={setShow}
    title="Alterar Vencimento"
  >
    <div className="px-3 py-5 flex items-center gap-3 w-full h-10 border border-dashed border-primary">
      <IconDictionary size={24} name="AlertTriangle" className="text-yellow" />
      <p className="text-sm">Contrato possui parcelas vencidas</p>
    </div>
    <p className="text-xs my-5">
      Para alterar a data de pagamento é necessário regularizar as parcelas
      vencidas do contrato
    </p>
    <div className="w-full flex gap-3">
      <Button
        actionType="button-loading"
        onClick={() => setShow(false)}
        variant="outline-primary"
      >
        Voltar
      </Button>
      <Button actionType="button-loading" onClick={openCharge}>
        Regularizar
      </Button>
    </div>
  </Modal>
);

export default AlertOutDateFinContract;
