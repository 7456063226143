import React, { useEffect, useState } from 'react';
import SelectWhatsappTag from '../../../../whatsappTag/components/select';
import IconDictionary from '../../../../../components/Icons/icons';
import { ApiContact } from '../../../api';
import { formPropsPage } from '../../../types';
import { useFetchContactTags } from '../../../hooks';
import { Button, DeleteModal, Table } from '../../../../../components';
import { WhatsappTagProps } from '../../../../whatsappTag/types';

const Tags: React.FC<formPropsPage> = ({
  id,
  defaultValue,
  setContact,
  nextPage,
  notify,
}) => {
  const [key, setKey] = useState('');
  const { TagsList, isLoadingList } = useFetchContactTags(
    key,
    id,
    defaultValue?.person_tag
  );
  const idsTagsSelected = TagsList.map((item) => item.tag_id);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentTag, setcurrentTag] = useState<WhatsappTagProps | null>(null);

  const addTag = async (tagId: number) => {
    setIsLoading(true);
    const res = await ApiContact.addTagContact({
      person_id: id,
      tag_id: tagId,
    });
    if (res.id) {
      setKey(String(Math.random()));
    }
    setIsLoading(false);
  };

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: any) => row.tag.id,
    },
    {
      name: 'Nome',
      key: 'title',
      selector: (row: any) => row.tag.title,
    },
    {
      name: '',

      cell: (row: any) => (
        <IconDictionary
          name="Excluir"
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setcurrentTag(row);
            setShowDelete(true);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (defaultValue) setContact({ ...defaultValue, person_tag: TagsList });
  }, [TagsList]);

  return (
    <div className="mt-8">
      <SelectWhatsappTag
        itemsSelectedId={idsTagsSelected}
        onChange={(e) => addTag(e.value)}
      />
      <Table
        scrollTable
        fixedHeaderScrollHeight="300px"
        isLoading={isLoading || isLoadingList}
        columns={columns}
        data={TagsList}
        selectedRowId={-1}
      />
      <Button
        actionType="button-loading"
        className="mx-auto mt-8"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => nextPage()}
      >
        Salvar
      </Button>
      {currentTag && (
        <DeleteModal
          apiFunction={ApiContact.removeTagContact}
          type="Tag"
          article="A"
          notify={notify}
          updateTable={() => setKey(`${Math.random()} delete`)}
          close={() => setShowDelete(false)}
          id={currentTag.id}
          name={currentTag.title}
          open={showDelete}
        />
      )}
    </div>
  );
};

export default Tags;
