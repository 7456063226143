import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Input,
  SelectComponent,
  TextArea,
} from '../../../../../components';
import { ToastNotify } from '../../../../../components/Toast/toast';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import { CampaignProps, OpportunityProps } from '../../../types';
import { ApiOpportunity } from '../../../api';
import { states } from '../../../../../helpers/address.helper';

/* eslint-disable no-unused-vars */
interface props {
  defaultValue: OpportunityProps;
  updateOpportunity: React.Dispatch<
    React.SetStateAction<OpportunityProps | null>
  >;
}

const CampainData: React.FC<props> = ({ defaultValue, updateOpportunity }) => {
  const defaultValues: CampaignProps = {
    campaign_city: defaultValue.campaign_city,
    campaign_investment: defaultValue.campaign_investment,
    campaign_message: defaultValue.campaign_message,
    campaign_project: defaultValue.campaign_project,
    campaign_utm_medium: defaultValue.campaign_utm_medium,
    campaign_state: defaultValue.campaign_state,
    campaign_utm_term: defaultValue.campaign_utm_term,
    campaign_utm_content: defaultValue.campaign_utm_content,
    campaign_utm_source: defaultValue.campaign_utm_source,
    campaign_version: defaultValue.campaign_version,
    campaign_utm_campaign: defaultValue.campaign_utm_campaign,
  };

  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const [isLoading, setIsLoading] = useState(false);

  const { notify } = ToastNotify();

  const [editCampaign, setEditCampaign] = useState(defaultValues);

  const { handleSubmit, setValue, formState } = useForm<CampaignProps>({
    defaultValues: clearNullValuesObject(defaultValues),
  });

  const Submit = async (e: CampaignProps) => {
    setIsLoading(true);
    const response = await ApiOpportunity.updateOpportunityCampaign(
      {
        ...e,
      },
      defaultValue.id
    );
    if (!response.id) {
      notify({ message: dictionaryError(response), type: 'Error' });
    } else {
      notify({
        message: 'Dados atualizados com sucesso!',
        type: 'Success',
      });

      setEditCampaign({
        campaign_city: response.campaign_city,
        campaign_investment: response.campaign_investment,
        campaign_message: response.campaign_message,
        campaign_project: response.campaign_project,
        campaign_utm_medium: response.campaign_utm_medium,
        campaign_state: response.campaign_state,
        campaign_utm_term: response.campaign_utm_term,
        campaign_utm_content: response.campaign_utm_content,
        campaign_utm_source: response.campaign_utm_source,
        campaign_version: response.campaign_version,
      });

      updateOpportunity({
        ...defaultValue,
        campaign_city: response.campaign_city,
        campaign_investment: response.campaign_investment,
        campaign_message: response.campaign_message,
        campaign_project: response.campaign_project,
        campaign_utm_medium: response.campaign_utm_medium,
        campaign_state: response.campaign_state,
        campaign_utm_term: response.campaign_utm_term,
        campaign_utm_content: response.campaign_utm_content,
        campaign_utm_source: response.campaign_utm_source,
        campaign_version: response.campaign_version,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setEditCampaign(defaultValues);
  }, []);

  return (
    <form
      onSubmit={handleSubmit(Submit)}
      className="flex flex-col sm:grid grid-cols-2 p-4 gap-3"
    >
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="Projeto"
        value={editCampaign.campaign_project}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_project: e.target.value,
          });
          setValue('campaign_project', e.target.value);
        }}
        errorMensage={formState.errors.campaign_project?.message}
      />
      <SelectComponent
        isDisabled={isLoading}
        title="Estado"
        options={states}
        closeMenuOnSelect
        value={states.find(
          (element) => element.value === editCampaign.campaign_state
        )}
        onChange={(e: any) => {
          setValue('campaign_state', e.value);
        }}
        errorMensage={formState.errors.campaign_state?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="Cidade"
        value={editCampaign.campaign_city}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_city: e.target.value,
          });
          setValue('campaign_city', e.target.value);
        }}
        errorMensage={formState.errors.campaign_city?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="Investimento"
        value={editCampaign.campaign_investment}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_investment: e.target.value,
          });
          setValue('campaign_investment', e.target.value);
        }}
        errorMensage={formState.errors.campaign_investment?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="UTM Source"
        value={editCampaign.campaign_utm_source}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_utm_source: e.target.value,
          });
          setValue('campaign_utm_source', e.target.value);
        }}
        errorMensage={formState.errors.campaign_utm_source?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="UTM Medium"
        value={editCampaign.campaign_utm_medium}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_utm_medium: e.target.value,
          });
          setValue('campaign_utm_medium', e.target.value);
        }}
        errorMensage={formState.errors.campaign_utm_medium?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="UTM Campaign"
        value={editCampaign.campaign_utm_campaign}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_utm_campaign: e.target.value,
          });
          setValue('campaign_utm_campaign', e.target.value);
        }}
        errorMensage={formState.errors.campaign_utm_campaign?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="UTM Term"
        value={editCampaign.campaign_utm_term}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_utm_term: e.target.value,
          });
          setValue('campaign_utm_term', e.target.value);
        }}
        errorMensage={formState.errors.campaign_utm_term?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="UTM Content"
        value={editCampaign.campaign_utm_content}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_utm_content: e.target.value,
          });
          setValue('campaign_utm_content', e.target.value);
        }}
        errorMensage={formState.errors.campaign_utm_content?.message}
      />
      <Input
        disabled={isLoading}
        variant="outline-orange"
        title="Versão"
        value={editCampaign.campaign_version}
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_version: e.target.value,
          });
          setValue('campaign_version', e.target.value);
        }}
        errorMensage={formState.errors.campaign_version?.message}
      />

      <TextArea
        disabled={isLoading}
        title="Mensagem"
        value={editCampaign.campaign_message}
        classNamediv="col-span-2"
        onChange={(e) => {
          setEditCampaign({
            ...editCampaign,
            campaign_message: e.target.value,
          });
          setValue('campaign_message', e.target.value);
        }}
        errorMensage={formState.errors.campaign_message?.message}
      />

      <Button
        type="submit"
        actionType="button-loading"
        className="mx-auto mt-3 col-span-2"
        variant="primary-strong"
        disabled={isLoading}
      >
        Salvar
      </Button>
    </form>
  );
};

export default CampainData;
