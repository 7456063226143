import React from 'react';
import { Modal, Table } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import { attachment } from '../../../clients/types';
import { downloadFile } from '../../../../helpers/utils.helper';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  obs: null | string | undefined;
  path: string | null | undefined;
}

const ModalInfos: React.FC<props> = ({ setShow, show, obs, path }) => {
  const columnsAttachment = [
    {
      maxWidth: '45px',
      cell: (row: attachment) => (
        <div className="flex items-center justify-center w-full">
          <button
            type="button"
            onClick={() => {
              if (path)
                downloadFile(
                  row.path.split('/').pop() ?? 'arquivo_etapa',
                  row.path
                );
            }}
          >
            <IconDictionary
              name="Download"
              size={20}
              className="cursor-pointer"
            />
          </button>
        </div>
      ),
    },
    {
      name: 'Nome',
      key: 'path',
      minWidth: '65%',
      selector: (row: attachment) =>
        row.path ? row.path.split('/').pop() : '',
    },
  ];
  return (
    <Modal
      minHeight="min-h-[300px]"
      size="2xlarge"
      title="Informações da Etapa"
      isOpen={show}
      setIsOpen={setShow}
    >
      {obs && (
        <>
          <p className="text-sm mb-1.5">Observação</p>
          <div className="max-h-[250px] overflow-y-auto border border-dashed border-gray-400 rounded-md p-3 mb-4">
            <p className="text-justify text-xs">{obs}</p>
          </div>
        </>
      )}
      {path && (
        <>
          <p className="relative z-10 text-sm -mb-6">Arquivo</p>
          <Table
            selectedRowId={-1}
            columns={columnsAttachment}
            data={[{ path }]}
            isLoading={false}
          />
        </>
      )}
      {!obs && !path && (
        <div className="w-full flex">
          <p className="mt-5 mx-auto text-xs">Nenhuma informação encontrada</p>
        </div>
      )}
    </Modal>
  );
};

export default ModalInfos;
