// companie/opportunity/20

import { baseApiPrivate } from '../../../api/baseApi';
import { OpportunityProps } from '../../opportunity/types';
import { filtersInformationProps, transferCreateProps } from '../types';

async function getAllOpportunityByCompanie(
  id: number
): Promise<OpportunityProps[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/opportunity/${id}`,
  });
}

async function filterOpportunities(
  companyId: number,
  period: string,
  filters: filtersInformationProps
): Promise<{ total: number; data: OpportunityProps[] } | string> {
  return baseApiPrivate({
    method: 'POST',
    body: filters,
    url: `companie/transferOpportunity/filter/${companyId}/${period}`,
  });
}

async function transferOpportunity(
  information: transferCreateProps
): Promise<any | string> {
  return baseApiPrivate({
    method: 'POST',
    body: information,
    url: `companie/transferOpportunity`,
  });
}

export const ApiTransferOpportunity = {
  getAllOpportunityByCompanie,
  filterOpportunities,
  transferOpportunity,
};
