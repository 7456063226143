import { baseApiPrivate } from '../../../api/baseApi';
import { ActivitieType } from '../types';

async function getActivitieTypeByCompany(companyId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/activitie/type/${companyId}`,
  });
}

async function createActivitieType(e: Omit<ActivitieType, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/activitie/type',
    body: e,
  });
}

async function updateActivitieType(e: Omit<ActivitieType, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/activitie/type/${id}`,
    body: e,
  });
}

async function deleteActivitieType(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/activitie/type/${id}`,
  });
}

export const ApiActivitieType = {
  getActivitieTypeByCompany,
  createActivitieType,
  updateActivitieType,
  deleteActivitieType,
};
