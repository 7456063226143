/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal } from '../../../../components';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  isLoading: boolean;
  confirm: () => void;
  type: 'R' | 'D';
  id?: number;
}

const ModalConfirm: React.FC<props> = ({
  setShow,
  confirm,
  show,
  isLoading,
  type,
  id,
}) => (
  <Modal
    isOpen={show}
    setIsOpen={setShow}
    title="Confirmar"
    minHeight="min-h-[100px]"
  >
    <p>
      Deseja realmente {id ? 'atualizar' : 'criar'} a{' '}
      {type === 'D' ? 'Despesa' : 'Receita'}?
    </p>
    <div className="flex gap-3 mx-auto mt-5">
      <Button
        disabled={isLoading}
        className="w-56"
        variant="outline-primary"
        onClick={() => setShow(false)}
      >
        Sair
      </Button>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        className="w-56"
        actionType="button-loading"
        variant="primary-strong"
        onClick={confirm}
      >
        Confirmar
      </Button>
    </div>
  </Modal>
);

export default ModalConfirm;
