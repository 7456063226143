import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { Button, Card, DeleteModal } from '../../../../components';
import { Menu, MenuFullProps } from '../types';
import { ApiMenu } from '../api';
import { useFetchMenus } from '../hooks';
import RegisterMenu from '../components/register';
import AssociationMenuModal from '../components/associationMenu';
import { useFetchModule } from '../../module/hooks';

const Menus: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterMenu, setModalRegisterMenu] = useState(false);
  const [modalReleaseMenu, setModalReleaseMenu] = useState(false);
  const [input, setInput] = useState('');
  const [groupData, setMenuData] = useState<MenuFullProps | null>(null);
  const [key, setKey] = useState('key');

  const { MenuList, isLoadingMenus } = useFetchMenus(key);
  const { ModuleList } = useFetchModule('');
  const moduleOptions = ModuleList.map((item) => ({
    label: item.title,
    value: item.id,
  }));
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: MenuFullProps) => row.title,
    },
    {
      name: 'Rota',
      key: 'route_frontend',
      selector: (row: MenuFullProps) => row.route_frontend,
    },
    {
      name: 'Módulo',
      key: 'route_frontend',
      selector: (row: MenuFullProps) => row.group?.module.title,
    },
    {
      name: 'Grupo',
      key: 'route_frontend',
      selector: (row: MenuFullProps) => row.group?.title,
    },
    {
      name: '',
      cell: (row: MenuFullProps) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setMenuData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return MenuList;
    }
    return MenuList.filter(
      (item) =>
        item.title.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.route_frontend.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingMenus}
        column={columns}
        title="Menus"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterMenu}
        setRegisterPage={() => {
          setMenuData(null);
        }}
        onRowClick={(e: Menu) => {
          setMenuData(e);
          setModalRegisterMenu(true);
        }}
        CustomCard={
          <Card className="w-full pb-3.5 pt-5 xl:w-2/4 flex flex-col items-center justify-center gap-4 ">
            <strong>Liberar Menu</strong>
            <Button
              className="w-40 h-11"
              onClick={() => setModalReleaseMenu(true)}
            >
              Liberar
            </Button>
          </Card>
        }
      />
      <RegisterMenu
        moduleOption={moduleOptions}
        menu={groupData}
        reloadTable={setKey}
        setShow={setModalRegisterMenu}
        show={modalRegisterMenu}
      />
      <AssociationMenuModal
        reloadTable={setKey}
        setShow={setModalReleaseMenu}
        show={modalReleaseMenu}
      />

      {groupData && (
        <DeleteModal
          apiFunction={ApiMenu.deleteMenu}
          notify={notify}
          type="Menu"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={groupData.id}
          name={groupData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Menus;
