export const transformMessageOptions = (messageConfigs: any) =>
  messageConfigs.map((config: any) => ({
    label: config?.text || 'No message',
    value: config?.id || null,
  }));

export const defaultDays: { [key: number]: string } = {
  1: '1 dia',
  2: '2 dias',
  3: '3 dias',
};

export const optionDays = [
  { label: '1 dia', value: 1 },
  { label: '2 dias', value: 2 },
  { label: '3 dias', value: 3 },
];

export const absenceOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
  { label: '60', value: 60 },
  { label: '90', value: 90 },
  { label: '120', value: 120 },
  { label: '180', value: 180 },
];

export const quantityOfPurchases = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '6', value: 6 },
  { label: '9', value: 9 },
  { label: '10', value: 12 },
];

export const periodOptions = [
  { value: 'today', label: 'Hoje' },
  { value: 'yesterday', label: 'Ontem' },
  { value: 'this_week', label: 'Esta semana' },
  { value: 'last_week', label: 'Semana passada' },
  { value: 'this_month', label: 'Este mês' },
  { value: 'last_30_days', label: 'Últimos 30 dias' },
];
