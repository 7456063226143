import { useEffect, useState } from 'react';
import { ApiCompanyGalaxPay } from '../../admin/GalaxPay/SubAccounts/api';
import { notify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

export function useFetchCompanieById(
  companieId: number,
  key: string,
  // eslint-disable-next-line no-unused-vars
  setActionType: (e: 'PF' | 'PJ' | '') => void
) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiCompanyGalaxPay.getSubAccountsById(companieId);
      if (typeof companies !== 'string') {
        if (companies.Professional) {
          setActionType('PF');
        } else {
          setActionType('PJ');
        }
        setData(companies);
        setError('');
      } else {
        setActionType('');
        notify({ message: dictionaryError(companies), type: 'Error' });
        setError(
          'Não foi possível buscar a empresa. Por favor, tente mais tarde.'
        );
      }
      setIsLoading(false);
    };
    if (companieId) FetchCompany();
  }, [companieId, key]);
  return {
    GalaxAccount: data,
    isLoading,
    setGalaxAccount: setData,
    messError: error,
  };
}
