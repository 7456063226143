import React, { useContext, useLayoutEffect, useState } from 'react';
import { lighten } from 'polished';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Input,
  TextArea,
  isLoadingApplication,
} from '../../../components';
import { OpportunityFormProps } from '../../opportunity/types';
import maskHelper from '../../../helpers/mask.helper';
import Header from '../../login/components/header';
import Menu from '../../login/components/menu';
import {
  dictionaryError,
  verifyCompanyId,
} from '../../../helpers/utils.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import { ApiInputAutomation } from '../api';
import phoneValidate from '../../../helpers/validations/phone.validate';
import { setItem } from '../../../helpers/storage.helper';

interface formProps {
  companyId: number;
  title: string;
}
function Form({ companyId, title }: formProps) {
  const { notify } = ToastNotify();
  const schema = yup.object().shape(
    {
      name: yup.string().required('Nome é obrigatório'),
      email: yup
        .string()
        .email('E-mail inválido')
        .when('whatsapp', {
          is: (whatsapp: any) => !whatsapp || whatsapp.length === 0,
          then: yup.string().required(''),
          otherwise: yup.string(),
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !email || email.length === 0,
        then: yup
          .string()
          .required('É obrigatório preencher E-mail ou WhatsApp')
          .test({
            message: 'Número inválido',
            test: (value) =>
              value !== undefined
                ? phoneValidate.isValid(maskHelper.number(value))
                : true,
          }),
        otherwise: yup.string().test({
          message: 'Número inválido',
          test: (value) =>
            value !== undefined && value !== ''
              ? phoneValidate.isValid(maskHelper.number(value))
              : true,
        }),
      }),
    },
    [['email', 'whatsapp']]
  );

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState } = useForm<OpportunityFormProps>({
    resolver: yupResolver(schema),
    defaultValues: { obs: '' },
  });

  const Submit = async (e: OpportunityFormProps) => {
    setIsLoading(true);
    const res = await ApiInputAutomation.createForm({
      name: e.name,
      email: e.email,
      whatsapp: e.whatsapp,
      date_expectation: null,
      obs: e.obs,
      companie_id: companyId,
    });
    if (res.opportunity_id || res.id) {
      notify({ message: 'Formulário enviado com sucesso!', type: 'Success' });
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col lg:flex-row gap-8 relative z-2 items-center sm:h-fit xl:h-screen 2xl:h-screen justify-evenly justify-center px-3.5 xl:px-16 pt-20 lg:mt-10 xl:py-16 2xl:py-0 2xl:mt-20 min-h-screen xl:min-h-fit">
      <h3 className="whitespace-normal break-words max-w-xl text-white font-bold text-4xl text-center lg:text-left md:text-6xl">
        {title}
      </h3>
      <div className="flex flex-col bg-white w-full sm:w-4/5 lg:w-2/5 xl:w-2/5 rounded-[30px] px-[38px] py-8 lg:max-w-md min-h-[480px]">
        <p className="text-2xl font-semibold">Seja bem vindo(a)!</p>
        <p className="font-normal text-sm mb-8 lg:mb-2 text-matterhorn">
          Informe os dados abaixo
        </p>
        <form
          className="text-sm mt-2 2xl:mt-6 gap-2 2xl:gap-3 flex flex-col"
          onSubmit={handleSubmit(Submit)}
        >
          <Input
            title="Nome"
            type="text"
            id="name"
            placeholder="Seu nome"
            onChange={(e) => setValue('name', e.target.value)}
            errorMensage={formState.errors.name?.message}
          />
          <Input
            title="E-mail"
            placeholder="email@exemplo.com"
            type="text"
            id="email"
            onChange={(e) => setValue('email', e.target.value)}
            errorMensage={formState.errors.email?.message}
          />

          <Input
            id="whatsapp"
            title="Whatsapp"
            placeholder="(00) 00000-0000"
            onChange={(e) => {
              e.target.value = maskHelper.phone(e.target.value);
              setValue('whatsapp', maskHelper.number(e.target.value));
            }}
            errorMensage={formState.errors.whatsapp?.message}
          />

          <TextArea
            variant="black"
            title="Informações adicionais"
            onChange={(e) => setValue('obs', e.target.value)}
          />

          <Button
            variant="transparent"
            className="max-w-full mt-2 2xl:mt-5 mx-auto bg-primary hover:bg-primary/70 text-white"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            actionType="button-loading"
          >
            Enviar
          </Button>
        </form>
      </div>
    </div>
  );
}

const OpportunityForm: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const [layout, setLayout] = useState({
    layout_background_image: '',
    layout_logomarca: '',
    layout_text: '',
  });
  const { companyId } = useParams();
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const getLayoutConfig = async () => {
    const res = await ApiInputAutomation.getLayoutConfig(Number(companyId));
    if (typeof res === 'string') {
      navigate('/invalid-link');
    } else {
      setLayout({
        layout_background_image: res[0].layout_background_image,
        layout_logomarca: res[0].layout_logomarca,
        layout_text: res[0].layout_text,
      });
    }
  };

  useLayoutEffect(() => {
    const verify = async () => {
      setState(true);
      const res = await verifyCompanyId(Number(companyId));
      if (res.color_hex) {
        setItem('color-hex', res.color_hex);
        const root = document.documentElement;
        root.style.setProperty('--primary', res.color_hex);
        root.style.setProperty('--secondary', lighten(0.1, res.color_hex));
        await getLayoutConfig();
        setState(false);
      } else if (res === 'Error: error_settings_type_form_not_exist') {
        navigate('/missing-configuration');
        setState(false);
      } else {
        navigate('/invalid-link');
        setState(false);
      }
    };

    verify();
  }, []);

  return (
    <>
      <div className="containerScreen flex flex-col h-fit overflow-hidden">
        <div className="main w-full min-h-fit h-fit xl:min-h-screen pb-6 bg-primary">
          {!active ? (
            <Header
              active={active}
              setActive={setActive}
              companyLogo={`${process.env.REACT_APP_API}/${layout.layout_logomarca}`}
            />
          ) : (
            <Menu
              setNotActive={setActive}
              companyLogo={`${process.env.REACT_APP_API}/${layout.layout_logomarca}`}
            />
          )}
          <img
            src={`${process.env.REACT_APP_API}/${layout.layout_background_image}`}
            alt="backgroundImage"
            className="h-full w-full absolute top-0 z-0 xl:hidden"
          />
          <img
            src={`${process.env.REACT_APP_API}/${layout.layout_background_image}`}
            alt="backgroundImage"
            className="hidden xl:flex w-full absolute top-0 z-0 sm:min-h-[60vh]"
          />

          <Form companyId={Number(companyId)} title={layout.layout_text} />
        </div>
      </div>
      <div className="h-32 py-2 sm:py-0 sm:h-[80px] 2xl:h-[100px] bg-primary relative z-2">
        <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row px-10 items-center justify-between h-fit py-2 bg-primary">
          <h3 className="text-normal text-white text-sm">
            Todos os direitos reservados.
          </h3>
          <img
            src={`${process.env.REACT_APP_API}/${layout.layout_logomarca}`}
            alt="white-logo"
            className="justify-self-center h-20"
          />
          <h3 className="justify-self-end text-white text-sm font-base">
            © 2023-{new Date().getFullYear()} Laks Tecnologia
          </h3>
        </div>
      </div>
    </>
  );
};

export default OpportunityForm;
