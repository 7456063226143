import { atom } from 'jotai';
import { useEffect } from 'react';
import { getItem } from '../helpers/storage.helper';
import { getTokenAccountInformation } from '../helpers/token.helper';
import { logiParams } from '../modules/login/api';
import { saveParamLocal } from '../modules/login/utils';

export const reloadNotification = atom(false);
export const reloadAccess = atom('');
export const reloadMenu = atom('');
export const cookiesAccepted = atom(Boolean(getItem('cookies')));
export const reloadConnection = atom(false);
export const instancesNumber = atom(0);
export const alertGalaxAccount = atom(false);

export function CompanieUpdate() {
  const TEN_MINUTES = 600000;

  useEffect(() => {
    const fetchCompanie = async (email: string) => {
      const login = await logiParams({
        email,
        password: 'IHthn4KY4jELxt9JPjOL',
      });
      if (login.token) {
        saveParamLocal(login);
      }
    };

    const interval = setInterval(() => {
      const token = getTokenAccountInformation();
      if (token && token.email && token.companie) fetchCompanie(token.email);
    }, TEN_MINUTES);

    return () => clearInterval(interval);
  }, []);
}
