import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal } from '../../../../components';
import { Profile } from '../types';
import { ApiProfile } from '../api';
import { useFetchProfile } from '../hooks';
import RegisterProfile from '../components/register';

const Profiles: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterProfile, setModalRegisterProfile] = useState(false);
  const [input, setInput] = useState('');
  const [profileData, setProfileData] = useState<Profile | null>(null);
  const [key, setKey] = useState('key');

  const { ProfileList, isLoadingProfile } = useFetchProfile(key);
  const { notify } = ToastNotify();

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Profile) => row.title,
    },
    {
      name: 'Perfil Admin',
      key: 'is_admin',
      selector: (row: Profile) => row.is_admin,
      cell: (row: Profile) => (row.is_admin === true ? 'Sim' : 'Não'),
    },
    {
      name: 'Perfil Franqueador',
      key: 'is_franchisor',
      selector: (row: Profile) => row.is_franchisor,
      cell: (row: Profile) => (row.is_franchisor === true ? 'Sim' : 'Não'),
    },
    {
      name: 'Perfil Franquia',
      key: 'is_franchise',
      selector: (row: Profile) => row.is_franchise,
      cell: (row: Profile) => (row.is_franchise === true ? 'Sim' : 'Não'),
    },
    {
      name: '',
      cell: (row: Profile) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setProfileData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return ProfileList;
    }
    return ProfileList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingProfile}
        column={columns}
        title="Perfis"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterProfile}
        setRegisterPage={() => {
          setProfileData(null);
        }}
        onRowClick={(e: Profile) => {
          setProfileData(e);
          setModalRegisterProfile(true);
        }}
      />
      <RegisterProfile
        profile={profileData}
        reloadTable={setKey}
        setShow={setModalRegisterProfile}
        show={modalRegisterProfile}
      />
      {profileData && (
        <DeleteModal
          apiFunction={ApiProfile.deleteProfile}
          notify={notify}
          type="Perfil"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={profileData.id}
          name={profileData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Profiles;
