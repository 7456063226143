import { ApiCompany } from '../../admin/company/api';

export const formatStatusVerification = (status?: string) => {
  switch (status) {
    case 'approved':
      return { status: 'Aprovada', color: 'text-green' };
    case 'denied':
      return { status: 'Negado', color: 'red' };
    case 'pending':
      return { status: 'Pendente', color: 'text-yellow' };
    case 'analyzing':
      return {
        status: 'Documentos estão sendo analizados',
        color: 'text-gray-600',
      };
    case 'empty':
      return { status: 'Documentos não informados', color: 'text-black' };
    default:
      return { status: '-', color: 'text-black' };
  }
};

export const associateAccount = async (id: number, companieId: number) =>
  await ApiCompany.updatePaymentAccount(
    {
      companie_id_galax: id,
      companie_id_maistodos: null,
      is_galaxpay: true,
      is_maistodos: false,
    },
    companieId
  );
