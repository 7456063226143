import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

import { useFetchAccounts, useFetchCompanie } from './hooks';
import { ApiParameters } from './api';
import { AdminProps } from './types';

import maskHelper from '../../../helpers/mask.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  data: AdminProps | null;
}

const schema = yup.object().shape({
  value_subscription: yup.string().required('O valor não pode ser zero'),
  plan_account_id_digital_signature_plan: yup
    .string()
    .required('Campo é Obrigatório'),
  plan_account_id_whatsapp_plan: yup.string().required('Campo é Obrigatório'),
  account_split_galax_id: yup.number().required('Campo é Obrigatório'),
});

const ModalEdit: React.FC<registerModalProps> = ({
  data,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();

  const [values, setValues] = useState<Omit<any, 'id'>>({
    plan_account_id_digital_signature_plan:
      data?.plan_account_id_digital_signature_plan ?? 0,
    plan_account_id_whatsapp_plan: data?.plan_account_id_whatsapp_plan ?? 0,
    value_subscription: data?.value_subscription ?? '',
    companie_id: data?.companie_id,
    is_laks: data?.is_laks ?? false,
    account_split_galax_id: data?.account_split_galax_id,
    plan_account_id_limit_user_plan: data?.plan_account_id_limit_user_plan,
  });

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      value_subscription: data?.value_subscription,
      plan_account_id_whatsapp_plan: data?.plan_account_id_whatsapp_plan,
      plan_account_id_digital_signature_plan:
        data?.plan_account_id_digital_signature_plan,
      is_laks: data?.is_laks ?? false,
      account_split_galax_id: data?.account_split_galax_id,
      plan_account_id_limit_user_plan: data?.plan_account_id_limit_user_plan,
    },
  });

  const { CompanieList } = useFetchCompanie();
  const companieList = CompanieList.map((elem) => ({
    label: elem.name,
    value: elem.id,
  }));

  const { AccountsList } = useFetchAccounts(
    values.companie_id || data?.companie_id
  );
  const accountOptions = AccountsList.filter(
    (item) => item.num_plan.split('.').length > 1
  ).map((elem) => ({
    label: `${elem.num_plan} - ${elem.title}`,
    value: elem.id ?? 0,
    isdisabled: elem.num_plan.split('.').length < 3,
  }));

  const isLaksOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    const res = await ApiParameters.put(values, data!.id);

    if (res.id) {
      setIsLoading(false);
      notify({ message: 'Parâmetro atualizado com sucesso', type: 'Success' });
      reloadTable(`${Math.random()} update`);
      setShow(false);
    } else {
      setIsLoading(false);
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  useEffect(() => {
    setValues({
      plan_account_id_digital_signature_plan:
        data?.plan_account_id_digital_signature_plan ?? 0,
      plan_account_id_whatsapp_plan: data?.plan_account_id_whatsapp_plan ?? 0,
      value_subscription: data?.value_subscription ?? '',
      companie_id: data?.companie_id ?? 0,
      is_laks: data?.is_laks ?? false,
      account_split_galax_id: data?.account_split_galax_id,
      plan_account_id_limit_user_plan: data?.plan_account_id_limit_user_plan,
    });
  }, [data, show]);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Atualizar Parâmetro"
      size="medium"
      minHeight="min-h-[350px]"
    >
      <form
        className="flex flex-col justify-center gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <SelectComponent
          title="Empresa que receberá os Lançamentos"
          value={companieList.find((item) => item.value === values.companie_id)}
          options={companieList}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValues({ ...values, companie_id: e.value });
          }}
        />
        <SelectComponent
          title="Conta de lançamento - Whatsapp"
          value={
            accountOptions.find(
              (item) => item.value === values.plan_account_id_whatsapp_plan
            ) || null
          }
          options={accountOptions}
          hasDivision
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('plan_account_id_whatsapp_plan', e.value);
            setValues({ ...values, plan_account_id_whatsapp_plan: e.value });
          }}
          errorMensage={formState.errors.plan_account_id_whatsapp_plan?.message}
        />
        <SelectComponent
          title="Conta de lançamento - Assinatura Digital"
          value={
            accountOptions.find(
              (item) =>
                item.value === values.plan_account_id_digital_signature_plan
            ) || null
          }
          options={accountOptions}
          closeMenuOnSelect
          hasDivision
          onChange={(e: any) => {
            setValue('plan_account_id_digital_signature_plan', e.value);
            setValues({
              ...values,
              plan_account_id_digital_signature_plan: e.value,
            });
          }}
          errorMensage={
            formState.errors.plan_account_id_digital_signature_plan?.message
          }
        />
        <Input
          title="Valor de assinatura da plataforma"
          variant="outline-orange"
          value={maskHelper.formatMoeda(values.value_subscription)}
          onChange={(e) => {
            setValue('value_subscription', Number(e.target.value));

            e.target.value = maskHelper.formatCurrencyInput(e.target.value);
            setValues({
              ...values,
              value_subscription: Number(
                maskHelper.numberCurrencyDecimal(e.target.value)
              ),
            });
          }}
          errorMensage={formState.errors.value_subscription?.message}
        />
        <SelectComponent
          title="Laks"
          value={
            isLaksOptions.find((item) => item.value === values.is_laks) || null
          }
          options={isLaksOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setValue('is_laks', e.value);
            setValues({
              ...values,
              is_laks: e.value,
            });
          }}
          errorMensage={formState.errors.is_laks?.message}
        />
        <Input
          title="Conta do Split"
          variant="outline-orange"
          value={values.account_split_galax_id}
          onChange={(e) => {
            setValue('account_split_galax_id', Number(e.target.value));
            setValues({
              ...values,
              account_split_galax_id: Number(e.target.value),
            });
          }}
          errorMensage={formState.errors.account_split_galax_id?.message}
        />
        <Input
          title="Conta de lançamento - Limite de Usuários"
          variant="outline-orange"
          value={values.plan_account_id_limit_user_plan}
          onChange={(e) => {
            setValue('plan_account_id_limit_user_plan', Number(e.target.value));
            setValues({
              ...values,
              plan_account_id_limit_user_plan: Number(e.target.value),
            });
          }}
          errorMensage={
            formState.errors.plan_account_id_limit_user_plan?.message
          }
        />
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
          variant="primary-strong"
          className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        >
          Atualizar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default ModalEdit;
