import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Input, SelectComponent } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { Profile } from '../../../../admin/profile/types';

import { ApiUsers } from '../../../api';
import { formPropsPage, generalData } from '../../../types';

interface generalProps extends formPropsPage {
  ProfileList: Profile[];
}

const GeneralData: React.FC<generalProps> = ({
  nextPage,
  defaultValue,
  id,
  notify,
  setId,
  setUser,
  ProfileList,
  companyId: companyUserId,
}) => {
  const defaultUserValues: generalData = {
    companie_id: id,
    document: defaultValue?.document ?? null,
    email: defaultValue?.email ?? '',
    name: defaultValue?.name ?? '',
    whatsapp: defaultValue?.whatsapp,
    gender: defaultValue?.gender ?? null,
    profile_id: defaultValue?.profile_id ?? -1,
    is_active: defaultValue?.is_active ?? true,
  };

  const category = [
    { value: 'Feminino', label: 'Feminino' },
    { value: 'Masculino', label: 'Masculino' },
  ];

  const status = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ];

  const profile = ProfileList.filter((item) => item.is_admin === false).map(
    (item) => ({ value: item.id, label: item.title })
  );

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    profile_id: yup.string().required('Perfil é obrigatório'),
    is_active: yup.bool().required('Status é obrigatório'),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<generalData>(defaultUserValues);
  const { handleSubmit, setValue, formState } = useForm<generalData>({
    resolver: yupResolver(schema),
    defaultValues: {
      document: defaultValue?.document ?? null,
      email: defaultValue?.email,
      name: defaultValue?.name,
      whatsapp: defaultValue?.whatsapp,
      gender: defaultValue?.gender ?? null,
      profile_id: defaultValue?.profile_id,
      is_active: defaultValue?.is_active ?? true,
    },
  });
  const Submit = async (values: generalData) => {
    const valuesToSubmit = {
      ...values,
      profile_id: Number(values.profile_id),
      gender: values.gender === '' ? null : values.gender,
    };
    if (defaultValue !== null) {
      setIsLoading(true);
      const res = await ApiUsers.updateGeneralData(valuesToSubmit, id);
      if (res.id) {
        setUser(res);
        setIsLoading(false);
        notify({ message: 'Conta atualizada com sucesso!', type: 'Success' });
        nextPage(res.id);
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiUsers.createCompanyGeneralData({
        ...valuesToSubmit,
        companie_id: companyUserId,
      });
      if (response.id) {
        setUser(response);
        setId(response.id);
        nextPage(response.id);
        notify({ message: 'Dados gerais salvos com sucesso', type: 'Success' });
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };
  return (
    <form
      className="grid grid-cols-2 gap-3 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <Input
        variant="outline-orange"
        title="Nome"
        value={defaultValues.name}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, name: e.target.value });
          setValue('name', e.target.value);
        }}
        errorMensage={formState.errors.name?.message}
      />
      <Input
        title="CPF"
        variant="outline-orange"
        type="string"
        placeholder="000.000.000-00"
        value={maskHelper.cpf(defaultValues.document ?? '')}
        onChange={(e) => {
          const value = maskHelper.cpf(e.target.value.trim());
          setDefaultValues({ ...defaultValues, document: value || null });
          setValue('document', maskHelper.number(value) || null);
        }}
        errorMensage={formState.errors.document?.message}
      />
      <Input
        title="WhatsApp"
        variant="outline-orange"
        placeholder="(00) 00000-0000"
        value={maskHelper.phone(defaultValues.whatsapp ?? '')}
        onChange={(e) => {
          e.target.value = maskHelper.phone(e.target.value);
          setDefaultValues({ ...defaultValues, whatsapp: e.target.value });
          setValue('whatsapp', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.whatsapp?.message}
      />
      <Input
        title="E-mail"
        variant="outline-orange"
        value={defaultValues.email}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, email: e.target.value });
          setValue('email', e.target.value);
        }}
        errorMensage={formState.errors.email?.message}
      />
      <SelectComponent
        title="Sexo"
        closeMenuOnSelect
        value={category.find((item) => item.value === defaultValues.gender)}
        options={category}
        onChange={(e: any) => {
          setValue('gender', e.value);
          setDefaultValues({ ...defaultValues, gender: e.value });
        }}
        errorMensage={formState.errors.gender?.message}
      />
      <div className="grid grid-cols-2 gap-3">
        <SelectComponent
          title="Perfil"
          closeMenuOnSelect
          options={profile}
          value={profile.find(
            (item) => item.value === defaultValues.profile_id
          )}
          onChange={(e: any) => {
            setValue('profile_id', e.value);
            setDefaultValues({ ...defaultValues, profile_id: e.value });
          }}
          errorMensage={formState.errors.profile_id?.message}
          className="mb-0.5"
        />
        <SelectComponent
          title="Status"
          closeMenuOnSelect
          options={status}
          value={status.find((item) => item.value === defaultValues.is_active)}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              is_active: e.value,
            });
            setValue('is_active', e.value);
          }}
          errorMensage={formState.errors.is_active?.message}
        />
      </div>

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default GeneralData;
