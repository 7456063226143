import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  DeleteModal,
  Input,
  Modal,
  SelectComponent,
  TextArea,
  Table,
} from '../../../../../components';
import InputDate from '../../../../../components/Input/inputDate';
import maskHelper from '../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { reloadNotification } from '../../../../../hooks/globalAtom';
import { ApiActivitie } from '../../../../activitie/api';
import { useFetchActivitieType } from '../../../../activitieType/hooks';

import { useFetchOpportunityDropdown } from '../../../../opportunity/hooks';
import { ApiContact } from '../../../api';
import { useFetchContactActivities } from '../../../hooks';
import { contactActivityProps, createActivityContact } from '../../../types';

/* eslint-disable no-unused-vars */
interface createProps {
  allOptionsOpportunity: { value: number; label: string }[];
  activityOptions: { value: number; label: string }[];
  setShowModalCreateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  showModalCreateUpdate: boolean;
  currentActivity: null | contactActivityProps;
  personId: number;
  companieUserId: number;
  companieId: number;
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
  reloadTable: (e: string) => void;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
}

function CreateUpdateActivity({
  companieId,
  activityOptions,
  companieUserId,
  currentActivity,
  notify,
  personId,
  setShowModalCreateUpdate,
  showModalCreateUpdate,
  allOptionsOpportunity,
  isLoadingDropdown,
  setInputOpportunity,
  reloadTable,
}: createProps) {
  const defaultActivity: createActivityContact = {
    companie_user_id: companieUserId,
    date: currentActivity?.date.slice(0, 10) ?? '',
    description: currentActivity?.description ?? '',
    hour: currentActivity?.hour ?? '',
    opportunity_id:
      currentActivity &&
      currentActivity.opportunity &&
      currentActivity.opportunity_id !== null
        ? {
            value: currentActivity.opportunity_id,
            label: currentActivity.opportunity.title,
          }
        : {
            value: -1,
            label: 'Sem vínculo com oportunidade',
          },
    person_id: personId,
    activitie_id: currentActivity?.activitie_id ?? -1,
  };

  const schema = yup.object().shape({
    activitie_id: yup.number().required('Selecione uma opção'),
    hour: yup.string().required('Horário é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
    date: yup.string().required('Data é obrigatória'),
  });

  const [notificationState, reloadNotificationContainer] =
    useAtom(reloadNotification);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<createActivityContact>(defaultActivity);

  const { handleSubmit, setValue, formState, reset, control } =
    useForm<createActivityContact>({
      resolver: yupResolver(schema),
      defaultValues: {
        opportunity_id:
          currentActivity &&
          currentActivity.opportunity &&
          currentActivity.opportunity_id !== null
            ? {
                value: currentActivity.opportunity_id,
                label: currentActivity.opportunity.title,
              }
            : {
                value: null,
                label: 'Sem vínculo com oportunidade',
              },
        activitie_id: currentActivity?.activitie_id,
        date: currentActivity
          ? maskHelper.formatDateYMD(currentActivity.date)
          : moment().format('YYYY-MM-DD'),
        description: currentActivity?.description,
        hour: currentActivity?.hour,
      },
    });

  const Submit = async (values: createActivityContact) => {
    if (currentActivity !== null) {
      setIsLoading(true);
      const res = await ApiActivitie.updateActivitie(
        {
          date: maskHelper.formatDateYMD(values.date),
          description: values.description,
          hour: values.hour,
          companie_user_id_owner: companieUserId,
          activitie_id: values.activitie_id,
          opportunity_id: values.opportunity_id?.value,
          person_id: personId,
        },
        currentActivity.id
      );
      if (res.id) {
        reloadNotificationContainer(!notificationState);
        setIsLoading(false);
        notify({
          message: 'Atividate atualizada com sucesso!',
          type: 'Success',
        });
        reloadTable(`${Math.random()} update`);
        setShowModalCreateUpdate(false);
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiActivitie.createActivitie({
        ...values,
        companie_user_id_owner: companieUserId,
        opportunity_id: values.opportunity_id?.value,
        companie_user_id: companieUserId,
        person_id: personId,
      });
      if (response.id) {
        reloadNotificationContainer(!notificationState);
        notify({ message: 'Tarefa salva com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShowModalCreateUpdate(false);
        setIsLoading(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setDefaultValues(defaultActivity);
    reset();
  }, [showModalCreateUpdate]);

  return (
    <Modal
      size="4xlarge"
      minHeight="min-h-[500px]"
      title={currentActivity ? 'Atualizar Tarefa' : 'Criar Tarefa'}
      setIsOpen={setShowModalCreateUpdate}
      isOpen={showModalCreateUpdate}
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3 mt-8"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="opportunity_id"
          defaultValue={defaultValues.opportunity_id}
          render={({ field }) => (
            <SelectComponent
              defaultValue={allOptionsOpportunity[0]}
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              isClearable
              autoComplete
              closeMenuOnSelect
              value={field.value}
              options={allOptionsOpportunity}
              title="Oportunidade"
              onChange={(e: any) => {
                setDefaultValues({ ...defaultValues, opportunity_id: e.value });
                setValue('opportunity_id', e);
              }}
            />
          )}
        />
        <SelectComponent
          closeMenuOnSelect
          value={activityOptions.find(
            (activity) => activity.value === defaultValues.activitie_id
          )}
          options={activityOptions}
          title="Tarefa"
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, activitie_id: e.value });
            setValue('activitie_id', e.value);
          }}
          errorMensage={formState.errors.activitie_id?.message}
        />

        <InputDate
          defaultValue={
            defaultValues.date.length
              ? new Date(`${defaultValues.date} `)
              : new Date()
          }
          onChange={(e: any) => {
            if (e !== null) {
              setDefaultValues({
                ...defaultValues,
                date: maskHelper.formatDateYMD(e.toLocaleDateString('pt-br')),
              });
              setValue(
                'date',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
            }
          }}
          title="Data"
          errorMensage={formState.errors.date?.message}
        />

        <Input
          title="Horário"
          type="time"
          variant="outline-orange"
          value={defaultValues.hour}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, hour: e.target.value });
            setValue('hour', e.target.value);
          }}
          errorMensage={formState.errors.hour?.message}
        />
        <TextArea
          variant="orange"
          title="Descrição"
          value={defaultValues.description}
          onChange={(e) => {
            setDefaultValues({ ...defaultValues, description: e.target.value });
            setValue('description', e.target.value);
          }}
          errorMensage={formState.errors.description?.message}
          classNamediv="col-span-2"
        />

        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Modal>
  );
}

interface ActivityProps {
  personId: number;
  companieUserId: number;
  companieId: number;
  nextPage: () => void;
  // eslint-disable-next-line no-unused-vars
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
}
const Activity: React.FC<ActivityProps> = ({
  companieUserId,
  notify,
  personId,
  companieId,
  nextPage,
}) => {
  const { ActivitieTypeList } = useFetchActivitieType('key', companieId);
  const activityOptions = ActivitieTypeList.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [currentActivity, setCurrentActivity] =
    useState<contactActivityProps | null>(null);
  const [inputOpportunity, setInputOpportunity] = useState('');

  const { OpportunityList, isLoadingOpportunityList } =
    useFetchOpportunityDropdown(inputOpportunity);

  const opportunityOptions = OpportunityList.data.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const allOptionsOpportunity = [
    {
      value: -1,
      label: 'Sem vínculo com oportunidade',
    },
  ].concat(opportunityOptions);
  const [showCreateUpdate, setShowCreateUpdate] = useState(false);
  const [key, setKey] = useState('key');
  const { ActivitiesList, isLoading } = useFetchContactActivities(
    key,
    personId
  );

  const columns = [
    {
      name: 'Descrição',
      key: 'description',
      selector: (row: contactActivityProps) => row.description,
    },
    {
      name: 'Data',
      key: 'date',
      selector: (row: contactActivityProps) => row.date,
      cell: (row: contactActivityProps) =>
        maskHelper.formatDateDMY(String(row.date)),
    },
    {
      name: 'Horário',
      key: 'hour',
      selector: (row: contactActivityProps) => row.hour,
    },
    {
      name: 'Tipo da Tarefa',
      key: 'activitie_type.title',
      selector: (row: contactActivityProps) => row.activitie_type.title,
    },
    {
      name: 'Contato',
      key: 'person.name',
      selector: (row: contactActivityProps) => row.person?.name,
    },
    {
      name: 'Oportunidade',
      key: 'opportunity_id',
      selector: (row: contactActivityProps) => row.opportunity_id,
      cell: (row: contactActivityProps) =>
        row.opportunity
          ? row.opportunity.title
          : 'Sem vínculo com oportunidade',
    },
    {
      name: '',
      key: 'action',
      cell: (row: contactActivityProps) => (
        <CloseOutline
          className="cursor-pointer"
          size={20}
          color="red"
          onClick={() => {
            setCurrentActivity(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col">
      <Button
        variant="primary-strong"
        className="mt-5"
        actionType="button-add"
        onClick={() => {
          setCurrentActivity(null);
          setShowCreateUpdate(true);
        }}
      >
        Adicionar Tarefa
      </Button>
      <Table
        columns={columns}
        data={ActivitiesList}
        isLoading={isLoading}
        onRowClick={(row: contactActivityProps) => {
          setCurrentActivity(row);
          setShowCreateUpdate(true);
        }}
      />
      {showCreateUpdate && (
        <CreateUpdateActivity
          isLoadingDropdown={isLoadingOpportunityList}
          setInputOpportunity={setInputOpportunity}
          activityOptions={activityOptions}
          reloadTable={setKey}
          allOptionsOpportunity={allOptionsOpportunity}
          setShowModalCreateUpdate={setShowCreateUpdate}
          showModalCreateUpdate={showCreateUpdate}
          companieId={companieId}
          notify={notify}
          currentActivity={currentActivity}
          personId={personId}
          companieUserId={companieUserId}
        />
      )}

      {currentActivity && (
        <DeleteModal
          apiFunction={ApiContact.deleteActivity}
          notify={notify}
          type="Tarefa"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={currentActivity.id}
          name={currentActivity.description}
          open={showModalDelete}
        />
      )}
      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="button"
          className="font-bold w-64"
          disabled={isLoading}
          onClick={nextPage}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default Activity;
