import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input, Spinner } from '../../../components';
import { setItem } from '../../../helpers/storage.helper';
import { login } from '../api';
import { credentialsProps } from '../types';
import { saveToken } from '../../../helpers/token.helper';
import { dictionaryError } from '../../../helpers/utils.helper';
import { cookiesAccepted } from '../../../hooks/globalAtom';

interface LoginFormProps {
  // eslint-disable-next-line no-unused-vars
  onApiResponse: (property: any) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onApiResponse }) => {
  const [acceptedCookies] = useAtom(cookiesAccepted);
  const schema = yup.object({
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatória'),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const navigate = useNavigate();
  const { handleSubmit, setValue, formState } = useForm<credentialsProps>({
    resolver: yupResolver(schema),
  });
  const Submit = async (credentials: credentialsProps) => {
    setIsLoading(true);
    const response = await login(credentials);
    if (response.token) {
      saveToken(response.token);
      setItem(
        'menu',
        response.menus.filter((item: any) => item !== null)
      );
      if (response.companie !== undefined)
        setItem(
          'generate_contract_opp',
          response.companie.is_generate_contract_opp
        );
      setIsLoading(false);
      navigate('/');
    } else {
      setErr(dictionaryError(response));
      setIsLoading(false);
    }
    onApiResponse(response);
    setIsLoading(false);
  };
  return (
    <form className="text-sm" onSubmit={handleSubmit(Submit)}>
      <Input
        title="Email"
        placeholder="Email"
        type="text"
        id="email"
        onChange={(e) => setValue('email', e.target.value)}
        errorMensage={formState.errors.email?.message}
        disabled={!acceptedCookies}
      />
      <br />

      <Input
        id="password"
        autoComplete="on"
        title="Password"
        placeholder="Senha"
        type="password"
        onChange={(e) => setValue('password', e.target.value)}
        errorMensage={formState.errors.password?.message}
        disabled={!acceptedCookies}
      />
      <p className="mt-2 text-xs text-red">{err}</p>
      <button
        className="relative flex items-center justify-center w-full text-white hover:bg-secondary disabled:hover:bg-primary bg-primary disabled:opacity-70 h-10 rounded font-semibold text-xs"
        type="submit"
        disabled={isLoading || acceptedCookies === false}
      >
        Entrar
        {isLoading && (
          <div className="absolute right-6">
            <Spinner />
          </div>
        )}
      </button>
    </form>
  );
};

export default LoginForm;
