import React, { ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';
import Spinner from '../Spinner/spinner';
import IconDictionary from '../Icons/icons';

type ButtonProps = ComponentPropsWithoutRef<'button'>;
interface fullButtonProps extends ButtonProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline-primary'
    | 'gray'
    | 'primary-strong'
    | 'transparent';
  actionType?:
    | 'button-loading'
    | 'button-add'
    | 'button-simple'
    | 'button-reconnect'
    | 'button-full-width';
  isLoading?: boolean;
}
const buttonStyles = {
  primary: 'text-white hover:bg-secondary bg-primary disabled:bg-primary/40',
  'primary-strong':
    'text-white hover:bg-secondary bg-primary disabled:bg-primary/30',
  secondary: 'text-white hover:bg-secondary bg-secondary disabled:opacity-75',
  'outline-primary': 'border border-solid border-primary',
  gray: 'bg-gray-300 text-gray-600',
  transparent: 'text-gray-600 hover:bg-primary/20',
};
const Button: React.FC<fullButtonProps> = ({
  className,
  children,
  variant = 'primary',
  actionType = 'button-simple',
  isLoading = false,
  ...props
}) => {
  switch (actionType) {
    case 'button-loading':
      return (
        <button
          type="button"
          className={classnames([
            'w-64 font-bold relative flex items-center justify-center',
            'h-10',
            'rounded',
            'font-semibold',
            'text-xs',
            'disabled:opacity-50',
            buttonStyles[variant],
            className,
          ])}
          {...props}
        >
          {children}
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </button>
      );
    case 'button-add':
      return (
        <button
          type="button"
          className={classnames([
            'flex w-44 gap-2.5 items-center justify-center text-white',
            'h-10',
            'rounded',
            'font-semibold',
            'text-xs',
            'relative',
            buttonStyles[variant],
            className,
          ])}
          {...props}
        >
          <div className="absolute right-5">
            {isLoading === false ? (
              <IconDictionary name="Adicionar" size={18} />
            ) : (
              <Spinner />
            )}
          </div>

          {children}
        </button>
      );
    case 'button-full-width':
      return (
        <button
          type="button"
          className={classnames([
            'w-full',
            'h-10',
            'rounded',
            'font-semibold',
            'text-xs',
            buttonStyles[variant],
            className,
          ])}
          {...props}
        >
          {children}
        </button>
      );
    case 'button-reconnect':
      return (
        <button
          type="button"
          className={classnames([
            'flex w-44 gap-2.5 items-center justify-center',
            'h-10',
            'rounded',
            'font-semibold',
            'text-xs',
            'relative',
            'disabled:opacity-50',
            buttonStyles[variant],
            className,
          ])}
          {...props}
        >
          <div className="absolute right-5">
            {isLoading === false ? (
              <IconDictionary name="PlugConnected" size={18} />
            ) : (
              <Spinner />
            )}
          </div>

          {children}
        </button>
      );
    case 'button-simple':
    default:
      return (
        <button
          type="button"
          className={classnames([
            'h-10',
            'rounded',
            'font-semibold',
            'text-xs',
            buttonStyles[variant],
            className,
          ])}
          {...props}
        >
          {children}
        </button>
      );
  }
};

export default Button;
