import { baseApiPrivate } from '../../../api/baseApi';
import {
  addressData,
  generalData,
  socialMediaData,
  attachment,
  historic,
  createUpdateOlds,
  payloadBackClients,
  ClientProps,
} from '../types';

async function createClientGeneralData(information: generalData) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'companie/client',
  });
}

async function updateGeneralData(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/client/${id}`,
  });
}

async function updateAddress(information: addressData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/client/address/${id}`,
  });
}

async function updateSocialMedia(information: socialMediaData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `companie/client/social/${id}`,
  });
}

async function getClientByCompanie(
  companyId: number,
  skip: number,
  take: number
): Promise<payloadBackClients | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/${companyId}/${skip}/${take}`,
  });
}

async function getClientById(id: number): Promise<ClientProps | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/id/${id}`,
  });
}

async function searchClients(
  key: string,
  skip: number,
  take: number
): Promise<payloadBackClients | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/autocomplete/find?q=${key}&skip=${skip}&take=${take}`,
  });
}

async function deleteClient(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/client/${id}`,
  });
}

function getClientHistoric(id: number): Promise<historic[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/history/${id}`,
  });
}

async function getClientAttachments(id: number): Promise<attachment[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/attachment/${id}`,
  });
}

async function getDropdownClient(companyId: number): Promise<ClientProps[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/client/ammountDropdown/${companyId}`,
  });
}

function createClientAttachments(attachments: any) {
  return baseApiPrivate({
    method: 'POST',
    body: attachments,
    url: `companie/client/attachment`,
    file: true,
  });
}

function deleteAttachments(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/client/attachment/${id}`,
  });
}

function createOlds(sale: createUpdateOlds) {
  return baseApiPrivate({
    method: 'POST',
    body: sale,
    url: `companie/client/purchaseOlds`,
  });
}

function updateOlds(id: number, sale: createUpdateOlds) {
  return baseApiPrivate({
    method: 'PUT',
    body: sale,
    url: `companie/client/purchaseOlds/${id}`,
  });
}

function deleteOlds(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/client/purchaseOlds/${id}`,
  });
}

function getAllProviders(id: number): Promise<any[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/provider/${id}`,
  });
}

function getAllProducts(): Promise<any[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/product`,
  });
}

function getAllHotels() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/provider/category/1`,
  });
}

export const ApiClient = {
  getAllHotels,
  getAllProviders,
  getClientAttachments,
  createClientAttachments,
  deleteAttachments,
  getClientByCompanie,
  getAllProducts,
  getClientHistoric,
  getDropdownClient,
  getClientById,
  seachClients: searchClients,
  createOlds,
  updateOlds,
  deleteOlds,
  createClientGeneralData,
  deleteClient,
  updateAddress,
  updateGeneralData,
  updateSocialMedia,
};
