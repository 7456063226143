import React, { useEffect, useState } from 'react';
import IconDictionary from '../../../../components/Icons/icons';
import { Button, Modal, SelectComponent, Table } from '../../../../components';
import { ApiContract } from '../../api';
import { notify } from '../../../../components/Toast/toast';
import { Props } from '../../types';

const Tags: React.FC<Props> = ({
  tags,
  idDependent,
  reloadTags,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [idDelete, setIdDelete] = useState(-1);

  const handleDelete = async () => {
    const res = await ApiContract.deleteTags(idDelete);
    notify({
      message: res ? 'Tag removida' : 'Desculpe! Ocorreu um Erro',
      type: res ? 'Success' : 'Error',
    });
    setIdDelete(-1);
    reloadTags(`${Math.random()} Delete`);
    setIsOpen(false);
  };

  const handleRegisterTag = async (option: any) => {
    const res = await ApiContract.createTags({
      contract_dependent_id: idDependent,
      tag_id: option.value,
    });
    notify({
      message: res ? 'Tag cadastrada' : 'Desculpe! Ocorreu um Erro',
      type: res ? 'Success' : 'Error',
    });
    reloadTags(`${Math.random()} Create`);
  };

  useEffect(() => {
    const handleGetDefaultTags = async () => {
      try {
        const res = await ApiContract.getWhatsappTagByCompany();

        const formattedOptions = res.map((item: any) => ({
          label: item.title,
          value: item.id,
        }));
        setOptions(formattedOptions);
      } catch (error) {
        console.error('Erro ao buscar as tags: ', error);
      }
    };

    handleGetDefaultTags();
  }, []);

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: any) => row.tag_id,
      minWidth: '100px',
    },
    {
      name: 'Nome',
      key: 'title',
      selector: (row: any) => row.tag.title,
      minWidth: '670px',
      center: true,
    },
    {
      minWidth: '0px',
      name: '',
      cell: (row: any) => (
        <IconDictionary
          name="Excluir"
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setIsOpen(true);
            setIdDelete(row.id);
          }}
        />
      ),
    },
  ];
  return (
    <div className="mt-8">
      <SelectComponent
        options={options}
        onChange={(SelectedOption: any) => handleRegisterTag(SelectedOption)}
      />
      <Table columns={columns} data={tags} isLoading={isLoading} />
      <Modal
        title="Confirmação de Exclusão"
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        minHeight="medium"
      >
        <div>
          <strong className="text-center text-lg">
            Tem certeza que deseja remover a tag?
          </strong>
          <p className="text-sm mt-4 mb-10">
            Esta ação não pode ser desfeita. A tag será removida
            {` `}
            do dependente cadastrado.
          </p>
          <div className="flex gap-3 w-full">
            <Button
              variant="primary-strong"
              onClick={() => setIsOpen(false)}
              className="w-1/2"
            >
              Cancelar
            </Button>
            <Button
              className="w-1/2 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
              variant="outline-primary"
              onClick={handleDelete}
            >
              Remover{' '}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Tags;
