import React from 'react';
import { Button, Table } from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import { BranchCompany } from '../../../types';

interface BranchProps {
  brachCompanies: BranchCompany[];
  nextPage: () => void;
}

const Branch: React.FC<BranchProps> = ({ brachCompanies, nextPage }) => {
  const column = [
    {
      name: 'Nome',
      key: 'name',
      selector: (row: BranchCompany) => row.name,
    },
    {
      name: 'CPF/CNPJ',
      key: 'document',
      selector: (row: BranchCompany) => maskHelper.cnpjCpf(row.document),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: BranchCompany) => row.email,
    },
    {
      name: 'Proprietário',
      key: 'owner',
      selector: (row: BranchCompany) => row.owner,
    },
    {
      name: 'Telefone',
      key: 'phone',
      selector: (row: BranchCompany) => maskHelper.phone(row.phone ?? ''),
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: BranchCompany) => maskHelper.phone(row.whatsapp),
    },
    {
      name: 'Data de criação',
      key: 'created_at',
      selector: (row: BranchCompany) =>
        maskHelper.formatDateDMY(row.created_at),
    },
  ];

  return (
    <div className="flex flex-col mt-8">
      <Table columns={column} data={brachCompanies} isLoading={false} />
      <div className="col-span-2 flex justify-center mt-6">
        <Button onClick={nextPage} className="font-bold w-64">
          Salvar Empresa
        </Button>
      </div>
    </div>
  );
};

export default Branch;
