import classNames from 'classnames';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

type InputProps = ComponentPropsWithoutRef<'input'>;
interface StyleProps {
  icon?: ReactNode;
  inputWithIconSize?: string;
  variant?: 'outline-orange' | 'outline-black' | 'login';
  errorMensage?: string;
  clearField?: ReactNode;
  classNameDiv?: string;
  borderDefault?: string;
}
type Props = InputProps & StyleProps;
const Input: React.FC<Props> = ({
  title,
  className,
  icon,
  inputWithIconSize,
  variant = 'outline-black',
  borderDefault = 'border-primary',
  errorMensage,
  clearField,
  classNameDiv,
  ...props
}) => {
  const { disabled } = props;
  const textStyle = {
    'outline-orange': 'text-sm font-normal',
    'outline-black': 'text-sm font-semibold',
    login: 'text-sm font-bold text-green text-xl',
  };
  const borderColor = {
    'outline-orange': errorMensage ? `${borderDefault}` : 'border-[#ddd]',
    'outline-black': 'border-matterhorn',
    login: `${borderDefault}`,
  };
  const borderRadius = {
    'outline-orange': 'rounded-lg',
    'outline-black': 'rounded',
    login: 'rounded-2xl',
  };
  return icon ? (
    <div className={classNames(['relative', className])}>
      <p className={textStyle[variant]}>{title}</p>
      <div
        className={classNames([
          'flex',
          'items-center',
          'text-xs',
          'border-solid',
          'border',
          'rounded-lg',
          !classNameDiv
            ? errorMensage
              ? `${borderDefault}`
              : 'border-[#ddd]'
            : classNameDiv,
          'w-full',
          'p-3',
          `focus-within:${borderDefault}`,
          'w-full',
          props.disabled && 'bg-gray-200 border-gray-400 text-gray-600',
        ])}
      >
        {icon}
        <input
          autoComplete="on"
          className={classNames([
            'w-[90%]',
            inputWithIconSize,
            'text-xs',
            'ml-2',
            `focus:${borderDefault}`,
            'disabled:opacity-75',
          ])}
          {...props}
        />
        {clearField}
      </div>
      <p className="text-red text-xs absolute">{errorMensage}</p>
    </div>
  ) : (
    <div className={classNames(['relative', 'mb-1.5', classNameDiv])}>
      <p className={textStyle[variant]}>{title}</p>
      <input
        autoComplete="on"
        className={classNames([
          'w-full',
          'text-xs',
          'border-solid',
          'border',
          borderColor[variant],
          borderRadius[variant],
          'p-3',
          'mt-1.5',
          `focus:${borderDefault}`,
          'disabled:bg-gray-200',
          'font-normal',
          variant === 'login' && 'h-[50px] text-black',
          disabled && 'bg-gray-300/50 text-gray-600',
          className,
        ])}
        {...props}
      />
      <p className="text-red text-xs absolute mt-0.5">{errorMensage}</p>
    </div>
  );
};

export default Input;
