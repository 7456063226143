/* eslint-disable no-unused-vars */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { toBase64 } from '../../../../utils';
import IconDictionary from '../../../../../../../../components/Icons/icons';
import { SelectComponent } from '../../../../../../../../components';

interface props {
  frontDoc: any;
  backDoc: any;
  selfieDoc: any;
  residenceDoc: any;
  setFrontDoc: (e: any) => void;
  setBackDoc: (e: any) => void;
  setSelfieDoc: (e: any) => void;
  setResidenceDoc: (e: any) => void;
  handleDocumentTypeChange: (e: any) => void;
  documentType: any;
  optionsDocumentCompanie: any[];
  disabledEdit: boolean;
}
const PersonalUpload: React.FC<props> = ({
  backDoc,
  frontDoc,
  selfieDoc,
  setBackDoc,
  setFrontDoc,
  setSelfieDoc,
  documentType,
  optionsDocumentCompanie,
  residenceDoc,
  setResidenceDoc,
  handleDocumentTypeChange,
  disabledEdit,
}) => {
  const {
    getRootProps: getRootPropsFront,
    getInputProps: getInputPropsFront,
    isDragActive: isDragActiveFront,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    disabled: disabledEdit,
    onDropAccepted: async (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      const base64: string = await toBase64(fileCreateObj[0]);
      setFrontDoc(base64);
    },
  });

  const {
    getRootProps: getRootPropsResidence,
    getInputProps: getInputPropsResidence,
    isDragActive: isDragActiveResidence,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    disabled: disabledEdit,
    onDropAccepted: async (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      const base64: string = await toBase64(fileCreateObj[0]);
      setResidenceDoc(base64);
    },
  });

  const isBase64 = (str: string) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const {
    getRootProps: getRootPropsBack,
    getInputProps: getInputPropsBack,
    isDragActive: isDragActiveBack,
  } = useDropzone({
    disabled: disabledEdit,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    onDropAccepted: async (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      const base64: string = await toBase64(fileCreateObj[0]);
      setBackDoc(base64);
    },
  });

  const {
    getRootProps: getRootPropsSelfie,
    getInputProps: getInputPropsSelfie,
    isDragActive: isDragActiveSelfie,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    disabled: disabledEdit,
    onDropAccepted: async (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      const base64: string = await toBase64(fileCreateObj[0]);
      setSelfieDoc(base64);
    },
  });

  return (
    <>
      <hr className="w-full h-px -mb-1 rounded-md mb-1 bg-primary col-span-4" />
      <strong className="text-sm col-span-4">Documentos Pessoais</strong>
      <div className="col-span-4">
        <SelectComponent
          classNameDiv="w-64"
          title="Tipo do Documento"
          value={documentType}
          options={optionsDocumentCompanie}
          onChange={handleDocumentTypeChange}
        />
      </div>

      <div className="col-span-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 text-center">
        <p className="hidden lg:flex">Frente</p>
        <p className="hidden lg:flex">Verso</p>
        <p className="hidden lg:flex">Selfie Segurando o Documento</p>
        <p className="hidden lg:flex">Comprovante de Endereço</p>
        <div
          id="form-file-upload"
          className="h-40 w-full flex flex-col text-center relative justify-center"
        >
          <input {...getInputPropsFront()} />
          <label
            {...getRootPropsFront()}
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={`h-40 w-full flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer
                ${isDragActiveFront && 'border-primary'}`}
          >
            {!frontDoc &&
              (!isDragActiveFront ? (
                <div className="flex flex-col p-6">
                  <button
                    className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                    type="button"
                  >
                    <IconDictionary
                      name="add-photo"
                      size={28}
                      color="var(--primary)"
                    />
                    <span className="text-sm font-bold text-primary">
                      Clique para Enviar a Frente do Documento
                    </span>
                  </button>
                  <p className="text-xs text-gray-400 font-medium">
                    ou arraste e solte aqui
                  </p>
                </div>
              ) : (
                <p className="text-sm font-bold text-primary">
                  {' '}
                  Solte o arquivo aqui...
                </p>
              ))}
            {frontDoc && (
              <div className="flex flex-col items-center relative justify-center">
                <img
                  src={
                    isBase64(frontDoc)
                      ? `data:image/png;base64,${frontDoc}`
                      : frontDoc
                  }
                  alt="logo"
                  className="h-40 object-center overflow-hidden object-contain"
                  onError={() => setFrontDoc(null)}
                />
              </div>
            )}
          </label>
        </div>

        <div
          id="form-file-upload"
          className="h-40 w-full flex flex-col text-center relative justify-center items-center"
        >
          <input {...getInputPropsBack()} />
          <label
            {...getRootPropsBack()}
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={`h-40 w-full flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer
                ${isDragActiveBack && 'border-primary'}`}
          >
            {!backDoc &&
              (!isDragActiveBack ? (
                <div className="flex flex-col items-center justify-center p-6">
                  <button
                    className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                    type="button"
                  >
                    <IconDictionary
                      name="add-photo"
                      size={28}
                      color="var(--primary)"
                    />
                    <span className="text-sm font-bold text-primary">
                      Clique para Enviar o Verso do Documento
                    </span>
                  </button>
                  <p className="text-xs text-gray-400 font-medium">
                    ou arraste e solte aqui
                  </p>
                </div>
              ) : (
                <p className="text-sm font-bold text-primary">
                  {' '}
                  Solte o arquivo aqui...
                </p>
              ))}
            {backDoc && (
              <div className="flex flex-col items-center relative justify-center">
                <img
                  src={
                    isBase64(backDoc)
                      ? `data:image/png;base64,${backDoc}`
                      : backDoc
                  }
                  alt="logo"
                  className="h-40 object-center overflow-hidden object-contain"
                  onError={() => setBackDoc(null)}
                />
              </div>
            )}
          </label>
        </div>

        <div
          id="form-file-upload"
          className="h-40 w-full flex flex-col text-center relative justify-center items-center"
        >
          <input {...getInputPropsSelfie()} />
          <label
            {...getRootPropsSelfie()}
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={`h-40 w-full flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer
                ${isDragActiveSelfie && 'border-primary'}`}
          >
            {!selfieDoc &&
              (!isDragActiveSelfie ? (
                <div className="flex flex-col items-center justify-center p-6">
                  <button
                    className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                    type="button"
                  >
                    <IconDictionary
                      name="add-photo"
                      size={28}
                      color="var(--primary)"
                    />
                    <span className="text-sm font-bold text-primary">
                      Clique para Enviar a Selfie
                    </span>
                  </button>
                  <p className="text-xs text-gray-400 font-medium">
                    ou arraste e solte aqui
                  </p>
                </div>
              ) : (
                <p className="text-sm font-bold text-primary">
                  {' '}
                  Solte o arquivo aqui...
                </p>
              ))}
            {selfieDoc && (
              <div className="flex flex-col items-center relative justify-center">
                <img
                  src={
                    isBase64(selfieDoc)
                      ? `data:image/png;base64,${selfieDoc}`
                      : selfieDoc
                  }
                  alt="logo"
                  className="h-40 object-center overflow-hidden object-contain"
                  onError={() => setSelfieDoc(null)}
                />
              </div>
            )}
          </label>
        </div>

        <div
          id="form-file-upload"
          className="h-40 w-full flex flex-col text-center relative justify-center items-center"
        >
          <input {...getInputPropsResidence()} />
          <label
            {...getRootPropsResidence()}
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={`h-40 w-full flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer
                ${isDragActiveResidence && 'border-primary'}`}
          >
            {!residenceDoc &&
              (!isDragActiveResidence ? (
                <div className="flex flex-col items-center justify-center p-6">
                  <button
                    className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                    type="button"
                  >
                    <IconDictionary
                      name="add-photo"
                      size={28}
                      color="var(--primary)"
                    />
                    <span className="text-sm font-bold text-primary">
                      Clique para Enviar o Comprovante de Endereço
                    </span>
                  </button>
                  <p className="text-xs text-gray-400 font-medium">
                    ou arraste e solte aqui
                  </p>
                </div>
              ) : (
                <p className="text-sm font-bold text-primary">
                  {' '}
                  Solte o arquivo aqui...
                </p>
              ))}
            {residenceDoc && (
              <div className="flex flex-col items-center relative justify-center">
                <img
                  src={
                    isBase64(residenceDoc)
                      ? `data:image/png;base64,${residenceDoc}`
                      : residenceDoc
                  }
                  alt="logo"
                  className="h-40 object-center overflow-hidden object-contain"
                  onError={() => setResidenceDoc(null)}
                />
              </div>
            )}
          </label>
        </div>
      </div>
    </>
  );
};

export default PersonalUpload;
