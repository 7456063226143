import { baseApiPrivate } from '../../../../api/baseApi';
import { Group } from '../types';

async function getAllGroup() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/group',
  });
}

async function getGroupByModule(moduleId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/group/module/${moduleId}`,
  });
}

async function createGroup(e: Omit<Group, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'admin/group',
    body: e,
  });
}

async function updateGroup(e: Omit<Group, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/group/${id}`,
    body: e,
  });
}

async function deleteGroup(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/group/${id}`,
  });
}

export const ApiGroup = {
  getAllGroup,
  getGroupByModule,
  createGroup,
  updateGroup,
  deleteGroup,
};
