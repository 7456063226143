import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Button, Card, Modal } from '../../../../components';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { importResultsProps } from '../../../inputAutomation/types';
import { ApiContact } from '../../api';
import { notify } from '../../../../components/Toast/toast';
import {
  dictionaryError,
  getContrastColorWithOpacity,
} from '../../../../helpers/utils.helper';
import SelectWhatsappTag from '../../../whatsappTag/components/select';
import IconDictionary from '../../../../components/Icons/icons';

// eslint-disable-next-line no-unused-vars
const CardImportPerson: React.FC<{ reloadTable: (e: string) => void }> = ({
  reloadTable,
}) => {
  const [resultsImport, setResultsImport] = useState<importResultsProps | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<
    { tag_color: string; label: string; value: number }[]
  >([]);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalImport, setModalImport] = useState(false);

  const companieId = getTokenAccountInformation().companie_id;
  const [droppedFile, setDroppedFile] = useState<File | null>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setDroppedFile(fileCreateObj[0]);
    },
  });

  const Submit = async () => {
    setIsLoading(true);
    const formData: any = new FormData();
    formData.append('companie_id', companieId);
    formData.append('file', droppedFile);
    formData.append(
      'tags',
      tags.map((item) => item.value)
    );

    const res = await ApiContact.createImport(formData);
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      setIsLoading(false);
      return;
    }
    setModalConfirm(false);
    setResultsImport(res);
    setIsLoading(false);
    reloadTable(String(Math.random()));
    setDroppedFile(null);
  };

  const close = () => {
    setModalConfirm(false);
    setResultsImport(null);
    setDroppedFile(null);
  };

  const clearImport = () => {
    setResultsImport(null);
    setDroppedFile(null);
    setTags([]);
  };

  return (
    <>
      <Modal
        title="Importar Contatos"
        isOpen={modalImport}
        setIsOpen={() => {
          clearImport();
          setModalImport(false);
        }}
        size="2xlarge"
        minHeight="min-h-[400px]"
      >
        <Modal
          isOpen={modalConfirm}
          setIsOpen={setModalConfirm}
          title="Importar planilha"
          minHeight="min-h-[200px]"
        >
          <p> Deseja realmente iniciar a importação desta planilha ?</p>
          <div className="mt-6 flex w-full justify-center gap-3">
            <Button
              variant="outline-primary"
              className="w-36"
              onClick={close}
              disabled={isLoading}
              actionType="button-loading"
            >
              Não
            </Button>
            <Button
              variant="primary-strong"
              className="w-36"
              onClick={Submit}
              actionType="button-loading"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Sim
            </Button>
          </div>
        </Modal>

        <div className="col-span-2 relative mb-12">
          <SelectWhatsappTag
            createTags
            value={null}
            title="Filtrar por TAGs"
            isMulti
            itemsSelectedId={tags.map((item) => item.value)}
            onChange={(e) => setTags(tags.concat(e))}
          />
          <div className="absolute mt-1 grid grid-cols-12 gap-1 z-10">
            {tags.map(
              (item) =>
                item.tag_color && (
                  <button
                    title={item.label}
                    type="button"
                    onClick={() =>
                      setTags(tags.filter((elem) => elem.value !== item.value))
                    }
                    style={{
                      backgroundColor: `${item.tag_color}`,
                      color: getContrastColorWithOpacity(item.tag_color),
                      border:
                        getContrastColorWithOpacity(item.tag_color) !==
                        '#ffffff'
                          ? `1px solid ${getContrastColorWithOpacity(
                              item.tag_color
                            )}`
                          : '0px',
                    }}
                    className="text-[8px] p-1 rounded-sm truncate max-w-[60px] hover:opacity-70"
                  >
                    {item.label}
                  </button>
                )
            )}
          </div>
        </div>
        <div className="flex w-full gap-1 text-sm mb-1.5">
          Planilha Modelo:
          <a
            href="/files/Contatos_Leadsim.xlsx"
            target="_blank"
            title="Planilha modelo"
          >
            <IconDictionary
              name="Excel"
              color="#00A374"
              size={18}
              className="cursor-pointer"
            />
          </a>
        </div>
        <div
          {...getRootProps()}
          className={classNames(
            'flex items-center justify-center border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background relative h-20',
            {
              'border-[#DC7E19]': isDragActive,
            }
          )}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="font-light text-sm text-primary text-center">
              Solte o arquivo aqui...
            </p>
          ) : (
            <p className="font-light text-sm text-center text-[#555]">
              Arraste e solte aqui ou clique para selecionar um arquivo
            </p>
          )}
        </div>
        {droppedFile && (
          <div className="text-sm mt-3">
            <strong>Arquivo escolhido:</strong>
            <p className="text-xs mt-1">{droppedFile.name}</p>
          </div>
        )}
        {resultsImport !== null && (
          <>
            <div className="text-sm mt-5">
              <strong>Resultado da importação:</strong>
              <p className="ml-4">
                • Total registros:{' '}
                {resultsImport.total_created + resultsImport.errors.total_error}
              </p>
              <p className="ml-4 text-green">
                • Total importado: {resultsImport.total_created}
              </p>
              <p className="ml-4 text-red">
                • Total de erros: {resultsImport.errors.total_error}
              </p>
            </div>
            {resultsImport.errors.descriptions_error.length > 0 && (
              <div className="max-w-full overflow-x-auto">
                <div className="min-w-[400px] grid grid-cols-5 text-sm mt-7 max-h-[250px] overflow-y-auto">
                  <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                    Linha
                  </strong>
                  <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                    Coluna
                  </strong>
                  <strong className="pl-2 pb-1 col-span-3 border-b border-solid border-gray-300">
                    Erro
                  </strong>
                  {resultsImport.errors.descriptions_error.map(
                    (error, index) => (
                      <>
                        <p
                          className={classNames([
                            'pl-2 py-1 text-xs',
                            resultsImport.errors.descriptions_error.length -
                              1 ===
                            index
                              ? 'border-r border-l-0 border-solid border-gray-300'
                              : 'border-b border-r border-solid border-gray-300',
                          ])}
                        >
                          {error.line}
                        </p>
                        <p
                          className={classNames([
                            'pl-2 py-1 text-xs',
                            resultsImport.errors.descriptions_error.length -
                              1 ===
                            index
                              ? 'border-r border-l-0 border-solid border-gray-300'
                              : 'border-b border-r border-solid border-gray-300',
                          ])}
                        >
                          {error.column}
                        </p>
                        <p
                          className={classNames([
                            'pl-2 py-1 text-xs col-span-3',
                            resultsImport.errors.descriptions_error.length -
                              1 ===
                            index
                              ? 'border-l-0 border-solid border-gray-300'
                              : 'border-b border-solid border-gray-300',
                          ])}
                        >
                          {error.error}
                        </p>
                      </>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <div className="flex w-full gap-3 justify-center">
          <Button
            variant="outline-primary"
            className="w-40 h-11 mt-10"
            onClick={clearImport}
            actionType="button-loading"
            disabled={
              droppedFile === null &&
              resultsImport === null &&
              tags.length === 0
            }
          >
            Limpar
          </Button>
          <Button
            className="w-40 h-11 mt-10"
            onClick={() => setModalConfirm(true)}
            actionType="button-loading"
            disabled={droppedFile === null}
          >
            Importar
          </Button>
        </div>
      </Modal>
      <Card className="min-w-fit px-2 flex-col w-full justify-center items-center gap-4 pb-3.5 pt-5">
        <strong className="text-md">Importar Contatos</strong>
        <Button className="w-40 h-11" onClick={() => setModalImport(true)}>
          Importar
        </Button>
      </Card>
    </>
  );
};
export default CardImportPerson;
