/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Button, Modal } from '../../../../components';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  isLoading: boolean;
  reply: (e: boolean) => void;
  type: 'R' | 'D';
  setConfirmReply: (e: boolean) => void;
  confirmReply: boolean;
}

const ModalConfirmReply: React.FC<props> = ({
  setShow,
  reply,
  show,
  isLoading,
  type,
  confirmReply,
  setConfirmReply,
}) => {
  useEffect(() => {
    if (show) setConfirmReply(false);
  }, []);

  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title="Confirmar"
      minHeight="min-h-[100px]"
    >
      <p>Deseja realmente alterar a {type === 'D' ? 'Despesa' : 'Receita'}?</p>
      <div className="flex gap-1 text-xs mt-3">
        <input
          type="checkbox"
          checked={confirmReply}
          onChange={() => setConfirmReply(!confirmReply)}
        />
        <p>Desejo alterar esta e as próximas cobranças</p>
      </div>
      <div className="flex gap-3 mx-auto mt-5">
        <Button
          disabled={isLoading}
          className="w-56"
          variant="outline-primary"
          onClick={() => setShow(false)}
        >
          Sair
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className="w-56"
          actionType="button-loading"
          variant="primary-strong"
          onClick={() => reply(confirmReply)}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmReply;
