import React from 'react';
import { Gears } from '@styled-icons/fa-solid/Gears';

const ComingSoonScreen: React.FC = () => (
  <div className="w-full h-screen flex flex-col items-center justify-center text-gray-600">
    <Gears size={64} />
    <p className="text-lg mt-2">PÁGINA EM CONSTRUÇÃO</p>
  </div>
);

export default ComingSoonScreen;
