import React from 'react';
import { Button, Modal } from '../../../../../components';

interface props {
  show: boolean;
  isLoading: boolean;
  setShow: () => void;
  reopenOpportunity: () => void;
}
const ReopenContractModalAlert: React.FC<props> = ({
  show,
  isLoading,
  reopenOpportunity,
  setShow,
}) => (
  <Modal
    title="Reabrir oportunidade"
    setIsOpen={setShow}
    isOpen={show}
    size="medium"
    minHeight="min-h-[200px]"
  >
    <p className="text-sm">
      Caso reabra esta oportunidade, o contrato vinculado será reaberto. Deseja
      realmente reabrir o contrato?
    </p>
    <div className="flex w-full items-center justify-center gap-3 mt-5">
      <Button className="w-64" variant="outline-primary" onClick={setShow}>
        Não
      </Button>
      <Button
        className="w-64"
        variant="primary-strong"
        actionType="button-loading"
        onClick={reopenOpportunity}
        isLoading={isLoading}
      >
        Sim
      </Button>
    </div>
  </Modal>
);

export default ReopenContractModalAlert;
