import { Provider } from '../context';

import {
  RegisterModal,
  PurchaseModal,
  CancelModal,
  Limit,
  Warn,
  ConnectModal,
} from '../components';

import Main from './screen';
import NegociatePlan from '../components/modal/negociate';

export default function MainZapZap() {
  return (
    <Provider>
      <Main />
      <NegociatePlan />
      <RegisterModal />
      <PurchaseModal />
      <Limit />
      <CancelModal />
      <Warn />
      <ConnectModal />
    </Provider>
  );
}
