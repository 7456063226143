import { useEffect, useState } from 'react';
import { ApiSearchForm } from '../api';
import { ResponseData, SearchFormProps } from '../types';

export function useFetchFormSearch(key: string, active: boolean) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SearchFormProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res = active
        ? await ApiSearchForm.getSearchFormByCompanyActive()
        : await ApiSearchForm.getSearchFormByCompany();
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key, active]);
  return { SearchFormList: data, isLoadingData: isLoading };
}

export function useFetchAnswerByOpportunity(opportunityId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ResponseData[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res = await ApiSearchForm.getAnswersByOpportunity(opportunityId);

      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [opportunityId]);
  return { FormAnswersByOpportunity: data, isLoadingAnswers: isLoading };
}
