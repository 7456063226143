import React, { useContext, useState } from 'react';
import { useFetchFormSearch } from '../hooks';
import {
  Button,
  Card,
  DeleteModal,
  HeaderPages,
  SelectComponent,
  Spinner,
  isLoadingApplication,
} from '../../../components';
import CardForm from '../components/cardForm';
import CreateUpdate from '../components/createUpdate';
import { FormModel, SearchFormProps } from '../types';
import { ApiSearchForm } from '../api';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';

const SearchForm: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const { notify } = ToastNotify();
  const [key, setKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateUpdate, setShowCreateUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [formData, setFormData] = useState<SearchFormProps>();
  const [filterActive, setFilterActive] = useState(false);
  const { SearchFormList, isLoadingData } = useFetchFormSearch(
    key,
    filterActive
  );

  const handleCreate = async () => {
    setIsLoading(true);
    const res = await ApiSearchForm.createSearchForm({
      title: 'Formulário sem título',
      is_active: true,
      questions: [
        {
          is_active: true,
          options: [],
          title: 'Pergunta',
          type_response: 'text',
        },
      ],
    });
    if (!res.id) {
      notify({ message: dictionaryError(res), type: 'Error' });
    } else {
      setFormData({
        ...res,
        title: res.title,
        id: res.id,
        is_active: res.is_active,
        search_form_questions: [
          {
            search_form_questions_options: [],
            search_form_id: res.id,
            is_active: true,
            title: 'Pergunta',
            type_response: 'text',
          },
        ],
      });
      setShowCreateUpdate(true);
    }
    setIsLoading(false);
  };

  const updateStatus = async (status: boolean, form: SearchFormProps) => {
    const res = await ApiSearchForm.updateSearchFormStatus(status, form.id);
    if (!res.id) {
      notify({ message: dictionaryError(res), type: 'Error' });
    } else {
      notify({ message: 'Status alterado com sucesso!', type: 'Success' });
    }
    setShowCreateUpdate(false);
    setUpdatingStatus(false);
  };

  const saveForm = async (form: FormModel, id: number) => {
    setState(true);
    const res = await ApiSearchForm.updateSearchForm(
      {
        is_active: form.is_active,
        title: form.title,
        questions: form.questions.map((item) => ({
          is_active: true,
          options: item.options.map((elem) => ({
            title: elem.title,
            is_active: true,
          })),
          title: item.title,
          type_response: item.type_response,
        })),
      },
      id
    );
    if (!res.id) {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setState(false);
  };

  const handleDelete = (form: SearchFormProps) => {
    setFormData(form);
    setShowDelete(true);
  };

  return (
    <div className="w-full h-full flex flex-col pb-8">
      <HeaderPages title="Formulário de Pesquisa" />
      {(showCreateUpdate === false || showDelete || updatingStatus) && (
        <>
          <div className="flex flex-col sm:flex-row justify-between items-center mt-10 mb-3">
            <Button
              className="flex w-full sm:w-44 gap-2.5 items-center justify-center text-white"
              variant="primary-strong"
              actionType="button-add"
              onClick={handleCreate}
              disabled={isLoading || isLoadingData}
              isLoading={isLoading}
            >
              Formulário
            </Button>

            <SelectComponent
              className="min-w-full mt-3 sm:w-48"
              classNameDiv="w-full sm:w-fit"
              variant="outline-primary"
              icon="Filtro"
              defaultValue={{ label: 'Todos', value: false }}
              options={[
                { label: 'Todos', value: false },
                { label: 'Ativos', value: true },
              ]}
              onChange={(e: any) => setFilterActive(e.value)}
            />
          </div>
          <Card className="relative min-h-[79vh] flex flex-col sm:flex-wrap sm:flex-row gap-3 pt-5 px-4 pb-3.5 min-h-full">
            {isLoadingData && (
              <div className="z-50 absolute w-full h-full flex items-center justify-center bg-gray-200 opacity-60">
                <Spinner />
              </div>
            )}
            {SearchFormList.map((form) => (
              <CardForm
                key={form.id}
                handleDelete={() => handleDelete(form)}
                updateStatus={(active) => {
                  setUpdatingStatus(true);
                  updateStatus(active, form);
                }}
                form={form}
                openForm={() => {
                  setFormData(form);
                  setShowCreateUpdate(true);
                }}
              />
            ))}
          </Card>
        </>
      )}
      {showCreateUpdate && formData && !showDelete && !updatingStatus && (
        <CreateUpdate
          notify={notify}
          saveData={(e) => saveForm(e, formData.id)}
          closeUpdate={() => {
            setShowCreateUpdate(false);
            setFormData(undefined);
            setKey(String(Math.random()));
          }}
          defaultValue={formData}
        />
      )}
      {formData && (
        <DeleteModal
          apiFunction={ApiSearchForm.deleteSearchForm}
          close={() => {
            setShowDelete(false);
            setShowCreateUpdate(false);
          }}
          name={formData.title}
          notify={notify}
          open={showDelete}
          type="Formulário"
          updateTable={() => setKey(String(Math.random()))}
          article="O"
          id={formData.id}
        />
      )}
    </div>
  );
};

export default SearchForm;
