import React, { useContext, useState } from 'react';
import { SubAccountFullProps } from '../types';
import MainPage from '../../../../main/components/mainPage';
import { useFetchCompanies } from '../hooks';
import Register from '../components/register';
import { Modal, Switch, isLoadingApplication } from '../../../../../components';
import ModalCompanyType from '../components/ModalCompanyType';
import maskHelper from '../../../../../helpers/mask.helper';
import { ChangeStatusUser } from '../utils';
import { formatStatusVerification } from '../../../../companieGalaxAccount/utils';

const SubAccountsGalax: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const [key, setKey] = useState('');
  const [action, setAction] = useState<'PF' | 'PJ' | ''>('');
  const [isEdited, setIsEdited] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  const [input, setInput] = useState('');
  const [modalCompany, setModalCompany] = useState(false);
  const [modalTypeCompany, setmodalTypeCompany] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const [companyData, setCompany] = useState<SubAccountFullProps | null>(null);

  const { companiesList, isLoading } = useFetchCompanies(key);

  const filterTable = () => {
    if (input === '') {
      return companiesList;
    }
    return companiesList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.document.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        formatStatusVerification(item.Verification?.status)
          .status.toLowerCase()
          .indexOf(input.toLowerCase()) > -1 ||
        item.emailContact.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  const generateCsv = () => {
    const csvData = [
      [
        'GalaxPayId',
        'Nome',
        'Documento',
        'Nome fantasia',
        'E-mail Contato',
        'Acessa a Plataforma',
        'Data Última Atualização',
        'Data Criação',
        'Data Criação',
        'Status',
        'Status Análise',
      ],
      ...companiesList.map((item) => [
        item.galaxPayId,
        item.name,
        maskHelper.cnpjCpf(item.document),
        item.nameDisplay,
        item.emailContact,
        item.canAccessPlatform ? 'Sim' : 'Não',
        item.active ? 'Ativo' : 'Inativo',
        maskHelper.formatDateDMY(item.createdAt) || '',
        maskHelper.formatDateDMY(item.updatedAt) || '',
        item.Verification?.status,
      ]),
    ];
    return csvData;
  };

  const columns = [
    {
      name: 'ID',
      key: 'galaxPayId',
      selector: (row: SubAccountFullProps) => row.galaxPayId,
      width: '80px',
    },
    {
      name: 'Nome',
      key: 'name',
      selector: (row: SubAccountFullProps) => row.name,
      minWidth: '180px',
    },
    // {
    //   name: 'Nome fantasia',
    //   key: 'nameDisplay',
    //   selector: (row: SubAccountFullProps) => row.nameDisplay,
    // },
    {
      name: 'Documento',
      key: 'cpf',
      selector: (row: SubAccountFullProps) => row.document,
      cell: (row: SubAccountFullProps) => maskHelper.cnpjCpf(row.document),
      minWidth: '150px',
    },
    {
      name: 'E-mail',
      key: 'email',
      minWidth: '150px',
      selector: (row: SubAccountFullProps) => row.emailContact,
    },
    {
      name: 'Plataforma',
      width: '130px',
      key: 'canAccessPlatform',
      selector: (row: SubAccountFullProps) =>
        row.canAccessPlatform ? 'Acessa' : 'Não Acessa',
    },
    {
      name: 'Atualizado em',
      key: 'updatedAt',
      width: '130px',
      selector: (row: SubAccountFullProps) =>
        maskHelper.formatDateDMY(row.updatedAt),
    },

    {
      name: 'Status Análise',
      minWidth: '205px',
      selector: (row: SubAccountFullProps) => {
        const StatusVerification = formatStatusVerification(
          row.Verification?.status
        );
        return (
          <div className="flex flex-wrap">
            <p className={`text-wrap font-medium ${StatusVerification.color}`}>
              {StatusVerification.status}
            </p>
          </div>
        );
      },
    },
    {
      name: 'Status',
      width: '80px',
      cell: (row: SubAccountFullProps) => (
        <Switch
          handleDiameter={18}
          width={40}
          height={20}
          state={row.active}
          onChange={() =>
            ChangeStatusUser(row.galaxPayId, !row.active, setState, setKey)
          }
        />
      ),
    },
  ];

  return (
    <>
      <ModalCompanyType
        type={action}
        show={modalTypeCompany}
        confirm={() => {
          setmodalTypeCompany(false);
          setModalCompany(true);
        }}
        setType={setAction}
        setShow={setmodalTypeCompany}
      />
      <MainPage
        pagination={input === ''}
        setInput={setInput}
        isLoading={isLoading && companiesList.length === 0}
        title="Contas no Banco"
        column={columns}
        filterTable={filterTable}
        setModalCreateUpdate={() => {
          setCompany(null);
          setAction('');
          setmodalTypeCompany(true);
          // setModalCompany(e);
        }}
        setRegisterPage={() => {
          setRegisterPage({ title: 'Dados da Conta', page: 0 });
        }}
        onRowClick={(e: any) => {
          const typeCompany = e.document.length > 11 ? 'PJ' : 'PF';
          setAction(typeCompany);
          setRegisterPage({ title: 'Dados da Conta', page: 0 });
          setCompany(e);
          setModalCompany(true);
        }}
        filename="contas_banco"
        reportAndCreate
        objectReport={generateCsv()}
      />
      <Modal
        minHeight="min-h-[850px]"
        size="6xlarge"
        title={companyData?.galaxPayId ? 'Atualizar Conta' : 'Cadastrar Conta'}
        isOpen={modalCompany}
        setIsOpen={() => {
          if (isEdited) {
            setModalAlert(true);
            return;
          }
          setModalCompany(false);
          setKey(`${Math.random()} 'create'`);
        }}
      >
        <Register
          setModalAlert={setModalAlert}
          modalAlert={modalAlert}
          setIsEdited={setIsEdited}
          setCompanyDefault={setCompany}
          action={action}
          companyDefault={companyData}
          reloadTable={setKey}
          registerPage={registerPage}
          setRegisterPage={setRegisterPage}
          setShow={setModalCompany}
        />
      </Modal>
    </>
  );
};

export default SubAccountsGalax;
