import moment from 'moment';

export function dateFirstDayMonth() {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
}

export function dateLastDayMonth() {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
}

export function generateNextMonthsText(qtd: number) {
  const months: any[] = [moment().format('MMMM [de] YYYY')];

  for (let i = 1; i <= qtd; i++) {
    months.push(
      moment(moment(new Date()).add(i, 'month')).format('MMMM [de] YYYY')
    );
  }

  return months;
}
