import { baseApiPrivate } from '../../../../api/baseApi';
import {
  PaymentAccount,
  addressData,
  attachment,
  company,
  socialMediaData,
} from '../types';

async function companyById(id: any) {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/${id}`,
  });
}

async function createStartup(information: { companie_user_id: number }) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'admin/companie/createStartup',
  });
}

async function createCompanyGeneralData(information: any) {
  return baseApiPrivate({
    body: information,
    method: 'POST',
    url: 'admin/companie',
  });
}

async function updatePaymentAccount(information: PaymentAccount, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `admin/companie/billing/${id}`,
  });
}

async function updateAddress(information: addressData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `admin/companie/address/${id}`,
  });
}

async function updateSocialMedia(information: socialMediaData, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `admin/companie/social/${id}`,
  });
}

async function updateGeneralData(information: any, id: number) {
  return baseApiPrivate({
    body: information,
    method: 'PUT',
    url: `admin/companie/${id}`,
  });
}

async function getCompanies(): Promise<company[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/companie',
  });
}

async function getAllMatrix(): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/companie/matriz-all',
  });
}

async function getGalaxAccounts() {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/accounts-galaxpay`,
  });
}

async function getCompanyAttachments(id: number): Promise<attachment[]> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/companie/attachment/${id}`,
  });
}

async function createCompanyAttachments(attachments: any) {
  return baseApiPrivate({
    method: 'POST',
    body: attachments,
    url: `admin/companie/attachment`,
    file: true,
  });
}

async function updateMenuCompany(payload: any, companyId: number) {
  return baseApiPrivate({
    method: 'PUT',
    body: payload,
    url: `admin/companie/menu/profile/${companyId}`,
  });
}

async function deleteAttachments(id: number): Promise<boolean> {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/companie/attachment/${id}`,
  });
}

async function deleteCompany(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `admin/companie/${id}`,
  });
}

async function updateConfig(id: number, body: any) {
  return baseApiPrivate({
    method: 'PUT',
    url: `admin/companie/configs/${id}`,
    body,
  });
}

async function getPartnersActive(): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'GET',
    url: `admin/partners/active`,
  });
}

async function getAutentiqueCompanies() {
  return baseApiPrivate({
    method: 'GET',
    url: 'admin/companie/autentique/companies',
  });
}

async function loginCompanies(payload: any) {
  return baseApiPrivate({
    body: payload,
    method: 'POST',
    url: 'session/auth/api',
  });
}

async function putStatus(payload: any, id: number) {
  return baseApiPrivate({
    body: payload,
    method: 'PUT',
    url: `admin/companie/status/${id}`,
  });
}

async function createImport(file: any, companieID: number) {
  return baseApiPrivate({
    body: file,
    file: true,
    method: 'POST',
    url: `admin/companie/import/${companieID}`,
  });
}

export const ApiCompany = {
  loginCompanies,
  companyById,
  createCompanyAttachments,
  createCompanyGeneralData,
  createStartup,
  deleteAttachments,
  deleteCompany,
  getAllMatrix,
  getGalaxAccounts,
  getCompanies,
  getCompanyAttachments,
  updateAddress,
  updatePaymentAccount,
  updateGeneralData,
  updateSocialMedia,
  updateMenuCompany,
  updateConfig,
  getPartnersActive,
  getAutentiqueCompanies,
  putStatus,
  createImport,
};
