import { baseApiPrivate } from '../../../api/baseApi';
import { IPostData, IPutData, IPutDays, IPutStatus } from '../types';

export async function getDefaultList() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/sending/configuration/list',
  });
}

export async function getDefaultMessages() {
  return baseApiPrivate({
    method: 'GET',
    url: 'companie/whatsapp/message/default',
  });
}

export async function postConfiguration(configuration: IPostData) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/whatsapp/sending/configuration',
    body: configuration,
  });
}

export async function putMessage(message: IPutData) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/message/${message.companie_whatsapp_message_sending_title_default_id}`,
    body: {
      companie_whatsapp_message_default_id:
        message.companie_whatsapp_message_default_id,
    },
  });
}

export async function putStatus(status: IPutStatus) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/status/${status.companie_whatsapp_message_sending_title_default_id}`,
    body: { is_active: status.is_active },
  });
}

export async function putDays(qty: IPutDays) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/qty/${qty.id}`,
    body: { qty: qty.qty },
  });
}

export async function putForm(id: number, formId: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/form/${id}`,
    body: { search_form_id: formId },
  });
}

export async function putPeriod(id: number, period: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/period/${id}`,
    body: { period },
  });
}

export async function putValue(id: number, value: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/whatsapp/sending/configuration/value/${id}`,
    body: { value },
  });
}
