import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { EditOutline } from '@styled-icons/evaicons-outline/EditOutline';
import { FunnelList, Step } from '../types';
import { useFetchFunnel } from '../hooks';
import RegisterFunnel from '../components/register';
import { ToastNotify } from '../../../components/Toast/toast';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { Button, Card, DeleteModal } from '../../../components';
import { ApiFunnel } from '../api';
import IconDictionary from '../../../components/Icons/icons';
import RegisterFunnelStep from '../components/registerStep';
import Steps from '../components/steps';
import { useFetchProfile } from '../../admin/profile/hooks';
import { Profile } from '../../admin/profile/types';

const Funnels: React.FC = () => {
  const { id } = useParams();
  const userProps = getTokenAccountInformation();

  const FilterProfiles = (profiles: Profile[]) =>
    profiles
      .filter((elem) => !elem.is_admin)
      .filter((item) => item.is_franchise === userProps.companie?.is_franchise);

  const [currentPage, setCurrentPage] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalDeleteStep, setShowModalDeleteStep] = useState(false);
  const [modalRegisterFunnel, setModalRegisterFunnel] = useState(false);
  const [modalRegisterFunnelStep, setModalRegisterFunnelStep] = useState(false);

  const [funnelData, setFunnelData] = useState<FunnelList | null>(null);
  const [step, setStep] = useState<Step | null>(null);
  const [key, setKey] = useState('key');

  const { FunnelsList } = useFetchFunnel(key, userProps.companie_id);

  const { ProfileList } = useFetchProfile('key');
  const optionsProfile = FilterProfiles(ProfileList).map((profile) => ({
    label: profile.title,
    value: profile.id,
  }));

  const { notify } = ToastNotify();

  useEffect(() => {
    if (typeof id !== 'undefined') {
      const openFunnel = async () => {
        const listFunnel = await ApiFunnel.getFunnelByCompanie(
          userProps.companie_id
        );
        const funnel = listFunnel.find((item: any) => item.id === Number(id));
        setCurrentPage(funnel ? listFunnel.indexOf(funnel) : 0);
        setModalRegisterFunnelStep(true);
      };
      openFunnel();
    }
  }, []);

  return (
    <>
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Funis de vendas" size={24} />
        <strong className="text-lg text-black">Funis de vendas</strong>
      </div>
      <Card className="mt-6 p-7 flex-col relative max-w-full min-h-[85vh] mb-5 sm:mb-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div className="mb-3 sm:mb-0">
            <strong className="text-xl">Funil de Vendas</strong>
            <p className="text-sm">Configurações</p>
          </div>

          <Button
            variant="primary-strong"
            actionType="button-add"
            onClick={() => {
              setFunnelData(null);
              setModalRegisterFunnel(true);
            }}
          >
            Novo Fúnil
          </Button>
        </div>

        <div className="w-full flex mt-5 flex-wrap gap-2">
          {FunnelsList.map((funnel, index) => (
            <Button
              key={funnel.id}
              variant={currentPage === index ? 'primary-strong' : 'gray'}
              className="font-medium flex justify-center items-center pl-2 pr-2"
              onClick={() => setCurrentPage(index)}
            >
              <h4 className="text-sm">
                {funnel.id} - {funnel.title}
              </h4>
              <Button
                onClick={() => {
                  setFunnelData(funnel);
                  setModalRegisterFunnel(true);
                }}
                variant={currentPage === index ? 'primary-strong' : 'gray'}
                className="flex justify-center items-center"
              >
                <EditOutline size={16} />
              </Button>
              <Button
                onClick={() => {
                  setFunnelData(funnel);
                  setShowModalDelete(true);
                }}
                variant={currentPage === index ? 'primary-strong' : 'gray'}
                className="flex justify-center items-center ml-2 ml-2"
              >
                <CloseOutline size={14} />
              </Button>
            </Button>
          ))}
        </div>

        {FunnelsList[currentPage] && (
          <div className="flex gap-3">
            <Steps
              reload={key}
              funnelId={FunnelsList[currentPage].id}
              setModalRegisterFunnelStep={setModalRegisterFunnelStep}
              setStep={setStep}
            />
          </div>
        )}

        {FunnelsList.length === 0 && (
          <p className="mx-auto text-sm">Nenhum funil cadastrado</p>
        )}

        <br />
      </Card>
      <RegisterFunnel
        optionsProfile={optionsProfile}
        funnel={funnelData}
        reloadTable={setKey}
        setShow={setModalRegisterFunnel}
        show={modalRegisterFunnel}
        companieUserId={userProps.id}
      />
      {FunnelsList[currentPage] && (
        <RegisterFunnelStep
          handleDelete={() => {
            setModalRegisterFunnelStep(false);
            setShowModalDeleteStep(true);
          }}
          step={step}
          reloadTable={setKey}
          setShow={setModalRegisterFunnelStep}
          show={modalRegisterFunnelStep}
          funnelId={FunnelsList[currentPage].id}
        />
      )}

      {step && (
        <DeleteModal
          apiFunction={ApiFunnel.deleteStep}
          notify={notify}
          type="Etapa"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDeleteStep(false)}
          id={step.id}
          name={step.title}
          open={showModalDeleteStep}
        />
      )}

      {funnelData && (
        <DeleteModal
          apiFunction={ApiFunnel.deleteFunnel}
          notify={notify}
          type="Funil"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={funnelData.id}
          name={funnelData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Funnels;
