import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Address, Button, Modal } from '../../../../components';
import GeneralData from './components/generalData';
import { ToastNotify } from '../../../../components/Toast/toast';
import { OfferProps, productOfferProps } from '../../types';
import { ApiOffer } from '../../api';
import ProductsOffer from './components/products';
import ObservationOffer from './components/observation';

interface page {
  title: string;
  page: number;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  isCreate: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  offer: OfferProps | null;
  setOffer: (e: OfferProps) => void;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  opportunityId: number;
  productsOptions: { label: string; value: number; price: number }[];
  personOpportunity: any[];
}

const header = [
  {
    id: 0,
    title: 'Dados Contato',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Pagamento',
    page: 2,
  },
  {
    id: 3,
    title: 'Observações',
    page: 3,
  },
];

const RegisterOffer: React.FC<registerModalProps> = ({
  show,
  setShow,
  registerPage,
  personOpportunity,
  offer,
  setOffer,
  setRegisterPage,
  reloadTable,
  opportunityId,
  productsOptions,
  isCreate,
}) => {
  const { notify } = ToastNotify();

  const [key, setKey] = useState('');
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [productServiceList, setProductServiceList] = useState<
    productOfferProps[]
  >([]);

  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(String(Math.random()));
      setShow(false);
    }
  };

  useEffect(() => {
    const FetchProducts = async () => {
      setIsLoadingTable(true);
      if (offer !== null) {
        const res = await ApiOffer.getProductsOffer(offer.id);
        if (typeof res !== 'string') {
          setProductServiceList(res);
        }
      }

      setIsLoadingTable(false);
    };

    FetchProducts();
  }, [key]);

  return (
    <Modal
      title={offer && !isCreate ? 'Atualizar Proposta' : 'Cadastrar Proposta'}
      isOpen={show}
      setIsOpen={(e) => {
        setShow(e);
        reloadTable(`${Math.random()}`);
      }}
      size="6xlarge"
      minHeight="min-h-[690px]"
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 sm:flex gap-2 mt-9 ">
          {header.map((item) => (
            <Button
              key={item.id}
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                offer && offer.id ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => offer && offer.id && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />

        {registerPage.page === 0 && (
          <GeneralData
            opportunityPerson={personOpportunity}
            isCreate={isCreate}
            show={show}
            setDefaultValue={setOffer}
            notify={notify}
            nextPage={nextPage}
            defaultValue={offer}
            opportunityId={opportunityId}
          />
        )}
        {registerPage.page === 1 && (
          <Address
            hasNumber
            apiFunction={async (values, id) => {
              const res = await ApiOffer.updateOfferAddress(
                {
                  person_address: values.address,
                  person_address_city: values.city,
                  person_address_complement: values.complement,
                  person_address_number: values.number,
                  person_address_province: values.province,
                  person_address_state: values.state,
                  person_address_zipcode: values.zipcode,
                },
                id
              );
              if (res.id) {
                setOffer(res);
              }
              return res;
            }}
            defaultValue={
              offer && {
                address: offer.person_address ?? '',
                city: offer.person_address_city ?? '',
                complement: offer.person_address_complement,
                province: offer.person_address_province ?? '',
                state: offer.person_address_state ?? '',
                zipcode: offer.person_address_zipcode ?? '',
                number: offer.person_address_number,
              }
            }
            id={offer?.id ?? -1}
            nextPage={nextPage}
          />
        )}
        {registerPage.page === 2 && (
          <ProductsOffer
            nextPage={nextPage}
            defaultValue={offer}
            isEditable
            isLoadingTable={isLoadingTable}
            productServiceList={productServiceList}
            productsOptions={productsOptions}
            setIsLoadingTable={setIsLoadingTable}
            setKey={setKey}
          />
        )}

        {registerPage.page === 3 && (
          <ObservationOffer
            nextPage={nextPage}
            defaultValues={offer}
            notify={notify}
            setDefaultValue={setOffer}
          />
        )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterOffer;
