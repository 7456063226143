/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  contractProps,
  generalDataContract,
  updateformProps,
} from '../../types';
import cpfValidation from '../../../../helpers/validations/cpf.validation';
import maskHelper from '../../../../helpers/mask.helper';
import cnpjValidation from '../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../helpers/validations/phone.validate';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { ApiContract } from '../../api';
import {
  Button,
  Input,
  InputDate,
  Modal,
  SelectComponent,
} from '../../../../components';
import { ClientProps } from '../../../clients/types';
import GeneralDataClient from '../../../clients/components/register/components/generalData';
import IconDictionary from '../../../../components/Icons/icons';

interface props extends updateformProps {
  clients: ClientProps[];
  reloadClientList: (e: string) => void;
  setClient: (e: any) => void;
  setCurrentContract: (e: contractProps) => void;
  setInputClient: (e: string) => void;
  isLoadingClients: boolean;
}

const GeneralData: React.FC<props> = ({
  defaultValue,
  nextPage,
  notify,
  setId,
  setClient,
  clients,
  reloadClientList,
  setCurrentContract,
  isEditable,
  isLoadingClients,
  setInputClient,
}) => {
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [showClientHasContractMessage, setShowClientHasContractMessage] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<generalDataContract>({
    client_id: defaultValue?.client_id ?? -1,
    offer_id: defaultValue?.offer_id ?? null,
    client_name: defaultValue?.client_name ?? '',
    client_name_fantasy: defaultValue?.client_name_fantasy ?? '',
    client_birth: defaultValue
      ? defaultValue.client_birth !== null
        ? defaultValue?.client_birth.slice(0, 10)
        : null
      : null,
    client_document: defaultValue?.client_document ?? '',
    client_email: defaultValue?.client_email ?? '',
    client_rg: defaultValue?.client_rg ?? '',
    client_gender: defaultValue?.client_gender ?? '',
    client_civil_status: defaultValue?.client_civil_status ?? '',
    client_whatsapp: defaultValue?.client_whatsapp ?? '',
  });

  const clientOptions = clients.map((item) => ({
    label: `${item.name} - ${item.document}`,
    value: item.id,
  }));
  const schema = yup.object().shape({
    client_id: yup
      .number()
      .required('Selecione uma opção')
      .min(0, 'Selecione uma opção'),

    client_name: yup.string().required('Nome é obrigatório'),
    client_name_fantasy: yup.string(),
    client_birth: yup
      .date()
      .typeError('Data inválida')
      .nullable(true)
      .max(yesterdayDate, 'Data deve ser menor que hoje'),
    client_document: yup
      .string()
      .required('CPF/CNPJ é obrigatório')
      .min(11, 'Campo deve conter ao menos 11 dígitos')
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) =>
          value
            ? cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            : false,
      }),

    client_whatsapp: yup
      .string()
      .required('WhatsApp é obrigatório')
      .min(11, 'Campo deve conter 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),

    client_email: yup.string().nullable().email('E-mail inválido'),
    client_civil_status: yup.string().typeError('Valor inválido'),
  });

  const { setValue, handleSubmit, formState, reset } =
    useForm<generalDataContract>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const verifyClientHasContract = async (clientId: number) => {
    const hasOpen = await ApiContract.filterContracts({
      offerId: [],
      clientId: [clientId],
      companieUserPromoterId: [],
      order: 'created_at_desc',
      status: ['Aberto', 'Ag.Pagamento'],
      date_start: '',
      date_end: '',
      filter: 'all',
      skip: 0,
      take: 1000000000,
    });
    if (typeof hasOpen !== 'string' && hasOpen.total > 0) {
      return true;
    }

    return false;
  };

  const Submit = async (e: generalDataContract) => {
    if (defaultValue !== null) {
      setIsLoading(true);
      const res = await ApiContract.updateClientContract(
        {
          client_id: e.client_id,
          client_name: e.client_name,
          client_name_fantasy: e.client_name_fantasy ?? null,
          client_birth: defaultValues.client_birth,
          client_document: e.client_document,
          client_email: e.client_email,
          client_rg: e.client_rg,
          client_gender: e.client_gender,
          client_civil_status: e.client_civil_status,
          client_whatsapp: e.client_whatsapp,
        },
        defaultValue.id
      );

      if (res.client_id) {
        setIsLoading(false);
        notify({
          message: 'Contrato atualizado com sucesso!',
          type: 'Success',
        });

        nextPage();
        if (
          defaultValue.is_financial_responsible_client === true &&
          defaultValue.resp_financial_number !== null
        ) {
          const resPay = await ApiContract.updatePaymentResContract(
            {
              is_financial_responsible_client: true,
              resp_financial_type: 'Física',
              resp_financial_name: e.client_name,
              resp_financial_document: e.client_document,
              resp_financial_email: e.client_email,
              resp_financial_phone: defaultValue.resp_financial_phone,
              resp_financial_whatsapp: e.client_whatsapp,
              resp_financial_address: defaultValue.resp_financial_address,
              resp_financial_number: defaultValue.resp_financial_number,
              resp_financial_complement: defaultValue.resp_financial_complement,
              resp_financial_province: defaultValue.resp_financial_province,
              resp_financial_city: defaultValue.resp_financial_city,
              resp_financial_state: defaultValue.resp_financial_state,
              resp_financial_zipcode: defaultValue.resp_financial_zipcode,
            },
            defaultValue.id
          );
          setCurrentContract({
            ...defaultValue,
            is_financial_responsible_client: true,
            client_id: res.client_id,
            client_name: res.client_name,
            client_name_fantasy: res.client_name_fantasy ?? null,
            client_birth: defaultValues.client_birth ?? null,
            client_document: res.client_document,
            client_email: res.client_email,
            client_rg: res.client_rg,
            client_gender: res.client_gender,
            client_civil_status: res.client_civil_status,
            client_whatsapp: res.client_whatsapp,
            resp_financial_name: resPay.client_name,
            resp_financial_document: resPay.client_document,
            resp_financial_email: resPay.client_email,
            resp_financial_phone: resPay.resp_financial_phone,
            resp_financial_whatsapp: resPay.client_whatsapp,
            resp_financial_address: resPay.resp_financial_address,
            resp_financial_number: resPay.resp_financial_number,
            resp_financial_complement: resPay.resp_financial_complement,
            resp_financial_province: resPay.resp_financial_province,
            resp_financial_city: resPay.resp_financial_city,
            resp_financial_state: resPay.resp_financial_state,
            resp_financial_zipcode: resPay.resp_financial_zipcode,
          });
        } else {
          setCurrentContract({
            ...defaultValue,
            client_id: res.client_id,
            client_name: res.client_name,
            client_name_fantasy: res.client_name_fantasy ?? null,
            client_birth: defaultValues.client_birth ?? null,
            client_document: res.client_document,
            client_email: res.client_email,
            client_rg: res.client_rg,
            client_gender: res.client_gender,
            client_civil_status: res.client_civil_status,
            client_whatsapp: res.client_whatsapp,
          });
        }
      } else {
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const hasContract = await verifyClientHasContract(e.client_id);
      if (hasContract === true) {
        setShowClientHasContractMessage(true);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const res = await ApiContract.createClientContract({
        ...e,
        client_birth: defaultValues.client_birth,
        client_civil_status: defaultValues.client_civil_status,
      });
      if (res.id) {
        setIsLoading(false);
        notify({
          message: 'Contrato criado com sucesso!',
          type: 'Success',
        });
        nextPage();
        setCurrentContract(res);
      } else {
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    }

    setIsLoading(false);
  };

  const setFieldsValues = (current: ClientProps) => {
    setDefaultValues({
      client_id: current.id,
      offer_id: defaultValues.offer_id,
      client_name: current.name,
      client_name_fantasy: current?.name_fantasy ?? '',
      client_birth:
        current.birth !== null && current.birth !== undefined
          ? current.birth.slice(0, 10)
          : null,
      client_document: current.document,
      client_email: current.email,
      client_rg: current.identity ?? '',
      client_gender: current.gender ?? '',
      client_civil_status: current.civil_status ?? '',
      client_whatsapp: current.whatsapp,
    });
    reset({
      client_id: current.id,
      offer_id: defaultValues.offer_id,
      client_name: current.name,
      client_name_fantasy: current?.name_fantasy ?? '',
      client_birth:
        current.birth !== null && current.birth !== undefined
          ? current.birth.slice(0, 10)
          : null,
      client_document: current.document,
      client_email: current.email,
      client_rg: current.identity ?? '',
      client_gender: current.gender ?? '',
      client_civil_status: current.civil_status ?? '',
      client_whatsapp: current.whatsapp,
    });
    setClient({
      client_address: current.address ?? '',
      client_address_city: current.city ?? '',
      client_address_complement: current.complement ?? '',
      client_address_province: current.province ?? '',
      client_address_state: current.state ?? '',
      client_address_zipcode: current.zipcode ?? '',
    });
  };

  const socialStatus = [
    { value: 'solteiro', label: 'Solteiro(a)' },
    { value: 'casado', label: 'Casado(a)' },
    { value: 'viuvo', label: 'Viúvo(a)' },
    { value: 'divorciado', label: 'Divorciado(a)' },
  ];

  const category = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
  ];

  useEffect(() => {
    const currentClient = clients.find(
      (item) => item.id === defaultValues.client_id
    );
    if (currentClient) setFieldsValues(currentClient);
  }, [defaultValues.client_id, clients]);

  useEffect(() => {
    setDefaultValues({
      client_id: defaultValue?.client_id ?? -1,
      offer_id: defaultValue?.offer_id ?? null,
      client_name: defaultValue?.client_name ?? '',
      client_name_fantasy: defaultValue?.client_name_fantasy ?? '',
      client_birth: defaultValue
        ? defaultValue.client_birth !== null
          ? defaultValue?.client_birth.slice(0, 10)
          : null
        : null,
      client_document: defaultValue?.client_document ?? '',
      client_email: defaultValue?.client_email ?? '',
      client_rg: defaultValue?.client_rg ?? '',
      client_gender: defaultValue?.client_gender ?? '',
      client_civil_status: defaultValue?.client_civil_status ?? '',
      client_whatsapp: defaultValue?.client_whatsapp ?? '',
    });
    reset({
      client_id: defaultValue?.client_id,
      offer_id: defaultValue?.offer_id,
      client_name: defaultValue?.client_name,
      client_name_fantasy: defaultValue?.client_name_fantasy ?? '',
      client_birth: defaultValue
        ? defaultValue.client_birth !== null
          ? defaultValue?.client_birth.slice(0, 10)
          : null
        : null,
      client_document: defaultValue?.client_document,
      client_email: defaultValue?.client_email,
      client_rg: defaultValue?.client_rg,
      client_gender: defaultValue?.client_gender,
      client_civil_status: defaultValue?.client_civil_status,
      client_whatsapp: defaultValue?.client_whatsapp,
    });
  }, [defaultValue]);
  return (
    <>
      {openCreateContact && (
        <Modal
          title="Criar cliente"
          isOpen={openCreateContact}
          setIsOpen={setOpenCreateContact}
          size="6xlarge"
          minHeight="min-h-[700px]"
        >
          <GeneralDataClient
            setClient={(clientData) => {
              setDefaultValues({
                ...defaultValues,
                client_id: clientData.id,
                client_name: clientData.name,
                client_name_fantasy: clientData?.name_fantasy ?? '',
                client_birth:
                  clientData.birth !== null && clientData.birth !== undefined
                    ? clientData.birth.slice(0, 10)
                    : null,
                client_document: clientData.document,
                client_email: clientData.email,
                client_rg: clientData.identity ?? '',
                client_gender: clientData.gender ?? '',
                client_civil_status: clientData.civil_status ?? '',
                client_whatsapp: clientData.whatsapp,
              });
              setInputClient(clientData.name);
              reset({
                client_id: clientData.id,
                offer_id: defaultValues.offer_id,
                client_name: clientData.name,
                client_name_fantasy: clientData.name_fantasy ?? '',
                client_birth: clientData
                  ? clientData.birth !== null
                    ? clientData.birth?.slice(0, 10)
                    : null
                  : null,
                client_document: clientData.document,
                client_email: clientData.email,
                client_rg: clientData.identity,
                client_gender: clientData.gender,
                client_civil_status: clientData.civil_status,
                client_whatsapp: clientData.whatsapp,
              });
            }}
            onePage
            id={0}
            nextPage={() => {
              notify({
                message: 'Contato criado com sucesso',
                type: 'Success',
              });
              setOpenCreateContact(false);
            }}
            notify={notify}
            setId={(id) => {
              setDefaultValues({ ...defaultValues, client_id: id });
              reloadClientList(`${Math.random()} create`);
            }}
            defaultValue={null}
          />{' '}
        </Modal>
      )}
      {showClientHasContractMessage === true && (
        <Modal
          title=""
          minHeight="200px"
          setIsOpen={setShowClientHasContractMessage}
          isOpen={showClientHasContractMessage}
          header={false}
        >
          <div className="flex gap-2 items-center mb-3">
            <IconDictionary
              name="AlertCircle"
              className="text-yellow-400"
              size={36}
            />
            <p className="text-sm font-medium">
              Não é possível criar contrato com esse cliente
            </p>
          </div>
          <p className="text-xs mb-3">
            Cliente possui contrato aberto ou aguardando pagamento vinculado ao
            seu nome.
          </p>
          <Button
            actionType="button-full-width"
            onClick={() => setShowClientHasContractMessage(false)}
          >
            Ok
          </Button>
        </Modal>
      )}
      <form
        className="grid grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
        onSubmit={handleSubmit(Submit)}
      >
        <div className="col-span-2">
          <div className="relative">
            {isEditable && (
              <button
                onClick={() => setOpenCreateContact(true)}
                type="button"
                className="absolute top-0 mt-1 right-0 text-xs text-primary cursor-pointer z-10"
              >
                Adicionar Novo
              </button>
            )}
            <SelectComponent
              isLoading={isLoadingClients}
              isDisabled={!isEditable}
              title="Cliente"
              placeholder="Digite as 4 primeiras letras ou números para pesquisar pelo nome ou CPF/CNPJ"
              isMulti={false}
              closeMenuOnSelect
              options={clientOptions}
              onInputChange={setInputClient}
              value={clientOptions.find(
                (item) => item.value === defaultValues.client_id
              )}
              onChange={(e: any) => {
                setDefaultValues({ ...defaultValues, client_id: e.value });
                setValue('client_id', e.value);
              }}
              errorMensage={formState.errors.client_id?.message}
            />
          </div>
        </div>

        <Input
          disabled={defaultValues.client_id === -1 || !isEditable}
          variant="outline-orange"
          title="Nome / Razão Social"
          value={defaultValues.client_name}
          classNameDiv="col-span-3 md:col-span-1"
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, client_name: e.target.value });
            setValue('client_name', e.target.value);
          }}
          errorMensage={formState.errors.client_name?.message}
        />
        <Input
          disabled={defaultValues.client_id === -1 || !isEditable}
          title="Apelido / Nome Fantasia"
          variant="outline-orange"
          classNameDiv="col-span-3 md:col-span-1"
          value={defaultValues.client_name_fantasy}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              client_name_fantasy: e.target.value,
            });
            setValue('client_name_fantasy', e.target.value);
          }}
          errorMensage={formState.errors.client_name_fantasy?.message}
        />
        <div className="flex flex-col col-span-2 sm:grid grid-cols-4 gap-3">
          <Input
            disabled={defaultValues.client_id === -1 || !isEditable}
            title="CPF / CNPJ"
            variant="outline-orange"
            className="md:col-span-2 lg:col-span-1"
            type="text"
            value={maskHelper.cnpjCpf(defaultValues.client_document)}
            placeholder="000.000.000-00"
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_document: e.target.value,
              });
              setValue(
                'client_document',
                maskHelper.numberCPFCNPJ(e.target.value)
              );
            }}
            errorMensage={formState.errors.client_document?.message}
          />
          <Input
            disabled={defaultValues.client_id === -1 || !isEditable}
            title="RG"
            variant="outline-orange"
            className="md:col-span-2 lg:col-span-1"
            type="text"
            value={defaultValues.client_rg}
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_rg: e.target.value,
              });
              setValue('client_rg', e.target.value);
            }}
            errorMensage={formState.errors.client_rg?.message}
          />
          <SelectComponent
            isDisabled={defaultValues.client_id === -1 || !isEditable}
            title="Estado Civil"
            isMulti={false}
            closeMenuOnSelect
            options={socialStatus}
            value={socialStatus.find(
              (item) => item.value === defaultValues.client_civil_status
            )}
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_civil_status: e.value,
              });
              setValue('client_civil_status', e.value);
            }}
            errorMensage={formState.errors.client_civil_status?.message}
          />

          <SelectComponent
            isDisabled={defaultValues.client_id === -1 || !isEditable}
            title="Sexo"
            closeMenuOnSelect
            options={category}
            value={category.find(
              (item) => item.value === defaultValues.client_gender
            )}
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_gender: e.value,
              });
              setValue('client_gender', e.value);
            }}
            errorMensage={formState.errors.client_gender?.message}
          />
        </div>
        <div className="flex flex-col sm:grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 sm:flex-row gap-x-3 gap-y-6 col-span-2 lg:col-span-2">
          <InputDate
            disabled={defaultValues.client_id === -1 || !isEditable}
            className="col-span-2 lg:col-span-1"
            title="Data de Nascimento"
            defaultValue={
              defaultValues.client_birth
                ? new Date(`${defaultValues.client_birth} `)
                : undefined
            }
            onChange={(e: Date) => {
              if (e !== null) {
                setDefaultValues({
                  ...defaultValues,
                  client_birth: maskHelper.formatDateYMD(
                    String(e.toLocaleString('pt-BR'))
                  ),
                });
                setValue(
                  'client_birth',
                  maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
                );
              } else {
                setDefaultValues({
                  ...defaultValues,
                  client_birth: null,
                });
                setValue('client_birth', null);
              }
            }}
            errorMensage={formState.errors.client_birth?.message}
          />
          <Input
            disabled={defaultValues.client_id === -1 || !isEditable}
            title="WhatsApp"
            variant="outline-orange"
            placeholder="(00) 00000-0000"
            max={15}
            type="text"
            value={maskHelper.phone(defaultValues.client_whatsapp)}
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_whatsapp: e.target.value,
              });
              setValue(
                'client_whatsapp',
                maskHelper.numberPhone(e.target.value)
              );
            }}
            errorMensage={formState.errors.client_whatsapp?.message}
          />
          <Input
            disabled={defaultValues.client_id === -1 || !isEditable}
            title="E-mail"
            variant="outline-orange"
            type="text"
            value={defaultValues.client_email}
            onChange={(e: any) => {
              setDefaultValues({
                ...defaultValues,
                client_email: e.target.value,
              });
              setValue('client_email', e.target.value);
            }}
            errorMensage={formState.errors.client_email?.message}
          />
        </div>
        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading || !isEditable}
          >
            Salvar e continuar
          </Button>
        </div>
      </form>
    </>
  );
};

export default GeneralData;
