import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
}
const ModalHasNoInstances: React.FC<props> = ({ setShow, show }) => {
  const navigate = useNavigate();
  return (
    <Modal
      setIsOpen={setShow}
      isOpen={show}
      title={
        <div className="flex gap-2 items-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Não é possível criar campanha
          </h3>
          <IconDictionary
            name="AlertCircle"
            className="text-yellow"
            size={24}
          />
        </div>
      }
      size="medium"
      minHeight="min-h-[200px]"
    >
      <p className="text-sm mb-7">
        Não existem instancias disponíveis para a criação de uma nova campanha.
        Deseja cadastrar uma nova instância ou conectar um existente?
      </p>

      <div className="flex gap-3 mx-auto">
        <Button
          className="w-56"
          variant="outline-primary"
          onClick={() => setShow(false)}
        >
          Sair
        </Button>
        <Button
          className="w-56"
          variant="primary-strong"
          onClick={() => navigate('/instances')}
        >
          Cadastrar/Conectar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalHasNoInstances;
