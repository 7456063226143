import { useEffect, useState } from 'react';
import { ApiOpportunityReports } from '../api';
import { reportFilter } from '../types';
import { OpportunityProps } from '../../../opportunity/types';

export function useFetchReportsOpportunity(
  companyId: number,
  filter: string,
  props: reportFilter
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ data: OpportunityProps[]; total: number }>(
    { data: [], total: 0 }
  );
  useEffect(() => {
    const FetchOldSale = async () => {
      setIsLoading(true);
      const oldSale =
        companyId === -1
          ? { data: [], total: 0 }
          : await ApiOpportunityReports.getOpportunityReports(
              companyId,
              filter,
              { ...props, opportunityId: props.opportunityId?.value }
            );
      if (typeof oldSale !== 'string') {
        setData(oldSale);
      } else {
        setData({ data: [], total: 0 });
      }
      setIsLoading(false);
    };

    FetchOldSale();
  }, [filter, props]);
  return { OpportunityReportsList: data, isLoading };
}
