/* eslint-disable no-unused-vars */
import React, { SetStateAction, useEffect, useState } from 'react';
import IconDictionary from '../../../../../components/Icons/icons';
import { answerItemProps } from '../../../types';

interface props {
  text: string;
  setText: (elem: answerItemProps[]) => void;
  options: {
    id: number;
    title: string;
  }[];
}

const MultipleBox: React.FC<props> = ({ setText, text, options }) => {
  const [optionsCheck, setOptionsCheck] = useState<answerItemProps[]>([]);

  const handleCheckboxChange = (elem: answerItemProps) => {
    setOptionsCheck((prevCheckedItems) => {
      const isChecked = prevCheckedItems.includes(elem);

      if (isChecked) {
        const updatedList = optionsCheck.filter(
          (item) =>
            item.search_form_question_option_id !==
            elem.search_form_question_option_id
        );
        setOptionsCheck(updatedList);
        return prevCheckedItems.filter(
          (item) =>
            item.search_form_question_option_id !==
            elem.search_form_question_option_id
        );
      }

      setOptionsCheck((prevLista) => [...prevLista, elem]);
      return [...prevCheckedItems, elem];
    });
  };
  useEffect(() => {
    setText(optionsCheck);
  }, [optionsCheck]);
  return (
    <div className="w-full sm:w-2/3 text-sm py-3">
      <p className="mb-1.5">{text}</p>

      {options.map((item, index) => (
        <div
          className="flex gap-2 text-xs my-2"
          key={item.title + String(index + 1)}
        >
          <input
            type="checkbox"
            id={item.title}
            className=" cursor-pointer"
            onChange={() =>
              handleCheckboxChange({
                search_form_question_option_id: item.id,
                text_response: item.title,
              })
            }
          />
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default MultipleBox;
