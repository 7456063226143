/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Button,
  Input,
  InputDate,
  SelectComponent,
} from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { OfferProps, createOfferProps } from '../../../types';
import { useFetchClientDropdown } from '../../../../clients/hooks';
import { ApiOffer } from '../../../api';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import { useFetchContact } from '../../../../contacts/hooks';
import { ContactOpportunity } from '../../../../opportunity/types';
import IconDictionary from '../../../../../components/Icons/icons';

interface generalProps {
  show: boolean;
  defaultValue: OfferProps | null;
  opportunityId: number;
  nextPage: () => void;
  notify: (e: {
    message: string;
    type: 'Info' | 'Warning' | 'Success' | 'Error';
  }) => void;
  setDefaultValue: (e: any) => void;
  isCreate: boolean;
  opportunityPerson: any[];
}

const GeneralData: React.FC<generalProps> = ({
  show,
  nextPage,
  defaultValue,
  notify,
  setDefaultValue,
  opportunityPerson,
  opportunityId,
  isCreate,
}) => {
  const isCreateNotHaveContact =
    !defaultValue && opportunityPerson.length === 0;
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const defaultUserValues: createOfferProps = {
    person_document: defaultValue?.person_document ?? '',
    person_email: defaultValue?.person_email ?? '',
    person_name: defaultValue?.person_name ?? '',
    person_whatsapp: defaultValue?.person_whatsapp ?? '',
    person_civil_status: defaultValue?.person_civil_status ?? '',
    opportunity_id: opportunityId,
    person_id: defaultValue?.person_id ?? -1,
    person_name_fantasy: defaultValue?.person_name_fantasy ?? '',
    person_birth: defaultValue?.person_birth ?? null,
    person_rg: defaultValue?.person_rg ?? '',
    person_gender: defaultValue?.person_gender ?? '',
  };

  const [personId, setPersonId] = useState(defaultValue?.person_id ?? -1);

  const category = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
  ];

  const contactOptions = opportunityPerson.map((item) => ({
    label: item.person?.name,
    value: item.person_id,
  }));

  const schema = yup.object().shape({
    person_name: yup.string().required('Nome é obrigatório'),
    person_name_fantasy: yup.string(),
    person_birth: yup
      .date()
      .nullable(true)
      .max(yesterdayDate, 'Data deve ser menor que hoje'),
    person_document: yup
      .string()
      .typeError('CPF/CNPJ é obrigatório')
      .required('CPF/CNPJ é obrigatório')
      .min(11, 'Campo deve conter ao menos 11 dígitos')
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) =>
          value
            ? cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            : false,
      }),

    person_whatsapp: yup
      .string()
      .typeError('WhatsApp é obrigatório')
      .required('WhatsApp é obrigatório')
      .min(11, 'Campo deve conter 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),

    person_email: yup
      .string()
      .typeError('E-mail é obrigatório')
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    opportunity_id: yup.number().min(0, 'Selecione uma opção'),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<createOfferProps>(defaultUserValues);
  const { handleSubmit, setValue, formState, reset } =
    useForm<createOfferProps>({
      resolver: yupResolver(schema),
      defaultValues: {
        person_document: defaultValue?.person_document ?? '',
        person_email: defaultValue?.person_email ?? '',
        person_name: defaultValue?.person_name ?? '',
        person_whatsapp: defaultValue?.person_whatsapp ?? '',
        person_civil_status: defaultValue?.person_civil_status ?? '',
        opportunity_id: opportunityId,
        person_id: defaultValue?.person_id ?? -1,
        person_name_fantasy: defaultValue?.person_name_fantasy ?? '',
        person_birth: defaultValue?.person_birth ?? null,
        person_rg: defaultValue?.person_rg ?? '',
        person_gender: defaultValue?.person_gender ?? '',
      },
    });

  const socialStatus = [
    { value: 'solteiro', label: 'Solteiro(a)' },
    { value: 'casado', label: 'Casado(a)' },
    { value: 'viuvo', label: 'Viúvo(a)' },
    { value: 'divorciado', label: 'Divorciado(a)' },
  ];

  const Submit = async (values: createOfferProps) => {
    if (defaultValue !== null && !isCreate) {
      setIsLoading(true);
      const res = await ApiOffer.updateOffer(
        {
          person_document: values.person_document,
          person_email: values.person_email,
          person_name: values.person_name,
          person_whatsapp: values.person_whatsapp,
          person_civil_status: values.person_civil_status,
          person_id: values.person_id,
          person_name_fantasy: values.person_name_fantasy,
          person_birth: values.person_birth,
          person_rg: values.person_rg,
          person_gender: values.person_gender,
        },
        defaultValue.id
      );
      if (res.id) {
        setDefaultValue({
          ...defaultValue,
          id: res.id,
          person_document: res.person_document,
          person_email: res.person_email,
          person_name: res.person_name,
          person_whatsapp: res.person_whatsapp,
          person_civil_status: res.person_civil_status,
          opportunity_id: res.opportunity_id,
          person_id: res.person_id,
          person_name_fantasy: res.person_name_fantasy,
          person_birth: res.person_birth,
          person_rg: res.person_rg,
          person_gender: res.person_gender,
          created_at: res.created_at,
          status: res.status,
          status_date: res.status_date,
          total: res.total,
          updated_at: res.updated_at,
          number: res.number,
        });
        setIsLoading(false);
        notify({
          message: 'Proposta atualizada com sucesso!',
          type: 'Success',
        });
        nextPage();
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiOffer.createOffer({
        ...values,
        opportunity_id: opportunityId,
        person_id: defaultValues.person_id,
      });
      if (response.id) {
        setDefaultValue({
          ...defaultValue,
          id: response.id,
          person_document: response.person_document,
          person_email: response.person_email,
          person_name: response.person_name,
          person_whatsapp: response.person_whatsapp,
          person_civil_status: response.person_civil_status,
          opportunity_id: response.opportunity_id,
          person_id: response.person_id,
          person_name_fantasy: response.person_name_fantasy,
          person_birth: response.person_birth,
          person_rg: response.person_rg,
          person_gender: response.person_gender,
          created_at: response.created_at,
          status: response.status,
          status_date: response.status_date,
          total: response.total,
          updated_at: response.updated_at,
          number: response.number,
        });
        nextPage();
        notify({ message: 'Proposta salva com sucesso', type: 'Success' });
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const person = opportunityPerson.find(
      (item) => item.person_id === defaultValues.person_id
    );

    if (person) {
      reset({
        person_document: person.person.document,
        person_email: person.person.email,
        person_name: person.person.name,
        person_whatsapp: person.person.whatsapp,
        opportunity_id: opportunityId,
        person_id: person.person_id,
      });
      setDefaultValues({
        person_document: person.person.document,
        person_email: person.person.email,
        person_name: person.person.name,
        person_whatsapp: person.person.whatsapp,
        opportunity_id: opportunityId,
        person_id: person.person_id,
      });
      setDefaultValue({
        ...defaultValue,
        person_address: person.person.address,
        person_address_city: person.person.city ?? '',
        person_address_complement: person.person.complement ?? '',
        person_address_province: person.person.province ?? '',
        person_address_state: person.person.state ?? '',
        person_address_zipcode: person.person.zipcode ?? '',
      });
    }
  }, [personId]);

  return (
    <form
      className="grid grid-cols-2 gap-3 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      {isCreateNotHaveContact && (
        <div className="px-3 py-5 flex items-center gap-3 w-full h-10 border border-dashed border-primary col-span-2 mb-2">
          <IconDictionary
            size={24}
            name="AlertTriangle"
            className="text-primary"
          />
          <p className="text-xs">
            Todos os contatos da oportunidade já estão vinculados a uma
            proposta. Por favor, adicione um novo contato a oportunidade para
            criar uma nova proposta.
          </p>
        </div>
      )}
      <SelectComponent
        classNameDiv={opportunityId ? 'col-span-2' : ''}
        title="Contato"
        isDisabled={contactOptions.length === 0}
        isMulti={false}
        closeMenuOnSelect
        options={contactOptions}
        value={
          contactOptions.length === 0 && defaultValue
            ? { label: defaultValue.person_name, value: defaultValue.person_id }
            : contactOptions.find(
                (item) => item.value === defaultValues.person_id
              )
        }
        onChange={(e: any) => {
          setPersonId(e.value);
          setDefaultValues({ ...defaultValues, person_id: e.value });
          setValue('person_id', e.value);
        }}
        errorMensage={formState.errors.person_id?.message}
      />

      <Input
        disabled={personId === -1}
        variant="outline-orange"
        title="Nome"
        value={defaultValues.person_name}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, person_name: e.target.value });
          setValue('person_name', e.target.value);
        }}
        errorMensage={formState.errors.person_name?.message}
      />

      <Input
        disabled={personId === -1}
        title="Apelido / Nome Fantasia"
        variant="outline-orange"
        className="col-span-3 md:col-span-1"
        value={defaultValues.person_name_fantasy}
        onChange={(e) => {
          setDefaultValues({
            ...defaultValues,
            person_name_fantasy: e.target.value,
          });
          setValue('person_name_fantasy', e.target.value);
        }}
        errorMensage={formState.errors.person_name_fantasy?.message}
      />
      <div className="flex flex-col sm:grid grid-cols-4  sm:flex-row gap-x-3 gap-y-6 col-span-2 lg:col-span-2">
        {' '}
        <Input
          disabled={personId === -1}
          title="CPF/CNPJ"
          variant="outline-orange"
          type="string"
          placeholder="000.000.000-00"
          value={maskHelper.cpf(defaultValues.person_document ?? '')}
          onChange={(e) => {
            e.target.value = maskHelper.cnpjCpf(e.target.value);
            setDefaultValues({
              ...defaultValues,
              person_document: e.target.value,
            });
            setValue(
              'person_document',
              maskHelper.numberCPFCNPJ(e.target.value)
            );
          }}
          errorMensage={formState.errors.person_document?.message}
        />
        <Input
          disabled={personId === -1}
          title="RG"
          variant="outline-orange"
          className="md:col-span-2 lg:col-span-1"
          type="text"
          value={defaultValues.person_rg}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              person_rg: e.target.value,
            });
            setValue('person_rg', e.target.value);
          }}
          errorMensage={formState.errors.person_rg?.message}
        />
        <Input
          disabled={personId === -1}
          title="WhatsApp"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          value={maskHelper.phone(defaultValues.person_whatsapp ?? '')}
          onChange={(e) => {
            e.target.value = maskHelper.phone(e.target.value);
            setDefaultValues({
              ...defaultValues,
              person_whatsapp: e.target.value,
            });
            setValue('person_whatsapp', maskHelper.number(e.target.value));
          }}
          errorMensage={formState.errors.person_whatsapp?.message}
        />
        <Input
          disabled={personId === -1}
          title="E-mail"
          variant="outline-orange"
          value={defaultValues.person_email}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              person_email: e.target.value,
            });
            setValue('person_email', e.target.value);
          }}
          errorMensage={formState.errors.person_email?.message}
        />
      </div>

      <div className="flex flex-col sm:grid grid-cols-3  sm:flex-row gap-x-3 gap-y-6 col-span-2 lg:col-span-2">
        <InputDate
          disabled={personId === -1}
          className="col-span-2 lg:col-span-1"
          title="Data de Nascimento"
          defaultValue={defaultValues.person_birth ?? undefined}
          onChange={(e: Date) => {
            if (e !== null)
              setValue(
                'person_birth',
                maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
              );
          }}
          // errorMensage={formState.errors.person_birth?.message}
        />

        <SelectComponent
          isDisabled={personId === -1}
          title="Estado Civil"
          isMulti={false}
          closeMenuOnSelect
          options={socialStatus}
          value={socialStatus.find(
            (item) => item.value === defaultValues.person_civil_status
          )}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              person_civil_status: e.value,
            });
            setValue('person_civil_status', e.value);
          }}
          errorMensage={formState.errors.person_civil_status?.message}
        />

        <SelectComponent
          isDisabled={personId === -1}
          title="Sexo"
          closeMenuOnSelect
          options={category}
          value={category.find(
            (item) => item.value === defaultValues.person_gender
          )}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              person_gender: e.value,
            });
            setValue('person_gender', e.value);
          }}
          errorMensage={formState.errors.person_gender?.message}
        />
      </div>

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default GeneralData;
