import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Link, useNavigate } from 'react-router-dom';
import { lighten } from 'polished';
import Footer from '../components/footer';
import Header from '../components/header';
import Menu from '../components/menu';
import LoginForm from '../components/formLogin';
import backgroundImage from '../../../assets/jpg/background_home.jpg';
import backgroundImageMobile from '../../../assets/jpg/background_home_mobile.jpg';
import { Modal, Spinner } from '../../../components';
import EmailValidate from '../../../helpers/validations/email.validate';
import { logiParams } from '../api';
import { saveToken } from '../../../helpers/token.helper';
import { getItem, setItem } from '../../../helpers/storage.helper';
import { dictionaryError } from '../../../helpers/utils.helper';
import CookiesBanner from '../components/cookies';
import { cookiesAccepted } from '../../../hooks/globalAtom';

function CardLogin({
  onApiResponse,
}: {
  // eslint-disable-next-line
  onApiResponse: (property: any) => void;
}) {
  return (
    <div className="border-primary border-solid border flex flex-col bg-white w-full sm:max-w-xl lg:max-w-md rounded-[30px] px-6 py-[53px] lg:py-11 min-h-[370px] h-fit mx-auto lg:auto-0">
      <p className="text-4xl font-semibold mb-3 lg:mb-1 2xl:mb-3">Entrar</p>
      <p className="font-normal text-sm mb-8 lg:mb-5 text-matterhorn">
        Informe o e-mail e senha para autenticação.
      </p>

      <LoginForm onApiResponse={onApiResponse} />

      <Link
        to="/password-recovery"
        className="font-normal text-xs self-end mt-4 mb-8 lg:mb-4 2xl:mb-8"
      >
        Esqueceu sua senha?
      </Link>
    </div>
  );
}

const AuthenticatedParamsLoading = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const [err, setErr] = useState('');
  const navigate = useNavigate();

  (async () => {
    const response = await logiParams({ email, password });
    if (response.token) {
      saveToken(response.token);
      if (response.companie !== undefined)
        setItem(
          'generate_contract_opp',
          response.companie.is_generate_contract_opp
        );
      setItem(
        'menu',
        response.menus.filter((item: any) => item !== null)
      );
      navigate('/');
    } else {
      setErr(dictionaryError(response));
    }
  })();

  return (
    <div className="absolute top-0 left-0 w-screen h-screen z-10 bg-zinc-100 flex items-center justify-center">
      {err ? (
        <span className="font-Fredoka">{err}</span>
      ) : (
        <>
          <Spinner />
          <span className="font-Fredoka">Carregando...</span>
        </>
      )}
    </div>
  );
};

const loginPage: React.FC = () => {
  const [active, setActive] = useState(false);
  const [acceptedCookies] = useAtom(cookiesAccepted);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { location } = window;
  const parts = location.pathname.split('/');
  const email = parts[parts.length - 2];
  const password = parts[parts.length - 1];

  const handleApiResponse = (property: any) => {
    setIsModalOpen(property === 'Error: companie_not_active');
  };

  if (EmailValidate.isValid(email) && password === 'IHthn4KY4jELxt9JPjOL') {
    return <AuthenticatedParamsLoading email={email} password={password} />;
  }

  useEffect(() => {
    const root = document.documentElement;
    const color = getItem('color-hex') || '#541554';
    root.style.setProperty('--primary', color);
    root.style.setProperty('--secondary', lighten(0.1, color));
  }, []);

  return (
    <div className="containerScreen flex flex-col bg-[#FCF7F2] h-fit min-h-screen overflow-hidden justify-between">
      <Modal
        title="ATENÇÃO"
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        minHeight="max-w-md"
      >
        <div>
          <div>
            <p className="text-md mb-4 text-justify mr-5">
              Acesso temporariamente bloqueado. Por favor, entre em contato com
              nosso suporte para regularizar sua situação.
            </p>
          </div>
          <div>
            <strong>Whatsapp:</strong> (31) 97141-1125
          </div>
        </div>
      </Modal>
      {!active ? (
        <Header active={active} setActive={setActive} />
      ) : (
        <Menu setNotActive={setActive} />
      )}
      <img
        src={backgroundImageMobile}
        alt="backgroundImage"
        className="w-full absolute top-0 z-0 lg:hidden brightness-75"
      />
      <img
        src={backgroundImage}
        alt="backgroundImage"
        className="hidden lg:flex lg:w-full absolute top-0 z-0 lg:min-h-[60vh] max-w-none brightness-75"
      />
      <div className="main w-full min-h-fit h-fit flex flex-col lg:flex-row mt-24 lg:mt-28 mb-24 gap-16 lg:gap-[77px] px-2 lg:px-0 justify-evenly bg-transparent items-center">
        <div className="h-full flex lg:justify-end mx-auto lg:mx-0 w-fit">
          <div className="font-semibold text-2xl sm:text-[38px] md:text-[52px] -ml-10 md:-ml-16 leading-[24px] sm:leading-[40px] md:leading-[64px] mt-10 z-1 relative">
            <h3 className="text-white font-bold text-4xl text-center lg:text-left md:text-6xl justify-self-start my-auto">
              Convertendo <br />
              mais clientes para <br />
              seu negócio!
            </h3>
          </div>
        </div>
        <div className="relative w-full lg:w-[360px]">
          <CardLogin onApiResponse={handleApiResponse} />
        </div>
      </div>
      <Footer />

      {acceptedCookies === false && <CookiesBanner />}
    </div>
  );
};

export default loginPage;
