import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';
import { SendDocumentPFProps, propsModal } from '../../../../types';
import { ApiCompanyGalaxPay } from '../../../../api';
import { Button, Input, InputDate } from '../../../../../../../../components';
import maskHelper from '../../../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../../../helpers/utils.helper';
import { notify } from '../../../../../../../../components/Toast/toast';
import { OptionsSelectProp } from '../../../../../../../../components/Select/select';
import PersonalUpload from './personalUpload';
import AlertCard from '../../../../../../../../components/Card/alertCard';

const DocumentsPF: React.FC<propsModal> = ({
  nextPage,
  defaultValue,
  disabledEdit,
}) => {
  const optionsDocumentCompanie = [
    { label: 'RG', value: 'RG' },
    { label: 'CNH', value: 'CNH' },
  ];
  const [frontDoc, setFrontDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.front ?? null
  );
  const [backDoc, setBackDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.back ?? null
  );
  const [selfieDoc, setSelfieDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.selfie ?? null
  );
  const [residenceDoc, setResidenceDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.address ?? null
  );

  const [documentType, setDocumentType] = useState<OptionsSelectProp>(
    optionsDocumentCompanie[0]
  );

  const [generalDataValue, setGeralData] = useState<SendDocumentPFProps>({
    galaxId: defaultValue?.galaxPayId ?? -1,
    Fields: {
      socialMediaLink: '',
      monthlyIncome: 0,
      about: '',
      motherName: '',
      birthDate: '',
    },

    Documents: {
      Personal: {
        CNH: {
          selfie: '',
          picture: [],
        },
        RG: {
          selfie: '',
          front: '',
          back: '',
        },
      },
    },
  });

  const schema = yup.object().shape({
    Fields: yup.object().shape({
      monthlyIncome: yup.number().required('Campo Obrigatório'),
      about: yup.string().required('Campo Obrigatório'),
      socialMediaLink: yup.string().required('Campo Obrigatório'),
      motherName: yup.string().required('Campo Obrigatório'),
      birthDate: yup.date().required('Campo Obrigatório'),
    }),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState } = useForm<SendDocumentPFProps>({
    resolver: yupResolver(schema),
  });

  const Submit = async (values: SendDocumentPFProps) => {
    if (defaultValue !== null) {
      if (
        selfieDoc === null ||
        frontDoc === null ||
        backDoc === null ||
        residenceDoc === null
      ) {
        notify({
          message: 'O envio de todos os Documentos Pessoais é obrigatório',
          type: 'Error',
        });
        return;
      }
      const docs =
        documentType.value === 'RG'
          ? {
              RG: {
                selfie: selfieDoc?.replace('data:', '').replace(/^.+,/, ''),
                front: frontDoc?.replace('data:', '').replace(/^.+,/, ''),
                back: backDoc?.replace('data:', '').replace(/^.+,/, ''),
                address: residenceDoc?.replace('data:', '').replace(/^.+,/, ''),
              },
            }
          : {
              CNH: {
                selfie: selfieDoc?.replace('data:', '').replace(/^.+,/, ''),
                picture: [
                  frontDoc?.replace('data:', '').replace(/^.+,/, ''),
                  backDoc?.replace('data:', '').replace(/^.+,/, ''),
                ],
                address: residenceDoc?.replace('data:', '').replace(/^.+,/, ''),
              },
            };
      const payload = {
        ...values,
        galaxId: generalDataValue.galaxId,
        Documents: {
          Personal: docs,
        },
      };

      setIsLoading(true);
      const response = await ApiCompanyGalaxPay.sendDocumentsPF(payload);

      if (response.type === true) {
        notify({
          message: 'Documentos enviados com sucesso',
          type: 'Success',
        });
        nextPage(response);
        setIsLoading(false);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };

  const handleDocumentChange = (type: OptionsSelectProp) => {
    const rg = defaultValue?.Documents?.Personal?.RG;
    const cnh = defaultValue?.Documents?.Personal?.CNH;
    if (type.value === 'RG') {
      setSelfieDoc(rg?.selfie ?? '');
      setFrontDoc(rg?.front ?? '');
      setBackDoc(rg?.back ?? '');
      setResidenceDoc(rg?.address ?? '');
    } else if (type.value === 'CNH') {
      setSelfieDoc(cnh?.selfie ?? '');
      setResidenceDoc(cnh?.address ?? '');
      setFrontDoc(cnh?.picture[0] ?? '');
      setBackDoc(cnh?.picture[1] ?? '');
    }
  };
  const handleDocumentTypeChange = (type: OptionsSelectProp) => {
    setDocumentType(type);
    handleDocumentChange(type);
  };

  return (
    <form
      className="flex flex-col mt-8 text-sm"
      onSubmit={handleSubmit(Submit)}
    >
      {defaultValue.Verification &&
        defaultValue.Verification.Reasons?.length > 0 && (
          <div>
            <AlertCard
              message={
                <div>
                  Pendências:{' '}
                  {defaultValue.Verification.Reasons.map((item: string) => (
                    <p>
                      {item}
                      <br />
                    </p>
                  ))}
                </div>
              }
            />
          </div>
        )}
      <div className="gap-3 flex flex-col md:grid  md:grid-cols-2 lg:grid-cols-4">
        <Input
          disabled={disabledEdit}
          variant="outline-orange"
          title="Renda Mensal"
          classNameDiv="lg:col-span-2"
          value={maskHelper.formatCurrencyInput(
            String(generalDataValue.Fields.monthlyIncome)
          )}
          onChange={(e) => {
            const formatedInput = Number(maskHelper.number(e.target.value));
            setGeralData({
              ...generalDataValue,
              Fields: {
                ...generalDataValue.Fields,
                monthlyIncome: formatedInput,
              },
            });
            setValue('Fields.monthlyIncome', formatedInput);
          }}
          errorMensage={formState.errors.Fields?.monthlyIncome?.message}
        />

        <Input
          disabled={disabledEdit}
          variant="outline-orange"
          title="Nome da Mãe"
          classNameDiv="lg:col-span-2"
          value={generalDataValue.Fields.motherName}
          onChange={(e) => {
            setGeralData({
              ...generalDataValue,
              Fields: {
                ...generalDataValue.Fields,
                motherName: e.target.value,
              },
            });
            setValue('Fields.motherName', e.target.value);
          }}
          errorMensage={formState.errors.Fields?.motherName?.message}
        />

        <Input
          disabled={disabledEdit}
          variant="outline-orange"
          title="Link de Mídia Social"
          value={generalDataValue.Fields.socialMediaLink}
          onChange={(e) => {
            setGeralData({
              ...generalDataValue,
              Fields: {
                ...generalDataValue.Fields,
                socialMediaLink: e.target.value,
              },
            });
            setValue('Fields.socialMediaLink', e.target.value);
          }}
          errorMensage={formState.errors.Fields?.socialMediaLink?.message}
        />

        <InputDate
          // className="col-span-2 lg:col-span-1"
          title="Data de Nascimento"
          disabled={disabledEdit}
          defaultValue={moment(generalDataValue.Fields.birthDate).toDate()}
          onChange={(e) => {
            if (e !== null) {
              setGeralData({
                ...generalDataValue,
                Fields: {
                  ...generalDataValue.Fields,
                  birthDate: maskHelper.formatDateYMD(
                    String(e.toLocaleString('pt-BR'))
                  ),
                },
              });
              setValue(
                'Fields.birthDate',
                maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
              );
            }
          }}
          errorMensage={formState.errors.Fields?.birthDate?.message}
        />

        <Input
          disabled={disabledEdit}
          variant="outline-orange"
          title="Sobre o Negócio"
          classNameDiv="lg:col-span-2"
          value={generalDataValue.Fields.about}
          onChange={(e) => {
            setGeralData({
              ...generalDataValue,
              Fields: { ...generalDataValue.Fields, about: e.target.value },
            });
            setValue('Fields.about', e.target.value);
          }}
          errorMensage={formState.errors.Fields?.about?.message}
        />

        <PersonalUpload
          disabledEdit={disabledEdit}
          residenceDoc={residenceDoc}
          setResidenceDoc={setResidenceDoc}
          documentType={documentType}
          optionsDocumentCompanie={optionsDocumentCompanie}
          handleDocumentTypeChange={handleDocumentTypeChange}
          backDoc={backDoc}
          frontDoc={frontDoc}
          selfieDoc={selfieDoc}
          setBackDoc={setBackDoc}
          setFrontDoc={setFrontDoc}
          setSelfieDoc={setSelfieDoc}
        />

        <div className="col-span-4 flex justify-center mt-6">
          <Button
            type="submit"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading || disabledEdit}
          >
            Salvar e continuar
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DocumentsPF;
