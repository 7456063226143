import { baseApi } from '../../../api/baseApi';
import { removeItem } from '../../../helpers/storage.helper';
import { deleteToken } from '../../../helpers/token.helper';

import {
  codeResponse,
  credentialsProps,
  verifyIdentityResponse,
} from '../types';

export async function logiParams(credentials: credentialsProps) {
  return baseApi({
    body: credentials,
    method: 'POST',
    url: 'session/auth/api',
  });
}

export async function login(credentials: credentialsProps) {
  return baseApi({
    body: credentials,
    method: 'POST',
    url: 'session/auth',
  });
}

export function logout() {
  deleteToken();
  removeItem('menu');
  removeItem('account_main_bearer_token');
  removeItem('account_main_menu');
}

export async function recoveryPass(email: string): Promise<boolean | string> {
  return baseApi({
    method: 'GET',
    url: `session/user/recovery-pass/${email}`,
  });
}

export async function validateIdentity(
  email: string,
  identity: string
): Promise<verifyIdentityResponse | string> {
  return baseApi({
    method: 'GET',
    url: `session/user/identity/${email}/${identity}`,
  });
}

export async function checkCode(
  code: number,
  id: number
): Promise<codeResponse | string> {
  return baseApi({
    method: 'GET',
    url: `session/user/code/${id}/${code}`,
  });
}

export async function sendEmail(email: string): Promise<boolean | string> {
  return baseApi({
    method: 'GET',
    url: `session/user/${email}`,
  });
}

export async function resetPassWord(
  id: number,
  password: string,
  confirmPassword: string
): Promise<boolean | string> {
  return baseApi({
    method: 'PUT',
    url: `session/user/change-pass/${id}`,
    body: {
      password,
      confirmPassword,
    },
  });
}
