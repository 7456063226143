import { baseApiPrivate } from '../../../../api/baseApi';
import { OpportunityProps } from '../../../opportunity/types';

async function getOpportunityReports(
  id: number,
  filterPeriod: string,
  props: any
): Promise<{ data: OpportunityProps[]; total: number } | string> {
  return baseApiPrivate({
    method: 'POST',
    body: props,
    url: `companie/opportunity/report/${id}/${filterPeriod}`,
  });
}

export const ApiOpportunityReports = {
  getOpportunityReports,
};
