import React, { useState } from 'react';
import {
  Button,
  DeleteModal,
  SelectComponent,
  Table,
} from '../../../../../components';
import IconDictionary from '../../../../../components/Icons/icons';
import { ToastNotify } from '../../../../../components/Toast/toast';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  dictionaryError,
  getContrastColorWithOpacity,
} from '../../../../../helpers/utils.helper';
import { ApiOpportunity } from '../../../api';
import { ContactOpportunity } from '../../../types';
import { useFetchContact } from '../../../../contacts/hooks';
import RegisterContact from '../../../../contacts/components/register';

/* eslint-disable no-unused-vars */
interface contactList {
  contacts: ContactOpportunity[];
  opportunityId: number;
  reloadOpportunity: (e: string) => void;
  companyUserId: number;
  isLoading: boolean;
}

const ContactOpportunityList: React.FC<contactList> = ({
  reloadOpportunity,
  contacts,
  opportunityId,
  companyUserId,
  isLoading,
}) => {
  const { notify } = ToastNotify();
  const [showDelete, setShowDelete] = useState(false);
  const [contactId, setContactId] = useState(-1);
  const [showCreateUpdate, setShowCreateUpdate] = useState({
    open: false,
    edit: false,
  });

  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const [currentContact, setCurrentContact] =
    useState<ContactOpportunity | null>(null);
  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: ContactOpportunity) => row.person.name,
    },
    {
      name: 'Organização',
      key: 'organization',
      selector: (row: ContactOpportunity) => row.person.organization,
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: ContactOpportunity) => row.person.whatsapp,
      cell: (row: ContactOpportunity) =>
        maskHelper.phone(row.person.whatsapp ?? ''),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: ContactOpportunity) => row.person.email,
      hide: 800,
    },
    {
      name: 'Tag',
      key: 'tag',
      width: '250px',
      selector: (row: ContactOpportunity) => (
        <div className="flex flex-wrap gap-2 my-2 w-64">
          {row.person.person_tag.map(
            (item) =>
              item.tag.color && (
                <div
                  style={{
                    backgroundColor: `${item.tag.color}`,
                    color: getContrastColorWithOpacity(item.tag.color),
                    border:
                      getContrastColorWithOpacity(item.tag.color) !== '#ffffff'
                        ? `1px solid ${getContrastColorWithOpacity(
                            item.tag.color
                          )}`
                        : '0px',
                  }}
                  className="p-2 rounded-sm truncate max-w-[100px]"
                >
                  {item.tag.title}
                </div>
              )
          )}
        </div>
      ),
      hide: 800,
    },
    contacts.length > 1 && {
      name: '',
      width: '50px',
      cell: (row: ContactOpportunity) => (
        <IconDictionary
          color="#DA3C3C"
          name="Excluir"
          size={20}
          className="cursor-pointer"
          onClick={() => {
            setCurrentContact(row);
            setShowDelete(true);
          }}
        />
      ),
    },
  ];

  const [inputContact, setInputContact] = useState('');
  const { ContactList, isLoadingContacts } = useFetchContact(
    'key',
    0,
    100000000,
    inputContact
  );

  const contactsOptions = ContactList.data.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const linkContactOpportunity = async (id?: number) => {
    if (showCreateUpdate.edit) {
      reloadOpportunity(`${Math.random()}`);

      notify({ message: 'Contato atualizado com sucesso!', type: 'Success' });
      setShowCreateUpdate({ edit: false, open: false });
    } else {
      const res = await ApiOpportunity.createContactOpportunity({
        person_id: id ?? contactId,
        opportunity_id: opportunityId,
      });
      if (res.person_id) {
        notify({ message: 'Contato adicionado com sucesso!', type: 'Success' });
        reloadOpportunity(`${Math.random()}`);
        // setShowCreateUpdate({ edit: false, open: false });
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  const updateFields = (id: number) => {
    const contact = ContactList.data.find((item) => item.id === id);
    if (contact) {
      setCurrentContact(
        {
          person: contact,
          person_id: contact.id,
          opportunity_id: opportunityId,
        } ?? null
      );
      setContactId(contact.id);
    }
  };

  return (
    <div className="w-full flex flex-col px-4">
      <RegisterContact
        companyId={companyUserId}
        contact={currentContact?.person ?? null}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        reloadTable={() => {}}
        onCreate={() => {
          if (!showCreateUpdate.edit && currentContact) {
            linkContactOpportunity(currentContact.person_id);
          }
        }}
        setContact={(e) => {
          setCurrentContact({
            opportunity_id: opportunityId,
            person_id: e.id,
            person: { ...currentContact?.person, ...e },
          });
        }}
        setShow={(e) => {
          setShowCreateUpdate({
            open: Boolean(e),
            edit: showCreateUpdate.edit,
          });
          reloadOpportunity(`${Math.random()}`);
        }}
        show={showCreateUpdate.open}
        outsideCreate
      >
        <>
          {!showCreateUpdate.edit && (
            <div className="mt-5">
              <SelectComponent
                title="Contatos"
                options={contactsOptions}
                closeMenuOnSelect
                onChange={(e: any) => updateFields(e.value)}
                onInputChange={setInputContact}
                autoComplete
                isLoading={isLoadingContacts}
              />
            </div>
          )}

          {currentContact === null && (
            <p className="text-sm font-medium -mb-5 mt-8">Criar contato</p>
          )}
        </>
      </RegisterContact>

      <Button
        className="w-52"
        actionType="button-add"
        variant="primary-strong"
        onClick={() => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setCurrentContact(null);
          setShowCreateUpdate({ edit: false, open: true });
        }}
      >
        Adicionar Contato
      </Button>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={contacts}
        selectedRowId={-1}
        onRowClick={(e: ContactOpportunity) => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setCurrentContact(e);
          setContactId(e.person_id);
          setShowCreateUpdate({ edit: true, open: true });
        }}
      />
      {currentContact && (
        <DeleteModal
          apiFunction={() =>
            ApiOpportunity.deleteContactOpportunity(
              opportunityId,
              currentContact.person.id
            )
          }
          notify={notify}
          type="Contato"
          article="O"
          updateTable={() => reloadOpportunity(`${Math.random()} 'delete'`)}
          close={() => setShowDelete(false)}
          id={currentContact.person_id}
          name={currentContact.person.name}
          open={showDelete}
        />
      )}
    </div>
  );
};

export default ContactOpportunityList;
