import { useEffect, useState } from 'react';
import { ApiUsers } from '../api';
import { accessUser, UserProps } from '../types';

export function useFetchUsersCompanie(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies =
        companyId !== -1 ? await ApiUsers.getUsersByCompany(companyId) : [];
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { UsersList: data, isLoading, setIsLoading };
}

export function useFetchAccess(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<accessUser[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiUsers.getAccess(id);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AccessList: data, isLoadingAccessList: isLoading };
}
