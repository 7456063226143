import React, { useState } from 'react';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { Button, Card, Spinner } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { tokenModel } from '../../../types';
import Register from '../../admin/GalaxPay/SubAccounts/components/register';
import { useFetchCompanieById } from '../hooks';
import ModalCompanyType from '../../admin/GalaxPay/SubAccounts/components/ModalCompanyType';
import { associateAccount, formatStatusVerification } from '../utils';

const CompanieGalaxAccount: React.FC = () => {
  const userProps: tokenModel = getTokenAccountInformation();
  const [key, setKey] = useState('key');

  const [action, setAction] = useState<'PF' | 'PJ' | ''>('');

  const { GalaxAccount, setGalaxAccount, isLoading, messError } =
    useFetchCompanieById(userProps.companie.companie_id_galax, key, setAction);

  const StatusVerification = formatStatusVerification(
    GalaxAccount?.Verification?.status
  );

  const [modalAlert, setModalAlert] = useState(false);
  const [modalTypeCompany, setmodalTypeCompany] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });
  return (
    <>
      <div className="flex gap-3 items-center text-primary">
        <IconDictionary name="Conta no Banco" size={24} />
        <strong className="text-lg text-black">Conta no Banco</strong>
      </div>
      {GalaxAccount && (
        <div className="mt-12 flex">
          <div className="text-matterhorn border-2 bg-white border-solid p-4 w-16 h-16 flex items-center justify-center rounded border-gray-300 mr-3">
            <IconDictionary name="Banco" />
          </div>
          <div className="flex flex-col gap-1 text-xs">
            <div className="flex gap-1">
              <p>{GalaxAccount.nameDisplay}</p>
            </div>
            <div className="flex gap-1">
              <strong>Status:</strong>
              <p
                className={
                  GalaxAccount.active
                    ? 'font-medium text-green'
                    : 'text-gray-600'
                }
              >
                {GalaxAccount.active ? 'Ativa' : 'Inativa'}
              </p>
            </div>

            <div className="flex gap-1">
              <strong>Verificação:</strong>

              <p className={`font-medium ${StatusVerification.color}`}>
                {StatusVerification.status}
              </p>
            </div>
          </div>
        </div>
      )}
      <Card className="relative text-sm mt-6 flex-col relative max-w-full min-h-[85vh] mb-5 sm:mb-0 pb-8">
        <br />

        {isLoading && (
          <div className="absolute flex items-center justify-center left-0 top-0 w-full h-full opacity-60 bg-gray-200">
            <Spinner />
          </div>
        )}

        {!GalaxAccount &&
          action === '' &&
          !isLoading &&
          (messError !== '' ? (
            <p className="text-gray-600 text-center pt-20 mx-auto">
              {messError}
            </p>
          ) : (
            <>
              <p className="text-gray-600 text-center pt-20 mx-auto">
                Nenhuma Conta no Banco encontrada
                <br />
                Crie uma conta e integre seu financeiros com o banco.
              </p>
              <Button
                className="mx-auto mt-10"
                onClick={() => {
                  setRegisterPage({ page: 0, title: 'Dados da Conta' });

                  setmodalTypeCompany(true);
                }}
                variant="primary-strong"
                actionType="button-add"
              >
                Criar Conta
              </Button>
            </>
          ))}
        {action !== '' && (
          <div className="-mt-8 px-8 w-full 2xl:mx-auto 2xl:w-1/2">
            <Register
              setModalAlert={setModalAlert}
              modalAlert={modalAlert}
              setIsEdited={() => {}}
              associateCompanieGalax={async (id) =>
                await associateAccount(id, userProps.companie.id)
              }
              setCompanyDefault={setGalaxAccount}
              action={action}
              companyDefault={GalaxAccount}
              reloadTable={setKey}
              registerPage={registerPage}
              setRegisterPage={setRegisterPage}
              setShow={() => {}}
            />
          </div>
        )}
      </Card>
      <ModalCompanyType
        type={action}
        show={modalTypeCompany}
        confirm={() => {
          setmodalTypeCompany(false);
        }}
        setType={setAction}
        setShow={setmodalTypeCompany}
      />
      <br />
    </>
  );
};

export default CompanieGalaxAccount;
