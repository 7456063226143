import React from 'react';

import { useWppContext } from '../context';

import IconDictionary from '../../../components/Icons/icons';
import { Button } from '../../../components';

import InstanceIcon from '../assets/Exclude.svg';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setModalPurchaseCancel: (a: 'cancel' | 'purchase') => void;
}

export const InstanceCard: React.FC<Props> = ({
  setModalPurchaseCancel,
}: Props) => {
  const { instances, whatsappPlans } = useWppContext();

  return (
    <div className="flex flex-col md:flex-row justify-between gap-3">
      <div className="flex items-center bg-background w-full md:w-[350px] px-3.5">
        <div className="flex items-center justify-center rounded-full min-w-[50px] min-h-[50px]">
          <IconDictionary color="#E63B2E" name="Database" size={30} />
        </div>

        <div className="p-2 w-[200px]">
          <strong className="text-sm font-medium">
            Você possui <br />
            <strong className="text-sm text-test_orange">
              {instances.length} Instância{instances.length === 1 ? '' : 's'}
            </strong>
          </strong>
        </div>

        <div className="p-2 w-full">
          {/* <Button
            typEstamos aguardando a confirmacao do pagamento.
Assim que for realizado será desbloqueada a criação de novas instâncias.e="submit"
            disabled={whatsappPlans.disablePurchase || listPlan.length === 0}
            actionType="button-loading"
            className="w-full bg-primary my-1"
            onClick={() => setModalPurchaseCancel('purchase')}
          >
            <strong className="text-sm font-medium">Comprar mais</strong>
          </Button> */}
          <Button
            type="submit"
            variant="outline-primary"
            actionType="button-loading"
            className="w-full bg-white my-1"
            onClick={() => setModalPurchaseCancel('cancel')}
          >
            <strong className="text-sm font-medium">Cancelar</strong>
          </Button>
        </div>
      </div>

      {Object.keys(whatsappPlans).length > 0 && (
        <div className="flex items-center bg-primary w-full md:w-[302px] px-3.5 rounded-sm">
          <img className="mr-3" src={InstanceIcon} alt="svg" />
          <div>
            <p className="text-sm text-white">
              <strong>Plano</strong>: {whatsappPlans.whatsapp_plans?.name}
            </p>
            <p className="text-sm text-white">
              <strong>Status</strong>: {whatsappPlans.status}
            </p>
            <p className="text-sm text-white">
              <strong>Máximo</strong>: {whatsappPlans.qty} Instâncias
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
