import { setItem } from '../../../helpers/storage.helper';
import { saveToken } from '../../../helpers/token.helper';

export function saveParamLocal(response: any) {
  saveToken(response.token);
  setItem(
    'menu',
    response.menus.filter((item: any) => item !== null)
  );
  if (response.companie !== undefined)
    setItem(
      'generate_contract_opp',
      response.companie.is_generate_contract_opp
    );
}
