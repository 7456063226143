import React, { useState } from 'react';
import { InputDate } from '../../../../../components';

interface props {
  text: string;
  // eslint-disable-next-line no-unused-vars
  setText: (e: string) => void;
  onFocus: () => void;
  index: string;
}

const DateQuestion: React.FC<props> = ({ setText, onFocus, text, index }) => {
  const [style, setStyle] = useState<{
    title: string;
    active: boolean;
    question: { id: number; title: string; active: boolean }[];
  }>({ title: '', active: false, question: [] });
  return (
    <div className="flex flex-col gap-2 text-sm w-2/3">
      <input
        type="text"
        id={`${index}title`}
        defaultValue={text}
        onFocus={() => {
          setStyle({ ...style, active: true });
          onFocus();
        }}
        onBlur={(e) => setText(e.target.value)}
        className={`${style.title} focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
      />
      <div className="w-48 mt-3 mb-8">
        <InputDate disabled />
      </div>
    </div>
  );
};

export default DateQuestion;
