import { baseApiPrivate } from '../../../../api/baseApi';

export default {
  get: async () => baseApiPrivate({ method: 'GET', url: 'admin/partners' }),

  post: async (body: any) =>
    baseApiPrivate({ method: 'POST', url: 'admin/partners', body }),

  put: async (id: number, body: any) =>
    baseApiPrivate({ method: 'PUT', url: `admin/partners/${id}`, body }),

  delete: async (id: number) =>
    baseApiPrivate({ method: 'DELETE', url: `admin/partners/${id}` }),
};
