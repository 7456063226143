/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { ApiClient } from '../api';
import {
  ClientProps,
  attachment,
  historic,
  payloadBackClients,
} from '../types';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

export function useFetchClientDropdown(
  key: string,
  skip: number,
  take: number,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackClients>({ data: [], total: 0 });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value.length >= 3) {
        const res = await ApiClient.seachClients(value, skip, take);
        if (typeof res !== 'string') {
          setData(res);
        }
      } else {
        setData({ data: [], total: 0 });
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, skip, take, value]);
  return {
    ClientsList: data,
    isLoadingClientsDropDown: isLoading,
    setClientsList: setData,
    setIsLoadingTable: setIsLoading,
  };
}

export function useFetchClientsList(
  key: string,
  companyId: number,
  skip: number,
  take: number,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackClients>({ data: [], total: 0 });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (value.length >= 3) {
        const res = await ApiClient.seachClients(value, skip, take);
        if (typeof res !== 'string') {
          setData(res);
        }
      } else if (value === '') {
        const companies = await ApiClient.getClientByCompanie(
          companyId,
          skip,
          take
        );
        if (typeof companies !== 'string') {
          setData(companies);
        }
      }

      setIsLoading(false);
    };

    FetchCompany();
  }, [key, skip, take, value]);
  return {
    ClientsList: data,
    isLoading,
    setClientsList: setData,
    setIsLoadingTable: setIsLoading,
  };
}

export function useFetchAttachments(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<attachment[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      if (id !== -1) {
        const companies =
          id === -1 ? [] : await ApiClient.getClientAttachments(id);
        if (typeof companies !== 'string') {
          setData(companies);
        }
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { AttachmentsList: data, isLoadingAttachmentsList: isLoading };
}

export function useFetchHistoric(key: string, id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<historic[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const historicData = await ApiClient.getClientHistoric(id);
      if (typeof historicData !== 'string') {
        setData(historicData);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { historicList: data, isLoadingHistoricList: isLoading };
}

export function useFetchAllProviders(key: string) {
  const companyId = getTokenAccountInformation().companie_id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiClient.getAllProviders(companyId);
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ProviderList: data, isLoading };
}
export function useFetchAllProducts(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiClient.getAllProducts();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { ProductsList: data, isLoading };
}
export function useFetchAllHotels(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const companies = await ApiClient.getAllHotels();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { HotelsList: data, isLoading };
}
