import React, { useState } from 'react';
import { formPropsPage } from '../../../types';
import { ApiCompany } from '../../../api';
import {
  Button,
  Input,
  SelectComponent,
  Table,
} from '../../../../../../components';
import maskHelper from '../../../../../../helpers/mask.helper';
import IconDictionary from '../../../../../../components/Icons/icons';
import { useFetchAccountsGalax } from '../../../hooks';
import { SubAccountFullProps } from '../../../../GalaxPay/SubAccounts/types';

/* eslint-disable no-unused-vars */

const Payments: React.FC<formPropsPage> = ({
  id,
  nextPage,
  setId,
  defaultValue,
  notify,
}) => {
  const accountIntegrationOptions = () => {
    if (defaultValue) {
      if (defaultValue.is_galaxpay === true) {
        return 0;
      }
      if (defaultValue.is_maistodos === true) {
        return 1;
      }
    }
    return null;
  };
  const [key, setKey] = useState('key');
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [integrationPayment, setIntegrationPayment] = useState<number | null>(
    accountIntegrationOptions()
  );
  const [selectedAccount, setSelectedAccount] = useState<number>(
    defaultValue?.companie_id_galax ?? -1
  );
  const updateGalaxAccount = async (idAccountGalax: number) => {
    const res = await ApiCompany.updatePaymentAccount(
      {
        companie_id_galax: idAccountGalax,
        companie_id_maistodos: null,
        is_galaxpay: true,
        is_maistodos: false,
      },
      id
    );
    if (res.id) {
      notify({ message: 'Conta salva com sucesso', type: 'Success' });
      setIsLoading(false);
      setId({ ...res });
    } else {
      setIsLoading(false);
      notify({ message: 'Desculpe, ocorreu um erro', type: 'Error' });
    }
  };

  const { galaxAccountList, isLoadingAsaas } = useFetchAccountsGalax(key);
  const column = [
    {
      name: 'Galax ID',
      key: 'galax_id',
      selector: (row: SubAccountFullProps) => row.id,
      cell: (row: SubAccountFullProps) => <div>{row.id}</div>,
    },
    {
      name: 'Nome',
      key: 'name',
      selector: (row: SubAccountFullProps) => row.name,
      cell: (row: SubAccountFullProps) => <div>{row.name}</div>,
    },
    {
      name: 'Galax Hash',
      key: 'galax_hash',
      selector: (row: SubAccountFullProps) => row.ApiAuth.galaxHash,
      cell: (row: SubAccountFullProps) => <div>{row.ApiAuth.galaxHash}</div>,
    },
    {
      name: 'Galax Documento',
      key: 'document',
      selector: (row: SubAccountFullProps) => maskHelper.cnpjCpf(row.document),
    },
    {
      name: 'Status',
      key: 'use_partner',
      selector: (row: SubAccountFullProps) =>
        row.active === true ? 'Ativo' : 'Inativo',
    },
    {
      name: '',
      key: 'name',
      cell: (row: SubAccountFullProps) => (
        <div>
          <input
            type="radio"
            className="text-primary w-10"
            checked={row.id === selectedAccount}
            onChange={() => {
              setSelectedAccount(row.id);
              updateGalaxAccount(row.id);
            }}
          />
        </div>
      ),
    },
  ];
  const options = [
    { label: 'GalaxPay', value: 0, isdisabled: false },
    { label: 'Maistodos', value: 1, isdisabled: true },
  ];

  const filterTable = () => {
    if (searchInput === '') {
      return galaxAccountList;
    }
    return galaxAccountList.filter(
      (item: SubAccountFullProps) =>
        item.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        item.document.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        item.ApiAuth.galaxHash
          .toLowerCase()
          .indexOf(searchInput.toLowerCase()) > -1
    );
  };

  return (
    <div className="flex flex-col gap-4 mt-8">
      <SelectComponent
        value={options.find((item) => item.value === integrationPayment)}
        closeMenuOnSelect
        isMulti={false}
        options={options}
        title="Deseja integrar o financeiro com qual Gateway?"
        onChange={(e: any) => {
          setIntegrationPayment(e.value);
        }}
      />
      {integrationPayment !== null && (
        <>
          <Input
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Pesquisar contas"
            variant="outline-orange"
            className="-mb-4 z-10 w-56"
            clearField={
              searchInput !== '' && (
                <IconDictionary
                  name="Excluir"
                  color="#dadada"
                  className="cursor-pointer"
                  onClick={() => setSearchInput('')}
                />
              )
            }
            icon={<IconDictionary name="Lupa" color="#dadada" />}
          />
          <Table
            scrollTable
            fixedHeaderScrollHeight="280px"
            columns={column}
            data={filterTable()}
            isLoading={isLoadingAsaas}
          />
        </>
      )}

      <div className="col-span-3 flex justify-center mt-6">
        <Button
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => nextPage()}
        >
          {defaultValue
            ? defaultValue?.is_franchise_matriz
              ? 'Salvar e continuar'
              : 'Salvar'
            : 'Salvar'}
        </Button>
      </div>
    </div>
  );
};

export default Payments;
