import { baseApiPrivate } from '../../../../api/baseApi';
import { ApiProps, AdminProps } from '../types';

export const ApiParameters: ApiProps = {
  administrator: async () =>
    await baseApiPrivate({ method: 'GET', url: 'admin/administrator' }),

  companie: async () =>
    await baseApiPrivate({ method: 'GET', url: 'admin/companie' }),

  accounts: async (id: number) =>
    await baseApiPrivate({
      method: 'GET',
      url: `companie/plan-accounts/${id}`,
    }),

  put: async (body: Omit<AdminProps, 'id'>, id: number) =>
    await baseApiPrivate({
      method: 'PUT',
      url: `admin/administrator/${id}`,
      body,
    }),
};
