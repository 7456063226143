import React from 'react';
import IconDictionary from '../Icons/icons';

const OutConnection: React.FC = () => (
  <div className="w-screen h-screen fixed z-50 top-0 bg-gray-200 text-sm flex flex-col items-center justify-center">
    <IconDictionary name="Desconectado" size={40} />
    <h3 className="font-medium my-2 text-xl">Sem conexão a internet</h3>
    <p className="mb-5">
      Verifique sua conexão com a internet. Certifique-se de que o Wi-Fi
      <br /> está ativado e o modo avião está desligado. Por favor, tente
      novamente.
    </p>
    <button
      type="button"
      className="bg-gray-600 w-48 py-3 text-white text-xs font-medium rounded hover:opacity-80"
      onClick={() => window.location.reload()}
    >
      Recarregar a página
    </button>
  </div>
);

export default OutConnection;
