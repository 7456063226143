import React, { useEffect, useState } from 'react';
import { Button, SelectComponent, Spinner } from '../../../../../../components';
import { ToastNotify } from '../../../../../../components/Toast/toast';
import { dictionaryError } from '../../../../../../helpers/utils.helper';
import { Group } from '../../../../group/types';
import { MenuFullProps } from '../../../../menu/types';
import { Profile } from '../../../../profile/types';
import { ApiCompany } from '../../../api';
import { useFetchMenusCompanieProfile } from '../../../../../releaseMenu/hooks';

interface ReleaseMenuProps {
  companyId: number;
  nextPage: () => void;
  MenuList: MenuFullProps[];
  GroupList: Group[];
  ProfileList: Profile[];
  isFranchisor: boolean;
}
const ReleaseMenu: React.FC<ReleaseMenuProps> = ({
  ProfileList,
  nextPage,
  companyId,
  GroupList,
  MenuList,
  isFranchisor,
}) => {
  const { notify } = ToastNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(-1);
  const [key, setKey] = useState('key');

  const { MenusCompanieProfileList, isLoadingMenuList } =
    useFetchMenusCompanieProfile(key, profile, companyId);

  const listOfMenus = MenusCompanieProfileList.map((item) => item.menu_id);
  const [menusID, setMenusID] = useState<number[]>(listOfMenus);

  const optionFranchisor = ProfileList.filter((item) => item.is_franchisor).map(
    (item) => ({ label: item.title, value: item.id, isdisabled: false })
  );
  const optionFranchise = ProfileList.filter((item) => item.is_franchise).map(
    (item) => ({ label: item.title, value: item.id, isdisabled: false })
  );

  const AddRemoveIdList = (id: number) => {
    if (menusID.includes(id)) {
      setMenusID(menusID.filter((item) => item !== id));
    } else {
      setMenusID([...menusID, id]);
    }
  };

  const Submit = async () => {
    if (profile === -1) {
      nextPage();
    } else {
      setIsLoading(true);
      const res = await ApiCompany.updateMenuCompany(
        {
          menus: menusID,
          profile_id: profile,
        },
        companyId
      );
      if (res === true) {
        setIsLoading(false);
        notify({ message: 'Menus liberados com sucesso', type: 'Success' });
        nextPage();
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  const FilterMenus = (menus: MenuFullProps[]) => {
    const menusByCompanyType =
      isFranchisor === true
        ? menus.filter(
            (item) =>
              item.is_franchisor === true &&
              !item.route_frontend.includes('-admin')
          )
        : menus.filter(
            (item) =>
              item.is_franchise === true &&
              !item.route_frontend.includes('-admin')
          );
    return menusByCompanyType;
  };

  useEffect(() => {
    const menus = MenusCompanieProfileList.map((item) => item.menu_id);
    setMenusID(menus);
  }, [MenusCompanieProfileList]);

  return (
    <div className="flex flex-col mt-8">
      <SelectComponent
        title="Liberar Menu para o perfil"
        closeMenuOnSelect
        options={isFranchisor ? optionFranchisor : optionFranchise}
        onChange={(e: any) => {
          setProfile(e.value);
          setKey(`${Math.random()} menu`);
        }}
      />
      {!isLoadingMenuList ? (
        profile !== -1 && (
          <div className="flex flex-col h-[50vh] overflow-y-auto">
            {MenuList.length > 0 && (
              <>
                <p className="font-bold mt-3 mb-2">Sem Módulo</p>
                {FilterMenus(
                  MenuList.filter((item) => item.group_id === null)
                )?.map((item) => (
                  <button
                    type="button"
                    onClick={() => AddRemoveIdList(item.id)}
                    className="flex w-fit mb-1 items-center"
                  >
                    <input
                      value={item.id}
                      type="checkbox"
                      className="accent-primary"
                      checked={menusID.includes(item.id)}
                      onChange={() => AddRemoveIdList(item.id)}
                    />
                    <p className="ml-2 text-sm">{item.title}</p>
                  </button>
                ))}
              </>
            )}
            <div className="flex flex-col my-3">
              {GroupList.map(
                (item) =>
                  FilterMenus(item.menu ?? []).length !== 0 && (
                    <>
                      <p className="font-bold">
                        {item.module?.title} - {item.title}
                      </p>

                      <div className="grid grid-cols-2 mt-2 mb-4">
                        {FilterMenus(item.menu ?? [])?.map((menus) => (
                          <button
                            type="button"
                            onClick={() => AddRemoveIdList(menus.id)}
                            className="flex w-fit mb-1 items-center"
                          >
                            <input
                              value={menus.id}
                              checked={menusID.includes(menus.id)}
                              type="checkbox"
                              className="accent-primary"
                              onChange={() => AddRemoveIdList(menus.id)}
                            />
                            <p className="ml-2 text-sm">{menus.title}</p>
                          </button>
                        ))}
                      </div>
                    </>
                  )
              )}
            </div>
          </div>
        )
      ) : (
        <div className="w-full flex items-center justify-center h-[40vh]">
          <Spinner />
        </div>
      )}

      <Button
        variant="primary"
        className="font-bold relative flex items-center justify-center mt-4 w-64 mx-auto"
        onClick={Submit}
        disabled={isLoading}
        isLoading={isLoading}
      >
        Salvar e continuar
      </Button>
    </div>
  );
};

export default ReleaseMenu;
