import React, { ComponentPropsWithRef } from 'react';

import IconDictionary from '../../../components/Icons/icons';

type CardProps = ComponentPropsWithRef<'div'>;
interface props extends CardProps {
  disabled: boolean;
  finished: boolean;
}
const CardRounded: React.FC<props> = ({ children, finished, disabled }) => (
  <div
    className={`relative bg-white flex flex-col rounded-2xl border border-solid ${
      disabled ? 'border-gray-400' : 'border-[#02a2c4]'
    } w-full md:w-2/5 xl:w-1/3 h-fit px-8 pt-10 pb-5`}
  >
    <div className="absolute top-[42px] right-8">
      {finished ? (
        <IconDictionary name="Check" className="text-green-400" size={24} />
      ) : (
        <IconDictionary
          name="Seta baixo"
          className={disabled ? 'text-gray-600' : 'text-green-400'}
        />
      )}
    </div>
    {children}
  </div>
);

export default CardRounded;
