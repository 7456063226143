import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Input, SelectComponent } from '../../../../../components';
import InputDate from '../../../../../components/Input/inputDate';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import { ApiClient } from '../../../api';
import {
  ClientProps,
  generalData,
  UpdateClient,
  updateformProps,
} from '../../../types';
import { getTokenAccountInformation } from '../../../../../helpers/token.helper';

interface props extends updateformProps {
  onePage?: boolean;
  // eslint-disable-next-line no-unused-vars
  setClient?: (e: ClientProps) => void;
}

const GeneralDataClient: React.FC<props> = ({
  defaultValue,
  id,
  nextPage,
  notify,
  setId,
  setClient,
  onePage = false,
}) => {
  const accountProps = getTokenAccountInformation();
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<generalData>({
    birth: defaultValue?.birth
      ? new Date(`${defaultValue?.birth.slice(0, 10)} `)
      : null,
    cellular: defaultValue?.cellular ?? '',
    civil_status: defaultValue?.civil_status ?? '',
    identity: defaultValue?.identity ?? '',
    name_fantasy: defaultValue?.name_fantasy ?? '',
    nationality: defaultValue?.nationality ?? '',
    passport: defaultValue?.passport ?? '',
    passport_due: defaultValue?.passport_due?.slice(0, 10) ?? null,
    phone: defaultValue?.phone ?? '',
    status: defaultValue?.status ?? 'ativo',
    document: defaultValue?.document ?? '',
    email: defaultValue?.email ?? '',
    name: defaultValue?.name ?? '',
    whatsapp: defaultValue?.whatsapp ?? '',
    gender: defaultValue?.gender ?? '',
    companie_user_id: id,
  });

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    name_fantasy: yup.string(),
    birth: yup
      .date()
      .nullable(true)
      .max(yesterdayDate, 'Data deve ser menor que hoje'),
    document: yup
      .string()
      .required('CPF/CNPJ é obrigatório')
      .min(11, 'Campo deve conter ao menos 11 dígitos')
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) =>
          value
            ? cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            : false,
      }),

    phone: yup.string().test({
      message: 'Número deve conter 10 dígitos',
      test: (value) => {
        if (value === '' || typeof value === 'undefined') return true;
        if (value?.length !== 10) return false;
        return true;
      },
    }),

    cellular: yup
      .string()
      .test({
        message: 'Número deve conter 11 dígitos',
        test: (value) => {
          if (value === '' || typeof value === 'undefined') return true;
          if (value?.length !== 11) return false;
          return true;
        },
      })
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),

    whatsapp: yup
      .string()
      .required('WhatsApp é obrigatório')
      .min(11, 'Campo deve conter 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),

    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    passport: yup.string().nullable(true),
    passport_due: yup
      .date()
      .nullable(true)
      .min(yesterdayDate, 'Data deve ser igual ou maior que hoje'),
    status: yup.string().required('Selecione uma opção'),
  });

  const generalValues: UpdateClient | null = {
    birth: defaultValue?.birth?.slice(0, 10),
    civil_status: defaultValue?.civil_status ?? '',
    gender: defaultValue?.gender,
    identity: defaultValue?.identity,
    nationality: defaultValue?.nationality,
    passport: defaultValue?.passport,
    passport_due: defaultValue?.passport_due?.slice(0, 10),
    status: defaultValue?.status ?? 'ativo',
    document: defaultValue?.document ?? '',
    email: defaultValue?.email ?? '',
    name: defaultValue?.name ?? '',
    whatsapp: defaultValue?.whatsapp ?? '',
    cellular: defaultValue?.cellular,
    name_fantasy: defaultValue?.name_fantasy ?? '',
    phone: defaultValue?.phone,
  };

  const { setValue, handleSubmit, formState, getValues } =
    useForm<UpdateClient>({
      resolver: yupResolver(schema),
      defaultValues: clearNullValuesObject(generalValues),
    });
  const Submit = async (e: UpdateClient) => {
    if (defaultValue !== null) {
      setIsLoading(true);
      delete defaultValues.companie_user_id;
      const res = await ApiClient.updateGeneralData(
        {
          ...defaultValues,
          birth: getValues('birth'),
        },
        defaultValue.id
      );
      if (res.id) {
        setIsLoading(false);
        if (!onePage)
          notify({
            message: 'Cliente atualizado com sucesso!',
            type: 'Success',
          });
        if (setClient) setClient(res);
        nextPage();
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const res = await ApiClient.createClientGeneralData({
        ...e,
        birth: getValues('birth'),
        passport_due: getValues('passport_due')?.slice(0, 10),
        companie_user_id: accountProps.id,
      });
      if (res.id) {
        setId(res.id);
        setIsLoading(false);
        if (!onePage)
          notify({ message: 'Cliente criado com sucesso!', type: 'Success' });
        if (setClient) setClient(res);
        nextPage();
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    }
  };

  const socialStatus = [
    { value: 'solteiro', label: 'Solteiro(a)' },
    { value: 'casado', label: 'Casado(a)' },
    { value: 'viuvo', label: 'Viúvo(a)' },
    { value: 'divorciado', label: 'Divorciado(a)' },
  ];

  const category = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
  ];

  const status = [
    { value: 'ativo', label: 'Ativo' },
    { value: 'inativo', label: 'Inativo' },
  ];

  return (
    <form
      className="grid grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
      onSubmit={handleSubmit(Submit)}
    >
      <Input
        variant="outline-orange"
        title="Nome / Razão Social"
        value={defaultValues.name}
        className="col-span-3 md:col-span-1"
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, name: e.target.value });
          setValue('name', e.target.value);
        }}
        errorMensage={formState.errors.name?.message}
      />
      <Input
        title="Apelido / Nome Fantasia"
        variant="outline-orange"
        className="col-span-3 md:col-span-1"
        value={defaultValues.name_fantasy}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, name_fantasy: e.target.value });
          setValue('name_fantasy', e.target.value);
        }}
        errorMensage={formState.errors.name_fantasy?.message}
      />
      <div className="flex flex-col sm:grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 sm:flex-row gap-x-3 gap-y-6 col-span-2 lg:col-span-2">
        <InputDate
          className="col-span-2 lg:col-span-1"
          title="Data de Nascimento"
          defaultValue={defaultValues.birth ?? undefined}
          onChange={(e: Date) => {
            if (e !== null)
              setValue(
                'birth',
                maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
              );
          }}
          errorMensage={formState.errors.birth?.message}
        />
        <Input
          title="CPF / CNPJ"
          variant="outline-orange"
          className="md:col-span-2 lg:col-span-1"
          type="text"
          value={maskHelper.cnpjCpf(defaultValues.document)}
          placeholder="000.000.000-00"
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              document: e.target.value,
            });
            setValue('document', maskHelper.numberCPFCNPJ(e.target.value));
          }}
          errorMensage={formState.errors.document?.message}
        />
        <Input
          title="RG"
          variant="outline-orange"
          className="md:col-span-2 lg:col-span-1"
          type="text"
          value={defaultValues.identity}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              identity: e.target.value,
            });
            setValue('identity', e.target.value);
          }}
          errorMensage={formState.errors.identity?.message}
        />

        <SelectComponent
          title="Estado Civil"
          isMulti={false}
          closeMenuOnSelect
          options={socialStatus}
          value={socialStatus.find(
            (item) => item.value === defaultValues.civil_status
          )}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              civil_status: e.value,
            });
            setValue('civil_status', e.value);
          }}
          errorMensage={formState.errors.civil_status?.message}
        />

        <SelectComponent
          title="Sexo"
          closeMenuOnSelect
          options={category}
          value={category.find((item) => item.value === defaultValues.gender)}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              gender: e.value,
            });
            setValue('gender', e.value);
          }}
          errorMensage={formState.errors.gender?.message}
        />
      </div>

      <div className="flex flex-col col-span-2 sm:grid grid-cols-4 gap-3">
        {' '}
        <Input
          title="Nacionalidade"
          variant="outline-orange"
          type="text"
          value={defaultValues.nationality}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              nationality: e.target.value,
            });
            setValue('nationality', e.target.value);
          }}
          errorMensage={formState.errors.nationality?.message}
        />
        <Input
          title="Telefone Fixo"
          variant="outline-orange"
          max={15}
          type="text"
          placeholder="(00) 0000-0000"
          value={maskHelper.phoneFix(defaultValues.phone ?? '')}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              phone: maskHelper.number(e.target.value),
            });
            setValue('phone', maskHelper.numberPhone(e.target.value));
          }}
          errorMensage={formState.errors.phone?.message}
        />
        <Input
          title="Telefone Celular"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          type="text"
          value={maskHelper.phone(defaultValues.cellular ?? '')}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              cellular: maskHelper.numberPhone(e.target.value),
            });
            setValue('cellular', maskHelper.numberPhone(e.target.value));
          }}
          errorMensage={formState.errors.cellular?.message}
        />
        <Input
          title="WhatsApp"
          variant="outline-orange"
          placeholder="(00) 00000-0000"
          max={15}
          type="text"
          value={maskHelper.phone(defaultValues.whatsapp)}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              whatsapp: maskHelper.numberPhone(e.target.value),
            });
            setValue('whatsapp', maskHelper.numberPhone(e.target.value));
          }}
          errorMensage={formState.errors.whatsapp?.message}
        />
      </div>
      <div className="col-span-2 flex flex-col sm:grid grid-cols-2 lg:grid-cols-4 gap-3">
        <Input
          title="E-mail"
          variant="outline-orange"
          type="text"
          value={defaultValues.email}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              email: e.target.value,
            });
            setValue('email', e.target.value);
          }}
          errorMensage={formState.errors.email?.message}
        />
        <Input
          title="Passaporte"
          variant="outline-orange"
          type="number"
          value={defaultValues.passport}
          onChange={(e) => {
            setDefaultValues({
              ...defaultValues,
              passport: e.target.value,
            });
            setValue('passport', e.target.value);
          }}
          errorMensage={formState.errors.passport?.message}
        />

        <InputDate
          defaultValue={
            defaultValues.passport_due
              ? new Date(`${defaultValues.passport_due} `)
              : undefined
          }
          onChange={(e: any) => {
            if (e !== null) {
              setDefaultValues({
                ...defaultValues,
                passport_due: maskHelper.formatDateYMD(
                  e.toLocaleDateString('pt-br')
                ),
              });
              setValue(
                'passport_due',
                maskHelper.formatDateYMD(e.toLocaleDateString('pt-br'))
              );
            } else {
              setValue('passport_due', undefined);
              setDefaultValues({
                ...defaultValues,
                passport_due: undefined,
              });
            }
          }}
          title="Data Vencimento Passaporte"
          errorMensage={formState.errors.passport_due?.message}
        />
        <SelectComponent
          title="Status"
          closeMenuOnSelect
          options={status}
          value={status.find((item) => item.value === defaultValues.status)}
          onChange={(e: any) => {
            setDefaultValues({
              ...defaultValues,
              status: e.value,
            });
            setValue('status', e.value);
          }}
          errorMensage={formState.errors.status?.message}
        />
      </div>
      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {onePage ? 'Salvar' : 'Salvar e continuar'}
        </Button>
      </div>
    </form>
  );
};

export default GeneralDataClient;
