import { baseApiPrivate } from '../../../api/baseApi';
import { WhatsappTagProps } from '../types';

async function getWhatsappTagByCompany() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/tag`,
  });
}

async function createWhatsappTag(e: Omit<WhatsappTagProps, 'id'>) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/tag',
    body: e,
  });
}

async function updateWhatsappTag(e: Omit<WhatsappTagProps, 'id'>, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/tag/${id}`,
    body: e,
  });
}

async function deleteWhatsappTag(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/tag/${id}`,
  });
}

export const ApiWhatsappTag = {
  getWhatsappTagByCompany,
  createWhatsappTag,
  updateWhatsappTag,
  deleteWhatsappTag,
};
