import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */

interface props {
  text: string;
  // eslint-disable-next-line no-unused-vars
  setText: (e: string) => void;
  isActive: boolean;
  onFocus: () => void;
  index: string;
  type: 'short' | 'textarea';
}

const ShortParagraphAnswer: React.FC<props> = ({
  setText,
  onFocus,
  text,
  isActive,
  index,
  type,
}) => {
  const [style, setStyle] = useState<{
    title: string;
    active: boolean;
    question: { id: number; title: string; active: boolean }[];
  }>({ title: '', active: isActive, question: [] });
  useEffect(() => {
    if (isActive) {
      document.getElementById(`${index}title`)?.focus();
    }
  }, [isActive]);
  return (
    <div className="flex flex-col gap-2 text-sm w-2/3">
      <input
        type="text"
        id={`${index}-title`}
        defaultValue={text}
        onFocus={() => {
          setStyle({ ...style, active: true });
          onFocus();
        }}
        onBlur={(e) => setText(e.target.value)}
        className={`${style.title} focus:border-b-primary focus:border-b border-solid w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
      />
      <input
        type="text"
        id="title"
        placeholder={
          type === 'short'
            ? 'Texto de resposta curta'
            : 'Texto de resposta longa'
        }
        disabled
        className="border-b-gray-400 mb-8 mt-3 border-b border-dotted w-full py-2 text-gray-700 leading-tight outline-none shadow-outline"
      />
    </div>
  );
};

export default ShortParagraphAnswer;
