import React, { useEffect, useState } from 'react';
import {
  Card,
  HeaderPages,
  Input,
  SelectComponent,
  Table,
} from '../../../components';
import {
  getDefaultList,
  getDefaultMessages,
  postConfiguration,
  putDays,
  putForm,
  putMessage,
  putPeriod,
  putStatus,
  putValue,
} from '../api';
import Checkbox from '../../../components/Switch';
import {
  absenceOptions,
  defaultDays,
  optionDays,
  periodOptions,
  quantityOfPurchases,
  transformMessageOptions,
} from '../utils';
import {
  ISelectedOption,
  IWhatsAppAutomation,
  IWhatsAppMessages,
} from '../types';
import { useFetchFormSearch } from '../../searchForm/hooks';
import maskHelper from '../../../helpers/mask.helper';

const WhatsAppAutomations: React.FC = () => {
  const [data, setData] = useState<IWhatsAppAutomation[]>([]);
  const [messages, setMessages] = useState<IWhatsAppMessages[]>([]);
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [valueAutomations, setValue] = useState<number | null>(null);
  const { SearchFormList, isLoadingData } = useFetchFormSearch('', false);

  const formOptions = SearchFormList.map((form: any) => ({
    label: form.title,
    value: form.id,
  }));

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getDefaultList();
      setData(result);
      const messages1 = await getDefaultMessages();
      setMessages(messages1);
      const initialSwitchStates: { [key: string]: boolean } = {};
      result.forEach((item: IWhatsAppAutomation) => {
        initialSwitchStates[item.id.toString()] =
          item.companie_whatsapp_message_sending_configuration.length > 0
            ? item.companie_whatsapp_message_sending_configuration[0].is_active
            : false;
      });
      const initialValue =
        result[9]?.companie_whatsapp_message_sending_configuration[0]?.value ??
        0;
      setValue(initialValue);
      setSwitchStates(initialSwitchStates);
    };
    const loadInitialData = async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    };
    loadInitialData();
  }, []);

  const handleSelectMessageChange = async (
    selectedOption: ISelectedOption,
    row: IWhatsAppAutomation
  ) => {
    if (row.companie_whatsapp_message_sending_configuration[0]) {
      const message = {
        companie_whatsapp_message_default_id: selectedOption.value,
        companie_whatsapp_message_sending_title_default_id:
          row.companie_whatsapp_message_sending_configuration[0].id,
      };
      await putMessage(message);
    } else {
      const configuration = {
        companie_whatsapp_message_sending_title_default_id: row.id,
        companie_whatsapp_message_default_id: selectedOption.value,
        qty: null,
        is_active: false,
      };
      await postConfiguration(configuration);
    }
  };

  const handleSwitchChange = async (
    rowId: string,
    row: IWhatsAppAutomation
  ) => {
    const newState = (prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [rowId]: !prevStates[rowId],
    });
    setSwitchStates(newState);
    const updatedState = newState(switchStates);
    const status = {
      companie_whatsapp_message_sending_title_default_id:
        row.companie_whatsapp_message_sending_configuration[0].id,
      is_active: updatedState[rowId],
    };
    await putStatus(status);
  };

  const handleSelectDays = async (selectDay: ISelectedOption, id: number) => {
    const qty = {
      id,
      qty: selectDay.value,
    };
    await putDays(qty);
  };

  const handleSelectForm = async (id: number, formId: number) => {
    await putForm(id, formId);
  };

  const handleSelectPeriod = async (id: number, period: number) => {
    await putPeriod(id, period);
  };

  const handlePostValue = async (id: number, value: number) => {
    await putValue(id, value);
  };

  const columns = [
    {
      name: 'Ação',
      key: 'action',
      cell: (row: IWhatsAppAutomation) => (
        <div className="flex items-center">
          {row.id === 5 && (
            <>
              <span className="mr-4">{row.title}</span>
              <SelectComponent
                className="w-40 mt-1"
                options={optionDays}
                placeholder="Dias"
                onChange={(selectedOption: ISelectedOption) => {
                  handleSelectDays(
                    selectedOption,
                    row.companie_whatsapp_message_sending_configuration[0].id
                  );
                }}
                defaultValue={
                  row.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  row.companie_whatsapp_message_sending_configuration[0].qty
                    ? {
                        label:
                          defaultDays[
                            row
                              .companie_whatsapp_message_sending_configuration[0]
                              .qty
                          ],
                        value:
                          row.companie_whatsapp_message_sending_configuration[0]
                            .qty,
                      }
                    : undefined
                }
                isDisabled={
                  row.companie_whatsapp_message_sending_configuration.length ===
                  0
                }
                menuPortalTarget={document.body}
              />
            </>
          )}

          {row.id === 7 && (
            <span className="mr-4 inline-flex items-center">
              Ausência de compras em{' '}
              <SelectComponent
                className="w-40 mr-3 ml-3 mt-1"
                options={absenceOptions}
                placeholder="Dias"
                onChange={(selectedOption: ISelectedOption) =>
                  handleSelectDays(
                    selectedOption,
                    row.companie_whatsapp_message_sending_configuration[0].id
                  )
                }
                defaultValue={
                  row.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  row.companie_whatsapp_message_sending_configuration[0].qty
                    ? absenceOptions.find(
                        (option) =>
                          option.value ===
                          row.companie_whatsapp_message_sending_configuration[0]
                            .qty
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  row.companie_whatsapp_message_sending_configuration.length ===
                  0
                }
                menuPortalTarget={document.body}
              />{' '}
              dias
            </span>
          )}

          {row.id === 9 && (
            <span className=" inline-flex items-center">
              Nº de compras{' '}
              <SelectComponent
                className="w-36 mr-7 ml-0 mt-1"
                placeholder="Número de compras"
                options={quantityOfPurchases}
                onChange={(selectedOption: ISelectedOption) =>
                  handleSelectDays(
                    selectedOption,
                    row.companie_whatsapp_message_sending_configuration[0].id
                  )
                }
                isDisabled={
                  row.companie_whatsapp_message_sending_configuration.length ===
                  0
                }
                defaultValue={
                  row.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  row.companie_whatsapp_message_sending_configuration[0].qty
                    ? quantityOfPurchases.find(
                        (option) =>
                          option.value ===
                          row.companie_whatsapp_message_sending_configuration[0]
                            .qty
                      ) || undefined
                    : undefined
                }
                menuPortalTarget={document.body}
              />{' '}
              no período{' '}
              <SelectComponent
                className="w-40 ml-3 mt-1"
                options={periodOptions}
                placeholder="Período"
                onChange={(selectedOption: any) =>
                  handleSelectPeriod(
                    row.companie_whatsapp_message_sending_configuration[0].id,
                    selectedOption.value
                  )
                }
                defaultValue={
                  row.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  row.companie_whatsapp_message_sending_configuration[0].period
                    ? periodOptions.find(
                        (option) =>
                          option.value ===
                          row.companie_whatsapp_message_sending_configuration[0]
                            .period
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  row.companie_whatsapp_message_sending_configuration.length ===
                  0
                }
                menuPortalTarget={document.body}
              />{' '}
            </span>
          )}

          {row.id === 10 && (
            <span className="inline-flex items-center">
              Valor total de compras{' '}
              <div className="w-45 mr-6 ml-0">
                <Input
                  variant="outline-orange"
                  value={
                    valueAutomations !== null
                      ? maskHelper.formatDecimal(valueAutomations!.toString())
                      : '0.00'
                  }
                  disabled={
                    row.companie_whatsapp_message_sending_configuration
                      .length === 0
                  }
                  onChange={(e) => {
                    const numericValue = maskHelper.number(e.target.value);

                    const valueAut = numericValue ? Number(numericValue) : null;

                    setValue(valueAut);
                  }}
                  onBlur={() => {
                    if (valueAutomations !== null) {
                      handlePostValue(
                        row.companie_whatsapp_message_sending_configuration[0]
                          .id,
                        valueAutomations!
                      );
                    }
                  }}
                />
              </div>
              no período{' '}
              <SelectComponent
                className="w-40 ml-3 mr-4 mt-1"
                placeholder="Período"
                options={periodOptions}
                onChange={(selectedOption: any) =>
                  handleSelectPeriod(
                    row.companie_whatsapp_message_sending_configuration[0].id,
                    selectedOption.value
                  )
                }
                defaultValue={
                  row.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  row.companie_whatsapp_message_sending_configuration[0].period
                    ? periodOptions.find(
                        (option) =>
                          option.value ===
                          row.companie_whatsapp_message_sending_configuration[0]
                            .period
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  row.companie_whatsapp_message_sending_configuration.length ===
                  0
                }
                menuPortalTarget={document.body}
              />{' '}
            </span>
          )}
          {![5, 7, 9, 10].includes(row.id) && <span>{row.title}</span>}
        </div>
      ),
      width: '500px',
    },
    {
      name: 'Mensagem',
      key: 'message',
      cell: (row: IWhatsAppAutomation) => (
        <div className="w-full mt-1 relative">
          <SelectComponent
            placeholder="Selecione a mensagem"
            className="w-full z-5000 absolute"
            options={transformMessageOptions(messages)}
            onChange={(selectedOption: ISelectedOption) =>
              handleSelectMessageChange(selectedOption, row)
            }
            defaultValue={
              row.companie_whatsapp_message_sending_configuration.length > 0 &&
              row.companie_whatsapp_message_sending_configuration[0]
                .companie_whatsapp_messages_default
                ? {
                    label:
                      row.companie_whatsapp_message_sending_configuration[0]
                        .companie_whatsapp_messages_default.text,
                    value:
                      row.companie_whatsapp_message_sending_configuration[0]
                        .companie_whatsapp_messages_default.id,
                  }
                : undefined
            }
            menuPortalTarget={document.body}
          />
        </div>
      ),
      width: '650px',
    },
    {
      name: 'Formulário',
      key: 'Formulário',
      cell: (row: any) => (
        <div className="w-full">
          {!isLoadingData && (
            <SelectComponent
              options={formOptions}
              placeholder="Selecione o formulário"
              menuPortalTarget={document.body}
              defaultValue={
                row.companie_whatsapp_message_sending_configuration.length >
                  0 &&
                row.companie_whatsapp_message_sending_configuration[0]
                  .search_form_id
                  ? formOptions.find(
                      (option) =>
                        option.value ===
                        row.companie_whatsapp_message_sending_configuration[0]
                          .search_form_id
                    ) || undefined
                  : undefined
              }
              isDisabled={
                row.companie_whatsapp_message_sending_configuration.length === 0
              }
              onChange={(selectedOption: any) => {
                handleSelectForm(
                  row.companie_whatsapp_message_sending_configuration[0].id,
                  selectedOption.value
                );
              }}
            />
          )}
        </div>
      ),
      width: '300px',
    },
    {
      name: 'Status',
      key: 'Status',
      cell: (row: any) => (
        <div>
          <Checkbox
            state={switchStates[row.id] || false}
            onChange={() => handleSwitchChange(row.id, row)}
            disabled={
              row.companie_whatsapp_message_sending_configuration.length === 0
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-8">
        <HeaderPages title="Automação de envio de Whatsapp" />
      </div>
      <Card className="min-h-[54rem]">
        <div className="w-full px-6 overflow-x-auto">
          <div className="min-w-[1000px]">
            <Table columns={columns} data={data} isLoading={isLoading} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default WhatsAppAutomations;
