import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { Attachments, Button, Modal } from '../../../../components';
import CreateProviderGeneralData from './components/generalData';
import Contacts from './components/contacts';
import { fullInformationProps } from '../../types';
import { useFetchAttachments } from '../../hooks';
import { ToastNotify } from '../../../../components/Toast/toast';
import Address from '../../../../components/Register/address';
import { ApiProvider } from '../../api';

const header = [
  {
    id: 0,
    title: 'Dados Gerais',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Contatos',
    page: 2,
  },
  {
    id: 3,
    title: 'Anexos',
    page: 3,
  },
];
const Register: React.FC<fullInformationProps> = ({
  show,
  setShow,
  registerPage,
  companyUserId,
  provider,
  setProvider,
  reloadTable,
  setRegisterPage,
}) => {
  const [providerId, setProviderId] = useState(-1);
  const [key, setKey] = useState(`${Math.random()} attachments`);
  const { AttachmentsList, isLoadingAttachmentsList } = useFetchAttachments(
    key,
    provider?.id ?? providerId
  );
  const { notify } = ToastNotify();
  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      setShow(false);
      reloadTable(`${Math.random()} create`);
    }
  };

  useEffect(() => {
    setKey(`${Math.random()} files`);
  }, [show]);

  return (
    <Modal
      minHeight="min-h-[780px]"
      size="4xlarge"
      title={provider ? 'Atualizar Fornecedor' : 'Cadastrar Fornecedor'}
      isOpen={show}
      setIsOpen={() => {
        setShow(false);
        reloadTable(`${Math.random()} save`);
      }}
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 sm:flex gap-2 mt-9">
          {header.map((item) => (
            <Button
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              key={item.id}
              actionType="button-full-width"
              className={classNames([
                'font-medium',
                'md:w-32',
                provider !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => provider !== null && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        {registerPage.page === 0 && (
          <CreateProviderGeneralData
            setProvider={setProvider}
            setId={setProviderId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={provider}
            id={companyUserId}
          />
        )}
        {registerPage.page === 1 && (
          <Address
            apiFunction={async (values, id) => {
              const res = await ApiProvider.updateAddress(values, id);
              if (res.id) {
                setProvider(res);
              }
              return res;
            }}
            defaultValue={
              provider && {
                address: provider.address ?? '',
                city: provider.city ?? '',
                complement: provider.complement,
                province: provider.province ?? '',
                state: provider.state ?? '',
                zipcode: provider.zipcode ?? '',
              }
            }
            id={provider?.id ?? providerId}
            nextPage={nextPage}
          />
        )}
        {registerPage.page === 2 && (
          <Contacts
            setProvider={() => {}}
            setId={setProviderId}
            notify={notify}
            nextPage={nextPage}
            defaultValue={provider}
            id={provider?.id ?? providerId}
          />
        )}
        {registerPage.page === 3 && (
          <Attachments
            AttachmentsList={AttachmentsList}
            apiFunction={ApiProvider.createProviderAttachments}
            deleteApiFunction={ApiProvider.deleteAttachments}
            id={provider?.id ?? providerId}
            keyFile="provider_id"
            nextPage={nextPage}
            updateTable={setKey}
            isLoadingTable={isLoadingAttachmentsList}
          />
        )}
        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Register;
