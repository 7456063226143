import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import * as XLSX from 'xlsx';
import moment from 'moment';
import maskHelper from '../../../../helpers/mask.helper';
import { Button, Card, SelectComponent, Table } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';
import { ApiCashFlow } from '../api';
import { dataProps, receiveExpenseProps } from '../types';
import FullInformation from '../components/fullInformation';

function formatTwoDigits(number: number) {
  if (number < 10) {
    return `0${number}`;
  }
  return String(number);
}

const CashFlowReports: React.FC = () => {
  const dateFilterOptions = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const years: { value: string; label: string }[] = [];
  const currentYear = moment().year();
  for (let year = 2022; year <= currentYear + 5; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }

  const [filterDateMonth, setFilterDateMonth] = useState(
    formatTwoDigits(moment().month() + 1)
  );

  const [filterDateYear, setFilterDateYear] = useState(String(currentYear));

  const [totalLine, setTotalLine] = useState<dataProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFullInfo, setshowFullInfo] = useState(true);
  const [currentCashflow, setcurrentCashflow] = useState<{
    type: 'D' | 'R';
    day: string;
    data: receiveExpenseProps[];
  } | null>(null);

  const [cashFlowList, setCashFlowList] = useState<dataProps[]>([]);
  const [defaultTotal, setDefaultTotal] = useState<number>(0);
  const [idRow, setIdRow] = useState(-1);

  const filterTable = () => cashFlowList;

  const excelData = [
    ['Dia', 'Receita', 'Despesa', 'Saldo Anterior'],
    ...filterTable().map((item) => [
      item.day,
      Number(item.totalR),
      Number(item.totalD),
      Number(item.saldo),
    ]),
  ];

  const exportToXLS = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const csvData: any = [];
    const keys = excelData[0];
    const values = excelData.slice(1, excelData.length);
    let item: any = {};

    for (let j = 0; j < values.length; j++) {
      for (let i = 0; i < keys.length; i++) {
        item[keys[i]] = values[j][i];
      }
      csvData.push(item);
      item = {};
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `fluxo_caixa${fileExtension}`);
  };

  function calTotalDay(totalStart: number) {
    const saldo: number[] = [];
    cashFlowList.forEach((item, index, arr) => {
      const previousSaldo = index > 0 ? saldo[index - 1] : totalStart;

      item.saldo = String(
        Number(item.totalR) - Number(item.totalD) + previousSaldo
      );

      saldo.push(Number(item.saldo));

      cashFlowList[index].saldo = item.saldo;
      if (index < arr.length - 1) {
        arr[index].saldo = item.saldo;
      }
    });
    if (cashFlowList.length > 0) {
      setTotalLine([
        {
          day: 'Saldo',
          totalR: String(
            cashFlowList.reduce((prev, cur) => prev + Number(cur.totalR), 0)
          ),
          date: '',
          despesas: [],
          receitas: [],
          totalD: String(
            cashFlowList.reduce((prev, cur) => prev + Number(cur.totalD), 0)
          ),
          saldo: cashFlowList[cashFlowList.length - 1].saldo,
        },
      ]);
    }
  }

  const statusContract = [
    { value: 'Ag.Pagamento', label: 'Aguardando Pagamento' },
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Inadimplente', label: 'Inadimplente' },
    { value: 'Interrompido', label: 'Interrompido' },
    { value: 'Cancelado', label: 'Cancelado' },
  ];

  const [contractStatus, setContractStatus] = useState<
    { label: string; value: string }[]
  >([]);

  const FetchReports = async () => {
    setIsLoading(true);
    const res = await ApiCashFlow.getcashFlow(
      `${filterDateMonth}/${filterDateYear}`,
      contractStatus.map((item) => item.value)
    );
    if (typeof res !== 'string') {
      setCashFlowList(res.data);
      setDefaultTotal(Number(res.saldo_anterior));

      if (
        Number(filterDateMonth) === moment().month() + 1 &&
        moment().year() === Number(filterDateYear)
      ) {
        setIdRow(moment().get('D'));
      } else {
        setIdRow(-1);
      }
    } else {
      setCashFlowList([]);
    }
    setIsLoading(false);
  };

  const columns = [
    {
      name: 'Dia',
      key: 'day',
      selector: (row: dataProps) => row.day,
    },
    {
      name: 'Receita',
      key: 'receitas',
      selector: (row: dataProps) => (
        <button
          type="button"
          className={
            Number(row.totalR) > 0 ? 'cursor-pointer' : 'cursor-default'
          }
          onClick={() => {
            if (Number(row.totalR) > 0) {
              setcurrentCashflow({
                type: 'R',
                data: row.receitas,
                day: maskHelper.formatDateDMY(row.date),
              });
              setshowFullInfo(true);
            }
          }}
        >
          {maskHelper.formatMoeda(row.totalR)}
        </button>
      ),
    },
    {
      name: 'Despesa',
      key: 'despesa',
      selector: (row: dataProps) => (
        <button
          type="button"
          className={
            Number(row.totalD) > 0 ? 'cursor-pointer' : 'cursor-default'
          }
          onClick={() => {
            if (Number(row.totalD) > 0) {
              setcurrentCashflow({
                type: 'D',
                data: row.despesas,
                day: maskHelper.formatDateDMY(row.date),
              });
              setshowFullInfo(true);
            }
          }}
        >
          {maskHelper.formatMoeda(row.totalD)}
        </button>
      ),
    },
    {
      name: 'Saldo anterior',
      minWidth: '350px',
      key: 'saldo',
      selector: (row: dataProps) => maskHelper.formatMoeda(row.saldo ?? ''),
    },
  ];

  useEffect(() => {
    calTotalDay(defaultTotal);
  }, [defaultTotal, cashFlowList]);

  useEffect(() => {
    FetchReports();
  }, []);

  return (
    <>
      <div className="flex gap-3 items-center text-primary mb-8">
        <IconDictionary name="Relatório Balancete" size={24} />
        <strong className="text-lg text-black">Relatório Fluxo de Caixa</strong>
      </div>
      <Card className="w-full px-4 pt-5 flex-col pb-3.5 gap-4 mb-3">
        <strong>Filtrar por</strong>
        <div className="flex flex-col md:flex-row">
          <div className="flex w-full flex-col md:flex-row md:items-center gap-3">
            <SelectComponent
              variant="outline-primary"
              icon="Calendario"
              value={dateFilterOptions.find(
                (item) => item.value === filterDateMonth
              )}
              className="w-full md:w-44"
              options={dateFilterOptions}
              closeMenuOnSelect
              onChange={(e: any) => {
                setFilterDateMonth(e.value);
              }}
              maxOptionsHeight="300px"
            />
            <SelectComponent
              variant="outline-primary"
              className="w-full lg:w-44"
              value={contractStatus}
              placeholder="Status contrato"
              isMulti
              onChange={(e: any) => {
                setContractStatus(e);
              }}
              options={statusContract}
              closeMenuOnSelect
              maxOptionsHeight="300px"
            />
            <SelectComponent
              variant="outline-primary"
              value={years.find((item) => item.value === filterDateYear)}
              className="w-full md:w-44"
              options={years}
              closeMenuOnSelect
              onChange={(e: any) => {
                setFilterDateYear(e.value);
              }}
              maxOptionsHeight="300px"
            />
          </div>

          <div className="flex w-full gap-3 justify-center md:justify-end">
            <Button
              variant="primary-strong"
              className="mb-1.5 w-36"
              onClick={() => {
                FetchReports();
              }}
              isLoading={isLoading}
              actionType="button-simple"
            >
              Carregar
            </Button>
            <Button
              actionType="button-simple"
              variant="outline-primary"
              className="flex justify-center items-center mb-1.5 gap-2 w-36"
              onClick={exportToXLS}
            >
              <IconDictionary name="Download" /> Exportar
            </Button>
          </div>
        </div>
      </Card>

      <Card className="relative mt-3 px-4 flex-col min-h-[80vh] mb-8 pb-8">
        {cashFlowList.length > 0 && (
          <div className="z-10 absolute top-8 right-16">
            <input
              placeholder="R$ 0,00"
              value={maskHelper.formatMoeda(String(defaultTotal))}
              className="hidden md:flex text-xs bg-white p-2 h-6 border border-solid focus:border-primary border-gray-600 rounded w-36"
              onChange={(e) => {
                e.target.value = maskHelper.formatCurrencyInput(e.target.value);
                setDefaultTotal(
                  Number(maskHelper.numberCurrencyDecimal(e.target.value))
                );
              }}
            />
          </div>
        )}
        <Table
          columns={columns}
          isLoading={isLoading}
          data={cashFlowList.concat(totalLine)}
          selectedRowId={idRow}
        />
      </Card>
      {currentCashflow !== null && (
        <FullInformation
          day={currentCashflow.day}
          close={() => setshowFullInfo(false)}
          open={showFullInfo}
          type={currentCashflow.type}
          value={currentCashflow.data}
        />
      )}
    </>
  );
};

export default CashFlowReports;
