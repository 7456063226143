/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import maskHelper from '../../../../../../helpers/mask.helper';
import RegisterUser from '../../../../../users/components/register';
import {
  Button,
  DeleteModal,
  Switch,
  Table,
} from '../../../../../../components';
import { formPropsPage } from '../../../types';
import { ApiUsers } from '../../../../../users/api';
import { UserProps } from '../../../../../users/types';
import { useFetchProfile } from '../../../../profile/hooks';
import { ApiCompany } from '../../../api';
import { dictionaryError } from '../../../../../../helpers/utils.helper';

export interface accessUser {
  id: number;
  companie_user_id: number;
  homepage: string;
  login: string;
  password: string;
}

interface props extends formPropsPage {
  UsersList: UserProps[];
  setIsLoading: (e: boolean) => void;
  setKey: (e: string) => void;
  isLoading: boolean;
}

const CreateUser: React.FC<props> = ({
  id,
  nextPage,
  notify,
  isFranchise,
  UsersList,
  isLoading,
  setIsLoading,
  setKey,
}) => {
  const { ProfileList } = useFetchProfile('key');
  const [showDelete, setShowDelete] = useState(false);
  const [user, setUser] = useState<UserProps | null>(null);
  const [modalProviders, setModalProviders] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const ChangeStatusUser = async (
    currentUserId: number,
    status: boolean,
    userProps: UserProps
  ) => {
    setIsLoading(true);
    const res = await ApiUsers.updateGeneralData(
      {
        email: userProps.email,
        name: userProps.name,
        profile_id: userProps.profile_id,
        is_active: status,
      },
      currentUserId
    );
    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: UserProps) => row.name,
    },
    {
      name: 'CPF/CNPJ',
      key: 'cpfCnpj',
      selector: (row: UserProps) => row.document,
      cell: (row: UserProps) => maskHelper.cnpjCpf(row.document ?? 's'),
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: UserProps) => row.whatsapp,
      cell: (row: UserProps) => maskHelper.phone(row.whatsapp ?? ''),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: UserProps) => row.email,
      hide: 800,
    },
    {
      name: 'Status',
      cell: (row: UserProps) => (
        <Switch
          state={row.is_active}
          onChange={() => ChangeStatusUser(row.id, !row.is_active, row)}
        />
      ),
    },
  ];

  const createStartup = async (newCompanyId: number) => {
    const res = await ApiCompany.createStartup({
      companie_user_id: newCompanyId,
    });
    if (res.activitieType) {
      notify({
        message: 'Inicialização do usuário realizada com sucesso!',
        type: 'Success',
      });
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
  };

  return (
    <div className="flex flex-col w-full">
      <RegisterUser
        setUser={setUser}
        isFranchise={isFranchise ?? false}
        ProfileList={ProfileList}
        setCompanyId={(e) => {
          if (UsersList.length === 0) {
            createStartup(e);
            setKey(String(Math.random()));
          } else {
            setKey(String(Math.random()));
          }
        }}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalProviders}
        show={modalProviders}
        companyId={id}
        user={user}
      />
      {user && (
        <DeleteModal
          apiFunction={ApiUsers.deleteUser}
          type="Usuário"
          article="O"
          notify={notify}
          updateTable={() => setKey(`${Math.random()}delete`)}
          close={() => setShowDelete(false)}
          id={user.id}
          name={user.name}
          open={showDelete}
        />
      )}

      <Button
        className="my-5"
        actionType="button-add"
        variant="primary-strong"
        onClick={() => {
          setUser(null);
          setModalProviders(true);
          setRegisterPage({ page: 0, title: 'Dados Pessoais' });
        }}
      >
        Adicionar Usuário
      </Button>

      <div className="flex flex-col w-full min-h-[200px]">
        <Table
          columns={columns}
          data={UsersList}
          isLoading={isLoading}
          onRowClick={(e: UserProps) => {
            setRegisterPage({ page: 0, title: 'Dados Gerais' });
            setUser(e);
            setModalProviders(true);
          }}
        />
      </div>

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          onClick={() => nextPage()}
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </div>
  );
};

export default CreateUser;
