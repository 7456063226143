import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import MainPage from '../../../main/components/mainPage';
import { ToastNotify } from '../../../../components/Toast/toast';
import { DeleteModal } from '../../../../components';
import { Group } from '../types';
import { ApiGroup } from '../api';
import { useFetchGroup } from '../hooks';
import RegisterGroup from '../components/register';
import { useFetchModule } from '../../module/hooks';

const Groups: React.FC = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterGroup, setModalRegisterGroup] = useState(false);
  const [input, setInput] = useState('');
  const [groupData, setGroupData] = useState<Group | null>(null);
  const [key, setKey] = useState('key');

  const { GroupList, isLoadingGroup } = useFetchGroup(key);
  const { notify } = ToastNotify();

  const { ModuleList } = useFetchModule('');
  const modulesOptions = ModuleList.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const columns = [
    {
      name: 'Nome',
      key: 'title',
      selector: (row: Group) => row.title,
    },
    {
      name: 'Módulo',
      key: 'module_id',
      selector: (row: Group) =>
        ModuleList.find((item) => item.id === row.module_id)?.title,
    },
    {
      name: '',
      cell: (row: Group) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setGroupData(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return GroupList;
    }
    return GroupList.filter(
      (item) => item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoadingGroup}
        column={columns}
        title="Grupos"
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalRegisterGroup}
        setRegisterPage={() => {
          setGroupData(null);
        }}
        onRowClick={(e: Group) => {
          setGroupData(e);
          setModalRegisterGroup(true);
        }}
      />
      <RegisterGroup
        modulesOptions={modulesOptions}
        group={groupData}
        reloadTable={setKey}
        setShow={setModalRegisterGroup}
        show={modalRegisterGroup}
      />
      {groupData && (
        <DeleteModal
          apiFunction={ApiGroup.deleteGroup}
          notify={notify}
          type="Grupo"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={groupData.id}
          name={groupData.title}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Groups;
