import React from 'react';
import { receiveExpenseProps } from '../types';
import maskHelper from '../../../../helpers/mask.helper';
import { Modal, Table } from '../../../../components';

/* eslint-disable no-unused-vars */
interface DeleteProps {
  close: () => void;
  open: boolean;
  value: receiveExpenseProps[];
  day: string;
  type: 'R' | 'D';
}

const FullInformation: React.FC<DeleteProps> = ({
  close,
  open,
  type,
  day,
  value,
}) => {
  const statusColor = {
    Aberta: 'text-black',
    Cancelada: 'text-gray-400',
    Vencida: 'text-red',
    Paga: 'text-green',
  };
  const columns = [
    {
      name: 'Cliente ou Fornecedor',
      key: 'client_name',
      minWidth: '170px',
      selector: (row: receiveExpenseProps) => row.name,
    },

    {
      name: 'Contas',
      key: 'plan_account.title',
      selector: (row: receiveExpenseProps) => row.title,
    },
    {
      name: 'Vencimento',
      selector: (row: receiveExpenseProps) =>
        row.due_date !== undefined
          ? maskHelper.formatDateDMY(row.due_date.slice(0, 10))
          : '',
    },
    {
      name: 'Parcela',
      key: 'installments',
      selector: (row: receiveExpenseProps) => row.installment,
      hide: 1407,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: receiveExpenseProps) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: receiveExpenseProps) => row.status,
      cell: (row: receiveExpenseProps) => (
        <p className={statusColor[row.status]}>{row.status}</p>
      ),
    },
    {
      name: 'Data Recebimento',
      key: 'received_date',
      minWidth: '150px',
      selector: (row: receiveExpenseProps) =>
        maskHelper.formatDateDMY(row.received_date ?? ''),
    },
    {
      name: 'Valor Recebido',
      key: 'received_value',
      selector: (row: receiveExpenseProps) =>
        maskHelper.formatMoeda(row.received_value),
    },
  ];
  return (
    <Modal
      setIsOpen={close}
      title={
        type === 'R' ? (
          <div className="flex gap-2 flex-wrap">
            Lançamentos Receita
            <div className="text-white py-px px-2.5 bg-primary rounded-xl flex items-center justify-center">
              {value.length}
            </div>
            - {day}
          </div>
        ) : (
          <div className="flex gap-2 flex-wrap">
            Lançamentos Despesa
            <div className="text-white py-px px-2.5 bg-primary rounded-xl flex items-center justify-center">
              {value.length}
            </div>
            - {day}
          </div>
        )
      }
      isOpen={open}
      size="6xlarge"
      minHeight="min-h-[350px]"
    >
      <div className="max-h-[80vh] overflow-y-auto">
        <Table
          columns={columns}
          data={value}
          isLoading={false}
          selectedRowId={-1}
          pagination={false}
          scrollTable
          fixedHeaderScrollHeight="74vh"
        />
      </div>
    </Modal>
  );
};

export default FullInformation;
