/* eslint-disable no-unused-vars */
import { notify } from '../../../../../components/Toast/toast';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { ApiCompanyGalaxPay } from '../api';

export const CompaniePFHeader = [
  {
    id: 0,
    title: 'Dados da Conta',
    page: 0,
  },
  {
    id: 1,
    title: 'Professional',
    page: 1,
  },
  {
    id: 2,
    title: 'Endereço',
    page: 2,
  },
  {
    id: 3,
    title: 'Documentos',
    page: 3,
  },
];

export const CompaniePJHeader = [
  {
    id: 0,
    title: 'Dados da Conta',
    page: 0,
  },
  {
    id: 1,
    title: 'Endereço',
    page: 1,
  },
  {
    id: 2,
    title: 'Documentos',
    page: 2,
  },
];

export const optionsTypeCompanie = [
  { label: 'LTDA', value: 'ltda' },
  { label: 'Eireli', value: 'eireli' },
  { label: 'Associações/Condomínios', value: 'association' },
  { label: 'Empresário individual', value: 'individualEntrepreneur' },
  { label: 'MEI', value: 'mei' },
  { label: 'S/A', value: 'sa' },
  { label: 'SLU', value: 'slu' },
];

export const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function isBase64(str: string) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export const ChangeStatusUser = async (
  id: number,
  status: boolean,
  setIsLoading: (e: boolean) => void,
  update: (e: string) => void
) => {
  setIsLoading(true);
  let res: any;
  if (status) {
    res = await ApiCompanyGalaxPay.activeCompanie(id);
  } else {
    res = await ApiCompanyGalaxPay.deleteCompanie(id);
  }
  if (res.id) {
    notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
    update(`${Math.random()} status`);
  } else {
    notify({ message: dictionaryError(res), type: 'Error' });
    setIsLoading(false);
  }
};

export const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};
