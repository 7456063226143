import { Route, Routes } from 'react-router-dom';
import { ComingSoonScreen, LoadingScreen } from '../components';
import AdminScreen from '../components/AdminScreen/adminScreen';
import { AdminRoute, AuthRoute, MainRoute } from '../modules/auth';

import {
  Categories,
  Company,
  Groups,
  Profiles,
  Menus,
  UsersAdmin,
  Products,
  Modules,
  WhatsappPlans,
  Partners,
  Signature,
  Parameters,
} from '../modules/admin/adminPages';

import {
  ActivitieTypes,
  Activity,
  Clients,
  CodeVerify,
  Contacts,
  Funnels,
  HomePage,
  InputAutomation,
  LoginPage,
  Opportunities,
  OpportunityForm,
  OtherCompanyAccess,
  PasswordRecovery,
  Providers,
  ReleaseMenuClient,
  ResponseLose,
  ResponseWin,
  SalesChannels,
  Segments,
  BiFunnelSales,
  TransferOpportunity,
  Users,
  WhatsappMessages,
} from '../modules/pages';
import PageInvalidLink from '../components/PageInvalidLink';
import ImportOpportunity from '../modules/inputAutomation/page/importOportunity';
import MissingConfiguration from '../components/PageInvalidLink/missingConfiguration';
import OpportunityReports from '../modules/reports/opportunity/pages';
import ChartAccount from '../modules/chartAccounts/pages';
import ContractEditPage from '../modules/contract/pages/editContract';
import ContractPage from '../modules/contract/pages';
import Financials from '../modules/financial/pages';
import FinancialReports from '../modules/reports/financial/pages';
import BalanceSheetReports from '../modules/reports/balanceSheet/pages';
import CashFlowReports from '../modules/reports/cashflow/pages';
import BiFinancial from '../modules/dashboard/financial/pages';
import BiContacts from '../modules/dashboard/contracts/pages';
import ProductsCompanie from '../modules/product/pages';
import CompanieCustom from '../modules/companieCustom/pages';
import Accept from '../modules/contract/pages/accept';
import OfferCustom from '../modules/offerCustom/pages';
import Offers from '../modules/offer/pages';
import SearchForm from '../modules/searchForm/page';
import AnswerForm from '../modules/searchForm/page/answerForm';
import Whatsapp from '../modules/whatsapp/pages';
import WhatsappTags from '../modules/whatsappTag/pages';
import OfferPdf from '../modules/offer/pages/offerPdf';
import WhatsappCampaign from '../modules/whatsappCampaign/pages';
import CreateEditCampaign from '../modules/whatsappCampaign/pages/createEditCampaign';
import DigitalSignatureCreditPage from '../modules/DigitalSignatureCredit/dashboard/pages';
import SignatureLimitUsers from '../modules/admin/planLimitUsers/pages';
import SubAccountsGalax from '../modules/admin/GalaxPay/SubAccounts/pages';
import LoginLP from '../modules/login/pages/loginLP';
import PixPayments from '../modules/paymentPix/page/index';
import BankStatement from '../modules/bankStatement/page';
import WhatsAppAutomations from '../modules/automations/page';
import CompanieGalaxAccount from '../modules/companieGalaxAccount/pages';
import PlugAndPlay from '../modules/plugAndPlay/pages';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/logout" element={<LoadingScreen />} />
      <Route path="/checkout" element={<PlugAndPlay />} />
      <Route path="/session/login/:email?/:pass?" element={<LoginPage />} />
      <Route path="/session/login/verify/:email" element={<LoginLP />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/change-pass/:email/:identity" element={<CodeVerify />} />
      <Route path="/form-lead/:companyId" element={<OpportunityForm />} />
      <Route path="/invalid-link" element={<PageInvalidLink />} />
      <Route path="/missing-configuration" element={<MissingConfiguration />} />
      <Route path="/offer/pdf/:offerId" element={<OfferPdf />} />
      <Route
        path="/contract/accept/:contractId/:companyId"
        element={<Accept />}
      />
      <Route
        path="/form/opportunity/:companieId/:searchFormId/:opportunityId"
        element={<AnswerForm />}
      />

      <Route path="/">
        <Route index element={<MainRoute />} />
        <Route
          path="*"
          element={
            <AuthRoute>
              <ComingSoonScreen />
            </AuthRoute>
          }
        />
        <Route
          path="admin"
          element={
            <AuthRoute>
              <AdminScreen />
            </AuthRoute>
          }
        />

        <Route
          path="bi-funnel"
          element={
            <AuthRoute>
              <BiFunnelSales />
            </AuthRoute>
          }
        />
        <Route
          path="bi-financial"
          element={
            <AuthRoute>
              <BiFinancial />
            </AuthRoute>
          }
        />
        <Route
          path="provider"
          element={
            <AuthRoute>
              <Providers />
            </AuthRoute>
          }
        />
        <Route
          path="user"
          element={
            <AuthRoute>
              <Users />
            </AuthRoute>
          }
        />
        <Route
          path="client"
          element={
            <AuthRoute>
              <Clients />
            </AuthRoute>
          }
        />
        <Route
          path="funnel"
          element={
            <AuthRoute>
              <Funnels />
            </AuthRoute>
          }
        />
        <Route
          path="funnel/:id"
          element={
            <AuthRoute>
              <Funnels />
            </AuthRoute>
          }
        />
        <Route
          path="segment"
          element={
            <AuthRoute>
              <Segments />
            </AuthRoute>
          }
        />
        <Route
          path="sales-channel"
          element={
            <AuthRoute>
              <SalesChannels />
            </AuthRoute>
          }
        />
        <Route
          path="responses-win"
          element={
            <AuthRoute>
              <ResponseWin />
            </AuthRoute>
          }
        />
        <Route
          path="responses-lose"
          element={
            <AuthRoute>
              <ResponseLose />
            </AuthRoute>
          }
        />
        <Route
          path="home"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route
          path="activities-type"
          element={
            <AuthRoute>
              <ActivitieTypes />
            </AuthRoute>
          }
        />
        <Route
          path="contacts"
          element={
            <AuthRoute>
              <Contacts />
            </AuthRoute>
          }
        />
        <Route
          path="opportunity"
          element={
            <AuthRoute>
              <Opportunities />
            </AuthRoute>
          }
        />
        <Route
          path="imports-opportunities"
          element={
            <AuthRoute>
              <ImportOpportunity />
            </AuthRoute>
          }
        />
        <Route
          path="activities"
          element={
            <AuthRoute>
              <Activity />
            </AuthRoute>
          }
        />
        <Route
          path="activities/:activityId"
          element={
            <AuthRoute>
              <Activity />
            </AuthRoute>
          }
        />

        <Route
          path="transfer-opportunity"
          element={
            <AuthRoute>
              <TransferOpportunity />
            </AuthRoute>
          }
        />
        <Route
          path="menus-profile"
          element={
            <AuthRoute>
              <ReleaseMenuClient />
            </AuthRoute>
          }
        />
        <Route
          path="companie-custom"
          element={
            <AuthRoute>
              <CompanieCustom />
            </AuthRoute>
          }
        />

        <Route
          path="access-companie-group"
          element={
            <AuthRoute>
              <OtherCompanyAccess />
            </AuthRoute>
          }
        />

        <Route
          path="input-automations"
          element={
            <AuthRoute>
              <InputAutomation />
            </AuthRoute>
          }
        />

        <Route
          path="reports-opportunity"
          element={
            <AuthRoute>
              <OpportunityReports />
            </AuthRoute>
          }
        />
        <Route
          path="plan-accounts"
          element={
            <AuthRoute>
              <ChartAccount />
            </AuthRoute>
          }
        />

        <Route
          path="contracts-settings"
          element={
            <AuthRoute>
              <ContractEditPage />
            </AuthRoute>
          }
        />

        <Route
          path="contracts"
          element={
            <AuthRoute>
              <ContractPage />
            </AuthRoute>
          }
        />
        <Route
          path="accounts-pay-receive"
          element={
            <AuthRoute>
              <Financials />
            </AuthRoute>
          }
        />
        <Route
          path="payment-pix"
          element={
            <AuthRoute>
              <PixPayments />
            </AuthRoute>
          }
        />
        <Route
          path="reports-accounts-pay-receive"
          element={
            <AuthRoute>
              <FinancialReports />
            </AuthRoute>
          }
        />
        <Route
          path="reports-balancete"
          element={
            <AuthRoute>
              <BalanceSheetReports />
            </AuthRoute>
          }
        />
        <Route
          path="reports-cash-flow"
          element={
            <AuthRoute>
              <CashFlowReports />
            </AuthRoute>
          }
        />
        <Route
          path="bi-contracts"
          element={
            <AuthRoute>
              <BiContacts />
            </AuthRoute>
          }
        />
        <Route
          path="products"
          element={
            <AuthRoute>
              <ProductsCompanie />
            </AuthRoute>
          }
        />
        <Route
          path="offer-custom"
          element={
            <AuthRoute>
              <OfferCustom />
            </AuthRoute>
          }
        />
        <Route
          path="offer"
          element={
            <AuthRoute>
              <Offers />
            </AuthRoute>
          }
        />
        <Route
          path="search-form"
          element={
            <AuthRoute>
              <SearchForm />
            </AuthRoute>
          }
        />
        <Route
          path="instances"
          element={
            <AuthRoute>
              <Whatsapp />
            </AuthRoute>
          }
        />
        <Route
          path="tags"
          element={
            <AuthRoute>
              <WhatsappTags />
            </AuthRoute>
          }
        />
        <Route
          path="message-default"
          element={
            <AuthRoute>
              <WhatsappMessages />
            </AuthRoute>
          }
        />
        <Route
          path="bulk-shipping"
          element={
            <AuthRoute>
              <WhatsappCampaign />
            </AuthRoute>
          }
        />
        <Route
          path="bulk-shipping/edit/:planId/:campaignId"
          element={
            <AuthRoute>
              <CreateEditCampaign />
            </AuthRoute>
          }
        />
        <Route
          path="bank-account"
          element={
            <AuthRoute>
              <CompanieGalaxAccount />
            </AuthRoute>
          }
        />
      </Route>
      {/* Admin routes */}
      <Route
        path="partners"
        element={
          <AuthRoute>
            <AdminRoute>
              <Partners />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="companie"
        element={
          <AuthRoute>
            <AdminRoute>
              <Company />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="companie-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Company />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="profile-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Profiles />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="group-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Groups />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="parameters"
        element={
          <AuthRoute>
            <AdminRoute>
              <Parameters />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="module-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Modules />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="whatsapp-plans"
        element={
          <AuthRoute>
            <AdminRoute>
              <WhatsappPlans />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="category-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Categories />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="product-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Products />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="menu-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <Menus />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="user-admin"
        element={
          <AuthRoute>
            <AdminRoute>
              <UsersAdmin />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="digital-signature"
        element={
          <AuthRoute>
            <AdminRoute>
              <Signature />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="digital-signature-plans"
        element={
          <AuthRoute>
            <AdminRoute>
              <SignatureLimitUsers />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="digital-signature-credit"
        element={
          <AuthRoute>
            <DigitalSignatureCreditPage />
          </AuthRoute>
        }
      />
      <Route
        path="subaccounts-galax-pay"
        element={
          <AuthRoute>
            <AdminRoute>
              <SubAccountsGalax />
            </AdminRoute>
          </AuthRoute>
        }
      />
      <Route
        path="bank-statement"
        element={
          <AuthRoute>
            <BankStatement />
          </AuthRoute>
        }
      />
      <Route
        path="automations"
        element={
          <AuthRoute>
            <WhatsAppAutomations />
          </AuthRoute>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
