import { useEffect, useState } from 'react';
import { ApiReleaseMenu } from '../api';
import { MenuFullProps } from '../../admin/menu/types';

export function useFetchMenusCompanie(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MenuFullProps[]>([]);
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res = await ApiReleaseMenu.getMenus();
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { MenusCompanieList: data, isLoadingMenus: isLoading };
}

export function useFetchMenusCompanieProfile(
  key: string,
  profile: number,
  companyId: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ menu_id: number; profile_id: number }[]>(
    []
  );
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);
      const res =
        profile === -1
          ? []
          : await ApiReleaseMenu.getMenusByProfile(profile, companyId);
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key, profile]);
  return { MenusCompanieProfileList: data, isLoadingMenuList: isLoading };
}
