import React from 'react';
import Select, { defaultTheme } from 'react-select';

type dataProps = {
  id: number;
  name: string;
};

interface SelectCustomProps {
  placeholder: string;
  noOptionsMessage: string;
  defaultValue?: any;
  data: dataProps[];
}

const SelectCustom: React.FC<SelectCustomProps> = ({
  placeholder,
  noOptionsMessage,
  defaultValue,
  data,
}) => {
  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      fontSize: 14,
      color: 'black',
      padding: 10,
      cursor: 'pointer',
      background: 'white',
      '&:hover': {
        background: '#FFF4DF',
      },
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      fontSize: 14,
    }),
    control: () => ({
      width: '100%',
      height: 40,
      borderRadius: 6,
      border: '1px solid #541554',
      marginTop: 10,
      fontSize: 14,
      display: 'flex',
      cursor: 'pointer',
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
      };
    },
  };

  return (
    <Select
      styles={customStyles}
      defaultValue={defaultValue}
      theme={{
        ...defaultTheme,
        colors: { ...defaultTheme.colors, primary: 'var(--color-black)' },
      }}
      placeholder={placeholder}
      getOptionLabel={(item) => item.name}
      options={data}
      noOptionsMessage={() => noOptionsMessage}
    />
  );
};

export default SelectCustom;
