import { getItem } from './storage.helper';
import { hexToRgba } from './utils.helper';

export const conditionalRowStyles = (selectedRowId: number) => {
  const defaultColorCompany = getItem('color-hex');

  return [
    {
      when: (row: any) =>
        row.id === selectedRowId || Number(row.day) === selectedRowId,
      style: {
        backgroundColor: hexToRgba(defaultColorCompany ?? '', 20),
      },
    },
    {
      when: (row: any) => row.day === 'Saldo',
      style: {
        backgroundColor: '#dadada',
        fontWeight: 700,
      },
    },
  ];
};
