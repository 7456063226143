class CNPJ {
  isValid(cnpj: any) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.padStart(14, '0');

    if (this.isExceptionCnpj(cnpj)) return false;

    if (!this.isValidCnpjNumber(cnpj)) return false;

    if (
      this.calculateRestCnpj(this.sumCnpjDigit(cnpj, 12)) !==
      parseInt(cnpj.substring(12, 13))
    ) {
      return false;
    }

    if (
      this.calculateRestCnpj(this.sumCnpjDigit(cnpj.substring(0, 13), 13)) !==
      parseInt(cnpj.substring(13, 14))
    ) {
      return false;
    }

    return true;
  }

  private isExceptionCnpj(cnpj: any) {
    const lstCpfException = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ];

    return lstCpfException.includes(cnpj);
  }

  private isValidCnpjNumber(cnpj: any) {
    if (cnpj === '' || cnpj === undefined || cnpj === null || cnpj.legth < 14)
      return false;

    return true;
  }

  private sumCnpjDigit(cnpj: any, length: any) {
    let pos = length - 7;
    let sumDigit = 0;

    for (let i = length; i >= 1; i--) {
      sumDigit += cnpj.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    return sumDigit;
  }

  private calculateRestCnpj(cnpj: any) {
    return cnpj % 11 < 2 ? 0 : 11 - (cnpj % 11);
  }
}

export default new CNPJ();
