/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components';
import Card from '../../../../../components/Card/card';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiDashboard } from '../../api';
import { cardsProps } from '../../types';

interface props {
  companyId: number;
  period: string;
}

const DayIndicators: React.FC<props> = ({ companyId, period }) => {
  const [totalContracts, setTotalContracts] = useState<cardsProps>();
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [isLoadingPredicted, setIsLoadingPredicted] = useState(false);
  const [totalContractsImplemented, setTotalContractsImplemented] =
    useState<cardsProps>();
  const [totalContratosCanceled, setTotalContratosCanceled] =
    useState<cardsProps>();

  useEffect(() => {
    const fetchAllContracts = async () => {
      setIsLoadingPredicted(true);
      const res = await ApiDashboard.getTotalContractsOpen(companyId, period);

      if (typeof res !== 'string') {
        setTotalContracts(res);
      }
      setIsLoadingPredicted(false);
    };

    const fetchAllContractsImplemented = async () => {
      setIsLoadingTotal(true);
      const res = await ApiDashboard.getTotalContractsActive(companyId, period);

      if (typeof res !== 'string') {
        setTotalContractsImplemented(res);
      }
      setIsLoadingTotal(false);
    };

    const fetchAllContractsCanceled = async () => {
      setIsLoadingTotal(true);
      const res = await ApiDashboard.getTotalContractsCanceled(
        companyId,
        period
      );

      if (typeof res !== 'string') {
        setTotalContratosCanceled(res);
      }
      setIsLoadingTotal(false);
    };

    fetchAllContracts();
    fetchAllContractsImplemented();
    fetchAllContractsCanceled();
  }, [period]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
      <Card className="flex-col p-4">
        {isLoadingTotal ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Total de contratos
              </p>
            </div>

            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-green">
                {maskHelper.formatMoeda(totalContracts?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">
                {`${Number(totalContracts?._count ?? '').toLocaleString(
                  'pt-br'
                )} Contrato(s)`}
              </p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Contratos implantados
              </p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-green">
                {maskHelper.formatMoeda(totalContractsImplemented?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">
                {`${Number(
                  totalContractsImplemented?._count ?? ''
                ).toLocaleString('pt-br')} Contrato(s)`}
              </p>
            </div>
          </>
        )}
      </Card>
      <Card className="flex-col p-4">
        {isLoadingPredicted ? (
          <div className="flex items-center justify-center h-[60px]">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="font-medium text-xs uppercase">
                Contratos cancelados
              </p>
            </div>
            <div className="flex flex-col self-end">
              <p className="font-medium text-lg text-gray-600">
                {maskHelper.formatMoeda(totalContratosCanceled?._sum ?? '')}
              </p>
              <p className="text-xs text-gray-600">
                {`${Number(totalContratosCanceled?._count ?? '').toLocaleString(
                  'pt-br'
                )} Contrato(s)`}
              </p>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default DayIndicators;
