/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal } from '../../../components';

interface props {
  openModalActiveRandom: boolean;
  setOpenModalActiveRandom: (e: boolean) => void;
  isLoadingSubmit: boolean;
  changeRandomSwitch: (e: boolean) => void;
}
const ModalRandom: React.FC<props> = ({
  openModalActiveRandom,
  setOpenModalActiveRandom,
  isLoadingSubmit,
  changeRandomSwitch,
}) => (
  <Modal
    title="Ativar distribuição automática"
    setIsOpen={setOpenModalActiveRandom}
    isOpen={openModalActiveRandom}
    minHeight="min-h-[250px]"
  >
    <p className="text-sm ">
      Ao ativar a distribuição randômica os vendedores receberão os leads em
      ordem de fila e não mais pela configuração padrão definida abaixo. Deseja
      realmente ativar?
    </p>
    <div className="flex gap-3 mt-5">
      <Button
        variant="outline-primary"
        onClick={() => setOpenModalActiveRandom(false)}
        disabled={isLoadingSubmit}
        actionType="button-loading"
      >
        Não
      </Button>
      <Button
        variant="primary"
        onClick={() => changeRandomSwitch(true)}
        disabled={isLoadingSubmit}
        isLoading={isLoadingSubmit}
        actionType="button-loading"
      >
        Sim
      </Button>
    </div>
  </Modal>
);

export default ModalRandom;
