import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spinner, Switch } from '../../../../components';
import { Profile } from '../types';
import { ApiProfile } from '../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable: (e: string) => void;
  profile: Profile | null;
}

const RegisterProfile: React.FC<registerModalProps> = ({
  profile,
  reloadTable,
  setShow,
  show,
}) => {
  const { notify } = ToastNotify();
  const [values, setValues] = useState<Omit<Profile, 'id'>>({
    title: profile ? profile?.title : '',
    is_admin: profile ? profile?.is_admin : false,
    is_franchise: profile ? profile?.is_franchise : false,
    is_franchisor: profile ? profile?.is_franchisor : false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async () => {
    setIsLoading(true);
    if (profile !== null) {
      const res = await ApiProfile.updateProfile(values, profile.id);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Perfil atualizado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} update`);
        setShow(false);
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } else {
      const res = await ApiProfile.createProfile(values);
      if (res.id) {
        setIsLoading(false);
        notify({ message: 'Perfil criado com sucesso', type: 'Success' });
        reloadTable(`${Math.random()} create`);
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    }
  };

  useEffect(() => {
    setValues({
      title: profile?.title ?? '',
      is_admin: profile?.is_admin ?? false,
      is_franchise: profile?.is_franchise ?? false,
      is_franchisor: profile?.is_franchisor ?? false,
    });
  }, [profile, show]);
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      title={profile ? 'Atualizar Perfil' : 'Criar Perfil'}
      size="medium"
      minHeight="min-h-[300px]"
    >
      <div className="flex flex-col">
        <Input
          variant="outline-orange"
          title="Nome"
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
        />
        <p className="text-sm font-normal mb-2">Perfil Admin</p>
        <Switch
          onChange={() => setValues({ ...values, is_admin: !values.is_admin })}
          state={values.is_admin}
        />

        <p className="text-sm font-normal mt-3 mb-2">Perfil Franqueador</p>
        <Switch
          onChange={() =>
            setValues({ ...values, is_franchisor: !values.is_franchisor })
          }
          state={values.is_franchisor}
        />

        <p className="text-sm font-normal mt-3 mb-2">Perfil Franquia</p>
        <Switch
          onChange={() =>
            setValues({ ...values, is_franchise: !values.is_franchise })
          }
          state={values.is_franchise}
        />

        <Button
          disabled={values.title.length === 0 || isLoading}
          variant="primary-strong"
          onClick={Submit}
          className="font-bold relative flex items-center justify-center mt-5 w-64 mx-auto"
        >
          Salvar
          {isLoading && (
            <div className="absolute right-0">
              <Spinner />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default RegisterProfile;
