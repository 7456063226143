import React from 'react';

import { Button, Card, Modal } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

import { useWppContext } from '../../context';

export const Limit: React.FC = () => {
  const { modal, setModal } = useWppContext();

  return (
    <Modal
      title=""
      header={false}
      setIsOpen={() => setModal({ show: 'undefined' })}
      isOpen={modal.show === 'limit'}
      minHeight="min-h-[100px]"
    >
      <Card className="flex justify-center items-center flex-col w-full pt-5 px-4 pb-3.5 gap-4">
        <IconDictionary
          color="#E63B2E"
          name="ExclamationTriangleFill"
          size={50}
        />
        <strong>Limite de instâncias excedido</strong>
        <p>Deseja atualizar seu plano para obter mais instâncias?</p>
        <div className="flex items-center justify-around">
          <Button
            type="submit"
            variant="outline-primary"
            actionType="button-loading"
            className="w-[150px]"
            onClick={() => setModal({ show: 'undefined' })}
          >
            <strong className="text-sm font-medium">Não</strong>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            type="submit"
            className="w-[150px] bg-primary my-1"
            actionType="button-loading"
            onClick={() => setModal({ show: 'purchase' })}
          >
            <strong className="text-sm font-medium">Sim</strong>
          </Button>
        </div>
      </Card>
    </Modal>
  );
};
