import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import Register from '../components/register';
import MainPage from '../../main/components/mainPage';
import { useFetchProvider } from '../hooks';
import { ProviderProps } from '../types';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { DeleteModal } from '../../../components';
import { ApiProvider } from '../api';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

const Providers: React.FC = () => {
  const userInfo = getTokenAccountInformation();
  const { notify } = ToastNotify();
  const companyId = userInfo.companie_id;

  const [key, setKey] = useState('key');
  const { ProviderList, isLoading } = useFetchProvider(key, companyId);
  const [input, setInput] = useState('');
  const [provider, setProvider] = useState<ProviderProps | null>(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalProviders, setModalProviders] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const columns = [
    {
      name: 'Nome/R. Social',
      key: 'nomeRSocial',
      selector: (row: ProviderProps) => row.name,
    },
    {
      name: 'Apelido/Nome Fantasia',
      key: 'apelidoNomeFantasia',
      selector: (row: ProviderProps) => row.name_fantasy,
    },
    {
      name: 'CPF/CNPJ',
      key: 'cpfCnpj',
      selector: (row: ProviderProps) => row.document,
      cell: (row: ProviderProps) =>
        row.document && maskHelper.cnpjCpf(row.document),
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: ProviderProps) => row.whatsapp,
      cell: (row: ProviderProps) =>
        row.whatsapp && maskHelper.phone(row.whatsapp),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: ProviderProps) => row.email,
      hide: 800,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: ProviderProps) => row.status,
      hide: 1366,
    },
    {
      name: '',
      cell: (row: ProviderProps) => (
        <CloseOutline
          size={20}
          className="cursor-pointer"
          color="#DA3C3C"
          onClick={() => {
            setProvider(row);
            setShowModalDelete(true);
          }}
        />
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return ProviderList;
    }
    return ProviderList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.name_fantasy?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.document?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.whatsapp?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.email?.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  const reloadTable = (e: string) => {
    setKey(e);
    if (provider !== null) {
      const idProvider = provider.id;
      setProvider(ProviderList.find((item) => item.id === idProvider) ?? null);
    }
  };

  return (
    <>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading}
        column={columns}
        filterTable={filterTable}
        title="Fornecedores"
        setRegisterPage={() => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setProvider(null);
        }}
        setInput={setInput}
        setModalCreateUpdate={setModalProviders}
        onRowClick={(e: ProviderProps) => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setProvider(e);
          setModalProviders(true);
        }}
      />
      <Register
        setProvider={setProvider}
        companyUserId={userInfo.id}
        provider={provider}
        reloadTable={reloadTable}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalProviders}
        show={modalProviders}
      />
      {provider && (
        <DeleteModal
          type="Fornecedor"
          article="O"
          apiFunction={ApiProvider.deleteProvider}
          notify={notify}
          updateTable={() => setKey('delete')}
          close={() => setShowModalDelete(false)}
          id={provider.id}
          name={provider.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Providers;
