import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SignatureTransactionsPayload,
  SignatureTransactionsResponse,
  StatusType,
  Transaction,
  getSignatureExtract,
} from '../api';
import maskHelper from '../../../../helpers/mask.helper';
import { Modal, Spinner, Table } from '../../../../components';
import { ModalProps } from '../../../../components/Modal/Modal';
import { debounce } from '../../../../helpers/utils.helper';

const StatusColorMap: Record<StatusType, string> = {
  'Ag.Pagamento': 'black',
  Ativo: 'green',
  Debitado: 'red',
  Cancelado: 'black',
  Inadimplente: 'black',
};

const VisualizeModal = (props: Omit<ModalProps, 'title' | 'children'>) => {
  const modalRef = useRef<any>();
  const [transactions, setTransactions] =
    useState<SignatureTransactionsResponse | null>(null);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [take, setTake] = useState(10);
  const [
    loadingTransactionsInfiniteScroll,
    setLoadingTransactionsInfiniteScroll,
  ] = useState(false);

  const loadSignatureTransactions = useCallback(
    async (payload: SignatureTransactionsPayload) => {
      setLoadingTransactions(true);
      try {
        const response = await getSignatureExtract(payload);
        setTransactions(response);
      } finally {
        setLoadingTransactions(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!props.isOpen) return;
    loadSignatureTransactions({
      status: [
        'Ag.Pagamento',
        'Ativo',
        'Cancelado',
        'Debitado',
        'Inadimplente',
      ],
      take: 10,
      filter: 'all',
      skip: 0,
      type: 'all',
    });
  }, [loadSignatureTransactions, props.isOpen, take]);

  useEffect(() => {
    if (!props.isOpen) return;
    const debounceFunction = debounce(async () => {
      setLoadingTransactionsInfiniteScroll(true);
      await loadSignatureTransactions({
        take: take + 50,
        skip: 0,
        type: 'all',
        filter: 'all',
        status: [
          'Ag.Pagamento',
          'Ativo',
          'Cancelado',
          'Debitado',
          'Inadimplente',
        ],
      });
      setTake((prev) => prev + 50);
      setLoadingTransactionsInfiniteScroll(false);
    }, 200);
    const listener = () => {
      if (
        !loadingTransactionsInfiniteScroll &&
        modalRef.current.scrollTop + modalRef.current.offsetHeight >=
          modalRef.current.scrollHeight
      ) {
        debounceFunction();
      }
    };
    setTimeout(() => {
      modalRef.current?.addEventListener('scroll', listener);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => {
      modalRef.current.removeEventListener('scroll', listener);
    };
  }, [props.isOpen, loadingTransactionsInfiniteScroll]);
  const columns = [
    {
      name: 'Descrição',
      key: 'description',
      selector: (row: Transaction) =>
        !row.contract_id
          ? `Compra Crédito: ${row.companie_digital_signature_credit.digital_signature_credit.name}`
          : `Debitado no contrato: ${row.contract.name}`,
    },
    {
      name: 'Valor',
      key: 'value',
      selector: (row: Transaction) =>
        !row.contract_id &&
        maskHelper.formatMoeda(
          row.companie_digital_signature_credit.value ?? 0
        ),
    },
    {
      name: 'Crédito',
      key: 'credit',
      selector: (row: Transaction) => (!row.contract ? row.qty : ''),
    },
    {
      name: 'Débito',
      key: 'debit',
      selector: (row: Transaction) => (row.contract ? 1 : ''),
    },
    {
      name: 'Data',
      key: 'created_at',
      selector: (row: Transaction) =>
        new Date(row.created_at).toLocaleDateString(),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: Transaction) => {
        const status = row.contract_id
          ? 'Debitado'
          : row.companie_digital_signature_credit.status;
        return (
          <p
            style={{
              color: StatusColorMap[status],
            }}
          >
            {status}
          </p>
        );
      },
    },
    {
      name: 'Ações',
      key: '',
      selector: () => '',
    },
  ];
  return (
    <Modal title="visualizar" {...props} size="6xlarge">
      <div className="w-full max-h-[500px] overflow-scroll" ref={modalRef}>
        <Table
          columns={columns}
          data={transactions?.data ?? []}
          isLoading={loadingTransactions}
        />
        {loadingTransactionsInfiniteScroll &&
          (transactions?.data?.length ?? 0) < (transactions?.total ?? 0) && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}
      </div>
    </Modal>
  );
};

export default VisualizeModal;
