import React, { ComponentPropsWithRef } from 'react';

import { Spinner } from '../../../components';

type ButtonProps = ComponentPropsWithRef<'button'>;
interface FullProps extends ButtonProps {
  isLoading?: boolean;
  variant?: 'orange' | 'green';
}
const ButtonRounded: React.FC<FullProps> = ({
  children,
  isLoading,
  variant = 'orange',
  ...props
}) => (
  <button
    type="button"
    className={`disabled:bg-gray-400 disabled:opacity-50 h-12 rounded-2xl text-base ${
      variant === 'orange'
        ? 'bg-gradient-to-r from-[#03a6c7] to-[#037f98] text-white font-semibold w-full'
        : 'bg-gray-200 text-green-400 w-80 font-bold'
    } mt-5 flex items-center justify-center relative `}
    {...props}
  >
    {children}
    {isLoading && (
      <div className="absolute right-0">
        <Spinner />
      </div>
    )}
  </button>
);

export default ButtonRounded;
