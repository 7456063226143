import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';
import { SendDocumentPJProps, propsModal } from '../../../../types';
import { ApiCompanyGalaxPay } from '../../../../api';
import {
  Button,
  Input,
  InputDate,
  SelectComponent,
} from '../../../../../../../../components';
import maskHelper from '../../../../../../../../helpers/mask.helper';
import { dictionaryError } from '../../../../../../../../helpers/utils.helper';
import { notify } from '../../../../../../../../components/Toast/toast';
import { OptionsSelectProp } from '../../../../../../../../components/Select/select';
import CompanyUpload from './companyUpload';
import PersonalUpload from './personalUpload';
import AlertCard from '../../../../../../../../components/Card/alertCard';

const DocumentsPJ: React.FC<propsModal> = ({
  nextPage,
  defaultValue,
  disabledEdit,
}) => {
  const optionsTypeCompanie = [
    { label: 'Sócio', value: 'partner' },
    { label: 'Procurador', value: 'attorney' },
    { label: 'Pessoa Envolvida', value: 'personinvolved' },
  ];
  const optionsDocumentCompanie = [
    { label: 'RG', value: 'RG' },
    { label: 'CNH', value: 'CNH' },
  ];

  const [frontDoc, setFrontDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.front ?? null
  );
  const [backDoc, setBackDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.back ?? null
  );
  const [selfieDoc, setSelfieDoc] = useState<string | null>(
    defaultValue?.Documents?.Personal?.RG?.selfie ?? null
  );

  const [documentType, setDocumentType] = useState<OptionsSelectProp>(
    optionsDocumentCompanie[0]
  );

  const [generalDataValue, setGeralData] = useState<SendDocumentPJProps>({
    galaxId: defaultValue?.galaxPayId ?? -1,
    Fields: {
      monthlyIncome: 0,
      about: '',
      socialMediaLink: '',
    },
    Associate: {
      document: '',
      name: '',
      motherName: '',
      birthDate: '',
      type: optionsTypeCompanie[0].value,
    },
    Documents: {
      Company: {
        cnpjCard: defaultValue?.Documents?.Company?.cnpjCard ?? '',
        electionRecord: defaultValue?.Documents?.Company?.electionRecord ?? '',
        lastContract: defaultValue?.Documents?.Company?.lastContract ?? '',
        statute: defaultValue?.Documents?.Company?.statute ?? '',
      },
      Personal: {
        CNH: {
          selfie: defaultValue?.Documents?.Personal?.CNH?.selfie ?? '',
          picture: defaultValue?.Documents?.Personal?.CNH?.picture ?? ['', ''],
        },
        RG: {
          selfie: defaultValue?.Documents?.Personal?.RG?.selfie ?? '',
          front: defaultValue?.Documents?.Personal?.RG?.front ?? '',
          back: defaultValue?.Documents?.Personal?.RG?.back ?? '',
        },
      },
    },
  });

  const schema = yup.object().shape({
    Fields: yup.object().shape({
      monthlyIncome: yup.number().required('Campo Obrigatório'),
      about: yup.string().required('Campo Obrigatório'),
      socialMediaLink: yup.string().required('Campo Obrigatório'),
    }),
    Associate: yup.object().shape({
      document: yup.string().required('Campo Obrigatório'),
      name: yup.string().required('Campo Obrigatório'),
      motherName: yup.string().required('Campo Obrigatório'),
      birthDate: yup.date().required('Campo Obrigatório'),
      type: yup
        .string()
        .oneOf(['partner', 'attorney', 'personinvolved'])
        .required('Campo Obrigatório'),
    }),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, setValue, formState } = useForm<SendDocumentPJProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      Associate: {
        type: optionsTypeCompanie[0].value,
      },
    },
  });

  const Submit = async (values: SendDocumentPJProps) => {
    if (defaultValue !== null) {
      if (!selfieDoc || !frontDoc || !backDoc) {
        notify({
          message: 'O envio de todos os Documentos Pessoais é obrigatório',
          type: 'Error',
        });
        return;
      }
      let ValidationLastContract = ['ltda', 'eireli', 'slu'];
      if (
        ValidationLastContract.includes(defaultValue.typeCompany) &&
        !generalDataValue.Documents.Company.lastContract
      ) {
        notify({
          message:
            'O envio de Contrato social ou Última alteração é obrigatório para esse tipo de empresa',
          type: 'Error',
        });
        return;
      }

      ValidationLastContract = ['individualEntrepreneur', 'mei'];
      if (
        ValidationLastContract.includes(defaultValue.typeCompany) &&
        !generalDataValue.Documents.Company.cnpjCard
      ) {
        notify({
          message:
            'O envio do Documento CNPJ é obrigatório para esse tipo de empresa',
          type: 'Error',
        });
        return;
      }

      ValidationLastContract = ['association', 'sa'];
      if (
        ValidationLastContract.includes(defaultValue.typeCompany) &&
        !generalDataValue.Documents.Company.electionRecord
      ) {
        notify({
          message:
            'O envio da Ata de eleição da diretoria é obrigatório para esse tipo de empresa',
          type: 'Error',
        });
        return;
      }
      ValidationLastContract = ['association', 'sa'];
      if (
        ValidationLastContract.includes(defaultValue.typeCompany) &&
        !generalDataValue.Documents.Company.statute
      ) {
        notify({
          message:
            'O envio do Estatuto é obrigatório para esse tipo de empresa',
          type: 'Error',
        });
        return;
      }

      const docs =
        documentType.value === 'RG'
          ? {
              RG: {
                selfie: selfieDoc?.replace('data:', '').replace(/^.+,/, ''),
                front: frontDoc?.replace('data:', '').replace(/^.+,/, ''),
                back: backDoc?.replace('data:', '').replace(/^.+,/, ''),
              },
            }
          : {
              CNH: {
                selfie: selfieDoc?.replace('data:', '').replace(/^.+,/, ''),
                picture: [
                  frontDoc?.replace('data:', '').replace(/^.+,/, ''),
                  backDoc?.replace('data:', '').replace(/^.+,/, ''),
                ],
              },
            };
      const payload = {
        ...values,
        Associate: [values.Associate],
        galaxId: generalDataValue.galaxId,
        Documents: {
          Company: {
            lastContract: generalDataValue.Documents.Company.lastContract
              ?.replace('data:', '')
              .replace(/^.+,/, ''),
            statute: generalDataValue.Documents.Company.statute
              ?.replace('data:', '')
              .replace(/^.+,/, ''),
            cnpjCard: generalDataValue.Documents.Company.cnpjCard
              ?.replace('data:', '')
              .replace(/^.+,/, ''),
            electionRecord: generalDataValue.Documents.Company.electionRecord
              ?.replace('data:', '')
              .replace(/^.+,/, ''),
          },
          Personal: docs,
        },
      };
      setIsLoading(true);
      const response = await ApiCompanyGalaxPay.sendDocumentsPJ(payload);
      if (response.type === true) {
        notify({
          message: 'Documentos enviados com sucesso',
          type: 'Success',
        });
        setIsLoading(false);
        nextPage(response);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        setIsLoading(false);
      }
    }
  };
  const handleDocumentChange = (type: OptionsSelectProp) => {
    const rg = defaultValue?.Documents?.Personal?.RG;
    const cnh = defaultValue?.Documents?.Personal?.CNH;
    if (type.value === 'RG') {
      setSelfieDoc(rg?.selfie ?? '');
      setFrontDoc(rg?.front ?? '');
      setBackDoc(rg?.back ?? '');
    } else if (type.value === 'CNH') {
      setSelfieDoc(cnh?.selfie ?? '');
      setFrontDoc(cnh?.picture[0] ?? '');
      setBackDoc(cnh?.picture[1] ?? '');
    }
  };
  const handleDocumentTypeChange = (type: OptionsSelectProp) => {
    setDocumentType(type);
    handleDocumentChange(type);
  };
  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-4 gap-4 mt-8 text-sm"
      onSubmit={handleSubmit(Submit)}
    >
      {defaultValue.Verification &&
        defaultValue.Verification.Reasons?.length > 0 && (
          <div className="sm:col-span-4">
            <AlertCard
              message={
                <div>
                  Pendências:{' '}
                  {defaultValue.Verification.Reasons.map((item: string) => (
                    <p>
                      {item}
                      <br />
                    </p>
                  ))}
                </div>
              }
            />
          </div>
        )}
      <strong className="text-sm col-span-4">Informações Negócio</strong>
      <Input
        disabled={disabledEdit}
        variant="outline-orange"
        title="Renda Mensal"
        value={maskHelper.formatCurrencyInput(
          String(generalDataValue.Fields.monthlyIncome)
        )}
        onChange={(e) => {
          const formatedInput = Number(maskHelper.number(e.target.value));
          setGeralData({
            ...generalDataValue,
            Fields: {
              ...generalDataValue.Fields,
              monthlyIncome: formatedInput,
            },
          });
          setValue('Fields.monthlyIncome', formatedInput);
        }}
        errorMensage={formState.errors.Fields?.monthlyIncome?.message}
      />

      <Input
        disabled={disabledEdit}
        variant="outline-orange"
        title="Link de Mídia Social"
        value={generalDataValue.Fields.socialMediaLink}
        onChange={(e) => {
          setGeralData({
            ...generalDataValue,
            Fields: {
              ...generalDataValue.Fields,
              socialMediaLink: e.target.value,
            },
          });
          setValue('Fields.socialMediaLink', e.target.value);
        }}
        errorMensage={formState.errors.Fields?.socialMediaLink?.message}
      />
      <Input
        disabled={disabledEdit}
        variant="outline-orange"
        classNameDiv="col-span-2"
        title="Sobre o Negócio"
        value={generalDataValue.Fields.about}
        onChange={(e) => {
          setGeralData({
            ...generalDataValue,
            Fields: { ...generalDataValue.Fields, about: e.target.value },
          });
          setValue('Fields.about', e.target.value);
        }}
        errorMensage={formState.errors.Fields?.about?.message}
      />
      <hr className="w-full h-px -mb-1 rounded-md mb-1 bg-primary col-span-4" />
      <strong className="text-sm col-span-4">Associado</strong>

      <Input
        disabled={disabledEdit}
        variant="outline-orange"
        title="Nome Completo do Associado"
        classNameDiv="col-span-2"
        value={generalDataValue.Associate.name}
        onChange={(e) => {
          setGeralData({
            ...generalDataValue,
            Associate: {
              ...generalDataValue.Associate,
              name: e.target.value,
            },
          });
          setValue('Associate.name', e.target.value);
        }}
        errorMensage={formState.errors.Associate?.name?.message}
      />
      <Input
        disabled={disabledEdit}
        variant="outline-orange"
        title="Nome da Mãe"
        classNameDiv="col-span-2"
        value={generalDataValue.Associate.motherName}
        onChange={(e) => {
          setGeralData({
            ...generalDataValue,
            Associate: {
              ...generalDataValue.Associate,
              motherName: e.target.value,
            },
          });
          setValue('Associate.motherName', e.target.value);
        }}
        errorMensage={formState.errors.Associate?.motherName?.message}
      />
      <div className="col-span-3 grid-cols-1 md:grid grid-cols-2 gap-3">
        <Input
          disabled={disabledEdit}
          title="CPF Associado"
          variant="outline-orange"
          type="string"
          value={maskHelper.cpf(generalDataValue.Associate.document)}
          onChange={(e) => {
            e.target.value = maskHelper.cpf(e.target.value);
            setGeralData({
              ...generalDataValue,
              Associate: {
                ...generalDataValue.Associate,
                document: e.target.value,
              },
            });
            setValue(
              'Associate.document',
              maskHelper.numberCPFCNPJ(e.target.value)
            );
          }}
          errorMensage={formState.errors.Associate?.document?.message}
        />
        <SelectComponent
          isDisabled={disabledEdit}
          title="Tipo de Associado"
          value={optionsTypeCompanie.find(
            (elem) => elem.value === generalDataValue.Associate.type
          )}
          options={optionsTypeCompanie}
          onChange={(e: OptionsSelectProp) => {
            setGeralData({
              ...generalDataValue,
              Associate: { ...generalDataValue.Associate, type: e.value },
            });
            setValue('Associate.type', e.value);
          }}
        />
      </div>
      <InputDate
        disabled={disabledEdit}
        className="col-span-2 lg:col-span-1"
        title="Data de Nascimento"
        defaultValue={moment(generalDataValue.Associate.birthDate).toDate()}
        onChange={(e) => {
          if (e !== null) {
            setGeralData({
              ...generalDataValue,
              Associate: {
                ...generalDataValue.Associate,
                birthDate: maskHelper.formatDateYMD(
                  String(e.toLocaleString('pt-BR'))
                ),
              },
            });
            setValue(
              'Associate.birthDate',
              maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
            );
          }
        }}
        errorMensage={formState.errors.Associate?.birthDate?.message}
      />
      <CompanyUpload
        typeCompanie={defaultValue.typeCompany}
        disabledEdit={disabledEdit}
        generalDataValue={generalDataValue}
        setGeralData={setGeralData}
      />

      <PersonalUpload
        disabledEdit={disabledEdit}
        documentType={documentType}
        optionsDocumentCompanie={optionsDocumentCompanie}
        handleDocumentTypeChange={handleDocumentTypeChange}
        backDoc={backDoc}
        frontDoc={frontDoc}
        selfieDoc={selfieDoc}
        setBackDoc={setBackDoc}
        setFrontDoc={setFrontDoc}
        setSelfieDoc={setSelfieDoc}
      />

      <div className="col-span-4 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

export default DocumentsPJ;
