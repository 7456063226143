import { baseApiPrivate } from '../../../api/baseApi';
import { FormModel, FormResponse } from '../types';

async function getSearchFormByCompany() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/searchForm`,
  });
}
async function getSearchFormByCompanyActive() {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/searchForm/active`,
  });
}
async function getSearchFormById(id: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/searchForm/${id}`,
  });
}
async function getAnswersByOpportunity(opportunityId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/response/searchForm/opportunity/${opportunityId}`,
  });
}

async function getFormByID(companieId: number, searchFormId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/searchForm/${companieId}/${searchFormId}`,
  });
}
async function createSearchForm(payload: FormModel) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/searchForm',
    body: payload,
  });
}

async function createSearchResponseForm(payload: FormResponse) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/response/searchForm',
    body: payload,
  });
}

async function updateSearchForm(payload: FormModel, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/searchForm/${id}`,
    body: payload,
  });
}

async function updateSearchFormStatus(status: boolean, id: number) {
  return baseApiPrivate({
    method: 'PUT',
    url: `companie/searchForm/status/${id}`,
    body: { is_active: status },
  });
}

async function deleteSearchForm(id: number) {
  return baseApiPrivate({
    method: 'DELETE',
    url: `companie/searchForm/${id}`,
  });
}

export const ApiSearchForm = {
  getFormByID,
  getSearchFormByCompany,
  getSearchFormByCompanyActive,
  getSearchFormById,
  getAnswersByOpportunity,
  createSearchForm,
  createSearchResponseForm,
  updateSearchForm,
  deleteSearchForm,
  updateSearchFormStatus,
};
