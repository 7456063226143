import React from 'react';
import Select, { components, ControlProps } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import IconDictionary from '../Icons/icons';
import { getTextColorByOpacity } from '../../helpers/utils.helper';
import { getTokenAccountInformation } from '../../helpers/token.helper';
import { useTheme } from '../../modules/main/hooks';

export type OptionsSelectProp = {
  label: string;
  value: any;
  isdisabled?: boolean;
  color?: string;
  icon?: string;
};

interface SelectProps extends StateManagerProps {
  onChange?: any;
  defaultValue?: { label: string; value: any };
  colorInputText?: string;
  autoComplete?: boolean;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  options: OptionsSelectProp[];
  title?: string;
  icon?: string;
  errorMensage?: string;
  hasDivision?: boolean;
  variant?: 'gray' | 'outline-primary';
  maxOptionsHeight?: string;
  classNameDiv?: string;
  primaryColor?: string;
}

const SelectComponent: React.FC<SelectProps> = ({
  closeMenuOnSelect = true,
  defaultValue,
  isMulti = false,
  autoComplete = false,
  colorInputText,
  options,
  onChange,
  title,
  icon,
  errorMensage,
  hasDivision = false,
  variant = 'gray',
  maxOptionsHeight,
  classNameDiv,
  primaryColor = 'var(--primary)',
  ...props
}: SelectProps) => {
  const accoutProps = getTokenAccountInformation();
  const { theme } = useTheme(accoutProps?.companie_id);
  const getStyleVariant = {
    gray: {
      borderColor: '#E6E6E6',
      color: colorInputText ?? '#000',
    },

    'outline-primary': {
      borderColor: primaryColor,
      color: '#8C8C8C',
    },
  };

  const style = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state.isDisabled
        ? '#E6E6E6'
        : errorMensage
        ? primaryColor
        : getStyleVariant[variant].borderColor,
      color: '#000',
      fontSize: '12px',
      padding: '3px 4px',
      borderRadius: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: primaryColor,
      },
      '&:focus': {
        borderColor: primaryColor,
      },
    }),

    option: (base: any, { isDisabled, isSelected, data }: any) => ({
      ...base,
      cursor: 'pointer',
      paddingLeft: hasDivision ? (isDisabled ? '12px' : '28px') : '12px',
      color: isDisabled
        ? '#b4b4b4'
        : data.color
        ? data.color
        : isSelected
        ? '#fff'
        : '#000',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: isDisabled ? '#fff' : 'var(--secondary)',
        color: isDisabled ? '#b4b4b4' : getTextColorByOpacity(theme.color_hex),
      },
      backgroundColor: isSelected ? primaryColor : '#fff',
    }),

    menu: (base: any) => ({
      ...base,
      zIndex: 11,
    }),

    menuList: (base: any) => ({
      ...base,
      maxHeight: maxOptionsHeight ?? '200px',
    }),
    singleValue: (base: any, state: any) => ({
      ...base,
      color: state.isDisabled ? '#8C8C8C' : getStyleVariant[variant].color,
      fontSize: '12px',
      fontWeight: 500,
    }),
    valueContainer: (base: any) => ({
      ...base,
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      backgroundColor: primaryColor,
      color: getTextColorByOpacity(theme.color_hex),
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      backgroundColor: primaryColor,
      color: getTextColorByOpacity(theme.color_hex),
      '&:hover': {
        color: '#fff',
        backgroundColor: primaryColor,
      },
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
  };
  const Control = ({ children, ...rest }: ControlProps) => (
    <components.Control {...rest}>
      <div className={icon ? 'ml-3 text-gray-600' : ''}>
        <IconDictionary name={icon ?? ''} size={16} />
      </div>

      {children}
    </components.Control>
  );

  interface CustomOptionProps {
    label: string;
    data: {
      label: string;
      value: any;
      isdisabled?: boolean;
      color?: string;
      icon?: string;
    };
  }

  const CustomOption: React.FC<CustomOptionProps> = ({ label, data }) => (
    <div className="flex gap-1.5">
      <IconDictionary name={data.icon ?? ''} color={data.color} />
      {label}
    </div>
  );
  return (
    <div className={`flex flex-col relative mb-1.5 ${classNameDiv}`}>
      {title && <p className="text-sm font-normal mb-1 mb-1.5">{title}</p>}
      <Select
        placeholder={autoComplete ? 'Digite...' : 'Selecione...'}
        onChange={onChange}
        closeMenuOnSelect={closeMenuOnSelect}
        defaultValue={defaultValue}
        isMulti={isMulti}
        components={icon ? { Control } : {}}
        options={options}
        formatOptionLabel={(data: any) => (
          <CustomOption data={data} label={data.label} />
        )}
        styles={style}
        isOptionDisabled={(op: any) => op.isdisabled}
        loadingMessage={() => 'Carregando...'}
        {...props}
        noOptionsMessage={({ inputValue }) => !inputValue && 'Nenhuma Opção'}
      />
      <p className="text-red text-xs absolute -bottom-4">{errorMensage}</p>
    </div>
  );
};

export default SelectComponent;
