import { baseApiGalax, baseApiPrivate } from '../../../api/baseApi';
import { IDates } from '../types';

export async function statementQuery(dates: IDates) {
  return baseApiPrivate({
    method: 'POST',
    url: 'companie/financial/balance/movements',
    body: dates,
  });
}

export async function balanceFinder(id: number) {
  return baseApiGalax({
    method: 'GET',
    url: `companie/balance/${id}`,
  });
}
