import React from 'react';
import classNames from 'classnames';
import maskHelper from '../../../../helpers/mask.helper';
import { Button } from '../../../../components';
import IconDictionary from '../../../../components/Icons/icons';

/* eslint-disable no-unused-vars */
interface contactList {
  currentInstance: number | null;
  nextPage: () => Promise<void>;
  instances: any[];
  setCurrentInstance: (e: number) => void;
  isLoadingSave: boolean;
  prevPage: () => void;
  isEditable: boolean;
}

const ConectInstance: React.FC<contactList> = ({
  instances,
  currentInstance,
  isLoadingSave,
  isEditable,
  setCurrentInstance,
  nextPage,
  prevPage,
}) => {
  const getStatus = (status: string) => {
    switch (status) {
      case 'Desconectada':
        return <strong className="text-red">{status}</strong>;
      case 'Inativa':
        return <strong className="text-gray-400">{status}</strong>;
      case 'Conectada':
        return <strong className="text-green">{status}</strong>;
      default:
        return <> </>;
    }
  };

  const handleIsCreateCampaign = async () => {
    nextPage();
  };

  return (
    <div className="w-full flex flex-col px-4 mt-5 mt-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
        {instances.map((item) => (
          <div
            title="Instância"
            className={classNames([
              'hover:border-primary cursor-pointer border-gray-400 ',
              'border border-solid  relative text-sm rounded bg-gray-200 flex flex-col py-4 px-1 gap-3 items-center',
              currentInstance === item.id ? ' border-primary' : 'opacity-70',
            ])}
          >
            <div className="absolute left-5 p-2 rounded bg-orange-500 flex item-center justify-center">
              <IconDictionary name="Whatsapp" color="#fff" size={18} />
            </div>
            <strong>Número WhatsApp</strong>
            {maskHelper.phone(item.whatsapp_number.replace('55', ''))}
            {getStatus(item.status)}
            <Button
              className={classNames([
                'hover:scale-105 transition-all duration-300 ease-in-out border hover:border-solid hover:border-gray-400',
              ])}
              actionType="button-reconnect"
              variant={item.id === currentInstance ? 'primary-strong' : 'gray'}
              onClick={() => setCurrentInstance(item.id)}
              disabled={!isEditable}
            >
              Selecionar
            </Button>
          </div>
        ))}
      </div>
      {instances.length === 0 && (
        <p className="mx-auto mt-5 text-sm">Nenhuma instância encontrada</p>
      )}
      <div className="mx-auto mt-10 flex gap-3 w-full sm:w-fit">
        <Button
          actionType="button-loading"
          variant="outline-primary"
          onClick={prevPage}
        >
          Voltar
        </Button>
        <Button
          disabled={!currentInstance || isLoadingSave}
          isLoading={isLoadingSave}
          actionType="button-loading"
          variant="primary-strong"
          onClick={handleIsCreateCampaign}
        >
          Salvar e continuar
        </Button>
      </div>
    </div>
  );
};

export default ConectInstance;
