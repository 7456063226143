import React, { memo, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight';
import DataTable from 'react-data-table-component';
import Button from '../Button/button';

import { headCells, rows, IconSort, Buttons } from './styles';
import Spinner from '../Spinner/spinner';
import { conditionalRowStyles } from '../../helpers/table.utils';

/* eslint-disable no-unused-vars */
interface ITableProps {
  columns: any[];
  data: any[];
  headerColorTransparent?: boolean;
  pagination?: boolean;
  setCurrentPage?: (e: number) => void;
  currentPage?: number;
  totalPerPage?: number;
  selectableRows?: boolean;
  onSelectedRowsChange?: (row: any) => void;
  isLoading: boolean;
  onRowClick?: (e: any) => void;
  selectedRowId?: number;
  changePageTable?: number;
  scrollTable?: boolean;
  fixedHeaderScrollHeight?: string;
  styleProps?: string;
}

const Table: React.FC<ITableProps> = ({
  columns,
  data = [],
  headerColorTransparent = false,
  pagination = false,
  selectedRowId,
  changePageTable,
  scrollTable = false,
  fixedHeaderScrollHeight = '350px',
  currentPage = 1,
  totalPerPage = pagination ? 10 : data.length,
  selectableRows = false,
  isLoading,
  styleProps = '',
  onRowClick,
  onSelectedRowsChange = () => {},
  setCurrentPage = (e: number) => {},
}: ITableProps) => {
  const [dataPagination, setDataPagination] = useState(data);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(totalPerPage);
  if (headerColorTransparent) {
    headCells.style.background = 'var(--white)';
  }

  function getPagination() {
    const pages: any = [];
    let totalPages = 0;
    const totalRegisters = data.length;
    if (pagination) {
      totalPages = Math.ceil(totalRegisters / totalPerPage);
      let minLength =
        totalPages > 3 ? (currentPage === 1 ? 0 : currentPage - 4) : 0;
      minLength = minLength < 0 ? 0 : minLength;

      const maxLengthSum = currentPage + 3;
      const maxLength =
        totalPages > 3 && maxLengthSum < totalPages ? maxLengthSum : totalPages;

      for (let i = minLength; i < maxLength; i++) {
        const numberPage = i + 1;
        pages.push(
          <Buttons
            onClick={() => {
              setCurrentPage(numberPage);
              setStart((numberPage - 1) * totalPerPage);
              setEnd(numberPage * totalPerPage);
            }}
            key={`page_${numberPage}`}
            active={numberPage === currentPage}
            number={numberPage !== currentPage}
          >
            {numberPage}
          </Buttons>
        );
      }
    }

    return pagination ? (
      <div className="flex w-full justify-center items-center gap-4 mt-2">
        {currentPage > 1 && (
          <Button className="rounded-lg w-7 h-7">
            <KeyboardArrowLeft
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setStart(start - totalPerPage);
                setEnd(end - totalPerPage);
              }}
            />
          </Button>
        )}
        <div>{totalPages > 1 && pages.map((item: ReactNode) => item)}</div>

        {currentPage < totalPages && (
          <Button className="rounded-lg w-7 h-7">
            <KeyboardArrowRight
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setStart(start + totalPerPage);
                setEnd(end + totalPerPage);
              }}
            />
          </Button>
        )}
      </div>
    ) : (
      <> </>
    );
  }

  function ChangePagePagination(numberPage: number) {
    setCurrentPage(numberPage);
    setStart((numberPage - 1) * totalPerPage);
    setEnd(numberPage * totalPerPage);
  }

  useEffect(() => {
    if (pagination) {
      setDataPagination(data.slice(start, totalPerPage * currentPage));
    } else {
      setDataPagination(data);
    }
  }, [data]);

  useEffect(() => {
    setDataPagination(data.slice(start, end));
  }, [start, end]);

  useEffect(() => {
    if (changePageTable) ChangePagePagination(changePageTable);
  }, [changePageTable]);

  return scrollTable ? (
    <div className={`relative ${styleProps}`}>
      {isLoading && (
        <div
          role="status"
          className={classNames([
            data.length === 0 ? 'h-20' : 'bg-gray-400/20',
            'z-40 absolute top-0 items-center w-full flex justify-center h-full mt-4',
          ])}
        >
          <Spinner />
        </div>
      )}
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        title=""
        columns={columns}
        noDataComponent={
          dataPagination.length > 0 || isLoading ? (
            ''
          ) : (
            <p className="mt-6 text-sm">Nenhum Registo Encontrado</p>
          )
        }
        data={dataPagination}
        noHeader
        className={`tableClass ${styleProps}`}
        defaultSortAsc
        selectableRows={selectableRows}
        sortIcon={<IconSort />}
        pagination={pagination}
        paginationComponent={() => getPagination()}
        onRowClicked={onRowClick}
        onSelectedRowsChange={onSelectedRowsChange}
        conditionalRowStyles={conditionalRowStyles(selectedRowId ?? -1)}
        customStyles={{
          headCells,
          rows: rows(Boolean(onRowClick)),
        }}
      />
    </div>
  ) : (
    <div className={`relative ${styleProps}`}>
      {isLoading && (
        <div
          role="status"
          className={classNames([
            data.length === 0 ? 'h-20' : 'bg-gray-400/20',
            'z-40 absolute top-0 items-center w-full flex justify-center h-full mt-4',
          ])}
        >
          <Spinner />
        </div>
      )}
      <DataTable
        title=""
        columns={columns}
        noDataComponent={
          dataPagination.length > 0 || isLoading ? (
            <p className="mt-16 relative"> </p>
          ) : (
            <p className="mt-6 text-sm">Nenhum Registo Encontrado</p>
          )
        }
        data={dataPagination}
        noHeader
        className={`tableClass ${styleProps}`}
        defaultSortAsc
        selectableRows={selectableRows}
        sortIcon={<IconSort />}
        pagination={pagination}
        paginationComponent={() => getPagination()}
        onRowClicked={onRowClick}
        onSelectedRowsChange={onSelectedRowsChange}
        conditionalRowStyles={conditionalRowStyles(selectedRowId ?? -1)}
        customStyles={{
          headCells,
          rows: rows(Boolean(onRowClick)),
        }}
      />
    </div>
  );
};

export default memo(Table);
