import React, { useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { ToastNotify } from '../../../components/Toast/toast';
import RegisterContact from '../components/register';
import { ContactProps } from '../types';
import { useFetchTableContact } from '../hooks';
import maskHelper from '../../../helpers/mask.helper';
import { DeleteModal } from '../../../components';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ApiContact } from '../api';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import { ApiActivitie } from '../../activitie/api';
import {
  dictionaryError,
  getContrastColorWithOpacity,
} from '../../../helpers/utils.helper';
import CardImportPerson from '../components/card/import';

const Contacts: React.FC = () => {
  const accountProps = getTokenAccountInformation();
  const companyId = accountProps.companie_id;
  const { notify } = ToastNotify();
  const [key, setKey] = useState('');
  const [input, setInput] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { ContactList, isLoading } = useFetchTableContact(
    key,
    companyId,
    startDate,
    10,
    input
  );
  const [contact, setContact] = useState<ContactProps | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [modalCreateUpdateContact, setModalCreateUpdateContact] =
    useState(false);

  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const checkIfIsTheOnlyContact = (opportunityContact: any[]) => {
    for (let i = 0; i < opportunityContact.length; i++) {
      if (opportunityContact[i].opportunity.opportunity_person.length <= 1) {
        return false;
      }
    }
    return true;
  };

  const checkHasActivity = async (idPerson: number) => {
    const res = await ApiActivitie.getActivityFilters('all', {
      activitieId: [],
      companieUserIdOwner: [],
      id: [],
      opportunityId: [],
      personId: [idPerson],
      skip: 0,
      take: 0,
      status: 'Todos',
    });
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      return true;
    }
    if (res.total > 0) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      name: 'Nome',
      key: 'nome',
      selector: (row: ContactProps) => row.name,
    },
    {
      name: 'Organização',
      key: 'organization',
      selector: (row: ContactProps) => row.organization,
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: ContactProps) => row.whatsapp,
      cell: (row: ContactProps) => maskHelper.phone(row.whatsapp ?? ''),
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: ContactProps) => row.email,
      hide: 800,
    },
    {
      name: 'Tag',
      key: 'tag',
      width: '250px',
      selector: (row: ContactProps) => (
        <div className="flex flex-wrap gap-2 my-2 w-64">
          {row.person_tag.map(
            (item) =>
              item.tag.color && (
                <div
                  style={{
                    backgroundColor: `${item.tag.color}`,
                    color: getContrastColorWithOpacity(item.tag.color),
                    border:
                      getContrastColorWithOpacity(item.tag.color) !== '#ffffff'
                        ? `1px solid ${getContrastColorWithOpacity(
                            item.tag.color
                          )}`
                        : '0px',
                  }}
                  className="text-[8px] p-1 rounded-sm truncate max-w-[60px]"
                >
                  {item.tag.title}
                </div>
              )
          )}
        </div>
      ),
      hide: 800,
    },
    {
      name: '',
      cell: (row: ContactProps) => {
        if (row.opportunity_person) {
          if (checkIfIsTheOnlyContact(row.opportunity_person)) {
            return (
              <CloseOutline
                size={20}
                className="cursor-pointer"
                color="#DA3C3C"
                onClick={() => {
                  setContact(row);
                  setShowDelete(true);
                }}
              />
            );
          }
          return (
            <CloseOutline size={20} className="cursor-auto" color="#dadada" />
          );
        }
        return (
          <CloseOutline
            size={20}
            className="cursor-pointer"
            color="#DA3C3C"
            onClick={() => {
              setContact(row);
              setShowDelete(true);
            }}
          />
        );
      },
    },
  ];

  const generateCsv = async () => {
    const res = await ApiContact.getContactByCompany(
      accountProps.companie_id,
      0,
      100000000000
    );
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Nome',
          'WhatsApp (DD987654321)',
          'Data de Nascimento (DD/MM/YYYY)',
          'Organização',
          'CPF',
          'E-mail',
          'Telefone',
          'Cep',
          'Logradouro',
          'Bairro',
          'Cidade',
          'Estado',
        ],
        ...res.data.map((item) => [
          item.name,
          maskHelper.phone(item.whatsapp ?? ''),
          maskHelper.formatDateDMY(item.birth ?? ''),
          item.organization,
          item.document,
          item.email,
          maskHelper.phone(item.phone ?? ''),
          item.zipcode,
          item.address,
          item.province,
          item.city,
          item.state,
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  const searchContacts = async (value: string) => {
    setStartDate(0);
    setCurrentPage(1);
    setInput(value);
  };

  return (
    <>
      <MainPagePagination
        search={searchContacts}
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartDate}
        totalRegisters={ContactList.total}
        isLoading={isLoading}
        column={columns}
        title="Contatos"
        filterTable={() => ContactList.data}
        CustomCard={
          <div className="w-full xl:w-1/3">
            <CardImportPerson reloadTable={setKey} />
          </div>
        }
        setModalCreateUpdate={setModalCreateUpdateContact}
        setRegisterPage={() => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setContact(null);
        }}
        onRowClick={(e: ContactProps) => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setContact(e);
          setModalCreateUpdateContact(true);
        }}
        objectReport={generateCsv}
        reportAndCreate
        filename="contatos"
      />
      {contact && (
        <DeleteModal
          apiFunction={async (id) => {
            const has = await checkHasActivity(id);
            if (has === true) {
              return 'contact_has_activity';
            }
            return ApiContact.deleteContact(id);
          }}
          type="contato"
          article="O"
          notify={notify}
          updateTable={() => setKey(`${Math.random()} delete`)}
          close={() => setShowDelete(false)}
          id={contact.id}
          name={contact.name}
          open={showDelete}
        />
      )}

      <RegisterContact
        setContact={setContact}
        companyId={accountProps.id}
        contact={contact}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalCreateUpdateContact}
        show={modalCreateUpdateContact}
      />
    </>
  );
};

export default Contacts;
