import { useEffect, useState } from 'react';
import { ApiSegment } from '../api';
import { Segment } from '../types';

export function useFetchSegment(key: string, companyId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Segment[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const segment = await ApiSegment.getSegmentByCompany(companyId);
      if (typeof segment !== 'string') {
        setData(segment);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { SegmentList: data, isLoading };
}
