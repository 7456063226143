import { useEffect, useState } from 'react';
import { ApiSignatureLimitUsers } from '../api';
import { SignatureProps } from '../types';

export function useFetchSignature(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SignatureProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiSignatureLimitUsers.get();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return {
    SignatureList: data,
    isLoadingSignature: isLoading,
    setLoadingSignature: setIsLoading,
  };
}

export function useFetchPlanUsersActive(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SignatureProps[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const companies = await ApiSignatureLimitUsers.active();
      if (typeof companies !== 'string') {
        setData(companies);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return {
    PlanUsersActiveList: data,
    isLoadingPlanUsersActive: isLoading,
  };
}
