import { baseApiPrivate } from '../../../../api/baseApi';
import { indicatorsProps } from '../types';

async function getBalance(
  filterPeriod: string,
  date: 'due_date' | 'received_date',
  contractStatus: string[]
): Promise<any[] | string> {
  return baseApiPrivate({
    method: 'POST',
    body: { status_contract: contractStatus },
    url: `companie/financial/balanceSheet/${date}/${filterPeriod}`,
  });
}

async function getIndicators(
  filterPeriod: string,
  date: 'due_date' | 'received_date',
  contractStatus: string[]
): Promise<indicatorsProps | string> {
  return baseApiPrivate({
    method: 'POST',
    body: { status_contract: contractStatus },
    url: `companie/financial/balanceIndicator/${date}/${filterPeriod}`,
  });
}

export const ApiBalanceReports = {
  getBalance,
  getIndicators,
};
