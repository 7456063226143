import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { CreatePJProps, propsModal } from '../../../types';
import {
  Button,
  Input,
  SelectComponent,
  Switch,
} from '../../../../../../../components';
import maskHelper from '../../../../../../../helpers/mask.helper';
import cpfValidation from '../../../../../../../helpers/validations/cpf.validation';
import phoneValidate from '../../../../../../../helpers/validations/phone.validate';
import { optionsTypeCompanie, toBase64 } from '../../../utils';
import IconDictionary from '../../../../../../../components/Icons/icons';
import cnpjValidation from '../../../../../../../helpers/validations/cnpj.validation';
import { OptionsSelectProp } from '../../../../../../../components/Select/select';

const GeneralDataPJ: React.FC<propsModal> = ({
  nextPage,
  defaultValue,
  disabledEdit,
}) => {
  const resetValues: any = {
    document: defaultValue?.document ?? '',
    emailContact: defaultValue?.emailContact ?? '',
    canAccessPlatform: defaultValue?.canAccessPlatform ?? false,
    phone: defaultValue?.phone ?? '',
    name: defaultValue?.name ?? '',
    softDescriptor: defaultValue?.softDescriptor ?? '',
    nameDisplay: defaultValue?.nameDisplay ?? '',
    responsibleDocument: defaultValue?.responsibleDocument ?? '',
    typeCompany: defaultValue
      ? defaultValue.typeCompany
      : optionsTypeCompanie[0].value,
    logo: defaultValue ? defaultValue.logo ?? defaultValue.urlLogo : undefined,
    Address: defaultValue?.Address,
    is_leadsim_plus: true,
    is_petdetodos: false,
    cnae: defaultValue?.cnae ?? '',
  };

  const DisableEdit =
    !!(defaultValue && defaultValue.galaxPayId) || disabledEdit;

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    document: yup
      .string()
      .required('CNPJ é obrigatório')
      .test({
        message: 'CNPJ inválido',
        test: (value) =>
          value ? cnpjValidation.isValid(maskHelper.number(value)) : false,
      }),
    nameDisplay: yup.string().required('Nome é obrigatório'),
    phone: yup
      .string()
      .required('Campo obrigatório')
      .min(10, 'Campo deve conter ao menos 10 dígitos')
      .max(11, 'Campo deve conter no máximo 11 dígitos')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value ? phoneValidate.isValid(maskHelper.number(value)) : true,
      }),
    emailContact: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    logo: yup.string(),
    responsibleDocument: yup
      .string()
      .required('Campo obrigatório')
      .test({
        message: 'CNPJ inválido',
        test: (value) =>
          value ? cpfValidation.isValid(maskHelper.number(value)) : false,
      }),
    typeCompany: yup.string().required('Campo obrigatório'),
    canAccessPlatform: yup.boolean(),

    softDescriptor: yup
      .string()
      .required('Campo obrigatório')
      .max(18, 'Campo deve conter no máximo 18 dígitos'),
    cnae: yup
      .string()
      .required('Campo obrigatório')
      .min(7, 'Campo deve conter 7 dígitos'),
  });

  const [generalDataValue, setGeneralData] =
    useState<CreatePJProps>(resetValues);

  const { handleSubmit, setValue, formState } = useForm<CreatePJProps>({
    resolver: DisableEdit ? undefined : yupResolver(schema),
    defaultValues: resetValues,
  });

  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    isDragActive: isDragActive1,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    maxFiles: 1,
    multiple: false,
    onDropAccepted: async (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      const base64: string = await toBase64(fileCreateObj[0]);
      setGeneralData({ ...generalDataValue, logo: base64 });
    },
  });
  const Submit = async (values: CreatePJProps) => {
    if (defaultValue?.galaxPayId) {
      const { logo }: any = generalDataValue;
      nextPage({
        ...values,
        logo,
        canAccessPlatform: generalDataValue.canAccessPlatform,
      });
      return;
    }
    nextPage({ ...values, logo: generalDataValue.logo });
  };

  useEffect(() => {
    setGeneralData(resetValues);
  }, [defaultValue]);

  return (
    <form
      className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <Input
        variant="outline-orange"
        title="Razão Social"
        disabled={DisableEdit}
        value={generalDataValue.name}
        onChange={(e) => {
          setGeneralData({ ...generalDataValue, name: e.target.value });
          setValue('name', e.target.value);
        }}
        errorMensage={formState.errors.name?.message}
      />

      <Input
        variant="outline-orange"
        title="Nome fantasia"
        disabled={DisableEdit}
        value={generalDataValue.nameDisplay}
        onChange={(e) => {
          setGeneralData({ ...generalDataValue, nameDisplay: e.target.value });
          setValue('nameDisplay', e.target.value);
        }}
        errorMensage={formState.errors.nameDisplay?.message}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <Input
          title="CNPJ"
          variant="outline-orange"
          type="string"
          disabled={DisableEdit}
          value={maskHelper.cnpj(generalDataValue.document)}
          onChange={(e) => {
            e.target.value = maskHelper.cnpj(e.target.value);
            setGeneralData({ ...generalDataValue, document: e.target.value });
            setValue('document', maskHelper.numberCPFCNPJ(e.target.value));
          }}
          errorMensage={formState.errors.document?.message}
        />

        <Input
          title="Telefone"
          variant="outline-orange"
          disabled={DisableEdit}
          value={maskHelper.phone(generalDataValue.phone ?? '')}
          onChange={(e) => {
            e.target.value = maskHelper.phone(e.target.value);
            setGeneralData({ ...generalDataValue, phone: e.target.value });

            setValue('phone', maskHelper.numberPhone(e.target.value));
          }}
          errorMensage={formState.errors.phone?.message}
        />
        <Input
          title="CPF Responsável"
          variant="outline-orange"
          type="string"
          disabled={DisableEdit}
          value={maskHelper.cnpjCpf(generalDataValue.responsibleDocument)}
          onChange={(e) => {
            e.target.value = maskHelper.cpf(e.target.value);
            setGeneralData({
              ...generalDataValue,
              responsibleDocument: e.target.value,
            });
            setValue(
              'responsibleDocument',
              maskHelper.numberCPFCNPJ(e.target.value)
            );
          }}
          errorMensage={formState.errors.responsibleDocument?.message}
        />
      </div>

      <Input
        title="E-mail Contato"
        variant="outline-orange"
        disabled={DisableEdit}
        value={generalDataValue.emailContact}
        onChange={(e) => {
          setGeneralData({ ...generalDataValue, emailContact: e.target.value });
          setValue('emailContact', e.target.value);
        }}
        errorMensage={formState.errors.emailContact?.message}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <SelectComponent
          title="Tipo da empresa"
          isDisabled={DisableEdit}
          value={optionsTypeCompanie.find(
            (elem) => elem.value === generalDataValue.typeCompany
          )}
          options={optionsTypeCompanie}
          onChange={(e: OptionsSelectProp) => {
            setGeneralData({ ...generalDataValue, typeCompany: e.value });
            setValue('typeCompany', e.value);
          }}
          errorMensage={formState.errors.typeCompany?.message}
        />
        <Input
          variant="outline-orange"
          title="CNAE"
          disabled={DisableEdit}
          maxLength={7}
          value={generalDataValue.cnae}
          onChange={(e) => {
            setGeneralData({
              ...generalDataValue,
              cnae: maskHelper.number(e.target.value),
            });
            setValue('cnae', maskHelper.number(e.target.value));
          }}
          errorMensage={formState.errors.cnae?.message}
        />
      </div>

      <Input
        title="Nome na fatura do seu cliente"
        variant="outline-orange"
        disabled={DisableEdit}
        value={generalDataValue.softDescriptor}
        onChange={(e) => {
          setGeneralData({
            ...generalDataValue,
            softDescriptor: e.target.value,
          });
          setValue('softDescriptor', e.target.value);
        }}
        errorMensage={formState.errors.softDescriptor?.message}
      />

      <div className="flex gap-2 items-center">
        <Switch
          state={generalDataValue.canAccessPlatform ?? false}
          onChange={() => {
            setGeneralData({
              ...generalDataValue,
              canAccessPlatform: !generalDataValue.canAccessPlatform,
            });
            setValue('canAccessPlatform', generalDataValue.canAccessPlatform);
          }}
        />
        <p className="text-sm">Acessar a Plataforma do banco?</p>
      </div>

      <div
        id="form-file-upload"
        className="col-span-2 h-fit mt-3 w-full  max-w-full flex flex-col text-center relative  items-center"
      >
        <input {...getInputProps1()} />
        <label
          {...getRootProps1()}
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={`h-48 w-2/3 flex items-center justify-center border-2 relative border-dashed border-gray-600  cursor-pointer 
                ${isDragActive1 && 'border-primary'}`}
        >
          {!generalDataValue.logo &&
            (!isDragActive1 ? (
              <div className="flex flex-col items-center justify-center p-6">
                <button
                  className="upload-button cursor-pointer p-1 text-base
                    border-nonebg-transparent flex flex-col items-center justify-center w-full"
                  type="button"
                >
                  <IconDictionary
                    name="add-photo"
                    size={28}
                    color="var(--primary)"
                  />
                  <span className="text-sm font-bold text-primary">
                    Selecione sua logomarca
                  </span>
                </button>
                <p className="text-xs text-gray-400 font-medium">
                  ou arraste e solte aqui
                </p>
              </div>
            ) : (
              <p className="text-sm font-bold text-primary">
                {' '}
                Solte o arquivo aqui...
              </p>
            ))}
          {generalDataValue.logo && (
            <div className="flex flex-col items-center relative justify-center">
              <img
                src={generalDataValue.logo}
                alt="logo"
                className="h-44 object-center overflow-hidden object-contain"
                onError={() =>
                  setGeneralData({ ...generalDataValue, logo: undefined })
                }
              />
            </div>
          )}
        </label>
      </div>

      <div className="col-span-2 flex justify-center mt-6">
        <Button type="submit" actionType="button-loading">
          Continuar
        </Button>
      </div>
    </form>
  );
};

export default GeneralDataPJ;
