/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { ApiDashboard } from '../../api';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  companyId: number;
  period: string;
}
const BillingExpenseGraph: React.FC<props> = ({ companyId, period }) => {
  const [graph, setGraph] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const segment = await ApiDashboard.getFinancialBillingExpense(
        companyId,
        period
      );
      if (typeof segment !== 'string') {
        setGraph(segment);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period]);
  return !isLoading && graph !== undefined ? (
    <>
      <div className="font-medium text-xs uppercase">
        Faturamento x despesas{' '}
        <p className="text-xs text-gray-600 normal-case font-normal">
          (Recebidas e pagas nos últimos 6 meses)
        </p>
      </div>
      <div className="w-full h-full flex items-center">
        <Plot
          className="w-full"
          data={[
            {
              x: Object.values(graph).map((item: any) =>
                item.legenda.replaceAll('_', ' ')
              ),
              y: Object.values(graph).map((item: any) =>
                Number(item.valor_receita)
              ),

              text: Object.values(graph).map((item: any) =>
                maskHelper.formatMoeda(item.valor_receita)
              ),
              marker: {
                color: '#2FAC66',
              },
              type: 'bar',
              name: 'Receita',
              hoverinfo: 'name',
            },
            {
              x: Object.values(graph).map((item: any) =>
                item.legenda.replaceAll('_', ' ')
              ),
              y: Object.values(graph).map((item: any) =>
                Number(item.valor_despesa)
              ),

              text: Object.values(graph).map((item: any) =>
                maskHelper.formatMoeda(item.valor_despesa)
              ),
              marker: {
                color: '#DA3C3C',
              },
              type: 'bar',
              name: 'Despesa',
              hoverinfo: 'name',
            },
          ]}
          config={{ displayModeBar: false, responsive: true }}
          layout={{
            height: 200,
            autosize: true,
            margin: { l: 40, r: 20, b: 50, t: 28 },
            font: {
              size: 10,
            },
            xaxis: {
              showgrid: false,
            },
            yaxis: {
              showgrid: false,

              // tickmode:
              //   graph.reduce(
              //     (a, b) => Math.max(a, Number(b._count)),
              //     -Infinity
              //   ) <= 2
              //     ? 'linear'
              //     : 'auto',
              tick0: 0,
              tickformat: ' ,d',
            },
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default BillingExpenseGraph;
