import React, { useContext, useState } from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { company } from '../types';
import MainPage from '../../../main/components/mainPage';
import { useFetchCompanies } from '../hooks';
import Register from '../components/register';
import { ToastNotify } from '../../../../components/Toast/toast';
import maskHelper from '../../../../helpers/mask.helper';
import {
  Button,
  DeleteModal,
  isLoadingApplication,
  Modal,
  SelectComponent,
  TextArea,
} from '../../../../components';
import { ApiCompany } from '../api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { useFetchMenus } from '../../menu/hooks';
import { useFetchGroup } from '../../group/hooks';
import { useFetchProfile } from '../../profile/hooks';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { accessCompanie } from '../utils';

const Company: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const [key, setKey] = useState('');
  const [action, setAction] = useState<'create' | 'update'>('create');

  const { MenuList } = useFetchMenus('key');
  const { GroupList } = useFetchGroup('key');
  const { ProfileList } = useFetchProfile('key');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [input, setInput] = useState('');
  const [modalCompany, setModalCompany] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });
  const [companyData, setCompany] = useState<company | null>(null);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [defaultStatus, setDefaultStatus] = useState({ label: '', value: '' });
  const [defaultObs, setDefaultObs] = useState('');
  const [companieId, setCompanieId] = useState(0);

  const accountProps = getTokenAccountInformation();
  const { companiesList, isLoading } = useFetchCompanies(key);
  const { notify } = ToastNotify();

  const filterTable = () => {
    if (input === '') {
      return companiesList;
    }
    return companiesList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.owner.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.document.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.email.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        item.whatsapp.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
  };

  const typeCompany = (item: company) => {
    if (item.is_franchisor) return 'Franqueador';
    if (item.is_franchise_matriz) return 'Franquia Matriz';
    return 'Franquia';
  };

  const generateCsv = () => {
    const csvData = [
      [
        'ID',
        'Nome',
        'Apelido',
        'Owner',
        'CPF/CNPJ',
        'E-mail',
        'WhatsApp',
        'Tipo',
        'CEP',
        'Endereço',
        'Complemento',
        'Bairro',
        'Cidade',
        'Estado',
        'Homepage',
        'Facebook',
        'Instagram',
        'Twitter',
        'Observação',
        'Token Asaas',
        'Documento Asaas',
        'Parceiro',
      ],
      ...filterTable().map((item) => [
        item.id,
        item.name,
        item.name_fantasy,
        item.owner,
        maskHelper.cnpjCpf(item.document),
        item.email,
        maskHelper.phone(item.whatsapp),
        typeCompany(item),
        item.zipcode || '',
        item.address || '',
        item.complement || '',
        item.province || '',
        item.city || '',
        item.state || '',
        item.homepage || '',
        item.facebook || '',
        item.instagram || '',
        item.twitter || '',
        item.obs || '',
        item.asaas_token || '',
        item.asaas_document || '',
        item.partner?.name || '',
      ]),
    ];

    return csvData;
  };

  const handleDataForModal = (row: any) => {
    setModalIsOpen(true);
    setDefaultStatus({ label: String(row.status), value: row.status });
    setCompanieId(row.id);
  };

  const handlePutStatus = async () => {
    const payload = {
      status: defaultStatus.value,
      obs: defaultObs ?? '',
    };
    const res = await ApiCompany.putStatus(payload, companieId);
    setModalIsOpen(false);
    setKey(`${Math.random()} update`);
    notify({
      message: !res.status
        ? dictionaryError(res.toString())
        : 'Status Alterado com Sucesso!',
      type: !res.status ? 'Error' : 'Success',
    });
    setDefaultObs('');
  };

  const columns = [
    {
      name: 'ID',
      key: 'id',
      selector: (row: company) => row.id,
      width: '50px',
    },
    {
      name: 'Nome',
      key: 'name',
      selector: (row: company) => row.name,
      minWidth: '255px',
    },
    {
      name: 'Owner',
      key: 'owner',
      minWidth: '80px',
      selector: (row: company) => row.owner,
    },
    {
      name: 'CPF/CNPJ',
      key: 'cpf',
      selector: (row: company) => row.document,
      cell: (row: company) => maskHelper.cnpjCpf(row.document),
      minWidth: '50px',
    },
    {
      name: 'E-mail',
      key: 'email',
      selector: (row: company) => row.email,
    },
    {
      name: 'WhatsApp',
      key: 'whatsapp',
      selector: (row: company) => row.whatsapp,
      cell: (row: company) => maskHelper.phone(row.whatsapp),
    },
    {
      name: 'Tipo',
      key: 'is_matrix',
      selector: (row: company) => typeCompany(row),
    },
    {
      name: 'Parceiro',
      key: 'partner.name',
      selector: (row: company) => row.partner?.name,
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: company) => row.status,
    },
    {
      name: '',
      minWidth: '200px',
      cell: (row: company) => (
        <div className="flex gap-3">
          <button
            onClick={() => accessCompanie(row.email, setState)}
            type="button"
            className="bg-green opacity-70 transition  duration-50 hover:opacity-100 text-white py-1 px-2 rounded text-[0.54rem]"
          >
            Acessar
          </button>
          <button
            type="button"
            className="bg-amber-400 opacity-70 transition  duration-50 hover:opacity-100 text-white py-1 px-2 rounded text-[0.54rem]"
            onClick={() => handleDataForModal(row)}
          >
            Alterar Status
          </button>
          <CloseOutline
            size={20}
            className="cursor-pointer"
            color="#DA3C3C"
            onClick={() => {
              setCompany(row);
              setShowModalDelete(true);
            }}
          />
        </div>
      ),
    },
  ];
  const franchisorCompanies = companiesList
    .filter((item) => item.is_franchisor)
    .map((item) => ({ id: item.id, nome: item.name }));
  return (
    <>
      <Modal
        title="Alterar Status"
        isOpen={isModalOpen}
        setIsOpen={setModalIsOpen}
        minHeight="medium"
      >
        <div className="flex flex-col items-center">
          <div className="w-full">
            <SelectComponent
              options={[
                { label: 'Ativa', value: 'Ativa' },
                { label: 'Inativa', value: 'Inativa' },
                { label: 'Bloqueada', value: 'Bloqueada' },
              ]}
              defaultValue={defaultStatus}
              title="Status"
              onChange={(selectedOption: any) =>
                setDefaultStatus(selectedOption)
              }
            />
          </div>
          <div className="w-full mt-4">
            <TextArea
              title="Observação"
              className="w-full"
              onChange={(event) => setDefaultObs(event.target.value)}
            />
          </div>
          <Button
            variant="primary"
            className="w-[3.65rem] min-h-[2.65rem] mt-4"
            onClick={handlePutStatus}
          >
            Alterar
          </Button>
        </div>
      </Modal>
      <MainPage
        pagination={input === ''}
        isLoading={isLoading && companiesList.length === 0}
        title="Franquias e Franqueadora"
        column={columns}
        filterTable={filterTable}
        setInput={setInput}
        setModalCreateUpdate={setModalCompany}
        setRegisterPage={() => {
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setAction('create');
          setCompany(null);
        }}
        onRowClick={(e: company) => {
          setAction('update');
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
          setCompany(e);
          setModalCompany(true);
        }}
        filename="franquias_e_franqueadora"
        reportAndCreate
        objectReport={generateCsv()}
      />
      <Register
        setCompanyDefault={setCompany}
        hasFranchisor={
          companiesList.findIndex((item) => item.is_franchisor === true) !== -1
        }
        action={action}
        adminUserId={accountProps.id}
        companyDefault={companyData}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalCompany}
        show={modalCompany}
        GroupList={GroupList}
        MenuList={MenuList}
        ProfileList={ProfileList}
        franchisorCompanies={franchisorCompanies}
      />
      {companyData && (
        <DeleteModal
          apiFunction={ApiCompany.deleteCompany}
          updateTable={() => setKey(`${Math.random()} delete`)}
          notify={notify}
          type="Empresa"
          article="A"
          close={() => setShowModalDelete(false)}
          id={companyData.id}
          name={companyData.name}
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Company;
