import { useEffect, useState } from 'react';
import { ApiOffer } from '../api';
import { filtersOfferProps, payloadBackOffer } from '../types';

export function useFetchOffer(
  key: string,
  filters: filtersOfferProps,
  value: string
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackOffer>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    const FetchCompany = async () => {
      setIsLoading(true);

      if (value === '') {
        const res = await ApiOffer.getOfferFilters({
          ...filters,
          opportunityId: filters.opportunityId.map((item) => item.value),
          personId: filters.personId.map((item) => item.value),
        });
        if (typeof res !== 'string') {
          setData(res);
        }
      } else if (value.length >= 3) {
        const res = await ApiOffer.seachOffer(
          value,
          filters.skip,
          filters.take
        );
        if (typeof res !== 'string') {
          setData(res);
        }
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key, filters, value]);
  return { OfferList: data, isLoading };
}

export function useFetchOfferByOpportunity(key: string, opportunityId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackOffer>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    setIsLoading(true);
    const FetchCompany = async () => {
      const res = await ApiOffer.getOfferFilters({
        companieUserId: [],
        opportunityId: [opportunityId],
        personId: [],
        order: 'created_at_desc',
        status: 'Todos',
        filter: 'all',
        date_start: '',
        date_end: '',
        skip: 0,
        take: 30,
      });
      if (typeof res !== 'string') {
        setData(res);
      }
      setIsLoading(false);
    };

    FetchCompany();
  }, [key]);
  return { OfferList: data, isLoadingOffers: isLoading };
}

export function useFetchOfferDropdown(key: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<payloadBackOffer>({
    data: [],
    total: 0,
  });
  useEffect(() => {
    const FetchCompany = async () => {
      if (key.length >= 3) {
        setIsLoading(true);
        const offerRes = await ApiOffer.seachOffer(key, 0, 999999999);
        if (typeof offerRes !== 'string') {
          setData(offerRes);
        }
        setIsLoading(false);
      } else {
        setData({ data: [], total: 0 });
      }
    };

    FetchCompany();
  }, [key]);
  return {
    OfferListDropdown: data,
    isLoadingOfferDropdownList: isLoading,
    setOfferDropdownList: setData,
  };
}
