import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Card } from '../../../../components';
import ContactsList from './contactsList';
import NameCampaign from './nameCampaign';
import ConectInstance from './conect';
import { ApiWhatsappCampaign } from '../../api';
import { ToastNotify } from '../../../../components/Toast/toast';
import { dictionaryError } from '../../../../helpers/utils.helper';
import { useFetchWhatsappMessagesActive } from '../../../whatsappMessages/hooks';
import CreateMessages from './history';
import {
  createUpdateWhatsAppCampaign,
  whatsappCampaignProps,
} from '../../types';

interface page {
  title: string;
  page: number;
}

/* eslint-disable no-unused-vars */
interface registerModalProps {
  reloadTable: (e: string) => void;
  campaign: whatsappCampaignProps | null;
  setCampaign: (e: whatsappCampaignProps) => void;
  campaignList: whatsappCampaignProps[];
  companieId: number;
  registerPage: page;
  setRegisterPage: React.Dispatch<React.SetStateAction<page>>;
  instances: any[];
  setInputSelect: (e: string) => void;
  isLoadingWhatsappDropdown: boolean;
}

const header = [
  {
    id: 0,
    title: 'Busque os Contatos',
    page: 0,
  },
  {
    id: 1,
    title: 'Dados da Campanha',
    page: 1,
  },
  {
    id: 2,
    title: 'Conecte a Instância',
    page: 2,
  },
  {
    id: 3,
    title: 'Dispare as Mensagens',
    page: 3,
  },
];

const RegisterCampaign: React.FC<registerModalProps> = ({
  registerPage,
  setRegisterPage,
  reloadTable,
  setCampaign,
  setInputSelect,
  campaign,
  isLoadingWhatsappDropdown,
  companieId,
  campaignList,
  instances,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isEditable = campaign ? campaign.status === 'Não iniciada' : true;
  const { notify } = ToastNotify();
  const [contactsIds, setContactIds] = useState<number[]>([]);
  const [currentInstance, setCurrentInstance] = useState<number | null>(null);
  const [nameMessage, setNameMessage] = useState<{
    campaignName?: string;
    texId: number | null;
    textMessage?: string;
  }>({
    texId: campaign ? campaign.companie_whatsapp_message_default_id : -1,
    campaignName: campaign?.title,
  });
  const { WhatsappMessagesList } = useFetchWhatsappMessagesActive('');

  const SaveNameInstances = async (
    campaignId: number,
    payload: Omit<createUpdateWhatsAppCampaign, 'person_ids'>
  ) => {
    setIsLoading(true);
    const res = await ApiWhatsappCampaign.updateWhatsappCampaignNameInstances(
      payload,
      campaignId
    );
    if (res.id) {
      notify({
        message: 'Campanha atualizada com sucesso!',
        type: 'Success',
      });
      setCampaign({ ...res });
    } else {
      notify({
        message: dictionaryError(res),
        type: 'Error',
      });
    }

    setIsLoading(false);
  };

  const handlePrevPage = () => {
    setRegisterPage({
      title: header[registerPage.page - 1].title,
      page: registerPage.page - 1,
    });
  };

  const nextPage = async () => {
    if (registerPage.page < header.length - 1) {
      if (registerPage.page === 2 && !campaign) {
        if (
          contactsIds.length > 0 &&
          currentInstance &&
          nameMessage.campaignName &&
          nameMessage.texId
        ) {
          setIsLoading(true);
          const res = await ApiWhatsappCampaign.createWhatsappCampaign({
            companie_whatsapp_instance_id: currentInstance,
            companie_whatsapp_message_default_id: nameMessage.texId,
            person_ids: contactsIds,
            title: nameMessage.campaignName,
          });

          if (res.id) {
            const men = WhatsappMessagesList.find(
              (item) => item.id === res.companie_whatsapp_message_default_id
            );
            setCampaign({
              ...res,
              companie_whatsapp_messages_default: {
                text: men?.text,
              },
            });
            setRegisterPage({
              title: header[registerPage.page + 1].title,
              page: registerPage.page + 1,
            });
          } else {
            notify({ message: dictionaryError(res), type: 'Error' });
          }
          setIsLoading(false);
        }
      } else if (
        (registerPage.page === 1 || registerPage.page === 2) &&
        campaign &&
        campaign.status === 'Não iniciada'
      ) {
        if (nameMessage.campaignName && currentInstance && nameMessage.texId) {
          await SaveNameInstances(campaign.id, {
            title: nameMessage.campaignName,
            companie_whatsapp_instance_id: currentInstance,
            companie_whatsapp_message_default_id: nameMessage.texId,
          });
          setRegisterPage({
            title: header[registerPage.page + 1].title,
            page: registerPage.page + 1,
          });
        }
      } else {
        setRegisterPage({
          title: header[registerPage.page + 1].title,
          page: registerPage.page + 1,
        });
      }
    } else {
      reloadTable(`${Math.random()}create`);
    }
  };

  useEffect(() => {
    setCurrentInstance(
      campaign ? campaign.companie_whatsapp_instance_id : null
    );
    if (campaign)
      setNameMessage({
        texId: campaign.companie_whatsapp_message_default_id,
        campaignName: campaign.title,
        textMessage: campaign.companie_whatsapp_messages_default.text,
      });
  }, [campaign]);

  return (
    <Card className="flex flex-col mt-5 px-4 py-5 mb-2 min-h-fit h-[80vh]">
      <div className="grid grid-cols-2 sm:flex gap-2 ">
        {header.map((item) => (
          <Button
            key={item.id}
            variant={
              registerPage.page === item.page ? 'primary-strong' : 'gray'
            }
            actionType="button-full-width"
            className={classNames([
              'font-medium',
              'md:w-56',
              campaign !== null ? 'cursor-pointer' : 'cursor-default',
            ])}
            onClick={() => campaign !== null && setRegisterPage(item)}
          >
            {item.title}
          </Button>
        ))}
      </div>
      <hr className="w-full h-[1.5px] bg-primary mt-3" />
      {registerPage.page === 0 && (
        <ContactsList
          isEditable={isEditable}
          isLoadingWhatsappDropdown={isLoadingWhatsappDropdown}
          setInputSelect={setInputSelect}
          notify={notify}
          nextPage={nextPage}
          campaignOptions={campaignList.map((item: any) => ({
            label: item.title,
            value: item.id,
          }))}
          campaign={campaign}
          companieId={companieId}
          listContact={contactsIds}
          setListContact={setContactIds}
        />
      )}

      {registerPage.page === 1 && (
        <NameCampaign
          isEditable={isEditable}
          isLoadingSave={isLoading}
          WhatsappMessagesList={WhatsappMessagesList}
          nameMessage={nameMessage}
          nextPage={nextPage}
          setNameMessage={setNameMessage}
          prevPage={handlePrevPage}
        />
      )}
      {registerPage.page === 2 && (
        <ConectInstance
          isEditable={isEditable}
          isLoadingSave={isLoading}
          currentInstance={currentInstance}
          setCurrentInstance={setCurrentInstance}
          nextPage={nextPage}
          instances={instances}
          prevPage={handlePrevPage}
        />
      )}

      {registerPage.page === 3 && campaign && (
        <CreateMessages
          notify={notify}
          instance={instances.find(
            (item) => item.id === campaign.companie_whatsapp_instance_id
          )}
          setCampaign={setCampaign}
          whatsAppMessages={WhatsappMessagesList}
          campaign={campaign}
          prevPage={handlePrevPage}
        />
      )}
    </Card>
  );
};

export default RegisterCampaign;
