export function convertKeysErros(keys: any) {
  switch (keys) {
    case 'Company.softDescriptor':
      return 'Nome na fatura';
    case 'cnae':
      return 'CNAE';
    default:
      return '';
  }
}
